import { Box, Typography, styled } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { MultiSelect } from 'react-multi-select-component';

interface FormikCampaignMultiSelectProps {
  name: string;
  label?: string;
  options: any;
  noMargin?: boolean;
}

const Wrapper = styled('div')({
  '& .rmsc': {
    border: '1px solid rgba(0, 0, 0, 0.04)',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    outline: 'none',
    fontSize: '1rem',
    width: '200px',
  },
  '& .dropdown-container': {
    height: '44px',
    '& :focus-within': {
      boxShadow: 'none !important',
      borderColor: 'transparent !important',
    },
  },
  '& .dropdown-heading': {
    padding: '1rem 0.6rem 0.3rem 1rem',
  },
});

const FormikCampaignMultiSelect: React.FC<FormikCampaignMultiSelectProps> = ({
  name,
  label,
  options,
  noMargin,
}) => {
  const defaultValue = (value: string[]) => {
    return options
      ? options.filter((option: any) => value.includes(option.value))
      : [];
  };

  return (
    <Wrapper>
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box>
              <MultiSelect
                options={options}
                value={defaultValue(value)}
                onChange={(e: any) => {
                  const selected = e.map((item: any) => item.value);
                  setFieldValue(name, selected);
                }}
                hasSelectAll={false}
                disableSearch={true}
                labelledBy='Select'
                // style={{
                //   border: '1px solid rgba(0, 0, 0, 0.04)',
                //   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                //   padding: '5px 0.7rem 0',
                //   fontFamily: 'Overpass',
                //   outline: 'none',
                //   fontSize: '1rem',
                //   minHeight: '38px',
                //   width: '180px',
                // }}
              />

              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </Wrapper>
  );
};

export default FormikCampaignMultiSelect;
