import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomerSmartKollectAnalysis = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: 'rgba(255, 182, 0, 0.31)',
        borderRadius: '9px',
        padding: '5px 10px',
        margin: '1rem auto',

        '& span': {
          color: '#24254A',
          fontWeight: 600,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
    >
      <Typography color='#24254A'>
        Not Subscribed yet.{' '}
        <span onClick={() => navigate('/smart-collect')}>Click here</span> to
        get the subscription.
      </Typography>
    </Box>
  );
};

export default CustomerSmartKollectAnalysis;
