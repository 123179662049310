import * as React from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import EmailVerfied from './EmailVerfied';
import LinkExpired from './LinkExpired';
import Loader from 'components/loader';
import { useAppDispatch } from 'hooks/useRedux';
import {
  defaultSubscription,
  verifyEmailToken,
} from 'store/reducer/auth/actions';
import { setVerifyEmailData } from 'store/reducer/auth';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email')?.replace(' ', '+');
  const dispatch = useAppDispatch();

  const [tokenState, setTokenState] = React.useState<string | null>(null);

  const verifyToken = React.useCallback(async () => {
    try {
      const { meta, data } = await dispatch(
        verifyEmailToken({ token })
      ).unwrap();
      if (meta?.success) {
        let subscriptionDate: any = new Date();
        subscriptionDate = subscriptionDate.setSeconds(0);
        subscriptionDate = new Date(subscriptionDate).setMilliseconds(0);
        await dispatch(
          defaultSubscription({
            token: token!,
            subscription_start_ts: new Date(subscriptionDate)
              .toISOString()
              .replace(/\.\d+/, ''),
          })
        );
        setTokenState('verified');
        dispatch(setVerifyEmailData({ ...data, token }));
      } else {
        setTokenState('expired');
      }
    } catch (err) {
      setTokenState('expired');
    }
  }, [dispatch, token]);

  React.useEffect(() => {
    if (token) verifyToken();
  }, [token, verifyToken]);

  if (!tokenState) return <Loader loading={true} />;

  return (
    <>
      <Box width='55%'>
        {tokenState === 'verified' && <EmailVerfied />}
        {tokenState === 'expired' && <LinkExpired email={email} />}
      </Box>
    </>
  );
};

export default EmailVerification;
