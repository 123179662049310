import { Box, styled, Typography } from '@mui/material';
import { useField } from 'formik';

interface CustomFormikInputProps {
  label: string;
  [x: string]: any;
}

const FormikInputWrapper = styled('div')({
  display: 'flex',
  marginBottom: '1rem',
  opacity: '0.6',

  '& label': {
    minWidth: '250px',
  },

  '& input': {
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '0.5rem',
    fontFamily: 'inherit',
    outline: 'none',
    minWidth: '275px',
  },
});

const CustomFormikInput: React.FC<CustomFormikInputProps> = ({
  label,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <FormikInputWrapper>
      <label>
        <Typography sx={{ fontSize: '1rem' }}>{label}</Typography>
      </label>
      <Box>
        <input {...field} {...props} />
        {meta.touched && meta.error && (
          <Typography fontSize='0.8rem' color='#d32f2f'>
            {meta.error}
          </Typography>
        )}
      </Box>
    </FormikInputWrapper>
  );
};

export default CustomFormikInput;
