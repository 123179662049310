import * as React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import {
  FormikCampaignSelect,
  FormikFilterInput,
} from 'components/formik/campaign';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import * as yup from 'yup';
import useStyles from './index.styles';

import { workflowData } from 'constants/workflowData';
import { createSelectOption } from 'utils/helper';
import FormikCampaignDatePicker from 'components/formik/campaign/FormikCampaignDatePicker';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  addCustomFilter,
  getCustomFilter,
  updateCustomFilter,
} from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import {
  campaignFilters,
  setCampaignPayload,
  setCustomFilter,
} from 'store/reducer/campaignReducer';
import { customFilterData } from 'utils/campaign/campaignModifier';
import FormikCampaignMultiSelect from 'components/formik/campaign/FormikCampaignMultiSelect';

interface OptionType {
  label: string;
  value: string;
}

interface AttributeType {
  attribute: string;
  type: string;
  allowed_operator?: string[] | string;
  allowed_values?: string[];
}

const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  // operator: yup.string().required('Required'),
  // value: yup.string().required('Required'),
});

const FilterModal = ({ onClose, rows }: { rows: any; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );
  const filterAttributes = useAppSelector(campaignFilters);

  const [attributes, setAttributes] = React.useState<any[]>([]);
  const [attributeOptions, setAttributeOptions] = React.useState<
    OptionType[] | []
  >([]);
  const [attribute, setAttribute] = React.useState<AttributeType | null>(null);
  const [operatorOptions, setOperatorOptions] = React.useState<
    OptionType[] | []
  >([]);
  const [listOptions, setListOptions] = React.useState<OptionType[] | []>([]);
  const [isFormula, setIsFormula] = React.useState<{
    start: boolean;
    end: boolean;
  }>({ start: false, end: false });
  const [loading, setLoading] = React.useState<boolean>(false);

  // const allowedAttributes = workflowData.allowed_attributes as AttributeType[];

  // React.useEffect(() => {
  //   const option = createSelectOption({
  //     arr: allowedAttributes,
  //     isObj: true,
  //     objKey: 'attribute',
  //   });
  //   setAttributeOptions(option);
  // }, [allowedAttributes]);
  React.useEffect(() => {
    if (filterAttributes.length) {
      const option = createSelectOption({
        arr: filterAttributes,
        isObj: true,
        objKey: 'attribute',
      });
      setAttributeOptions(option);
    } else {
      setAttributeOptions([]);
    }
  }, [filterAttributes]);

  const handleAttributeRemove = (attribute: any) => {
    const newAttribute = attributes.filter(
      (item: any) => JSON.stringify(item) !== JSON.stringify(attribute)
    );
    setAttributes(newAttribute);
  };

  const handleAttributeChange = (value: string) => {
    const attr = filterAttributes.find((item: any) => item.attribute === value);
    if (attr) {
      const option = createSelectOption({
        arr: attr?.allowed_operator || [],
      });
      if (attr.type === 'List' && Array.isArray(attr.allowed_values)) {
        const opt = createSelectOption({
          arr: attr?.allowed_values || [],
        });
        setListOptions(opt);
      }
      setOperatorOptions(option);
      setAttribute(attr as AttributeType);
    }
  };

  const getParameters = (para: any) => {
    if (typeof para === 'object') {
      return Object.values(para).join(' and ');
    }
    return para;
  };

  const handleAdd = (values: FormikValues, actions: any) => {
    if (attributes.length < 3) {
      const copyAttr = [...attributes];
      const { name, operator, value, upper_value, start_date, end_date } =
        values;
      let parameters: any = value;
      const attribute: any = filterAttributes.find(
        (item) => item.attribute === name
      );
      if (attribute.type === 'Number') {
        if (['in_range', 'not_in_range'].includes(values.operator)) {
          parameters = {
            lower_limit: value,
            upper_limit: upper_value,
          };
        }
        if (['listed_in', 'not_listed_in'].includes(values.operator)) {
          parameters = value
            .split(',')
            .map((str: string) => str.replace(/\s/g, ''));
        }
      }
      if (attribute.type === 'Date') {
        const getDate = (value: string | Date, position: 'start' | 'end') => {
          return isFormula[position]
            ? value
            : moment(value).format('YYYY-MM-DD');
        };
        if (['in_range', 'not_in_range'].includes(operator)) {
          parameters = {
            start_date: start_date ? getDate(start_date, 'start') : '',
            end_date: end_date ? getDate(end_date, 'end') : '',
          };
        } else {
          parameters = start_date ? getDate(start_date, 'start') : '';
        }
      }
      const payload = {
        attribute: name,
        operator: operator || 'equals_to',
        value: parameters,
      };
      copyAttr.push(payload);
      setAttributes(copyAttr);
      actions.resetForm();
    }
  };

  const handleSave = async () => {
    let attributesArr = attributes.map((attribute) => {
      const attributeOpt: any = filterAttributes.find(
        (item: any) => item.attribute === (attribute as any).attribute
      );

      if (['listed_in', 'not_listed_in'].includes(attribute.operator)) {
        let attr = { ...attribute };
        if (attributeOpt.type === 'List') {
          attr.value = [attribute.value];
        }
        if (attributeOpt.type === 'Number') {
          attr.value = JSON.stringify(attribute.value);
        }
        return attr;
      }
      return attribute;
    });

    setLoading(true);
    try {
      let payload: any = {
        campaign_id: campaignData?.campaign_id,
        // filters: attributes,
        filters: attributesArr,
      };
      const rest = rows.find((row: any) => row.condition === 'rest');
      let value: any = [];
      if (rest) {
        payload.group_id = rest?.group_id;
        await dispatch(updateCustomFilter(payload));
        // const { data, meta } = await dispatch(
        //   updateCustomFilter(payload)
        // ).unwrap();
        // if (meta?.success) {
        //   const filterData = customFilterData(data);
        //   const noRestRow = rows.filter((row: any) => row.condition !== 'rest');
        //   value = [...noRestRow, ...filterData];
        // }
      } else {
        await dispatch(addCustomFilter(payload));
        // const { data } = await dispatch(addCustomFilter(payload)).unwrap();
        // value = customFilterData(data);
      }
      // dispatch(
      //   setCampaignPayload({
      //     key: 'add_filter',
      //     data: { filter: 'add_custom', value },
      //   })
      // );
      // dispatch(setCustomFilter(value));

      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box minWidth='550px'>
        <Typography fontWeight={600}>
          Add Filters{' '}
          {/* <span style={{ fontSize: '0.9rem' }}>
            (for 1211 ungrouped customers)
          </span> */}
        </Typography>

        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            operator: '',
            value: '',
            upper_value: '',
            start_date: null,
            end_date: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleAdd}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box className={classes.formWrapper}>
                <Box>
                  <Typography align='center'>Attribute Name</Typography>
                  <FormikCampaignSelect
                    name='name'
                    options={attributeOptions}
                    handleChange={(value) => {
                      handleAttributeChange(value);
                      setFieldValue('operator', '');
                      setFieldValue('value', '');
                    }}
                  />
                </Box>

                {(!attribute ||
                  (attribute?.type !== 'Boolean' &&
                    attribute.allowed_operator)) && (
                  <Box>
                    <Typography align='center'>Operator</Typography>
                    <FormikCampaignSelect
                      name='operator'
                      options={operatorOptions}
                      handleChange={(value) => {
                        setFieldValue('value', '');
                        setFieldValue('upper_value', '');
                        setFieldValue('start_date', '');
                      }}
                    />
                  </Box>
                )}

                {values.operator && attribute?.type === 'Date' && (
                  <Box className={classes.dateWrapper}>
                    <Box
                      sx={{ display: 'flex', alignItems: 'start', gap: '1rem' }}
                    >
                      <Box>
                        {isFormula.start ? (
                          <Box>
                            <Typography align='center'>
                              {['in_range', 'not_in_range'].includes(
                                values.operator
                              )
                                ? 'Start Date'
                                : 'Value'}
                            </Typography>
                            <FormikFilterInput name='value' />
                          </Box>
                        ) : (
                          <Box>
                            <Typography align='center'>
                              {['in_range', 'not_in_range'].includes(
                                values.operator
                              )
                                ? 'Start Date'
                                : 'Value'}
                            </Typography>
                            <FormikCampaignDatePicker name='start_date' />
                          </Box>
                        )}

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isFormula.start}
                                onChange={(e) => {
                                  setFieldValue('end_date', null);
                                  setIsFormula({
                                    ...isFormula,
                                    start: Boolean(e.target.checked),
                                  });
                                }}
                                disableRipple={true}
                              />
                            }
                            label='Add Formula'
                          />
                        </FormGroup>
                      </Box>

                      {['in_range', 'not_in_range'].includes(
                        values.operator
                      ) && (
                        <Box>
                          {isFormula.end ? (
                            <Box>
                              <Typography align='center'>Value</Typography>
                              <FormikFilterInput name='end_date' />
                            </Box>
                          ) : (
                            <Box>
                              <Typography align='center'>End Date</Typography>
                              <FormikCampaignDatePicker name='end_date' />
                            </Box>
                          )}

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isFormula.end}
                                  onChange={(e) => {
                                    setFieldValue('start_date', null);
                                    setIsFormula({
                                      ...isFormula,
                                      end: Boolean(e.target.checked),
                                    });
                                  }}
                                  disableRipple={true}
                                />
                              }
                              label='Add Formula'
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </Box>

                    {(isFormula.start || isFormula.end) && (
                      <Box sx={{ '& p': { fontSize: '12px' } }}>
                        <Typography>Relative Formula Usage</Typography>
                        <ul style={{ marginLeft: '15px' }}>
                          <li>
                            <Typography>For today: CURRENT_DATE</Typography>
                          </li>
                          <li>
                            <Typography>
                              For 5 days before today: CURRENT_DATE-5
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              For 10 days after today: CURRENT_DATE+10
                            </Typography>
                          </li>
                        </ul>
                      </Box>
                    )}
                  </Box>
                )}

                {attribute?.type === 'Boolean' && (
                  <Box>
                    <Typography align='center'>Value</Typography>
                    <FormikCampaignSelect
                      name='value'
                      options={booleanOptions}
                    />
                  </Box>
                )}

                {(values.operator || !attribute?.allowed_operator) &&
                  attribute?.type === 'List' &&
                  !['listed_in', 'not_listed_in'].includes(values.operator) && (
                    <Box>
                      <Typography align='center'>Value</Typography>
                      <FormikCampaignSelect
                        name='value'
                        options={listOptions}
                      />
                    </Box>
                  )}

                {/* new */}
                {values.operator &&
                  attribute?.type === 'List' &&
                  ['listed_in', 'not_listed_in'].includes(values.operator) && (
                    <Box>
                      <Typography align='center'>Value</Typography>
                      <FormikCampaignMultiSelect
                        name='value'
                        options={listOptions}
                      />
                    </Box>
                  )}

                {values.operator &&
                  attribute?.type !== 'List' &&
                  ['listed_in', 'not_listed_in'].includes(values.operator) && (
                    <Box>
                      <Typography align='center'>
                        Value{' '}
                        <Typography component='span' sx={{ fontSize: '12px' }}>
                          (enter comma separated values)
                        </Typography>
                      </Typography>
                      <FormikFilterInput name='value' />
                    </Box>
                  )}

                {values.operator &&
                  // attribute?.type !== 'List' &&
                  attribute &&
                  !['List', 'Date'].includes(attribute.type) &&
                  [
                    'equals_to',
                    'not_equals_to',
                    'greater_than',
                    'less_than',
                  ].includes(values.operator) && (
                    <Box>
                      <Typography align='center'>Value</Typography>
                      <FormikFilterInput name='value' />
                    </Box>
                  )}

                {values.operator &&
                  attribute &&
                  ['Number', 'Boolean'].includes(attribute?.type) &&
                  ['in_range', 'not_in_range'].includes(values.operator) && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <Box>
                        <Typography align='center'>Lower Value</Typography>
                        <FormikFilterInput name='value' />
                      </Box>
                      <Box>
                        <Typography align='center'>Upper Value</Typography>
                        <FormikFilterInput name='upper_value' />
                      </Box>
                    </Box>
                  )}

                <Box sx={{ transform: 'translate(0, 85%)' }}>
                  <button type='submit'>
                    <AiFillPlusCircle
                      color={attributes.length >= 3 ? '#6F6F6F' : '#6AC5AB'}
                      fontSize='1.5rem'
                    />
                  </button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>

        <Box mt='10px' sx={{ maxWidth: '60%', margin: '0 auto' }}>
          <Typography color='#004357' mb='10px'>
            <span style={{ textDecoration: 'underline' }}>Conditions</span>{' '}
            <span style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>
              (Not more than 3)
            </span>
          </Typography>
          {attributes.length ? (
            <Box sx={{ border: '1px solid #E6E6E6', borderRadius: '10px' }}>
              {attributes.map((item: any, index: number) => {
                const para = getParameters(item.value);
                return (
                  <Box
                    key={index}
                    className={classes.conditionCont}
                    sx={{
                      borderTop: `${index === 0 ? '' : '1px solid #E6E6E6'}`,
                    }}
                    onClick={() => {}}
                  >
                    <Box display='flex' gap='10px' alignItems='center'>
                      <Typography sx={{ fontSize: '0.9rem', color: '#A8ABC3' }}>
                        {item.attribute} {item.operator} {para}
                      </Typography>
                    </Box>

                    <button
                      type='button'
                      onClick={() => handleAttributeRemove(item)}
                    >
                      <AiFillMinusCircle color='#DAA09A' fontSize='1.4rem' />
                    </button>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box className={classes.noFilterCont}>
              <Typography color='#6F6F6F' fontStyle='italic'>
                No filters added yet
              </Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.saveButtonCont}>
          <button
            type='button'
            disabled={attributes.length ? false : true}
            className={classes.saveButtonStyle}
            style={{ background: attributes.length ? '#004357' : '#6F6F6F' }}
            onClick={handleSave}
          >
            <Typography fontWeight={600}>Save Filter</Typography>
          </button>
        </Box>
      </Box>
    </>
  );
};

export default FilterModal;
