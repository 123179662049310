import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import emptyImg from 'assets/empty_img.svg';

const EmptyScreenWrapper = styled('div')(() => ({
  height: 'calc(100vh - 450px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F7F7F7',
  border: '1px solid #E6E6E6',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',

  '& p': {
    fontFamily: 'Overpass',
    color: '#969696',
    margin: '0.5rem auto',
  },

  '@media (max-width: 1600px)': {
    '& img': {
      height: 100,
    },
  },
}));

const EmptyScreen = ({
  status,
  isSearchData,
}: {
  status: string;
  isSearchData: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <EmptyScreenWrapper>
      <img src={emptyImg} alt='' />
      {isSearchData ? (
        <Typography>No customer found for the searched ID</Typography>
      ) : (
        <>
          <Typography>
            Seems like you haven't added any customers for {status} yet
          </Typography>

          <Typography>
            View{' '}
            <span
              style={{ color: '#004357', fontWeight: 600, cursor: 'pointer' }}
              onClick={() => navigate('/integrations')}
            >
              [Integrations]
            </span>{' '}
            to select an option to add your customer base.
          </Typography>
        </>
      )}
    </EmptyScreenWrapper>
  );
};

export default EmptyScreen;
