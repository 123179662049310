import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { BiEdit } from 'react-icons/bi';

import { ActionButtons, PermissionEdit, PermissionNameWrapper } from './styles';
import CreateEditPermissionTable from './table';
import { PermissionButton } from 'containers/Permissions/styles';
import { getCreatePermissionModule } from 'utils/helper';
import Loader from 'components/loader';
import { useAppDispatch } from 'hooks/useRedux';
import {
  createPermission,
  getPermissionSummary,
  updatePermission,
} from 'store/reducer/permissionReducer/action';

export type ModuleType = {
  module: string;
  actions: {
    operation: string;
    status: number;
    checked: boolean;
  }[];
};

export const CreateEditPermission = ({
  data,
  onClose,
}: {
  data?: any;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [permissionName, setPermissionName] = React.useState<string>('');
  const [editName, setEditName] = React.useState<boolean>(false);
  const [moduleValues, setModuleValues] = React.useState<ModuleType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsEdit(data && true);
    setPermissionName(data?.permission_name || '');
  }, [data]);

  const handlePermissionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPermissionName(e.target.value);
  };

  const handleSave = async () => {
    if (permissionName) {
      setLoading(true);
      try {
        const modules = getCreatePermissionModule(moduleValues);

        if (isEdit) {
          const payload = {
            permission_id: data?.permission_id,
            modules,
          };
          await dispatch(updatePermission(payload));
        } else {
          const payload = {
            permission_name: permissionName,
            modules,
          };
          await dispatch(createPermission(payload));
        }
        await dispatch(getPermissionSummary({ limit: 100, offset: 0 }));
        onClose();
      } catch (error) {}
      setLoading(false);
    }
  };

  // console.log({ data });

  return (
    <>
      <Loader loading={loading} />
      <Box minWidth='70vw' maxHeight='90vh' overflow='auto'>
        <PermissionNameWrapper>
          {isEdit && !editName ? (
            <Typography fontSize='1.2rem' fontWeight={500}>
              {permissionName}
            </Typography>
          ) : (
            <Box>
              <input
                type='text'
                placeholder='{{Enter permission name}}'
                value={permissionName}
                onChange={handlePermissionName}
              />
            </Box>
          )}
          {isEdit && !editName && (
            <PermissionEdit onClick={() => setEditName(true)}>
              <Typography color='#32475C8A'>Edit</Typography>
              <BiEdit />
            </PermissionEdit>
          )}
        </PermissionNameWrapper>

        <Box
          sx={{
            border: '1px solid rgba(50, 71, 92, 0.12)',
            padding: '14px',
          }}
        >
          <Typography fontWeight={600}>
            These are the access rights under these permissions
          </Typography>
        </Box>

        <CreateEditPermissionTable
          data={data}
          moduleValues={moduleValues}
          setModuleValues={setModuleValues}
        />

        <ActionButtons>
          <PermissionButton
            background='#004357'
            color='#fff'
            disabled={!permissionName || (data && !data?.editable)}
            onClick={handleSave}
          >
            <Typography>Save Changes</Typography>
          </PermissionButton>
          <PermissionButton background='#fff' color='#8592A3' onClick={onClose}>
            <Typography>Discard</Typography>
          </PermissionButton>
        </ActionButtons>
      </Box>
    </>
  );
};
