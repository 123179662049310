import { Box, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';

import WorkflowButton from './button';
// import StrategyFilter from './filter';
import { Add } from '@mui/icons-material';
import { useAppSelector } from 'hooks/useRedux';
// import { userAccess } from 'utils/helper';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface StrategyDetailsHeaderProps {
  onEditClick: () => void;
  setOpenCreateModal: (value: boolean) => void;
  handleDeleteModalOpen?: () => void;
  handleCopyModalOpen?: () => void;
  handleEditModalOpen?: () => void;
  strategySelected: Array<string>;
}

const StrategyDetailsHeader: React.FC<StrategyDetailsHeaderProps> = ({
  onEditClick,
  setOpenCreateModal,
  handleDeleteModalOpen,
  handleCopyModalOpen,
  handleEditModalOpen,
  strategySelected,
}) => {
  const disabledDeleteOption = !strategySelected?.length;
  const disabledDuplicate = strategySelected?.length !== 1;
  const disabledEdit = strategySelected?.length !== 1;

  // const userRole = useAppSelector((state) => state.auth.role);

  const permissions = useAppSelector(userPermissions);
  // const userPermissions = userAccess(userRole)?.strategy.permissions;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      {/* {userPermissions.includes('create') ? ( */}
      {permissions[MODULELIST.STRATEGY].includes('create') ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          gap='0.5rem'
          bgcolor='#004357'
          padding='17px 34px'
          borderRadius={4}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setOpenCreateModal(true);
          }}
        >
          <Add style={{ fontSize: '2rem', color: '#fff' }} />
          <Typography fontSize={'1.2rem'} color='#fff'>
            Create New Strategy
          </Typography>
        </Box>
      ) : (
        <Box></Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {/* {userPermissions.includes('create') && ( */}
        {permissions[MODULELIST.STRATEGY].includes('create') && (
          <WorkflowButton
            text='COPY STRATEGY'
            bgColor='#115180'
            icon={<ContentCopy />}
            onClick={handleCopyModalOpen}
            disabled={disabledDuplicate}
          />
        )}

        {/* {userPermissions.includes('update') && ( */}
        {permissions[MODULELIST.STRATEGY].includes('update') && (
          <WorkflowButton
            text='EDIT STRATEGY'
            bgColor='#6AC5AB'
            icon={<EditIcon />}
            onClick={handleEditModalOpen}
            disabled={disabledEdit}
          />
        )}
        {/* {userPermissions.includes('delete') && ( */}
        {permissions[MODULELIST.STRATEGY].includes('delete') && (
          <WorkflowButton
            text='DELETE'
            bgColor='#DAA09A'
            icon={<DeleteIcon />}
            onClick={handleDeleteModalOpen}
            disabled={disabledDeleteOption}
          />
        )}
      </Box>
    </Box>
  );
};

export default StrategyDetailsHeader;
