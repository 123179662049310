import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { kollectAITheme } from '../../config/theme';
import CustomIcon from '../customIcons';
import useStyles from './styles';
import Calendar from 'react-calendar';
import { useState } from 'react';
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';

export type DateTimeBoxProps = {
  icon?: string;
  text?: string;
  setValue?: any;
  value?: string;
  isTimePicker?: boolean;
  disabled?: boolean;
  isPositionBottom?: boolean;
  minDate?: Date;
};

const DateTimeBox: React.FC<DateTimeBoxProps> = ({
  icon,
  text,
  setValue,
  isTimePicker,
  disabled,
  isPositionBottom,
  minDate,
}) => {
  const classes = useStyles({ disabled, isPositionBottom });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onChangeDate = (value: any) => {
    if (value) {
      setValue(value);
      setOpenModal(false);
    }
  };
  const onOpenCalendar = () => {
    setOpenModal(true);
  };

  return (
    <Button
      className={classes.dateTimeBoxMainCont}
      onClick={() => onOpenCalendar()}
      disabled={disabled}
    >
      <Typography className={classes.dateTimeBoxTextStyle}>{text}</Typography>
      <CustomIcon
        name={icon}
        size={18}
        color={disabled ? kollectAITheme.lightGrey : kollectAITheme.darkBlue}
      />

      {openModal && (
        <div className={classes.calendarContainer}>
          {isTimePicker ? (
            <TimePicker
              value={''}
              onChange={(value: any) => onChangeDate(value)}
            />
          ) : (
            <Calendar
              minDate={new Date()}
              onChange={(value: any) => onChangeDate(value)}
            />
          )}
        </div>
      )}
    </Button>
  );
};

export default DateTimeBox;
