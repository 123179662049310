import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    customSelectTitle: {
      textAlign: 'center',
      fontSize: '1rem',
      lineHeight: '26px',
      marginBottom: '12px',
    },
    label: {
      fontSize: '0.8em',
      marginTop: '3px',
    },
    radio: {
      '&$checked': {
        color: '#3552CC',
      },
    },
    checked: {},
    radioGroupContainer: {
      padding: '3px 10px',
      borderRadius: '11px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',

      '& .MuiSvgIcon-root': {
        width: '0.9em',
        height: '0.9em',
      },
    },
  })
);

export default useStyles;
