import React from 'react';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';

import DownloadImg from 'assets/download_salmon.svg';

const InvalidEntries = ({
  errors,
  onClose,
}: {
  errors: any;
  onClose: () => void;
}) => {
  // const [validData, setValidData] = React.useState<number>(0);
  const [invalidData, setInvalidData] = React.useState<number>(0);
  const [totalData, setTotalData] = React.useState<number>(0);

  React.useEffect(() => {
    // const total = errors?.['PII']?.['Total Data'] || 0;
    // const valid = errors?.['PII']?.['Valid Data'] || 0;
    // setValidData(valid);
    // setTotalData(total);

    const total = errors?.total_records || 0;
    const invalid = errors?.invalid_records || 0;
    setInvalidData(invalid);
    setTotalData(total);
  }, [errors]);

  const handleDownloadInvalid = React.useCallback(() => {
    // const json = new Blob([JSON.stringify(errors)], {
    //   type: 'application/json',
    // });
    // const jsonURL = window.URL.createObjectURL(json);
    // let tempLink = document.createElement('a');
    // tempLink.href = jsonURL;
    // tempLink.setAttribute('download', `invalid_entries.json`);
    // tempLink.click();

    if (errors?.link) {
      window.open(errors.link, '_self');
    }
  }, [errors]);

  const validData = React.useMemo(
    () => +totalData - invalidData,
    [totalData, invalidData]
  );

  return (
    <Box>
      <Typography>
        It seems the customer data that you have uploaded have some invalid
        entries as below:
      </Typography>

      <ListItem sx={{ pt: 0, pb: 0 }}>
        <ListItemText
          primary={`Total customer data uploaded: ${totalData}`}
          sx={{ display: 'list-item' }}
        />
      </ListItem>
      <ListItem sx={{ pt: 0, pb: 0 }}>
        <ListItemText
          primary={`Total valid customer data found: ${validData}`}
          sx={{ display: 'list-item' }}
        />
      </ListItem>
      <ListItem sx={{ pt: 0, pb: 0 }}>
        <ListItemText
          primary={`Total invalid data uploaded: ${invalidData}`}
          sx={{ display: 'list-item' }}
        />
      </ListItem>

      <Typography mt='1rem'>
        <button onClick={handleDownloadInvalid}>
          <Typography color='#95261F'>Click here</Typography>
        </button>
        to download invalid customer ids with their reasons. Please upload them
        again with correct data.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: '0.75rem',
          cursor: 'pointer',
        }}
        onClick={handleDownloadInvalid}
      >
        <img src={DownloadImg} alt='' />
      </Box>
    </Box>
  );
};

export default InvalidEntries;
