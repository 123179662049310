import api from 'apis/api';
import kollectAiPermissions from './config';

import { ModuleType } from 'constants/types';

const permissionApi = {
  async getPermissionSummary(payload: {
    search_query?: string;
    limit: number;
    offset: number;
  }) {
    return api.get(kollectAiPermissions.permissionSummary, { params: payload });
  },

  async getPermission(payload: { permission_id: string }) {
    return api.get(kollectAiPermissions.permissionView, { params: payload });
  },

  async createPermission(payload: {
    permission_name: string;
    modules: ModuleType[];
  }) {
    return api.post(kollectAiPermissions.permissionView, payload);
  },

  async updatePermission(payload: {
    permission_id: string;
    modules: ModuleType[];
  }) {
    return api.patch(kollectAiPermissions.permissionView, payload);
  },

  async deletePermission(payload: { permission_id: string }) {
    return api.delete(kollectAiPermissions.permissionView, { data: payload });
  },

  async getClientConfig() {
    return api.get(kollectAiPermissions.clientConfig);
  },
};

export default permissionApi;
