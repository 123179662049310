import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterOptionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterOptionsCont: {
      display: 'inline-flex',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
    filterOptions: {
      background: '#EBEEF2',
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 500,
      color: '#969696',
    },
    filterSelected: {
      color: '#fff',
      background: '#036482',
    },
    tableStyle: {
      borderCollapse: 'collapse',
      width: '60%',
      '& td, th': {
        textAlign: 'center',
        padding: '8px',
        '& p': { fontWeight: 500 },
      },
      '& th': { color: '#6F6F6F' },
    },
    splitInput: {
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      outline: 'none',
      background: '#fff',
      border: 'transparent',
      padding: '8px 10px 5px',
      width: '40px',
      fontSize: '1rem',
      textAlign: 'center',
    },
    updateSplitBtn: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      background: '#6AC5AB',
      color: '#fff',
      borderRadius: '8px',
      padding: '6px 12px',
    },
  })
);

export default useStyles;
