import { styled } from '@mui/material';

export const PermissionButton = styled('button')(
  ({
    background,
    disabled,
    color = '#fff',
  }: {
    background: string;
    disabled?: boolean;
    color?: string;
  }) => ({
    background: !disabled ? background : '#A2A7AE',
    boxShadow:
      '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
    borderRadius: '6px',
    // cursor: 'pointer',
    padding: '8px 1.2rem',
    display: 'inline-block',
    cursor: disabled ? 'default' : 'pointer',
    '& p': {
      color: disabled ? '#fff !important' : `${color} !important`,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  })
);

export const PermissionHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const RoleLink = styled('div')(() => ({
  '& p': {
    textDecoration: 'underline',
    color: '#004357',
    fontSize: '1.1rem',
    cursor: 'pointer',
    fontWeight: 600,
    pointer: 'cursor',
  },
}));
