import { createSlice } from '@reduxjs/toolkit';
import { getTemplateAttributes, viewChannelTemplate } from './action';

const initialState = {
  emailTemplate: {},
  smsTemplate: {},
  ivrTemplate: {},
  whatsappTemplate: {},
  availableTemplateAttributes: [],
};

export const TemplateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewChannelTemplate.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.message === 'success') {
        if (data.email) state.emailTemplate = data.email;
        if (data.sms) state.smsTemplate = data.sms;
        if (data.ivr) state.ivrTemplate = data.ivr;
        if (data.whatsapp) state.whatsappTemplate = data.whatsapp;
      }
    });
    builder.addCase(getTemplateAttributes.fulfilled, (state, { payload }) => {
      state.availableTemplateAttributes = payload?.result || [];
    });
  },
});

export const emailTemplate = (state: any) => state.template.emailTemplate;
export const smsTemplate = (state: any) => state.template.smsTemplate;
export const ivrTemplate = (state: any) => state.template.ivrTemplate;
export const whatsappTemplate = (state: any) => state.template.whatsappTemplate;
export const availableTemplateAttributes = (state: any) =>
  state.template.availableTemplateAttributes;

export default TemplateSlice.reducer;
