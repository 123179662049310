import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

import onBoardingImg from 'assets/onboarding_img.svg';
import topDots from 'assets/login_dots_top.svg';
import bottomDots from 'assets/login_dots_bottom.svg';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainCont}>
      <Box className={classes.leftBoxCont}>
        <Box className={classes.leftBoxImgCont}>
          <img src={onBoardingImg} alt='' />
        </Box>

        <Box className={classes.leftBoxTextCont}>
          <Typography fontSize='1.6rem' fontWeight={500} mb='0.6rem'>
            Interconnected & No-code Collection for Enterprises
          </Typography>
          <Typography>
            An Automated Collection platform designed to help you for your
            collection process
          </Typography>
        </Box>
      </Box>

      <Box className={classes.rightBoxCont}>
        <Box className={classes.topDotsStyle}>
          <img src={topDots} alt='' />
        </Box>

        <Outlet/>

        <Box className={classes.bottomDotsStyle}>
          <img src={bottomDots} alt='' />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
