import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    workflowDrawerCont: {
      // background: theme.kollectAITheme.primaryColor,
      width: '300px',
      color: '#fff',
      // minHeight: '100%',
      padding: '1.5rem 1rem',
    },
    workflowTypeBoxStyle: {
      background: '#fff',
      color: theme.kollectAITheme.primaryColor,
    },
    workflowHorizontalLine: {
      margin: '5px 0 15px',
      height: '2px',
      background: '#fff',
      borderRadius: '5px',
    },
  })
);

export default useStyles;
