import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    channelBoxCont: {
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid #E6E6E6',
      borderRadius: '10px',
      padding: '0.5rem 1rem',
      alignItems: 'center',
    },
    templateOptionCont: {
      width: '80%',
      margin: '0 auto',
      background: 'rgba(0, 0, 0, 0.25)',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  })
);

export default useStyles;
