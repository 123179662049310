import { useEffect, useLayoutEffect, useState } from 'react';
import { styled, Box, Drawer, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

import {
  setAddBranch,
  setWorkflowActionData,
  setWorkflowEditor,
  setWorkflowType,
  toggleDrawer,
} from 'store/reducer/workflowReducer';
import { actions } from '../sidemenu/WorkflowTool/Actions/data';

import WorkflowManagementSideMenu from '../sidemenu';
import WorkflowGraph from './graph';
import WorkflowEditorTools from './tools';
import WorkflowDrawerContent from './drawerContent';
import { DragDropContext } from 'react-beautiful-dnd';
import { setWorkflowAction } from 'utils/workflow/graphHandler';
import { workflowActionOptions } from 'utils/helper';

const EmptyDataCont = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',

  '& p': {
    fontSize: '1.2rem',
    color: '#989898',
  },
});

const WorkflowEditorScreen = () => {
  const [hideSidemenu, setHideSidemenu] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector((state) => state.workflow.openDrawer);
  const workflowFunnel: any = useAppSelector(
    (state: any) => state.workflow.workflowFunnel
  );
  const funnelMapping = useAppSelector(
    (state: any) => state.workflow.funnelMapping
  );

  useEffect(() => {
    // if (workflowFunnel.viewFunnel && funnelMapping.length) {
    if (workflowFunnel.viewFunnel) {
      setHideSidemenu(true);
    }
    // }, [funnelMapping, workflowFunnel]);
  }, [workflowFunnel]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (destination && destination.droppableId !== 'actions') {
      const [parent, parentValue] = destination.droppableId.split('&');
      const payload = {
        name: '',
        parentValue,
        parent,
        position: 1,
      };
      const workflowType = workflowActionOptions.find(
        (option) => option.label === actions[source.index]['title']
      );
      if (workflowType) {
        dispatch(setWorkflowType(workflowType['value']));
        dispatch(setAddBranch(false));
        dispatch(setWorkflowEditor('create'));
        dispatch(setWorkflowActionData(payload));
        setWorkflowAction('workflowActionData', payload);
        dispatch(toggleDrawer(true));
      }
    }
  };

  return (
    <Box sx={{ marginLeft: '6px', flexGrow: 1 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: hideSidemenu ? '1fr' : '340px 1fr',
          }}
        >
          {!hideSidemenu && (
            <Box>
              <WorkflowManagementSideMenu />
            </Box>
          )}
          <Box>
            <Box sx={{ marginLeft: `${hideSidemenu ? 0 : '18px'}` }}>
              <WorkflowEditorTools
                handleSidebar={() => setHideSidemenu(!hideSidemenu)}
                enlargeIcon={hideSidemenu ? 'Exit Full Screeen' : 'Full Screen'}
                handleSave={() => {}}
                handlePublish={() => {}}
              />
              {workflowFunnel.viewFunnel && !funnelMapping.length ? (
                // {workflowFunnel.viewFunnel ? (
                <EmptyDataCont>
                  <Typography>No data for this date & execution id</Typography>
                </EmptyDataCont>
              ) : (
                <WorkflowGraph />
              )}
            </Box>
          </Box>
        </Box>
      </DragDropContext>

      <Drawer
        anchor={'right'}
        open={openDrawer}
        onClose={() => dispatch(toggleDrawer(false))}
        sx={{ '& .MuiDrawer-paper': { background: '#004357' } }}
      >
        <WorkflowDrawerContent />
      </Drawer>
    </Box>
  );
};

export default WorkflowEditorScreen;
