import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    templatePreviewText: {
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      background: 'rgba(150, 150, 150, 0.08)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
    },
  })
);

export default useStyles;
