import { Box, Typography } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import useStyles from '../index.styles';

const ChatHeader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.chatHeader}>
      <Box className={classes.flexBetween}>
        <Typography>ID: {`{id}`}</Typography>
        <Box className={classes.endChatBtn}>
          <IoMdClose color='#D73D00' />
          <Typography>End Chat</Typography>
        </Box>
      </Box>

      <Box className={classes.flexBetween} mt='0.6rem'>
        <Typography>Chat Started at: {`{timestamp}`}</Typography>
        <Box>
          <Typography>
            Chat Duration:{' '}
            <Box component='span' sx={{ fontWeight: 600 }}>
              00:01:28
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatHeader;
