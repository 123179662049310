import { useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { PrioritySlider, PrioritySliderCont } from './index.styles';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { postCustomerRequests } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import {
  customerOdfID,
  setRefetchRequest,
} from 'store/reducer/customerReducer';

import {
  FormikCustomerSelect,
  FormikCustomerTextarea,
} from 'components/formik/customer';
import { successToast } from 'utils/toast';

const requests = [
  { label: 'Update issue', value: 'Update issue' },
  { label: 'Delay in payment', value: 'Delay in payment' },
  { label: 'Incorrect communication', value: 'Incorrect communication' },
  { label: 'Unsubscription request', value: 'Unsubscription request' },
  { label: 'Restructuring request', value: 'Restructuring request' },
  { label: 'General query', value: 'General query' },
];

const marks = [
  { value: 0, label: 'Low' },
  { value: 50, label: 'Med' },
  { value: 100, label: 'High' },
];

interface AddRequestFormProps {
  editData?: any;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  request: yup.string().required('Required'),
  priority: yup.string().required('Required'),
  description: yup.string().required('Required'),
  comments: yup.string().required('Required'),
});

const AddRequestForm: React.FC<AddRequestFormProps> = ({
  editData,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { id: customerId = '' } = useParams<{ id: string }>();

  const odfId = useAppSelector(customerOdfID);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    try {
      let payload: any = {
        odf_customer_id: odfId,
        customer_id: customerId,
        priority: getPriority(+values.priority)!,
        category: editData?.category || 'Open',
        issue_title: values.request,
        issue_description: values.description,
        comments: [values.comments],
      };
      if (editData) payload.req_id = editData?.req_id;
      await dispatch(postCustomerRequests(payload));
      successToast('Request has been saved successfully');
      dispatch(setRefetchRequest(true));
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const defaultPriority = (priorityText: string) => {
    if (priorityText === 'Low') return 0;
    if (priorityText === 'Med') return 50;
    if (priorityText === 'High') return 100;
  };

  const getPriority = (priority: number) => {
    if (priority === 0) return 'Low';
    if (priority === 50) return 'Med';
    if (priority === 100) return 'High';
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={{
          request: editData?.issue_title || '',
          priority: defaultPriority(editData?.priority) || 0,
          description: editData?.issue_description || '',
          comments: editData?.comments?.[0] || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormikCustomerSelect
              name='request'
              label='Customer Request/Query Category'
              placeholder='Select Request'
              options={requests}
            />

            <PrioritySliderCont>
              <Typography>Priority</Typography>
              <PrioritySlider
                track={false}
                aria-labelledby='track-false-slider'
                defaultValue={0}
                step={50}
                marks={marks}
                // value={defaultPriority(values.priority)}
                value={values.priority}
                onChange={(event: any) => {
                  const { value } = event.target;
                  // let priority = 'Low';
                  // if (value === 50) priority = 'Med';
                  // if (value === 100) priority = 'High';
                  // setFieldValue('priority', priority);
                  setFieldValue('priority', value);
                }}
              />
            </PrioritySliderCont>

            <FormikCustomerTextarea
              name='description'
              label='Please mention the request/query here.'
              height='60px'
            />

            <FormikCustomerTextarea name='comments' label='Comments' />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Update</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddRequestForm;
