import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useParams } from 'react-router-dom';
import { TbDotsVertical } from 'react-icons/tb';
import useStyles from './style';

import { getDayTimePenetration } from 'store/reducer/campaignReducer/action';

import PenetrationGraph from './graph';
// import BasicSelect from 'components/Select';
import PenetrationFilter from './filter';
// import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import Loader from 'components/loader';
import PenetrationPagination from './pagination';
import {
  // campaignData,
  campaignExecutionId,
} from 'store/reducer/campaignReducer';
import useLocalStorage from 'hooks/useLocalStorage';

export type SlotDuration = 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;

const DayTimePenetration = ({
  selectedChannel,
}: {
  selectedChannel: string;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id: campaignId } = useParams();

  const channel = selectedChannel?.toLowerCase();

  const executionId = useAppSelector(campaignExecutionId);
  const { storageValue } = useLocalStorage('customer_execution_id', '');

  const [loading, setLoading] = React.useState(false);
  const [slot, setSlot] = React.useState<SlotDuration>(3);
  const [limit, setLimit] = React.useState<number>(7);
  const [offset, setOffset] = React.useState<number>(0);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);

  const fetchPenetration = React.useCallback(async () => {
    setLoading(true);
    try {
      const { distribution } = await dispatch(
        getDayTimePenetration({
          campaignId: campaignId!,
          channel,
          slot,
          limit,
          offset,
          executionId: executionId || storageValue,
        })
      ).unwrap();
      const total = Math.ceil(distribution.count / limit);
      setTotalPage(total);
    } finally {
      setLoading(false);
    }
  }, [
    campaignId,
    channel,
    dispatch,
    executionId,
    limit,
    offset,
    slot,
    storageValue,
  ]);

  React.useEffect(() => {
    if (campaignId) fetchPenetration();
  }, [campaignId, channel, limit, slot, offset, fetchPenetration]);

  const handlePagination = (action: 'forward' | 'backward') => {
    if (action === 'forward') {
      setOffset((prev) => prev + 7);
      setCurrentPage((prev) => prev + 1);
    }
    if (action === 'backward') {
      setOffset((prev) => prev - 7);
      setCurrentPage((prev) => prev - 1);
    }
  };

  const backwardDisable = React.useMemo(() => currentPage === 1, [currentPage]);
  const forwardDisable = React.useMemo(
    () => totalPage < 1 || currentPage === totalPage,
    [totalPage, currentPage]
  );

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.penetrationWrapper}>
        <Box className={classes.flexBetween} position='relative'>
          <Typography className={classes.titleText} fontWeight={500}>
            Day vs Time Penetration
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <PenetrationPagination
              handlePagination={handlePagination}
              backDisable={backwardDisable}
              forDisable={forwardDisable}
            />

            <button
              style={{ marginTop: '5px' }}
              onClick={() => setOpenFilter((prev) => !prev)}
            >
              <TbDotsVertical
                fontSize='1.2rem'
                color='rgba(50, 71, 92, 0.54)'
              />
            </button>
          </Box>

          {openFilter && (
            <PenetrationFilter
              slot={slot}
              setSlot={setSlot}
              limit={limit}
              setLimit={setLimit}
              setOpenFilter={setOpenFilter}
            />
          )}
        </Box>

        <PenetrationGraph slot={slot} />
      </Box>
    </>
  );
};

export default DayTimePenetration;
