import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    formWrapper: {
      height: '80vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    segmentListWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '0.5rem',
    },
    segmentCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 0.8rem',
    },
  })
);

export default useStyles;
