import { Box, Typography } from '@mui/material';
import { RiSendPlane2Fill } from 'react-icons/ri';

import useStyles from './index.styles';
import moment from 'moment';
import { FiChevronLeft } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';
import { BsCameraVideo, BsMic } from 'react-icons/bs';
import { IoCallOutline } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useParams } from 'react-router-dom';

interface WhatsappPreviewProps {
  selectedTemplate?: any;
  isText?: boolean;
  text?: string;
}

const WhatsappPreview: React.FC<WhatsappPreviewProps> = ({
  isText,
  selectedTemplate,
  text,
}) => {
  const { pathname } = useLocation();
  const pathUrl = pathname.split('/')[1];
  const { id } = useParams();
  const isCreate =
    pathUrl === 'template-management' &&
    (pathname === '/template-management/create' || id) &&
    true;
  const classes = useStyles({ isCreate });

  return (
    <Box className={classes.whatsappPreviewWrapper}>
      <Box className={classes.whatsappPreviewHeaderCont}>
        <Box className={classes.whatsappPreviewHeaderLeft}>
          <Box className={classes.whatsappPreviewHeaderBackIcon}>
            <FiChevronLeft fontSize='35px' />
            <Typography className={classes.whatsappPreviewHeaderBackIconText}>
              12
            </Typography>
          </Box>

          <Box className={classes.whatsappPreviewHeaderMiddleBox}>
            <Box className={classes.whatsappPreviewHeaderAvatarCont}>
              <img
                src='https://avatars.dicebear.com/api/bottts/rose.svg?mood[]=happy'
                alt=''
                width={25}
              />
            </Box>

            <Box>
              <Box className={classes.whatsappPreviewHeaderUserText}>
                <Typography fontWeight={600}>KollectAI</Typography>
                <MdVerified color='#25D366' />
              </Box>
              <Typography color='#898989' fontSize='0.8rem'>
                online
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.whatsappPreviewHeaderRight}>
          <BsCameraVideo color='#007AFF' fontSize='22px' />
          <IoCallOutline color='#007AFF' fontSize='22px' />
        </Box>
      </Box>

      <Box className={classes.whatsappPreviewMessageCont}>
        <Box className={classes.whatsappPreviewMessageBox}>
          <Box display='flex' justifyContent='center'>
            <Typography className={classes.whatsappPreviewTodayStyle}>
              Today
            </Typography>
          </Box>

          <Box maxWidth='90%' margin='0.8rem auto'>
            <Typography className={classes.whatsappPreviewLearnMoreStyle}>
              This chat is with the official business account of “KollectAI”.
              Tap to learn more.
            </Typography>
          </Box>

          <Box maxWidth='80%' position='relative'>
            <Box className={classes.whatsappPreviewMessage}>
              <Typography fontSize='0.9rem'>
                {isText ? text : selectedTemplate.template}
              </Typography>
            </Box>
            <Box className={classes.whatsappPreviewMessageTime}>
              <Typography fontSize='0.8rem' color='rgba(0, 0, 0, 0.15)'>
                {moment().format('h:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          background: '#F6F6F6',
          borderRadius: '0 0 10px 10px',
          paddingBottom: '3px',
        }}
      >
        <Box className={classes.whatsappPreviewInputCont}>
          <AiOutlinePlus fontSize='32px' />
          <input type='text' placeholder='Enter Message....' disabled />
          <button disabled>
            <RiSendPlane2Fill fontSize='22px' />
          </button>
          <BsMic fontSize='32px' />
        </Box>
        <Box className={classes.whatsappPreviewBottomLine}></Box>
      </Box>
    </Box>
  );
};

export default WhatsappPreview;
