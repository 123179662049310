import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { concatenatedTemplate } from 'utils/helper';
// import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';
import { useAppSelector } from 'hooks/useRedux';
import ChannelBox from './ChannelBox';
import TemplatePreviewModal from 'components/templates/templatePreview';

// const validationSchema = yup.object().shape({});

const SendCommunicationForm: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  // const [inititalState, setInititalState] = useState({});
  const [options, setOptions] = useState<any>({});
  const [selectedComm, setSelectedComm] = useState<any>({});
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>({ template: '', channel: '' });

  const emailTemplate = useAppSelector(
    (state: any) => state.template.emailTemplate
  );
  const smsTemplate = useAppSelector(
    (state: any) => state.template.smsTemplate
  );
  const ivrTemplate = useAppSelector(
    (state: any) => state.template.ivrTemplate
  );
  const whatsappTemplate = useAppSelector(
    (state: any) => state.template.whatsappTemplate
  );

  useEffect(() => {
    if (value) {
      // setInititalState({ category: value });
      setSelectedComm(value);
    }
  }, [value]);

  useEffect(() => {
    let opts: any = {};
    [
      { channel: 'email', template: emailTemplate },
      { channel: 'sms', template: smsTemplate },
      { channel: 'whatsapp', template: whatsappTemplate },
      { channel: 'ivr', template: ivrTemplate },
    ].forEach((el: { channel: string; template: any }) => {
      opts = {
        ...opts,
        [el.channel]: getTemplateOptions(el.template),
      };
    });
    setOptions(opts);
  }, [emailTemplate, smsTemplate, ivrTemplate, whatsappTemplate]);

  const getTemplateOptions = (template: any) => {
    return concatenatedTemplate(template).map((item: any) => ({
      label: item.template_name,
      value: item.id,
    }));
  };

  const handleSubmit = (values: any) => {
    setFieldValue(actionPath, 'send_another_communication');
    setFieldValue(path, selectedComm);
    onClose();
  };

  const handleChannel = (channel: string) => {
    if (channel in selectedComm) {
      let commCopy = { ...selectedComm };
      delete commCopy[channel];
      setSelectedComm(commCopy);
    } else {
      setSelectedComm((prev: any) => ({ ...prev, [channel]: '' }));
    }
  };

  const handleTemplate = (channel: string, templateId: string) => {
    setSelectedComm((prev: any) => ({ ...prev, [channel]: templateId }));
  };

  const handlePreview = (channel: string) => {
    if (channel in selectedComm && selectedComm[channel]) {
      let tempArr =
        channel === 'email'
          ? emailTemplate
          : channel === 'sms'
          ? smsTemplate
          : channel === 'ivr'
          ? ivrTemplate
          : whatsappTemplate;
      const temp = concatenatedTemplate(tempArr).find(
        (item: any) => item.id === selectedComm[channel]
      );
      if (temp) {
        setPreview({ template: temp, channel });
        setOpenPreview(true);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{}}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box>
            <Typography mb='1rem'>
              Please select the channel and template to trigger
            </Typography>

            <Box display='flex' alignItems='start' gap='1rem'>
              <ChannelBox
                channel='email'
                options={options['email']}
                handleChannel={handleChannel}
                handleTemplate={handleTemplate}
                selectedComm={selectedComm}
                handlePreview={handlePreview}
              />
              <ChannelBox
                channel='sms'
                options={options['sms']}
                handleChannel={handleChannel}
                handleTemplate={handleTemplate}
                selectedComm={selectedComm}
                handlePreview={handlePreview}
              />
              <ChannelBox
                channel='whatsapp'
                options={options['whatsapp']}
                handleChannel={handleChannel}
                handleTemplate={handleTemplate}
                selectedComm={selectedComm}
                handlePreview={handlePreview}
              />
              <ChannelBox
                channel='ivr'
                options={options['ivr']}
                handleChannel={handleChannel}
                handleTemplate={handleTemplate}
                selectedComm={selectedComm}
                handlePreview={handlePreview}
              />
            </Box>
          </Box>

          <ActionButtonWrapper>
            <ActionButton type='submit' color='#6AC5AB'>
              <Typography>Save</Typography>
            </ActionButton>
          </ActionButtonWrapper>
        </Form>
      </Formik>

      <TemplatePreviewModal
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setPreview({ template: '', channel: '' });
        }}
        selectedOption={preview.template}
        channel={preview.channel}
      />
    </>
  );
};

export default SendCommunicationForm;
