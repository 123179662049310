import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    formWrapper: {
      display: 'flex',
      alignItems: 'start',
      gap: '1rem',
      margin: '1rem 0',
      justifyContent: 'center',
    },
    dateWrapper: {
      gap: '1rem',
      '& .MuiSvgIcon-root': {
        color: '#004357',
        fontSize: '16px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '13px',
      },
    },
    conditionCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 0.8rem',
      cursor: 'pointer',
    },
    noFilterCont: {
      minHeight: '100px',
      width: '100%',
      display: 'grid',
      placeItems: 'center',
    },
    saveButtonCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '2rem',
    },
    saveButtonStyle: {
      padding: '10px 2rem',
      borderRadius: '4px',
      color: '#fff',
    },
  })
);

export default useStyles;
