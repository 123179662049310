import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import TuneIcon from '@mui/icons-material/Tune';

import RequestDragAndDropColumn from './Column';
import { useParams } from 'react-router-dom';
import {
  getCustomerRequests,
  postCustomerRequests,
} from 'store/reducer/customerReducer/action';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setRefetchRequest } from 'store/reducer/customerReducer';
import CustomerRequestFilter from './filter';
import useStyles from './index.styles';

const initialData = {
  requests: {},
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Open',
      color: '#5030E5',
      requestIds: [],
    },
    'column-2': {
      id: 'column-2',
      title: 'Closed',
      color: '#7EA131',
      requestIds: [],
    },
    'column-3': {
      id: 'column-3',
      color: '#FFC145',
      title: 'On-Hold',
      requestIds: [],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2', 'column-3'],
};

const reorderColumnList = (sourceCol: any, startIndex: any, endIndex: any) => {
  const newRequestIds = Array.from(sourceCol.requestIds);
  const [removed] = newRequestIds.splice(startIndex, 1);
  newRequestIds.splice(endIndex, 0, removed);

  const newColumn = {
    ...sourceCol,
    requestIds: newRequestIds,
  };

  return newColumn;
};

const CustomerRequests = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { customerRequests } = useAppSelector((state) => state.customer);
  const { refetchRequest } = useAppSelector((state) => state.customer);

  const [state, setState] = useState<any>(initialData);
  // const [loading, setLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [category, setCategory] = React.useState<string>('');
  const [priority, setPriority] = React.useState<string>('');
  const [date, setDate] = React.useState<Date | null>(null);

  const { id: customerId } = useParams();

  useEffect(() => {
    getRequests(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    refetchRequest && getRequests(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchRequest]);

  const getRequests = async (state: any) => {
    if (customerId) {
      // setLoading(true);
      const { results } = await dispatch(
        getCustomerRequests({ customerId })
      ).unwrap();

      updateState(results, state);

      dispatch(setRefetchRequest(false));
      // setLoading(false);
    }
  };

  const updateState = (res: any, state: any) => {
    let formattedRequests: any = {};
    let updatedColumns: any = { ...state?.columns };

    res?.forEach(function (element: any, index: any) {
      formattedRequests[index + 1] = element;
      for (const columnKey in state.columns) {
        if (updatedColumns[columnKey].title === element?.category) {
          updatedColumns[columnKey] = {
            ...updatedColumns[columnKey],
            requestIds: [...updatedColumns[columnKey]?.requestIds, index + 1],
          };
        }
      }
    });

    if (
      Object.keys(formattedRequests).length &&
      Object.keys(updatedColumns).length
    ) {
      setState({
        ...state,
        columns: updatedColumns,
        requests: formattedRequests,
      });
    } else {
      setState({ ...state });
    }
  };

  const onDragEnd = async (result: any) => {
    const { destination, source, draggableId } = result;

    const selectedCustomerRequest: any = customerRequests?.find(
      (request: any) => request.req_id === draggableId
    );

    // If user tries to drop in an unknown destination
    if (!destination) return;

    // if the user drags and drops back in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // If the user drops within the same column but in a different positoin
    const sourceCol = state.columns[source.droppableId];
    const destinationCol = state.columns[destination.droppableId];

    if (sourceCol.id === destinationCol.id) {
      const newColumn = reorderColumnList(
        sourceCol,
        source.index,
        destination.index
      );

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
      setState(newState);
      if (selectedCustomerRequest && selectedCustomerRequest !== -1) {
        await dispatch(
          postCustomerRequests({
            req_id: selectedCustomerRequest?.req_id,
            // client_id: selectedCustomerRequest?.client_id,
            odf_customer_id: selectedCustomerRequest?.odf_customer_id,
            customer_id: selectedCustomerRequest?.customer_id,
            priority: selectedCustomerRequest?.priority,
            category:
              selectedCustomerRequest?.state.columns[destination.droppableId]
                ?.title,
            issue_title: selectedCustomerRequest?.issue_title,
            issue_description: selectedCustomerRequest?.issue_description,
          })
        );
        dispatch(setRefetchRequest(true));
      }
      return;
    }

    // If the user moves from one column to another
    const startRequestIds = Array.from(sourceCol.requestIds);
    const [removed] = startRequestIds.splice(source.index, 1);
    const newStartCol = {
      ...sourceCol,
      requestIds: startRequestIds,
    };

    const endRequestIds = Array.from(destinationCol.requestIds);
    endRequestIds.splice(destination.index, 0, removed);
    const newEndCol = {
      ...destinationCol,
      requestIds: endRequestIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      },
    };

    setState(newState);
    if (selectedCustomerRequest && selectedCustomerRequest !== -1) {
      await dispatch(
        postCustomerRequests({
          req_id: selectedCustomerRequest?.req_id,
          // client_id: selectedCustomerRequest?.client_id,
          odf_customer_id: selectedCustomerRequest?.odf_customer_id,
          customer_id: selectedCustomerRequest?.customer_id,
          priority: selectedCustomerRequest?.priority,
          category: state.columns[destination.droppableId]?.title,
          issue_title: selectedCustomerRequest?.issue_title,
          issue_description: selectedCustomerRequest?.issue_description,
        })
      );
      dispatch(setRefetchRequest(true));
    }
  };

  return (
    <Box my='1rem'>
      <Box className={classes.requestFilter}>
        <Typography>Most recent at the top</Typography>

        <Box sx={{ position: 'relative' }}>
          <button onClick={() => setShowFilter((prev) => !prev)}>
            <TuneIcon />
          </button>
          {showFilter ? (
            <CustomerRequestFilter
              onClose={() => setShowFilter(false)}
              updateState={(res) => updateState(res, initialData)}
              category={category}
              priority={priority}
              date={date}
              setCategory={setCategory}
              setPriority={setPriority}
              setDate={setDate}
            />
          ) : null}
        </Box>
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          {state.columnOrder.map((columnId: string) => {
            const column = state.columns[columnId];
            const requests = column.requestIds?.length
              ? column.requestIds.map(
                  (requestId: string) => state.requests[requestId]
                )
              : [];

            return (
              <Grid item xs={4} key={column.id}>
                <RequestDragAndDropColumn column={column} requests={requests} />
              </Grid>
            );
          })}
        </Grid>
      </DragDropContext>
    </Box>
  );
};

export default CustomerRequests;
