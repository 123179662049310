import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormikCustomerSelect } from 'components/formik/customer';
import {
  getAllWorkflow,
  getWorkflowById,
} from 'store/reducer/workflowReducer/action';
import Loader from 'components/loader';
import CustomIcon from 'components/customIcons';
import { getStrategyList } from 'store/reducer/strategyReducer/action';
import { FormPropsType } from '../..';

const validationSchema = yup.object().shape({
  workflow: yup.string().required('Required'),
});

const RunWorkflowForm: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  const dispatch = useAppDispatch();

  const [inititalState, setInititalState] = useState<FormikValues>({
    workflow: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (value) {
      setInititalState({ workflow: value });
    }
  }, [value]);

  useEffect(() => {
    getWorkflows();
  }, []);

  const getWorkflows = async () => {
    setLoading(true);
    const { data, message } = await dispatch(getAllWorkflow()).unwrap();
    if (message === 'success') {
      const publishedWorkflows = data.filter(
        (workflow: any) => workflow.published
      );
      const opts = publishedWorkflows.map((item: any) => ({
        label: item.workflow_name,
        value: item.workflow_id,
      }));
      setOptions(opts);
    }
    setLoading(false);
  };

  const handleSubmit = (values: any) => {
    setFieldValue(actionPath, 'run_workflow');
    setFieldValue(path, values.workflow);
    onClose();
  };

  const handleView = async (values: FormikValues) => {
    const { workflow } = values;
    if (workflow) {
      setLoading(true);
      await dispatch(getWorkflowById({ workflow_id: workflow })).unwrap();
      await dispatch(getStrategyList());
      window.open('/workflow-editor', '_blank')?.focus();
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Box>
              <Typography mb='1rem'>
                Please select the workflow to trigger for the customer
              </Typography>

              <Box display='flex' alignItems='center' gap='10px'>
                <FormikCustomerSelect
                  name='workflow'
                  placeholder='Select Workflow'
                  options={options}
                />
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleView(values)}
                >
                  <CustomIcon name='share' color='#49F0AC' size={30} />
                </Box>
              </Box>
            </Box>

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RunWorkflowForm;
