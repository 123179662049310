import React from 'react';
import { Typography } from '@mui/material';
import { FormikValues } from 'formik';
import { Button } from './index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { MdDeleteForever } from 'react-icons/md';
import { toggleDrawer } from 'store/reducer/workflowReducer';

const CheckActionButtons = ({
  values,
  handleDelete,
}: {
  values: FormikValues;
  handleDelete: () => void;
}) => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(userPermissions);

  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  return (
    <>
      {permissions[MODULELIST.WORKFLOW].includes('update') && (
        <Button
          type='submit'
          backgroundColor={
            !values.check || values.check.length < 1 ? '#6F6F6F' : '#6ac5ab'
          }
          disabled={
            (!values.check && true) || (values.check.length < 1 && true)
          }
        >
          <Typography>Update</Typography>
        </Button>
      )}
      {permissions[MODULELIST.WORKFLOW].includes('delete') &&
      workflowEditor === 'edit' ? (
        <Button type='button' backgroundColor='#daa09a' onClick={handleDelete}>
          <MdDeleteForever fontSize={20} color='#fff' />
          <Typography>Delete</Typography>
        </Button>
      ) : (
        <Button
          type='button'
          backgroundColor='#daa09a'
          onClick={() => dispatch(toggleDrawer(false))}
        >
          <Typography>Discard</Typography>
        </Button>
      )}
    </>
  );
};

export default CheckActionButtons;
