import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../../index.styles';
import { AiFillDelete, AiFillPlusCircle } from 'react-icons/ai';

import BasicModal from 'components/Modal';
import FilterModal from './FilterModal';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { deleteCustomFilter } from 'store/reducer/campaignReducer/action';
import { customFilterData } from 'utils/campaign/campaignModifier';
import {
  setCampaignPayload,
  // setCustomFilter,
} from 'store/reducer/campaignReducer';
import Loader from 'components/loader';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
// import { useParams } from 'react-router-dom';

const AddCustomFilter = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const { id: campaignId } = useParams();

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );
  // const campaignPayload = useAppSelector(
  //   (state: any) => state.campaign.campaignPayload
  // );
  const customFilters = useAppSelector(
    (state: any) => state.campaign.customFilter
  );
  const permissions = useAppSelector(userPermissions);

  const [rows, setRows] = React.useState<any>([
    { customers: null, outstanding: null, avg_dpd: null, condition: '' },
  ]);
  const [openAdd, setOpenAdd] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDelete = async (groupId: string) => {
    try {
      setLoading(true);
      const payload = {
        campaign_id: campaignData?.campaign_id,
        group_id: groupId,
      };

      await dispatch(deleteCustomFilter(payload));
      // const { meta, data } = await dispatch(
      //   deleteCustomFilter(payload)
      // ).unwrap();
      // if (meta?.success) {
      //   const filterData = customFilterData(data);
      //   console.log({ filterData });
      //   const noRestDeleteData = rows.filter(
      //     (row: any) => row.group_id !== groupId && row.condition !== 'rest'
      //   );
      //   console.log({ noRestDeleteData });
      //   const value = [...noRestDeleteData, ...filterData];
      //   dispatch(setCustomFilter(value));
      // dispatch(
      //   setCampaignPayload({
      //     key: 'add_filter',
      //     data: { filter: 'add_custom', value },
      //   })
      // );
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // if (campaignPayload) {
    // if (campaignPayload?.add_filter?.value.length) {
    //   const newRows = campaignPayload?.add_filter?.value;
    //   setRows(newRows);
    // } else {
    let newRows: any = [];
    if (customFilters) {
      newRows = customFilterData(customFilters);
      dispatch(
        setCampaignPayload({
          key: 'add_filter',
          data: { filter: 'add_custom', value: newRows },
        })
      );
    } else {
      const groupAsOne = campaignData?.group_as_one;
      newRows = [
        {
          '#customers': groupAsOne?.['#customers'],
          outstanding_balance: groupAsOne?.outstanding_balance,
          average_dpd: groupAsOne?.average_dpd,
          condition: 'All',
        },
      ];
    }
    // console.log({ newRows });
    setRows(newRows);
    // }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilters]);
  // }, [campaignPayload, campaignData, customFilters]);

  return (
    <>
      <Loader loading={loading} />
      <Box mt='1rem'>
        <Typography>
          Will allow you to filter your population into specific groups based on
          custom filters (such as population having balance greater than 1000)
          and have specific channels and templates for each group. It it similar
          to ‘Split into Segments’ but here the selection of filter can be
          customised & not just based on pre-defined segments.
        </Typography>

        <Box ml='5rem' mt='2rem'>
          <table className={classes.tableStyle}>
            <tr>
              <th></th>
              <th>
                <Typography># Customers</Typography>
              </th>
              <th>
                <Typography>$ Outstanding</Typography>
              </th>
              <th>
                <Typography>Average DPD</Typography>
              </th>
              <th>
                <Typography>Condition</Typography>
              </th>
              <th></th>
            </tr>
            {rows.map((row: any, index: number) => {
              return (
                <tr>
                  <td>
                    <Typography>{index + 1}.</Typography>
                  </td>
                  <td>
                    <Typography>{row?.['#customers']}</Typography>
                  </td>
                  <td>
                    <Typography>
                      {row?.outstanding_balance
                        ? `$ ${row.outstanding_balance.toLocaleString()}`
                        : 0}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{row.average_dpd}</Typography>
                  </td>
                  <td>
                    <Typography>{row.condition}</Typography>
                  </td>
                  <td>
                    {permissions[MODULELIST.CAMPAIGN].includes('update') && (
                      <>
                        {index === rows.length - 1 && (
                          <button onClick={() => setOpenAdd(true)}>
                            <AiFillPlusCircle
                              color='#6AC5AB'
                              fontSize='1.8rem'
                            />
                          </button>
                        )}
                        {rows.length !== 1 && index !== rows.length - 1 && (
                          <button onClick={() => handleDelete(row.group_id)}>
                            <AiFillDelete color='#BB7065' fontSize='1.8rem' />
                          </button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </Box>
      </Box>

      <BasicModal
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        children={<FilterModal rows={rows} onClose={() => setOpenAdd(false)} />}
      />
    </>
  );
};

export default AddCustomFilter;
