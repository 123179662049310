import { createAsyncThunk } from '@reduxjs/toolkit';

import dashboardApi from 'apis/dashboard';
import dashboardEndpoint from 'apis/dashboard/config';

// export const dashboardPortfolio = createAsyncThunk(
//   `${dashboardEndpoint.dashboardProtfolio}Post`,
//   async (payload: any) => {
//     const { data } = await dashboardApi.dashboardPortfolio(payload);
//     return data;
//   }
// );

export const dashboardPortfolio = createAsyncThunk(
  `${dashboardEndpoint.dashboardProtfolio}Post`,
  async (payload: any) => {
    const { data } = await dashboardApi.dashboardPortfolio(payload);
    return data;
  }
);
