import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import { FormikAuthPassword } from 'components/formik/auth';
import { IoChevronBackOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useRedux';
import { resetPassword } from 'store/reducer/auth/actions';
import Loader from 'components/loader';
import { successToast } from 'utils/toast';

const UpdateButton = styled('button')(() => ({
  width: '100%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const BackToLoginButton = styled('button')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  marginTop: '1rem',
  color: '#004357',
  '& svg': { color: '#004357', fontSize: '1.2rem' },
}));

const validationSchema = yup.object().shape({
  password: yup.string().required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords doesnot match')
    .required('Required'),
});

const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    try {
      const payload = {
        new_password: values.password,
        confirm_new_password: values.confirmPassword,
        token,
      };
      const { meta } = await dispatch(resetPassword(payload)).unwrap();
      if (meta?.success) {
        successToast(meta?.message);
        navigate('/login');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box width='55%'>
        <Box mb='2rem'>
          <Typography
            fontWeight={600}
            color='rgba(50, 71, 92, 0.87)'
            fontSize='1.4rem'
          >
            Reset Password 🔒
          </Typography>
          <Typography mt='5px' color='rgba(50, 71, 92, 0.6)'>
            Your new password must be different from previously used passwords
          </Typography>
        </Box>

        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <FormikAuthPassword
                name='password'
                placeholder='New Password'
                show={showPassword}
                handleShow={() => setShowPassword(!showPassword)}
              />

              <Box mt='1rem' mb='1rem'>
                <FormikAuthPassword
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  show={showConfirm}
                  handleShow={() => setShowConfirm(!showConfirm)}
                />
              </Box>

              <UpdateButton onClick={() => {}}>
                <Typography>Update Password</Typography>
              </UpdateButton>

              <BackToLoginButton onClick={() => navigate('/login')}>
                <IoChevronBackOutline />
                <Typography>Back to login</Typography>
              </BackToLoginButton>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ResetPasswordScreen;
