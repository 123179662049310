import { Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import useStyles from './index.styles';

interface IDeleteStrategyProps {
  strategyNames: Array<string>;
  close: () => void;
  handleDeleteStrategy: () => void;
}
const DeleteStrategy: React.FC<IDeleteStrategyProps> = ({
  strategyNames,
  close,
  handleDeleteStrategy,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.title}>Are you sure?</Typography>
      <Typography className={classes.description}>
        Please review and click confirm if you want to continue to delete below
        strategies:
      </Typography>

      <Box className={classes.listContainer}>
        {strategyNames.map((name) => (
          <Typography
            className={classes.description}
            style={{ paddingLeft: 12 }}
            key={`delete-strategy-${name}`}
          >
            <li>{name}</li>
          </Typography>
        ))}
      </Box>

      <Box className={classes.buttonContainer}>
        <button
          type='submit'
          className={classes.confirmButton}
          onClick={handleDeleteStrategy}
        >
          Confirm
        </button>
        <button type='submit' className={classes.cancelButton} onClick={close}>
          Cancel
        </button>
      </Box>
    </Box>
  );
};

export default DeleteStrategy;
