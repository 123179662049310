import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const IntegrationCont = styled('div')(
  ({ isIntegration }: { isIntegration: boolean }) => ({
    display: 'flex',
    height: '70px',
    padding: '0 1rem',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    background: isIntegration ? 'rgb(255, 255, 255, 0.5)' : 'transparent',
    '& p': { textDecoration: 'underline', color: '#fff' },
  })
);

export const IntegrationLine = styled('div')(() => ({
  background: 'rgba(255, 255, 255, 0.2)',
  width: '1px',
  height: '70px',
  filter: 'drop-shadow(-1px 0px 0px rgba(0, 0, 0, 0.251))',
}));

export const UserButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  '& p': { color: '#fff' },
}));

export const UserRole = styled('div')(() => ({
  display: 'flex',
  width: 'auto',
  padding: '26px 20px',
  marginRight: '0px',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  '& p': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 200,
    backgroundColor: kollectAITheme.secondaryColor,
    borderRadius: '5px',
    padding: '4px 10px',
    height: '28px',
    alignItems: 'center',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerMainCont: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.kollectAITheme.primaryColor,
      padding: '0px 10px',
      boxShadow: '0px 3px 6px #272D3B33',
      justifyContent: 'space-between',
      height: '70px',
      left: '240px',
      top: '0px',
      position: 'sticky',
      zIndex: 90,
    },
    headerLeftCont: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: 'white',
    },
    headerItemContButtonStyle: {
      width: 'auto',
      borderRadius: '0px',
    },
    headerItemStyle: {
      color: '#white',
      fontSize: '16px',
      fontWeight: 600,
    },
    headerItemDropdownContStyle: {
      position: 'absolute',
      top: '70px',
      left: '40px',
      zIndex: 100,
      backgroundColor: '#CBD9F7',
      width: 'max-content',
      fontFamily: 'Overpass',
      fontSize: '16px',
      lineHeight: 1.2,
      fontWeight: 'bold',
      color: theme.kollectAITheme.primaryColor,
      display: 'flex',
      flexDirection: 'column',
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    headerItemStrategyDropdownStyle: {
      left: '215px',
    },
    headerItemDropdownButtonStyle: {
      width: 'auto',
      borderRadius: '0px',
      justifyContent: 'flex-start',
      backgroundColor: 'transparent',
      color: theme.kollectAITheme.darkBlue,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.kollectAITheme.darkBlue,
      },
      '&.MuiButton-root.Mui-disabled': {
        backgroundColor: 'transparent',
        color: theme.kollectAITheme.darkBlue,
      },
    },
    headerItemDropdownLineStyle: {
      height: '1px',
      width: '100%',
      backgroundColor: '#B4C7EF',
    },
    headerItemActiveLineStyle: {
      position: 'absolute',
      height: '3px',
      bottom: '0px',
      left: '10%',
      right: '10%',
      borderRadius: '180px',
      backgroundColor: '#ffffff',
    },
    headerRightCont: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      padding: '26px 0px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    headerItemMsgContStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'white',
      fontSize: '16px',
      fontWeight: 200,
      backgroundColor: '#5681F0',
      borderRadius: '5px',
      padding: '0px 6px 0px 6px',
      height: '28px',
      alignItems: 'center',
    },
    headerIconButtonStyle: {
      height: '40px',
      width: '40px',
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
      marginLeft: '20px',
    },
    headerIconUserButtonStyle: {
      height: '40px',
      width: '40px',
      borderRadius: '50px',
      backgroundColor: '#5681F0',
      outline: 'none',
      border: 'none',
      marginLeft: '20px',
    },
    headerItemUserNameAdminStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: 'white',
      paddingLeft: '5px',
    },
    headerLogoLink: {
      marginLeft: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      gap: '10px',
    },
    headerLogoText: {
      color: '#fff',
      textTransform: 'uppercase',
    },
    avatarClickOptions: {
      top: 50,
      right: 0,
      width: 200,
      display: 'flex',
      flexDirection: 'column',
      padding: '0.8rem',
      zIndex: 400,
      position: 'absolute',
      boxShadow:
        '0 4px 8px -2px rgb(9 30 66 / 25%), 0 0 1px rgb(9 30 66 / 31%)',
      borderRadius: '6px',
      backgroundColor: '#fff',
      alignItems: 'start',
      gap: '6px',
      '& button:hover': { textDecoration: 'underline', color: '#004357' },
    },
  })
);

export default useStyles;
