import { Box, Typography } from '@mui/material';

const ViewCustomerData = ({ data }: { data: any }) => {
  return (
    <Box
      sx={{
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'auto',
        minWidth: 650,
      }}
    >
      <Typography
        textAlign='center'
        fontWeight={600}
        mb='1rem'
        fontSize='1.2rem'
      >
        Customer Details
      </Typography>

      {data && Object.keys(data) && (
        <>
          {Object.entries(data).map((item: any) => (
            <Box
              display='flex'
              alignItems='center'
              gap='1rem'
              mb='0.8rem'
              key={item.customer_id}
            >
              <Typography width={180}>{item[0]}: </Typography>
              <Typography>
                {item[1] === null ? '-' : JSON.stringify(item[1])}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default ViewCustomerData;
