import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStyles from './index.styles';

import {
  addChannelTemplate,
  updateChannelTemplate,
} from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import {
  getBasedOn,
  getRequestGroups,
  getScheduleTime,
} from 'utils/campaign/campaignModifier';
import CampaignSummaryDetails from 'components/campaigns/summary/summary';

const CampaignSummary = ({ onClose }: { onClose: () => void }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );
  const campaignAction = useAppSelector(
    (state) => state.campaign.campaignActionType
  );

  const handleConfirm = async () => {
    setLoading(true);
    try {
      // const filter = campaignPayload?.add_filter?.filter;
      // const campaign_id = campaignData?.campaign_id;
      const groups = getRequestGroups(campaignPayload?.select_channel_template);
      const based_on = getBasedOn(campaignPayload);
      // const schedule_time = getScheduleTime(campaignPayload?.schedule_time);
      const schedule_time =
        campaignPayload?.schedule_time !== 'now'
          ? getScheduleTime(campaignPayload?.schedule_time)
          : 'now';
      let payload = {
        campaign_id: campaignData?.campaign_id,
        based_on,
        groups,
        // schedule_time: campaignPayload?.schedule_time,
        schedule_time,
        save_as: 'submit',
      };
      if (campaignAction === 'edit' && campaignData?.channel_count > 0) {
        await dispatch(updateChannelTemplate(payload));
      } else {
        await dispatch(addChannelTemplate(payload));
      }
      // if (filter === 'ab_testing') {
      //   let dist: any = { A: '', B: '' };
      //   const value = campaignPayload?.add_filter?.value;
      //   for (let key in value) {
      //     let data = value[key];
      //     dist[key] = data?.distribution;
      //   }
      //   await dispatch(
      //     updateAbTesting({ campaign_id, a_dist: dist.A, b_dist: dist.B })
      //   );
      // }
      onClose();
      navigate('/campaigns');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.summaryWrapper}>
        <Typography
          fontSize='1.2rem'
          color='#004357'
          align='center'
          fontWeight={600}
        >
          Summary
        </Typography>

        <Box>
          <CampaignSummaryDetails />

          <Box className={classes.saveButtonCont}>
            <button style={{}} onClick={handleConfirm}>
              <Typography>Review and Confirm</Typography>
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CampaignSummary;
