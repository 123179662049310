import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { FiSearch } from 'react-icons/fi';

import { useAppSelector } from 'hooks/useRedux';
import { availableTemplateAttributes } from 'store/reducer/templateReducer';
import { concatenatedTemplate } from 'utils/helper';
import AvailableAttributeTable from './table';
// import { availableAttributes } from './data';

const TemplateAvailableAttributes = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [type, setType] = useState<string>('');
  const [availableAttributes, setavailableAttributes] = useState<any>({
    pii: [],
    non_pii: [],
  });

  // const attributes: any = { ...availableAttributes };
  const templateAttributes = useAppSelector(availableTemplateAttributes);
  // console.log({ templateAttributes });

  // useEffect(() => {
  //   const attributes = concatenatedTemplate(availableAttributes);
  //   setData(attributes);
  // }, []);

  useEffect(() => {
    if (templateAttributes.length) {
      const pii = templateAttributes.filter(
        (attribute: any) => attribute.attribute_type === 'pii'
      );
      const non_pii = templateAttributes.filter(
        (attribute: any) => attribute.attribute_type === 'non_pii'
      );
      // console.log({ pii, non_pii });
      const attributes = { pii, non_pii };
      setavailableAttributes(attributes);
      const concatenatedAttributes = concatenatedTemplate(attributes);
      setData(concatenatedAttributes);
    }
  }, [templateAttributes]);

  const debounced = useDebouncedCallback((value) => {
    const attributes = concatenatedTemplate(availableAttributes);
    let filteredData = attributes.filter((attribute: any) =>
      attribute.attribute.toLowerCase().includes(value.toLowerCase())
    );
    setData(filteredData);
    setSearchValue(value);
  }, 0);

  const handlePiiNonPii = (attributeType: string) => {
    const concatenatedAttibutes = concatenatedTemplate(availableAttributes);
    const values =
      type === attributeType
        ? concatenatedAttibutes
        : // : attributes[attributeType];
          availableAttributes[attributeType];
    setData(values);
    const attType = type === attributeType ? '' : attributeType;
    setType(attType);
    setSearchValue('');
  };

  return (
    <Box sx={{ '& p': { fontFamily: 'Montserrat' } }}>
      <Typography
        sx={{
          fontSize: '1.2rem',
          fontWeight: 700,
          color: '#6F6F6F',
        }}
      >
        Available Attributes
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #6F6F6F',
            borderRadius: '5px',
            height: '35px',
            padding: '0 10px',
            '& input': {
              border: 'transparent',
              background: 'transparent',
              outline: 'none',
              height: '32px',
              fontSize: '1rem',
              paddingLeft: '10px',
              fontFamily: 'Montserrat',
            },
          }}
        >
          <FiSearch />
          <input
            type='text'
            placeholder='Search Attribute'
            value={searchValue}
            onChange={(e) => debounced(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <Box>
            <Box display='flex' justifyContent='center' mb='5px'>
              <Box
                sx={{
                  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  background: type === 'pii' ? '#004357' : '#0069AA',
                  borderRadius: '10px',
                  color: '#fff',
                  display: 'inline-block',
                  padding: '5px 2rem',
                  cursor: 'pointer',
                }}
                onClick={() => handlePiiNonPii('pii')}
              >
                <Typography>PII</Typography>
              </Box>
            </Box>
            <Typography align='center'>
              To be declared as <br />
              {`{{pii.attributeName}}`}
            </Typography>
          </Box>
          <Box>
            <Box display='flex' justifyContent='center' mb='5px'>
              <Box
                sx={{
                  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  background: type === 'non_pii' ? '#004357' : '#0069AA',
                  borderRadius: '10px',
                  color: '#fff',
                  display: 'inline-block',
                  padding: '5px 1rem',
                  cursor: 'pointer',
                }}
                onClick={() => handlePiiNonPii('non_pii')}
              >
                <Typography>Non PII</Typography>
              </Box>
            </Box>
            <Typography align='center'>
              To be declared as <br />
              {`{{attributeName}}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <AvailableAttributeTable data={data} />
    </Box>
  );
};

export default TemplateAvailableAttributes;
