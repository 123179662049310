import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikCustomerCheckbox } from 'components/formik/customer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';
import FormikCustomerDaySelector from 'components/formik/customer/FormikCustomerDaySelector';
import FormikCustomerTimeSlot from 'components/formik/customer/FormikCustomerTimeSlot';
import FormikTemplateActionSelect from 'components/formik/template/FormikActionSelect';

const callOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const validationSchema = yup.object().shape({
  wantCallPrefer: yup.string().required('Required'),
  wantDayPrefer: yup.boolean(),
  prefferedDay: yup
    .array()
    .nullable()
    .when('wantDayPrefer', {
      is: true,
      then: yup.array().min(1, 'Required').required('Required'),
    }),
  wantTimeSlotPrefer: yup.boolean(),
  prefferedTimeSlot: yup
    .array()
    .nullable()
    .when('wantTimeSlotPrefer', {
      is: true,
      then: yup.array().min(1, 'Required').required('Required'),
    }),
});

const UpdateCallRequiredForm: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  const [inititalState, setInititalState] = useState({
    wantCallPrefer: 'yes',
    wantDayPrefer: false,
    prefferedDay: [],
    wantTimeSlotPrefer: false,
    prefferedTimeSlot: [],
  });

  useEffect(() => {
    if (value) {
      let payload = {
        wantCallPrefer: 'yes',
        wantDayPrefer: false,
        prefferedDay: [],
        wantTimeSlotPrefer: false,
        prefferedTimeSlot: [],
      };
      if ('day' in value) {
        payload = {
          ...payload,
          wantDayPrefer: true,
          prefferedDay: value.day,
        };
      }
      if ('time_slot' in value) {
        payload = {
          ...payload,
          wantTimeSlotPrefer: true,
          prefferedTimeSlot: value.time_slot,
        };
      }
      setInititalState(payload);
    }
  }, [value]);

  const handleSubmit = (values: any) => {
    const {
      wantCallPrefer,
      wantDayPrefer,
      prefferedDay,
      wantTimeSlotPrefer,
      prefferedTimeSlot,
    } = values;
    let payload = {};
    if (wantCallPrefer === 'yes') {
      if (wantDayPrefer) {
        payload = { ...payload, day: prefferedDay };
      }
      if (wantTimeSlotPrefer) {
        payload = { ...payload, time_slot: prefferedTimeSlot };
      }
    }
    setFieldValue(actionPath, 'update_call_required_details');
    setFieldValue(path, payload);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormikTemplateActionSelect
              label='Want to specify call preference for day or time slot?'
              name='wantCallPrefer'
              options={callOptions}
            />

            {values.wantCallPrefer === 'yes' && (
              <>
                <Box display='flex' alignItems='center' mb='1rem'>
                  <Box mt='1rem' width={350}>
                    <FormikCustomerCheckbox
                      name='wantDayPrefer'
                      label='Want to put day preference'
                    />
                  </Box>
                  {values?.wantDayPrefer && (
                    <Box>
                      <FormikCustomerDaySelector name='prefferedDay' />
                    </Box>
                  )}
                </Box>

                <Box display='flex' alignItems='center'>
                  <Box mt='1rem' width={350}>
                    <FormikCustomerCheckbox
                      name='wantTimeSlotPrefer'
                      label='Want to put time slot preference'
                    />
                  </Box>
                  {values?.wantTimeSlotPrefer && (
                    <FormikCustomerTimeSlot name='prefferedTimeSlot' />
                  )}
                </Box>
              </>
            )}

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateCallRequiredForm;
