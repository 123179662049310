import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmButton: {
      borderRadius: '8px',
      border: 'transparent',
      color: theme.kollectAITheme.white,
      fontSize: '1rem',
      padding: '0.625rem 4.5rem',
      background: theme.kollectAITheme.mediumAquamarine,
      fontWeight: 500,
    },
    cancelButton: {
      borderRadius: '8px',
      border: 'transparent',
      color: theme.kollectAITheme.white,
      fontSize: '1rem',
      padding: '0.625rem 4.5rem',
      background: theme.kollectAITheme.parrotPink,
      fontWeight: 500,
    },
    title: {
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '30px',
      color: theme.kollectAITheme.primaryText,
      marginBottom: '2.32rem',
    },
    description: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: '1.12rem',
      lineHeight: '23px',
      color: theme.kollectAITheme.black,
      opacity: 0.5,
    },
    listContainer: {
      maxHeight: 'calc(100vh - 400px)',
      overflow: 'auto',
      margin: '1rem 0',
      '& li': {
        margin: '0.5rem 0',
      },
    },
    buttonContainer: {
      margin: '33px auto 28px',
      display: 'flex',
      justifyContent: 'center',
      columnGap: '1.5rem',
    },
  })
);

export default useStyles;
