import { FormikValues } from 'formik';
import {
  IvrPreview,
  SmsPreview,
  WhatsappPreview,
} from 'containers/Templates/previews';
import {
  IvrTwoWayPreview,
  SmsTwoWayPreview,
  WhatsappTwoWayPreview,
} from './twoWay';

const CreateTemplatePreview = ({
  channel,
  message,
  messageType,
  values,
}: {
  channel: string;
  message: string;
  messageType: string;
  values: FormikValues;
}) => {
  if (channel === 'sms') {
    if (messageType === 'json') {
      return <SmsTwoWayPreview messages={values.messages} />;
    } else {
      return <SmsPreview isText={true} text={message} />;
    }
  }
  if (channel === 'whatsapp') {
    if (messageType === 'json') {
      return (
        <WhatsappTwoWayPreview json={message} messages={values.messages} />
      );
    } else {
      return <WhatsappPreview isText={true} text={message} />;
    }
  }
  if (channel === 'ivr') {
    if (messageType === 'json') {
      return <IvrTwoWayPreview json={message} messages={values.messages} />;
    } else {
      return <IvrPreview isText={true} text={message} />;
    }
  }
  return <></>;
};

export default CreateTemplatePreview;
