import * as React from 'react';
import {
  Checkbox,
  Collapse,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  checkboxClasses,
} from '@mui/material';

import notesImg from 'assets/notes.svg';

import { CustomerDetailsType } from 'constants/types';
import AddNotesModal from './addNotesModal/addNotesModal';
import CustomerLoanDetailsTable from '../loanDetailsTable';

import LatestNote from './latestNote';
import LastCommunication from './lastCommunication';
import LoanDetails from './loanDetails';
import ViewDetails from './viewDetails';
import StatusUpdates from './statusUpdates';

interface TableDataProps {
  data: CustomerDetailsType[];
  onClick: (x: any, y: string) => void;
  isSelected: (x: string) => boolean;
}

const TableData: React.FC<TableDataProps> = ({ data, onClick, isSelected }) => {
  const [expand, setExpand] = React.useState<number>(0);
  const [showNotes, setShowNotes] = React.useState<boolean>(false);
  const [showMore, setShowMore] = React.useState<any>({});
  const [rowData, setRowData] = React.useState<any>(null);

  React.useEffect(() => {
    if (data && data.length > 0) {
      data.map((_, ind) =>
        setShowMore((prev: any) => ({ ...prev, [ind]: false }))
      );
    }
  }, [data]);

  const handleNoteClose = () => setShowNotes(false);

  return (
    <>
      <TableBody>
        {data?.map((row: CustomerDetailsType, index: number) => {
          const { last_communication_sent, loan_details, status_updates } = row;

          const latestNote: string =
            last_communication_sent?.latest_notes?.notes || '';

          return (
            <React.Fragment key={index}>
              <TableRow
                sx={{
                  cursor: 'pointer',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 3px 3px, rgba(0, 0, 0, 0.24) 0px 3px 2px',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                onClick={() => {
                  const exp = expand === 0 ? index + 1 : 0;
                  setExpand(exp);
                }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isSelected(row.customer_id)}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClick(event, row.customer_id);
                    }}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: '#396572',
                      },
                    }}
                    inputProps={{
                      'aria-labelledby': `customer-table-checkbox-${index}`,
                    }}
                  />
                </TableCell>

                <TableCell align='center' component='th' scope='row'>
                  <Typography>{row.customer_id}</Typography>
                </TableCell>

                <TableCell align='center'>
                  <LoanDetails loanDetails={row.loan_details} />
                </TableCell>

                <TableCell align='center'>
                  <Typography>
                    {row.net_balance_due
                      ? `$ ${row.net_balance_due.toLocaleString()}`
                      : '-'}
                  </Typography>
                </TableCell>

                <TableCell align='center'>
                  <Typography>{row.max_dpd}</Typography>
                </TableCell>

                <TableCell align='center'>
                  <LastCommunication
                    lastCommunication={last_communication_sent}
                  />
                </TableCell>

                <TableCell align='center'>
                  <LatestNote
                    note={latestNote}
                    showMore={showMore[index] as boolean}
                    onSeeMore={() =>
                      setShowMore((prev: any) => ({
                        ...prev,
                        [index]: !showMore[index],
                      }))
                    }
                  />
                </TableCell>

                <TableCell align='center'>
                  <StatusUpdates statusUpdates={status_updates} />
                </TableCell>

                <TableCell align='center'>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowNotes(true);
                      setRowData(row);
                    }}
                  >
                    <img src={notesImg} alt='' />
                  </button>
                </TableCell>

                <TableCell align='center'>
                  <ViewDetails customerId={row.customer_id} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ padding: 0 }} colSpan={12}>
                  <Collapse
                    in={expand === index + 1}
                    timeout='auto'
                    unmountOnExit
                  >
                    <CustomerLoanDetailsTable
                      data={loan_details}
                      odfID={row.odf_customer_id}
                      customerId={row.customer_id}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>

      <AddNotesModal
        open={showNotes}
        onClose={handleNoteClose}
        data={rowData}
      />
    </>
  );
};
export default TableData;
