import { Box, Typography } from '@mui/material';
import Select from 'react-select';
import { reactSelectStyles } from 'components/formik/workflow/reactSelectStyles';
import { getGraphData } from 'utils/workflow/graphHandler';
import { Field, FieldProps } from 'formik';

interface CustomFormikWorkflowSelectProps {
  name: string;
  placeholder?: string;
  label?: string;
  options: any;
  onChange?: (x: any) => void;
  noMargin?: boolean;
}

const CustomFormikWorkflowSelect: React.FC<CustomFormikWorkflowSelectProps> = ({
  name,
  placeholder = 'Select',
  label,
  options,
  onChange,
  noMargin,
}) => {
  const defaultValue = (options: any, value: string) => {
    return options ? options.find((option: any) => option.value === value) : '';
  };

  const graphData = getGraphData();

  return (
    <Box mb={`${noMargin ? 0 : '1rem'}`}>
      <label>
        <Typography sx={{ fontSize: '1rem' }}>{label}</Typography>
      </label>

      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box mt='0.3rem'>
              <Select
                key={`selected_key_${JSON.stringify(value)}`}
                placeholder={placeholder}
                value={defaultValue(options, value)}
                onChange={(value: any) => {
                  setFieldValue(name, value.value);
                  onChange && onChange(value.value);
                }}
                options={options}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={reactSelectStyles}
                isDisabled={graphData.published === 1 ? true : false}
              />
              {/* {touched && error && (
                <Box
                  sx={{
                    fontSize: '0.8rem',
                    color: '#d32f2f',
                  }}
                >
                  {error}
                </Box>
              )} */}
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </Box>
  );
};

export default CustomFormikWorkflowSelect;
