import { useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import useStyles from './index.styles';

import CustomerActionModal from '../ActionModals';
import { actions } from './actions';

const CustomerActions = ({
  // status,
  availableActions,
  fetchData,
  disabled,
}: {
  // status: string;
  availableActions: any;
  fetchData: () => void;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const handleAction = (action: string) => {
    setSelectedAction(action);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const filteredActions = actions.filter(
    (action) => availableActions[action.apiIdentifier] === 'True'
  );

  return (
    <Box className={classes.actionWrapper}>
      <Typography align='center' fontWeight={600}>
        Actions
      </Typography>

      <Box className={classes.actionCont}>
        {filteredActions.map((action, index) => {
          const { icon: Icon, color, size = 24 } = action;
          return (
            <button
              key={index}
              className={classes.actionStyle}
              onClick={() => handleAction(action.label)}
              disabled={disabled}
            >
              <Icon color={color} size={size} />
              <Typography>{action.label}</Typography>
            </button>
          );
        })}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'grid', placeItems: 'center' }}
        disableEnforceFocus={false}
        children={
          <CustomerActionModal
            action={selectedAction}
            onClose={handleClose}
            fetchData={fetchData}
          />
        }
      />
    </Box>
  );
};

export default CustomerActions;
