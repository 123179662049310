import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicModalStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxStyle: {
      borderRadius: '10px',
      backgroundColor: 'white',
      outline: 'none',
      padding: '40px 30px',
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
  })
);

export default useStyles;
