import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { normalize } from "path";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#fff",
      padding: "56px 48px",
      height: "100%",
      maxWidth: "1400px",
      margin: "auto",
    },
    banner: {
      background: "rgba(0, 67, 87, 0.2)",
      alignItems: "flex-end",
    },
    bannerContent: {
      maxWidth: 548,
      padding: "32px 0 20px 59px",
    },
    bannerImageWrapper: {
      maxWidth: 400,

      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    bannerHeading: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: "40px",
      color: "#004357",
    },
    bannerDescription: {
      fontFamily: "Overpass",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "25px",
      color: "#333333",
    },
    createABTestingButton: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      background: theme.kollectAITheme.primaryColor,
      border: "1px solid #E0E7FF",
      color: "#fff",
      padding: "0.6rem 0.8rem",
      borderRadius: "5px",
      marginTop: 10,

      "& svg": {
        fontSize: "1rem",
      },
      "& p": {
        fontWeight: "600",
        fontSize: "0.8rem",
        lineHeight: "18px",
      },
    },
    title: {
      fontFamily: "Overpass",
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "30px",
      color: "#333333",
      margin: "40px 50px 0",
    },
    description: {
      fontFamily: "Overpass",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineheight: "25px",
      color: "#333333",
      margin: "30px 50px 0",
    },
    dashboardManagerWrapper: {
      maxHeight: 243,
      background: "#fff",
      border: "0.5px solid #E6E6E6",
      boxShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "20px",
      padding: "1rem",
      cursor: "pointer",
    },
    cardTitle: {
      textTransform: "uppercase",
      textAlign: "center",
      fontFamily: "Overpass",
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "25px",
      color: "#272D3B",
    },
    requirementWrapper: {
      background: "rgba(111, 111, 111, 0.14)",
      marginTop: 67,
      padding: "12px 55px 32px 59px",
    },
    requirementTitle: {
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: "40px",
      color: "#004357",
    },
    requirementDescription: {
      fontFamily: "Overpass",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "25px",
      color: "#333333",
    },
    footerForm: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textAreaContainer: {
      flex: 1,
      paddingRight: "80px",
      marginTop: 8,
    },
    getQuotesButton: {
      background: theme.kollectAITheme.primaryColor,
      border: "1px solid #E0E7FF",
      color: "#fff",
      padding: "0.5rem 2.25rem",
      borderRadius: "5px",
      height: "fit-content",

      "&:disabled": {
        background: theme.kollectAITheme.lightGrey,
      },
    },

    popupbox: {},

    requirementPopupWrapper: {
      background: "rgba(111, 111, 111, 0.14)",
      // marginTop: 67,
      margin: "-40px -30px",
      padding: "39px 60px",
    },
    requirementPopupTitle: {
      marginTop: 29,
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: "40px",
      color: "#004357",
    },
    requirementPopupDescription: {
      fontFamily: "Overpass",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "25px",
      color: "#333333",
      marginTop: 29,
    },
    popupFormWrapper: {
      margin: "18px 0 32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "space-between",
    },
    popupTextAreaContainer: {
      width: "100%",
      height: 225,
    },
    submitQuotesButton: {
      background: theme.kollectAITheme.primaryColor,
      border: "1px solid #E0E7FF",
      color: "#fff",
      padding: "1.25rem 3.43rem",
      borderRadius: "5px",
      height: "fit-content",
      margin: "32px 0 auto",
      maxWidth: 230,

      "&:disabled": {
        background: theme.kollectAITheme.lightGrey,
      },
    },

    congratulationModalWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 765,
      paddingBottom: 80,
    },

    congratulationTitle: {
      fontFamily: "Overpass",
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: "40px",
      color: "#004357",
      textAlign: "center",
    },
    congratulationDescription: {
      fontFamily: "Overpass",
      fontWeight: 400,
      fontSize: "1.37rem",
      lineHeight: "28px",
      color: "#333333",
      marginTop: 76,
      paddingLeft: 80,
    },
  })
);

export default useStyles;
