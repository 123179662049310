import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataInput: {
      background: 'tranparent',
      border: 'transparent',
      outline: 'none',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRight: '1px solid #969696',
      width: '390px',
      color: '#969696',
      fontWeight: 600,
      fontSize: '1rem',
      fontFamily: 'Overpass',
      padding: '12px 0 10px 7px',
      '& ::placeholder': { color: '#969696' },
    },
  })
);

export default useStyles;
