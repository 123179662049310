import { useState, useCallback, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Typography } from '@mui/material';
import { CiCalendar, CiSearch } from 'react-icons/ci';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import useStyles from './index.styles';
import { kollectAITheme } from 'config/theme';
import useClickOutside from 'hooks/useClickOutside';

interface WorkflowFilterProps {
  onSearch: (x: string, y: any) => void;
}

interface WorflowFilterOptionType {
  label: string;
  placeholder: string;
  name: string;
}

const worflowFilterOptions: WorflowFilterOptionType[] = [
  { label: 'id', name: 'workflow_id', placeholder: 'Workflow ID' },
  { label: 'name', name: 'wf_name', placeholder: 'Name of the workflow' },
  { label: 'status', name: 'active', placeholder: 'Active/Inactive' },
  { label: 'author', name: 'author', placeholder: 'Name of the author' },
  {
    label: 'created date',
    name: 'created',
    placeholder: 'Enter the date range',
  },
];

const WorkflowFilter: React.FC<WorkflowFilterProps> = ({ onSearch }) => {
  const classes = useStyles();
  const filterRef = useRef<HTMLDivElement>(null);

  const [selectedOption, setSelectedOption] = useState<WorflowFilterOptionType>(
    worflowFilterOptions[1]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleOptions = () => {
    setSearchValue('');
    setStartDate(null);
    setEndDate(null);
    setShowOptions(!showOptions);
  };

  const handleSearch = (option?: any, isEmpty?: boolean) => {
    const param = option ? option.name : selectedOption.name;
    let value: any = isEmpty ? '' : searchValue;
    if (param === 'created') {
      if (startDate && endDate) {
        value = { startDate, endDate };
      }
    }
    if (param === 'active') {
      const activeValue = searchValue.toLowerCase();
      if (activeValue === 'active' || activeValue === 'inactive') {
        value = activeValue === 'active' ? 1 : 0;
      } else {
        value = '';
      }
    }
    onSearch(param, value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const onClearFilter = useCallback(() => {
    onSearch('wf_name', '');
    setSearchValue('');
    setStartDate(null);
    setEndDate(null);
    // setSelectedOption(worflowFilterOptions[1]);
  }, [onSearch]);

  const clearDisabled = useMemo(
    () => (searchValue || startDate || endDate ? false : true),
    [startDate, searchValue, endDate]
  );

  useClickOutside(filterRef, () => showOptions && setShowOptions(false));

  return (
    <Box sx={{ position: 'relative' }}>
      <Box className={classes.workflowSearchbarCont}>
        <Typography
          className={classes.workflowSearchbarLabel}
          onClick={handleOptions}
        >
          {`${selectedOption.label}:`}
        </Typography>

        {selectedOption.label !== 'created date' ? (
          <input
            type='text'
            placeholder={`${selectedOption.placeholder}`}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value === '') {
                handleSearch(selectedOption, true);
              }
            }}
            className={classes.workflowSearchbarInput}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Box className={classes.workflowSearchDatepickerStyle}>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                placeholderText='From'
              />
              <CiCalendar />
            </Box>
            <Box className={classes.workflowSearchDatepickerStyle}>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                maxDate={new Date()}
                placeholderText='To'
              />
              <CiCalendar />
            </Box>
          </Box>
        )}

        <button onClick={handleOptions}>
          {showOptions ? (
            <FiChevronLeft fontSize={20} />
          ) : (
            <FiChevronDown fontSize={20} />
          )}
        </button>

        <button onClick={() => handleSearch()}>
          <CiSearch
            color={kollectAITheme.primaryColor}
            fontWeight={600}
            fontSize={20}
          />
        </button>

        <button disabled={clearDisabled} onClick={onClearFilter}>
          <Typography className={classes.clearFilter}>Clear filter</Typography>
        </button>
      </Box>

      {showOptions && (
        <Box ref={filterRef} className={classes.workflowSearchOptionsCont}>
          <Typography sx={{ marginBottom: '0.8rem' }}>SEARCH USING</Typography>
          {worflowFilterOptions.map(
            (option: WorflowFilterOptionType, index: number) => {
              const { label, placeholder } = option;
              return (
                <Box
                  key={index}
                  className={classes.workflowSearchOptionStyle}
                  onClick={() => {
                    setSelectedOption(option);
                    setShowOptions(false);
                    handleSearch(option);
                  }}
                >
                  <Typography className={classes.workflowSearchOptionLabel}>
                    {`${label}:`}
                  </Typography>
                  <Typography color={'#8593B5'}>{placeholder}</Typography>
                </Box>
              );
            }
          )}
        </Box>
      )}
    </Box>
  );
};

export default WorkflowFilter;
