import { useState, useEffect } from 'react';
import { Card, Divider } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './index.styles';
import Breadcrumb from 'components/breadcrumbs';
import {
  StrategyDetailsHeader,
  ChannelSummary,
  ChannelLevelDetails,
} from 'components/strategyDetails';
import { useAppDispatch } from 'hooks/useRedux';
import { campaignGroupFilter } from 'store/reducer/uploadedFileDetailReducer';
import {
  getStrategyDetails,
  strategyChannelTemplate,
  strategyDetailsFilter,
} from 'store/reducer/strategyReducer/action';
import Loader from 'components/loader';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import ABTestSummary from 'components/strategyDetails/ABTestSummary';
import { useParams } from 'react-router-dom';

const ComparisionStrategy = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  let { groupName = '', strategyName = '' } = location.state as any;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // getStrategyData();
  }, []);

  const getStrategyData = async () => {
    setLoading(true);
    try {
      await dispatch(viewChannelTemplate({ channel_type: 'all' }));

      const res = await dispatch(
        campaignGroupFilter({ client_id: 1 })
      ).unwrap();

      if (res.meta.message === 'success') {
        const { AMOUNT, DPD, PRODUCT } = res.data;
        const filterPayload = {
          bucket: '1',
          amount: AMOUNT.LOW,
          product: PRODUCT['0'],
          dpd: DPD['0'],
          client_id: '1',
          auth_token: '',
          client_token: '',
        };
        // await dispatch(strategyDetailsFilter(filterPayload));
        // await dispatch(getStrategyDetails({ id }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderRootBreadcrumb = () => {
    return (
      <>
        <span style={{ marginRight: 4 }}>Strategy</span>
        <span>&gt;</span>
        <span style={{ marginLeft: 4 }}>
          <Link to='/strategy'>StrategySummary</Link>
        </span>
      </>
    );
  };

  return (
    <div>
      <Loader loading={loading} />
      <ABTestSummary />
    </div>
  );
};

export default ComparisionStrategy;
