import * as React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import BasicModal from 'components/Modal';

const ActivityViewDetails = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: Record<string, any>;
}) => {
  const [message, setMessage] = React.useState<any>('');
  const [viewData, setViewData] = React.useState<Record<string, any> | null>(
    null
  );

  React.useEffect(() => {
    if (
      data?.conversation_id &&
      ['Whatsapp', 'SMS'].includes(data?.communication_medium)
    ) {
      if (data.message.length > 1) {
        setMessage(data.message);
      } else {
        setMessage(data?.message?.[0]?.message);
      }
      const { message, ...rest } = data;
      setViewData(rest);
    } else {
      if (data?.communication_medium === 'Email') {
        const { conversation_id, ...rest } = data;
        setViewData(rest);
      } else {
        setViewData(data);
      }
    }
  }, [data]);

  return (
    <BasicModal
      open={open}
      onClose={() => {
        onClose();
        setMessage('');
      }}
      children={
        <Box>
          {viewData &&
            Object.keys(viewData)?.map((key, index) => {
              return (
                <Box
                  key={`view-data-${index}`}
                  sx={{ display: 'flex', gap: '2rem', mb: '1rem' }}
                >
                  <Typography minWidth={120} fontWeight={600}>
                    {key}
                  </Typography>

                  {data?.communication_medium === 'Email' &&
                  key === 'message' ? (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: viewData[key],
                      }}
                      maxWidth={'calc(100vw - 600px)'}
                    />
                  ) : (
                    <Typography maxWidth={300} sx={{ wordWrap: 'break-word' }}>
                      {JSON.stringify(viewData[key])}
                    </Typography>
                  )}
                </Box>
              );
            })}

          {data?.conversation_id && message ? (
            <Box
              sx={{
                display: 'flex',
                gap: typeof message === 'string' ? '2rem' : '1rem',
                mb: '1rem',
                flexDirection: typeof message === 'string' ? 'row' : 'column',
              }}
            >
              <Typography minWidth={120} fontWeight={600}>
                message
              </Typography>
              {typeof message === 'string' ? (
                <Typography maxWidth={300} sx={{ wordWrap: 'break-word' }}>
                  {message}
                </Typography>
              ) : (
                <Box
                  maxWidth={450}
                  sx={{
                    maxHeight: 'calc(100vh - 300px)',
                    overflow: 'auto',
                    padding: '0 10px',
                  }}
                >
                  {message.map((msg: any, ind: number) => {
                    const user =
                      msg.initial_status === 'SUCCESSFUL'
                        ? 'system'
                        : 'customer';
                    const createdAt = moment(msg.created_at).format(
                      'MMM DD, YYYY, HH:mm:ss'
                    );

                    return (
                      <Box
                        key={`${msg.message}-${ind}`}
                        sx={{
                          display: 'flex',
                          justifyContent:
                            msg.initial_status === 'SUCCESSFUL'
                              ? 'end'
                              : 'start',
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: '0.9rem',
                            marginBottom: '10px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              justifyContent:
                                user === 'system' ? 'end' : 'start',
                              marginTop: '5px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontStyle: 'italic',
                                fontSize: '0.8rem',
                                color: '#808080',
                              }}
                            >
                              {user}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'inline',
                              padding: '5px',
                              background: '#E5E5E5',
                              boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
                              borderRadius: '5px 5px 5px 0px',
                            }}
                          >
                            <Typography>{msg.message}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              justifyContent:
                                user === 'system' ? 'end' : 'start',
                              marginTop: '5px',
                            }}
                          >
                            <Typography
                              sx={{ fontSize: '0.7rem', color: '#808080' }}
                            >
                              {createdAt}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};
export default ActivityViewDetails;
