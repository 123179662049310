import { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowAttributes } from 'store/reducer/workflowReducer';

import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { getworkflowProperties } from 'store/reducer/workflowReducer/action';

import WorkflowActionButtons from '../actionButtons';

interface SetWaitDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const operatorOptions = [
  { label: 'x days before', value: 'before' },
  { label: 'x days after', value: 'after' },
  { label: 'on (specific date)', value: 'on' },
];

const SetWaitDrawer: React.FC<SetWaitDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const dispatch = useAppDispatch();

  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const graphData: any = getGraphData();

  const [attributeOptions, setAttributeOptions] = useState<any>([]);

  const allowedSetWaitAttributes: any =
    useAppSelector(workflowAttributes)?.set_wait;

  const fetchSetWaitAttributes = useCallback(async () => {
    try {
      await dispatch(getworkflowProperties({ action_type: 'set_wait' }));
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    fetchSetWaitAttributes();
  }, [fetchSetWaitAttributes]);

  useEffect(() => {
    if (allowedSetWaitAttributes) {
      const filteredAttribute = allowedSetWaitAttributes.filter(
        (attribute: any) => attribute.type === 'Date'
      );
      const options = filteredAttribute.map((item: any) => {
        return { label: item.attribute, value: item.attribute };
      });
      setAttributeOptions(options);
    }
  }, [allowedSetWaitAttributes]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName } = values;
    const { attribute, days, operator } = values;
    const waitTill = [{ days: +days, condition: operator, attribute }];
    let configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'WAIT',
          waitTill,
          haschildren: {
            After_waiting: [
              {
                parent: stepName,
                parentValue: 'After_waiting',
                position: 1,
                name: '',
              },
            ],
          },
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'waitTill');
      updateWorkflow(configObj, keys, waitTill);
    }

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      attribute: yup.string().required('Required'),
    })
  );

  const checkUpdateDisabled = (values: FormikValues): boolean => {
    let disabled = true;
    if (values?.operator === 'on' && values?.attribute) disabled = false;
    if (values?.operator !== 'on' && values?.attribute && values?.days)
      disabled = false;
    return disabled;
  };

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
        operator: workflowActionData?.waitTill?.[0].condition,
        days: +workflowActionData?.waitTill?.[0].days,
        attribute: workflowActionData?.waitTill?.[0].attribute,
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form
          style={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              type='text'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              type='text'
              placeholder='Precursor Name'
              disabled={true}
            />

            <CustomFormikWorkflowSelect
              name='operator'
              placeholder='Select Operator'
              label='Condition'
              options={operatorOptions}
              onChange={() => {
                setFieldValue('days', '');
                setFieldValue('attribute', '');
              }}
            />

            {values.operator && (
              <Box>
                {values.operator !== 'on' && (
                  <>
                    <CustomFormikWorkflowInput
                      label='Provide values'
                      name='days'
                      type='number'
                      placeholder='Enter Values'
                      noMargin={true}
                    />
                    <Typography textAlign='center' my='0.5rem'>
                      days {values.operator === 'before' ? 'before' : 'after'}
                    </Typography>
                  </>
                )}
                <CustomFormikWorkflowSelect
                  name='attribute'
                  placeholder='Select date attribute'
                  options={attributeOptions}
                />
              </Box>
            )}
          </Box>

          {!graphData.published && (
            <WorkflowActionButtons
              isUpdateDisabled={checkUpdateDisabled(values)}
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SetWaitDrawer;
