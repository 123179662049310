import { Card, Grid } from "@material-ui/core";
import ChallengerSummary from "./challengerSummary";
import ChampionSummary from "./championSummary";
import ChannelSchedule from "./channelSchedule";

import useStyles from "./index.styles";

const ABTestSummary = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item lg={6}>
        <Card variant="outlined" className={classes.card}>
          <ChampionSummary />
          <ChannelSchedule strategyIndex={0} />
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card variant="outlined" className={classes.card}>
          <ChallengerSummary />
          <ChannelSchedule strategyIndex={1} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ABTestSummary;
