import ChannelSummaryHeader from './header';
// import useStyles from './index.styles';

import ChannelSummaryCalendar from './calendar';

const ChannelSummary = () => {
  // const classes = useStyles();
  return (
    <div style={{ padding: '0 2rem' }}>
      <ChannelSummaryHeader />
      <ChannelSummaryCalendar />
    </div>
  );
};

export default ChannelSummary;
