import { AwsLinkType } from 'constants/types';
import { getAccessToken } from 'utils/auth';
import { errorToast } from 'utils/toast';

export const lambdaUpload = async ({
  urlObj,
  request,
  api_key,
}: {
  urlObj: AwsLinkType;
  request: string;
  api_key: string;
}) => {
  try {
    const res = await fetch(urlObj.link, {
      method: 'POST',
      body: request,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json',
        token: `${getAccessToken()}`,
        'x-api-key': urlObj.api_key,
        api_key,
      },
    });
    const jsonRes = await res.json();
    if (jsonRes?.meta?.success) {
      return {
        success: true,
        data: { 'Non PII': jsonRes?.['Non PII'], PII: jsonRes?.PII },
      };
    } else {
      const err = jsonRes?.errorMessage || 'Internal server error';
      errorToast(err);
      return { success: false, data: jsonRes };
    }
  } catch (error: any) {
    console.log(error.response);
    return { success: false };
  }
};
