import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    requestFilter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '10px',
      marginBottom: '1rem',

      '& p': {
        color: '#969696',
        fontStyle: 'italic',
      },

      '& img': {
        width: '26px',
      },
    },
    filterWrapper: {
      position: 'absolute',
      zIndex: 100,
      background: '#fff',
      width: 250,
      borderRadius: '10px',
      padding: '1rem',
      right: '5px',
      top: '34px',
      boxShadow:
        '0px 2px 9px rgb(50 71 92 / 6%), 0px 4px 9px 1px rgb(50 71 92 / 4%), 0px 2px 6px 4px rgb(50 71 92 / 2%)',
      '& p': { color: '#000 !important', fontStyle: 'normal !important' },
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
