import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    workflowSearchbarCont: {
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      background: '#FFFFFF',
      borderRadius: '8px',
      padding: '0.4rem 0.5rem',
      gap: '0.5rem',
      height: '32px',
    },
    workflowSearchbarLabel: {
      background: '#EDF0F4',
      borderRadius: '3px',
      width: '120px',
      textAlign: 'center',
      padding: '0.2rem 0',
      cursor: 'pointer',
    },
    workflowSearchbarInput: {
      background: 'transparent',
      border: 'transparent',
      outline: 'none',
      fontFamily: 'inherit',
      fontSize: '1rem',
    },
    workflowSearchOptionsCont: {
      background: '#FFFFFF',
      borderRadius: '4px',
      padding: '1rem',
      position: 'absolute',
      zIndex: 200,
      top: '48px',
    },
    workflowSearchOptionStyle: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '0.8rem',
      cursor: 'pointer',
    },
    workflowSearchOptionLabel: {
      background: '#EDF0F4',
      borderRadius: '3px',
      width: '145px',
      height: '25px',
      textAlign: 'center',
    },
    workflowSearchDatepickerStyle: {
      border: '1px solid #d5d5d5',
      display: 'inline-flex',
      padding: '0.2rem 1rem',
      alignItems: 'center',
    },
    clearFilter: {
      fontSize: '0.9rem !important',
      textDecoration: 'underline',
    },
  })
);

export default useStyles;
