import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  CustomerCallInput,
  CustomerCallSelect,
} from 'components/formik/customer/call';

const ReadyToPay = () => {
  return (
    <Box>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <Form>
            <CustomerCallSelect
              name='method'
              label='Select payment method'
              options={[]}
            />

            <CustomerCallInput name='amount' label='Payment Amount' />

            <Box position='relative'>
              <CustomerCallInput
                name='contact'
                label='Please provide the number/email address that you wish to get the payment link'
                type='password'
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '6px',
                  right: '15px',
                  cursor: 'pointer',
                }}
              >
                <Typography color='#294E9B' fontSize='0.9rem'>
                  Send
                </Typography>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ReadyToPay;
