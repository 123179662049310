import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  attributeCurrentValue,
  customerActivityFilter,
  getAgentActionLists,
  getCallRequestAttempts,
  getCustomerActivity,
  getCustomerAgentsList,
  getCustomerDetails,
  getCustomerProfile,
  getCustomerQueue,
  getCustomerRequests,
  getCustomerStats,
  getHardshipDetails,
} from './action';

const initialState = {
  customerCategory: '',
  customerStats: {},
  customerDetails: [],
  customerProfile: [] as any[],
  customerRequests: [],
  customerOdfID: '',
  customerID: '',
  customerStatus: '',
  customerQueueData: null,
  customerCurrentAttribute: {},
  refetchRequest: false,
  customerActivity: [],
  customerAgentsList: [],
  hardshipDetails: [],
  selectedCustomerProduct: '',
  agentActionLists: [],
  refetchSidetableCustomer: false,
  callRequestAttempts: null,
  selectedCustomersArr: [] as any[],
  customerPiiData: null as any,
};

export const CustomerManagementSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerCategory: (state, action: PayloadAction<string>) => {
      state.customerCategory = action.payload;
    },
    setCustomerProfile: (state, action: PayloadAction<any>) => {
      state.customerProfile = action.payload;
    },
    setCustomerOdfID: (state, action: PayloadAction<string>) => {
      state.customerOdfID = action.payload;
    },
    setCustomerID: (state, action: PayloadAction<string>) => {
      state.customerID = action.payload;
    },
    setCustomerStatus: (state, action: PayloadAction<string>) => {
      state.customerStatus = action.payload;
    },
    setRefetchRequest: (state, action: PayloadAction<boolean>) => {
      state.refetchRequest = action.payload;
    },
    setSelectedCustomerProduct: (state, action: PayloadAction<string>) => {
      state.selectedCustomerProduct = action.payload;
    },
    setRefetchSidetableCustomer: (state, action: PayloadAction<boolean>) => {
      state.refetchSidetableCustomer = action.payload;
    },
    setSelectedCustomersArr: (state, action: PayloadAction<any[]>) => {
      state.selectedCustomersArr = action.payload;
    },
    setCustomerPiiData: (state, action: PayloadAction<any>) => {
      state.customerPiiData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerStats.fulfilled, (state, { payload }) => {
      state.customerStats = payload;
    });
    builder.addCase(getCustomerDetails.fulfilled, (state, { payload }) => {
      const { results } = payload as any;
      state.customerDetails = results;
      // state.customerAgentsList = [];
    });
    builder.addCase(getCustomerProfile.fulfilled, (state, { payload }) => {
      // const { results } = payload as any;
      state.customerProfile = Array.isArray(payload) ? payload : [];
      state.customerOdfID = payload?.[0]?.odf_customer_id;
    });
    builder.addCase(getCustomerRequests.fulfilled, (state, { payload }) => {
      const { results } = payload as any;
      state.customerRequests = results;
    });
    builder.addCase(getCustomerQueue.fulfilled, (state, { payload }) => {
      state.customerQueueData = payload;
    });
    builder.addCase(attributeCurrentValue.fulfilled, (state, { payload }) => {
      state.customerCurrentAttribute = payload?.data || {};
    });
    builder.addCase(getCustomerActivity.fulfilled, (state, { payload }) => {
      state.customerActivity = payload?.results?.[0]?.activity || [];
    });
    builder.addCase(customerActivityFilter.fulfilled, (state, { payload }) => {
      state.customerActivity = payload.results;
    });
    builder.addCase(getCustomerAgentsList.fulfilled, (state, { payload }) => {
      state.customerAgentsList = payload?.data || [];
    });

    builder.addCase(getHardshipDetails.fulfilled, (state, { payload }) => {
      if (payload?.meta?.success) {
        state.hardshipDetails = payload.data;
      }
    });
    builder.addCase(getCallRequestAttempts.fulfilled, (state, { payload }) => {
      if (payload?.meta?.success) {
        state.callRequestAttempts = payload.data;
      }
    });
    builder.addCase(getAgentActionLists.fulfilled, (state, { payload }) => {
      if (payload?.meta?.success && payload.data?.agent_actions) {
        // state.hardshipDetails = payload.data;
        const { agent_actions, agent_notes } = payload.data;
        const concatenatedActions = agent_actions.concat(agent_notes);
        const sortedByActionDate = concatenatedActions.sort(
          // @ts-ignore
          (a, b) => new Date(b.action_date) - new Date(a.action_date)
        );
        state.agentActionLists = sortedByActionDate;
      }
    });
  },
});

export const dashboardPortfolioData = (state: any) =>
  state.dashboard.dashboardPortfolio;

export const {
  setCustomerCategory,
  setCustomerProfile,
  setCustomerOdfID,
  setCustomerStatus,
  setCustomerID,
  setRefetchRequest,
  setSelectedCustomerProduct,
  setRefetchSidetableCustomer,
  setSelectedCustomersArr,
  setCustomerPiiData,
} = CustomerManagementSlice.actions;

export default CustomerManagementSlice.reducer;

export const constumerActivity = (state: RootState) =>
  state.customer.customerActivity;
export const customerAgentsList = (state: RootState) =>
  state.customer.customerAgentsList;
export const customerOdfID = (state: RootState) => state.customer.customerOdfID;
export const hardshipDetails = (state: RootState) =>
  state.customer.hardshipDetails;
export const selectedCustomerProduct = (state: RootState) =>
  state.customer.selectedCustomerProduct;
export const agentActionLists = (state: RootState) =>
  state.customer.agentActionLists;
export const refetchSidetableCustomer = (state: RootState) =>
  state.customer.refetchSidetableCustomer;
export const callRequestAttempts = (state: RootState) =>
  state.customer.callRequestAttempts;
export const selectedCustomersArr = (state: RootState) =>
  state.customer.selectedCustomersArr;
export const customerCategory = (state: RootState) =>
  state.customer.customerCategory;
export const customerPiiData = (state: RootState) =>
  state.customer.customerPiiData;
