import { Typography, Box } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core';

interface WorkflowButtonProps {
  text: string;
  icon: JSX.Element;
  bgColor: string;
  disabled?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    workflowButtonStyle: {
      display: 'flex',
      background: (props: any) => props.bgColor,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '7px',
      color: '#fff',
      padding: '0.3rem 0.8rem',
      gap: '5px',
      alignItems: 'center',
      height: '43px',
      cursor: (props: any) => props.cursor,

      '& svg': {
        fontSize: '1.2rem',
      },
    },
  })
);

const WorkflowButton: React.FC<WorkflowButtonProps> = ({
  text,
  icon,
  bgColor,
  onClick,
  disabled,
}) => {
  const classes = useStyles({
    bgColor,
    cursor: disabled ? 'default' : 'pointer',
  });
  return (
    <button
      className={classes.workflowButtonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <Typography color='#fff'>{text}</Typography>
    </button>
  );
};

export default WorkflowButton;
