import { useState, useRef } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import successMappingJson from '../../db_connect_attributes_connection_status_success.json';
import failMappingJson from '../../db_connect_attributes_connection_status_fail.json';

import CustomInput from '../form/CustomInput';
import successfulConnectionImg from 'assets/generated.svg';
import AttributeMappingTable from './attributeMappingTable';
// import Loader from 'components/loader';
import { kollectAITheme } from 'config/theme';
// import { useAppSelector } from 'hooks/useRedux';
// import { userAccess } from 'utils/helper';

const ActionButton = styled('button')(({ color }: { color: string }) => ({
  textDecoration: 'underline',
  color,

  '& p': {
    fontWeight: 600,
    fontSize: '1.1rem',
  },
}));

const validationSchema = yup.object().shape({
  // workflowId: yup.string().required('Required'),
  // name: yup.string().required('Required'),
});

interface DBConnectedScreenProps {
  attributeJson: any;
  connectionData: any;
  setLoading: (x: boolean) => void;
  setIsDBConnected: (x: boolean) => void;
}

const DBConnectedScreen: React.FC<DBConnectedScreenProps> = ({
  attributeJson,
  setLoading,
  setIsDBConnected,
  connectionData,
}) => {
  const tableRef: any = useRef();
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const [verifyMappingRes, setVerifyMappingRes] = useState<any>(null);
  const [mappingErrorMsg, setMappingErrorMsg] = useState<string>('');
  const [allAttributeMapped, setAllAttributeMapped] = useState<boolean>(false);
  const [isAttributeSaved, setIsAttributeSaved] = useState<boolean>(false);
  const [mappingClick, setMappingClick] = useState(0);

  const handleSubmit = () => {};

  const handleDisconnect = () => {
    setLoading(true);
    setTimeout(() => {
      setIsDBConnected(false);
      setLoading(false);
    }, 2000);
  };

  const handleVerifyMapping = () => {
    setLoading(true);

    setTimeout(() => {
      const { selectedTableOption, selectedColumnOption } = tableRef.current;
      const allSelected =
        checkIsSelected(selectedTableOption) &&
        checkIsSelected(selectedColumnOption);
      if (allSelected) {
        setMappingErrorMsg('');
        if (mappingClick === 0) {
          setVerifyMappingRes(failMappingJson);
          setMappingClick(1);
        } else {
          setVerifyMappingRes(successMappingJson);
          setAllAttributeMapped(true);
        }
      } else {
        setMappingErrorMsg(
          'Please select table and column name for each attribute!'
        );
      }
      setLoading(false);
    }, 1000);
  };

  const checkIsSelected = (obj: any) => {
    return _.isEqual(_.keys(obj).sort(), _.keys(attributeJson).sort());
  };

  const handleAttributeSave = () => {
    setLoading(true);
    setTimeout(() => {
      setIsAttributeSaved(true);
      setLoading(false);
    }, 1000);
  };

  const handleEdit = () => {
    setLoading(true);
    setTimeout(() => {
      setIsAttributeSaved(false);
      setMappingClick(0);
      setAllAttributeMapped(false);
      setVerifyMappingRes(null);
      setIsAttributeSaved(false);
      setLoading(false);
    }, 1000);
  };

  // const userPermissions = userAccess(userRole)?.integration.permissions;

  return (
    <Box
      sx={{
        padding: '1rem 1.5rem 0',
      }}
    >
      <Box
        sx={{
          border: '2px solid #969696',
          borderRadius: '10px',
          position: 'relative',
          '& span': { color: '#00C087' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            position: 'absolute',
            right: 10,
            '& img': { width: '35px' },
          }}
        >
          <Typography fontWeight={600} color='#6AC5AB'>
            Connected Successfully
          </Typography>
          <img src={successfulConnectionImg} alt='' />
        </Box>
        <Formik
          initialValues={{
            host: connectionData?.host,
            database: connectionData?.database,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  width: '60%',
                  margin: '1rem auto',
                }}
              >
                <CustomInput
                  label='Host Name'
                  name='host'
                  type='text'
                  placeholder='www.example.com'
                  disabled={true}
                  inputWidth={300}
                />

                <Box display='flex' alignItems='center' gap='1rem'>
                  <CustomInput
                    label='Database Name'
                    name='database'
                    type='text'
                    placeholder='Collection DB'
                    inputWidth={300}
                    disabled={true}
                  />
                  {/* {userPermissions.includes('db_connect_update') && ( */}
                  <ActionButton type='button' color='#0069AA'>
                    <Typography>Edit Connection</Typography>
                  </ActionButton>
                  {/* )} */}
                  <ActionButton
                    type='button'
                    color='#95261F'
                    onClick={handleDisconnect}
                  >
                    <Typography>Disconnect</Typography>
                  </ActionButton>
                </Box>
              </Box>

              <Box display='flex' justifyContent='center'></Box>
            </Form>
          )}
        </Formik>
      </Box>

      <Box mt='30px' mb='16px'>
        <Typography color='#0C101A' fontWeight={700} fontSize='1.1rem'>
          Map your attributes
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 600px',
            alignItems: 'center',
            margin: '10px 0',
          }}
        >
          <Typography color='#969696' fontWeight={500}>
            Map each required attribute with the corresponding table name and
            column name. Once mapping is done, click verify mapping & save.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'end',
            }}
          >
            {isAttributeSaved ? (
              <button>
                <button
                  style={{
                    background: kollectAITheme.primaryColor,
                    borderRadius: '10px',
                    padding: '7px 25px',
                  }}
                  onClick={handleEdit}
                >
                  <Typography color='#fff' fontWeight={600}>
                    EDIT
                  </Typography>
                </button>
              </button>
            ) : (
              <>
                <button onClick={handleVerifyMapping}>
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      fontWeight: 700,
                      color: '#00C087',
                      fontSize: '1.1rem',
                    }}
                  >
                    Verify Mapping
                  </Typography>
                </button>
                <button
                  style={{
                    background: allAttributeMapped ? '#6AC5AB' : '#969696',
                    borderRadius: '10px',
                    padding: '7px 25px',
                  }}
                  disabled={!allAttributeMapped}
                  onClick={handleAttributeSave}
                >
                  <Typography color='#fff' fontWeight={600}>
                    SAVE
                  </Typography>
                </button>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Typography align='center' color='#FF646B'>
        {mappingErrorMsg && mappingErrorMsg}
      </Typography>

      <Box
        sx={{
          border: '1px solid #6F6F6F',
          borderRadius: '10px',
          margin: '1rem auto 0',
          height: 'calc(100vh - 563px)',
          overflow: 'auto',
          scrollbarWidth: 'none',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box>
          <AttributeMappingTable
            ref={tableRef}
            attributeJson={attributeJson}
            response={verifyMappingRes}
            saved={isAttributeSaved}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DBConnectedScreen;
