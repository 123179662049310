import { Box, styled } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCalendar } from 'react-icons/ci';
import { getGraphData } from 'utils/workflow/graphHandler';

interface DatePickerProps {
  label: string;
  name: string;
}

const DatePickeWrapper = styled('div')({
  border: '1px solid #d5d5d5',
  padding: '0.4rem 0 0.4rem 1rem',
  position: 'relative',
  marginTop: '0.5rem',
  borderRadius: '4px',

  '& .react-datepicker__input-container input': {
    width: '100% !important',
    background: 'transparent !important',
    color: '#fff !important',
    cursor: 'default',
  },
});

const FormikWorkflowDatePicker = ({ label, name }: DatePickerProps) => {
  const graphData = getGraphData();

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          // delete field.name;

          return (
            <DatePickeWrapper>
              <DatePicker
                id={name}
                {...field}
                selected={value}
                onChange={(val) => setFieldValue(name, val)}
                readOnly={graphData.published === 1 ? true : false}
              />
              <Box sx={{ position: 'absolute', right: '10px', top: '7px' }}>
                <CiCalendar />
              </Box>
            </DatePickeWrapper>
          );
        }}
      </Field>
      <ErrorMessage name={name} component={'hello'} />
    </div>
  );
};

export default FormikWorkflowDatePicker;
