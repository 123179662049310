import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { rolePalette } from 'store/reducer/roleReducer';

export const AssignedTo = ({ assigned }: { assigned: string }) => {
  // const hex = Math.floor(Math.random() * 16777215)
  //   .toString(16)
  //   .padStart(6, '0')
  //   .toUpperCase();

  // let color = `#${hex}`;
  // let background = `#${hex}40`;
  const palette = useAppSelector(rolePalette);

  if (!assigned) return <></>;

  return (
    <Box
      sx={{
        background: palette?.[assigned]?.background,
        borderRadius: '4px',
        padding: '6px 12px',
        // width: '120px',
        '& p': {
          color: palette?.[assigned]?.color,
          textTransform: 'uppercase',
          fontSize: '0.8rem',
        },
      }}
    >
      <Typography>{assigned}</Typography>
    </Box>
  );
};
