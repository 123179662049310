import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AiFillPlusCircle } from 'react-icons/ai';

import { useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';

import Breadcrumb from 'components/breadcrumbs';
import MultiTemplateModal from 'components/templates/multiTemplateModal';
import { MODULELIST } from 'constants/types';

const TemplateManagementHeader = ({
  getAllTemplates,
  className,
}: {
  getAllTemplates: () => void;
  className: any;
}) => {
  const permissions = useAppSelector(userPermissions);

  const [multiTemplateModal, setMultiTemplateModal] = useState<boolean>(false);

  return (
    <>
      <Box position='relative'>
        <Breadcrumb nested='Template Management' showNested />
        {permissions[MODULELIST.TEMPLATE].includes('create') && (
          <button
            className={className}
            onClick={() => setMultiTemplateModal(true)}
          >
            <AiFillPlusCircle />
            <Typography color='#fff'>
              Want to add multiple templates?
            </Typography>
          </button>
        )}
      </Box>

      <MultiTemplateModal
        open={multiTemplateModal}
        onClose={() => setMultiTemplateModal(false)}
        getAllTemplates={getAllTemplates}
      />
    </>
  );
};
export default TemplateManagementHeader;
