import { Box, Typography } from '@mui/material';

export default function LatestNote({
  note,
  showMore,
  onSeeMore,
}: {
  note: string;
  showMore: boolean;
  onSeeMore: () => void;
}) {
  return (
    <Box>
      {note ? (
        <>
          {note.length > 20 ? (
            <>
              {showMore ? (
                <Typography>
                  {`${note} `}
                  <ShowHide showMore={showMore} onSeeMore={onSeeMore} />
                </Typography>
              ) : (
                <Typography>
                  {`${note.substring(0, 20)}... `}
                  <ShowHide showMore={showMore} onSeeMore={onSeeMore} />
                </Typography>
              )}
            </>
          ) : (
            <Typography>{note}</Typography>
          )}
        </>
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
}

const ShowHide = ({
  onSeeMore,
  showMore,
}: {
  onSeeMore: () => void;
  showMore: boolean;
}) => (
  <Typography
    sx={{
      fontSize: '0.9rem',
      textDecoration: 'underline',
      color: '#004357',
    }}
    component='span'
    onClick={(e: any) => {
      e.stopPropagation();
      onSeeMore();
    }}
  >
    {!showMore ? 'see more' : 'hide'}
  </Typography>
);
