import {
  ChannelSummary,
  ChannelLevelDetails,
} from "components/strategyDetails";

const SingleStrategy = () => {
  return (
    <div>
      <ChannelSummary />
      <ChannelLevelDetails />
    </div>
  );
};

export default SingleStrategy;
