import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCustomerPaymentDetails } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';

export default function PaymentLevelDetails() {
  const dispatch = useAppDispatch();
  const customerOdfID = useAppSelector((state) => state.customer.customerOdfID);

  const [paymentDetails, setPaymentDetails] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPaymentDetails = React.useCallback(async () => {
    setLoading(true);
    try {
      const { data, meta } = await dispatch(
        getCustomerPaymentDetails({ odf_customer_id: customerOdfID })
      ).unwrap();
      if (meta?.success) {
        setPaymentDetails(data?.payment_details);
      }
    } catch (error) {}
    setLoading(false);
  }, [customerOdfID, dispatch]);

  React.useEffect(() => {
    fetchPaymentDetails();
  }, [fetchPaymentDetails]);

  // if (!data) return <></>;

  // console.log({ paymentDetails });

  return (
    <>
      <Loader loading={loading} />
      <Box
        sx={{
          background: 'rgba(255, 182, 0, 0.31)',
          // background: 'rgba(106, 197, 171, 0.31)',
          borderRadius: '9px',
          padding: '5px 10px',
          marginBottom: '2rem',

          '& a': {
            color: '#24254A',
          },
        }}
      >
        <Typography color='#24254A'>
          No special payment plan is active. Customer has to follow usual
          payment plan
        </Typography>
        {/* <Typography color='#24254A'>
          Custom payment plan is on for this customer.{' '}
          <a href='#'>Click here</a> to view to the details.
        </Typography> */}
      </Box>
      <TableContainer sx={{ width: 400 }}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
              textAlign: 'center',
            },
          }}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color='#115180'>PaymentID</Typography>
              </TableCell>
              <TableCell>
                <Typography color='#115180'>Amount</Typography>
              </TableCell>
              <TableCell>
                <Typography color='#115180'>Timestamp</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paymentDetails?.map((payment: any, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{payment?.PaymentID}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {payment?.payment_amount
                        ? `$ ${payment?.payment_amount?.toLocaleString()}`
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {payment?.payment_date
                        ? moment(payment?.payment_date).format('DD/MM/YYYY')
                        : '-'}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
