import { styled, Typography } from '@mui/material';
import Select from 'react-select';
import { kollectAITheme } from 'config/theme';
import { SelectOptionType } from 'constants/types';

interface CreateDuplicateSelectProps {
  label: string;
  options: SelectOptionType[];
  selected: SelectOptionType | '';
  onChange: (x: any) => void;
}

const CreateDuplicateSelectWrapper = styled('div')({
  display: 'flex',
  marginBottom: '1rem',
  opacity: '0.6',

  '& label': {
    minWidth: '250px',
  },
});

const styles = {
  control: (styles: any) => ({
    ...styles,
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    width: '295px',
    outline: 'transparent',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#000',
    fontSize: '0.8rem',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#000',
  }),
  menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};

const CreateDuplicateSelect: React.FC<CreateDuplicateSelectProps> = ({
  label,
  options,
  selected,
  onChange,
}) => {
  return (
    <CreateDuplicateSelectWrapper>
      <label>
        <Typography sx={{ fontSize: '1rem' }}>{label}</Typography>
      </label>
      <Select
        value={selected}
        onChange={async (value: any) => onChange(value)}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={styles}
        menuPortalTarget={document.body}
      />
    </CreateDuplicateSelectWrapper>
  );
};

export default CreateDuplicateSelect;
