import Cookies from 'js-cookie';
import moment from 'moment';

const cookieDomain = 'http://localhost:3000/';
export const cookieName = 'kollectAiUserData';

export const isAuthenticated = () => {
  const token = getAccessToken();
  return Boolean(token);
};

export const getUserCookie = () => {
  try {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
      const parsedData = JSON.parse(cookie) || {};
      return parsedData;
    }
  } catch (err) {
    return false;
  }
};

/**
 *
 * @param {{
 *  access: string;
 *  tags: string[];
 *  username: string;
 *  fullName: string;
 * }} param0
 */
export const setUserCookie = (userData: any, name = cookieName) => {
  if (userData?.access_token) {
    Cookies.set(name, JSON.stringify(userData), {
      // domain: cookieDomain,
      // 24 hours
      expires: 1,
    });
  } else {
    Cookies.remove(name);
    Cookies.remove(name, {
      domain: cookieDomain,
    });
  }
};

export const setCookie = (name: any, data: any) => {
  if (data) {
    Cookies.set(name, JSON.stringify(data), {
      expires: 1,
    });
  } else {
    Cookies.remove(name);
    Cookies.remove(name, {
      domain: cookieDomain,
    });
  }
};

/**
 *
 * @returns {string}
 */
export const getAccessToken = () => {
  try {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
      const parsedData = JSON.parse(cookie) || {};
      return parsedData?.access_token;
    }
  } catch (err) {
    return false;
  }
};

/**
 *
 * @returns {string}
 */
export const getCookie = (name: string) => {
  try {
    const cookie = Cookies.get(name);
    if (cookie) {
      const parsedData = JSON.parse(cookie) || {};
      return parsedData;
    }
  } catch (err) {
    return '';
  }
};

/**
 *
 * @param {import('react-router-dom').RouterProps['history']} history
 */
export const logout = () => {
  Cookies.remove(cookieName);
  Cookies.remove(cookieName, {
    domain: cookieDomain,
  });
  localStorage.removeItem('persist:root');
  window.location.replace('/login');
};

// export const setUserEmailToken = (data: string) => {
//   Cookies.set('userEmailToken', data, {
//     expires: 1,
//   });
// };

// const timerLength: number = 0.4 * 60;
export const getSecondsRemaining = () => {
  const userData = getCookie(cookieName);
  // const timeSinceLogin = Number(
  //   Math.abs(new Date().getTime() - new Date(userData?.loginTime).getTime())
  // );
  // const secondsSinceLogIn = Math.floor(timeSinceLogin / 1000);
  // const sessionSecondsRemaining = Number(timerLength - secondsSinceLogIn);

  // const start = moment(new Date(userData?.access_token_generate_time));
  // const end = moment(new Date());
  // const secondsSinceLogIn = Math.floor(
  //   moment.duration(start.diff(end)).asSeconds()
  // );

  // New //
  // const timeSinceLogin = Number(
  //   Math.abs(new Date().getTime() - new Date(userData?.loginTime).getTime())
  // );
  const timeSinceLogin = Number(
    Math.abs(
      new Date().getTime() -
        new Date(userData?.access_token_generate_time + 'Z').getTime()
    )
  );
  const secondsSinceLogIn = Math.floor(timeSinceLogin / 1000);
  const timerLength = Math.floor(
    moment
      .duration(
        moment(new Date(userData?.access_token_expiry_time)).diff(
          moment(new Date(userData?.access_token_generate_time))
        )
      )
      .asSeconds()
  );
  const sessionSecondsRemaining = Number(timerLength - secondsSinceLogIn);

  return sessionSecondsRemaining;
};
