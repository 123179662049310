import { styled } from '@mui/material';

export const EditHeader = styled('div')(
  ({ background }: { background: string }) => ({
    background,
    padding: '12px 1rem',
    marginBottom: '1rem',
    borderRadius: '8px 8px 0 0',
    '& p': { fontWeight: 600 },
  })
);

export const DeleteIconBox = styled('div')({
  display: 'inline-block',
  background: 'rgba(255, 77, 73, 0.12)',
  borderRadius: '6px',
  padding: '10px 10px 8px',
  '& svg': { fontSize: '2rem', color: '#FF3E1D' },
});

export const DeleteBackButton = styled('div')({
  cursor: 'pointer',
  '& svg': { fontSize: '1.5rem' },
});
