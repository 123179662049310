import { createAsyncThunk } from '@reduxjs/toolkit';

import customerManagementApi from 'apis/customer';
import customerManagementEndpoint from 'apis/customer/config';

export const getCustomerStats = createAsyncThunk(
  `${customerManagementEndpoint.customerStats}Get`,
  async () => {
    const { data } = await customerManagementApi.getCustomerStats();
    return data;
  }
);

export const getCustomerDetails = createAsyncThunk(
  `${customerManagementEndpoint.customerDetails}Get`,
  async (payload: { category: string; page: number }) => {
    const { data } = await customerManagementApi.getCustomerDetails(payload);
    return data;
  }
);

export const getCustomerProfile = createAsyncThunk(
  `${customerManagementEndpoint.customerProfile}Get`,
  async (payload: { customerId: string }) => {
    const { data } = await customerManagementApi.getCustomerProfile(payload);
    return data;
  }
);

export const getCustomerActivity = createAsyncThunk(
  `${customerManagementEndpoint.customerActivity}Get`,
  async (payload: { customerId: string }) => {
    const { data } = await customerManagementApi.getCustomerActivity(payload);
    return data;
  }
);

export const getCustomerRequests = createAsyncThunk(
  `${customerManagementEndpoint.customerRequests}Get`,
  async (payload: { customerId: string }) => {
    const { data } = await customerManagementApi.getCustomerRequests(payload);
    return data;
  }
);

export const getCustomerActions = createAsyncThunk(
  `${customerManagementEndpoint.customerActions}Get`,
  async (payload: { customerId: string }) => {
    const { data } = await customerManagementApi.getCustomerActions(payload);
    return data;
  }
);

export const getCustomerPreferences = createAsyncThunk(
  `${customerManagementEndpoint.customerPreferences}Get`,
  async (payload: { customerId: string; odf_customer_id: string }) => {
    const { data } = await customerManagementApi.getCustomerPreferences(
      payload
    );
    return data;
  }
);

export const updateCustomerPreferences = createAsyncThunk(
  `${customerManagementEndpoint.customerPreferences}Post`,
  async (payload: {
    customer_id: string;
    odf_customer_id: string;
    emai: boolean;
    sms: boolean;
    ivr: boolean;
    whatsapp: boolean;
  }) => {
    const { data } = await customerManagementApi.updateCustomerPreferences(
      payload
    );
    return data;
  }
);

export const postCustomerRequests = createAsyncThunk(
  `${customerManagementEndpoint.customerRequests}Post`,
  async (payload: {
    req_id?: string;
    // client_id: string;
    odf_customer_id: string;
    customer_id: string;
    priority: string;
    category: string;
    issue_title: string;
    issue_description: string;
  }) => {
    const { data } = await customerManagementApi.postCustomerRequests(payload);
    return data;
  }
);

export const postCustomerStatusUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerStatusUpdate}Post`,
  async (payload: any) => {
    const { data } = await customerManagementApi.postCustomerStatusUpdate(
      payload
    );
    return data;
  }
);

export const getCustomerQueue = createAsyncThunk(
  `${customerManagementEndpoint.customerQueue}Get`,
  async (payload: { customerId: string }) => {
    const { data } = await customerManagementApi.getCustomerQueue(payload);
    return data;
  }
);

export const addCustomerNote = createAsyncThunk(
  `${customerManagementEndpoint.customerNotes}Get`,
  async (payload: any) => {
    const { data } = await customerManagementApi.postCustomerNotes(payload);
    return data;
  }
);

export const customerQueueUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerQueue}Patch`,
  async (payload: any) => {
    const { data } = await customerManagementApi.customerQueueUpdate(payload);
    return data;
  }
);

export const customerAgentUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerAgentUpdate}Post`,
  async (payload: any) => {
    const { data } = await customerManagementApi.customerAgentUpdate(payload);
    return data;
  }
);

export const postCustomerCommunicationUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerCommunicationUpdate}Post`,
  async (payload: any) => {
    const { data } = await customerManagementApi.postCustomerCommuncationUpdate(
      payload
    );
    return data;
  }
);

export const postCustomerDelete = createAsyncThunk(
  `${customerManagementEndpoint.customerDelete}Post`,
  async (payload: any) => {
    const { data } = await customerManagementApi.postCustomerDelete(payload);
    return data;
  }
);

export const customerWorkableUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerWorkableUpdate}Patch`,
  async (payload: any) => {
    const { data } = await customerManagementApi.customerWorkableUpdate(
      payload
    );
    return data;
  }
);

export const getCustomerById = createAsyncThunk(
  `${customerManagementEndpoint.getCustomerById}Get`,
  async (payload: any) => {
    const { data } = await customerManagementApi.getCustomerById(payload);
    return data;
  }
);

export const attributeCurrentValue = createAsyncThunk(
  `${customerManagementEndpoint.customerAttribute}Get`,
  async (payload: any) => {
    const { data } = await customerManagementApi.attributeCurrentValue(payload);
    return data;
  }
);

export const customerAttributeUpdate = createAsyncThunk(
  `${customerManagementEndpoint.customerAttribute}Patch`,
  async (payload: any) => {
    const { data } = await customerManagementApi.customerAttributeUpdate(
      payload
    );
    return data;
  }
);

export const customerProfileFilter = createAsyncThunk(
  `${customerManagementEndpoint.profileFilter}Post`,
  async (payload: {
    customer_id: string;
    workflow_date?: string;
    communication_date?: string;
    communication_mode?: string;
  }) => {
    const { data } = await customerManagementApi.customerProfileFilter(payload);
    return data;
  }
);

export const customerActivityFilter = createAsyncThunk(
  `${customerManagementEndpoint.customerActivityFilter}Post`,
  async (payload: {
    customer_id: string;
    activity_filter_type: string;
    date: string;
  }) => {
    const { data } = await customerManagementApi.customerActivityFilter(
      payload
    );
    return data;
  }
);

export const getCustomerActivityDetails = createAsyncThunk(
  `${customerManagementEndpoint.customerActivityDetails}Post`,
  async (payload: {
    customer_id: string;
    id: string;
    message: string;
    step_name?: string;
  }) => {
    const { data } = await customerManagementApi.getCustomerActivityDetails(
      payload
    );
    return data;
  }
);

export const getCustomerPaymentDetails = createAsyncThunk(
  `${customerManagementEndpoint.customerPaymentDetails}Get`,
  async (payload: { odf_customer_id: string }) => {
    const { data } = await customerManagementApi.getCustomerPaymentDetails(
      payload
    );
    return data;
  }
);

export const getCustomerAgentsList = createAsyncThunk(
  `${customerManagementEndpoint.customerAgentsList}Get`,
  async (payload: { customer_id: string }) => {
    const { data } = await customerManagementApi.getCustomerAgentsList(payload);
    return data;
  }
);

export const customerRequestFilter = createAsyncThunk(
  `${customerManagementEndpoint.customerRequestFilter}Post`,
  async (payload: {
    customer_id: string;
    category: string;
    priority: string;
    date: string;
  }) => {
    const { data } = await customerManagementApi.customerRequestFilter(payload);
    return data;
  }
);

export const getHardshipDetails = createAsyncThunk(
  `${customerManagementEndpoint.hardshipDetails}Get`,
  async (payload: { customer_id: string }) => {
    const { data } = await customerManagementApi.getHardshipDetails(payload);
    return data;
  }
);

export const getAgentActionLists = createAsyncThunk(
  `${customerManagementEndpoint.agentActionLists}Get`,
  async (payload: { customer_id: string }) => {
    const { data } = await customerManagementApi.getAgentActionLists(payload);
    return data;
  }
);

export const getCallRequestAttempts = createAsyncThunk(
  `${customerManagementEndpoint.callRequestAttemts}Get`,
  async () => {
    const { data } = await customerManagementApi.getCallRequestAttempts();
    return data;
  }
);

export const getCustomerAllowedAttributes = createAsyncThunk(
  `${customerManagementEndpoint.customerAllowedAttributes}Get`,
  async (payload: { action_type: string; module: string }) => {
    const { data } = await customerManagementApi.getCustomerAllowedAttributes(
      payload
    );
    return data;
  }
);

export const getCustomerNotes = createAsyncThunk(
  `${customerManagementEndpoint.allCustomerNotes}Get`,
  async (payload: { odf_customer_id: string; customer_id: string }) => {
    const { data } = await customerManagementApi.getCustomerNotes(payload);
    return data;
  }
);
