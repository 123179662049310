import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    overViewBoxContainer: {
      display: 'inline-flex',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      padding: '1.3em 0 0.5em 0.8em',
      borderRadius: '10px',
      width: '170px',
      height: '80px',
      marginRight: '3em',
    },
    numberStyle: {
      color: (props: any) => props.color,
      marginTop: '0.4em',
    },
  })
);

export default useStyles;
