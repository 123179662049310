import { styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { updateMessageJson } from 'utils/customer/jsonMoifier';

interface FormikTemplateTextareaProps {
  name: string;
  placeholder?: string;
  fullHeight?: boolean;
  handleUpdate?: (x: string) => void;
  handleBlur?: () => void;
}

const FormikTemplateTextareaWrapper = styled('div')(
  ({ fullHeight }: { fullHeight: boolean }) => ({
    width: '100%',

    '& textarea': {
      outline: 'none',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      width: fullHeight ? '97%' : '98%',
      // width: '97%',
      // height: 'calc(100vh - 452px)',
      height: fullHeight ? 'calc(100vh - 452px)' : 50,
      padding: '10px',
      fontFamily: 'Overpass',
      fontSize: '1rem',
      color: '#000',
    },
  })
);

const FormikTemplateTextarea: React.FC<FormikTemplateTextareaProps> = ({
  name,
  placeholder = '',
  fullHeight = false,
  handleUpdate,
  handleBlur,
}) => {
  return (
    <FormikTemplateTextareaWrapper fullHeight={fullHeight}>
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const { setFieldValue, values } = form;
          const { value, name } = field;

          return (
            <div>
              <textarea
                placeholder={placeholder}
                {...field}
                value={value}
                onChange={(e) => {
                  setFieldValue(name, e.target.value);

                  if (values.messages[0].responseExpected) {
                    handleUpdate && handleUpdate(e.target.value);
                  }
                }}
                onBlur={() => {
                  handleBlur && handleBlur();
                }}
              />
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </div>
          );
        }}
      </Field>
    </FormikTemplateTextareaWrapper>
  );
};

export default FormikTemplateTextarea;
