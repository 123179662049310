import * as React from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';
import { useAppSelector } from 'hooks/useRedux';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import * as yup from 'yup';

import FormikCustomerSelect from './FormikCustomerSelect';
import { selectedCustomersArr } from 'store/reducer/customerReducer';

const levels = [
  {
    label: 'Customer Level',
    value: 'customer_level',
    tooltip: 'Will delete whole customer data; all products & loans',
  },
  {
    label: 'Product Level',
    value: 'product_level',
    tooltip: 'Will delete data specific to selected product & its loans',
  },
  {
    label: 'Loan Level',
    value: 'loan_level',
    tooltip: 'Will delete data specific to selected loan',
  },
];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#6F6F6F',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    width: 230,
  },
}));

interface FormikCustomerRadioProps {
  name: string;
  setInitials: (x: any) => void;
  setValidations: (x: any) => void;
  formikValues: any;
}

const FormikCustomerRadio: React.FC<FormikCustomerRadioProps> = ({
  name,
  setInitials,
  setValidations,
  formikValues,
}) => {
  const profileData = useAppSelector((state) => state.customer.customerProfile);
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [productOptions, setProductOptions] = React.useState<any>([]);

  React.useEffect(() => {
    let options: any = [];
    if (selectedCustomers.length) {
      let opts: any = [];
      selectedCustomers.forEach((customer: any) => {
        const { loan_details } = customer;
        loan_details.forEach((loan: any) => {
          if (!opts.includes(loan.product)) opts.push(loan.product);
        });
      });
      options = opts.map((opt: string) => ({ label: opt, value: opt }));
    } else {
      options =
        Array.isArray(profileData) &&
        profileData?.map((profile: any) => ({
          label: profile.product_suscribed,
          value: profile.product_suscribed,
        }));
    }
    setProductOptions(options);
  }, [profileData, selectedCustomers]);

  const RadioButton = (
    <Radio
      size='small'
      sx={{
        '&.Mui-checked': {
          color: kollectAITheme.primaryColor,
        },
      }}
    />
  );

  const setState = (level: string) => {
    let schema = yup.object().shape({
      level: yup.string().required('Required'),
    });
    if (level === 'customer_level') {
      setInitials({ level: 'customer_level', comments: '' });
    }
    if (level === 'product_level') {
      setInitials({ level: 'product_level', product_type: '', comments: '' });
      schema = schema.concat(
        yup.object().shape({
          product_type: yup.string().required('Required'),
        })
      );
      setValidations(schema);
    }
    if (level === 'loan_level') {
      setInitials({
        level: 'loan_level',
        product_type: '',
        loan_id: '',
        comments: '',
      });
      schema = schema.concat(
        yup.object().shape({
          product_type: yup.string().required('Required'),
          loan_id: yup.string().required('Required'),
        })
      );
      setValidations(schema);
    }
  };

  const getLoanOptions = () => {
    let loans: any[] = [];
    if (selectedCustomers.length) {
      selectedCustomers.forEach((customer) => {
        customer?.loan_details?.forEach((loan: any) => {
          if (loan?.product === formikValues.product_type)
            loans.push({ loan_id: loan?.odf_loan_id || '' });
        });
      });
    } else {
      loans =
        Array.isArray(profileData) &&
        profileData?.find(
          (profile) => profile.product_suscribed === formikValues.product_type
        )?.loan_details;
    }

    if (loans) {
      return loans?.map((l: any) => ({
        label: l?.loan_id,
        value: l?.loan_id,
      }));
    }
    return [];
  };

  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        const { value } = field;

        return (
          <>
            <Box display='flex' alignItems='center' gap='10px'>
              <RadioGroup
                {...field}
                value={value}
                onChange={(e: any) => setState(e.target.value)}
                sx={{ width: '100%' }}
              >
                {levels.map((level, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '2rem',
                    }}
                    key={index}
                  >
                    <Box display='flex' alignItems='center'>
                      <FormControlLabel
                        value={level.value}
                        control={RadioButton}
                        label={level.label}
                        sx={{ width: 155 }}
                      />
                      <LightTooltip title={level.tooltip} placement='right'>
                        <Box>
                          <BsFillInfoCircleFill color='rgba(0, 67, 87, 0.37)' />
                        </Box>
                      </LightTooltip>
                    </Box>

                    {value === 'product_level' &&
                      level.value === 'product_level' && (
                        <FormikCustomerSelect
                          name='product_type'
                          options={productOptions}
                        />
                      )}

                    {value === 'loan_level' && level.value === 'loan_level' && (
                      <Box display='flex' alignItems='center' gap='1rem'>
                        <FormikCustomerSelect
                          name='product_type'
                          placeholder='Select Product'
                          options={productOptions}
                          width={170}
                        />
                        <FormikCustomerSelect
                          name='loan_id'
                          placeholder='Select LoanID'
                          options={getLoanOptions()}
                          width={170}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </Box>
          </>
        );
      }}
    </Field>
  );
};

export default React.memo(FormikCustomerRadio);
