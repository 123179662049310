import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const HtmlPreviewButton = styled('div')(
  ({ active }: { active: boolean }) => ({
    background: active ? '#2E384D' : '#EBEEF2',
    padding: active ? '8px 1.5rem' : '8px 1rem',
    borderRadius: '20px',
    color: active ? '#fff' : '#8F9499',
    cursor: 'pointer',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameFieldCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    allowedVariableText: {
      color: '#0F91D2',
      textDecoration: 'underline',
    },
    unmapMessage: {
      fontStyle: 'italic',
      color: '#E92C2C',
      fontSize: '0.9rem',
      marginTop: '2px',

      '& span': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    htmlMessageHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #B7B7B7',
      padding: '8px 1.5rem 6px',
      background: '#fff',
    },
    htmlMessageHeaderIconCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',

      '& svg': {
        fontSize: '1.8rem',
      },
    },
    previewHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    pdfDowloadButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '2px',

      '& svg': {
        color: '#2E384D',
        fontSize: '1.8rem',
      },
      '& p': {
        color: '#2E384D',
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
    htmlViewCont: {
      display: 'inline-flex',
      alignItems: 'center',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
    htmlDeviceCont: {
      '& svg': { fontSize: '1.5rem' },
    },
    previewCont: {
      marginTop: '1rem',
      height: 'calc(100vh - 318px)',
      // border: '1px solid #000',
      background: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      overflow: 'auto',
    },
  })
);

export default useStyles;
