import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import { Field, FieldProps } from 'formik';

interface SelectProps {
  placeholder?: string;
  label?: string;
  width?: number;
  name: string;
  options: any;
}

const CustomerCallSelect: React.FC<SelectProps> = ({
  placeholder,
  label,
  width = 200,
  name,
  options,
}) => {
  return (
    <Box mb='1rem'>
      <label>
        <Typography
          sx={{ opacity: '0.6', fontFamily: 'Overpass', fontSize: '0.9rem' }}
          mb='5px'
        >
          {label}
        </Typography>
      </label>

      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value, name } = field;

          return (
            <FormControl sx={{ width: '100%', zIndex: 10 }}>
              <Select
                displayEmpty
                value={value || ''}
                onChange={(event: SelectChangeEvent) => {
                  const { value } = event.target;
                  setFieldValue(name, value);
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  '.MuiSelect-select': {
                    padding: '9px 10px 7px 14px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <MenuItem disabled value=''>
                  <Typography color='#757575'>{placeholder}</Typography>
                </MenuItem>
                {options?.map((type: any, index: number) => (
                  <MenuItem value={type.value} key={index}>
                    {type?.label}
                  </MenuItem>
                ))}
              </Select>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          );
        }}
      </Field>
    </Box>
  );
};

export default CustomerCallSelect;
