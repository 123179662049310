import api from '../api';
import kollectAiWorkflow from './config';

const workflowApi = {
  async getAllWorkflow() {
    return api.get(kollectAiWorkflow.getWorkflow);
  },
  async createWorkflow(payload: any) {
    return api.post(kollectAiWorkflow.createWorkflow, payload);
  },
  async getWorkflowById(payload: any) {
    const url = `${kollectAiWorkflow.getWorkflowById}?workflow_id=${payload.workflow_id}`;
    return api.get(url);
  },
  async workflowHandler(payload: any) {
    const url = `${kollectAiWorkflow.workflowHandler}?workflow_id=${payload.workflow_id}`;
    return api.post(url, payload);
  },
  async getStrategyGroup() {
    return api.get(kollectAiWorkflow.getStrategyGroup);
  },
  async getStrategyByGroupId(payload: any) {
    const url = `${kollectAiWorkflow.getStrategyByGroupId}?strategy_group_id=${payload.strategy_group_id}`;
    return api.get(url);
  },
  async filterWorkflow(payload: any) {
    let url = '';
    if (payload.field_name === 'created') {
      url = `${kollectAiWorkflow.filterWorkflow}?From=${payload.startDate}&To=${payload.endDate}`;
    } else {
      url = `${kollectAiWorkflow.filterWorkflow}?${payload.field_name}=${payload.value}`;
    }

    return api.get(url);
  },
  async getFunnelMapping(payload: any) {
    const url = `${kollectAiWorkflow.getFunnelMapping}?workflow_id=${payload.workflow_id}&date=${payload.date}&execution_id=${payload.execution_id}`;
    return api.get(url);
  },
  async getSpecificFunnel(payload: any) {
    const url = `${kollectAiWorkflow.getSpecificFunnel}?workflow_id=${payload.workflowId}&date=${payload.date}&execution_id=${payload.executionId}&step_name=${payload.stepName}`;
    return api.get(url);
  },
  async workflowCustomerDetails(payload: any) {
    const url = `${kollectAiWorkflow.workflowCustomerDetails}?customer_id=${payload.customerIds}`;
    return api.get(url);
  },
  async runWorkflow(payload: any) {
    return api.post(kollectAiWorkflow.runWorkflow, payload);
  },
  async allWorkflowExecution(payload: { workflowId: string; date: string }) {
    const url = `${kollectAiWorkflow.allWorkflowExecution}?workflow_id=${payload.workflowId}&date=${payload.date}`;
    return api.get(url);
  },

  // async getworkflowProperties(payload: { action_type: string }) {
  //   const url = `${kollectAiWorkflow.workflowProperties}?action_type=${payload.action_type}`;
  //   return api.get(url);
  // },
  async getworkflowProperties(payload: {
    action_type: string;
    module?: string;
  }) {
    // const url = `${kollectAiWorkflow.workflowProperties}?action_type=${payload.action_type}`;
    return api.get(kollectAiWorkflow.workflowProperties, { params: payload });
  },
};

export default workflowApi;
