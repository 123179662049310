import { Box, Typography, styled } from '@mui/material';
import { Field, FieldProps } from 'formik';

const InputWrapper = styled('div')({
  '& input': {
    border: '1px solid #D4DCEB',
    padding: '10px 0.7rem 7px',
    fontFamily: 'Overpass',
    outline: 'none',
    fontSize: '0.9rem',
    borderRadius: '5px',
    background: 'transparent',
    width: '90%',
  },
});

interface InputProps {
  label?: string;
  name: string;
  placeholder?: string;
  type?: string;
}

const CustomerCallInput: React.FC<InputProps> = ({
  label,
  name,
  placeholder = '',
  type = 'text',
}) => {
  return (
    <Box mb='1rem'>
      <label>
        <Typography
          sx={{ opacity: '0.6', fontFamily: 'Overpass', fontSize: '0.9rem' }}
          mb='5px'
        >
          {label}
        </Typography>
      </label>

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <InputWrapper>
            <input type={type} placeholder={placeholder} {...field} />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </InputWrapper>
        )}
      </Field>
    </Box>
  );
};

export default CustomerCallInput;
