export const kollectAiTemplate = {
  viewBucketChannelTemplate:
    '/kollectai/api/v1/template/view-bucket-channel-template',
  viewChannelTemplateTwoWay:
    '/kollectai/api/v1/template/view-bucket-channel-template-two-way',
  fetchVariableResolverAttributes:
    '/kollectai/api/v1/template/variable_resolver',
  bulkTemplateUpload: '/kollectai/api/v1/template/upload',
  validateTemplate: '/kollectai/api/v1/template/validate_template',
  templateAttributes: '/kollectai/api/v1/template/get_template_attributes',
};

export default kollectAiTemplate;
