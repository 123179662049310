import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    preferenceBoxWrapper: {
      borderRadius: '18px',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      background: '#fff',
      border: '1px solid #efefef',
      padding: '16px 0 16px 25px',
      marginBottom: '10px',
    },
    subscriptionText: {
      background: (props: any) => (props.subscription ? '#6AC5AB' : '#6F6F6F'),
      borderRadius: '5px',
      padding: '2px 10px 0',
      color: '#fff',
    },
    switchStyle: {
      width: 80,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(30px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#D9D9D9',
          },
        },
      },
      '& .Mui-checked .MuiSwitch-thumb': {
        backgroundColor: '#6AC5AB',
        width: 22,
        height: 22,
      },
      '&  .MuiSwitch-thumb': {
        backgroundColor: '#6F6F6F',
        minWidth: 22,
        minHeight: 22,
        marginTop: 8,
      },

      '& .MuiSwitch-track': {
        opacity: 1,
        width: 36,
        height: 16,
        backgroundColor: '#D9D9D9',
        borderRadius: 20 / 2,
        boxShadow:
          'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      },

      '& .MuiFormControlLabel-labelPlacementStart': {
        gap: '5px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '50px',
        color: 'red',
      },
    },
  })
);

export default useStyles;
