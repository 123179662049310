import React from 'react';
import { Box } from '@mui/material';
import {
  FormikCustomerCheckbox,
  FormikCustomerDatePicker,
  FormikCustomerTimePicker,
} from 'components/formik/customer';
import FormikCustomerDaySelector from 'components/formik/customer/FormikCustomerDaySelector';
import FormikCustomerTimeSlot from 'components/formik/customer/FormikCustomerTimeSlot';

const CallRequired = ({ values }: { values: any }) => {
  return (
    <>
      <Box
        sx={{
          marginBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormikCustomerCheckbox
          name='hasPreferredDate'
          label='Has preferred date for calling'
        />
        {values?.hasPreferredDate && (
          <FormikCustomerDatePicker name='prefferedDate' minDate={new Date()} />
        )}
      </Box>

      <Box
        sx={{
          marginBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormikCustomerCheckbox
          name='hasPreferredTime'
          label='Has preferred time for calling'
        />
        {values?.hasPreferredTime && (
          <FormikCustomerTimePicker name='prefferedTime' />
        )}
      </Box>
      <Box
        sx={{
          marginBottom: '1rem',
        }}
      >
        <FormikCustomerCheckbox
          name='hasPreferredDays'
          label='Has preferred day(s) for calling'
        />
        {values?.hasPreferredDays && (
          <Box ml={5}>
            <FormikCustomerDaySelector name='prefferedDay' />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          marginBottom: '1rem',
        }}
      >
        <FormikCustomerCheckbox
          name='hasPreferredTimeSlot'
          label='Has preferred time slot(s) for calling'
        />
        {values?.hasPreferredTimeSlot && (
          <Box ml={5}>
            <FormikCustomerTimeSlot name='prefferedTimeSlot' />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CallRequired;
