import { useState } from 'react';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Dialog,
} from '@mui/material';
import Breadcrumb from 'components/breadcrumbs';
import useStyles from './index.styles';

import CampaignDashboardFilter from './FilterOption';
import CampaignDashboardDetails from './Details';

function createData(
  campaignType: string,
  campaignName: string,
  campaignDate: string,
  totalAttempts: number,
  totalConnected: number
) {
  return {
    campaignType,
    campaignName,
    campaignDate,
    totalAttempts,
    totalConnected,
  };
}

const rows = [
  createData('Daily Segment', 'Campaign 1', '12.03.2022; 12:00', 24, 40),
  createData('One time Campaign', 'Campaign 2', '12.03.2022; 12:00', 37, 43),
  createData('Daily Segment', 'Campaign 3', '12.03.2022; 12:00', 24, 60),
  createData('Daily Segment', 'Campaign 4', '12.03.2022; 12:00', 67, 43),
  createData('One time Campaign', 'Campaign 5', '12.03.2022; 12:00', 49, 39),
];

const CampaignDashboardScreen = () => {
  const classes = useStyles();

  const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);

  const handlePagination = (event: any, pageNumber: number) => {
    console.log(pageNumber);
  };

  const handleDetailsDialogClose = () => {
    setOpenDetailsDialog(false);
  };

  return (
    <div className={classes.campaignDashboardWrapper}>
      <Breadcrumb main='Dashboard' nested='Campaign Dashboard' showNested />
      <Card sx={{ overflow: 'visible' }}>
        <CampaignDashboardFilter />
        <Box
          sx={{ width: ' 90%', margin: '2rem auto 0', paddingBottom: '1rem' }}
        >
          <TableContainer
            sx={{
              borderTop: '1px solid #E5E5E5',
            }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Campaign Type</TableCell>
                  <TableCell align='left'>Campaign Name</TableCell>
                  <TableCell align='left'>Campaign Date</TableCell>
                  <TableCell align='left'>Total Attempts</TableCell>
                  <TableCell align='left'>Total Connected</TableCell>
                  <TableCell align='left'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.campaignType}
                    </TableCell>
                    <TableCell align='left'>{row.campaignName}</TableCell>
                    <TableCell align='left'>{row.campaignDate}</TableCell>
                    <TableCell align='left'>{row.totalAttempts}</TableCell>
                    <TableCell align='left'>{row.totalConnected}</TableCell>
                    <TableCell align='left'>
                      <button
                        className={classes.detailsButtonStyle}
                        onClick={() => setOpenDetailsDialog(true)}
                      >
                        More Details
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}
          >
            <Pagination
              count={10}
              variant='outlined'
              shape='rounded'
              size='small'
              onChange={handlePagination}
            />
          </Box>
        </Box>

        <Dialog
          maxWidth='lg'
          open={openDetailsDialog}
          onClose={handleDetailsDialogClose}
        >
          <CampaignDashboardDetails />
        </Dialog>
      </Card>
    </div>
  );
};

export default CampaignDashboardScreen;
