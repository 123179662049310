import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import TemplatePreviewModal from 'components/templates/templatePreview';
import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { GrLink } from 'react-icons/gr';

interface ChannelSelectBoxProps {
  channel: string;
  index: number;
  channelTemplate: any;
  selectedData: any;
  disabled: boolean;
  handleChange: (x: string, y?: string) => void;
}

const ChannelSelectBox: React.FC<ChannelSelectBoxProps> = ({
  channel,
  index,
  channelTemplate,
  selectedData,
  handleChange,
  disabled,
}) => {
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>({ template: '', channel: '' });

  const isSelected =
    selectedData &&
    selectedData[index] &&
    Object.keys(selectedData[index]).includes(channel);

  const selectedTemplate =
    (selectedData && selectedData?.[index] && selectedData[index][channel]) ||
    '';

  const options = channelTemplate.map((item: any) => ({
    label: item.template_name,
    value: item.id,
  }));

  const handlePreview = () => {
    const template = channelTemplate.find(
      (item: any) => item.id === selectedTemplate
    );
    if (template) {
      setPreview({ template: template, channel });
      setOpenPreview(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          background: '#FFFFFF',
          border: '0.5px solid #E6E6E6',
          padding: '5px 10px',
          filter: 'drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2))',
          borderRadius: '14px',
        }}
      >
        <Checkbox
          checked={isSelected || false}
          onChange={() => handleChange(channel)}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ '&.Mui-checked': { color: '#004357' } }}
          disabled={disabled}
        />
        <Typography variant='overline'>{channel}</Typography>

        <FormControl sx={{ width: 120, zIndex: 10 }}>
          <Select
            disabled={disabled}
            displayEmpty
            value={selectedTemplate}
            onChange={(event: SelectChangeEvent) => {
              const { value } = event.target;
              handleChange(channel, value);
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              position: 'relative',
              borderRadius: '4px',
              textAlign: 'center',
              '.MuiSelect-select': {
                padding: '7px 10px 7px 10px;',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.3)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.3)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.3)',
              },
            }}
            IconComponent={() => (
              <FiChevronDown
                style={{
                  fontSize: '1rem',
                  marginRight: '0.8rem',
                  position: 'absolute',
                  right: 0,
                  top: 12,
                }}
              />
            )}
          >
            {options?.map((type: any, index: number) => (
              <MenuItem value={type.value} key={index}>
                {type?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ cursor: 'pointer' }} onClick={handlePreview}>
          <GrLink color='#004357' fontSize='1.2rem' />
        </Box>
      </Box>

      <TemplatePreviewModal
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          setPreview({ template: '', channel: '' });
        }}
        selectedOption={preview.template}
        channel={preview.channel}
      />
    </>
  );
};

export default ChannelSelectBox;
