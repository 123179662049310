import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    channelSummaryCalendarContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      // margin: '2rem 2rem 0 4rem',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
    },
    channelBoxContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      columnGap: '1rem',
      marginBottom: '1rem',
    },
    dpdBoxDialogCustomizedWidth: {
      maxWidth: '45%',
    },
    dpdBoxDialogBoxWrapper: {
      padding: '2rem',
    },
    dpdBoxDialogHeader: {
      fontSize: '1.3em',
      fontWeight: 600,
      lineHeight: '35px',
      marginBottom: '1.5rem',
    },
    dpdBoxTemplateSelect: {
      display: 'flex',
      justifyContent: 'center',
    },
    dpdBoxTemplateDisplay: {
      maxWidth: '55%',
      border: '1px solid #2C62EC',
      margin: '1rem auto',
      padding: '2rem',
      minHeight: '250px',
      borderRadius: '5px',
      maxHeight: '200px',
      overflowY: 'auto',
    },
    dpdBoxDialogButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    dpdBoxDialogButton: {
      background: 'transparent',
      border: '2px solid #2C62EC',
      borderRadius: '30px',
      padding: '0.5em 3em',
      color: '#2C62EC',
      textTransform: 'capitalize',
      marginRight: '1rem',
      cursor: 'pointer',
    },
    dpdBoxDialogSaveButton: {
      background: '#2C62EC',
      color: '#fff',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    selectedTextStyle: {
      width: '250px',
    },
  })
);

export default useStyles;
