import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppSelector } from 'hooks/useRedux';

import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';

import WorkflowActionButtons from '../actionButtons';

interface RunWorkflowDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const RunWorkflowDrawer: React.FC<RunWorkflowDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const allWorkflowList: any = useAppSelector(
    (state: any) => state.workflow.allWorkflow
  );
  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const graphData: any = getGraphData();

  const [workflowOptions, setworkflowOptions] = useState<any>([]);

  useEffect(() => {
    const { workflow_id } = graphData;
    const filteredWorkflow = allWorkflowList.filter(
      (list: any) => list.workflow_id !== workflow_id
    );

    const publishedWorkflows = filteredWorkflow.filter(
      (workflow: any) => workflow.published
    );

    const options = publishedWorkflows.map((workflow: any) => {
      const { workflow_id, workflow_name } = workflow;
      return {
        label: workflow_name,
        value: workflow_id,
      };
    });
    setworkflowOptions(options);
  }, []);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName, workflow } = values;
    const configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'RUN_WORKFLOW',
          workflow,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'workflow');
      updateWorkflow(configObj, keys, values.workflow);
    }

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      workflow: yup.string().required('Required'),
    })
  );

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
        workflow: workflowActionData?.workflow,
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form
          style={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              placeholder='Precursor Name'
              disabled={true}
            />

            <CustomFormikWorkflowSelect
              name='workflow'
              placeholder='Select Worflow'
              label='Select Workflow'
              options={workflowOptions}
            />
          </Box>

          {!graphData.published && (
            <WorkflowActionButtons
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RunWorkflowDrawer;
