import { useCallback, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Typography } from '@mui/material';
import { CiCalendar, CiSearch } from 'react-icons/ci';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import moment from 'moment';
import useStyles from './style';
import { kollectAITheme } from 'config/theme';
import useClickOutside from 'hooks/useClickOutside';

type filterOptionType = {
  label: string;
  placeholder: string;
};

const filterOptions: filterOptionType[] = [
  { label: 'name', placeholder: 'Name of the campaign' },
  { label: 'group_type', placeholder: 'Based on group type' },
  { label: 'created_at', placeholder: 'Enter the date range' },
  { label: 'schedule_time', placeholder: 'Enter the schedule time' },
];

const SearchCampaign = ({
  onSearch,
}: {
  onSearch: (x: string, y: any) => void;
}) => {
  const classes = useStyles();
  const filterRef = useRef<HTMLDivElement>(null);

  const [selectedOption, setSelectedOption] = useState<filterOptionType>(
    filterOptions[0]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [createdDate, setCreatedDate] = useState<Date | null>(null);

  const handleOptions = () => {
    setSearchValue('');
    setCreatedDate(null);
    setShowOptions(!showOptions);
  };

  const handleSearch = (option?: any, isEmpty?: boolean) => {
    const param = (option && option?.label) || selectedOption?.label;
    let value: any = isEmpty ? '' : searchValue;
    console.log({ value, param, option });
    if (['created_at', 'schedule_time'].includes(param)) {
      value = createdDate ? moment(createdDate).format('YYYY-MM-DD') : '';
    }
    onSearch(param, value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const onClearFilter = useCallback(() => {
    if (searchValue || createdDate) {
      onSearch('name', '');
      setSearchValue('');
      setCreatedDate(null);
    }
  }, [onSearch, searchValue, createdDate]);

  const clearDisabled = useMemo(
    () => (searchValue || createdDate ? false : true),
    [createdDate, searchValue]
  );

  useClickOutside(filterRef, () => showOptions && setShowOptions(false));

  return (
    <Box sx={{ position: 'relative' }}>
      <Box className={classes.searchbarCont}>
        <Typography className={classes.searchbarLabel} onClick={handleOptions}>
          {selectedOption?.label}:
        </Typography>

        {!['created_at', 'schedule_time'].includes(selectedOption?.label) ? (
          <input
            type='text'
            placeholder={selectedOption?.placeholder}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value === '') {
                handleSearch(selectedOption, true);
              }
            }}
            className={classes.searchbarInput}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Box className={classes.searchDatepickerStyle}>
              <DatePicker
                selected={createdDate}
                onChange={(date: Date) => setCreatedDate(date)}
                placeholderText='Date'
              />
              <CiCalendar />
            </Box>
          </Box>
        )}

        <button onClick={handleOptions}>
          {showOptions ? (
            <FiChevronLeft fontSize={20} />
          ) : (
            <FiChevronDown fontSize={20} />
          )}
        </button>

        <button onClick={handleSearch}>
          <CiSearch
            color={kollectAITheme.primaryColor}
            fontWeight={600}
            fontSize={20}
          />
        </button>

        <button disabled={clearDisabled} onClick={onClearFilter}>
          <Typography className={classes.clearFilter}>Clear filter</Typography>
        </button>
      </Box>

      {showOptions && (
        <Box ref={filterRef} className={classes.searchOptionsCont}>
          <Typography sx={{ marginBottom: '0.8rem' }}>SEARCH USING</Typography>
          {filterOptions.map((option: filterOptionType, index: number) => {
            const { label, placeholder } = option;
            return (
              <Box
                key={index}
                className={classes.searchOptionStyle}
                onClick={() => {
                  setSelectedOption(option);
                  setShowOptions(false);
                  handleSearch(option);
                }}
              >
                <Typography className={classes.searchOptionLabel}>
                  {label}:
                </Typography>
                <Typography color={'#8593B5'}>{placeholder}</Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SearchCampaign;
