import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerDetailedInfoBox from '../../CustomerDetailedInfo';
import useStyles from './index.styles';
import HardshipDetailsModal from './hardshipDetailsModal';
import {
  hardshipDetails,
  setSelectedCustomerProduct,
} from 'store/reducer/customerReducer';
import CallAttempt from './callAttempt';

const CustomerStatusAttributes = ({
  status,
  selectedProduct,
  setSelectedProduct,
  setSelectedProfileData,
  selectedProfileData,
  ...props
}: {
  status: string;
  selectedProduct: string;
  setSelectedProduct: (x: string) => void;
  setSelectedProfileData: (x: any) => void;
  selectedProfileData: any;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: customerId } = useParams();
  const profileData: any = useAppSelector(
    (state) => state.customer.customerProfile
  );

  const [viewHardship, setViewHardship] = useState<boolean>(false);
  const [viewData, setViewData] = useState<any>([]);

  const hardshipData = useAppSelector(hardshipDetails);

  useEffect(() => {
    const { product_suscribed } = props as any;
    product_suscribed && setSelectedProduct(product_suscribed);
  }, [props, setSelectedProduct]);

  useEffect(() => {
    if ((props as any).current_status === 'Hardship') {
      setViewData(hardshipData);
    } else {
      setViewData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hardshipData]);

  const getStatus = () => {
    const formatDate = moment(status_start_date).format('DD/MM/YYYY');
    if (current_status === 'Not_engaged') {
      const date = formatDate === 'Invalid date' ? '' : `since ${formatDate}`;
      return `Not Engaged since ${date}`;
    }
    if (current_status === 'Hardship') {
      const date = formatDate === 'Invalid date' ? '' : `${formatDate}`;
      return `In Hardship ${date}`;
    }
    if (current_status === 'Call_Required') {
      const date = formatDate === 'Invalid date' ? '' : `on ${formatDate}`;
      return `Call Requested ${date}`;
    }
    if (current_status === 'PTP') return 'PTP';
    // if (current_status === 'Broken Promise') return 'Broken PTP';
    if (current_status === 'Broken_Promise') return 'PTP Broken';
    if (current_status === 'Bad_Contact') return 'Bad Contact';
    if (current_status === 'Default') return 'Default';
    return 'All';
  };

  const onChangeSubscribedProduct = (product: any) => {
    setSelectedProfileData(product);
    dispatch(setSelectedCustomerProduct(product?.product_suscribed || ''));
  };

  const getPTPUpcomingDays = () => {
    let desc = '',
      isDelayed = false;
    if (new Date(ptp_commitment_date).valueOf() >= Date.now().valueOf()) {
      desc = `Coming in ${moment(ptp_commitment_date).diff(
        Date.now(),
        'days'
      )} days`;
    } else {
      isDelayed = true;
      desc = `Delayed by ${moment(Date.now()).diff(
        ptp_commitment_date,
        'days'
      )} days`;
    }
    return { desc, isDelayed };
  };

  const getCallPreferenceDays = () => {
    let desc = '',
      isPending = false;

    if (call_preference_date && call_preference_date !== '-') {
      if (new Date(call_preference_date).valueOf() >= Date.now().valueOf()) {
        desc = `Coming in ${moment(call_preference_date).diff(
          Date.now(),
          'days'
        )} days`;
      } else {
        isPending = true;
        desc = `Delayed by ${moment(Date.now()).diff(
          call_preference_date,
          'days'
        )} days`;
      }
    }
    return { desc, isPending };
  };

  if (!props) return <></>;

  const {
    outstanding_due,
    current_status,
    status_start_date,
    ptp_commitment_date,
    call_preference_date,
  } = props as any;

  return (
    <Box mt='1rem'>
      <CustomerDetailedInfoBox
        label='Customer Current Status'
        mb='1rem'
        children={
          <Box>
            <Typography
              sx={{ color: '#24254A', fontWeight: 700, fontSize: '1.1rem' }}
            >
              {getStatus()}
            </Typography>
          </Box>
        }
      />

      {current_status === 'PTP' && (
        <CustomerDetailedInfoBox
          label='Promised to pay by'
          mb='1rem'
          children={
            <Box display='flex' alignItems='center' gap='1rem'>
              <Typography
                className={classes.customerStatusStyle}
                bgcolor={
                  getPTPUpcomingDays().isDelayed
                    ? 'rgba(218, 160, 154, 0.52)'
                    : 'rgba(255, 182, 0, 0.31)'
                }
              >
                {getPTPUpcomingDays().desc}
              </Typography>
              <Typography>
                {moment(ptp_commitment_date).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          }
        />
      )}

      {/* {current_status === 'Broken_Promise' && (
        <CustomerDetailedInfoBox
          label='Promise to pay by'
          mb='1rem'
          children={
            ptp_commitment_date === '-' ? (
              <Typography>-</Typography>
            ) : (
              <Box display='flex' alignItems='center' gap='1rem'>
                <Typography
                  className={classes.customerStatusStyle}
                  sx={{ background: 'rgba(218, 160, 154, 0.52)' }}
                >
                  Delayed by{' '}
                  {moment
                    .duration(moment(Date.now()).diff(ptp_commitment_date))
                    .asDays()}{' '}
                  days
                </Typography>
                <Typography>
                  {moment(ptp_commitment_date).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            )
          }
        />
      )} */}

      {current_status === 'Hardship' && (
        <CustomerDetailedInfoBox
          // label='Hardship Category'
          label='Hardship Details'
          mb='1rem'
          // children={<Typography>NA</Typography>}
          children={
            <Box display='flex' justifyContent='end'>
              <Typography
                sx={{
                  textDecoration: 'underline',
                  color: kollectAITheme.primaryColor,
                  cursor: 'pointer',
                }}
                onClick={() => setViewHardship(true)}
              >
                View Details
              </Typography>
            </Box>
          }
        />
      )}

      {current_status === 'Call_Required' && (
        <>
          <CustomerDetailedInfoBox
            label='Call attempt already made'
            mb='1rem'
            children={<CallAttempt />}
          />

          {customerId === 'dfab-23435' && (
            <CustomerDetailedInfoBox
              label='Call Preferences'
              mb='1rem'
              children={
                <Box display='flex' alignItems='center' gap='1rem'>
                  <Typography className={classes.customerStatusStyle}>
                    {getCallPreferenceDays().desc}
                  </Typography>
                  <Box display='flex' alignItems='center' gap='5px'>
                    <Typography color='#6F6F6F'>Date:</Typography>
                    <Typography>
                      {moment(Date.now()).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center' gap='5px'>
                    <Typography color='#6F6F6F'>Time:</Typography>
                    <Typography>
                      {moment(Date.now()).format('H mm')}-
                      {moment(Date.now()).format('H mm')}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          )}
        </>
      )}

      <CustomerDetailedInfoBox
        label='Overall Customer Outstanding Due'
        mb='1rem'
        children={
          <Typography>
            $ {outstanding_due && outstanding_due.toLocaleString()}
          </Typography>
        }
      />

      <CustomerDetailedInfoBox
        label='Customer Risk Profile'
        mb='0.5rem'
        children={
          <button>
            <Typography
              className={classes.smartkollectSubscribe}
              onClick={() => navigate('/smart-collect')}
            >
              Subscribe SmartKollect
            </Typography>
          </button>
        }
      />

      <CustomerDetailedInfoBox
        label='Product Subscribed'
        children={
          <RadioGroup row value={selectedProduct} onChange={() => {}}>
            {Array.isArray(profileData) &&
              profileData?.map((product: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={product.product_suscribed}
                    control={
                      <Radio
                        size='medium'
                        sx={{
                          '&.Mui-checked': {
                            color: kollectAITheme.primaryColor,
                          },
                        }}
                        checked={
                          selectedProfileData?.product_suscribed ===
                          product.product_suscribed
                        }
                        onChange={() => onChangeSubscribedProduct(product)}
                      />
                    }
                    label={product.product_suscribed}
                  />
                );
              })}
          </RadioGroup>
        }
      />

      {/* {current_status === 'Hardship' && (
        <Box display='flex' justifyContent='end'>
          <Typography
            sx={{
              textDecoration: 'underline',
              color: kollectAITheme.primaryColor,
              cursor: 'pointer',
            }}
            onClick={() => setViewHardship(true)}
          >
            View Details
          </Typography>
        </Box>
      )} */}

      <HardshipDetailsModal
        open={viewHardship}
        onClose={() => setViewHardship(false)}
        viewData={viewData}
      />
    </Box>
  );
};

export default CustomerStatusAttributes;
