import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { RiArrowUpLine, RiArrowUpSLine } from 'react-icons/ri';
import { TbDotsVertical } from 'react-icons/tb';
import ConversionGraph from './ConversionGraph';
import { useAppSelector } from 'hooks/useRedux';
import { campaignCommStatus } from 'store/reducer/campaignReducer';
import useStyles from './style';
import { getCampaignFunnelData } from 'utils/campaign/campaignModifier';

const color800 = 'rgba(50, 71, 92, 0.87)';
const color600 = 'rgba(50, 71, 92, 0.6)';

const CustomerConversionBox = ({
  label,
  customers,
  conversion,
}: {
  label: string;
  customers: number;
  conversion: number;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      mt='1rem'
    >
      <Box>
        <Typography color={color800}>{label}</Typography>
        <Typography color='rgba(50, 71, 92, 0.38)' fontSize='0.8rem'>
          {customers ? `${customers?.toLocaleString()} Customers` : 'NA'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {conversion ? <RiArrowUpLine color='#71DD37' /> : null}
        <Typography>
          {conversion ? `${conversion.toFixed(2)}%` : 'NA'}
        </Typography>
      </Box>
    </Box>
  );
};

const ConversionFunnel = ({ channel }: { channel: string }) => {
  const classes = useStyles();

  const [funnelData, setFunnelData] = React.useState<any>(null);

  const commStatus = useAppSelector(campaignCommStatus);

  React.useEffect(() => {
    if (commStatus) {
      const lowerChannel = channel.toLowerCase();
      const data = commStatus?.[lowerChannel];
      const funData = getCampaignFunnelData(data, lowerChannel);
      setFunnelData(funData);
    }
  }, [channel, commStatus]);

  return (
    <Box className={classes.funnelWrapper}>
      <Box className={classes.flexBetween}>
        <Box>
          <Typography color={color800} fontSize='1.5rem'>
            Conversion Funnel
          </Typography>
          <Typography color={color600}>Enagagement included</Typography>
        </Box>

        <button style={{ marginTop: '6px' }}>
          <TbDotsVertical fontSize='1.2rem' color='rgba(50, 71, 92, 0.54)' />
        </button>
      </Box>

      <Box className={classes.flexBetween}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
          <Typography color={color800} fontSize='2rem'>
            {funnelData?.engagement
              ? `${funnelData.engagement.toFixed(2)}%`
              : 'NA'}
          </Typography>
          {funnelData?.conversion ? (
            <Box className={classes.conversionRateCont}>
              <RiArrowUpSLine />
              <Typography>
                {funnelData?.conversion
                  ? `${funnelData.conversion.toFixed(2)}%`
                  : 'NA'}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box>
          <ConversionGraph />
        </Box>
      </Box>

      {channel !== 'IVR' && (
        <>
          <Box>
            <Typography color={color800}>Sent</Typography>
            <Typography color='rgba(50, 71, 92, 0.38)' fontSize='0.8rem'>
              {funnelData?.sent ? `${funnelData?.sent} Customers` : 'NA'}
            </Typography>
          </Box>

          <CustomerConversionBox
            label='Delivered'
            customers={funnelData?.delivered}
            conversion={funnelData?.deliveryRate}
          />
        </>
      )}
      {channel === 'EMAIL' && (
        <>
          <CustomerConversionBox
            label='Open Rate'
            customers={funnelData?.open}
            conversion={funnelData?.openRate}
          />
          <CustomerConversionBox
            label='Click Rate'
            customers={funnelData?.click}
            conversion={funnelData?.clickRate}
          />
        </>
      )}
      {channel === 'WHATSAPP' && (
        <CustomerConversionBox
          label='Read'
          customers={funnelData?.read}
          conversion={funnelData?.readRate}
        />
      )}
      {channel === 'IVR' && (
        <>
          <Box>
            <Typography color={color800}>Initiated</Typography>
            <Typography color='rgba(50, 71, 92, 0.38)' fontSize='0.8rem'>
              {funnelData?.initiated
                ? `${funnelData?.initiated} Customers`
                : 'NA'}
            </Typography>
          </Box>
          <CustomerConversionBox
            label='Completed'
            customers={funnelData?.completed}
            conversion={funnelData?.completedRate}
          />
          <CustomerConversionBox
            label='Busy'
            customers={funnelData?.busy}
            conversion={funnelData?.busyRate}
          />
          <CustomerConversionBox
            label='No Answer'
            customers={funnelData?.noAnswer}
            conversion={funnelData?.noAnswerRate}
          />
        </>
      )}
    </Box>
  );
};

export default ConversionFunnel;
