import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';

import { CustomFormikWorkflowInput } from 'components/formik/workflow';
import { useAppSelector } from 'hooks/useRedux';
import { concatenatedTemplate } from 'utils/helper';
import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import WorkflowActionButtons from '../actionButtons';
import AddTriggerChannels from './channels';

interface AddTriggerDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

export const channels = ['email', 'whatsapp', 'sms', 'ivr'];

const AddTriggerDrawer: React.FC<AddTriggerDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const [showTemplate, setShowTemplate] = useState<any>({});
  const [templateOptions, setTemplateOptions] = useState<any>({});
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<any>({});
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state) => state.workflow.workflowActionData
  );

  const template = useAppSelector((state: any) => state.template);
  const emailTemplate = template?.emailTemplate;
  const smsTemplate = template?.smsTemplate;
  const ivrTemplate = template?.ivrTemplate;
  const whatsappTemplate = template?.whatsappTemplate;

  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();

  const getTemplateOptions = useCallback(() => {
    let options: any = {};
    channels.forEach((channel) => {
      let template: any = [];
      if (channel === 'email') template = concatenatedTemplate(emailTemplate);
      if (channel === 'sms') template = concatenatedTemplate(smsTemplate);
      if (channel === 'ivr') template = concatenatedTemplate(ivrTemplate);
      if (channel === 'whatsapp')
        template = concatenatedTemplate(whatsappTemplate);

      let channelOptions: any = [];
      template.forEach((element: any) => {
        const option = {
          ...element,
          label: element.template_name,
          value: element.id,
          channel,
        };
        channelOptions.push(option);
      });
      options = { ...options, [channel]: channelOptions };
    });
    setTemplateOptions(options);

    const trigger = workflowActionData?.trigger;
    let showTemp = {},
      selectedTemp = {};
    for (const key in trigger) {
      const lowerItem = key.toLowerCase();
      showTemp = { ...showTemp, [lowerItem]: true };
      selectedTemp = { ...selectedTemp, [lowerItem]: trigger[key] };
    }
    setShowTemplate(showTemp);
    setSelectedTemplateOption(selectedTemp);
  }, [
    emailTemplate,
    ivrTemplate,
    smsTemplate,
    whatsappTemplate,
    workflowActionData,
  ]);

  useEffect(() => {
    getTemplateOptions();

    const { published } = getGraphData();
    const pub: boolean = published === 1 ? true : false;
    setIsPublished(pub);
  }, [getTemplateOptions]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName } = values;
    let configObj = { ...graphData.config };

    let payload: any = {};
    for (const key in selectedTemplateOption) {
      if (showTemplate[key]) {
        payload = { ...payload, [key]: selectedTemplateOption[key] };
      }
    }

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'ADD_TRIGGER',
          trigger: payload,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'trigger');
      updateWorkflow(configObj, keys, payload);
    }

    updateJsonConfig(configObj);
  };

  const isUpdateDisabled = useMemo(
    () =>
      Boolean(
        !Object.values(showTemplate)?.length ||
          !Object.keys(selectedTemplateOption)?.length ||
          !Object.keys(selectedTemplateOption).filter((el) => showTemplate[el])
            ?.length ||
          !Object.keys(selectedTemplateOption)
            .filter((el) => showTemplate[el])
            .every((item) => item)
      ),
    [showTemplate, selectedTemplateOption]
  );

  return (
    <>
      <Formik
        initialValues={{
          stepName: workflowActionData?.name,
          precursorName: workflowActionData?.parent,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            style={{
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <CustomFormikWorkflowInput
                label='Step Name'
                name='stepName'
                placeholder='Step Name'
                disabled={workflowEditor === 'create' ? false : true}
              />

              <CustomFormikWorkflowInput
                label='Precursor Name'
                name='precursorName'
                placeholder='Precursor Name'
                disabled={true}
              />

              <AddTriggerChannels
                showTemplate={showTemplate}
                setShowTemplate={setShowTemplate}
                isPublished={isPublished}
                templateOptions={templateOptions}
                selectedTemplateOption={selectedTemplateOption}
                setSelectedTemplateOption={setSelectedTemplateOption}
              />
            </Box>

            {!graphData.published && (
              <WorkflowActionButtons
                isUpdateDisabled={isUpdateDisabled}
                setLoading={setLoading}
                updateJsonConfig={updateJsonConfig}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTriggerDrawer;
