import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import TimePicker from 'react-time-picker';

import clockImg from 'assets/clock.svg';

interface FormikCustomerTimePickerProps {
  name: string;
}

const TimePickerWrapper = styled('div')({
  position: 'relative',
  marginTop: '0.5rem',
  width: 200,
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'center',

  '.react-time-picker__inputGroup': {
    '& input': { outline: 'none' },
    '& select': { outline: 'none' },
  },

  '.react-time-picker__wrapper': {
    background: 'transparent !important',
    border: 'transparent',
    padding: '5px',
  },
});

const FormikCustomerTimePicker = ({ name }: FormikCustomerTimePickerProps) => {
  return (
    <Field name={name}>
      {({ form, field, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const { value } = field;
        return (
          <Box>
            <TimePickerWrapper>
              <TimePicker
                {...field}
                hourPlaceholder='00'
                minutePlaceholder='00'
                clearIcon={null}
                disableClock={true}
                value={value}
                onChange={(val) => setFieldValue(name, val)}
              />
              <Box sx={{ position: 'absolute', right: '10px', top: '7px' }}>
                <img src={clockImg} alt='' width={20} />
              </Box>
            </TimePickerWrapper>
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        );
      }}
    </Field>
  );
};

export default FormikCustomerTimePicker;
