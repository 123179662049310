export const workflowData = {
  allowed_attributes: [
    {
      attribute: 'product_type',
      type: 'List',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'listed_in',
        'not_listed_in',
      ],
      allowed_values: ['IL', 'LOC', 'CC', 'BNPL', 'IC'],
    },
    {
      attribute: 'dpd',
      type: 'Number',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'listed_in',
        'not_listed_in',
        'in_range',
        'not_in_range',
        'greater_than',
        'less_than',
      ],
      allowed_values: 'Inputbox',
    },
    {
      attribute: 'loan_amount',
      type: 'Number',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'listed_in',
        'not_listed_in',
        'in_range',
        'not_in_range',
        'greater_than',
        'less_than',
      ],
      allowed_values: 'Inputbox',
    },
    {
      attribute: 'outstanding_balance',
      type: 'Number',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'listed_in',
        'not_listed_in',
        'in_range',
        'not_in_range',
        'greater_than',
        'less_than',
      ],
      allowed_values: 'Inputbox',
    },
    {
      attribute: 'delinquent_amount',
      type: 'Number',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'listed_in',
        'not_listed_in',
        'in_range',
        'not_in_range',
        'greater_than',
        'less_than',
      ],
      allowed_values: 'Inputbox',
    },
    {
      attribute: 'delinquent_since_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'workable_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'current_status',
      type: 'List',
      allowed_values: [
        'default',
        'PTP',
        'Broken_Promise',
        'Call_Required',
        'Hardship',
        'Not_engaged',
        'Bad_Contact',
      ],
    },
    {
      attribute: 'events_ptp_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_ptpbroken_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_callrequired_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_hardships_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_latebucket_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_not_engaged_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'events_bad_contact_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'vulnerability_flag',
      type: 'Boolean',
      allowed_values: [1, 0],
    },
    {
      attribute: 'ptp_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'broken_promise_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'need_call_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'hardship_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
        'greater_than',
        'less_than',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'not_engaged_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'bad_contact_start_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'ptp_commitment_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'broken_promise_end_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'need_call_end_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'hardship_end_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'not_engaged_end_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
    {
      attribute: 'bad_contact_end_date',
      type: 'Date',
      allowed_operators: [
        'equals_to',
        'not_equals_to',
        'before',
        'after',
        'in_range',
        'not_in_range',
      ],
      allowed_values: 'Calendar',
    },
  ],
  allowed_segment: [
    {
      DPD_bucket: [
        'DPD 1-30',
        'DPD 31-60',
        'DPD 61-90',
        'DPD 91-120',
        'DPD >120',
      ],
    },
    {
      balance_category: ['Low', 'Med', 'High'],
    },
    {
      // product_type: ['IL', 'LoC', 'CC', 'BNPL', 'IC'],
      product_type: ['Instalment Loan', 'LOC'],
    },
    {
      current_status: [
        'Default',
        'PTP',
        // 'Broken_Promise',
        'PTP Broken',
        // 'Call_Required',
        'Call Required',
        'Hardship',
        // 'Not_Engaged',
        'Not Engaged',
        // 'Bad_Contact',
        'Bad Contact',
      ],
    },
  ],
  default_queues: [
    'Inquiry_Calling_Queue',
    'PTP_Calling_Queue',
    'PTP_Broken_Calling_Queue',
    'Hardship_calling_Queue',
    'NotEngaged_Calling_Queue',
    'Skip_Tracing_Queue',
  ],
  default_strategy: [
    'default_strategy',
    'broken_promise_strategy',
    'Non_Engaged_Strategy',
    'Run_Harshship_strategy',
    'if_call_default_strategy',
  ],
  default_workflow: ['default workflow'],
};
