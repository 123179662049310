import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { postCustomerDelete } from 'store/reducer/customerReducer/action';
import { selectedCustomersArr } from 'store/reducer/customerReducer';

import Loader from 'components/loader';
import {
  FormikCustomerRadio,
  FormikCustomerTextarea,
} from 'components/formik/customer';
import { successToast } from 'utils/toast';

interface DeleteCustomerFormProps {
  onClose: () => void;
}

const validation = yup.object().shape({
  level: yup.string().required('Required'),
});

const DeleteCustomerForm: React.FC<DeleteCustomerFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [initialValues, setInitialValues] = useState({
    level: 'customer_level',
  });
  const [validationSchema, setvalidationSchema] = useState(validation);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const odf_customer_id =
        selectedCustomers && selectedCustomers?.length
          ? selectedCustomers?.map((customer) => customer?.odf_customer_id)
          : [customerOdfId];
      let payload: any = { odf_customer_id, ...values };
      await dispatch(postCustomerDelete(payload));
      successToast('Customer has been deleted successfully');
      onClose();
      navigate('/customer-management');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Box mb='1rem'>
              <Typography mb='1rem'>
                Please select the level of data that you want to delete for this
                customer
              </Typography>

              <FormikCustomerRadio
                name='level'
                setInitials={setInitialValues}
                setValidations={setvalidationSchema}
                formikValues={values}
              />
            </Box>

            <FormikCustomerTextarea
              name='comments'
              label='Comments'
              labelDesc='Reason for change or generic notes'
            />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Confirm</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DeleteCustomerForm;
