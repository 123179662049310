import { Line } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import ContactibilityAndPenetrationBox from './contactablityAndPenetrationBox';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from 'chart.js';
import ObservationSelectors from './observationSelectors';
import LineChartLabel from './lineChartLabel';
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Day of the month',
      },
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: false,
      },
    },
    y: {
      max: 4000,
      min: 0,
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: false,
      },
    },
  },
};

const DayWiseAcrossChannel = () => {
  const data = {
    labels: Array.from({ length: 31 }, (_, index) => index + 1),
    datasets: [
      {
        label: 'First Dataset',
        data: Array.from({ length: 31 }, () => 3500),
        tension: 0.4,
        fill: false,
        borderColor: '#00C087',
        backgroundColor: '#00C087',
        pointRadius: 0,
      },
      {
        label: 'First Dataset',
        data: Array.from({ length: 31 }, () => 1500),
        tension: 0.4,
        fill: false,
        borderColor: '#2E384D',
        backgroundColor: '#2E384D',
        pointRadius: 0,
      },
      {
        label: 'First Dataset',
        data: Array.from({ length: 31 }, () => 1000),
        tension: 0.4,
        fill: false,
        borderColor: '#C07F00',
        backgroundColor: '#C07F00',
        pointRadius: 0,
      },
      {
        label: 'First Dataset',
        data: Array.from({ length: 31 }, () => 500),
        tension: 0.4,
        fill: false,
        borderColor: '#C00068',
        backgroundColor: '#C00068',
        pointRadius: 0,
      },
    ],
  };

  return (
    <div>
      <Typography variant='body1' style={{ marginBottom: '1em' }}>
        Day wise - Number of customers and Communication sent across channel
      </Typography>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 330px' }}>
        <div>
          <div>
            <ObservationSelectors />
          </div>
          <div style={{ maxWidth: '95%', height: '90%' }}>
            <Line data={data} options={options} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LineChartLabel
              background='#00C087'
              labelName='Number of Customers'
            />
            <LineChartLabel background='#2E384D' labelName='SMS sent' />
            <LineChartLabel background='#C07F00' labelName='Email sent' />
            <LineChartLabel background='#C00068' labelName='IVR sent' />
            <LineChartLabel background='#04FF4A' labelName='Whatsapp sent' />
          </div>
        </div>
        <div>
          <div style={{ marginBottom: '2em' }}>
            <Typography
              variant='body1'
              align='center'
              style={{ marginBottom: '1em' }}
            >
              Contactability
            </Typography>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '0.8em',
              }}
            >
              <ContactibilityAndPenetrationBox name='SMS' value='40%' />
              <ContactibilityAndPenetrationBox name='Email' value='40%' />
              <ContactibilityAndPenetrationBox name='Whatsapp' value='40%' />
              <ContactibilityAndPenetrationBox name='IVR' value='40%' />
            </div>
          </div>
          <div>
            <Typography
              variant='body1'
              align='center'
              style={{ marginBottom: '1em' }}
            >
              Channel Penetration
            </Typography>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '0.8em',
              }}
            >
              <ContactibilityAndPenetrationBox name='SMS' value='2' />
              <ContactibilityAndPenetrationBox name='Email' value='2' />
              <ContactibilityAndPenetrationBox name='Whatsapp' value='3' />
              <ContactibilityAndPenetrationBox name='IVR' value='1' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayWiseAcrossChannel;
