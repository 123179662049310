import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signupButtonStyle: {
      marginTop: '20px',
    },
    formControl: {
      marginBottom: '1.2rem',
    },
    formInput: {
      width: '90%',
      background: '#ECF2F7',
      border: 'transparent',
      height: '38px',
      borderRadius: '5px',
      padding: '0 1em',
      fontSize: '0.9em',

      '&:focus': {
        outline: 'none',
      },
    },
    formErrorMessage: {
      marginTop: 0,
      color: '#d32f2f',
      fontSize: '0.8em',
    },

    signupSubmitButton: {
      width: '100%',
      borderRadius: '8px',
      border: 'transparent',
      color: '#fff',
      fontSize: '1em',
      height: '40px',
    },
    validationTextStyle: {
      margin: 0,
      color: '#F44336',
    },
    showPasswordIcon: {
      position: 'absolute',
      right: '25px',
      bottom: '6px',
      cursor: 'pointer',
      fontSize: '1.2em',
    },
    forgotPasswordButton: {
      color: theme.kollectAITheme.primaryColor,
      cursor: 'pointer',
    },
    signUpButtonStyle: {
      color: theme.kollectAITheme.primaryColor,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
