import * as React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { CRUDCheckbox } from './CRUDCheckbox';
import { CustomHeadCell } from 'components/rolesPermissions/CustomHeadCell';
import { useAppSelector } from 'hooks/useRedux';
import { clientConfig } from 'store/reducer/permissionReducer';
import { ModuleType } from './Create';
import { interHierarchyModules } from 'utils/helper';

const getDisableState = (actions: any, operator: string) => {
  if (operator === 'read') {
    const checkArr = actions.filter((action: any) =>
      ['create', 'update', 'delete', 'execute'].includes(action?.operation)
    );
    if (checkArr.some((item: any) => item?.checked)) return true;
  }
  if (operator === 'create') {
    const checkArr = actions.filter((action: any) =>
      ['update', 'delete'].includes(action?.operation)
    );
    if (checkArr.some((item: any) => item?.checked)) return true;
  }
  if (operator === 'update') {
    const deleteObj = actions.find(
      (action: any) => action?.operation === 'delete'
    );
    if (deleteObj?.checked) return true;
  }
  return false;
};

const CreateEditPermissionTable = ({
  data,
  moduleValues,
  setModuleValues,
}: {
  data: any;
  moduleValues: ModuleType[];
  setModuleValues: (x: ModuleType[]) => void;
}) => {
  const [actions, setActions] = React.useState<string[]>([]);

  const modules = useAppSelector(clientConfig);

  React.useEffect(() => {
    if (modules) {
      const operations: string[] = (Object.values(modules)?.[0] as any[])?.map(
        (item) => item.operation
      );
      setActions(operations);
      let checked = [];
      for (let key in modules) {
        const moduleActions = [...modules[key]].map((item) => ({
          ...item,
          checked: false,
        }));
        checked.push({ module: key, actions: moduleActions });
      }

      if (data) {
        checked = checked.map((el) => {
          const actions = data[el.module];
          if (!actions) return el;

          const newActions = el.actions.map((act) => {
            return { ...act, checked: actions[act?.operation] };
          });
          return { ...el, actions: newActions };
        });
      }
      setModuleValues(checked);
    }
  }, [modules, setModuleValues]);

  const handleCheck = (operation: string, checked: boolean, module: string) => {
    let mod = moduleValues.map((el: ModuleType) => {
      if (el.module === module) {
        const newActions = el.actions.map((act) => {
          if (checked) {
            if (operation === 'read') {
              if (act.operation === operation) return { ...act, checked: true };
            }
            if (operation === 'create') {
              if (['read', 'create'].includes(act.operation))
                return { ...act, checked: true };
            }
            if (operation === 'update') {
              if (['read', 'create', 'update'].includes(act.operation))
                return { ...act, checked: true };
            }
            if (operation === 'delete') {
              if (
                ['read', 'create', 'update', 'delete'].includes(act.operation)
              )
                return { ...act, checked: true };
            }
            if (operation === 'execute') {
              if (['read', 'execute'].includes(act.operation))
                return { ...act, checked: true };
            }
            return act;
          } else {
            if (act.operation === operation) return { ...act, checked: false };
            return act;
          }
        });

        return { ...el, actions: newActions };
      }
      return el;
    });

    const hierarchyModules = interHierarchyModules(mod);
    setModuleValues(hierarchyModules);
  };

  const handleCheckAll = (checked: boolean, module: string) => {
    const mod = moduleValues.map((el: ModuleType) => {
      if (el.module === module) {
        const newActions = el.actions.map((action) => ({ ...action, checked }));
        return { ...el, actions: newActions };
      }
      return el;
    });

    const hierarchyModules = interHierarchyModules(mod);
    setModuleValues(hierarchyModules);
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <CustomHeadCell
              width='25%'
              pl='2rem'
              label='modules permissions'
              align='left'
            />
            <CustomHeadCell label='all' />
            {actions?.map((action) => (
              <CustomHeadCell label={action} key={`operation-${action}`} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {moduleValues?.map((module: ModuleType, index: number) => {
            return (
              <TableRow key={`permission_table_${index}`}>
                <TableCell>
                  <Typography textTransform='capitalize' pl='2rem'>
                    {module.module}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Checkbox
                    checked={module.actions.every((action) => action.checked)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckAll(e.target.checked, module.module)
                    }
                    sx={{ padding: 0, '&.Mui-checked': { color: '#004357' } }}
                  />
                </TableCell>
                {module.actions?.map((action, ind) => {
                  return (
                    <TableCell
                      align='center'
                      key={`${module.module}-action-${ind}`}
                    >
                      <CRUDCheckbox
                        {...action}
                        disabled={getDisableState(
                          module.actions,
                          action?.operation
                        )}
                        handleCheck={(x: string, y: boolean) =>
                          handleCheck(x, y, module.module)
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(CreateEditPermissionTable);
