export const ACTION_TRIGGER: any = {
  update_ptp_commitment: 'Update PTP Commitment Data',
  update_call_required_details: 'Update Call Required Data',
  add_customer_request: 'Select Request Category',
  send_another_communication: 'Send Communication',
  run_workflow: 'Trigger Workflow',
  resend_from_this_template: 'Resend message from this template',
  connect_to_an_agent: 'Connect to an Agent',
  assign_agent_task: 'Assign task to an agent',
};

export const attributeRequiredActions = [
  'update_ptp_commitment',
  'update_call_required_details',
  'add_customer_request',
  'send_another_communication',
  'run_workflow',
  'resend_from_this_template',
  'connect_to_an_agent',
  'assign_agent_task',
];
