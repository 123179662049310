// import React from 'react';
import { Modal, Box, Typography, ListItem, ListItemText } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  // campaignSummary,
  deleteCampaign,
  getCampaignSummary,
} from 'store/reducer/campaignReducer/action';
import { successToast } from 'utils/toast';
// import { useNavigate } from 'react-router-dom';
import useStyles, { ActionButton } from './index.styles';

const DeleteCampaign = ({
  open,
  campaigns,
  onClose,
  setLoading,
}: {
  open: boolean;
  campaigns: string[];
  onClose: () => void;
  setLoading: (x: boolean) => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const campaignData = useAppSelector((state) => state.campaign.campaignSummary)
    ?.campaigns?.results;

  const handleDelete = async () => {
    try {
      setLoading(true);
      const { STATUS } = await dispatch(
        deleteCampaign({ campaign_id: campaigns })
      ).unwrap();
      STATUS && successToast(STATUS);
      await dispatch(getCampaignSummary({ limit: 10, offset: 0 }));
      // await dispatch(campaignSummary({ limit: 10, offset: 0 }));
      // navigate('/campaigns');
      onClose();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const demonstratives = campaigns?.length > 1 ? 'these' : 'this';

  const campaignLists = campaigns?.map((campaignId) => {
    const campaign = campaignData?.find(
      (el: any) => el.campaign_id === campaignId
    );
    return campaign;
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEnforceFocus={false}
      className={classes.modal}
    >
      <Box sx={{ width: '550px' }} className={classes.modalCotent}>
        <Box className={classes.contentTitle}>
          <Typography fontWeight={600} align='center' fontSize='1.2rem'>
            Delete Campaign
          </Typography>
        </Box>

        <Box sx={{ padding: '2rem 3rem' }}>
          <Typography mb='1rem'>
            Are you sure you want to delete {demonstratives} campaign?
            {campaignLists.map((campaign) => (
              <ListItem sx={{ pt: 0, pb: 0 }}>
                <ListItemText
                  primary={campaign?.campaign_name}
                  sx={{ display: 'list-item' }}
                />
              </ListItem>
            ))}
          </Typography>
          <Typography mb='1rem'>
            If yes, please click on confirm and all the data related to{' '}
            {demonstratives} campaign will be removed and can’t be recovered.
          </Typography>
          <Typography>
            If you want to keep the data, you can click on cancel and save{' '}
            {campaigns.length > 1 ? 'that' : 'this'} campaign as Draft instead.
          </Typography>
        </Box>

        <Box className={classes.actionButtonCont} mb='2rem'>
          <ActionButton color='#6AC5AB' onClick={handleDelete}>
            <Typography>Confirm</Typography>
          </ActionButton>

          <ActionButton color='#DAA09A' onClick={onClose}>
            <Typography>Cancel</Typography>
          </ActionButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteCampaign;
