import { styled } from '@mui/material';

export const RoleWrapper = styled('div')(() => ({
  background: '#fff',
  boxShadow:
    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  padding: '1rem 0.8rem',
}));

export const RoleButton = styled('div')(() => ({
  background: '#004357',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '6px',
  cursor: 'pointer',
  padding: '8px 1.2rem',
  '& p': {
    color: '#fff',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));

export const RoleName = styled('p')(() => ({
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: '1.2rem',
  margin: '6px 0 4px',
}));

export const EditRoleCont = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& p': { cursor: 'pointer' },
}));

export const AddRoleWrapper = styled('div')(() => ({
  background: '#fff',
  boxShadow:
    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  // paddingRight: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'end',
  position: 'relative',
  padding: '10px 1rem',

  '& img': {
    height: '85%',
    position: 'absolute',
    bottom: 0,
    left: '25px',
  },
}));

export const AddRoleText = styled('p')(() => ({
  width: '40%',
  fontSize: '0.9rem',
  textAlign: 'end',
  lineHeight: 1.2,
  marginTop: '12px',
  marginBottom: 0,
}));
