import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './index.styles';

import { userPermissions } from 'store/reducer/auth';
import { useAppSelector } from 'hooks/useRedux';

import { MODULELIST } from 'constants/types';
import Breadcrumb from 'components/breadcrumbs';

const WorkflowHeader = () => {
  const classes = useStyles();
  const permissions = useAppSelector(userPermissions);

  return (
    <Box sx={{ position: 'relative', margin: '1rem 1rem 2rem 0' }}>
      <Breadcrumb nested='Workflow Management' showNested />

      {permissions[MODULELIST.WORKFLOW].includes('create') && (
        <Link to='' className={classes.howCreateWorkflowLinkStyle}>
          How to create a workflow?
        </Link>
      )}
    </Box>
  );
};
export default WorkflowHeader;
