import { Box, Drawer, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import WorkflowDrawerContent from 'containers/Workflow/editor/drawerContent';
import {
  setAddBranch,
  setWorkflowActionData,
  setWorkflowEditor,
  setWorkflowType,
  toggleDrawer,
} from 'store/reducer/workflowReducer';
import { setWorkflowAction } from 'utils/workflow/graphHandler';
import { Droppable } from 'react-beautiful-dnd';

interface AddWorkflowStepProps {
  id: string;
  text: any;
}

const AddWorkflowStep: React.FC<AddWorkflowStepProps> = (props) => {
  const dispatch = useAppDispatch();
  const {
    text: { parent, parentValue },
  } = props;

  const handleStep = () => {
    dispatch(setAddBranch(false));
    dispatch(setWorkflowEditor('create'));
    dispatch(setWorkflowType(''));
    dispatch(setWorkflowActionData(props.text));
    setWorkflowAction('workflowActionData', props.text);
    dispatch(toggleDrawer(true));
  };

  return (
    <>
      <Droppable droppableId={`${parent}&${parentValue}`}>
        {(droppableProvided, droppableSnapshot) => (
          <Box
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            <Box
              sx={{
                padding: '12px 0',
                cursor: 'default',
                boxShadow:
                  'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                background: '#fff',
              }}
              onClick={handleStep}
            >
              <Typography
                fontWeight={500}
                fontSize='12px'
                color='#00C087'
                mb='5px'
              >
                Add Step
              </Typography>
              <Typography fontSize='10px' color='#6F6F6F'>
                Drag & drop or Select and choose
              </Typography>
            </Box>
          </Box>
        )}
      </Droppable>

      <Drawer
        anchor={'right'}
        open={false}
        onClose={() => dispatch(toggleDrawer(false))}
      >
        <WorkflowDrawerContent />
      </Drawer>
    </>
  );
};

export default AddWorkflowStep;
