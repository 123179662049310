import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableSortLabel,
  // Paper,
  Checkbox,
  TableCell,
  tableCellClasses,
  Typography,
  Collapse,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ArrowRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'hooks/useRedux';
import useStyles from './index.styles';
import moment from 'moment';
import StrategySegmentTable from './StrategySegmentTable';
import EmptyScreen from 'components/EmptyScreen';

interface Data {
  strategy_name: string;
  defined_for: string;
  status: string;
  based_on: string;
  updatedon: string;
  id: string;
}

function createData(
  strategy_name: string,
  defined_for: string,
  status: string,
  based_on: string,
  updatedon: string,
  id: string
): Data {
  return {
    id,
    strategy_name,
    defined_for,
    status,
    based_on,
    updatedon,
  };
}

const rows = [
  createData(
    '1',
    'strategy_name',
    'Active',
    'Ronaldo',
    '17 October, 2022; 05:00',
    '17 October, 2022; 05:00'
  ),
  createData(
    '2',
    'WID0123',
    'Inactive',
    'Messi',
    '17 October, 2022; 05:00',
    '17 October, 2022; 05:00'
  ),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  // id: keyof Data;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'strategy_name',
    numeric: true,
    disablePadding: false,
    label: 'NAME',
  },
  {
    id: 'defined_for',
    numeric: true,
    disablePadding: false,
    label: 'CONTAINS',
  },

  {
    id: 'based_on',
    numeric: true,
    disablePadding: false,
    label: 'RUN BASED ON',
  },

  {
    id: 'created_on',
    numeric: true,
    disablePadding: false,
    label: 'LAST UPDATED ON',
  },
];

interface SegementData {
  balance_level: string[];
  bucket: string[];
  product: string[];
}

interface StrategyDetail {
  ab_testing_flag: false;
  created_on: string;
  group_id: string;
  status: boolean;
  strategy_id: string;
  strategy_name: string;
}

interface GroupData {
  created_on: string;
  status: number;
  strategy_detail: StrategyDetail[];
  strategy_group_id: string;
  strategy_group_name: string;
  index?: number;

  segment: SegementData;
  based_on?: string;
}

interface StrategyTableHeadProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function StrategyTableHead(props: StrategyTableHeadProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          color: '#A8ABC3',
          '& th': {
            color: '#A8ABC3',
            borderBottom: '2px solid #CCCCCC',
            borderTop: '2px solid #CCCCCC',
          },
          '& th:first-child': {
            borderLeft: '2px solid #CCCCCC',
          },
          '& th:last-child': {
            borderRight: '2px solid #CCCCCC',
          },
        }}
      >
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell colSpan={2}></TableCell>
      </TableRow>
    </TableHead>
  );
}

const RenderGroupDetails = (groupProps: GroupData) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow sx={{ borderBottom: 'none' }}>
            <TableCell>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                color='#979797'
                fontWeight={500}
              >
                #
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                color='#979797'
                fontWeight={500}
              >
                GROUP ID
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                fontFamily='Overpass'
                color='#979797'
                fontWeight={500}
              >
                GROUP NAME
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                fontFamily='Overpass'
                color='#979797'
                fontWeight={500}
              >
                STATUS
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                fontFamily='Overpass'
                color='#979797'
                fontWeight={500}
              >
                UPDATED ON
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                fontWeight={700}
              >
                {(groupProps.index || 0) + 1}
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                fontWeight={700}
              >
                {groupProps.strategy_group_id}
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                fontWeight={700}
              >
                {groupProps.strategy_group_name}
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                fontWeight={700}
              >
                {groupProps.status}
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography
                className={classes.groupCellTitle}
                fontFamily='Overpass'
                fontWeight={700}
              >
                {groupProps?.created_on &&
                  moment(groupProps.created_on).format('MMMM')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IStrategyDetailsTableProps {
  data: any;
  setStrategyData: React.Dispatch<React.SetStateAction<any>>;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  selected: Array<string>;
}

export default function StrategyDetailsTable({
  data,
  setStrategyData,
  setSelected,
  selected,
}: IStrategyDetailsTableProps) {
  const [expandedGroupId, setExpanededGroupId] = React.useState<string | false>(
    ''
  );
  const navigate = useNavigate();
  const strategyList =
    useAppSelector((state: any) => state.strategy.strategyList) || [];

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('strategy_id');
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.strategy_name);

      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const viewStrategy = async ({
    abtesting,
    id,
    groupName,
    strategyName,
    groupId,
  }: {
    abtesting: boolean;
    id: string;
    groupName: string;
    strategyName: string;
    groupId: string;
  }) => {
    navigate(`/strategy-details/${id}?group_id=${groupId}`, {
      state: {
        groupName,
        strategyName,
        groupId,
      },
    });
  };

  // const onChangeAbTesting = (row: any) => {
  //   const newStrategyData = data?.map((eachData: any) => {
  //     if (eachData.strategy_id === row.strategy_id) {
  //       return { ...eachData, abtestingflag: !eachData.abtestingflag };
  //     }
  //     return eachData;
  //   });
  //   setStrategyData(newStrategyData);
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby='tableTitle'
          size={'medium'}
          stickyHeader
        >
          <StrategyTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          <TableBody>
            {strategyList
              // .slice()
              .map((group: GroupData, index: number) => {
                const isItemSelected = isSelected(group.strategy_group_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                const segment =
                  group?.segment?.balance_level?.length *
                  group?.segment?.bucket?.length *
                  group?.segment?.product?.length;

                return (
                  <>
                    <TableRow
                      hover
                      sx={{
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                      }}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={group.strategy_group_id}
                      selected={isItemSelected}
                      onClick={() =>
                        setExpanededGroupId(
                          expandedGroupId === group.strategy_group_id
                            ? false
                            : group.strategy_group_id
                        )
                      }
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(event, group.strategy_group_id);
                          }}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {group.strategy_group_name}
                      </TableCell>
                      <TableCell align='center'>
                        {segment || ''}
                        {segment <= 1 ? ' strategy' : ' strategies'}
                      </TableCell>

                      <TableCell align='center'>
                        {group.based_on || ''}
                      </TableCell>
                      <TableCell align='center'>
                        {moment(group.created_on).format('LLL') || ''}
                      </TableCell>
                      <TableCell>
                        <Box
                          aria-label='expand group'
                          onClick={() =>
                            setExpanededGroupId(
                              expandedGroupId === group.strategy_group_id
                                ? false
                                : group.strategy_group_id
                            )
                          }
                        >
                          {expandedGroupId === group.strategy_group_id ? (
                            <ExpandMore
                              fontSize='large'
                              sx={{
                                color: '#00C087',
                              }}
                            />
                          ) : (
                            <ArrowRight
                              fontSize='large'
                              sx={{
                                color: '#00C087',
                              }}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align='center'>
                        <button
                          style={{
                            background: '#004357',
                            color: '#fff',
                            borderRadius: '20px',
                            padding: '13px 40px',
                            fontSize: '1rem',
                          }}
                          onClick={() => {
                            viewStrategy({
                              abtesting:
                                group.strategy_detail?.[0]?.ab_testing_flag,
                              id: group.strategy_detail?.[0]?.strategy_id,
                              groupName: group.strategy_group_name,
                              strategyName:
                                group.strategy_detail?.[0]?.strategy_name,
                              groupId: group.strategy_group_id,
                            });
                          }}
                        >
                          View
                        </button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={8}>
                        <Collapse
                          in={expandedGroupId === group.strategy_group_id}
                          timeout='auto'
                          unmountOnExit
                        >
                          <StrategySegmentTable group={group} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {(!strategyList || !strategyList?.length) && (
        <EmptyScreen table='strategy' onClick={() => {}} />
      )}
    </Box>
  );
}
