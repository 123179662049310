import { styled, Typography } from '@mui/material';
import DashboardManager from './DashboardManger';
import DashboardTile from './DashboardTile';
import { managementData } from './data';

const DashboardScreenWrapper = styled('div')({
  width: '95%',
  margin: '0 auto',
  border: '1px solid #E6E6E6',
  padding: '2rem',
  background: '#fff',
});

const DasboardScreenBox = styled('div')({
  border: '1px solid #E6E6E6',
  borderRadius: '10px',
  padding: '1rem 0',

  '& p': {
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: 500,
  },
});

const ManagerWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '3rem 2rem',
  margin: '2rem auto 4rem',
});

const DashboardScreen = () => {
  return (
    <DashboardScreenWrapper>
      <DasboardScreenBox>
        <Typography align='center'>start collecting</Typography>
      </DasboardScreenBox>

      <ManagerWrapper>
        {managementData.map((item, index) => {
          return <DashboardManager {...item} key={index} />;
        })}
      </ManagerWrapper>

      <DashboardTile label='performance reports' url='/performance-reports' />

      <DashboardTile label='integrations' url='/integrations' />
    </DashboardScreenWrapper>
  );
};

export default DashboardScreen;
