import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

import { useAppDispatch } from 'hooks/useRedux';
import { setSelectedIntegrationOption } from 'store/reducer/IntegrationReducer';

import apiCallImg from 'assets/api-call.svg';
import fileUploadImg from 'assets/file-upload-drop.svg';
import dbConnectImg from 'assets/db-connection.svg';
// import { userAccess } from 'utils/helper';

const integrationOptions = [
  {
    label: 'API Call option',
    value: 'api-call',
    img: apiCallImg,
    description: [
      'APIs to add, update or delete your customers user',
      'Option to modify or edit any user’s repayment data',
      'Can add digital subscriptions or preference settings for users',
    ],
    position: '1',
  },

  {
    label: 'File upload/SFTP file drop',
    value: 'file-upload',
    img: fileUploadImg,
    description: [
      'You can upload your file or drop to this SFTP location',
      'Also provide a template file to check the format',
    ],
    position: '2',
  },
  {
    label: 'Direct DB Connection',
    value: 'db-connect',
    img: dbConnectImg,
    description: [
      'Set up your details with required data',
      'Test your connection',
      'Fill in the mapping details and fetch data',
    ],
    position: '3',
  },
];

const IntegrationLandingOptions = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const userRole = useAppSelector((state: any) => state.auth.role);

  // const userPermissions = userAccess(userRole)?.integration.permissions;

  return (
    <Box className={classes.integrationLandingOptionWrapper}>
      {integrationOptions?.map((item: any, index: number) => {
        const { label, img, description, position, value } = item;
        // if (
        //   value === 'db-connect'
        //   && !userPermissions.includes('db_connect_view')
        // )
        //   return <></>;

        return (
          <Box
            key={index}
            className={classes.integrationLandingOptionCont}
            onClick={() => dispatch(setSelectedIntegrationOption(value))}
          >
            <Box className={classes.optionBoxStyle}>
              <Typography>Option {position}</Typography>
            </Box>

            <Box className={classes.contentBoxStyle}>
              <Typography
                fontWeight={700}
                className={classes.contentLabelStyle}
              >
                {label}
              </Typography>

              <Box className={classes.contentBottomSection}>
                <img src={img} alt='' />
                <ul>
                  {description.map((desc: string, index: number) => {
                    return (
                      <li key={index}>
                        <Typography>{desc}</Typography>
                      </li>
                    );
                  })}
                </ul>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default IntegrationLandingOptions;
