import { Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getStrategyDetails,
  strategyDistributionUpdate,
} from 'store/reducer/strategyReducer/action';
import CustomSelect from '../customSelect';
import './styles.css';

const ChannelSelectBox = (props: any) => {
  const {
    channel,
    channelValue,
    channelTemplate,
    selectedTemplate,
    dpd,
    item,
  } = props;
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('group_id');
  const [isChecked, setIsChecked] = useState(channelValue);

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );

  const { activeDpd, activeProduct, activeAmount }: any = useAppSelector(
    (state: any) => state.strategy
  );

  const filterList: any = useAppSelector(
    (state) => state.uploadedFileDetail.groupFilterList
  );

  const { strategy_summary } = strategyDetailsData;

  useEffect(() => {
    setIsChecked(channelValue ? 'checked' : '');
  }, [channelValue]);

  const option =
    channelTemplate &&
    channelTemplate?.find((template: any) => template.id === selectedTemplate);
  const selectedOption = option ?? channelTemplate[0];

  const handleChangeTemplate = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatePayload: any = {
      strategy_summary_id: strategy_summary?.[0]?.strategy_summary_id || '',
      dpd,
      email: item.email,
      template_email: item.template_email,
      sms: item.sms,
      template_sms: item.template_sms,
      ivr: item.ivr,
      template_ivr: item.template_ivr,
      whatsapp: item.whatsapp,
      template_whatsapp: item.template_whatsapp,
    };

    if (channel === 'email') {
      updatePayload = {
        ...updatePayload,
        email: !isChecked,
        template_email: selectedOption.id,
      };
    }
    if (channel === 'sms') {
      updatePayload = {
        ...updatePayload,
        sms: !isChecked,
        template_sms: selectedOption.id,
      };
    }
    if (channel === 'ivr') {
      updatePayload = {
        ...updatePayload,
        ivr: !isChecked,
        template_ivr: selectedOption.id,
      };
    }
    if (channel === 'whatsapp') {
      updatePayload = {
        ...updatePayload,
        whatsapp: !isChecked,
        template_whatsapp: selectedOption.id,
      };
    }
    setIsChecked(!isChecked);

    const res = await dispatch(strategyDistributionUpdate(updatePayload));

    if (res?.payload?.meta?.success) {
      const bucket = Number(
        Object.values(filterList?.DPD || {}).findIndex(
          (dpd) => dpd === activeDpd
        )
      );
      await dispatch(
        getStrategyDetails({
          query: `strategy_group_id=${
            groupId || ''
          }&balance_level=${activeAmount}&product=${activeProduct}&bucket=${
            bucket + 1
          }`,
        })
      );
    }
  };

  return (
    <div className='channelSelectBoxContainer'>
      <div className='checkbox-wrapper'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleChangeTemplate}
          className={isChecked ? 'checked' : ''}
        />
      </div>
      <Typography variant='overline'>{channel}</Typography>
      <CustomSelect
        channel={channel}
        selected={channelValue}
        optionsList={channelTemplate}
        selectedOptionValue={selectedOption}
      />
    </div>
  );
};

export default ChannelSelectBox;
