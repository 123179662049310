import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface IProps {
  isActive?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideMenuItemMainCont: {
      padding: '2px',
      margin: '2px',
      display: 'flex',
      flexDirection: 'column',
      width: '75px',
      height: '75px',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      backgroundColor: (props: IProps) =>
        props.isActive === true
          ? theme.kollectAITheme.lightPrimaryColor
          : 'transparent',
      position: 'relative',
      cursor: 'pointer',
    },
    sideMenuItemActiveBarStyle: {
      position: 'absolute',
      // borderLeft: " 1px solid #2D62ED",
      borderRadius: '180px',
      top: '-1.5px',
      bottom: '-1.5px',
      left: '0px',
      background: '#B1C5F7',
    },
    sideMenuItemRightTitleStyle: {
      fontSize: '12px',
      lineHeight: 1.15,
      fontWeight: 'bold',
      marginTop: '5px',
      color: theme.kollectAITheme.primaryColor,
    },
    sideMenuIteIconContStyle: {
      width: '40px',
      background: '#E6EFF9',
      borderRadius: '10px',
      display: ' flex',
      justifyContent: 'center',
      alignItems: ' center',
      height: '40px',
    },
    sideMenuItemRightIconStyle: {
      color: (props: IProps) =>
        props.isActive === true ? '#2D62ED' : '#112958',
    },
  })
);
// ;

export default useStyles;
