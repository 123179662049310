import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

type BreadcrumbProps = {
  main?: any;
  nested?: string;
  showNested?: boolean;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  main,
  nested,
  showNested,
}) => {
  const classes = useStyles();
  // render
  return (
    <Box className={classes.breadcrumbContainer}>
      {main && (
        <Box className={classes.breadcrumbItems}>
          <Typography>{main}</Typography>
          <Typography>&gt;</Typography>
          <Typography>{nested}</Typography>
        </Box>
      )}
      {showNested && (
        <Box className={classes.breadcrumbPageTitle}>
          <Typography fontSize='1.5rem'>{nested}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Breadcrumb;
