import { AwsLinkType } from 'constants/types';
import { getAccessToken } from 'utils/auth';
import { errorToast } from 'utils/toast';

export const lambdaCustomerPIIData = async ({
  customer_id,
  awsUrl,
}: {
  customer_id: string;
  awsUrl: AwsLinkType;
}) => {
  try {
    const res = await fetch(awsUrl.link, {
      method: 'POST',
      body: JSON.stringify({ customer_id }),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json',
        token: `${getAccessToken()}`,
        'x-api-key': awsUrl.api_key,
      },
    });
    const jsonRes = await res.json();
    if (jsonRes.statusCode === 200) {
      const data = JSON.parse(jsonRes.body);
      if (Array.isArray(data)) return data[0];
      return null;
    }
    return null;
  } catch (error) {
    errorToast('Error in fetching customer PII data');
    return null;
  }
};
