import { Box, Typography } from '@mui/material';

const chats = [
  {
    id: '041172d4-1f0e-460b-b072-aa10fdb72ae3',
    messages: [`How can we help? We're here for you!`],
    timestamp: '1:15 PM',
    sender: 'agent',
  },
  {
    id: '4c17f2ad-96ef-4cb1-acd5-5099cb49c864',
    messages: [
      `Hey John, I am looking for payment link to make the payment.`,
      'Can you please help?',
    ],
    timestamp: '1:15 PM',
    sender: 'customer',
  },
  {
    id: '6938bce6-367b-49d7-9af0-763d45cb1970',
    messages: ['Absolutely!', 'Here is the link you can use to pay <link>'],
    timestamp: '1:16 PM',
    sender: 'agent',
  },
];

const CustomerChatBody = () => {
  return (
    <Box sx={{ padding: '1rem' }}>
      {chats.map((chat) => {
        return (
          <Box key={chat.id}>
            <Box
              sx={{
                marginBottom: '1rem',
                display: 'flex',
                gap: '0.8rem',
                width: '100%',
                justifyContent: chat.sender === 'agent' ? 'end' : 'start',
              }}
            >
              {chat.sender === 'customer' && (
                <Box
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#808080',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color='#fff' mt='5px'>
                    C
                  </Typography>
                </Box>
              )}
              <Box maxWidth='60%'>
                {chat.messages.map((message) => (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: chat.sender === 'agent' ? 'end' : 'start',
                    }}
                  >
                    <Box
                      sx={{
                        filter: 'drop-shadow(0px 2px rgba(50, 71, 92, 0.04))',
                        borderRadius:
                          chat.sender === 'agent'
                            ? '6px 0 6px 6px'
                            : '0 6px 6px',
                        padding: '0.75rem',
                        background:
                          chat.sender === 'agent' ? '#6AC5AB' : '#FFFFFF',
                        marginBottom: '0.5rem',
                        display: 'inline-flex',
                      }}
                    >
                      <Typography
                        color={chat.sender === 'agent' ? '#fff' : '#32475CDE'}
                        fontSize='0.9rem'
                      >
                        {message}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              {chat.sender === 'agent' && (
                <Box
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#6AC5AB',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color='#fff' mt='5px'>
                    A
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default CustomerChatBody;
