import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import {
  FormikCustomerDisplay,
  FormikCustomerSelect,
  FormikCustomerTextarea,
} from 'components/formik/customer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { changeStatusHandler, statusPayload } from 'utils/customer/form';

import PTP from './PTP/ptp';
import CallRequired from './CallRequired/callRequired';
import Hardship from './Hardship/hardship';
import NotEngaged from './NotEngaged/notEngaged';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { postCustomerStatusUpdate } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import { lamdaStatusUpdate } from 'apis/aws/lambdaStatusUpdate';
import { selectedCustomersArr } from 'store/reducer/customerReducer';
import { successToast } from 'utils/toast';
import { awsUrl } from 'store/reducer/auth';

const status = [
  { label: 'PTP', value: 'PTP' },
  { label: 'PTP Broken', value: 'Broken_Promise' },
  { label: 'Call Required', value: 'Call_Required' },
  { label: 'Hardship', value: 'Hardship' },
  { label: 'Not Engaged', value: 'Not_engaged' },
  { label: 'Bad Contact', value: 'Bad_Contact' },
  { label: 'Default/No Category', value: 'Default' },
];

interface ChangeCustomerStatusFormProps {
  onClose: () => void;
}

const validation = yup.object().shape({
  newStatus: yup.string().required('Required'),
});

const ChangeCustomerStatusForm: React.FC<ChangeCustomerStatusFormProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: customerId = '' } = useParams();

  const statusUpdateAWSUrl =
    useAppSelector(awsUrl)?.cust_mgmt_customer_pii_data_update_api;
  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const profileData: any = useAppSelector(
    (state) => state.customer.customerProfile?.[0]
  );
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    status: '',
    newStatus: '',
    comments: '',
  });
  const [validationSchema, setValidationSchema] = useState<any>(validation);

  useEffect(() => {
    if (profileData) {
      const statusObj = status.find(
        (status) => status.value === profileData.current_status
      );
      const customerStatus =
        statusObj?.value === 'Default' ? 'Default' : statusObj?.label;
      setInitialValues({
        status: customerStatus,
        newStatus: '',
        comments: '',
      });
    }
  }, [profileData]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const odf_customer_id =
        selectedCustomers && selectedCustomers?.length
          ? selectedCustomers?.map((customer) => customer?.odf_customer_id)
          : [customerOdfId];
      const payload = statusPayload({
        ...values,
        odf_customer_id,
      });

      const { alternate_email, alternate_phone_number, status_type, ...rest } =
        payload;
      let updatePayload = { ...rest };

      if (
        ['Not_engaged', 'Bad_Contact'].includes(values?.newStatus) &&
        values?.hasAlternateContact
      ) {
        const { odf_customer_id, ...lambdaRest } = payload;
        const customer_id =
          selectedCustomers && selectedCustomers?.length
            ? selectedCustomers?.map((customer) => customer?.customer_id)
            : [customerId];
        const lambdaPayload = { ...lambdaRest, customer_id };
        await lamdaStatusUpdate({
          ...lambdaPayload,
          awsUrl: statusUpdateAWSUrl,
        });
        updatePayload.status = status_type;
      }
      await dispatch(postCustomerStatusUpdate(updatePayload));
      successToast('Status has been updated successfully');
      onClose();
      navigate('/customer-management');
    } finally {
      setLoading(false);
    }
  };

  const handleInitials = (selectedStatus: string) => {
    const { status, comments } = initialValues;
    changeStatusHandler(
      status,
      selectedStatus,
      comments,
      setInitialValues,
      setValidationSchema
    );
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            {selectedCustomers && selectedCustomers.length ? null : (
              <FormikCustomerDisplay
                name='status'
                label='Current Customer Status'
              />
            )}

            <FormikCustomerSelect
              name='newStatus'
              label='New Customer Status'
              placeholder='Select Status'
              options={status}
              handleChange={handleInitials}
            />

            {values.newStatus === 'PTP' && <PTP />}
            {values.newStatus === 'Call_Required' && (
              <CallRequired values={values} />
            )}
            {values.newStatus === 'Hardship' && <Hardship />}
            {/* {values.newStatus === 'Bad Contact' && (
              <BadContact values={values} />
            )} */}
            {(values.newStatus === 'Bad_Contact' ||
              values.newStatus === 'Not_engaged') && (
              <NotEngaged values={values} />
            )}
            {(values.newStatus === 'Broken_Promise' ||
              values.newStatus === 'Default') && <></>}

            <FormikCustomerTextarea
              name='comments'
              label='Comments'
              labelDesc='Reason for change or generic notes'
            />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Update</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeCustomerStatusForm;
