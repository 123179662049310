import { useState, useEffect } from 'react';
import _ from 'lodash';

import SideMenuItem from '../../../components/sideMenuItem';
import { useLocation, useNavigate } from 'react-router-dom';

import useStyles from './index.styles';
import { Box } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setUploadedFileDetailReducerToInitial } from 'store/reducer/uploadedFileDetailReducer';
import { setStepperValue } from 'store/reducer/uiUpdateReducer';
// import { userAccess } from 'utils/helper';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const SideMenuComponent = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // const userRole = useAppSelector((state: any) => state.auth.role);
  const permissions = useAppSelector(userPermissions);

  const [showChildAccordian, setShowChildAccordian] = useState<number>(1);

  useEffect(() => {
    const url = window.location.href.split('/').at(-1);
    if (url === '') setShowChildAccordian(1);
    if (url === 'performance-reports') setShowChildAccordian(2);
    if (url === 'strategy') setShowChildAccordian(3);
    if (url === 'campaigns') setShowChildAccordian(4);
    if (url === 'template-management') setShowChildAccordian(5);
    if (url === 'workflow') setShowChildAccordian(6);
    if (url === 'ab-testing') setShowChildAccordian(7);
    if (url === 'customer-management') setShowChildAccordian(8);
    if (url === 'subscriptions') setShowChildAccordian(9);
    if (url === 'integrations') setShowChildAccordian(0);
    if (url === 'roles' || url === 'permissions') setShowChildAccordian(100);
  }, [location.pathname]);

  return (
    <div className={classes.sideMenuComponentCont}>
      <Box>
        <SideMenuItem
          title='Dashboard'
          onClick={() => {
            setShowChildAccordian(1);
            navigate('/');
          }}
          isActive={showChildAccordian === 1 && true}
          iconName='dashboardIcon'
          disabled={false}
        />
        {/* <SideMenuItem
          title='Performance'
          onClick={() => {
            setShowChildAccordian(2);
            navigate('performance-reports');
          }}
          isActive={showChildAccordian === 2 && true}
          iconName='performanceIcon'
          disabled={false}
        /> */}

        {/* Strategy */}
        {/* {userAccess(userRole)?.strategy.access && ( */}
        {_.has(permissions, MODULELIST.STRATEGY) && (
          <SideMenuItem
            title='Strategy'
            onClick={() => {
              navigate('/strategy');
              setShowChildAccordian(3);
            }}
            isActive={showChildAccordian === 3 && true}
            iconName='strategyIcon'
            disabled={false}
          />
        )}

        {/* Campaign */}
        {/* {userAccess(userRole)?.campaign.access && ( */}
        {_.has(permissions, MODULELIST.CAMPAIGN) && (
          <SideMenuItem
            title='Campaign'
            onClick={() => {
              dispatch(setUploadedFileDetailReducerToInitial());
              dispatch(setStepperValue(1));
              // navigate('/create-campaigns');
              navigate('/campaigns');
              setShowChildAccordian(4);
            }}
            isActive={showChildAccordian === 4 && true}
            iconName='campaignIcon'
            disabled={false}
          />
        )}

        {/* Template */}
        {/* {userAccess(userRole)?.template.access && ( */}
        {_.has(permissions, MODULELIST.TEMPLATE) && (
          <SideMenuItem
            title='Template MGMT'
            onClick={() => {
              setShowChildAccordian(5);
              navigate('/template-management');
            }}
            isActive={showChildAccordian === 5 && true}
            iconName='templateIcon'
            disabled={false}
          />
        )}

        {/* Workflow Mgmt */}
        {/* {userAccess(userRole)?.workflow.access && ( */}
        {_.has(permissions, MODULELIST.WORKFLOW) && (
          <SideMenuItem
            title='Workflow MGMT'
            onClick={() => {
              navigate('/workflow');
              setShowChildAccordian(6);
            }}
            isActive={showChildAccordian === 6 && true}
            iconName='branches'
            disabled={false}
          />
        )}

        {/* AB testing */}
        <SideMenuItem
          title='AB Testing'
          onClick={() => {
            navigate('ab-testing');
            setShowChildAccordian(7);
          }}
          isActive={showChildAccordian === 7 && true}
          iconName='ab-testing-solid'
          disabled={false}
        />

        {/* Customer Mgmt */}
        {/* {userAccess(userRole)?.customer.access && ( */}
        {_.has(permissions, MODULELIST.CUSTOMER) && (
          <SideMenuItem
            title='Customer MGMT'
            onClick={() => {
              navigate('customer-management');
              setShowChildAccordian(8);
            }}
            isActive={showChildAccordian === 8 && true}
            iconName='customer_uncategoriesed'
            disabled={false}
          />
        )}
      </Box>

      {/* Subsription */}
      <Box>
        <SideMenuItem
          title='FB Connect'
          onClick={() => {
            navigate('/fb-connect');
            setShowChildAccordian(9);
          }}
          isActive={showChildAccordian === 9 && true}
          iconName='subscription-icon'
          disabled={false}
        />
      </Box>
    </div>
  );
};

export default SideMenuComponent;
