import Header from './header';
import SideMenuComponent from './sideMenu';
import useStyles from './index.styles';
import BaseRouter from 'routes/BaseRouter';
import SessionExpirationModal from 'components/SessionExpirationModal';

const BaseContainer = () => {
  const classes = useStyles();

  return (
    <div>
      <SessionExpirationModal />
      <Header />
      <div className={classes.baseContainerStyle}>
        <div
          className='scrollbar'
          style={{
            position: 'fixed',
            top: 70,
            height: 'calc(100vh - 70px)',
            zIndex: 10,
          }}
        >
          <div className={classes.baseContainerLeftCont}>
            <SideMenuComponent />
          </div>
        </div>
        <div className={classes.baseContainerRightCont}>
          <BaseRouter />
        </div>
      </div>
    </div>
  );
};

export default BaseContainer;
