import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';
import { kollectAITheme } from 'config/theme';

const CustomerDataCategory = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: {
  categories: any;
  selectedCategory: string;
  setSelectedCategory: (x: string) => void;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.categoryCont}>
      {categories.map((category: any, index: number) => {
        const { filter_name, no_customers } = category;
        const selected = selectedCategory === filter_name;

        return (
          <Box
            sx={{
              background: selected ? kollectAITheme.primaryColor : '#fff',
              color: selected ? '#fff' : '#000',
            }}
            className={classes.categoryBox}
            onClick={() => setSelectedCategory(filter_name)}
            key={index}
          >
            <Typography mb='1.2rem' align='center' textTransform='capitalize'>
              {filter_name}
            </Typography>
            <Typography
              fontSize='1.4rem'
              align='center'
              color={selected ? '#fff' : '#0069AA'}
            >
              {no_customers}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomerDataCategory;
