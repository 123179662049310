import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '0.8em',
      marginTop: '3px',
    },
    radio: {
      '&$checked': {
        color: theme.kollectAITheme.primaryColor,
      },
    },
    checked: {},
    radioGroupContainer: {
      padding: '3px 10px',
      borderRadius: '11px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',

      '& .MuiSvgIcon-root': {
        width: '0.9em',
        height: '0.9em',
      },
    },
    workflowTypeButtonStyle: {
      fontWeight: 600,
    },
    workflowTypeButtonActiveStyle: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      padding: '0.5rem',
    },
    workflowToolActiveStyle: {
      background: theme.kollectAITheme.primaryColor,
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      color: '#fff',
      fontWeight: 600,
      padding: '0.7rem',
    },
    workflowTypeCont: {
      background: '#D9D9D9',
      boxShadow:
        'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0.4rem',
      marginBottom: '1rem',
    },
    workflowDetailsCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    workflowDetailsTable: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '7px',
      marginTop: '0.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    workflowDetailsTableBox: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.9rem',
      marginBottom: '0.5rem',
      borderBottom: '1px solid #9696961C',
    },
    workflowDetailsTableBoxTitle: {
      width: '120px',
      height: '45px',
      display: 'grid',
      placeItems: 'center',
    },
    workflowToolsWrapper: {
      background: 'rgba(0, 67, 87, 0.1)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '0.2rem 1rem',
      marginTop: '0.5rem',
    },
    workflowToolsCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // padding: '1rem 0',
      border: '1px solid rgba(44, 98, 236, 0.4)',
      borderRadius: '8px',
      position: 'relative',

      '& input': {
        border: 'transparent',
        background: 'transparent',
        outline: 'none',
        width: '100%',
        height: '30px',
        padding: '10px 15px',
        fontSize: '1rem',
      },
    },
    workflowToolOptionCont: {
      background: '#FFFFFF',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      textAlign: 'center',
      margin: '1rem auto',
    },
    scheduleToRunCont: {
      marginLeft: '14px',
      color: '#6F6F6F',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    scheduleToRunValue: {
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      padding: '4px 10px',
    },
  })
);

export default useStyles;
