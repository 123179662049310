import { Box, Grid, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { useAppSelector } from 'hooks/useRedux';
import React, { useEffect, useState } from 'react';
import PopulationLine from './populationLine';

const ChallengerSummary = () => {
  const [randomMin, setRandomMin] = useState(0);
  const [randomMax, setRandomMax] = useState(100);
  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );
  const strategySliderValue: any = useAppSelector(
    (state: any) => state.strategy.sliderValue
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const challengerSummary = Array.isArray(strategyDetailsData?.strategy_summary)
    ? strategyDetailsData?.strategy_summary?.filter(
        (strategy: any) => strategy.ab_testing_group === 'B'
      )[0] || {}
    : strategyDetailsData?.strategy_summary || {};

  useEffect(() => {
    const min =
      strategySliderValue !== 0
        ? 100 - strategySliderValue + 1
        : 100 - strategySliderValue;
    setRandomMin(min);
    if (challengerSummary.random_max === 0 && strategySliderValue !== 0) {
      setRandomMax(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategySliderValue]);

  useEffect(() => {
    setRandomMin(challengerSummary.random_min || 100);
    setRandomMax(challengerSummary.random_max || 100);
  }, [challengerSummary]);

  return (
    <>
      <Typography fontSize={'1.2rem'} textAlign='center' marginTop={'1rem'}>
        Challenger Summary (B)
      </Typography>
      <Typography fontSize={'1.2rem'} textAlign='center' marginTop={'1rem'}>
        (B) Population %
      </Typography>
      <Grid container marginTop='1rem'>
        <Grid item xs={4}>
          <Box>
            <Typography
              fontSize={'1.5rem'}
              textAlign='center'
              marginTop={'1rem'}
              color='#2C62EC'
              fontWeight={'bold'}
            >
              {randomMin || 100}-{randomMax || 100}
            </Typography>
            <Typography
              fontSize={'0.75rem'}
              textAlign='center'
              color='rgba(51, 51, 51, 0.6)'
            >
              Random Number
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          {/* <Typography fontSize={"1.2rem"} textAlign="center" marginTop={'1rem'}>Stepper</Typography> */}
          <PopulationLine />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent='center'
        columnGap={6.5}
        marginTop='3.75rem'
      >
        <Grid item>
          <CustomIcon name='email-icon' size={20} color='#E92C2C' />
          <Typography fontSize={'1.5rem'} textAlign='center' color='#E92C2C'>
            {challengerSummary?.email || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name='smsTemplate' size={20} color='#FFB600' />
          <Typography fontSize={'1.5rem'} textAlign='center' color='#FFB600'>
            {challengerSummary?.sms || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name='whatsapp-icon' size={20} color='#00C087' />
          <Typography fontSize={'1.5rem'} textAlign='center' color='#00C087'>
            {challengerSummary?.whatsapp || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name='phone-icon' size={20} color='#115180' />
          <Typography fontSize={'1.5rem'} textAlign='center' color='#115180'>
            {challengerSummary?.ivr || 0}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChallengerSummary;
