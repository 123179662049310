import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';

import { FormikCustomerSelect } from 'components/formik/customer';

const connectionOptions = [
  { label: 'Call', value: 'call' },
  { label: 'Chat', value: 'chat' },
];

const validationSchema = yup.object().shape({
  connectionType: yup.string().required('Required'),
});

const ConnectToAnAgent: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  const [inititalState, setInititalState] = useState({
    connectionType: '',
  });

  useEffect(() => {
    if (value) {
      setInititalState({ connectionType: value });
    }
  }, [value]);

  const handleSubmit = (values: FormikValues) => {
    setFieldValue(actionPath, 'connect_to_an_agent');
    setFieldValue(path, values.connectionType);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Box>
              <Typography mb='1rem'>
                Please select the type of connection
              </Typography>

              <FormikCustomerSelect
                name='connectionType'
                placeholder='Select'
                options={connectionOptions}
              />
            </Box>

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConnectToAnAgent;
