import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalBox: {
      borderRadius: '8px',
      backgroundColor: '#fff',
      outline: 'none',
      padding: '2rem 2rem',
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
    },
  })
);
