import * as React from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import Loader from 'components/loader';
import { useAppDispatch } from 'hooks/useRedux';
import { verifyEmailToken } from 'store/reducer/auth/actions';
import { setVerifyEmailData } from 'store/reducer/auth';
import InvitationSignup from './Signup';
import LinkExpired from './LinkExpired';
import { verifyUserEmail } from 'store/reducer/userReducer/action';

const Verification = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const twoFa = searchParams.get('two_f_a');
  const dispatch = useAppDispatch();

  const [tokenState, setTokenState] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const verifyToken = React.useCallback(async () => {
    if (token && twoFa) {
      setLoading(true);
      try {
        const res = await dispatch(
          verifyUserEmail({ token, two_f_a: twoFa })
        ).unwrap();
        setTokenState('verified');

        // const { meta, data } = await dispatch(
        //   verifyUserEmail({ token, two_f_a: twoFa === 'False' ? false : true })
        // ).unwrap();
        // if (meta?.status === 'SUCCESS') {
        //   setTokenState('verified');
        //   dispatch(setVerifyEmailData({ ...data, token }));
        // }
        // if (!meta?.success) {
        //   setTokenState('expired');
        // }
      } catch (err) {
        setTokenState('expired');
      }
      setLoading(false);
    }
  }, [dispatch, token, twoFa]);

  React.useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  if (!tokenState) return <Loader loading={true} />;

  return (
    <>
      <Loader loading={loading} />

      <Box width='55%'>
        <InvitationSignup />
        {/* {tokenState === 'verified' && <InvitationSignup />}
        {tokenState === 'expired' && <LinkExpired />} */}
      </Box>
    </>
  );
};

export default Verification;
