import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import authReducer from './reducer/auth';
import uploadedFileDetailReducer from './reducer/uploadedFileDetailReducer';
import uiUpdateReducer from './reducer/uiUpdateReducer';
import strategyReducer from './reducer/strategyReducer';
import templateReducer from './reducer/templateReducer';
import workflowReducer from './reducer/workflowReducer';
import integrationReducer from './reducer/IntegrationReducer';
import customerReducer from './reducer/customerReducer';
import campaignReducer from './reducer/campaignReducer';
import permissionReducer from './reducer/permissionReducer';
import roleReducer from './reducer/roleReducer';
import userReducer from './reducer/userReducer';
import fbConnectReducer from './reducer/fbConnectReducer';

const reducers = combineReducers({
  auth: authReducer,
  uploadedFileDetail: uploadedFileDetailReducer,
  uiUpdate: uiUpdateReducer,
  strategy: strategyReducer,
  template: templateReducer,
  workflow: workflowReducer,
  integration: integrationReducer,
  customer: customerReducer,
  campaign: campaignReducer,
  permission: permissionReducer,
  role: roleReducer,
  user: userReducer,
  fbConnect: fbConnectReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  timeout: 500,
  blacklist: ['uiUpdate', 'template', 'campaign'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
