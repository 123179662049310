import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '6px',
      padding: '10px 1rem 12px',
      marginLeft: '1rem',
      width: '160px',
      cursor: 'pointer',
      '& img': { height: '30px', width: '30px' },
    },
    circle: {
      height: '20px',
      width: '20px',
      background: '#D4DCEB',
      borderRadius: '30px',
      position: 'absolute',
      top: '16px',
      left: '6px',
      display: 'grid',
      placeItems: 'center',
    },
    activeSubCircle: {
      height: '12px',
      width: '12px',
      background: '#6AC5AB',
      borderRadius: '50%',
    },
    paymentResponseWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '1rem',
    },
  })
);

export default useStyles;
