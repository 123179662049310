import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewWorkflowButton: {
      background: theme.kollectAITheme.primaryColor,
      color: '#fff',
      borderRadius: '20px',
      padding: '10px 20px',
      fontSize: '1rem',
    },
  })
);

export default useStyles;
