import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: '98%',
      margin: 'auto',
    },
    strategyContentContainer: {
      border: '1px solid #C6C6C6',
      padding: '2rem 0',
      margin: '2rem',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
      paddingRight: '2rem',
    },
    saveButton: {
      border: 'none',
      background: '#2C62EC',
      color: '#fff',
      padding: '0.6em 3.5em',
      borderRadius: '20px',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
