import { useCallback, useEffect, useState } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';

import ViewHistory from './viewHistory';
import AddNotesForm from 'components/customer/ActionModals/AddNotes/addNotes';
import { useAppDispatch } from 'hooks/useRedux';
import { setCustomerOdfID } from 'store/reducer/customerReducer';
import { getCustomerNotes } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';

interface AddNotesModalProps {
  data: any;
  open: boolean;
  onClose: () => void;
}

const AddNotesModal: React.FC<AddNotesModalProps> = ({
  data,
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const [showHistory, setShowHistory] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCustomerNotes = useCallback(async () => {
    try {
      setLoading(true);
      const res = await dispatch(
        getCustomerNotes({
          odf_customer_id: data?.odf_customer_id,
          customer_id: data?.customer_id,
        })
      ).unwrap();
      if (res?.meta.success) {
        setNotes(res?.data);
      }
    } finally {
      setLoading(false);
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      fetchCustomerNotes();
      dispatch(setCustomerOdfID(data?.odf_customer_id));
    }
  }, [data, fetchCustomerNotes, dispatch]);

  return (
    <>
      <Loader loading={loading} />

      <Modal
        open={open}
        onClose={() => {
          onClose();
          setShowHistory(false);
          setNotes([]);
        }}
        sx={{ display: 'grid', placeItems: 'center' }}
        disableEnforceFocus={false}
      >
        <Box
          sx={{
            borderRadius: '10px',
            backgroundColor: '#fff',
            outline: 'none',
            minWidth: '40vw',

            '& p': {
              fontFamily: 'Overpass',
            },
          }}
        >
          <Box
            sx={{
              background: kollectAITheme.primaryColor,
              borderRadius: '10px 10px 0px 0px',
              padding: '1rem',
              position: 'relative',

              '& p': {
                fontSize: '1.2rem',
                textAlign: 'center',
                color: '#fff',
                fontFamily: 'Inter',
              },
            }}
          >
            <Typography>Add Notes</Typography>

            <Typography
              sx={{
                position: 'absolute',
                right: '1rem',
                top: '1.2rem',
                fontSize: '0.9rem !important',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => setShowHistory((prev) => !prev)}
            >
              {showHistory ? 'Add Note' : 'View History'}
            </Typography>
          </Box>
          <Box p='1rem 2rem'>
            {showHistory ? (
              <ViewHistory notes={notes} />
            ) : (
              <AddNotesForm onClose={onClose} actionType='Generic notes' />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AddNotesModal;
