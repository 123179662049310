import { styled, Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';

// import { userAccess } from 'utils/helper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import WorkflowButton from './button';
import WorkflowFilter from './filter';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const WorkflowDetailsHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1rem',
});

interface WorkflowDetailsHeaderProps {
  isEmpty: boolean;
  onEditClick: () => void;
  onDelete: () => void;
  onSearch: (x: string, y: any) => void;
}

const WorkflowDetailsHeader: React.FC<WorkflowDetailsHeaderProps> = ({
  isEmpty,
  onEditClick,
  onDelete,
  onSearch,
}) => {
  // const userRole = useAppSelector((state: any) => state.auth.role);

  // const userPermissions = userAccess(userRole)?.workflow.permissions;
  const permissions = useAppSelector(userPermissions);

  return (
    <WorkflowDetailsHeaderWrapper>
      <Typography variant='h5'>Workflow Details</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <WorkflowFilter onSearch={onSearch} />

        {/* {userPermissions.includes('create') && ( */}
        {permissions[MODULELIST.WORKFLOW].includes('create') && (
          <WorkflowButton
            text='Duplicate & Edit'
            bgColor={isEmpty ? 'rgb(162, 167, 174)' : '#6AC5AB'}
            icon={<EditIcon />}
            disabled={isEmpty}
            onClick={onEditClick}
          />
        )}

        {/* {userPermissions.includes('delete') && ( */}
        {permissions[MODULELIST.WORKFLOW].includes('delete') && (
          <WorkflowButton
            text='Delete'
            bgColor={isEmpty ? 'rgb(162, 167, 174)' : '#DAA09A'}
            icon={<DeleteIcon />}
            disabled={isEmpty}
            onClick={onDelete}
          />
        )}
      </Box>
    </WorkflowDetailsHeaderWrapper>
  );
};

export default WorkflowDetailsHeader;
