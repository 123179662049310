import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { RiFileCopyLine } from 'react-icons/ri';
import useStyles from './index.styles';
import { errorToast, infoToast } from 'utils/toast';
import IntegrationDragAndDrop from './dragAndDrop';
import { FaFileCsv } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import CloseIcon from '@mui/icons-material/Close';
import { integrationSampleFile } from 'store/reducer/IntegrationReducer/action';
import { lambdaUserVerification } from 'apis/aws/lambdaUserVerification';
import Loader from 'components/loader';
// import { userAccess } from 'utils/helper';
import CustomModal from 'components/Modal/CustomModal';
import InvalidEntries from './invalidEntries';
import { awsUrl } from 'store/reducer/auth';

const IntegrationFileUpload = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const urlObj = useAppSelector(awsUrl)?.file_upload;

  const [sftpLocation, setSftpLocation] = useState<string>('');
  const [file, setFile] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number>(0);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);

  const handleUpload = async () => {
    // if (file && userPermissions.includes('allow_upload')) {
    if (file && urlObj) {
      const payload = { file, type: 'etl', urlObj };
      setLoading(true);
      const res = await lambdaUserVerification(payload);
      if (res!.success) {
        setOpenSuccess(true);
        setTotalData(res!.total_records);
        setFile('');
      } else {
        if (res?.status === 500) {
          setOpenError(true);
          setErrorData(res!.data);
        }
      }
      setLoading(false);
    }
  };

  const handleTemplateDownload = async () => {
    const { meta, data } = await dispatch(
      integrationSampleFile({ fileType: 'sample' })
    ).unwrap();
    if (meta?.success) {
      const url = data?.url;
      if (url) {
        window.open(url, '_self');
      } else {
        errorToast('Download template failed');
      }
    } else {
      errorToast('Download template failed');
    }
  };

  // const userPermissions = userAccess(userRole)?.integration.permissions;

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.fileUploadWrapper}>
        <Box>
          <Typography fontSize='1.1em' fontWeight={500}>
            File Upload
          </Typography>
          <Typography className={classes.infoText}>
            Kindly make sure the uploaded file is in the specified format. Refer
            the <span onClick={handleTemplateDownload}>template</span> to upload
            data for your customers
          </Typography>
        </Box>

        <Box my='2rem'>
          {!file ? (
            <Box height='350px'>
              <IntegrationDragAndDrop setFile={setFile} />
            </Box>
          ) : (
            <Box
              sx={{ textAlign: 'start', width: '80%', margin: ' 10px auto' }}
            >
              <Box>
                <Typography>File added</Typography>
                <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
                  <Box className={classes.fileCont}>
                    <Box>
                      <FaFileCsv color='#00C087' fontSize='25px' />
                    </Box>
                    <Box
                      sx={{ display: 'flex', alignItems: 'start', gap: '10px' }}
                    >
                      <Box>
                        <Typography>{file?.name}</Typography>
                        <Typography fontSize='12px' color='rgba(0, 0, 0, 0.7)'>
                          {file?.size} B
                        </Typography>
                      </Box>
                      {/* <button onClick={() => handlePreview(file)}>
                    <Typography color='#00C087'>Preview</Typography>
                  </button> */}
                    </Box>
                    <button onClick={() => setFile('')}>
                      <CloseIcon style={{ fontSize: '16px' }} />
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Typography
          sx={{ color: '#636C80', fontFamily: 'Overpass', margin: '2rem auto' }}
        >
          Or
        </Typography>

        <Box mb='2rem'>
          <Typography fontSize='1.1em' fontWeight={500}>
            Drop to this SFTP location
          </Typography>

          <Box className={classes.copyUrlCont}>
            <input
              type='text'
              placeholder='user@remotehost_domainname'
              value={sftpLocation}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setSftpLocation((e.target as HTMLInputElement).value)
              }
            />
            <Box
              className={classes.copyUrlButton}
              onClick={() => {
                navigator.clipboard.writeText(sftpLocation);
                infoToast('URL Copied');
              }}
            >
              <RiFileCopyLine color='#fff' />
              <Typography fontSize='1em'>Copy URL</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.actionButtonWrapper}>
        <button onClick={handleTemplateDownload}>
          <Typography
            sx={{
              textDecoration: 'underline',
              fontWeight: 600,
              color: kollectAITheme.primaryColor,
            }}
          >
            Download Template
          </Typography>
        </button>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5em' }}>
          <button>
            <Typography>Cancel</Typography>
          </button>

          <Box
            className={classes.uploadButton}
            sx={{
              background: file ? '#6AC5AB' : '#A2A7AE',
              cursor: file ? 'pointer' : 'default',
            }}
            onClick={handleUpload}
          >
            <Typography>Upload</Typography>
          </Box>
        </Box>
      </Box>

      <CustomModal
        header='Data uploaded successfully'
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        children={
          <Box>
            <Typography align='center' fontWeight={600}>
              Uploaded {totalData} Customer Data Entries Successfully!
            </Typography>
          </Box>
        }
      />

      <CustomModal
        header='Found few invalid entries'
        open={openError}
        onClose={() => setOpenError(false)}
        children={
          <InvalidEntries
            errors={errorData}
            onClose={() => setOpenError(false)}
          />
        }
      />
    </>
  );
};

export default IntegrationFileUpload;
