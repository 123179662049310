import * as React from 'react';
import { LineChart, Line } from 'recharts';

const data = [
  { name: 'A', conversion: 34 },
  { name: 'B', conversion: 64 },
  { name: 'C', conversion: 94 },
  { name: 'D', conversion: 74 },
  { name: 'E', conversion: 104 },
  { name: 'F', conversion: 114 },
  { name: 'G', conversion: 124 },
];

export default function ConversionGraph() {
  return (
    <LineChart width={80} height={80} data={data}>
      <Line
        type='monotone'
        dataKey='conversion'
        stroke='#004357'
        strokeWidth={4}
        dot={false}
      />
    </LineChart>
  );
}
