import { Box, Typography, styled } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

interface FormikAuthPasswordProps {
  label?: string;
  name: string;
  placeholder?: string;
  show: boolean;
  handleShow: () => void;
}

const InputWrapper = styled('div')(() => ({
  '& input': {
    width: '93%',
    background: '#ECF2F7',
    border: 'transparent',
    height: '45px',
    borderRadius: '5px',
    padding: '0 1em',
    fontSize: '1em',
    outline: 'none',
    fontFamily: 'Inter',
  },
}));

const ShowPasswordWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 10,
  bottom: '8px',
  cursor: 'pointer',
  fontSize: '1.2em',
}));

const FormikAuthPassword: React.FC<FormikAuthPasswordProps> = ({
  label,
  name,
  placeholder = '',
  show,
  handleShow,
}) => {
  return (
    <Box>
      {label && <Typography mb='5px'>{label}</Typography>}

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <InputWrapper>
            <Box position='relative'>
              <input
                type={show ? 'text' : 'password'}
                placeholder={placeholder}
                {...field}
              />
              <ShowPasswordWrapper onClick={handleShow}>
                {show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </ShowPasswordWrapper>
            </Box>
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </InputWrapper>
        )}
      </Field>
    </Box>
  );
};

export default FormikAuthPassword;
