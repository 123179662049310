import { v4 as uuidv4 } from 'uuid';
import runWorkflowImg from 'assets/run_workflow.svg';
import addTriggerImg from 'assets/add_trigger.svg';
import endImg from 'assets/end.svg';

export const actions = [
  {
    id: uuidv4(),
    title: 'Get Segments',
    desc: 'Adds segments to filter',
    iconName: 'branches',
    iconSize: 28,
    color: '#036482',
  },
  {
    id: uuidv4(),
    title: 'Run Strategy',
    desc: 'Set/define strategy',
    iconName: 'run_strategy',
    iconSize: 28,
    color: '#664672',
  },
  {
    id: uuidv4(),
    title: 'Add Trigger',
    desc: 'Send communication',
    iconName: 'add_trigger',
    img: addTriggerImg,
    color: '#BB9B4C',
  },
  {
    id: uuidv4(),
    title: 'Run Workflow',
    desc: 'Redirect to specific workflow',
    img: runWorkflowImg,
    color: '#ED9144',
  },
  {
    id: uuidv4(),
    title: 'Move to Queue',
    desc: 'Move user to specific queue',
    iconName: 'queue',
    color: '#C07CA9',
  },
  {
    id: uuidv4(),
    title: 'Check Attribute',
    desc: 'Check condition',
    iconName: 'check_attribute',
    color: '#0A91E4',
  },
  {
    id: uuidv4(),
    title: 'Update Attribute',
    desc: 'Update data/attributes',
    iconName: 'update_attribute',
    color: '#468965',
  },
  {
    id: uuidv4(),
    title: 'Set Wait',
    desc: 'Delays for fixed time',
    iconName: 'alarm',
    color: '#95261F',
  },
  {
    id: uuidv4(),
    title: 'Add Loop',
    desc: 'Reasses from specific point',
    iconName: 'loop',
    color: '#47587A',
  },
  {
    id: uuidv4(),
    title: 'AB Testing',
    desc: 'Create AB Testing Split',
    iconName: 'ab_testing',
    iconSize: 28,
    color: '#0084FF',
  },
  {
    id: uuidv4(),
    title: 'End',
    desc: 'Ending workflow',
    img: endImg,
    color: '#000000',
  },
];
