import React from 'react';
import { Box, Typography } from '@mui/material';
import { TbDotsVertical } from 'react-icons/tb';
import ContactProgressBar from './ContactProgressBar';
import useStyles from './style';

const GoodBadContact = ({ channel }: { channel: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.contactWrapper}>
      <Box className={classes.flexBetween}>
        <Typography
          color='rgba(50, 71, 92, 0.87)'
          fontSize='1.4rem'
          fontWeight={500}
        >
          Good vs Bad Contact
        </Typography>
        <button style={{ marginTop: '5px' }}>
          <TbDotsVertical fontSize='1.2rem' color='rgba(50, 71, 92, 0.54)' />
        </button>
      </Box>

      <ContactProgressBar channel={channel} />
    </Box>
  );
};

export default GoodBadContact;
