import { styled } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const WorkflowToolWrapper = styled('div')(() => ({
  background: '#FFFFFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',
  padding: '0 1rem',
  zIndex: 100000,

  '& svg': {
    fontSize: '20px',
  },
}));

export const Button = styled('button')((props) => ({
  background: props.color,
  borderRadius: '8px',
  color: '#fff',
  fontSize: '0.9rem',
  padding: '0.4rem 0.8rem',
}));

export const FullScreenButton = styled('button')({
  fontWeight: 600,
  textDecoration: 'underline',
  color: kollectAITheme.primaryColor,
});

export const ViewFunnelButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  cursor: 'pointer',

  '& img': {
    height: '18px',
  },

  '& p': {
    textDecoration: 'underline',
    color: '#6AC5AB',
    fontWeight: 600,
  },
});

export const DatePickerCont = styled('div')({
  border: '1px solid #d5d5d5',
  display: 'inline-flex',
  padding: '0.2rem 1rem',
  alignItems: 'center',
});

export const FunnelViewCont = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',

  '& button': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const RunWorkflowId = styled('p')({
  background: '#E3E5E8',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: '8px 10px',
  color: '#6F6F6F',
  fontSize: '0.9em',
  minWidth: 150,
  textAlign: 'center',
});
