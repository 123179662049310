import React from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { CustomerCallDatePicker } from 'components/formik/customer/call';

const PromiseToPay = () => {
  return (
    <Box>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <Form>
            <CustomerCallDatePicker
              label='Please select the PTP date'
              name='ptp_date'
            />

            <Box display='flex' justifyContent='end'>
              <Box sx={{ cursor: 'pointer' }}>
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    fontSize: '0.9rem',
                    color: '#004357',
                  }}
                >
                  Update
                </Typography>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PromiseToPay;
