import { Box, styled, Typography } from '@mui/material';
import { useField } from 'formik';

interface CustomFormikTextareaProps {
  label: string;
  [x: string]: any;
}

const FormikTextareaWrapper = styled('div')({
  display: 'flex',
  marginBottom: '1rem',
  opacity: '0.6',

  '& label': {
    minWidth: '250px',
  },

  '& textarea': {
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '0.5rem',
    fontFamily: 'inherit',
    outline: 'none',
    minWidth: '350px',
    height: '80px',
  },
});

const CustomFormikTextarea: React.FC<CustomFormikTextareaProps> = ({
  label,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  return (
    <FormikTextareaWrapper>
      <label>
        <Typography sx={{ fontSize: '1rem' }}>{label}</Typography>
        <Typography sx={{ fontSize: '0.8rem' }}>(Optional)</Typography>
      </label>
      <Box>
        <textarea {...field} {...props} />
        {meta.touched && meta.error && (
          <Box
            sx={{
              fontSize: '0.8rem',
              color: '#d32f2f',
            }}
          >
            {meta.error}
          </Box>
        )}
      </Box>
    </FormikTextareaWrapper>
  );
};

export default CustomFormikTextarea;
