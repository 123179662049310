import { Box } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { roleList } from 'store/reducer/roleReducer';

import { RoleTile } from './roleTile';
import { AddRole } from './addRole';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST, RoleType } from 'constants/types';

export const RoleLists = () => {
  const roles = useAppSelector(roleList);

  const permissions = useAppSelector(userPermissions);

  const cols = roles?.length > 5 ? 5 : 3;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gap: '8px 1rem',
        height: roles?.length > 9 ? '220px' : 'auto',
        overflow: roles?.length > 9 ? 'auto' : 'visible',
        paddingBottom: '10px',
      }}
    >
      {permissions[MODULELIST.USER].includes('create') && <AddRole />}
      {roles?.map((role: RoleType, index: number) => {
        return <RoleTile data={role} key={index} />;
      })}
    </Box>
  );
};
