import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionBoxWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      marginTop: '2rem',
    },
    optionBoxTitle: {
      fontSize: '1.3rem !important',
      fontFamily: 'Inter',
      textAlign: 'center',
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.8rem !important',
      },
    },
  })
);

export default useStyles;
