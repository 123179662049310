import { styled } from '@mui/material';

export const InviteText = styled('div')({
  background: 'rgba(50, 71, 92, 0.08)',
  padding: '12px 1rem',
  marginBottom: '1rem',
  borderRadius: '8px 8px 0 0',
  '& p': { fontWeight: 600 },
});

export const InviteButton = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  background: '#004357',
  color: '#fff',
  padding: '10px 1.5rem 10px 2rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '6px',
  '& p': { color: '#fff !important' },
  '& svg': { fontSize: '1.2rem' },
}));
