import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { kollectAITheme } from 'config/theme';

interface FormikCustomerTimeSlotProps {
  name: string;
}

const TIME_SLOT = [
  {
    daysPartLabel:'Morning',
    daysPartTime:'(9 AM - 12 PM)',
    value:'morning',
  },
  {
    daysPartLabel:'Afternoon',
    daysPartTime:'(12 PM - 3 PM)',
    value:'afternoon',
  },
  {
    daysPartLabel:'Evening',
    daysPartTime:'(3 PM - 6 PM)',
    value:'evening',
  },
  {
    daysPartLabel:'After Office',
    daysPartTime:'(6 PM - 9 PM)',
    value:'after office',
  },
]


const TimeSlotBox = styled(Box)({
  boxShadow:"inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
  padding:"8px 5px 2px 6px",
  minWidth:'35px'
})

const FormikCustomerTimeSlot = ({ name }: FormikCustomerTimeSlotProps) => {
  return (
        <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value = [] } = field;
  
          return (
            <Box mt={2} display="flex" columnGap={4} sx={{cursor:'pointer'}}>
              {TIME_SLOT.map(slot=>{
                const isSelected = value?.includes(slot.value);
                return(
                    <TimeSlotBox sx={{border: isSelected? "3px solid #6AC5AB" : "1px solid rgba(0, 0, 0, 0.04)"}} 
                      onClick={()=> {
                        if(value.includes(slot.value)) {
                          setFieldValue(name, value?.filter((v:any)=> v!== slot.value));
                        } else {
                          setFieldValue(name, [...value,slot.value]);
                        }
                    }}>
                      <Typography textAlign='center' color={kollectAITheme.primaryText} fontSize="14px" fontFamily="Overpass" fontWeight={isSelected? 500 : 400}>{slot.daysPartLabel}</Typography>
                      <Typography textAlign='center' color={kollectAITheme.primaryText} fontSize="14px" fontFamily="Overpass" fontWeight={isSelected? 500 : 400}>{slot.daysPartTime}</Typography>
                    </TimeSlotBox>
                    )
              })}
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
        </Field>  
  );
};

export default FormikCustomerTimeSlot;
