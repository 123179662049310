import api from '../api';
import kollectAiStrategy from './config';

const strategyApi = {
  // async strategySummary(payload: any) {
  //   const url = `${kollectAiStrategy.strategySummary}?bucket=${payload.bucket}&product=${payload.product}&balance_level=${payload.balance_level}&client_id=${payload.client_id}`;
  //   return api.get(url);
  // },
  // async strategyDistribution(payload: any) {
  //   const url = `${kollectAiStrategy.strategyDistribution}?bucket=${payload.bucket}&product=${payload.product}&balance_level=${payload.balance_level}&client_id=${payload.client_id}`;
  //   return api.get(url);
  // },
  async strategyChannelTemplate(payload: any) {
    const url = `${kollectAiStrategy.strategyChannelTemplates}?channel_type=${payload.channel_type}&client_key=${payload.client_id}`;
    return api.get(url);
  },
  async strategyDetailsFilter(payload: any) {
    return api.post(kollectAiStrategy.strategyDetailsFilter, payload);
  },
  async strategyUpdate(payload: any) {
    return api.patch(kollectAiStrategy.strategyUpdate, payload);
  },
  async strategyDistributionUpdate(payload: any) {
    return api.post(kollectAiStrategy.updateSrategyDistribution, payload);
  },
  async strategyCreateGroup(payload: any) {
    return api.post(kollectAiStrategy.strategyCreateGroup, payload);
  },
  async getStrategyList() {
    return api.get(kollectAiStrategy.strategyList);
  },
  async getStrategyDetails({
    id,
    query,
  }: {
    id: string | undefined;
    query?: any;
  }) {
    let queryParams: string = '';
    if (id) {
      queryParams = `?strategy_summary_id=${id}`;
    }
    if (query) {
      queryParams = `?${query}`;
    }
    return api.get(`${kollectAiStrategy.strategyDetails}${queryParams}`);
  },
  async updateStrategyDetails(payload: any) {
    return api.patch(kollectAiStrategy.strategyDetails, payload);
  },
  async deleteStrategy(payload: {
    group_id: Array<string>;
    force_delete?: boolean;
  }) {
    let url = payload?.force_delete
      ? `${kollectAiStrategy.strategyDelete}?strategy_group_id=${JSON.stringify(
          payload.group_id
        )}&force_delete=True`
      : `${kollectAiStrategy.strategyDelete}?strategy_group_id=${JSON.stringify(
          payload.group_id
        )}`;
    return api.delete(url);
  },

  async copyStrategy(payload: {
    base_strategy_group_id: string;
    strategy_group_name: string;
  }) {
    return api.post(kollectAiStrategy.strategyCopyGroup, payload);
  },

  async updateStrategy(payload: {
    strategy_group_id: string;
    segment: string;
    days_range?: string;
    valid_for?: string;
  }) {
    return api.patch(kollectAiStrategy.strategyGroupUpdate, payload);
  },
};

export default strategyApi;
