import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    channelLevelDetailsWrapper: {
      padding: '2rem 2rem 0',
    },
    channelDetailsWrapper: {
      border: '1px solid #E0E7FF',
      padding: '1rem',
      maxHeight: '370px',
      overflowY: 'auto',
      borderRadius: '3px',
      fontSize: '14px',
      display: 'grid',
    },
    channelDetailsItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1rem',
    },
  })
);

export default useStyles;
