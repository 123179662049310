import { Box, Switch, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useStyles from './index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { customerWorkableUpdate } from 'store/reducer/customerReducer/action';

interface DetailsHeaderProps {
  serviceable: boolean;
  customerTimestamp: Date | null;
  setLoading: (x: boolean) => void;
  setServiceable: (x: boolean) => void;
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  serviceable,
  customerTimestamp,
  setLoading,
  setServiceable,
}) => {
  const classes = useStyles();
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(userPermissions);
  const customerOdfID = useAppSelector((state) => state.customer.customerOdfID);

  const handleServiceable = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (userPermissions.includes('update_workable')) {
    if (permissions[MODULELIST.CUSTOMER].includes('update')) {
      setLoading(true);
      try {
        const { checked } = e.target;
        const payload = {
          odf_customer_id: customerOdfID,
          workable_flag: checked,
          customer_id: id,
        };
        await dispatch(customerWorkableUpdate(payload)).unwrap();
        setServiceable(checked);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box display='flex' alignItems='center' gap='1rem' my='1rem'>
        <Typography fontWeight={600} fontSize='1.2rem'>
          Details of Customer ID: {id}
        </Typography>

        <Box display='flex' alignItems='center' gap='10px'>
          <Box
            className={`${classes.activeStatus} ${
              !serviceable ? classes.inactiveStatus : ''
            }`}
          >
            <Typography>
              {serviceable ? 'Serviceable' : 'Unserviceable'}
            </Typography>
          </Box>
          <Switch
            defaultChecked
            checked={serviceable}
            onChange={handleServiceable}
            className={classes.switch}
            size='medium'
          />
        </Box>
      </Box>

      <Typography sx={{ color: '#969696', fontStyle: 'italic' }}>
        In system since{' '}
        {customerTimestamp && moment(customerTimestamp).format('DD/MM/YYYY')}
      </Typography>
    </Box>
  );
};
export default DetailsHeader;
