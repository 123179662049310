import { useState, useEffect, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import useStyles, {
  IntegrationCont,
  IntegrationLine,
  UserButton,
  UserRole,
} from './index.styles';

import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { setSelectedIntegrationOption } from 'store/reducer/IntegrationReducer';
import { handleLogOut, userPermissions } from 'store/reducer/auth';

import { MODULELIST } from 'constants/types';
import integrationImg from 'assets/integration.svg';
import userImg from 'assets/user.svg';

import { HeaderMenuButton } from './MenuItem';
import PlainModal from 'components/Modal/PlainModal';
import { AddUserModal } from 'components/rolesPermissions/roles/modal';
import { setCustomerCategory } from 'store/reducer/customerReducer';
import useClickOutside from 'hooks/useClickOutside';

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const signoutRef = useRef<HTMLDivElement>(null);

  const auth = useAppSelector((state: any) => state.auth);

  const [isIntegrationPage, setIsIntegrationPage] = useState<boolean>(false);
  const [showSignOut, setShowSignOut] = useState<boolean>(false);
  const [openInvite, setOpenInvite] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === '/integrations') {
      setIsIntegrationPage(true);
    } else {
      setIsIntegrationPage(false);
    }
    if (
      location.pathname !== '/customer-management' &&
      location.pathname.split('/')[1] !== 'customer-details'
    ) {
      dispatch(setCustomerCategory(''));
    }
  }, [location, dispatch]);

  const handleIntegration = () => {
    dispatch(setSelectedIntegrationOption(''));
    navigate('integrations');
  };

  const permissions = useAppSelector(userPermissions);

  useClickOutside(signoutRef, () => setShowSignOut(false));

  return (
    <>
      <Box className={classes.headerMainCont}>
        <Box className={classes.headerLeftCont}>
          <Link to='/' className={classes.headerLogoLink}>
            <Typography
              fontSize='1.3rem'
              fontWeight={600}
              className={classes.headerLogoText}
            >
              Kollect AI
            </Typography>
          </Link>
        </Box>

        <Box className={classes.headerRightCont}>
          <>
            <IntegrationLine />
            <IntegrationCont
              isIntegration={isIntegrationPage}
              onClick={handleIntegration}
            >
              <img src={integrationImg} alt='' />
              <Typography>Integrations</Typography>
            </IntegrationCont>
            <IntegrationLine />
          </>
          {/* )} */}
          <UserRole>
            <Typography sx={{ textTransform: 'capitalize' }}>
              {auth?.role_name}
            </Typography>
          </UserRole>

          <Box sx={{ position: 'relative' }}>
            <UserButton onClick={() => setShowSignOut(!showSignOut)}>
              <img src={userImg} alt='' />
              <Typography>{auth?.email}</Typography>
            </UserButton>
            {showSignOut && (
              <div className={classes.avatarClickOptions} ref={signoutRef}>
                <HeaderMenuButton menu='Manage account' onClick={() => {}} />

                {_.has(permissions, MODULELIST.USER) && (
                  <>
                    <HeaderMenuButton
                      menu='Invite a team member'
                      onClick={() => {
                        setOpenInvite(true);
                        setShowSignOut(false);
                      }}
                    />
                    <HeaderMenuButton
                      menu='Roles & Permisions'
                      onClick={() => {
                        navigate('/roles');
                        setShowSignOut(false);
                      }}
                    />
                  </>
                )}
                <HeaderMenuButton
                  menu='Sign Out'
                  onClick={() => dispatch(handleLogOut())}
                />
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <PlainModal
        open={openInvite}
        onClose={() => setOpenInvite(false)}
        children={<AddUserModal />}
      />
    </>
  );
};

export default Header;
