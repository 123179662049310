import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import OTPField from 'components/auth/OTPField';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { generateOtp, resendOtp, verifyOtp } from 'store/reducer/auth/actions';
import OTPVerifiedModal from 'components/auth/modals/OtpVerified';

const VerifyButton = styled('button')(
  ({ disabled }: { disabled: boolean }) => ({
    backgroundColor: disabled ? '#BCBCBC' : '#004357',
    cursor: disabled ? 'default' : 'pointer',
    width: '100%',
    color: '#fff',
    border: 'transparent',
    height: '40px',
    fontSize: '1rem',
    marginTop: '1.5rem',
    marginBottom: '1rem',
    boxShadow: disabled
      ? 'unset'
      : '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
    borderRadius: '8px',
    margin: '3rem auto 1rem',
  })
);

const Verification = ({
  setAction,
  setLoading,
}: {
  setAction: (x: string) => void;
  setLoading: (x: boolean) => void;
}) => {
  const dispatch = useAppDispatch();

  const verifyEmailData = useAppSelector(
    (state: any) => state.auth.verifyEmailData
  );

  const [otp, setOtp] = React.useState<string[]>(new Array(6).fill(''));
  const [submitDisable, setSubmitDisable] = React.useState<boolean>(true);
  const [openOtp, setOpenOtp] = React.useState<boolean>(false);
  const [isPhoneVerified, setIsPhoneVerified] = React.useState<boolean>(false);

  React.useEffect(() => {
    const isOtpEmpty = otp.includes('');
    setSubmitDisable(isOtpEmpty);
  }, [otp]);

  React.useEffect(() => {
    sendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendOtp = async () => {
    const { phone, phone_country_code, phone_verified } = verifyEmailData;
    if (phone && phone_country_code && !phone_verified) {
      try {
        setLoading(true);
        const send_to = `${phone_country_code}${phone}`;
        const { twilio_error } = await dispatch(
          generateOtp({
            req_source: 1,
            channel: 2,
            platform: 1,
            send_to,
            token: verifyEmailData?.token || '',
          })
        ).unwrap();
        console.log({ twilio_error });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const sent_to = `${verifyEmailData?.phone_country_code}${verifyEmailData?.phone}`;
      const otp_value = otp.join('');
      const { meta, data } = await dispatch(
        verifyOtp({
          req_source: 6,
          otp_value,
          sent_to,
          token: verifyEmailData?.token || '',
        })
      ).unwrap();
      if (meta?.success) {
        if (data.verified) {
          setIsPhoneVerified(true);
        } else {
          setIsPhoneVerified(false);
        }
        setOpenOtp(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      setLoading(true);
      const send_to = `${verifyEmailData?.phone_country_code}${verifyEmailData?.phone}`;
      const { twilio_error } = await dispatch(
        resendOtp({
          req_source: 6,
          channel: 2,
          platform: 1,
          send_to,
          token: verifyEmailData?.token || '',
        })
      ).unwrap();
      if (!twilio_error) setOtp(new Array(6).fill(''));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const phone = verifyEmailData?.phone.replace(/\d(?=(?:\D*\d){4})/g, '*');

  return (
    <Box>
      <Typography fontSize='1.6rem' fontWeight={500}>
        Two Step Verification 💬
      </Typography>

      <Box mt='5px' mb='1.5rem'>
        <Typography color='rgba(50, 71, 92, 0.6)'>
          We sent a verification code to your mobile. Enter the code from the
          mobile in the field below.
        </Typography>
        <Typography color='rgba(50, 71, 92, 0.87)' fontWeight={600}>
          {phone}
        </Typography>
      </Box>

      <OTPField otp={otp} setOtp={setOtp} />

      <VerifyButton onClick={handleSubmit} disabled={submitDisable}>
        <Typography>Verify</Typography>
      </VerifyButton>

      <Typography color='rgba(50, 71, 92, 0.6)' align='center' mb='1rem'>
        Didn't get the OTP?{' '}
        <span
          style={{ color: '#004357', cursor: 'pointer' }}
          onClick={handleResend}
        >
          Resend
        </span>
      </Typography>

      <Typography
        sx={{ cursor: 'pointer', color: '#004357', textAlign: 'center' }}
        onClick={() => setAction('update_number')}
      >
        Want to update the number instead?
      </Typography>

      <OTPVerifiedModal
        open={openOtp}
        isPhoneVerified={isPhoneVerified}
        onClose={() => setOpenOtp(false)}
      />
    </Box>
  );
};

export default Verification;
