import { useState } from 'react';
import { Box } from '@mui/material';
import DateTimeBox from 'components/dateTimeBox';
import moment from 'moment';
import { BsChevronDown } from 'react-icons/bs';

const optionsList = [
  { id: 1, name: 'Daily Segment' },
  { id: 2, name: 'One time Campaign' },
];

const CampaignDashboardFilter = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [showOptionList, setShowOptionList] = useState<boolean>(false);

  const handleStartDateChange = (date: Date) => {
    const start = moment(date).format('YYYY-MM-DD');
    setStartDate(start);
  };
  const handleEndDateChange = (date: Date) => {
    const end = moment(date).format('YYYY-MM-DD');
    setEndDate(end);
  };

  const handleListDisplay = () => setShowOptionList((state) => !state);
  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setShowOptionList(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '2rem 4rem 0' }}>
      <DateTimeBox
        text={startDate ? startDate.toString() : 'Start Date'}
        icon='calendar-icon'
        setValue={(date: Date) => handleStartDateChange(date)}
        value={startDate}
        isPositionBottom={true}
      />
      <DateTimeBox
        text={endDate ? endDate.toString() : 'End Date'}
        icon='calendar-icon'
        setValue={(date: Date) => handleEndDateChange(date)}
        value={endDate}
        disabled={startDate ? false : true}
        isPositionBottom={true}
      />
      <div className='customSelectContainer' style={{ minWidth: '190px' }}>
        <div
          className={showOptionList ? 'selectedText active' : 'selectedText'}
          style={{ padding: '8px 10px' }}
          onClick={handleListDisplay}
        >
          {selectedOption.name ? selectedOption.name : 'Campaign Type'}
          <BsChevronDown />
        </div>

        {showOptionList && (
          <ul className='selectOptions'>
            {optionsList?.map((option) => {
              return (
                <div key={option.id}>
                  <li
                    className={`customSelectOption ${
                      option.id === selectedOption.id
                        ? 'customOptionSelected'
                        : ''
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.name}
                  </li>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    </Box>
  );
};

export default CampaignDashboardFilter;
