import { styled } from '@mui/material';

export const RoleHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PermissionLink = styled('div')(() => ({
  '& p': {
    textDecoration: 'underline',
    color: '#004357',
    fontSize: '1.1rem',
    cursor: 'pointer',
    fontWeight: 600,
    pointer: 'cursor',
  },
}));
