import { Navigate } from 'react-router-dom';
import { getAccessToken } from 'utils/auth';

function Protected({ children }: { children: any }) {
  const token = getAccessToken();

  if (!token) return <Navigate to='/login' />;

  return children;
}

export default Protected;
