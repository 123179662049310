import * as React from 'react';
import { Box, Typography } from '@mui/material';
import ChannelSelectBox from './ChannelSelectBox';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { concatenatedTemplate } from 'utils/helper';
import useStyles from './index.styles';
// import { addChannelTemplate } from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import { setCampaignPayload } from 'store/reducer/campaignReducer';
import {
  getCampaignGroups,
  getSelectedChannels,
  setLocalCampaignData,
} from 'utils/campaign/campaignModifier';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const SelectChannelTemplate = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const emailTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.emailTemplate) || []
  );
  const smsTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.smsTemplate) || []
  );
  const ivrTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.ivrTemplate) || []
  );
  const whatsappTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.whatsappTemplate) || []
  );
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );
  const permissions = useAppSelector(userPermissions);

  const [campaignGroups, setCampaignGroups] = React.useState<any>([]);
  const [selectedData, setSelectedData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const groups = getCampaignGroups(campaignPayload?.add_filter).filter(
      (campaign: any) => campaign['#customers'] !== 0
    );
    setCampaignGroups(groups);
    if (!campaignPayload?.select_channel_template.length) {
      setSelectedData(null);
    }
  }, [campaignPayload]);
  // }, [campaignPayload]);

  // React.useEffect(() => {
  //   setSelectedData(null);
  // }, [campaignPayload?.add_filter]);
  React.useEffect(() => {
    const groups = getCampaignGroups(campaignPayload?.add_filter).filter(
      (campaign: any) => campaign['#customers'] !== 0
    );
    const templates = campaignPayload?.select_channel_template;
    const data = getSelectedChannels(templates, groups);
    setSelectedData(data);
  }, []);

  // React.useEffect(() => {
  //   const groups = getCampaignGroups(campaignPayload?.add_filter);
  //   if (campaignPayload?.select_channel_template.length) {
  //     const templates = campaignPayload?.select_channel_template;
  //     const data = getSelectedChannels(templates, groups);
  //     setSelectedData(data);
  //   }
  // }, [campaignPayload?.select_channel_template]);

  const handleChange = async (
    index: number,
    channel: string,
    group: any,
    template?: string
  ) => {
    let newData = { ...selectedData };
    let data = { ...newData[index] };
    if (!template) {
      if (Object.keys(data).includes(channel)) {
        delete data[channel];
      } else {
        data[channel] = '';
        data['group_id'] = group?.group_id;
      }
      newData[index] = data;
    } else {
      if (Object.keys(data).includes(channel)) {
        setLoading(true);
        data[channel] = template;
        newData[index] = data;
        let groups: any = [];
        Object.values(newData).forEach((item: any) => {
          let data: any = { group_id: item.group_id };
          for (let key in item) {
            if (key !== 'group_id') {
              data[key] = 'True';
              data[`${key}_template_id`] = item[key];
            }
          }
          groups.push(data);
        });
        setLocalCampaignData({ select_channel_template: groups });
        dispatch(
          setCampaignPayload({ key: 'select_channel_template', data: groups })
        );
        setLoading(false);
      }
    }
    setSelectedData(newData);
  };

  const isDisabled = React.useMemo(
    () => Boolean(!permissions[MODULELIST.CAMPAIGN].includes('update')),
    [permissions]
  );

  return (
    <>
      <Loader loading={loading} />
      <Box>
        <Typography mb='1rem'>
          Please select channels with their respective templates to be sent for
          each group
        </Typography>

        {campaignGroups?.map((group: any, index: number) => {
          return (
            <Box className={classes.groupWrapper}>
              <Typography>{index + 1}.</Typography>
              <Box sx={{ '& p': { textAlign: 'center', fontWeight: 600 } }}>
                <Typography>{group.condition}</Typography>
                <Typography>{group['#customers']} customer</Typography>
                <Typography>
                  {group.outstanding_balance &&
                    `$ ${group.outstanding_balance.toLocaleString()}`}{' '}
                  balance
                </Typography>
              </Box>

              <Box className={classes.channelBoxCont}>
                <ChannelSelectBox
                  channel='ivr'
                  index={index}
                  selectedData={selectedData}
                  channelTemplate={ivrTemplate ?? []}
                  handleChange={(channel, template) =>
                    handleChange(index, channel, group, template)
                  }
                  disabled={isDisabled}
                />
                <ChannelSelectBox
                  channel='email'
                  index={index}
                  selectedData={selectedData}
                  channelTemplate={emailTemplate ?? []}
                  handleChange={(channel, template) =>
                    handleChange(index, channel, group, template)
                  }
                  disabled={isDisabled}
                />
                <ChannelSelectBox
                  channel='sms'
                  index={index}
                  selectedData={selectedData}
                  channelTemplate={smsTemplate ?? []}
                  handleChange={(channel, template) =>
                    handleChange(index, channel, group, template)
                  }
                  disabled={isDisabled}
                />
                <ChannelSelectBox
                  channel='whatsapp'
                  index={index}
                  selectedData={selectedData}
                  channelTemplate={whatsappTemplate ?? []}
                  handleChange={(channel, template) =>
                    handleChange(index, channel, group, template)
                  }
                  disabled={isDisabled}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default SelectChannelTemplate;
