import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { FaRegEye } from 'react-icons/fa';
import { UserStatus } from './styles';
import { CustomHeadCell } from 'components/rolesPermissions/CustomHeadCell';
import PlainModal from 'components/Modal/PlainModal';
import EditUserModal from '../../modal/editModal';
import { UserType } from 'constants/types';
// import { getUserSummary } from 'store/reducer/userReducer/action';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userSummary } from 'store/reducer/userReducer';
import { getUserSummary } from 'store/reducer/userReducer/action';
import Loader from 'components/loader';
import BasicPagination from 'components/Pagination';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell width='5%'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        <CustomHeadCell label='user' align='left' />
        <CustomHeadCell label='email' align='left' />
        <CustomHeadCell label='role' align='left' />
        <CustomHeadCell label='2fa required' />
        <CustomHeadCell label='status' />
        <CustomHeadCell label='action' />
      </TableRow>
    </TableHead>
  );
}

export default function UsersTable() {
  const dispatch = useAppDispatch();

  const [rows, setRows] = React.useState<UserType[]>([]);
  // const [count, setCount] = React.useState<number>(0);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  const userData = useAppSelector(userSummary);

  React.useEffect(() => {
    if (userData) {
      // setCount(userData?.count);
      setTotalPage(Math.ceil(userData?.count / 10));
      setRows(userData?.results);
      setSelected([]);
    }
  }, [userData]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.user_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handlePageChange = async (event: unknown, newPage: number) => {
  //   setLoading(true);
  //   try {
  //     const offset = 10 * newPage;
  //     await dispatch(getUserSummary({ limit: 10, offset }));
  //     setPage(newPage);
  //   } catch (error) {}
  //   setLoading(false);
  // };

  const handlePagination = async (event: unknown, newPage: number) => {
    setLoading(true);
    try {
      const offset = 10 * (newPage - 1);
      await dispatch(getUserSummary({ limit: 10, offset }));
      setCurrentPage(newPage);
    } catch (error) {}
    setLoading(false);
  };

  // const handleChangeRowsPerPage = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setLoading(true);
  //   try {
  //     const limit = parseInt(event.target.value, 10);
  //     await dispatch(getUserSummary({ limit, offset: 0 }));
  //     setRowsPerPage(limit);
  //     setPage(0);
  //   } catch (error) {}
  //   setLoading(false);
  // };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleEdit = (edit: any) => {
    setEditData({ ...edit, status: 'active' });
    setOpenEdit(true);
  };

  const handleEditClose = () => setOpenEdit(false);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ width: '100%', paddingBottom: '1rem' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows?.map((row, index) => {
                const isItemSelected = isSelected(row?.user_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row?.user_id)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.user_id}
                    selected={isItemSelected}
                  >
                    <TableCell width='5%'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                    >
                      <Typography>
                        {row?.first_name} {row?.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row?.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography textTransform='capitalize'>
                          {row?.role_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography color='#32475CDE' fontWeight={500}>
                        {row?.two_f_a ? 'Yes' : 'No'}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <UserStatus isActive={row?.is_active}>
                        <Typography>
                          {row?.is_active === 0
                            ? 'inactive'
                            : row?.is_active === 1
                            ? 'pending'
                            : 'active'}
                        </Typography>
                      </UserStatus>
                    </TableCell>
                    <TableCell align='center'>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(row)}
                      >
                        <FaRegEye color='#32475C8A' fontSize='1.4rem' />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          // rowsPerPageOptions={[5, 10, 20]}
          rowsPerPageOptions={[10]}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <Box sx={{ display: 'flex', justifyContent: 'end', m: '1rem 1rem 0' }}>
          <BasicPagination
            count={totalPage}
            page={currentPage}
            onChange={handlePagination}
          />
        </Box>
      </Box>

      <PlainModal
        open={openEdit}
        onClose={handleEditClose}
        children={
          <EditUserModal
            onClose={handleEditClose}
            data={editData}
            setPage={() => setCurrentPage(1)}
          />
        }
      />
    </>
  );
}
