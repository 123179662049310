import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { AiOutlineDelete } from 'react-icons/ai';
import { PermissionButton } from 'containers/Permissions/styles';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { DeleteBackButton, DeleteIconBox } from './styles';
import { useAppDispatch } from 'hooks/useRedux';
import { deleteUser, getUserSummary } from 'store/reducer/userReducer/action';
import Loader from 'components/loader';

export const DeletePendingUser = ({
  setAction,
  onClose,
  userId,
  setPage,
}: {
  setAction: (x: string) => void;
  onClose: () => void;
  setPage: () => void;
  userId: string;
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await dispatch(deleteUser({ user_id: userId }));
      await dispatch(getUserSummary({ limit: 10, offset: 0 }));
      onClose();
      setPage();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />

      <Box width='650px'>
        <DeleteBackButton onClick={() => setAction('')}>
          <HiOutlineArrowSmLeft />
        </DeleteBackButton>

        <Stack direction='row' justifyContent='center' mt='4rem'>
          <DeleteIconBox>
            <AiOutlineDelete />
          </DeleteIconBox>
        </Stack>

        <Box p='2rem 3.5rem'>
          <Box
            sx={{
              '& p': {
                fontWeight: 500,
                color: '#32475C99',
                fontSize: '1.1rem',
              },
            }}
          >
            <Typography mb='1rem' align='center'>
              Are you sure you want to permanently delete this account?
            </Typography>
            <Typography align='center'>
              This will make the invite that has already been sent to the user
              invalid and they will not be able to create the account
            </Typography>
          </Box>
        </Box>

        <Stack direction='row' justifyContent='center' mt='2rem' gap='1rem'>
          <PermissionButton background='#BB7065' onClick={handleDelete}>
            <Typography>confirm & delete</Typography>
          </PermissionButton>
        </Stack>
      </Box>
    </>
  );
};
