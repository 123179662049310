import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    customSelectTitle: {
      textAlign: 'center',
      fontSize: '1rem',
      lineHeight: '26px',
      marginBottom: '12px',
    },
    label: {
      fontSize: '0.8em',
      marginTop: '3px',
    },
    radio: {
      '&$checked': {
        color: '#3552CC',
      },
    },
    checked: {},
    radioGroupContainer: {
      '& .MuiSvgIcon-root': {
        width: '0.9em',
        height: '0.9em',
      },
    },
  })
);

export default useStyles;
