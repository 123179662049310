import { AwsLinkType } from 'constants/types';
import { getAccessToken } from 'utils/auth';
import { errorToast } from 'utils/toast';

interface LambdaUserVerification {
  file: File;
  type: string;
  urlObj: AwsLinkType;
  campaign_name?: string;
  campaign_desc?: string;
  ignore_errors?: string;
}

export const lambdaUserVerification = async ({
  file,
  type,
  urlObj,
  campaign_name = 'abc',
  campaign_desc = 'abc',
  ignore_errors = 'false',
}: LambdaUserVerification) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await fetch(
      // 'https://0pis7x10o6.execute-api.ap-south-1.amazonaws.com/dev/lambda-trigger-user-verification',
      urlObj.link,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'application/json',
          token: `${getAccessToken()}`,
          // 'x-api-key': 'CR1YPnUVLY7ItM4gnAr6C7XswWBf3ULM97XdxsKF',
          'x-api-key': urlObj.api_key,
          type,
          pii_flag: 'True',
          source: '1',
          filename: file.name,
          campaign_name,
          campaign_desc,
          ignore_errors,
        },
      }
    );
    const jsonRes = await res.json();
    const nonPii = jsonRes?.['Non PII'];
    if (type === 'campaign') {
      const { status, invalid_records, link, total_records } = jsonRes;
      if (status === 200) return { success: true, data: nonPii };
      if (status === 500)
        return {
          status,
          success: false,
          data: { invalid_records, total_records, link },
        };
      if (status === 400) {
        errorToast(nonPii?.error);
        return { status, success: false };
      }
      return { success: false };
    }
    if (type === 'etl') {
      const { statusCode, invalid_records, link, total_records } = jsonRes;
      if (statusCode === 200) return { success: true, total_records };
      if (statusCode === 500)
        return {
          status: statusCode,
          success: false,
          data: { invalid_records, total_records, link },
        };
      if (statusCode === 400) {
        errorToast(nonPii);
        return { status: statusCode, success: false };
      }
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};
