import * as React from 'react';
import { Box } from '@mui/material';

import Verification from './Verification';
import UpdateNumber from './UpdateNumber';
import Loader from 'components/loader';

const TwoStepVerification = () => {
  const [action, setAction] = React.useState<string>('verify');
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <>
      <Loader loading={loading} />

      <Box width='50%'>
        {action === 'verify' && (
          <Verification setAction={setAction} setLoading={setLoading} />
        )}
        {action === 'update_number' && (
          <UpdateNumber setAction={setAction} setLoading={setLoading} />
        )}
      </Box>
    </>
  );
};

export default TwoStepVerification;
