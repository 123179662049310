import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import BasicSelect from 'components/Select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CiCalendar } from 'react-icons/ci';
import useStyles from './index.styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  customerProfileFilter,
  getCustomerProfile,
} from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import { useParams } from 'react-router-dom';
import { setCustomerProfile } from 'store/reducer/customerReducer';

const channels = [
  { label: 'All', value: '' },
  { label: 'Email', value: 'Email' },
  { label: 'SMS', value: 'SMS' },
  { label: 'Whatsapp', value: 'Whatsapp' },
  { label: 'IVR', value: 'IVR' },
];

export const DatePickerCont = styled('div')({
  display: 'inline-flex',
  padding: '0.2rem 1rem',
  alignItems: 'center',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '& .react-datepicker-wrapper input': {
    background: 'transparent !important',
    color: '#000 !important',
  },
});

const CustomerCommFilter = ({
  channel,
  date,
  setChannel,
  setDate,
  onClose,
}: {
  onClose: () => void;
  channel: string;
  date: Date | null;
  setChannel: (x: string) => void;
  setDate: (x: Date | null) => void;
}) => {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  const [loading, setLoading] = React.useState<boolean>(false);

  const profileData: any = useAppSelector(
    (state) => state.customer.customerProfile?.[0]
  );

  const handleChannelChange = async (newChannel: string) => {
    setLoading(true);
    let payload: any = { customer_id: id };
    if (date)
      payload.communication_date = moment(date).format('YYYY-MM-DD') ?? '';
    if (newChannel) payload.communication_mode = newChannel;
    if (!newChannel && !date) {
      await dispatch(getCustomerProfile({ customerId: id })).unwrap();
    } else {
      const res = await dispatch(customerProfileFilter(payload)).unwrap();
      if (res?.[0]?.communication_details) {
        const newProfileData = {
          ...profileData,
          communication_details: res?.[0]?.communication_details,
        };
        dispatch(setCustomerProfile([newProfileData]));
      }
    }
    setChannel(newChannel);
    // setDate(null);
    setLoading(false);
    onClose();
  };

  const handleDate = async (newDate: Date) => {
    setLoading(true);
    let payload: any = { customer_id: id };
    if (channel) payload.communication_mode = channel;
    if (newDate) {
      payload.communication_date = moment(newDate).format('YYYY-MM-DD');
    }
    if (!channel && !newDate) {
      await dispatch(getCustomerProfile({ customerId: id })).unwrap();
    } else {
      const res = await dispatch(customerProfileFilter(payload)).unwrap();
      if (res?.[0]?.communication_details) {
        const newProfileData = {
          ...profileData,
          communication_details: res?.[0]?.communication_details,
        };
        dispatch(setCustomerProfile([newProfileData]));
      }
    }
    // setChannel('');
    setDate(newDate);
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.filterWrapper}>
        <Typography fontSize='1.1rem' mb='1rem'>
          Filter
        </Typography>
        <Box className={classes.flexBetween}>
          <Typography>Channel</Typography>
          <BasicSelect
            value={channel}
            options={channels}
            onChange={handleChannelChange}
            width='120px'
            size='sm'
          />
        </Box>

        <Box mt='1rem' className={classes.flexBetween}>
          <Typography>Date</Typography>
          <DatePickerCont>
            <DatePicker selected={date} onChange={handleDate} />
            <CiCalendar />
          </DatePickerCont>
        </Box>
      </Box>
    </>
  );
};

export default CustomerCommFilter;
