import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { RiSendPlane2Fill } from 'react-icons/ri';

import useStyles from './index.styles';
import moment from 'moment';
import TypingAnimation from 'components/typingAnimation';
import { FiChevronLeft } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';
import { BsCameraVideo, BsMic } from 'react-icons/bs';
import { IoCallOutline } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai';

interface WhatsappTwoWayPreviewProps {
  json?: any;
  messages?: any;
}

const WhatsappTwoWayPreview: React.FC<WhatsappTwoWayPreviewProps> = ({
  json,
  messages,
}) => {
  const classes = useStyles();
  const messageBoxRef = useRef<HTMLDivElement>(null);

  const [input, setInput] = useState<string>('');
  const [chats, setChats] = useState<any>([{ message: '', response: '' }]);
  const [botTyping, setBotTyping] = useState<number>(0);
  const [responses, setResponses] = useState<any[]>([]);
  const [responseMeta, setResponseMeta] = useState<{
    type: string;
    expected: boolean;
  }>({ type: '', expected: false });

  useEffect(() => {
    const message = messages[0];
    if (message && message.responseExpected) {
      setTimeout(() => {
        const msg = createResponseOptions(message);
        setBotTyping(1);
        setChats([{ message: msg, response: '' }]);
        setResponses(message.responses);
        setResponseMeta({
          type: message.responseType,
          expected: message.responseExpected,
        });
      }, 1000);
    }
  }, [messages]);

  const handleSend = () => {
    if (responseMeta.type === 'text') {
      const index = chats.length - 1;
      let chatCopy = chats.map((chat: any, ind: number) => {
        if (index === ind) return { ...chat, response: input };
        return { ...chat };
      });

      if (responses.length === 1) {
        const msgObj = messages[+responses[0].message];
        chatCopy[index + 1] = { message: msgObj.msg, response: '' };
        let resMeta = {
          type: msgObj.responseType,
          expected: msgObj.responseExpected,
        };
        if (msgObj?.defaultResponse?.message) {
          setResponses([msgObj?.defaultResponse]);
        } else {
          setResponses([]);
          resMeta = { type: '', expected: false };
        }
        setResponseMeta(resMeta);
        setChats(chatCopy);
        setTimeout(() => {
          setBotTyping(chats.length + 1);
        }, 1000);
      } else {
        setResponses([]);
        setChats(chatCopy);
        setResponseMeta({ type: '', expected: false });
      }
      setInput('');
    } else {
      const response = responses.find((resp: any) => resp.option === input);
      if (response) {
        const index = chats.length - 1;
        const msgObj = messages[+response.message];
        if (responseMeta.expected) {
          let chatCopy = chats.map((chat: any, ind: number) => {
            if (index === ind) return { ...chat, response: input };
            return { ...chat };
          });

          if (msgObj?.responseExpected) {
            if (msgObj.responseType === 'predefined') {
              chatCopy[index + 1] = {
                message: createResponseOptions(msgObj),
                response: '',
              };
              setResponses(msgObj.responses);
            } else {
              chatCopy[index + 1] = { message: msgObj.msg, response: '' };
              if (msgObj?.defaultResponse?.message) {
                setResponses([msgObj?.defaultResponse]);
              } else {
                setResponses([]);
              }
            }
            setResponseMeta({
              type: msgObj.responseType,
              expected: msgObj.responseExpected,
            });
          } else {
            chatCopy[index + 1] = { message: msgObj.msg, response: '' };
            setResponses([]);
            setResponseMeta({ type: '', expected: false });
          }
          setInput('');
          setChats(chatCopy);
          setTimeout(() => {
            setBotTyping(chats.length + 1);
          }, 1000);
        }
      }
    }
  };

  const createResponseOptions = (message: any) => {
    return (
      <div>
        <Typography>{message.msg}</Typography>
        {message.responseExpected &&
        message.responseType === 'predefined' &&
        message.responses.length ? (
          <>
            <Typography>Please send any of the below options:</Typography>
            <ul style={{ marginLeft: '1.5rem' }}>
              {message.responses.map((res: any, index: number) => (
                <li key={index}>
                  <Typography>{res.option}</Typography>
                </li>
              ))}
            </ul>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    const messageDiv = messageBoxRef?.current as HTMLDivElement;
    messageDiv.scrollTop = messageDiv.scrollHeight;
  }, [chats, botTyping]);

  return (
    <Box className={classes.whatsappPreviewWrapper}>
      <Box className={classes.whatsappPreviewTwoWayHeaderCont}>
        <Box className={classes.whatsappPreviewTwoWayHeaderLeft}>
          <Box className={classes.whatsappPreviewTwoWayHeaderBackIcon}>
            <FiChevronLeft fontSize='35px' />
            <Typography
              className={classes.whatsappPreviewTwoWayHeaderBackIconText}
            >
              12
            </Typography>
          </Box>

          <Box className={classes.whatsappPreviewTwoWayHeaderMiddleBox}>
            <Box className={classes.whatsappPreviewTwoWayHeaderAvatarCont}>
              <img
                src='https://avatars.dicebear.com/api/bottts/rose.svg?mood[]=happy'
                alt=''
                width={25}
              />
            </Box>

            <Box>
              <Box className={classes.whatsappPreviewTwoWayHeaderUserText}>
                <Typography fontWeight={600}>KollectAI</Typography>
                <MdVerified color='#25D366' />
              </Box>
              <Typography color='#898989' fontSize='0.8rem'>
                online
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.whatsappPreviewTwoWayHeaderRight}>
          <BsCameraVideo color='#007AFF' fontSize='22px' />
          <IoCallOutline color='#007AFF' fontSize='22px' />
        </Box>
      </Box>

      <Box
        ref={messageBoxRef}
        className={classes.whatsappPreviewTwoWayMessageCont}
      >
        <Box className={classes.whatsappPreviewTwoWayMessageBox}>
          <Box display='flex' justifyContent='center'>
            <Typography className={classes.whatsappPreviewTwoWayTodayStyle}>
              Today
            </Typography>
          </Box>

          <Box maxWidth='90%' margin='0.8rem auto'>
            <Typography className={classes.whatsappPreviewTwoWayLearnMoreStyle}>
              This chat is with the official business account of “KollectAI”.
              Tap to learn more.
            </Typography>
          </Box>

          {chats.map((chat: any, index: number) => {
            return (
              <>
                <Box className={classes.whatsappPreviewTwoWayChatMessageCont}>
                  {botTyping === index ? (
                    <TypingAnimation />
                  ) : (
                    <Box
                      className={classes.whatsappPreviewTwoWayChatMessageBox}
                    >
                      <Typography>{chat.message}</Typography>
                      <Typography
                        fontSize='0.7rem'
                        className={classes.whatsappPreviewTwoWayChatMessageTime}
                      >
                        {moment().format('h:mm')}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box className={classes.whatsappPreviewTwoWayChatResponseCont}>
                  {chat.response && (
                    <Box
                      className={classes.whatsappPreviewTwoWayChatMessageBox}
                    >
                      <Typography>{chat.response}</Typography>
                      <Typography
                        fontSize='0.7rem'
                        className={classes.whatsappPreviewTwoWayChatMessageTime}
                      >
                        {moment().format('h:mm')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          background: '#F6F6F6',
          paddingBottom: '5px',
          borderRadius: '0 0 10px 10px',
        }}
      >
        <Box className={classes.whatsappPreviewTwoWayInputCont}>
          <AiOutlinePlus fontSize='32px' />
          <input
            type='text'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder='Enter Message....'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <button type='button' onClick={handleSend}>
            <RiSendPlane2Fill fontSize='22px' />
          </button>
          <BsMic fontSize='32px' />
        </Box>
        <Box className={classes.whatsappPreviewTwoWayBottomLine}></Box>
      </Box>
    </Box>
  );
};

export default WhatsappTwoWayPreview;
