import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useRedux';
import { Typography } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { dashboardPortfolio } from 'store/reducer/dashboardReducer/action';
import OverviewBox from '../overviewBox';
import './styles.css';
import Loader from 'components/loader';

interface DataType {
  labels: string[];
  value: string[];
}

const PortfolioOverview = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [portfolioData, setPortfolioData] = useState<any>({
    total_customer: 0,
    total_outstanding_amount: 0,
    total_paid_amount: 0,
  });
  const [bucket, setBucket] = useState<DataType>({
    labels: [],
    value: [],
  });
  const [balance, setBalance] = useState<DataType>({
    labels: [],
    value: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const { payload } = await dispatch(dashboardPortfolio({ client_key: 1 }));
      setPortfolioData(payload.data);
      setBucket({
        labels: Object.keys(payload.data.bucket),
        value: Object.values(payload.data.bucket),
      });
      setBalance({
        labels: Object.keys(payload.data.AMOUNT),
        value: Object.values(payload.data.AMOUNT),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const balanceData = {
    datasets: [
      {
        label: 'Balance',
        data: balance?.value,
        backgroundColor: ['#8c34ea', '#d325cc', 'rgba(44, 98, 236, 0.7)'],
        borderWidth: 1,
      },
    ],
  };
  const bucketData = {
    datasets: [
      {
        label: 'Bucket',
        data: bucket?.value,
        backgroundColor: ['#bb7065', '#8a0343', '#d44638', '#b23327'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: function (context: any) {
          return context.dataset.data[context.dataIndex] !== 0;
        },
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: any) => (sum += data));
          let percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
          // return `${value}%`;
        },
        color: '#fff',
        font: {
          size: 9,
        },
      },
    },
  };

  return (
    <div className='portfolioOverviewContainer'>
      <Loader loading={loading} />
      <Typography variant='body1' style={{ marginBottom: '1em' }}>
        Portfolio Overview
      </Typography>
      <div className='overviewWrapper'>
        <OverviewBox
          overviewText='Total No. of Customers'
          overviewNumber={portfolioData?.total_customer}
        />
        <OverviewBox
          iconName='dollarLight'
          iconColor='#D44638'
          overviewText='Outstanding Balance '
          overviewNumber={portfolioData?.total_outstanding_amount}
        />
        <div className='pieChartWrapper'>
          <div className='labelsWrapper'>
            {bucket.labels.map((item: any, index) => {
              const val = item.replace(/\d+/g, '');
              const label = `${val} ${item.match(/\d+/)[0]}`;
              return (
                <div
                  key={index}
                  className={`pie-label bucket-${index + 1} font-medium`}
                >
                  {label}
                </div>
              );
            })}
          </div>
          <div className='pieChartContainer'>
            <Pie data={bucketData} options={options} />
          </div>
        </div>
        <div className='pieChartWrapper'>
          <div className='labelsWrapper'>
            {balance.labels.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`pie-label balance-${index + 1} font-medium`}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className='pieChartContainer'>
            <Pie data={balanceData} options={options} />
          </div>
        </div>
        <OverviewBox
          iconName='dollarLight'
          iconColor='#8A0343'
          overviewText='Paid'
          overviewNumber={portfolioData?.total_paid_amount}
        />
      </div>
    </div>
  );
};

export default PortfolioOverview;
