import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateApiKey } from './action';

const initialState = {
  selectedIntegrationOption: '',
  integrationApiKey: '',
};

export const IntegrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    setSelectedIntegrationOption(state, action: PayloadAction<string>) {
      state.selectedIntegrationOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateApiKey.fulfilled, (state, { payload }) => {
      state.integrationApiKey = payload?.api_key;
    });
  },
});

export const { setSelectedIntegrationOption } = IntegrationSlice.actions;

export default IntegrationSlice.reducer;
