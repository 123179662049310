import { Typography } from "@material-ui/core";
import { useAppSelector } from "hooks/useRedux";
import { useParams, useSearchParams } from "react-router-dom";
import ChannelBoxContainer from "./channelBoxContainer";
import useStyles from "./index.styles";

const ChannelSummaryHeader = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("group_id");

  const strategyDetailsData: any = useAppSelector(
    (state) => state.strategy.strategyDetailsFilter
  );

  const strategyList = useAppSelector(
    (state: any) => state.strategy.strategyList
  );

  const selectedStrategyGroup = strategyList?.find(
    (strategyGroup: any) => strategyGroup.strategy_group_id === groupId
  );

  const strategySummaryData =
    strategyDetailsData?.strategy_summary?.[0] ||
    strategyDetailsData?.strategy_summary;

  return (
    <div className={classes.channelSummaryHeaderWrapper}>
      <div>
        <Typography variant="subtitle1">
          Channel Summary{" "}
          {selectedStrategyGroup?.based_on === "dpd"
            ? "(As per dpd)"
            : `(As per ${selectedStrategyGroup?.based_on})`}
        </Typography>
      </div>
      <div className={classes.channelBoxContainerWrapper}>
        <ChannelBoxContainer
          channelName="IVR"
          channelValue={strategySummaryData?.ivr}
          channelValueColor="#0085FF"
        />
        <ChannelBoxContainer
          channelName="Email"
          channelValue={strategySummaryData?.email}
          channelValueColor="#EE4037"
        />
        <ChannelBoxContainer
          channelName="SMS"
          channelValue={strategySummaryData?.sms}
          channelValueColor="#00C087"
        />
        <ChannelBoxContainer
          channelName="Whatsapp"
          channelValue={strategySummaryData?.whatsapp}
          channelValueColor="#FFB600"
        />
      </div>
    </div>
  );
};

export default ChannelSummaryHeader;
