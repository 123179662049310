import * as React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TuneIcon from '@mui/icons-material/Tune';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useStyles from './index.styles';

import CustomIcon from 'components/customIcons';
import emailImg from 'assets/comm_email.svg';
import smsImg from 'assets/comm_sms.svg';
import whatsappImg from 'assets/comm_whatsapp.svg';
import ivrImg from 'assets/comm_ivr.svg';
import CustomerCommFilter from './filter';
import { useAppDispatch } from 'hooks/useRedux';
import { getCustomerProfile } from 'store/reducer/customerReducer/action';

export default function CommunicationDetails({
  communicationDetails,
}: {
  communicationDetails: any;
}) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [channel, setChannel] = React.useState<string>('');
  const [date, setDate] = React.useState<Date | null>(null);

  const getCustomerDetails = React.useCallback(async () => {
    try {
      await dispatch(getCustomerProfile({ customerId: id })).unwrap();
    } catch (error) {}
  }, [dispatch, id]);

  React.useEffect(() => {
    getCustomerDetails();
    setShowFilter(false);
    setChannel('');
    setDate(null);
  }, [getCustomerDetails]);

  return (
    <Box sx={{ '& p': { fontFamily: 'Overpass' } }}>
      <Box className={classes.commDetailsWrapper}>
        <Typography>Most recent at the top</Typography>
        <Box sx={{ position: 'relative' }}>
          <button onClick={() => setShowFilter((prev) => !prev)}>
            <TuneIcon />
          </button>
          {showFilter ? (
            <CustomerCommFilter
              onClose={() => setShowFilter(false)}
              channel={channel}
              setChannel={setChannel}
              date={date}
              setDate={setDate}
            />
          ) : null}
        </Box>
      </Box>

      <TableContainer sx={{ maxHeight: 500 }}>
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
              padding: '10px 0',
            },
          }}
          aria-label='caption table'
        >
          <TableBody>
            {communicationDetails?.map((item: any, index: number) => {
              const {
                communication_medium,
                sent_date,
                template_name,
                recieved,
                msg,
              } = item;
              const status =
                ['Email', 'IVR'].includes(communication_medium) ||
                (!['Email', 'IVR'].includes(communication_medium) && !recieved)
                  ? 'sent'
                  : 'received';
              const imgSrc =
                communication_medium === 'Email'
                  ? emailImg
                  : communication_medium === 'IVR'
                  ? ivrImg
                  : communication_medium === 'SMS'
                  ? smsImg
                  : whatsappImg;

              return (
                <TableRow key={index}>
                  <TableCell align='left' width='26%'>
                    <Typography color='#6F6F6F'>
                      {moment(sent_date).format('DD/MM/YYYY hh:mm A')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align='left'
                    width={40}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ zIndex: 10, position: 'relative' }}>
                      {status === 'sent' ? (
                        <CustomIcon name='sent' color='#004357' size={22} />
                      ) : (
                        <CustomIcon name='received' color='#6AC5AB' size={22} />
                      )}
                    </Box>
                    {communicationDetails.length > 1 && (
                      <Box
                        className={classes.connectingLineStyle}
                        sx={{
                          '&:before': {
                            top:
                              index === 0
                                ? '50%'
                                : index === communicationDetails.length - 1
                                ? '-50%'
                                : 0,
                          },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align='left'>
                    <Box display='flex' alignItems='center' gap='1rem'>
                      <Box display='flex' alignItems='center' gap='10px'>
                        <img src={imgSrc} alt='' />
                      </Box>
                      <Typography>
                        {status === 'sent' ? 'Sent ' : `Recieved `}
                        {['SMS', 'Whatsapp'].includes(communication_medium) &&
                          msg &&
                          `${msg} for`}{' '}
                        {template_name || communication_medium}{' '}
                        {status === 'sent' ? 'to' : 'from'} customer
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Box className={classes.showMoreStyle}>
        <button>
          <Typography>Show more</Typography>
        </button>
      </Box> */}
    </Box>
  );
}
