import { styled, Box, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';

interface CustomerOptionBoxProps {
  color: string;
  icon: string;
  label: string;
  value: string;
  svg?: string;
  svgWidth?: number;
}

const CustomerOptionBoxWrapper = styled('div')(() => ({
  background: '#fff',
  color: '#000',
  border: '1px solid #E6E6E6',
  display: 'inline-flex',
  alignItems: 'center',
  width: '200px',
  height: '60px',
  gap: '0.8em',
  justifyContent: 'center',
  padding: '0 10px',
  boxShadow:
    'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
  borderRadius: '10px',
  cursor: 'pointer',
}));

const CustomerOptionBox: React.FC<CustomerOptionBoxProps> = ({
  color,
  icon,
  label,
  value,
  svg,
  svgWidth,
}) => {
  return (
    <CustomerOptionBoxWrapper>
      <Box mt={label === 'Call_Required' && !svg ? '5px' : '3px'}>
        {svg ? (
          <img src={svg} alt='' width={svgWidth ?? 45} />
        ) : (
          <CustomIcon name={icon} size={22} color={color} />
        )}
      </Box>

      <Typography width='100px' lineHeight={1}>
        {label}
      </Typography>

      <Typography color={color} fontSize='1.2rem'>
        {value}
      </Typography>
    </CustomerOptionBoxWrapper>
  );
};

export default CustomerOptionBox;
