import { makeStyles, Theme } from '@material-ui/core';

interface ChannelDisplayBoxProps {
  channelName: string;
  channelColor: string;
  channelBackgroundColor: string;
}

export interface StyleProps extends ChannelDisplayBoxProps {}

const useStyles = makeStyles<Theme, StyleProps>({
  channelDisplayBoxContainer: {
    color: (props) => props.channelColor,
    background: (props) => props.channelBackgroundColor,
    display: 'inline-block',
    padding: '3px 10px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '0.6rem',
    borderRadius: '4px',
    marginLeft: '5px',
    marginBottom: '2px',
  },
});

const ChannelDisplayBox = (props: ChannelDisplayBoxProps) => {
  const { channelName } = props;
  const { channelDisplayBoxContainer } = useStyles(props);
  return <div className={channelDisplayBoxContainer}>{channelName}</div>;
};

export default ChannelDisplayBox;
