import { Pagination } from '@mui/material';

const BasicPagination = ({
  count,
  page,
  onChange,
}: {
  count: number;
  page: number;
  onChange: (event: unknown, page: number) => void;
}) => {
  return (
    <Pagination
      count={count}
      variant='outlined'
      shape='rounded'
      page={page}
      onChange={onChange}
      sx={{
        '.Mui-selected': {
          background: '#004357 !important',
          color: '#fff',
        },
      }}
    />
  );
};

export default BasicPagination;
