import * as React from 'react';
import { Box } from '@mui/material';
import useStyles from './index.styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  ABTestingSplit,
  AddCustomFilter,
  GroupAsOne,
  SplitIntoSegments,
} from './filters';
import SelectSegment from './SelectSegment';
import FilterBox from './FilterBox';
import { setCampaignPayload } from 'store/reducer/campaignReducer';
import { updateBasedOn } from 'store/reducer/campaignReducer/action';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const AddFilter = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );
  const customFilter = useAppSelector(
    (state: any) => state.campaign.customFilter
  );
  const permissions = useAppSelector(userPermissions);

  const [filter, setFilter] = React.useState<string>('group');
  const [selectedSegment, setSelectedSegment] =
    React.useState<string>('dpd_bucket');

  React.useEffect(() => {
    if (campaignPayload) {
      console.log({ campaignPayload });
      const seg = campaignPayload?.add_filter?.value
        ? Object.keys(campaignPayload?.add_filter?.value)[0]
        : 'dpd_bucket';
      setFilter(campaignPayload?.add_filter?.filter || 'group');
      setSelectedSegment(seg || 'dpd_bucket');
    }
  }, [campaignPayload]);

  const handleFilterChange = (filter: string) => {
    if (permissions[MODULELIST.CAMPAIGN].includes('update')) {
      setFilter(filter);
      setSelectedSegment('dpd_bucket');
      let value: any = {};
      let basedOn = '';
      switch (filter) {
        case 'group':
          basedOn = 'all';
          value = campaignData?.group_as_one;
          break;
        case 'split':
          basedOn = 'product_type';
          // const data = campaignData?.default_data;
          // let segData = {};
          // Object.keys(data).forEach((key) => {
          //   if (key === selectedSegment) segData = { [key]: data[key] };
          // });
          value = campaignData?.default_data || [];
          break;
        case 'add_custom':
          if (customFilter) basedOn = 'custom_filter';
          value = [];
          break;
        case 'ab_testing':
          basedOn = 'A_B';
          value = campaignData?.default_ab_data;
          break;
      }
      basedOn &&
        dispatch(
          updateBasedOn({
            campaign_id: campaignData?.campaign_id,
            based_on_group_type: basedOn,
          })
        );
      dispatch(
        setCampaignPayload({ key: 'add_filter', data: { filter, value } })
      );
      dispatch(
        setCampaignPayload({ key: 'select_channel_template', data: [] })
      );
    }
    // dispatch(setCustomFilter(null));
  };

  return (
    <Box sx={{ '& p': { fontFamily: 'Montserrat' } }}>
      <Box className={classes.filterOptionsWrapper}>
        <Box className={classes.filterOptionsCont}>
          <FilterBox
            label='Group As One'
            selected={filter === 'group'}
            handleFilterChange={() => handleFilterChange('group')}
          />
          <FilterBox
            label='Split into Segments'
            selected={filter === 'split'}
            handleFilterChange={() => handleFilterChange('split')}
          />
          <FilterBox
            label='Add Custom Filter'
            selected={filter === 'add_custom'}
            handleFilterChange={() => handleFilterChange('add_custom')}
          />
          <FilterBox
            label='AB Testing Split'
            selected={filter === 'ab_testing'}
            handleFilterChange={() => handleFilterChange('ab_testing')}
          />
        </Box>

        {permissions[MODULELIST.CAMPAIGN].includes('update') &&
          filter === 'split' && (
            <SelectSegment
              segments={campaignData?.default_data}
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
            />
          )}
      </Box>

      <Box>
        {filter === 'group' && <GroupAsOne data={campaignData?.group_as_one} />}
        {filter === 'split' && (
          <SplitIntoSegments
            segments={campaignData?.default_data}
            selectedSegment={selectedSegment}
          />
        )}
        {filter === 'add_custom' && <AddCustomFilter />}
        {filter === 'ab_testing' && (
          <ABTestingSplit campaignData={campaignData} />
        )}
      </Box>
    </Box>
  );
};

export default AddFilter;
