import * as React from 'react';
import { Box } from '@mui/material';
import { UserType } from 'constants/types';

import { roleList } from 'store/reducer/userReducer';
import { ActiveUserModal } from './activeUser';
import { InactiveUserModal } from './inactiveUser';
import { PendingUserModal } from './pendingUser';
import { useAppSelector } from 'hooks/useRedux';

const EditUserModal = ({
  data,
  onClose,
  setPage,
}: {
  data: UserType;
  onClose: () => void;
  setPage: () => void;
}) => {
  const isActive = data?.is_active;

  const [roleOptions, setRoleOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const roles = useAppSelector(roleList);

  React.useEffect(() => {
    if (roles) {
      const names = roles?.role_id_name;
      const ids = roles?.role_id_list;
      const options = ids.map((id, index) => ({
        label: names[index],
        value: id,
      }));
      setRoleOptions(options);
    }
  }, [roles]);

  return (
    <Box>
      {isActive === 2 && (
        <ActiveUserModal
          data={data}
          onClose={onClose}
          setPage={setPage}
          roleOptions={roleOptions}
        />
      )}
      {isActive === 0 && (
        <InactiveUserModal
          data={data}
          onClose={onClose}
          setPage={setPage}
          roleOptions={roleOptions}
        />
      )}
      {isActive === 1 && (
        <PendingUserModal
          data={data}
          onClose={onClose}
          setPage={setPage}
          roleOptions={roleOptions}
        />
      )}
    </Box>
  );
};

export default EditUserModal;
