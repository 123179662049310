import * as React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import FormikCampaignInput from 'components/formik/campaign/FormikCampaignInput';
import CampaignOption from './CampaignOption';
import useStyles, { ActionButton } from './index.styles';
import CampaignSummary from './Summary';
import DeleteCampaign from 'components/campaigns/modals/DeleteCampaign';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  addChannelTemplate,
  customerResegmentation,
  getCampaignDetails,
  getCampaignFilterAttributes,
  getCustomFilter,
  updateChannelTemplate,
} from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import {
  getBasedOn,
  getCustomSelectedChannelPayload,
  getRequestGroups,
  getScheduleTime,
} from 'utils/campaign/campaignModifier';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import { MODULELIST } from 'constants/types';
import { userPermissions } from 'store/reducer/auth';
import { setCampaignPayload } from 'store/reducer/campaignReducer';

const CampaignCreateScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id: campaign_id } = useParams();
  const dispatch = useAppDispatch();

  const campaignData = useAppSelector((state: any) => state.campaign.campaign);
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );
  const campaignAction = useAppSelector(
    (state: any) => state.campaign.campaignActionType
  );

  const [openCollapse, setOpenCollapse] = React.useState<string[]>(['1']);
  const [openSummary, setOpenSummary] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    campaign_id && fetchCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchCampaignData = async () => {
    setLoading(true);
    try {
      await dispatch(customerResegmentation({ campaign_id: campaign_id! }));
      const detailsRes = await dispatch(
        getCampaignDetails({ campaign_id })
      ).unwrap();
      const customerRes = await dispatch(
        getCustomFilter({ campaign_id })
      ).unwrap();
      if (detailsRes?.based_on === 'custom_filter') {
        const channels = getCustomSelectedChannelPayload(
          customerRes?.data || []
        );
        dispatch(
          setCampaignPayload({ key: 'select_channel_template', data: channels })
        );
      }
      await dispatch(getCampaignFilterAttributes());
      await dispatch(viewChannelTemplate({ channel_type: 'all' }));
    } finally {
      setLoading(false);
    }
  };

  const handleCollapse = (index: string) => {
    if (openCollapse.includes(index)) {
      const openCopy = openCollapse.filter((item) => item !== index);
      setOpenCollapse(openCopy);
    } else {
      const openCopy = [...openCollapse];
      openCopy.push(index);
      setOpenCollapse(openCopy);
    }
  };

  const handleSubmit = () => setOpenSummary(true);

  const handleDraft = async () => {
    setLoading(true);
    try {
      // const filter = campaignPayload?.add_filter?.filter;
      const campaign_id = campaignData?.campaign_id;
      const groups = getRequestGroups(campaignPayload?.select_channel_template);
      const based_on = getBasedOn(campaignPayload);
      const schedule_time =
        campaignPayload?.schedule_time !== 'now'
          ? getScheduleTime(campaignPayload?.schedule_time)
          : 'now';
      let payload = {
        campaign_id,
        based_on,
        groups,
        // schedule_time: campaignPayload?.schedule_time,
        schedule_time,
        save_as: 'draft',
      };
      if (campaignAction === 'edit' && campaignData?.channel_count > 0) {
        await dispatch(updateChannelTemplate(payload));
      } else {
        await dispatch(addChannelTemplate(payload));
      }
      // if (filter === 'ab_testing') {
      //   let dist: any = { A: '', B: '' };
      //   const value = campaignPayload?.add_filter?.value;
      //   for (let key in value) {
      //     let data = value[key];
      //     dist[key] = data?.distribution;
      //   }
      //   await dispatch(
      //     updateAbTesting({ campaign_id, a_dist: dist.A, b_dist: dist.B })
      //   );
      // }
      navigate('/campaigns');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ margin: '2rem 2rem 0', '& p': { fontFamily: 'Overpass' } }}>
        <Box className={classes.campaignHeader}>
          <button onClick={() => navigate('/campaigns')}>
            <Typography fontWeight={600}>Back to List</Typography>
          </button>
          <Typography fontSize='1.5rem'>Campaign Manager</Typography>
          <Typography>Initiated on: 23/11/20233 at 4:30 PM IST</Typography>
        </Box>

        <Box sx={{ minHeight: 'calc(100vh - 310px)' }}>
          <Box my='1rem'>
            <Formik
              enableReinitialize
              initialValues={{
                name: campaignData?.campaign_name || '',
                description: campaignData?.campaign_desc || '',
              }}
              onSubmit={() => {}}
            >
              <Form>
                <Box className={classes.campaignFormCont}>
                  <FormikCampaignInput
                    label='Campaign Name'
                    name='name'
                    placeholder='{Campaign Name}'
                    disabled={true}
                  />

                  <FormikCampaignInput
                    label='Description'
                    subLabel='(if any)'
                    name='description'
                    placeholder='{Description}'
                    width='100%'
                    disabled={true}
                  />
                </Box>
              </Form>
            </Formik>
          </Box>

          <CampaignOption
            option='add_filter'
            openCollapse={openCollapse}
            handleCollapse={handleCollapse}
          />
          <CampaignOption
            option='select_channel'
            openCollapse={openCollapse}
            handleCollapse={handleCollapse}
          />
          <CampaignOption
            option='schedule'
            openCollapse={openCollapse}
            handleCollapse={handleCollapse}
          />
        </Box>

        <Box className={classes.actionCont}>
          {permissions[MODULELIST.CAMPAIGN].includes('delete') ? (
            <ActionButton
              background='#DAA09A'
              onClick={() => setOpenDelete(true)}
            >
              <Typography>DELETE</Typography>
            </ActionButton>
          ) : (
            <div></div>
          )}
          {permissions[MODULELIST.CAMPAIGN].includes('update') && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <ActionButton
                background='#115180'
                radius='20px'
                onClick={handleDraft}
              >
                <Typography>SAVE AS DRAFT</Typography>
              </ActionButton>
              {permissions[MODULELIST.CAMPAIGN].includes('execute') && (
                <ActionButton background='#6AC5AB' onClick={handleSubmit}>
                  <Typography>Submit</Typography>
                </ActionButton>
              )}
            </Box>
          )}
        </Box>

        <Modal
          open={openSummary}
          onClose={() => setOpenSummary(false)}
          disableEnforceFocus={false}
          className={classes.modalStyle}
          children={<CampaignSummary onClose={() => setOpenSummary(false)} />}
        />

        <DeleteCampaign
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          campaigns={campaign_id ? [campaign_id] : []}
          setLoading={setLoading}
        />
      </Box>
    </>
  );
};

export default CampaignCreateScreen;
