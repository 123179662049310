import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

import emailImg from 'assets/comm_email.svg';
import ivrImg from 'assets/comm_ivr.svg';
import smsImg from 'assets/comm_sms.svg';
import whatsappImg from 'assets/comm_whatsapp.svg';
import CustomIcon from 'components/customIcons';
import { getCampaignGroups } from 'utils/campaign/campaignModifier';

const CampaignSummaryDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [populationDetails, setPopulationDetails] = React.useState<any>([]);
  const [groupDetails, setGroupDetails] = React.useState<any>([]);
  const [totals, setTotals] = React.useState({ customers: 0, outstanding: 0 });

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );

  React.useEffect(() => {
    if (campaignPayload) {
      const groups = getCampaignGroups(campaignPayload?.add_filter);
      const channelTemplate = campaignPayload?.select_channel_template;
      const newGroups = groups.map((group: any) => {
        let newGroup = { ...group };
        channelTemplate.forEach((selected: any) => {
          if (group.group_id === selected?.group_id) {
            newGroup['channels'] = selected;
          }
        });
        return newGroup;
      });

      const total = newGroups.reduce(
        (acc: any, obj: any) => ({
          customers: acc.customers + obj['#customers'],
          outstanding: acc.outstanding + obj.outstanding_balance,
        }),
        { customers: 0, outstanding: 0 }
      );
      setTotals(total);
      setGroupDetails(newGroups);
      setPopulationDetails([campaignData?.group_as_one]);
    }
  }, [campaignData, campaignPayload]);

  return (
    <Box>
      <Box className={classes.detailsBlockCont}>
        <Typography color='#6F6F6F' fontWeight={500} fontSize='1.1rem'>
          Name
        </Typography>
        <Typography fontWeight={500} fontSize='1.1rem'>
          {campaignData?.campaign_name}
        </Typography>
      </Box>

      <Box>
        <Box className={classes.detailsBlockCont}>
          <Typography color='#6F6F6F' fontWeight={500} fontSize='1.1rem'>
            Population Details
          </Typography>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/campaigns/customer-data')}
          >
            <CustomIcon name='share' color='#49F0AC' size={26} />
          </Box>
        </Box>

        <Box mb='1rem'>
          <table className={classes.tableStyle}>
            <tr>
              <th>
                <Typography># Customers</Typography>
              </th>
              <th>
                <Typography>$ Outstanding</Typography>
              </th>
              <th>
                <Typography>Average DPD</Typography>
              </th>
            </tr>
            {populationDetails?.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Typography>{row?.['#customers']}</Typography>
                  </td>
                  <td>
                    <Typography>
                      {row?.outstanding_balance &&
                        `$ ${row.outstanding_balance.toLocaleString()}`}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{row?.average_dpd || row?.avg_dpd}</Typography>
                  </td>
                </tr>
              );
            })}
          </table>
        </Box>

        <Box>
          <Typography color='#6F6F6F' fontWeight={500} fontSize='1.1rem'>
            Campaign Group Details
          </Typography>

          <Box mb='1rem'>
            <table className={classes.tableStyle}>
              <tr>
                <th></th>
                <th>
                  <Typography>Group</Typography>
                </th>
                <th>
                  <Typography>Customers</Typography>
                </th>
                <th>
                  <Typography>Balance</Typography>
                </th>
                <th>
                  <Typography>Channels</Typography>
                </th>
                <th></th>
              </tr>
              {groupDetails.map((row: any, index: number) => {
                return (
                  <tr style={{ borderTop: '0.5px solid #969696' }} key={index}>
                    <td>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <td>
                      <Typography>{row.condition}</Typography>
                      {/* <Typography>{row['#customers']} Customers</Typography>
                        <Typography>{row.outstanding} Balance</Typography> */}
                    </td>
                    <td>
                      <Typography>{row['#customers']}</Typography>
                    </td>
                    <td>
                      <Typography>
                        {row?.outstanding_balance &&
                          `$ ${row?.outstanding_balance.toLocaleString()}`}
                      </Typography>
                    </td>
                    <td>
                      {row?.channels && (
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '25px 25px',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {row.channels['email'] && (
                            <img src={emailImg} alt='' />
                          )}
                          {row.channels['sms'] && <img src={smsImg} alt='' />}
                          {row.channels['whatsapp'] && (
                            <img src={whatsappImg} alt='' />
                          )}
                          {row.channels['ivr'] && <img src={ivrImg} alt='' />}
                        </Box>
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => navigate('/campaigns/customer-data')}
                      >
                        <CustomIcon name='share' color='#49F0AC' size={26} />
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr style={{ borderTop: '2px solid #00C087' }}>
                <td></td>
                <td></td>
                <td>
                  <Typography>{totals.customers}</Typography>
                </td>
                <td>
                  <Typography>
                    {totals.outstanding &&
                      `$ ${totals.outstanding.toLocaleString()}`}
                  </Typography>
                </td>
                <td></td>
              </tr>
            </table>
          </Box>
        </Box>
      </Box>

      <Box className={classes.detailsBlockCont}>
        <Typography color='#6F6F6F' fontWeight={500} fontSize='1.1rem'>
          Campaign Schedule
        </Typography>
        <Typography fontWeight={500} fontSize='1.1rem'>
          {campaignPayload?.schedule_time === 'now'
            ? 'NOW'
            : `${campaignPayload?.schedule_time?.date || '-'} ${
                campaignPayload?.schedule_time?.time || ''
              }`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignSummaryDetails;
