import { createSlice } from '@reduxjs/toolkit';
import {
  FileDetailsObjectType,
  AccountReceivedDetailsObjectType,
  TemplateListObjectType,
  SummaryListItemObjectType,
} from '../../../constants/types';
import {
  createCampaign,
  downloadTemplates,
  createCampaignGroup,
  createCommunicationCampaign,
  campaignGroupFilter,
  campaignAddGroupFilter,
  viewTemplate,
  createConfiguration,
  createScheduler,
  campaignGroupSummary,
  triggerNow,
  campaignGroupGetFilter,
} from './actions';

export interface UploadedFileDetailTypes {
  fileDetailsObject: FileDetailsObjectType | null;
  accountReceivedDetailsObject: AccountReceivedDetailsObjectType | null;
  downloadSampleUrl: string;
  campaignGroupId: string;
  groupFilterList: any;
  templateList: TemplateListObjectType | null;
  cgrId: string;
  csId: string;
  campaignGroupGetFilter: any;
  summaryList: Array<SummaryListItemObjectType> | null;
  gccId: string;
}

const initialState: UploadedFileDetailTypes = {
  fileDetailsObject: null,
  accountReceivedDetailsObject: null,
  downloadSampleUrl: '',
  campaignGroupId: '',
  groupFilterList: null,
  templateList: null,
  cgrId: '',
  csId: '',
  campaignGroupGetFilter: null,
  summaryList: null,
  gccId: '',
};

export const UploadedFileDetailSlice = createSlice({
  name: 'uploadedFileDetail',
  initialState,
  reducers: {
    setUploadedFileDetailReducerToInitial: (state) => {
      state.fileDetailsObject = null;
      state.accountReceivedDetailsObject = null;
      state.downloadSampleUrl = '';
      state.campaignGroupId = '';
      state.groupFilterList = null;
      state.templateList = null;
      state.cgrId = '';
      state.csId = '';
      state.summaryList = null;
      state.gccId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCampaign.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true) {
        state.fileDetailsObject = {
          fileName: data.file_details.file_name,
          fileFormat: data.file_details.file_format,
          fileSize: data.file_details.file_size,
          uploadedBy: data.file_details.uploaded_by,
        };
        state.accountReceivedDetailsObject = {
          uniqueCustomers: data.account_recv_details.unique_customers,
          totalNumberOfCustomers:
            data.account_recv_details.total_no_of_customers,
          totalOutstandingInvoices:
            data.account_recv_details.total_outstanding_invoices,
          totalOutstandingAmount:
            data.account_recv_details.total_outstanding_amt,
          totalUpcomingInvoices:
            data.account_recv_details.total_upcoming_invoices,
          totalUpcomingInvoiceAmt:
            data.account_recv_details.total_upcoming_invoice_amt,
          totalNumberOfValidRecords:
            data.account_recv_details.total_no_of_valid_records,
          totalNumberOfInvalidRecords:
            data.account_recv_details.total_no_of_invalid_records,
          campaignId: data.account_recv_details.campaign_id,
          campaignName: data.account_recv_details.campaign_name,
          cUploadId: data.account_recv_details.c_upload_id,
          dpd_0_to_10_days: data.account_recv_details.dpd_0_to_10_days,
          dpd_11_to_30_days: data.account_recv_details.dpd_11_to_30_days,
          dpd_31_to_60_days: data.account_recv_details.dpd_31_to_60_days,
          dpd_61_to_90_days: data.account_recv_details.dpd_61_to_90_days,
          dpd_91_to_120_days: data.account_recv_details.dpd_91_to_120_days,
          dpd_more_than_121_days:
            data.account_recv_details.dpd_more_than_121_days,
        };
      }
    });
    builder.addCase(downloadTemplates.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true) {
        state.downloadSampleUrl = data.url;
      }
    });
    builder.addCase(createCampaignGroup.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.status === 'success') {
        state.campaignGroupId = data.groupId;
      }
    });
    builder.addCase(
      createCommunicationCampaign.fulfilled,
      (state, { payload }) => {
        const { meta, data } = payload as any;
        if (meta?.status === 'success') {
          state.campaignGroupId = data.groupId;
        }
      }
    );
    builder.addCase(campaignGroupFilter.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true && meta?.message === 'success') {
        state.groupFilterList = data;
      }
    });
    builder.addCase(campaignAddGroupFilter.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.status === 'success') {
        state.cgrId = data.cgr_id;
      }
    });
    builder.addCase(viewTemplate.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true && meta?.message === 'success') {
        state.templateList = data;
      }
    });

    builder.addCase(createConfiguration.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.status === 'success') {
        state.gccId = data.gcc_id;
      }
    });
    builder.addCase(createScheduler.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true && meta?.message === 'success') {
        state.csId = data.cs_id;
      }
    });
    builder.addCase(campaignGroupGetFilter.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (meta?.status === 'success') {
        state.campaignGroupGetFilter = data;
      }
    });
    builder.addCase(campaignGroupSummary.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.success === true) {
        state.summaryList = data;
      }
    });
    builder.addCase(triggerNow.fulfilled, (state, { payload }) => {
      const { meta } = payload as any;
      if (meta?.success === true) {
        // state.csId = data.cs_id
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { setUploadedFileDetailReducerToInitial } =
  UploadedFileDetailSlice.actions;

export {
  createCampaign,
  downloadTemplates,
  createCampaignGroup,
  createCommunicationCampaign,
  campaignGroupFilter,
  campaignAddGroupFilter,
  viewTemplate,
  createConfiguration,
  createScheduler,
  campaignGroupSummary,
  triggerNow,
};

export const groupFilterList = (state: any) =>
  state.uploadedFileDetail.groupFilterList;
export const groupGetFilterList = (state: any) =>
  state.uploadedFileDetail.campaignGroupGetFilter;

export default UploadedFileDetailSlice.reducer;
