import { Box, Pagination } from '@mui/material';
import SearchCampaign from './search';
import useStyles from './style';

type FilterProps = {
  totalPage: number;
  currentPage: number;
  handleSearch: (x: string, y: any) => void;
  handlePagination: (x: React.ChangeEvent<unknown>, y: number) => void;
};

const CampaignFilter = ({
  totalPage,
  currentPage,
  handlePagination,
  handleSearch,
}: FilterProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.filterContainer}>
      <Pagination
        count={totalPage}
        variant='outlined'
        shape='rounded'
        page={currentPage}
        onChange={handlePagination}
        sx={{
          '.Mui-selected': {
            background: '#004357 !important',
            color: '#fff',
          },
        }}
      />

      <SearchCampaign onSearch={handleSearch} />
    </Box>
  );
};

export default CampaignFilter;
