import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerActivityHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '10px',
      marginBottom: '1rem',

      '& p': {
        color: '#969696',
        fontStyle: 'italic',
      },

      '& img': {
        width: '26px',
      },
    },
    connectingLineCont: {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      padding: '8px 0',
      position: 'relative',
    },
    connectingBullet: {
      background: '#D9D9D9',
      border: '1px solid #004357',
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      zIndex: 10,
      position: 'relative',
    },
    connectectingLine: {
      '&:before': {
        position: 'absolute',
        borderLeft: '1px solid #004357',
        width: 0,
        height: '100%',
        display: 'block',
        content: '""',
        left: '10px',
      },
    },
    filterWrapper: {
      position: 'absolute',
      zIndex: 100,
      background: '#fff',
      width: 250,
      borderRadius: '10px',
      padding: '1rem',
      right: '5px',
      top: '34px',
      boxShadow:
        '0px 2px 9px rgb(50 71 92 / 6%), 0px 4px 9px 1px rgb(50 71 92 / 4%), 0px 2px 6px 4px rgb(50 71 92 / 2%)',
      '& p': { color: '#000 !important', fontStyle: 'normal !important' },
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
