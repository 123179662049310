import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Form, Formik, FormikValues } from 'formik';
import CustomFormikInput from 'components/formik/FormikInput';
import CustomFormikTextarea from 'components/formik/FormikTextarea';
import useStyles, { SubmitButton } from './index.styles';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useRedux';
import {
  CampaignPopulationRadio,
  FormikCampaignCheckbox,
  // FormikCampaignSelectFile,
} from 'components/formik/campaign';
// import { lambdaUserVerification } from 'apis/aws/lambdaUserVerification';
// import {
//   setCampaignActionType,
//   setInitialCampaign,
// } from 'store/reducer/campaignReducer';
import Loader from 'components/loader';
import { copyCampaignWithoutFile } from 'store/reducer/campaignReducer/action';
import { successToast } from 'utils/toast';
import { setCampaignActionType } from 'store/reducer/campaignReducer';

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  // population: yup.mixed().required('File is required'),
});

const CopyCampaignModal = ({
  onClose,
  campaign,
}: {
  onClose: () => void;
  campaign: any;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: FormikValues) => {
    if (values?.population_type === 'base') {
      const { name, description } = values;
      setLoading(true);
      try {
        const { STATUS, new_campaign_id } = await dispatch(
          copyCampaignWithoutFile({
            campaign_id: campaign?.campaign_id,
            campaign_name: name,
            campaign_desc: description,
          })
        ).unwrap();
        if (new_campaign_id) {
          successToast(STATUS || '');
          dispatch(setCampaignActionType('edit'));
          navigate(`create/${new_campaign_id}`);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ position: 'relative', '& p': { fontFamily: 'Overpass' } }}>
        <Box className={classes.createCloseButton} onClick={() => onClose()}>
          <AiFillCloseCircle />
        </Box>

        <Typography fontWeight={600} fontSize='1.5rem' mb='1rem'>
          Create a copy of Campaign
        </Typography>

        <Formik
          enableReinitialize
          initialValues={{
            base: campaign?.campaign_name || '',
            name: '',
            description: '',
            population_type: 'base',
            population: null,
            has_pii: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <CustomFormikInput
                label='Base Campaign'
                name='base'
                type='text'
                placeholder='Base Campaign Name'
                disabled={true}
              />

              <CustomFormikInput
                label='Add Name'
                name='name'
                type='text'
                placeholder='Campaign Name'
              />

              <CustomFormikTextarea
                label='Add Description'
                name='description'
                placeholder='Description'
              />

              <CampaignPopulationRadio name='population_type' />

              {/* <FormikCampaignSelectFile name='population' /> */}

              {values.population_type === 'upload' && (
                <FormikCampaignCheckbox name='has_pii' label='Has PII Data?' />
              )}

              <Box display='flex' justifyContent='center' mt='2rem'>
                <SubmitButton type='submit'>Create</SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CopyCampaignModal;
