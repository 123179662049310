import { styled, Box, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import useStyles from './index.styles';

const EndpointActiveLine = styled('div')(
  ({ selected }: { selected: boolean }) => ({
    backgroundColor: selected ? kollectAITheme.primaryColor : '#F4F8FB',
    width: '4px',
    borderRadius: '5px',
    height: '49px',
    float: 'left',
  })
);

interface Endpoint {
  label: string;
  value: string;
}

interface IntegrationEndpointProps {
  endPoints: Endpoint[] | [];
  selectedEndpoint: string;
  handleEndpoint: (x: string) => void;
  handleApiScreen: () => void;
}

const IntegrationEndpoints: React.FC<IntegrationEndpointProps> = ({
  endPoints,
  selectedEndpoint,
  handleEndpoint,
  handleApiScreen,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.apiCallEndpointWrapper}>
      <Typography fontWeight={700} margin='10px 16px'>
        endpoints
      </Typography>

      <Box className={classes.apiCallEndpointCont}>
        <Box className={classes.endpointBoxStyle}>
          {endPoints.map((endpoint, index) => {
            const { label, value } = endpoint;
            const selected = selectedEndpoint === value ? true : false;
            return (
              <Box
                key={index}
                sx={{
                  cursor: 'pointer',
                  background: selected ? '#F4F8FB' : '#fff',
                }}
              >
                <EndpointActiveLine selected={selected} />
                <Typography
                  className={classes.endpointTextStyle}
                  onClick={() => handleEndpoint(value)}
                >
                  {label}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box
          sx={{
            cursor: 'pointer',
            background: '#F4F8FB',
          }}
        >
          <Typography
            className={classes.getApiTextStyle}
            onClick={handleApiScreen}
          >
            {` Get API Keys >>`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default IntegrationEndpoints;
