import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

import IntegrationLandingOptions from './integrationOptions';
import IntegrationOptionBox from './OptionBox';
import IntegrationFileUpload from './fileUpload';
import IntegrationApiCall from './apiCall';
import IntegrationDBConnection from './dbConnect';
import { useAppSelector } from 'hooks/useRedux';
// import { userAccess } from 'utils/helper';

const IntegrationScreen = () => {
  const classes = useStyles();

  const selectedOption = useAppSelector(
    (state: any) => state.integration.selectedIntegrationOption
  );
  // const userRole = useAppSelector((state: any) => state.auth.role);

  // const userPermissions = userAccess(userRole)?.integration.permissions;

  return (
    <Box
      sx={{
        background: '#fff',
        height: 'calc(100vh - 70px)',
      }}
    >
      <Box sx={{ margin: '2rem 2rem 0' }}>
        <Typography fontWeight={700} className={classes.optionBoxTitle}>
          Add your customer data (Integration Options)
        </Typography>

        {!selectedOption ? (
          <IntegrationLandingOptions />
        ) : (
          <Box>
            <Box className={classes.optionBoxWrapper}>
              <IntegrationOptionBox
                name='API Call option'
                nameAbbr='api-call'
                selectedOption={selectedOption}
                position='1'
              />

              {/* {userPermissions.includes('file_upload_view') && ( */}
              <IntegrationOptionBox
                name='File upload/SFTP file drop'
                nameAbbr='file-upload'
                selectedOption={selectedOption}
                position='2'
              />
              {/* )} */}

              {/* {userPermissions.includes('db_connect_view') && ( */}
              <IntegrationOptionBox
                name='Direct DB Connection'
                nameAbbr='db-connect'
                selectedOption={selectedOption}
                // position={
                //   userPermissions.includes('file_upload_view') ? '3' : '2'
                // }
                position='3'
              />
              {/* )} */}
            </Box>

            <Box
              sx={{
                background: '#FBFDFE',
                border: '1px solid #E6E6E6',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '0px 0px 10px 10px',
                minHeight: 'calc(100vh - 245px)',
                marginBottom: '1rem',
              }}
            >
              {selectedOption === 'api-call' && <IntegrationApiCall />}
              {
                // userPermissions.includes('file_upload_view') &&
                selectedOption === 'file-upload' && <IntegrationFileUpload />
              }
              {/* {selectedOption === 'db-connect' && <IntegrationDBConnection />} */}
              {
                // userPermissions.includes('db_connect_view') &&
                selectedOption === 'db-connect' && <IntegrationDBConnection />
              }
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationScreen;
