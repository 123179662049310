import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useStyles from './index.styles';

import EmptyScreen from './EmptyScreen';
import {
  setCampaignActionType,
  setCampaignDetailStatus,
  setCampaignExecutionId,
} from 'store/reducer/campaignReducer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import useLocalStorage from 'hooks/useLocalStorage';

export const statusType = {
  0: { name: 'Draft', color: '' },
  1: { name: 'Completed', color: '#6AC5AB' },
  2: { name: 'Scheduled', color: '#0F91D2' },
  3: { name: 'Triggering Communication', color: '' },
  4: { name: 'Blocked', color: '' },
  5: { name: 'Expired', color: '' },
};
type StatusValue = 0 | 1 | 2 | 3 | 4 | 5;

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell align='center'>
          <Typography>CAMPAIGN</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>GROUPS</Typography>
          <Typography fontSize='0.8rem'>(based on)</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>POPULATION</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>NET DUE</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>ENGAGEMENT </Typography>
          <Typography fontSize='0.8rem'>Open % | Click %</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>CONVERSION</Typography>
          <Typography fontSize='0.8rem'># Paid % | $ Paid %</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>STATUS</Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography>SCHEDULE</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function CampaignTable({
  data,
  isSearchData,
  selected,
  setSelected,
  handleCreate,
}: {
  data: any;
  isSearchData: boolean;
  selected: string[];
  setSelected: (x: string[]) => void;
  handleCreate: () => void;
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setStorageValue } = useLocalStorage('customer_execution_id', '');

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.campaign_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, id: string) => {
    let selectedCopy: any = [...selected];
    if (event.target.checked) {
      selectedCopy.push(id);
    } else {
      selectedCopy = selectedCopy.filter((item: any) => item !== id);
    }
    setSelected(selectedCopy);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const permissions = useAppSelector(userPermissions);

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby='tableTitle'
          size='medium'
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.length}
          />
          <TableBody
            sx={{
              border: '1px solid #CCCCCC',
              background: '#fff',
              boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
            }}
          >
            {data?.map((row: any, index: number) => {
              const isItemSelected = isSelected(row.campaign_id);
              const labelId = `campaign-table-checkbox-${index}`;
              const status = statusType[row?.status as StatusValue];

              return (
                <TableRow
                  onClick={(event) => handleClick(event, row.campaign_id)}
                  key={labelId}
                  sx={{ '& td': { '& p': { fontSize: '0.9rem' } } }}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <Typography>{row?.campaign_name}</Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography>
                      {row?.no_of_filters} - {row?.based_on_group_type}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography>{row?.no_customers || '-'}</Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography>
                      {row?.outstanding_balance
                        ? `$ ${row?.outstanding_balance.toLocaleString()}`
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    {row.engagement ? (
                      <Typography color='#115180'>{`${row?.engagement?.open}% | ${row?.engagement?.click}%`}</Typography>
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {row.conversion ? (
                      <Typography color='#00C087'>{`${row?.conversion['#paid']}% | ${row?.conversion?.total_paid}%`}</Typography>
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </TableCell>
                  <TableCell align='center' width='120px'>
                    {[
                      'Draft',
                      'Triggering Communication',
                      'Blocked',
                      'Expired',
                    ].includes(status.name) ? (
                      <Typography>{status.name}</Typography>
                    ) : (
                      <Box
                        className={classes.statusCont}
                        sx={{ background: status.color }}
                      >
                        <Typography>{status.name}</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    <Typography>
                      {row?.schedule_time
                        ? moment(row.schedule_time).format(
                            'DD MMMM, YYYY HH:mm'
                          )
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    {/* {status.name === 'Completed' ? ( */}
                    {!['Draft', 'Scheduled'].includes(status.name) ? (
                      permissions[MODULELIST.CAMPAIGN].includes('create') && (
                        <button
                          className={classes.viewEditButton}
                          onClick={() => {
                            dispatch(setCampaignExecutionId(row.execution_id));
                            dispatch(setCampaignDetailStatus(row.status));
                            setStorageValue(row.execution_id);
                            navigate(`/campaigns/details/${row.campaign_id}`);
                          }}
                        >
                          <Typography>View</Typography>
                        </button>
                      )
                    ) : (
                      <>
                        {permissions[MODULELIST.CAMPAIGN].includes('read') && (
                          <button
                            className={classes.viewEditButton}
                            onClick={() => {
                              dispatch(setCampaignActionType('edit'));
                              navigate(`${row.campaign_id}`);
                            }}
                          >
                            <Typography>Edit</Typography>
                          </button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {(!data || !data?.length) && (
        <EmptyScreen onClick={handleCreate} isSearchData={isSearchData} />
      )}
    </Box>
  );
}
