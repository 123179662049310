import * as React from 'react';
import { Box, Modal } from '@mui/material';
import { useStyles } from '../index.styles';

import ForgotPassword from './ForgotPassword';
import LinkSent from './LinkSent';
import { useAppDispatch } from 'hooks/useRedux';
import { forgotPassword } from 'store/reducer/auth/actions';
import Loader from 'components/loader';
import { errorToast, successToast } from 'utils/toast';

const ForgotPasswordModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [email, setEmail] = React.useState<string>('');
  const [emailValidation, setEmailValidation] = React.useState<string>('');
  const [linkSent, setLinkSent] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleEmailChange = (email: string) => {
    setEmail(email);
    if (email) {
      setEmailValidation('');
    } else {
      setEmailValidation('Required');
    }
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      if (email) {
        const { meta } = await dispatch(forgotPassword({ email })).unwrap();
        if (meta?.success) {
          successToast(meta?.message);
          setEmail('');
        }
        if (!meta?.success) {
          errorToast(meta?.message);
        }
      } else {
        setEmailValidation('Required');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const hanldeClose = () => {
    setEmail('');
    setEmailValidation('');
    setLinkSent(false);
    onClose();
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal
        open={open}
        onClose={hanldeClose}
        disableEnforceFocus={false}
        className={classes.modalStyle}
      >
        <Box className={classes.modalBox}>
          <Box sx={{ width: 380, '& p': { fontFamily: 'Open sans' } }}>
            {!linkSent ? (
              <ForgotPassword
                email={email}
                validation={emailValidation}
                handleEmailChange={handleEmailChange}
                handleSend={handleSend}
                onClose={onClose}
              />
            ) : (
              <LinkSent email={email} />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
