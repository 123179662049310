import { useState, useEffect } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { toggleDrawer } from 'store/reducer/workflowReducer';

import { createSelectOption } from 'utils/helper';
import { getGraphData, getWorkflowSteps } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { MdDeleteForever } from 'react-icons/md';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface AddLoopDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const Button = styled('button')((props: { backgroundColor: string }) => ({
  background: props.backgroundColor,
  borderRadius: '4px',
  color: '#fff',
  padding: '5px 16px',
}));

const AddLoopDrawer: React.FC<AddLoopDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const dispatch = useAppDispatch();

  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();
  const workflowSteps = getWorkflowSteps(
    graphData.config.Steps,
    workflowActionData?.name
  );
  const [loopOptions, setLoopOptions] = useState<any>([]);

  useEffect(() => {
    const options = createSelectOption({ arr: workflowSteps });
    setLoopOptions(options);
  }, []);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName, loopedOn } = values;
    const configObj = { ...graphData.config };
    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'ADD_LOOP',
          run: stepName,
          loopedOn,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'loopedOn');
      updateWorkflow(configObj, keys, loopedOn);
    }

    updateJsonConfig(configObj);
  };

  const handleDelete = async () => {
    setLoading(true);
    let configObj = { ...graphData.config };
    const { parent, parentValue } = workflowActionData;

    const path = getPath(configObj, parent, parentValue);
    const oldData = getStepValue(configObj, path);
    let data = [{ name: '', parentValue, parent, position: 1 }];
    if (oldData.length > 1) {
      data = oldData
        .filter((item: any) => item.name !== workflowActionData.name)
        .map((el: any, index: number) => ({ ...el, position: index + 1 }));
    }
    updateConfigObj(configObj, path, data);

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      loopedOn: yup.string().required('Required'),
    })
  );

  const permissions = useAppSelector(userPermissions);

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
        loopedOn: workflowActionData?.loopedOn,
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form
          style={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              placeholder='Precursor Name'
              disabled={true}
            />

            <CustomFormikWorkflowSelect
              name='loopedOn'
              placeholder='Select Step'
              label='Looped on'
              options={loopOptions}
            />
          </Box>

          {!graphData.published && (
            <Box display='flex' gap='3rem' justifyContent='center' mt='1rem'>
              {permissions[MODULELIST.WORKFLOW].includes('update') && (
                <Button type='submit' backgroundColor='#6ac5ab'>
                  <Typography>Update</Typography>
                </Button>
              )}
              {permissions[MODULELIST.WORKFLOW].includes('delete') &&
              workflowEditor === 'edit' ? (
                <Button
                  type='button'
                  backgroundColor='#daa09a'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                  onClick={handleDelete}
                >
                  <MdDeleteForever fontSize={20} color='#fff' />
                  <Typography>Delete</Typography>
                </Button>
              ) : (
                <Button
                  backgroundColor='#daa09a'
                  onClick={() => dispatch(toggleDrawer(false))}
                >
                  <Typography>Discard</Typography>
                </Button>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddLoopDrawer;
