import { Box, styled, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { useAppDispatch } from 'hooks/useRedux';
import { setSelectedIntegrationOption } from 'store/reducer/IntegrationReducer';

interface IntegrationOptionBoxProps {
  name: string;
  nameAbbr: string;
  selectedOption: string;
  position: string;
}

const IntegrationOptionBoxWrapper = styled('div')(
  ({ isSelected }: { isSelected: boolean }) => ({
    border: '1px solid #E8E8E8',
    borderRadius: '10px 10px 0px 0px',
    padding: '1em 0',
    cursor: 'pointer',
    background: isSelected ? kollectAITheme.primaryColor : '#fff',

    '& p': {
      fontSize: '1.1rem',
      textAlign: 'center',
      fontWeight: 700,
      color: isSelected ? '#fff' : '#6F6F6F',
    },
  })
);

const OptionBox = styled('div')({
  background: '#BCE2EE',
  borderRadius: '15px',
  display: 'inline-block',
  padding: '2px 20px',
  position: 'absolute',
  left: 15,
  top: -10,

  '& p': {
    color: kollectAITheme.primaryColor,
    fontSize: '14px',
  },
});

const IntegrationOptionBox: React.FC<IntegrationOptionBoxProps> = ({
  name,
  nameAbbr,
  selectedOption,
  position,
}) => {
  const dispatch = useAppDispatch();
  const isSelected = nameAbbr === selectedOption ? true : false;

  return (
    <Box
      sx={{
        position: 'relative',
        '& p': {
          fontFamily: 'Overpass',
        },
      }}
    >
      {!isSelected && (
        <OptionBox>
          <Typography>Option {position}</Typography>
        </OptionBox>
      )}
      <IntegrationOptionBoxWrapper
        isSelected={isSelected}
        onClick={() => dispatch(setSelectedIntegrationOption(nameAbbr))}
      >
        <Typography>{name}</Typography>
      </IntegrationOptionBoxWrapper>
    </Box>
  );
};

export default IntegrationOptionBox;
