import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import CustomModal from 'components/Modal/CustomModal';

const TemplateDeleteModal = ({
  open,
  deleteData,
  onClose,
  onDelete,
}: {
  open: boolean;
  deleteData: any;
  onDelete: () => void;
  onClose: () => void;
}) => {
  return (
    <CustomModal
      header='Confirm Delete'
      open={open}
      onClose={onClose}
      children={
        <Box
          sx={{
            maxHeight: 'calc(100vh - 300px)',
            overflow: 'auto',
          }}
        >
          <Typography mb='0.8rem'>
            Following templates are used in following modules:
          </Typography>

          {deleteData?.map((data: any, index: number) => {
            return (
              <Box key={`template-${index}`}>
                <Typography fontWeight={600} fontSize='1.1rem'>
                  {data.template}
                </Typography>
                {data.data.map((item: any, ind: number) => (
                  <Box mb='0.6rem' key={`modules-${ind}`}>
                    <Typography>{item?.module}</Typography>
                    {item?.usedList.map((item: any) => (
                      <ListItem sx={{ pt: 0, pb: 0 }}>
                        <ListItemText
                          primary={item}
                          sx={{ display: 'list-item' }}
                        />
                      </ListItem>
                    ))}
                  </Box>
                ))}
              </Box>
            );
          })}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              marginTop: '2rem',
              '& button': {
                color: '#fff',
                padding: '8px 4rem',
                borderRadius: '8px',
              },
            }}
          >
            <button style={{ background: '#6AC5AB' }} onClick={onDelete}>
              <Typography>Confirm</Typography>
            </button>
            <button style={{ background: '#DAA09A' }} onClick={onClose}>
              <Typography>Discard</Typography>
            </button>
          </Box>
        </Box>
      }
    />
  );
};
export default TemplateDeleteModal;
