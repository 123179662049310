import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const ResponseButton = styled('div')(
  ({ active }: { active: boolean }) => ({
    background: active ? '#0069AA' : '#EBEEF2',
    padding: '8px 2.5rem 6px',
    borderRadius: '20px',
    color: active ? '#fff' : '#8F9499',
    cursor: 'pointer',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responseTypeCont: {
      display: 'inline-flex',
      alignItems: 'center',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
  })
);

export default useStyles;
