import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { infoToast } from 'utils/toast';
import { RiFileCopyLine } from 'react-icons/ri';
import { kollectAITheme } from 'config/theme';
import { MdDeleteForever } from 'react-icons/md';
import { useAppDispatch } from 'hooks/useRedux';
// import useStyles from './styles';

import generatedImg from 'assets/generated.svg';
import notGeneratedImg from 'assets/not_generated.svg';
import { generateApiKey } from 'store/reducer/IntegrationReducer/action';
import DataInput from './DataInput';

const GenerateAPIKey = () => {
  const dispatch = useAppDispatch();
  // const classes = useStyles();

  const [apiKey, setApiKey] = React.useState<string>('');

  return (
    <Box sx={{ padding: '1rem', '& p': { fontFamily: 'Overpass' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '100px' }}>
        <Typography sx={{ fontWeight: 600, color: '#0C101A' }}>
          Client ID
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #969696',
            '& button': { width: '42px', cursor: 'default' },
          }}
        >
          <DataInput value='8a0eXXXX-XXXX-XXXX-XXXX-XXXXXXXX0d08' />
          <button
            onClick={() => {
              // navigator.clipboard.writeText(clientId);
              // infoToast('Copied to clipboard!');
            }}
          >
            <RiFileCopyLine
              fontSize='20px'
              color={kollectAITheme.primaryColor}
            />
          </button>
        </Box>
      </Box>

      <Box
        sx={{
          border: '2px solid #969696',
          borderRadius: '10px',
          padding: '1rem',
          marginTop: '2rem',
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            '& img': { width: '35px' },
          }}
        >
          <Typography fontWeight={600} color=' #0C101A' fontSize='1.2rem'>
            Sandbox
          </Typography>
          <img src={!apiKey ? notGeneratedImg : generatedImg} alt='' />
        </Box>

        <Box
          sx={{
            margin: '5px auto 3rem',
            '& p': {
              color: '#969696',
              fontWeight: 600,
              marginBottom: '1rem',
            },
          }}
        >
          <Typography>
            Your sandbox environment key has been generated and can be used for
            upto 20 API calls per month.
          </Typography>

          <Typography>
            The customers registered using sandbox key can not be more than 10
            and would be chargeable if exceeds.
          </Typography>
        </Box>

        <Box>
          <Typography>API Key</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              marginTop: '8px',
              marginBottom: '1rem',
            }}
          >
            {!apiKey ? (
              <Typography color='#969696' fontWeight={600}>
                Not Generated
              </Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #969696',

                  '& button': {
                    width: '42px',
                    height: '42px',
                  },
                }}
              >
                <DataInput value={apiKey} />
                <button
                  style={{ background: '#6AC5AB' }}
                  onClick={() => {
                    navigator.clipboard.writeText(apiKey);
                    infoToast('Copied to clipboard!');
                  }}
                >
                  <RiFileCopyLine fontSize='20px' color='#fff' />
                </button>
                <button
                  style={{ background: '#E4897B' }}
                  // onClick={() => setIsSandboxKeyGenerated(false)}
                >
                  <MdDeleteForever fontSize='20px' color='#fff' />
                </button>
              </Box>
            )}

            <Box>
              <Box
                sx={{
                  background: apiKey ? kollectAITheme.primaryColor : '#6AC5AB',
                  borderRadius: '20px',
                  color: '#fff',
                  padding: '8px 25px',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  if (!apiKey) {
                    const { payload } = await dispatch(
                      generateApiKey({ product: 'KOLLECT AI' })
                    );
                    setApiKey(payload.api_key);
                    // setIsSandboxKeyGenerated(true);
                  }
                }}
              >
                <Typography fontWeight={600} align='center'>
                  {apiKey ? 'Regenerate API Key' : 'Generate API Key'}
                </Typography>
              </Box>
              {apiKey && (
                <Typography
                  sx={{
                    color: '#969696',
                    fontStyle: 'italic',
                    fontSize: '0.9rem',
                    marginTop: '10px',
                  }}
                >
                  Last generated on 4th Nov, 2022
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          border: '2px solid #969696',
          borderRadius: '10px',
          padding: '1rem',
          marginTop: '2rem',
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            '& img': {
              width: '35px',
            },
          }}
        >
          <Typography fontWeight={600} color=' #0C101A' fontSize='1.2rem'>
            Production
          </Typography>
          <img src={notGeneratedImg} alt='' />
        </Box>

        <Box
          sx={{
            margin: '0.8rem auto 3rem',
            '& p': {
              color: '#969696',
              fontWeight: 600,
              marginBottom: '1rem',
            },
            '& span': {
              color: kollectAITheme.primaryColor,
              cursor: 'pointer',
              fontWeight: 600,
            },
          }}
        >
          <Typography>
            Set up your production environment by generating your production API
            key. This key has a limit of 100,000 APIs per month. To increase the
            API key usage limit, please reach out to Kollect AI support at{' '}
            <span>support@kollectai.com</span>.
          </Typography>

          <Typography>
            Your current customer limit in production is 100,000. Customers
            registered post this limit will be charged at $0.4/customer per
            month. To increase the limit, please reach out to Kollect AI support
            at <span>support@kollectai.com</span>.
          </Typography>
        </Box>

        <Box>
          <Typography>API Key</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '8px',
              marginBottom: '1rem',
            }}
          >
            <Typography color='#969696' fontWeight={600}>
              Not Generated
            </Typography>
            <Box>
              <Box
                sx={{
                  // background: '#6AC5AB',
                  background: '#A2A7AE',
                  borderRadius: '20px',
                  color: '#fff',
                  padding: '8px 25px',
                  // cursor: 'pointer',
                  cursor: 'default',
                }}
              >
                <Typography fontWeight={600}>Generate API Key</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GenerateAPIKey;
