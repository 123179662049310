import { createStyles, makeStyles } from '@material-ui/core';
import { styled } from '@mui/material';

export const Button = styled('button')(
  (props: { backgroundColor: string }) => ({
    background: props.backgroundColor,
    borderRadius: '4px',
    color: '#fff',
    padding: '5px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  })
);

export const AddConditionButton = styled('div')(
  ({ check }: { check: any[] }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '6px',
    margin: '1rem',
    cursor: !check ? 'pointer' : check.length < 5 ? 'pointer' : 'default',
    '& p': {
      color: !check || check.length < 5 ? '#6AC5AB' : '#6F6F6F',
      textDecoration: 'underline',
    },
    '& svg': {
      color: !check ? '#6AC5AB' : check.length < 5 ? '#6AC5AB' : '#6F6F6F',
      fontSize: '24px',
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    formWrapper: {
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    datePickerWrapper: {
      '& .MuiSvgIcon-root': {
        color: '#fff',
        fontSize: '16px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '13px',
      },
    },
    conditionsWrapper: {
      border: '1px solid #E6E6E6',
      borderRadius: '10px',
    },
    conditionsCont: {
      display: 'grid',
      gridTemplateColumns: '1fr 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '10px',
      padding: '0.5rem 0.8rem',
      cursor: 'pointer',
      '& svg': {
        color: '#DAA09A',
        fontSize: '1.1rem',
      },
    },
    buttonWrapper: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      mt: '1rem',
    },
    conditionButtonCont: {
      display: 'flex',
      justifyContent: 'center',
      margin: '1rem auto',
    },
    updateCondition: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginBottom: '1rem',
      '& p': {
        color: '#6AC5AB',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    conditionText: {
      fontSize: '0.8rem !important',
      color: '#A8ABC3',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
  })
);

export default useStyles;
