import * as React from 'react';
import { channels } from './addTrigger';
import { Box, Typography } from '@mui/material';
import { AiFillEye, AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import Select from 'react-select';
import useStyles from './index.styles';

import TemplatePreviewModal from 'components/templates/templatePreview';

const styles = {
  container: (styles: any) => ({
    ...styles,
    width: '100%',
  }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
    };
  },
};

interface AddTriggerChannelsProps {
  showTemplate: any;
  isPublished: boolean;
  templateOptions: any;
  selectedTemplateOption: any;
  setShowTemplate: (x: any) => void;
  setSelectedTemplateOption: (x: any) => void;
}

const AddTriggerChannels = ({
  showTemplate,
  setShowTemplate,
  isPublished,
  templateOptions,
  selectedTemplateOption,
  setSelectedTemplateOption,
}: AddTriggerChannelsProps) => {
  const classes = useStyles();

  const [previewTemplate, setPreviewTemplate] = React.useState<any>({});
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);

  const handleShowTemplate = (channel: string) => {
    setShowTemplate((prev: any) => {
      let newState = { ...prev };
      if (Object.keys(prev).includes(channel)) {
        delete newState[channel];
      } else {
        newState = { ...newState, [channel]: true };
      }
      return newState;
    });
  };

  return (
    <>
      <Box>
        <Typography mb='0.5rem'>Communication Channels</Typography>
        {channels.map((channel, index) => {
          return (
            <Box key={index} mb='0.5rem'>
              <Box className={classes.channelBoxCont}>
                <Typography color='#A8ABC3' fontSize='0.8rem'>
                  {channel.toUpperCase()}
                </Typography>
                <button
                  type='button'
                  onClick={() => handleShowTemplate(channel)}
                  disabled={isPublished}
                >
                  {showTemplate[channel] ? (
                    <AiFillMinusCircle color=' #DAA09A' fontSize='1.1rem' />
                  ) : (
                    <AiFillPlusCircle color='#6ac5ab' fontSize='1.1rem' />
                  )}
                </button>
              </Box>

              {showTemplate[channel] && (
                <Box className={classes.templateOptionCont}>
                  <Select
                    placeholder='Template'
                    options={templateOptions[channel]}
                    value={
                      templateOptions[channel].find(
                        (each: any) =>
                          each.value === selectedTemplateOption[channel]
                      ) ?? ''
                    }
                    onChange={(value: any) => {
                      setSelectedTemplateOption((prev: any) => ({
                        ...prev,
                        [channel]: value.value,
                      }));
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={styles}
                    isDisabled={isPublished}
                  />
                  <AiFillEye
                    color='#fff'
                    fontSize='1.8rem'
                    cursor='pointer'
                    onClick={() => {
                      if (selectedTemplateOption[channel]) {
                        const template = templateOptions[channel].find(
                          (template: any) =>
                            template.id === selectedTemplateOption[channel]
                        );
                        setPreviewTemplate(template);
                        setOpenPreview(true);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>

      <TemplatePreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        selectedOption={previewTemplate}
        channel={previewTemplate.channel}
      />
    </>
  );
};

export default AddTriggerChannels;
