import {
  createTheme as createMuiTheme,
  ThemeOptions,
  Theme,
} from "@material-ui/core/styles";

export const kollectAITheme = {
  white: "#ffffff",
  black: "#000000",
  bluishWhite: "#F4F8FB",
  solitude: "#ECEDEE",
  backgroundViolet: "#f4f7fe",
  lightGreen: "#00C087",
  darkYellow: "#FFB600",
  darkBlue: "#2C62EC",
  blueViolet: "#8C34EA",
  torchRed: "#FF0D29",
  jasperRed: "#D44638",
  BluishSolitude: "#E9EFFD",
  primaryColor: "#004357",
  lightPrimaryColor: "#B2C6CC",
  secondaryColor: "#336979",

  lightGrey: "#A2A7AE",
  borderGrey: "#E6E6E6",
  lightBorderGrey: "#E0E7FF",

  mediumAquamarine: "#6AC5AB",
  parrotPink: "#DAA09A",

  primaryText: "#272D3B",
  secondaryText: "#636C80",
  charcoalText: "#333333",

  // Transparent Colors
  lightTransparentGreen: "rgba(0, 192, 135, 0.5)",
  darkTransparentYellow: "rgba(255, 182, 0, 0.5)",
  darkTransparentBlue: "rgba(44, 98, 236, 0.5)",
  boxShadowColor: "rgba(39, 45, 59, 0.2)",
  purpleTransparent: "rgba(44, 98, 236, 0.4)",
  blackTransparent: "rgba(0, 0, 0, 0.5)",
};

declare module "@material-ui/core/styles" {
  interface Theme {
    kollectAITheme: typeof kollectAITheme;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    kollectAITheme?: typeof kollectAITheme;
  }
}

const baseTheme: Partial<ThemeOptions> = {
  kollectAITheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      "Overpass",
      "Montserrat",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 16,
    button: {
      textTransform: "none",
    },
  },
};

const theme = createMuiTheme(baseTheme);

export type IKollectAITheme = keyof Theme["kollectAITheme"];
// export function createTheme(customTheme: ThemeOptions): Theme {
//   const mergedTheme: ThemeOptions = merge(baseTheme, customTheme);
//   return createMuiTheme(mergedTheme);
// }

export default theme;
