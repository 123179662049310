import { AwsLinkType } from 'constants/types';
import { getAccessToken } from 'utils/auth';

export const lamdaStatusUpdate = async (props: {
  customer_id: string;
  odf_customer_id: string;
  status_type: string;
  alternate_email: string;
  alternate_phone_number: string;
  awsUrl: AwsLinkType;
  comments?: string;
}) => {
  const { awsUrl, ...payload } = props;
  try {
    const res = await fetch(awsUrl.link, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json',
        token: `${getAccessToken()}`,
        'x-api-key': awsUrl.api_key,
      },
    });
    const jsonRes = await res.json();
    console.log({ jsonRes });
  } catch (error) {
    console.log({ error });
    return { success: false };
  }
};
