import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import useStyles from './index.styles';
import 'react-phone-input-2/lib/style.css';

import { signup } from 'store/reducer/auth/actions';
import { useAppDispatch } from 'hooks/useRedux';
import { kollectAITheme } from 'config/theme';

import Loader from 'components/loader';
import { FormikAuthInput, FormikAuthPassword } from 'components/formik/auth';
import { VerifyEmailModal } from 'components/auth/modals';
import { setVerifyEmailData } from 'store/reducer/auth';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please provide a valid email.')
    .required('Required'),
  password: yup
    .string()
    .min(6, 'Password should be atleast 6 characters long.')
    .required('Required'),
  phone: yup.string().required('Required'),
  countryCode: yup.string().required('Required'),
  organisationName: yup.string().required('Required'),
  organisationWebsite: yup.string().required('Required'),
});

const SignUpScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [phoneTouched, setPhoneTouched] = useState<boolean>(false);
  const [openVerify, setOpenVerify] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');

  const checkValidation = (isValid: boolean, touched: any) => {
    return isValid && Object.keys(touched).length > 0;
  };

  const handleSubmit = async (values: FormikValues, actions: any) => {
    setLoading(true);
    const {
      email,
      phone,
      countryCode,
      organisationName,
      organisationWebsite,
      password,
    } = values;
    const payload = {
      email,
      phone,
      country_code: countryCode,
      client_name: organisationName,
      website_url: organisationWebsite,
      password: password,
    };

    try {
      const res = await dispatch(signup(payload)).unwrap();

      if (res?.meta?.status === 'SUCCESS' && res?.meta?.success === true) {
        setUserEmail(email);
        dispatch(setVerifyEmailData(null));
        setOpenVerify(true);
      }
      actions.resetForm();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />

      <Box style={{ width: '60%', padding: '2em 0' }}>
        <Box mb='1rem'>
          <Typography fontSize='1.6rem' fontWeight={500}>
            Welcome
          </Typography>
          <Typography>Please signup to access account.</Typography>
        </Box>

        <Formik
          initialValues={{
            fullNumber: '',
            email: '',
            phone: '',
            countryCode: '',
            organisationName: '',
            organisationWebsite: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            touched,
            handleBlur,
            setFieldValue,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormikAuthInput
                name='email'
                label='E-mail'
                placeholder='Type your e-mail'
              />

              <Box className={classes.formControl}>
                <label>
                  <Typography mb='5px'>Phone Number</Typography>
                </label>
                <PhoneInput
                  country={'in'}
                  value={values.fullNumber}
                  countryCodeEditable={false}
                  onChange={(e, country: any) => {
                    const phoneNumber = e.replace(country?.dialCode, '');
                    setFieldValue('countryCode', country.dialCode);
                    setFieldValue('phone', phoneNumber);
                    handleChange(e);
                    setPhoneTouched(true);
                  }}
                  onBlur={handleBlur}
                />
                {values.phone === '' && phoneTouched && (
                  <p className={classes.formErrorMessage}>Required</p>
                )}
              </Box>

              <FormikAuthInput
                name='organisationName'
                label='Organisation Name'
                placeholder='Type your organisation name'
              />

              <FormikAuthInput
                name='organisationWebsite'
                label='Organisation Website'
                placeholder='www.example.com'
              />

              <FormikAuthPassword
                name='password'
                label='Create Password'
                placeholder='Type your password'
                show={showPassword}
                handleShow={() => setShowPassword(!showPassword)}
              />

              <Box mt='1.5rem' mb='1rem'>
                <button
                  type='submit'
                  className={classes.signupSubmitButton}
                  style={{
                    background: `${
                      checkValidation(isValid, touched)
                        ? kollectAITheme.primaryColor
                        : '#BCBCBC'
                    }`,
                    cursor: `${
                      checkValidation(isValid, touched) ? 'pointer' : 'default'
                    }`,
                  }}
                  disabled={!checkValidation(isValid, touched)}
                >
                  Sign Up
                </button>
              </Box>
            </form>
          )}
        </Formik>

        <Box>
          <Typography align='center'>
            Already have an account?{' '}
            <span
              className={classes.signUpButtonStyle}
              onClick={() => navigate('/login')}
            >
              Log In
            </span>
          </Typography>
        </Box>
      </Box>

      <VerifyEmailModal
        open={openVerify}
        onClose={() => setOpenVerify(false)}
        email={userEmail}
      />
    </>
  );
};

export default SignUpScreen;
