import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { MdDeleteForever } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getPath,
  getStepValue,
  updateConfigObj,
} from 'utils/workflow/jsonModifier';
import { toggleDrawer } from 'store/reducer/workflowReducer';

export const Button = styled('button')(
  (props: { backgroundColor: string; disabled?: boolean }) => ({
    background: props.disabled ? '#6F6F6F' : props.backgroundColor,
    borderRadius: '4px',
    padding: '5px 16px',
    cursor: props.disabled ? 'default' : 'pointer',
    '& p': { color: '#fff' },
  })
);

const WorkflowActionButtons = ({
  isUpdateDisabled = false,
  setLoading,
  updateJsonConfig,
}: {
  isUpdateDisabled?: boolean;
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
}) => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(userPermissions);
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const workflowActionData = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );

  const graphData: any = getGraphData();

  const handleDelete = async () => {
    setLoading(true);
    let configObj = { ...graphData.config };
    const { parent, parentValue } = workflowActionData;

    const path = getPath(configObj, parent, parentValue);
    const oldData = getStepValue(configObj, path);
    let data = [{ name: '', parentValue, parent, position: 1 }];
    if (oldData.length > 1) {
      data = oldData
        .filter((item: any) => item.name !== workflowActionData.name)
        .map((el: any, index: number) => ({ ...el, position: index + 1 }));
    }
    updateConfigObj(configObj, path, data);

    updateJsonConfig(configObj);
  };

  return (
    <Box display='flex' gap='1rem' justifyContent='center' mt='1rem'>
      {permissions[MODULELIST.WORKFLOW].includes('update') && (
        <Button
          type='submit'
          backgroundColor='#6ac5ab'
          disabled={isUpdateDisabled}
        >
          <Typography>Update</Typography>
        </Button>
      )}
      {permissions[MODULELIST.WORKFLOW].includes('delete') &&
      workflowEditor === 'edit' ? (
        <Button
          type='button'
          backgroundColor='#daa09a'
          sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          onClick={handleDelete}
        >
          <MdDeleteForever fontSize={20} color='#fff' />
          <Typography>Delete</Typography>
        </Button>
      ) : (
        <Button
          type='button'
          backgroundColor='#daa09a'
          onClick={() => dispatch(toggleDrawer(false))}
        >
          <Typography>Discard</Typography>
        </Button>
      )}
    </Box>
  );
};

export default WorkflowActionButtons;
