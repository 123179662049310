import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fitlerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    searchWrapper: {
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '0 10px',
      '& input': {
        outline: 'none',
        background: 'transparent',
        border: 'transparent',
        fontSize: '1rem',
        padding: '10px',
      },
      '& svg': { cursor: 'pointer' },
    },
    idWrapper: {
      background: '#EDF0F4',
      borderRadius: '3px',
      padding: '2px 5px',
    },
    statusWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '1em',
      margin: '0 auto 1rem',
    },
    actionsWrapper: {
      position: 'absolute',
      left: '-10px',
      top: '20px',
      height: '25px',
      width: '25px',
      background: '#fff',
      zIndex: 100,
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      border: '2px solid #E7EBF0',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
