import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';

import { useAppSelector } from 'hooks/useRedux';

import { CustomFormikWorkflowInput } from 'components/formik/workflow';
import AbTestingSplits from './splits';
import WorkflowActionButtons from '../actionButtons';

import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  // updateWorkflow,
} from 'utils/workflow/jsonModifier';
import {
  getGraphData,
  parseWorkflowAbTestingSplit,
  workflowAbTestingSplitPayload,
} from 'utils/workflow/graphHandler';

interface ABTestingDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

export interface SplitType {
  split: string;
  value: number;
}

const ABTestingDrawer: React.FC<ABTestingDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const [splits, setSplits] = useState([
    { split: 'A', value: 50 },
    { split: 'B', value: 50 },
  ]);

  const graphData: any = getGraphData();

  useEffect(() => {
    if (workflowActionData.split) {
      const parsedSplits = parseWorkflowAbTestingSplit(
        workflowActionData.split
      );

      setSplits(parsedSplits);
    }
  }, [workflowActionData]);

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const { stepName } = values;
    const { splitPayload, splitChildren } = workflowAbTestingSplitPayload(
      splits,
      stepName
    );
    const configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'AB_TESTING',
          split: splitPayload,
          haschildren: splitChildren,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const pathKeys = getKeys(configObj, workflowActionData?.name);
      pathKeys?.splice(-1, 1, 'haschildren');

      let splitChildrenValue = configObj;
      for (const key of pathKeys!) {
        splitChildrenValue = splitChildrenValue[key];
      }

      const newSplitChildren: any = {};
      splits.forEach(({ split, value }) => {
        if (Object.keys(splitChildrenValue).includes(split)) {
          newSplitChildren[split] = splitChildrenValue[split];
        } else {
          newSplitChildren[split] = [
            { parent: stepName, parentValue: split, position: 1, name: '' },
          ];
        }
      });

      let objToUpdate = configObj;
      for (const key of pathKeys!.slice(0, -1)) {
        objToUpdate = objToUpdate[key];
      }
      objToUpdate.split = splitPayload;
      objToUpdate.haschildren = newSplitChildren;

      // const keys = getKeys(configObj, workflowActionData?.name);
      // const splitKeys = keys?.splice(-1, 1, 'split');
      // updateWorkflow(configObj, splitKeys, splitPayload);
    }

    updateJsonConfig(configObj);
  };

  return (
    <>
      <Formik
        initialValues={{
          stepName: workflowActionData?.name,
          precursorName: workflowActionData?.parent,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            style={{
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <CustomFormikWorkflowInput
                label='Step Name'
                name='stepName'
                placeholder='Step Name'
                disabled={workflowEditor === 'create' ? false : true}
              />

              <CustomFormikWorkflowInput
                label='Precursor Name'
                name='precursorName'
                placeholder='Precursor Name'
                disabled={true}
              />

              <AbTestingSplits splits={splits} setSplits={setSplits} />
            </Box>

            {!graphData.published && (
              <WorkflowActionButtons
                setLoading={setLoading}
                updateJsonConfig={updateJsonConfig}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ABTestingDrawer;
