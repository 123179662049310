import { Typography } from '@mui/material';
import moment from 'moment';

const getStatus = (eventName: string) => {
  if (eventName === 'PTP') return 'PTP commitment';
  if (eventName === 'Broken Promise') return 'Broken_PTP';
  if (eventName === 'New and uncategorised') return 'Default';
  return eventName;
};

const StatusUpdates = ({ statusUpdates }: { statusUpdates: any }) => {
  return (
    <>
      <Typography>
        {getStatus(statusUpdates.event) || '-'}{' '}
        {statusUpdates.date && (statusUpdates.event === 'PTP' ? 'on' : 'since')}
      </Typography>
      {statusUpdates.date && (
        <Typography>
          {moment(statusUpdates.date).format('D MMM, YYYY')}
        </Typography>
      )}
    </>
  );
};
export default StatusUpdates;
