import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import { useAppSelector } from 'hooks/useRedux';
import useStyles from './index.styles';

import Loader from 'components/loader';
// import TestTemplatePreview from './previews';
import { concatenatedTemplate } from 'utils/helper';
import { getAccessToken } from 'utils/auth';
import { awsUrl } from 'store/reducer/auth';

interface TestTemplateModalProps {
  onClose: (x: boolean) => void;
  selectedTemplate: any;
  selectedChannel: string;
}

const TestTemplateModal: React.FC<TestTemplateModalProps> = ({
  onClose,
  selectedTemplate,
  selectedChannel,
}) => {
  const classes = useStyles();

  const userEmail = useAppSelector((state: any) => state.auth.email);
  const urlObj = useAppSelector(awsUrl)?.template_api;

  console.log({ urlObj });

  const [initialValues, setInitialValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [inputFields, setInputFields] = useState<string[]>([]);
  const [schema, setSchema] = useState({});

  useEffect(() => {
    if (selectedTemplate.variable_resolver) {
      const parsedResolver = JSON.parse(selectedTemplate.variable_resolver);

      let resolver: string[] = [];
      if ('variable_resolver' in parsedResolver) {
        resolver = concatenatedTemplate(parsedResolver.variable_resolver);
      }
      if ('pii' in parsedResolver && 'non_pii' in parsedResolver) {
        resolver = concatenatedTemplate(parsedResolver);
      }

      const values = resolver.reduce((a, v) => ({ ...a, [v]: '' }), {
        send_to: selectedChannel === 'email' ? userEmail : '',
        ...(selectedChannel === 'email' && {
          subject: selectedTemplate.template_subject,
        }),
      });
      const validation = resolver.reduce(
        (a, v) => ({ ...a, [v]: yup.string().required('Required') }),
        {
          send_to: yup.string().required('Required'),
          ...(selectedChannel === 'email' && {
            subject: yup.string().required('Required'),
          }),
        }
      );
      setSchema(validation);
      setInitialValues(values);
      setInputFields(resolver);
    } else {
      setInitialValues({ send_to: userEmail });
    }
  }, [selectedTemplate, userEmail]);

  const validationSchema = yup.object().shape(schema);

  const handleSubmit = async (values: FormikValues) => {
    if (urlObj) {
      setLoading(true);
      try {
        const variable_values = { ...values };
        delete variable_values.send_to;
        const { template, variable_resolver } = selectedTemplate;

        let reqInstance = axios.create({
          // headers: { 'x-api-key': 'eQYX3vskyj6uIBmEAFuMp9TXWqbzROuW2RE7NOGH' },
          headers: { 'x-api-key': urlObj.api_key },
        });

        await reqInstance.post(
          // 'https://622nuk9o9a.execute-api.ap-south-1.amazonaws.com/Dev/data_receiver',
          urlObj.link,
          {
            test: [
              {
                channel: selectedChannel,
                send_to: values.send_to,
                variable_resolver: JSON.parse(variable_resolver),
                variable_values,
                template,
                token: getAccessToken(),
              },
            ],
          }
        );
        onClose(false);
      } catch (error) {}
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <div className={classes.createTemplateContainer}>
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '-20px',
            cursor: 'pointer',
          }}
          onClick={() => onClose(false)}
        >
          <AiOutlineClose />
        </div>
        <Typography
          sx={{ marginBottom: '1.5rem', fontWeight: 600, fontSize: '1.5rem' }}
        >
          Test your template{' '}
          <span style={{ fontSize: '1rem', fontWeight: 400 }}>
            (Send it to yourself)
          </span>
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, handleBlur }) => (
            <Form className={classes.testFormStyle}>
              <Box>
                <div className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <Typography>
                      {selectedChannel === 'email' ? 'Email' : 'Phone Number'}
                    </Typography>
                  </label>
                  <div>
                    <input
                      type='text'
                      name='send_to'
                      placeholder={
                        selectedChannel === 'email' ? 'Email' : 'Phone_Number'
                      }
                      value={values.send_to}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.formInput}
                    />
                    {errors.send_to && touched.send_to && (
                      <p className={classes.formErrorMessage}>
                        {errors.send_to}
                      </p>
                    )}
                  </div>
                </div>

                {selectedChannel === 'email' && (
                  <div className={classes.formControl}>
                    <label className={classes.formLabel}>
                      <Typography>Subject</Typography>
                    </label>
                    <div>
                      <input
                        type='text'
                        name='subject'
                        placeholder='Subject'
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.formInput}
                      />
                      {errors.subject && touched.subject && (
                        <p className={classes.formErrorMessage}>
                          {errors.subject}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {inputFields.length > 0 && (
                  <Box>
                    <Typography mb='1rem'>
                      Please fill below variable for your test{' '}
                      {selectedChannel === 'email' ? 'email' : 'phone number'}
                    </Typography>
                    {inputFields?.map((field: string, index: number) => {
                      return (
                        <div className={classes.formControl} key={index}>
                          <label className={classes.formLabel}>
                            <Typography>{field}</Typography>
                          </label>
                          <div>
                            <input
                              type='text'
                              name={field}
                              placeholder=''
                              value={values[field]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={classes.formInput}
                            />
                            {errors[field] && touched[field] && (
                              <p className={classes.formErrorMessage}>
                                {errors[field]}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Box>
                )}
              </Box>

              <div className={classes.saveButtonWrapper}>
                <button type='submit' className={classes.saveButtonStyle}>
                  send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TestTemplateModal;
