import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';

const radioButton = (
  <Radio
    size='small'
    sx={{
      '&.Mui-checked': {
        color: kollectAITheme.primaryColor,
      },
    }}
  />
);

const CustomerCallRadio = ({
  label,
  name,
}: {
  label: string;
  name: string;
}) => {
  return (
    <Box mb='1rem'>
      <label>
        <Typography
          sx={{ opacity: '0.6', fontFamily: 'Overpass', fontSize: '0.9rem' }}
          mb='5px'
        >
          {label}
        </Typography>
      </label>

      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { value } = field;
          const { setFieldValue } = form;

          return (
            <>
              <RadioGroup
                {...field}
                value={value}
                onChange={(e: any) => setFieldValue(name, e.target.value)}
                row
                sx={{ width: '100%' }}
              >
                <FormControlLabel
                  value='full'
                  control={radioButton}
                  label={
                    <Typography
                      sx={{
                        opacity: 0.6,
                        fontSize: '0.9rem',
                        color: kollectAITheme.primaryColor,
                      }}
                    >
                      Full due amount
                    </Typography>
                  }
                />
                <FormControlLabel
                  value='partial'
                  control={radioButton}
                  label={
                    <Typography
                      sx={{
                        opacity: 0.6,
                        fontSize: '0.9rem',
                        color: kollectAITheme.primaryColor,
                      }}
                    >
                      Partial Amount
                    </Typography>
                  }
                />
              </RadioGroup>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};

export default CustomerCallRadio;
