import React from 'react';
import { Box, ListItem, ListItemText, styled, Typography } from '@mui/material';

import DownloadImg from 'assets/download_salmon.svg';

const ConfirmButton = styled('button')({
  background: '#6AC5AB',
  borderRadius: '8px',
  padding: '10px 1.5rem',
  color: '#fff',
});

const InvalidEntries = ({
  errors,
  onClose,
  handleConfirm,
}: {
  errors: any;
  onClose: () => void;
  handleConfirm: () => void;
}) => {
  const [invalidData, setInvalidData] = React.useState<number>(0);
  const [totalData, setTotalData] = React.useState<number>(0);

  React.useEffect(() => {
    const total = errors?.total_records || 0;
    const invalid = errors?.invalid_records || 0;
    setInvalidData(invalid);
    setTotalData(total);
  }, [errors]);

  const handleDownload = React.useCallback(() => {
    if (errors?.link) {
      window.open(errors.link, '_self');
    }
  }, [errors]);

  const validData = React.useMemo(
    () => +totalData - invalidData,
    [totalData, invalidData]
  );

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: '10px',
        outline: 'none',
        width: '650px',
        '& p': { fontFamily: 'Overpass' },
      }}
    >
      <Box
        sx={{
          background: '#004357',
          borderRadius: '10px 10px 0px 0px',
          padding: '1rem 0',
          color: '#fff',
          position: 'absolute',
          left: '-30px',
          width: 'calc(650px + 3.8rem)',
          top: '-2.5rem',
        }}
      >
        <Typography fontWeight={600} align='center' fontSize='1.2rem'>
          Found few invalid entries
        </Typography>
      </Box>

      <Box sx={{ padding: '3rem 2rem 0' }}>
        {' '}
        <Typography>
          It seems the customer data that you have uploaded have some invalid
          entries as below:
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <ListItemText
              primary={`Total customer data uploaded: ${totalData}`}
              sx={{ display: 'list-item' }}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <ListItemText
              primary={`Total valid customer data found: ${validData}`}
              sx={{ display: 'list-item' }}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <ListItemText
              primary={`Total invalid data uploaded: ${invalidData}`}
              sx={{ display: 'list-item' }}
            />
          </ListItem>
        </Typography>
        <Typography mt='1rem'>
          <button type='button' onClick={handleDownload}>
            <Typography color='#95261F'>Click here</Typography>
          </button>
          to download invalid customer ids with their reasons
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: '0.75rem',
            cursor: 'pointer',
          }}
          onClick={handleDownload}
        >
          <img src={DownloadImg} alt='' />
        </Box>
        <Typography>
          Please select from below options if you want to create the campaign
          with {validData} valid entries or if you want to cancel this campaign
          and re-create with fixed data
        </Typography>
        {/* {validData !== 0 && ( */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
          <ConfirmButton onClick={handleConfirm}>
            <Typography>
              Confirm to create campaign with {validData} valid entries
            </Typography>
          </ConfirmButton>
        </Box>
        {/* )} */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1.5rem' }}>
          <button onClick={onClose}>
            <Typography
              sx={{
                textDecoration: 'underline',
                color: '#115180',
                fontWeight: 500,
              }}
            >
              Let me fix the data and re-upload later
            </Typography>
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default InvalidEntries;
