import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    penetrationWrapper: {
      background: '#fff',
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
      borderRadius: '8px',
      padding: '1rem 1.5rem',
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
    },
    titleText: {
      color: 'rgba(50, 71, 92, 0.87)',
      fontSize: '1.4rem !important',
    },
    filterWrapper: {
      position: 'absolute',
      zIndex: 10,
      background: '#fff',
      width: 200,
      borderRadius: '10px',
      padding: '1rem',
      right: '5px',
      top: '34px',
      boxShadow:
        '0px 2px 9px rgb(50 71 92 / 6%), 0px 4px 9px 1px rgb(50 71 92 / 4%), 0px 2px 6px 4px rgb(50 71 92 / 2%)',
    },
  })
);

export default useStyles;
