import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// import useStyles from './index.styles';

import ConversionFunnel from './ConversionFunnel';
import ChannelsGraph from './ChannelGraph';
import GoodBadContact from './ContactCircle';
import DayTimePenetration from './PenetrationGraph';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getCampaignCommStatus,
  // getCampaignExecutionList,
} from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import {
  campaignCommStatus,
  campaignExecutionId,
  // campaignExecutionList,
} from 'store/reducer/campaignReducer';
import ChannelBox from './ChannelGraph/ChannelBox';
import useLocalStorage from 'hooks/useLocalStorage';

// const channels = ['EMAIL', 'SMS', 'WHATSAPP', 'IVR'];

const CampaignDetailsPerformance = () => {
  const dispatch = useAppDispatch();
  const { id: campaignId } = useParams();

  const executionId = useAppSelector(campaignExecutionId);
  const communicationStatus = useAppSelector(campaignCommStatus);
  const { storageValue } = useLocalStorage('customer_execution_id', '');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = React.useState('EMAIL');
  const [channels, setChannels] = React.useState<Array<string>>([]);

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      if (campaignId) {
        await dispatch(
          getCampaignCommStatus({
            campaignId,
            executionId: executionId || storageValue,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [campaignId, dispatch, executionId, storageValue]);

  React.useEffect(() => {
    campaignId && fetchData();
  }, [campaignId, fetchData]);

  React.useEffect(() => {
    if (communicationStatus) {
      const {
        total_email_customers,
        total_ivr_customers,
        total_sms_customers,
        total_whatsapp_customers,
      } = communicationStatus;
      const selectedChannels = ['EMAIL', 'SMS', 'WHATSAPP', 'IVR']
        .map((item) => {
          if (item === 'EMAIL' && total_email_customers > 0) return item;
          if (item === 'SMS' && total_sms_customers > 0) return item;
          if (item === 'WHATSAPP' && total_whatsapp_customers > 0) return item;
          if (item === 'IVR' && total_ivr_customers > 0) return item;
          return '';
        })
        .filter((el) => el);
      setChannels(selectedChannels);
      setSelectedChannel(selectedChannels[0]);
    }
  }, [communicationStatus]);

  const handleChannelChange = (channel: string) => setSelectedChannel(channel);

  return (
    <>
      <Loader loading={loading} />
      <Box>
        <Box position='relative'>
          <Typography
            fontSize='1.3rem'
            color='#004357'
            align='center'
            fontWeight={700}
          >
            Performance
          </Typography>
          <Box sx={{ position: 'absolute', right: 0, top: -5 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
                justifyContent: 'center',
                paddingBottom: '12px',
              }}
            >
              {channels.map((channel) => (
                <ChannelBox
                  channel={channel}
                  selected={selectedChannel}
                  onClick={() => handleChannelChange(channel)}
                />
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem',
            marginTop: '10px',
          }}
        >
          <ChannelsGraph
            selected={selectedChannel}
            handleChannel={handleChannelChange}
          />
          <GoodBadContact channel={selectedChannel} />
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '45% 53%',
            columnGap: '1rem',
            marginTop: '2rem',
          }}
        >
          <ConversionFunnel channel={selectedChannel} />
          <DayTimePenetration selectedChannel={selectedChannel} />
        </Box>
      </Box>
    </>
  );
};

export default CampaignDetailsPerformance;
