import { LinkItUrl } from 'react-linkify-it';
import styled, { DefaultTheme } from 'styled-components';

function getTypeColor(value: string, theme: DefaultTheme) {
  if (!Number.isNaN(+value)) return '#A771FE';
  if (value === 'true') return '#00DC7D';
  if (value === 'false') return '#F85C50';
  if (value === 'null') return '#939598';
  return '#535353';
}

export const StyledLinkItUrl = styled(LinkItUrl)`
  text-decoration: underline;
  pointer-events: all;
`;

export const StyledForeignObject = styled.foreignObject<{
  hasCollapse?: boolean;
  hideCollapse?: boolean;
  isObject?: boolean;
}>`
  text-align: ${({ isObject }) => !isObject && 'center'};
  font-size: 12px;
  overflow: hidden;
  color: '#35D073';
  pointer-events: none;
  padding: ${({ isObject }) => isObject && '10px'};

  * {
    font-family: 'Roboto Mono', monospace;
  }

  &.searched {
    border: 2px solid #fff;
    border-radius: 2px;
    box-sizing: border-box;
  }

  .highlight {
    border: 2px dashed #ff2970;
    background: rgba(255, 214, 0, 0.3);
  }

  .renderVisible {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
  }
`;

function getKeyColor(
  theme: DefaultTheme,
  parent: 'array' | 'object' | false,
  objectKey: boolean
) {
  if (parent) {
    if (parent === 'array') return '#FC9A40';
    return '#BC1CEA';
  }
  if (objectKey) return '#BC1CEA';
  return '#35D073';
}

export const StyledKey = styled.span<{
  objectKey?: boolean;
  parent?: 'array' | 'object' | false;
  value?: string;
}>`
  display: inline;
  flex: 1;
  font-weight: 500;
  color: ${({ theme, objectKey = false, parent = false }) =>
    getKeyColor(theme, parent, objectKey)};
  font-size: ${({ parent }) => parent && '14px'};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ objectKey }) => !objectKey && 10}px;
`;

export const StyledRow = styled.span.attrs<{
  'data-key': string;
  theme: DefaultTheme;
}>((props) => ({
  style: {
    color: getTypeColor(props['data-key'], props.theme),
  },
}))<{ 'data-key': string; theme: DefaultTheme }>`
  display: block;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 auto;
`;

export const StyledChildrenCount = styled.span`
  color: '#fff';
  padding: 10px;
  margin-left: -15px;
`;
