import React, { useState, useEffect, useMemo } from 'react';
import { styled, Box, Switch, SwitchProps, Typography } from '@mui/material';
import { GiAlarmClock } from 'react-icons/gi';
import moment from 'moment';
import useStyles from './index.styles';

import ActionTool from './WorkflowTool/Actions';
import { getGraphData } from 'utils/workflow/graphHandler';
import BasicModal from 'components/Modal';
import {
  UnpublishedActivationModal,
  ActivatingWorkflowModal,
  DeactivatingWorkflowModal,
} from 'components/workflow/landing';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setRefetchWorkflow } from 'store/reducer/workflowReducer';
// import { userAccess } from 'utils/helper';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const ActivationSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 56,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    color: '#6F6F6F',
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#6AC5AB',
      '& + .MuiSwitch-track': {
        backgroundColor: '#D9D9D9',
        boxShadow:
          'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    boxShadow:
      'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 26 / 2,
    backgroundColor: '#D9D9D9',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const WorkflowManagementSideMenu: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const refetchWorkflow = useAppSelector(
    (state: any) => state.workflow.refetchWorkflow
  );
  // const userRole = useAppSelector((state: any) => state.auth.role);
  const permissions = useAppSelector(userPermissions);

  const graphData = getGraphData();

  const [workflowType, setWorkflowType] = useState<string>('');
  const [activeStatus, setActiveStatus] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState({
    wf_name: '',
    wf_description: '',
    schedule_to_run: '',
  });
  const [openModal, setOpenModal] = useState(false);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    permissions[MODULELIST.WORKFLOW].includes('execute') && setOpenModal(true);
  };

  useEffect(() => {
    const { active, published, wf_name, wf_description, schedule_to_run } =
      graphData;
    const type = published ? 'published' : 'draft';
    const status = active ? true : false;
    setWorkflowType(type);
    setActiveStatus(status);
    setDetailsData({ wf_name, wf_description, schedule_to_run });

    dispatch(setRefetchWorkflow(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWorkflow]);

  const handleModalClose = () => setOpenModal(false);

  const dailyScheduleTime = useMemo(() => {
    let time = '00:00';
    if (detailsData?.schedule_to_run) {
      time = moment
        .utc(detailsData.schedule_to_run, 'HH:mm:ss')
        .local()
        .format('hh:mm a');
    }
    return time;
  }, [detailsData?.schedule_to_run]);

  return (
    <Box
      sx={{
        background: '#fff',
        width: '100%',
        padding: '8px',
      }}
    >
      <Box className={classes.workflowTypeCont}>
        <Box
          className={`${classes.workflowTypeButtonStyle} ${
            workflowType === 'published'
              ? classes.workflowTypeButtonActiveStyle
              : ''
          }`}
        >
          Published
        </Box>
        <Box
          className={`${classes.workflowTypeButtonStyle} ${
            workflowType === 'draft'
              ? classes.workflowTypeButtonActiveStyle
              : ''
          }`}
        >
          Draft
        </Box>
      </Box>

      <Box className={classes.workflowDetailsCont}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
          Workflow Details
        </Typography>
      </Box>

      <Box className={classes.workflowDetailsTable}>
        <Box className={classes.workflowDetailsTableBox}>
          <Box className={classes.workflowDetailsTableBoxTitle} width={160}>
            WorkflowID
          </Box>
          <Box sx={{ marginLeft: '14px', color: '#6F6F6F' }}>
            {graphData?.workflow_id}
          </Box>
        </Box>

        <Box className={classes.workflowDetailsTableBox}>
          <Box className={classes.workflowDetailsTableBoxTitle}>
            Description
          </Box>
          <Box sx={{ marginLeft: '14px', color: '#6F6F6F' }}>
            {detailsData?.wf_description ?? '-'}
          </Box>
        </Box>

        <Box className={classes.workflowDetailsTableBox}>
          <Box className={classes.workflowDetailsTableBoxTitle}>Status</Box>
          <Box
            sx={{
              marginLeft: '14px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <ActivationSwitch
              sx={{ m: 1 }}
              checked={activeStatus}
              onChange={handleStatusChange}
            />
            <Box
              sx={{
                background: activeStatus ? '#6AC5AB' : '#B8B5B5',
                borderRadius: '7px',
                padding: '2px 16px',
                color: '#fff',
              }}
            >
              <Typography fontSize='0.9rem'>
                {activeStatus ? 'Active' : 'Inactive'}
              </Typography>
            </Box>
          </Box>
        </Box>

        {activeStatus && (
          <Box className={classes.workflowDetailsTableBox}>
            <Box className={classes.workflowDetailsTableBoxTitle}>
              Daily Schedule
            </Box>
            <Box className={classes.scheduleToRunCont}>
              <Box className={classes.scheduleToRunValue}>
                <Typography>{dailyScheduleTime}</Typography>
              </Box>
              <GiAlarmClock fontSize='25px' color='#000' />
            </Box>
          </Box>
        )}
      </Box>

      <Box>
        <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
          Workflow Tools
        </Typography>
        <Box className={classes.workflowToolsWrapper}>
          <Box className={classes.workflowToolOptionCont}>
            <Box className={classes.workflowToolActiveStyle}>Actions</Box>
          </Box>

          <ActionTool />
        </Box>
      </Box>

      <BasicModal
        open={openModal}
        onClose={handleModalClose}
        children={
          !graphData.published ? (
            <UnpublishedActivationModal
              workflowId={graphData.workflow_id}
              onClose={handleModalClose}
            />
          ) : graphData.active ? (
            <DeactivatingWorkflowModal
              data={graphData}
              onClose={handleModalClose}
            />
          ) : (
            <ActivatingWorkflowModal
              data={graphData}
              onClose={handleModalClose}
            />
          )
        }
      />
    </Box>
  );
};

export default WorkflowManagementSideMenu;
