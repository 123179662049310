import useStyles from "./style";

export type TextareaProps = {
  label?: string;
  type?: string;
  placeholder?: string;
  id?: string;
  value?: string;
  containerClass?: any;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea: React.FC<TextareaProps> = ({
  label,
  type,
  id,
  value,
  placeholder,
  onChange,
  containerClass,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.textareaMainCont} ${containerClass}`}>
      <textarea
        onChange={onChange}
        // type={type}
        id={id}
        value={value}
        placeholder={placeholder}
        className={classes.textareaStyle}
      />
    </div>
  );
};

export default Textarea;
