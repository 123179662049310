export const getKeys = (obj: any, val: string) => {
  if (obj === val) return []; // Found it!
  if (Object(obj) !== obj) return; // A primitive value, but no match
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    const result: any = getKeys(obj[key], val);
    if (result) return [key, ...result];
  }
};

export const updateWorkflow = (obj: any, keyPath: any, value: any) => {
  let lastKeyIndex = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    let key = keyPath[i];

    // choose if nested object is array or hash based on if key is number
    // eslint-disable-next-line no-self-compare
    if (!(key in obj)) obj[key] = parseInt(key) !== parseInt(key) ? {} : [];
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
};

export const findPath: any = (a: string, obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (a === obj[key]) return key;
      else if (obj[key] && typeof obj[key] === 'object') {
        let path = findPath(a, obj[key]);
        if (path) return key + '.' + path;
      }
    }
  }
};

export const getPath = (obj: any, a: string, parent: string) => {
  const path = findPath(a, obj);
  let arr = path.split('.');
  arr.pop();
  return arr.join('.') + '.haschildren.' + parent;
};

export const updateConfigObj = (object: any, path: string, value: any) => {
  const parts = path.split('.');
  const limit = parts.length - 1;
  for (let i = 0; i < limit; ++i) {
    const key = parts[i];
    object = object[key] ?? (object[key] = {});
  }
  const key = parts[limit];
  object[key] = value;
};

export const getStepValue = (obj: any, pathValue: string) => {
  for (
    let i = 0, path = pathValue.split('.'), len = path.length;
    i < len;
    i++
  ) {
    obj = obj[path[i]];
  }
  return obj;
};
