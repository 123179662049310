import { createAsyncThunk } from '@reduxjs/toolkit';

import campaignApi from '../../../apis/campaign';
import campaignEndPoint from '../../../apis/campaign/config';

export const createCampaign = createAsyncThunk(
  `${campaignEndPoint.createCampaign}Post`,
  async (payload: FormData) => {
    const { data } = await campaignApi.createCampaign(payload);
    return data;
  }
);

export const downloadTemplates = createAsyncThunk(
  `${campaignEndPoint.downloadTemplates}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.downloadTemplates(payload);
    return data;
  }
);

export const createCampaignGroup = createAsyncThunk(
  `${campaignEndPoint.createCampaignGroup}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.createCampaignGroup(payload);
    return data;
  }
);

export const createCommunicationCampaign = createAsyncThunk(
  `${campaignEndPoint.createCommunicationCampaign}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.createCommunicationCampaign(payload);
    return data;
  }
);

export const campaignGroupFilter = createAsyncThunk(
  `${campaignEndPoint.campaignGroupFilter}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignGroupFilter(payload);
    return data;
  }
);

export const campaignAddGroupFilter = createAsyncThunk(
  `${campaignEndPoint.campaignAddGroupFilter}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignAddGroupFilter(payload);
    return data;
  }
);

export const viewTemplate = createAsyncThunk(
  `${campaignEndPoint.viewTemplate}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.viewTemplate(payload);
    return data;
  }
);

export const createConfiguration = createAsyncThunk(
  `${campaignEndPoint.createConfiguration}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.createConfiguration(payload);
    return data;
  }
);

export const createScheduler = createAsyncThunk(
  `${campaignEndPoint.createScheduler}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.createScheduler(payload);
    return data;
  }
);

const summaryMock = {
  data: [
    {
      cgrId: 'd81dccf0-5f74-4e95-aae6-2810165df933',
      groupName: 'sad',
      groupDesc: 'asd',
      filterType: 'DPD is between 30 to 60 and amount with in range 1-10000',
      channelType: 'email',
      runTime: '2022-05-27T10:40:52Z',
      totalPopulation: 10,
      totalUser: 4,
    },
  ],
  meta: {
    message: 'success',
    success: true,
  },
};

export const campaignGroupGetFilter = createAsyncThunk(
  `${campaignEndPoint.campaignGroupGetFilter}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignGroupGetFilter(payload);
    return data;
  }
);

export const campaignGroupApplyFilter = createAsyncThunk(
  `${campaignEndPoint.campaignGroupApplyFilter}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignGroupApplyFilter(payload);
    return data;
  }
);

export const campaignGroupSummary = createAsyncThunk(
  `${campaignEndPoint.campaignGroupSummary}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignGroupSummary(payload);
    return data;
    // return summaryMock;
  }
);

export const triggerNow = createAsyncThunk(
  `${campaignEndPoint.triggerNow}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.triggerNow(payload);
    return data;
  }
);
