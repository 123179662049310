import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import useStyles from "./index.styles";

import SmsPreview from "./sms";
import WhatsappPreview from "./whatsapp";
import IvrPreview from "./ivr";
import WhatsappTwoWayPreview from "./twoWay/whatsapp";
import IvrTwoWayPreview from "./twoWay/ivr";
import SmsTwoWayPreview from "./twoWay/sms";

interface TemplatePreviewProps {
  selectedChannel: string;
  selectedTemplates: any;
}

const TemplatePreviews: React.FC<TemplatePreviewProps> = ({
  selectedChannel,
  selectedTemplates,
}) => {
  const classes = useStyles();

  const selectedTemplate = selectedTemplates;

  if (!selectedTemplates) {
    return (
      <Box className={classes.templatePreviewText}>
        {selectedTemplates.length > 1 ? (
          <Typography>Selected {selectedTemplates.length} files</Typography>
        ) : (
          <Typography>Select a template to view preview</Typography>
        )}
      </Box>
    );
  } else {
    if (selectedChannel === "sms") {
      if (selectedTemplate.two_way_flag) {
        return <SmsTwoWayPreview json={selectedTemplate.template_json} />;
      } else {
        return <SmsPreview selectedTemplate={selectedTemplate} />;
      }
    }
    if (selectedChannel === "whatsapp") {
      if (selectedTemplate.two_way_flag) {
        return <WhatsappTwoWayPreview json={selectedTemplate.template_json} />;
      } else {
        return <WhatsappPreview selectedTemplate={selectedTemplate} />;
      }
    }
    if (selectedChannel === "ivr") {
      if (selectedTemplate.two_way_flag) {
        return <IvrTwoWayPreview json={selectedTemplate.template_json} />;
      } else {
        return <IvrPreview selectedTemplate={selectedTemplate} />;
      }
    }
  }

  return <></>;
};

export default TemplatePreviews;
