import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@mui/material';

export const ChatButton = styled('div')({
  background: '#004357',
  color: '#fff',
  padding: '8px 14px 6px',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '6px',
  display: 'inline-block',
  cursor: 'pointer',
  '& p': {
    fontSize: '0.9rem',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
      borderRadius: '0px 0px 8px 8px',
    },
    chatHeader: {
      background: theme.kollectAITheme.primaryColor,
      color: '#fff',
      padding: '1rem',
      '& p': { fontSize: '0.9rem' },
    },
    endChatBtn: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      background: 'rgba(215, 61, 0, 0.2)',
      border: '1px solid #D73D00',
      borderRadius: '20px',
      padding: '4px 10px',
      cursor: 'pointer',
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    waveBox: {
      height: '2.5rem',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      margin: '2rem auto',
    },
    contentWrapper: {
      background: 'rgba(50, 71, 92, 0.04)',
      paddingBottom: '1rem',
      height: 'calc(100vh - 260px)',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  })
);

export default useStyles;
