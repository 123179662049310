import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { getGraphData } from 'utils/workflow/graphHandler';

interface CustomFormikWorkflowInputProps {
  name: string;
  label?: string;
  type?: string;
  labelTag?: string;
  noMargin?: boolean;
  disabled?: boolean;
  inputWidth?: string;
  placeholder?: string;
}

interface FormikWorkflowInputWrapperProps {
  mb?: string | number;
  inputWidth?: string;
}

const FormikWorkflowInputWrapper = styled('div')(
  (props: FormikWorkflowInputWrapperProps) => ({
    marginBottom: props.mb,
    width: '100%',

    '& input': {
      border: '1px solid #EBEBEB',
      borderRadius: '4px',
      padding: '0 0.5rem',
      fontFamily: 'inherit',
      outline: 'none',
      width: props.inputWidth ? props.inputWidth : '94%',
      background: 'inherit',
      color: '#fff',
      fontSize: '16px',
      minHeight: '38px',
    },
  })
);

const CustomFormikWorkflowInput: React.FC<CustomFormikWorkflowInputProps> = ({
  name,
  label,
  type = 'text',
  labelTag,
  noMargin,
  disabled,
  inputWidth,
  placeholder = '',
}) => {
  const graphData = getGraphData();

  return (
    <FormikWorkflowInputWrapper
      mb={`${noMargin ? 0 : '1rem'}`}
      inputWidth={inputWidth}
    >
      {label && (
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            marginBottom: '0.3rem',
          }}
        >
          <Typography fontSize='1rem'>{label}</Typography>
          {labelTag && (
            <Typography fontSize='0.7rem'>{`(${labelTag})`}</Typography>
          )}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <Box>
            <input
              type={type}
              placeholder={placeholder}
              {...field}
              disabled={
                disabled ? disabled : graphData.published === 1 ? true : false
              }
            />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        )}
      </Field>
    </FormikWorkflowInputWrapper>
  );
};

export default CustomFormikWorkflowInput;
