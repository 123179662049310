import { Box, Typography } from '@mui/material';
import moment from 'moment';

const ViewHistory = ({ notes }: { notes: any }) => {
  return (
    <>
      {notes && notes.length ? (
        <Box sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {notes?.map((note: any) => (
              <Box key={note.created_at}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <Typography width={120}>Created at</Typography>
                  <Typography>
                    {moment(note?.created_at).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <Typography width={120}>Note</Typography>
                  <Typography>{note?.notes}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: '100px',
            width: '100%',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Typography>Please add a note to view history</Typography>
        </Box>
      )}
    </>
  );
};
export default ViewHistory;
