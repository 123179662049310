import { Box, Typography } from '@mui/material';
import BasicSelect from 'components/Select';
import { SlotDuration } from '.';
import useStyles from './style';

const slotOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '6', value: '6' },
  { label: '8', value: '8' },
  { label: '12', value: '12' },
  { label: '24', value: '24' },
];

const durationOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
];

const PenetrationFilter = ({
  slot,
  limit,
  setLimit,
  setSlot,
  setOpenFilter,
}: {
  slot: SlotDuration;
  limit: number;
  setSlot: (x: any) => void;
  setLimit: (x: number) => void;
  setOpenFilter: (x: boolean) => void;
}) => {
  const classes: any = useStyles();

  return (
    <Box className={classes.filterWrapper}>
      <Typography fontSize='1.1rem' mb='1rem'>
        Filter
      </Typography>
      <Box className={classes.flexBetween}>
        <Typography>Time-slot</Typography>
        <BasicSelect
          value={String(slot)}
          options={slotOptions}
          onChange={(slot) => {
            setSlot(+slot);
            setOpenFilter(false);
          }}
          size='sm'
        />
      </Box>
      <Box mt='10px' className={classes.flexBetween}>
        <Typography>Duration</Typography>
        <BasicSelect
          value={String(limit)}
          options={durationOptions}
          onChange={(limit) => {
            setLimit(+limit);
            setOpenFilter(false);
          }}
          size='sm'
        />
      </Box>
    </Box>
  );
};

export default PenetrationFilter;
