import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { Typography } from '@material-ui/core';
import ChannelSelectBox from './ChannelSelectBox';
import useStyles from './index.styles';
import { concatenatedTemplate } from 'utils/helper';

interface DPDType {
  id: string;
  dpd: number;
  email: boolean;
  ivr: boolean;
  sms: boolean;
  whatsapp: boolean;
  template_email: string;
  template_ivr: string;
  template_whatsapp: string;
  template_sms: string;
}

const ChannelLevelDetails = () => {
  const classes = useStyles();
  const [strategyData, setStrategyData] = useState([]);

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );
  const bucket = strategyDetailsData?.strategy_summary?.bucket;

  const emailTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.emailTemplate) || []
  );
  const smsTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.smsTemplate) || []
  );
  const ivrTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.ivrTemplate) || []
  );
  const whatsappTemplate = useAppSelector(
    (state: any) => concatenatedTemplate(state.template.whatsappTemplate) || []
  );

  useEffect(() => {
    setStrategyData(strategyDetailsData.strategy_distribution);
  }, [strategyDetailsData]);

  return (
    <div className={classes.channelLevelDetailsWrapper}>
      <Typography variant='subtitle1'>Channel Level Details</Typography>
      <div className={classes.channelDetailsWrapper}>
        {strategyData
          ?.slice()
          ?.sort((a: DPDType, b: DPDType) => a.dpd - b.dpd)
          ?.map((item: any) => {
            return (
              <div key={item.id} className={classes.channelDetailsItemWrapper}>
                <Typography variant='overline'>dpd {item.dpd}</Typography>
                <ChannelSelectBox
                  channel='ivr'
                  channelValue={item.ivr}
                  channelTemplate={ivrTemplate ?? []}
                  selectedTemplate={item.template_ivr}
                  dpd={item.dpd}
                  item={item}
                />
                <ChannelSelectBox
                  channel='email'
                  channelValue={item.email}
                  channelTemplate={emailTemplate ?? []}
                  selectedTemplate={item.template_email}
                  dpd={item.dpd}
                  item={item}
                />
                <ChannelSelectBox
                  channel='sms'
                  channelValue={item.sms}
                  channelTemplate={smsTemplate ?? []}
                  selectedTemplate={item.template_sms}
                  dpd={item.dpd}
                  item={item}
                />
                <ChannelSelectBox
                  channel='whatsapp'
                  channelValue={item.whatsapp}
                  channelTemplate={whatsappTemplate ?? []}
                  selectedTemplate={item.template_whatsapp}
                  dpd={item.dpd}
                  item={item}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ChannelLevelDetails;
