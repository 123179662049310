import { styled } from '@mui/material';

export const PermissionNameWrapper = styled('div')(() => ({
  padding: '1rem 1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& input': {
    border: '1px solid rgba(50, 71, 92, 0.22)',
    borderRadius: '6px',
    fontSize: '1rem',
    width: '350px',
    padding: '10px',
    outline: 'none',
    color: '#32475CDE',
  },
}));

export const PermissionEdit = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
  '& svg': { color: '#32475C8A', fontSize: '1.2rem' },
}));

export const ActionButtons = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  margin: '1rem 0 1rem 1rem',
}));
