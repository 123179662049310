import { Box, Typography } from '@mui/material';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import useStyles, { SplitBox } from './index.styles';

import { SplitType } from './abTesting';

const AbTestingSplits = ({
  splits,
  setSplits,
}: {
  splits: SplitType[];
  setSplits: (x: SplitType[]) => void;
}) => {
  const classes = useStyles();

  const onSplitAdd = () => {
    if (splits.length < 4) {
      let newSplits: any;
      if (splits.length === 2) {
        newSplits = [
          { split: 'A', value: 33 },
          { split: 'B', value: 33 },
          { split: 'C', value: 34 },
        ];
      }
      if (splits.length === 3) {
        newSplits = [
          { split: 'A', value: 25 },
          { split: 'B', value: 25 },
          { split: 'C', value: 25 },
          { split: 'D', value: 25 },
        ];
      }
      setSplits(newSplits);
    }
  };

  const onSplitRemove = () => {
    if (splits.length > 2) {
      let newSplits: any;
      if (splits.length === 4) {
        newSplits = [
          { split: 'A', value: 33 },
          { split: 'B', value: 33 },
          { split: 'C', value: 34 },
        ];
      }
      if (splits.length === 3) {
        newSplits = [
          { split: 'A', value: 50 },
          { split: 'B', value: 50 },
        ];
      }
      setSplits(newSplits);
    }
  };

  const getSplitDistribution = (index: number, value: number) => {
    const totalPerviousDistribution = splits
      .slice(0, index)
      .reduce((acc, current) => (acc += current.value), 0);

    if (index === 0) return `0 - ${value}`;
    if (index + 1 === splits.length)
      return `${
        totalPerviousDistribution !== 100
          ? totalPerviousDistribution + 1
          : totalPerviousDistribution
      } - 100`;
    return `${totalPerviousDistribution + 1} - ${
      totalPerviousDistribution + value
    }`;
  };

  const onSplitChange = (split: string, percentage: number) => {
    const lastSplit = splits.at(-1)!.split;
    const unchangedSplitPercent = splits
      .filter((item) => ![split, lastSplit].includes(item.split))
      .reduce((acc, current) => (acc += current.value), 0);

    if (percentage <= 100 - unchangedSplitPercent && percentage >= 0) {
      let newSplits = [...splits];
      newSplits = newSplits.map((newSplit) => {
        if (newSplit.split === split) return { split, value: percentage };
        if (newSplit.split === lastSplit)
          return {
            split: lastSplit,
            value: 100 - unchangedSplitPercent - percentage,
          };
        return newSplit;
      });
      setSplits(newSplits);
    }
  };

  return (
    <>
      <Box mb='1rem'>
        <Box>
          <Typography mb='1rem'>Select testing population split</Typography>

          <Box px='1rem'>
            {splits.map((split, ind) => {
              const isLastSplit = ind === splits.length - 1;
              return (
                <SplitBox key={`${split.split}-${ind}`}>
                  <label>
                    <Typography>{split.split}:</Typography>
                  </label>

                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    <Box className={classes.splitPercentInput}>
                      <input
                        type='number'
                        value={split.value}
                        disabled={isLastSplit}
                        onChange={(e) =>
                          onSplitChange(split.split, Number(e.target.value))
                        }
                      />
                      <Typography fontFamily='Overpass'>%</Typography>
                    </Box>

                    {isLastSplit && (
                      <Box className={classes.splitActionButtons}>
                        {splits.length < 4 && (
                          <AiFillPlusCircle
                            color='#6ac5ab'
                            onClick={onSplitAdd}
                          />
                        )}
                        {splits.length > 2 && (
                          <AiFillMinusCircle
                            color='#DAA09A'
                            onClick={onSplitRemove}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </SplitBox>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontStyle: 'italic', color: '#A8ABC3' }}>
          Population Distribution
        </Typography>

        <Box mb='0.5rem'>
          {splits.map((split, index) => (
            <Box key={split.split} className={classes.splitDistributionCont}>
              <Typography>Population {split.split}</Typography>
              <Typography>
                {getSplitDistribution(index, split.value)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default AbTestingSplits;
