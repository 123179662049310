export const kollectAiCampaign = {
  createCampaign: '/kollectai/api/v1/campaign/',
  downloadTemplates: '/kollectai/api/v1/campaign/download-sample-template',
  createCampaignGroup: '/kollectai/api/v1/campaign-group/add-group',
  createCommunicationCampaign:
    '/kollectai/api/v1/communication/create-communication-campaign',
  campaignGroupFilter: '/kollectai/api/v1/campaign-group/group-filter',
  campaignAddGroupFilter: '/kollectai/api/v1/campaign-group/add-group-filter',
  viewTemplate: '/kollectai/api/v1/template/view-template',
  createConfiguration: '/kollectai/api/v1/campaign-group/create-configuration',
  createScheduler: '/kollectai/api/v1/campaign-group/create-scheduler',
  campaignGroupGetFilter: '/kollectai/api/v1/campaign-group/get-filter',
  campaignGroupApplyFilter: '/kollectai/api/v1/campaign-group/apply-filter',
  campaignGroupSummary: '/kollectai/api/v1/campaign-group/summary',
  triggerNow: '/kollectai/api/v1/campaign-group/trigger-now',

  // Campaign V2
  campaignSummary: '/kollectai/api/v1/campaign_v2/campaign_summary',
  createCampaignV2: '/kollectai/api/v1/campaign_v2/create_campiagn',
  addABTesting: '/kollectai/api/v1/campaign_v2/add_ab_testing',
  addChannelTemplate: '/kollectai/api/v1/campaign_v2/add_channel_template',
  groupBasedCustomerData:
    '/kollectai/api/v1/campaign_v2/customer_data_based_on_group',
  campaignDetails: '/kollectai/api/v1/campaign_v2/get-campaign-details',
  campaignCustomFilter: '/kollectai/api/v1/campaign_v2/add_campaign_filter',
  addCampaignAbTesting: '/kollectai/api/v1/campaign_v2/add_ab_testing',
  deleteCampaign: '/kollectai/api/v1/campaign_v2/delete_campaign',
  rerunCampaign: '/kollectai/api/v1/campaign_v2/campaign_rerun',
  campaignFilterAttributes: '/kollectai/api/v1/campaign_v2/filter_attributes',
  copyCampaignWithoutFile:
    '/kollectai/api/v1/campaign_v2/copy_campaign_without_file_upload',
  campaignCommStatus:
    '/kollectai/api/v1/campaign_v2/get-communication-status-counts',
  dayTimePenetration: '/kollectai/api/v1/campaign_v2/day-vs-time',
  changeBasedOn: '/kollectai/api/v1/campaign_v2/change_based_on_group_type',
  campaignData: '/kollectai/api/v1/campaign_v2/search_campaign',
  campaignExecutionList:
    '/kollectai/api/v1/campaign_v2/get_campaign_executions_list',
  campaignCustomerDetail:
    '/kollectai/api/v1/campaign_v2/get_specific_customer_detail',
  customerResegmentation:
    '/kollectai/api/v1/campaign_v2/customer_resegmentation',
};

export default kollectAiCampaign;
