import { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import CustomSelectBox from 'components/strategyDetails/customSelectBox';
import useStyles from './index.styles';

import { getStrategyDetails } from 'store/reducer/strategyReducer/action';
import {
  changeActiveAmount,
  changeActiveDpd,
  changeActiveProduct,
} from 'store/reducer/strategyReducer';

interface StrategyDetailsProps {
  strategy?: any;
  strategyGroup?: any;
}

const StrategyDetailsHeader = ({
  strategy,
  strategyGroup,
}: StrategyDetailsProps) => {
  const classes = useStyles();
  const [productLabels, setProductLabels] = useState<string[]>([]);
  const [dpdLabels, setDpdLabels] = useState<string[]>([]);
  const [balanceLabels, setBalanceLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<string>('');
  const [dpd, setDpd] = useState<string>('');
  const [balance, setBalance] = useState<string>('');

  const groupId = strategyGroup?.strategy_group_id;

  const filterData = useAppSelector(
    (state: any) => state.uploadedFileDetail.groupFilterList
  );

  const { activeDpd, activeProduct, activeAmount }: any = useAppSelector(
    (state: any) => state.strategy
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setProduct(strategy?.product || '');
    setDpd(filterData?.DPD[strategy.bucket - 1] || '');
    setBalance(strategy?.balance_level || '');
  }, [strategy, filterData]);

  useEffect(() => {
    if (activeDpd !== dpd) {
      dispatch(changeActiveDpd(dpd));
    }
    if (activeProduct !== product) {
      dispatch(changeActiveProduct(product));
    }
    if (activeAmount !== balance) {
      dispatch(changeActiveAmount(balance));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpd, product, balance]);

  useEffect(() => {
    if (filterData) {
      if (strategyGroup?.segment?.bucket[0]) {
        const dpdLabels =
          strategyGroup?.segment?.bucket?.map(
            (eachBucket: number) => filterData?.DPD[eachBucket - 1]
          ) || [];
        setDpdLabels(dpdLabels);
      } else {
        setDpdLabels(['All']);
      }

      if (!['0', null].includes(strategyGroup?.segment?.product[0])) {
        setProductLabels(
          strategyGroup?.segment?.product ||
            Object.values(filterData?.PRODUCT ?? {})
        );
      } else {
        setProductLabels(['All']);
      }

      if (!['0', null].includes(strategyGroup?.segment?.balance_level[0])) {
        setBalanceLabels(
          strategyGroup?.segment?.balance_level ||
            Object.values(filterData?.AMOUNT ?? {})
        );
      } else {
        setBalanceLabels(['All']);
      }
    }
  }, [filterData]);

  const filterChange = async (title: string, value: string) => {
    setLoading(true);

    let filteredProduct = product;
    let filteredDpd = dpd;
    let filteredBalance = balance;
    if (title === 'Product') {
      filteredProduct = value;
    }
    if (title === 'DPD Category') {
      filteredDpd = value;
    }
    if (title === 'Balance Level') {
      filteredBalance = value;
    }
    const bucket =
      Number(
        Object.keys(filterData.DPD).find(
          (key) => filterData.DPD[key] === filteredDpd
        )
      ) + 1;

    const balanceLevel = ['0', null, 'All'].includes(filteredBalance)
      ? '0'
      : filteredBalance;
    const productLabel = ['0', null, 'All'].includes(filteredProduct)
      ? '0'
      : filteredProduct;
    const bucketLabel = bucket ? bucket : 0;

    const query = `strategy_group_id=${groupId}&balance_level=${balanceLevel}&product=${productLabel}&bucket=${bucketLabel}`;
    await dispatch(getStrategyDetails({ query }));
    setLoading(false);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <div className={classes.strategyDetailsHeader}>
        <CustomSelectBox
          title='Product'
          labels={productLabels}
          filterChange={filterChange}
          value={product}
          setValue={setProduct}
        />
        <CustomSelectBox
          title='DPD Category'
          labels={dpdLabels}
          filterChange={filterChange}
          value={dpd}
          setValue={setDpd}
        />
        <CustomSelectBox
          title='Balance Level'
          labels={balanceLabels}
          filterChange={filterChange}
          value={balance}
          setValue={setBalance}
        />
      </div>
    </div>
  );
};

export default StrategyDetailsHeader;
