import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import BasicModal from 'components/Modal';
// import { kollectAITheme } from 'config/theme';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  setAddBranch,
  setWorkflowActionData,
  setWorkflowEditor,
  setWorkflowType,
  toggleDrawer,
} from 'store/reducer/workflowReducer';
import {
  getActionName,
  getGraphData,
  setWorkflowAction,
} from 'utils/workflow/graphHandler';
import useStyles from './index.styles';
import StrategyDetails from 'components/workflow/drawer/runStrategy/strategy';
import { useNavigate } from 'react-router-dom';
import { getWorkflowBoxValue } from 'utils/helper';
// import { MdClear } from 'react-icons/md';
import { BiPlus } from 'react-icons/bi';
import {
  getSpecificFunnel,
  workflowCustomerDetails,
} from 'store/reducer/workflowReducer/action';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
// import Loader from 'components/loader';

interface GraphBoxPropTypes {
  name?: string;
  [x: string]: any;
}

const GraphBox: React.FC<GraphBoxPropTypes> = ({ name, ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const userEmail = useAppSelector((state: any) => state.auth.email);
  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowFunnel: any = useAppSelector(
    (state: any) => state.workflow.workflowFunnel
  );
  const strategyList = useAppSelector(
    (state: any) => state.strategy.strategyList
  );
  const funnelMapping = useAppSelector(
    (state: any) => state.workflow.funnelMapping
  );
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const { type, parent } = props.text as any;
  const {
    title,
    icon,
    color,
    img: iconImg,
  } = getWorkflowBoxValue(type, parent);
  const classes = useStyles({ type });

  const funnelData: any = useAppSelector((state) => state.workflow.funnelData);

  // const [loading, setLoading] = useState<boolean>(false);
  const [strategyModal, setStrategyModal] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [customerNumber, setCustomerNumber] = useState<number>(0);

  const workflowGraphData = getGraphData();

  useEffect(() => {
    const { text } = props;
    if (funnelMapping && funnelMapping.length && typeof text === 'object') {
      const customerObj = funnelMapping.find(
        (item: any) =>
          // item.step_name === text.parent && item.out_come === text.parentValue
          item.step_name === text.name
      );
      if (customerObj) {
        setCustomerNumber(customerObj.number_of_customers);
      } else {
        setCustomerNumber(0);
      }
    }
  }, [funnelMapping, props]);

  const handleBoxClick = async (isDelete: boolean) => {
    if (!isDelete) {
      if (type === 'RUN_STRATEGY' && workflowGraphData.published) {
        dispatch(setWorkflowActionData(props.text));
        setWorkflowAction('workflowActionData', props.text);
        dispatch(setWorkflowType(type));

        const strategyGroup = strategyList.find(
          (list: any) => list.strategy_group_name === props.text.strategy
        );
        const group =
          strategyGroup ??
          strategyList.find(
            (list: any) => list.strategy_group_name === 'default_strategy'
          );
        setSelectedGroup(group);
        setStrategyModal(true);
        // } else if (type || name === 'end') {
      } else if (type) {
        dispatch(setWorkflowEditor('edit'));
        dispatch(setWorkflowActionData(props.text));
        setWorkflowAction('workflowActionData', props.text);
        // name === 'end'
        type === 'end'
          ? dispatch(setWorkflowType('END'))
          : dispatch(setWorkflowType(type));
        dispatch(toggleDrawer(true));
      }
    }
  };

  const handleBranchAdd = () => {
    const { parent, parentValue } = props.child;
    const data = { name: '', parent, parentValue };

    dispatch(setWorkflowEditor('create'));
    dispatch(setWorkflowType(''));
    dispatch(setWorkflowActionData(data));
    setWorkflowAction('workflowActionData', data);
    dispatch(toggleDrawer(true));
    dispatch(setAddBranch(true));
  };

  const handleView = async () => {
    // setLoading(true);
    // try {
    const { date, executionId, workflowId } = funnelData;
    const stepName = props.text.name;
    const { result } = await dispatch(
      getSpecificFunnel({ date, executionId, workflowId, stepName })
    ).unwrap();
    const customerIds = result?.map((item: any) => item?.odf_customer_id);
    if (customerIds.length) {
      let ids = customerIds.reduce((acc: string, val: string, ind: number) => {
        if (customerIds.length === 1) return `[${val}]`;
        if (ind === 0) return `[${val}, `;
        if (ind === customerIds.length - 1) return `${acc}${val}]`;
        return `${acc}${val}, `;
      }, '');
      await dispatch(workflowCustomerDetails({ customerIds: ids }));
      navigate(`/customer-management?workflow_id=${workflowId}`);
    }
    // } finally {
    //   setLoading(false);
    // }
  };

  const actionName = name && getActionName(name);

  // const userPermissions = userAccess(userRole)?.workflow.permissions;
  const permissions = useAppSelector(userPermissions);

  return (
    <>
      {/* <Loader loading={loading} /> */}
      {type === 'END' ? (
        <Box
          className={classes.graphBoxWrapperStyle}
          onClick={() => handleBoxClick(false)}
        >
          {actionName}
        </Box>
      ) : (
        <>
          <Box
            className={classes.graphBoxContainer}
            sx={{ borderBottom: type && `5px solid ${color}` }}
            onClick={() => handleBoxClick(false)}
          >
            {type && (
              <Box className={classes.graphIconContainer}>
                {iconImg ? (
                  <img src={iconImg} alt='' width={30} />
                ) : (
                  <CustomIcon name={icon} size={25} color={color} />
                )}
              </Box>
            )}

            <Box sx={{ padding: '5px 10px' }}>
              {type && (
                <Typography
                  sx={{
                    margin: '0 0 5px 0',
                    fontSize: '12px',
                    fontWeight: 700,
                  }}
                >
                  {title}
                </Typography>
              )}

              <Box
                display='flex'
                alignItems='center'
                justifyContent={
                  !type &&
                  // userEmail === 'aayushi@opendatafabric.com' &&
                  !workflowGraphData.published
                    ? 'center'
                    : 'start'
                }
              >
                <Typography m={0} fontSize='10px'>
                  {actionName}
                </Typography>
                {!type &&
                  // userPermissions.includes('update') &&
                  permissions[MODULELIST.WORKFLOW].includes('update') &&
                  !workflowGraphData.published && (
                    <Box
                      className={classes.addStepIconBox}
                      onClick={handleBranchAdd}
                    >
                      <Box className={classes.addStepIconCont}>
                        <BiPlus color='#00C087' />
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
          {workflowFunnel.viewFunnel && workflowFunnel.workflowId && type && (
            <Box className={classes.customerNumberCont}>
              <Typography>{customerNumber}</Typography>
              <Typography>
                <button onClick={handleView}>View List</button>
              </Typography>
            </Box>
          )}
        </>
      )}

      <BasicModal
        open={strategyModal}
        onClose={() => setStrategyModal(false)}
        children={
          <Box width='95vw' height='80vh' overflow='auto'>
            <Typography
              align='center'
              fontWeight={600}
              fontSize='1.2rem'
              mb='0.5rem'
            >
              Strategy: {workflowActionData?.strategy}
            </Typography>
            <StrategyDetails strategyGroup={selectedGroup} />
          </Box>
        }
      />
    </>
  );
};

export default GraphBox;
