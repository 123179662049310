import campaignManagerImg from 'assets/campaign-manager.svg';
import strategyMangerImg from 'assets/strategy-manager.svg';
import workflowMangerImg from 'assets/workflow-manager.svg';
import templateManagerImg from 'assets/template-manager.svg';
import AbTestingImg from 'assets/ab-testing.svg';
import contactCenterImg from 'assets/contact-center.svg';
import customerMmgtImg from 'assets/customer-management.svg';
import smartCollectImg from 'assets/smart-contract.svg';

export const managementData = [
  {
    name: 'template manager',
    description: [
      'Supports Email, SMS, whatsapp, IVR',
      'Create, edit or copy templates',
      'Two way communication templates',
    ],
    img: templateManagerImg,
    url: '/template-management',
  },
  {
    name: 'strategy manager',
    description: [
      'Create a short term or long term strategy journey for customers',
      'Build segmented approach for your customer base',
    ],
    img: strategyMangerImg,
    url: '/strategy',
  },
  {
    name: 'workflow manager',
    description: [
      'An end-to-end workflow in one single view for all your business needs',
    ],
    img: workflowMangerImg,
    url: '/workflow',
  },

  {
    name: 'campaign manager',
    description: [
      'Send communication using multiple channels with respective templates',
      'Trigger or schedule the Campaign',
    ],
    img: campaignManagerImg,
    url: '/campaigns',
  },
  {
    name: 'customer management',
    description: [
      'Get status of all your customers and manage in a single dashboard by reviewing their current behaviour and payment action',
    ],
    img: customerMmgtImg,
    url: '/customer-management',
  },
  {
    name: 'ab testing',
    description: [
      'Easy to choose different strategy, workflow or campaign to do AB Testing',
      'Run with same configuration ',
    ],
    img: AbTestingImg,
    url: '/ab-testing',
  },
  {
    name: 'contact center',
    description: [
      'Set up agents and get them onboard to your customised contact centre',
    ],
    img: contactCenterImg,
    url: 'https://master.d3qpxcsqvkamhx.amplifyapp.com',
  },
  {
    name: 'smart collect',
    description: [
      'Get maximum benefit by adding models in your segmentation and treatment strategy',
    ],
    img: smartCollectImg,
    url: '/smart-collect',
  },
];
