import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PreferencesBox from './PreferenceBox';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useParams } from 'react-router-dom';
import {
  getCustomerPreferences,
  updateCustomerPreferences,
} from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';

const channels = ['email', 'sms', 'whatsapp', 'ivr'];

const CustomerPreferences = () => {
  const [subscription, setsubscription] = useState<any>({
    email: true,
    sms: true,
    whatsapp: false,
    ivr: false,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { id: customerId = '' } = useParams<{ id: string }>();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);

  const fetchCustomerPreferences = useCallback(async () => {
    if (customerOdfId && customerId) {
      try {
        setLoading(true);
        const { payload } = await dispatch(
          getCustomerPreferences({ customerId, odf_customer_id: customerOdfId })
        );
        if (payload) {
          setsubscription({
            email: !!payload?.email_subscribed,
            sms: !!payload?.sms_subscribed,
            whatsapp: !!payload?.whatsapp_subscribed,
            ivr: !!payload?.ivr_subscribed,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, customerId, customerOdfId]);

  useEffect(() => {
    fetchCustomerPreferences();
  }, [fetchCustomerPreferences]);

  const handleSubscription = async (
    event: React.ChangeEvent<HTMLInputElement>,
    channel: string
  ) => {
    try {
      setLoading(true);
      const { checked } = event.target;
      const newSubscription = { ...subscription, [channel]: checked };
      const payload = {
        ...newSubscription,
        customer_id: customerId,
        odf_customer_id: customerOdfId,
      };
      await dispatch(updateCustomerPreferences(payload));
      setsubscription(newSubscription);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box my='2rem'>
        {/* <PreferencesBox
          channel='email'
          subscription={subscription.email}
          onSubscriptionChange={handleSubscription}
        />
        <PreferencesBox
          channel='sms'
          subscription={subscription.sms}
          onSubscriptionChange={handleSubscription}
        />
        <PreferencesBox
          channel='whatsapp'
          subscription={subscription.whatsapp}
          onSubscriptionChange={handleSubscription}
        />
        <PreferencesBox
          channel='ivr'
          subscription={subscription.ivr}
          onSubscriptionChange={handleSubscription}
        /> */}
        {channels.map((channel) => (
          <PreferencesBox
            channel={channel}
            subscription={subscription?.[channel]}
            onSubscriptionChange={handleSubscription}
            key={channel}
          />
        ))}
      </Box>
    </>
  );
};

export default CustomerPreferences;
