import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import './styles.css';

import StrategyDetailsHeader from 'components/strategy/landing/header';
import {
  CreateStrategyModal,
  StrategyDetailsTable,
} from 'components/strategy/landing';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  copyStrategy,
  deleteStrategy,
  getStrategyList,
} from 'store/reducer/strategyReducer/action';
import BasicModal from 'components/Modal';
import Loader from 'components/loader';
import DeleteStrategy from 'components/strategy/landing/deleteStrategy';
import EditStrategy from 'components/strategy/landing/editModal';
import DuplicateStrategy from 'components/strategy/landing/duplicateModal';
import { isStrategyWorkflowDeletable } from 'utils/helper';
import CustomModal from 'components/Modal/CustomModal';

const StrategyScreenV2 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [strategyData, setStrategyData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [openForceDelete, setOpenForceDelete] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<
    { strategy: string; usedList: string[] }[]
  >([]);

  const strategyList =
    useAppSelector((state: any) => state.strategy.strategyList) || [];

  const selectedStrategyNames = selected.reduce(
    (acc: Array<string>, curr: string) => {
      let strategyDetail = strategyList.find(
        (strategy: any) => strategy.strategy_group_id === curr
      );
      if (strategyDetail) {
        return [...acc, strategyDetail.strategy_group_name];
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    fetchStrategyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStrategyList = async () => {
    try {
      setLoading(true);
      const res = await dispatch(getStrategyList()).unwrap();
      setLoading(false);
      return res;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCreateModalClose = () => setOpenCreateModal(false);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);
  const handleDeleteModalOpen = () => setOpenDeleteModal(true);

  const handleCopyModalClose = () => setOpenCopyModal(false);
  const handleCopyModalOpen = () => setOpenCopyModal(true);

  const handleEditModalClose = () => setOpenEditModal(false);
  const handleEditModalOpen = () => setOpenEditModal(true);

  const handleDeleteStrategy = async () => {
    try {
      const { data, meta } = await dispatch(
        deleteStrategy({ group_id: selected })
      ).unwrap();
      if (meta.success) {
        const { deletable, usedList } = isStrategyWorkflowDeletable(data);

        if (!deletable) {
          setDeleteData(usedList);
          setOpenDeleteModal(false);
          setOpenForceDelete(true);
        } else {
          await dispatch(
            deleteStrategy({ group_id: selected, force_delete: true })
          ).unwrap();
          await fetchStrategyList();
          setOpenDeleteModal(false);
          setSelected([]);
        }
      }
    } catch (error) {
      setOpenDeleteModal(false);
    }
  };

  const handleForceDelete = async () => {
    setLoading(true);
    try {
      await dispatch(
        deleteStrategy({ group_id: selected, force_delete: true })
      ).unwrap();
      await fetchStrategyList();
      setOpenForceDelete(false);
      setSelected([]);
    } catch (error) {}
    setLoading(false);
  };

  const handleDuplicateStrategy = async (strategy_group_name: string) => {
    const selectedStrategyGroupId = strategyList.find(
      (strategy: any) => strategy.strategy_group_id === selected[0]
    );
    if (selectedStrategyGroupId) {
      setLoading(true);
      const payload = {
        strategy_group_name,
        base_strategy_group_id: selectedStrategyGroupId.strategy_group_id,
      };
      try {
        const { strategy_group_id, strategy_group_name } = await dispatch(
          copyStrategy(payload)
        ).unwrap();
        if (strategy_group_id) {
          const { data } = await fetchStrategyList();
          const strategy = data.find(
            (el: any) => el?.strategy_group_id === strategy_group_id
          );
          if (strategy) {
            const { strategy_detail } = strategy;
            navigate(
              `/strategy-details/${strategy_detail[0]?.strategy_id}?group_id=${strategy_group_id}`,
              {
                state: {
                  groupName: strategy_group_name,
                  strategyName: strategy_detail[0]?.strategy_name,
                  groupId: strategy_group_id,
                },
              }
            );
          }
        }
        setOpenCopyModal(false);
        setLoading(false);
      } catch (error) {
        // setOpenCopyModal(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box>
        <Loader loading={loading} />
        <Typography mt='1rem' fontSize={'1.8rem'} textAlign='center'>
          Strategy Manager
        </Typography>

        <Box sx={{ margin: '2rem 2rem 0' }}>
          <StrategyDetailsHeader
            onEditClick={() => {}}
            setOpenCreateModal={setOpenCreateModal}
            handleDeleteModalOpen={handleDeleteModalOpen}
            handleCopyModalOpen={handleCopyModalOpen}
            handleEditModalOpen={handleEditModalOpen}
            strategySelected={selected}
          />
          <StrategyDetailsTable
            data={strategyData}
            setStrategyData={setStrategyData}
            setSelected={setSelected}
            selected={selected}
          />
        </Box>

        <BasicModal
          disableEnforceFocus
          open={openCopyModal}
          onClose={handleCopyModalClose}
          children={
            <DuplicateStrategy
              strategyNames={selectedStrategyNames}
              handleDuplicateStrategy={handleDuplicateStrategy}
              close={handleCopyModalClose}
            />
          }
        />

        <BasicModal
          disableEnforceFocus
          open={openEditModal}
          onClose={handleEditModalClose}
          children={
            <EditStrategy
              strategyNames={selectedStrategyNames}
              close={handleEditModalClose}
            />
          }
        />

        <BasicModal
          disableEnforceFocus
          open={openDeleteModal}
          onClose={handleDeleteModalClose}
          children={
            <DeleteStrategy
              strategyNames={selectedStrategyNames}
              handleDeleteStrategy={handleDeleteStrategy}
              close={handleDeleteModalClose}
            />
          }
        />

        <BasicModal
          disableEnforceFocus
          open={openCreateModal}
          onClose={handleCreateModalClose}
          children={<CreateStrategyModal close={handleCreateModalClose} />}
        />
      </Box>

      <CustomModal
        header='Confirm Delete'
        open={openForceDelete}
        onClose={() => setOpenForceDelete(false)}
        children={
          <Box>
            {deleteData?.map((data) => {
              return (
                <Box mb='1rem'>
                  <Typography>
                    The strategy{' '}
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '1.2rem',
                        fontWeight: 600,
                        display: 'inline',
                      }}
                    >
                      {data?.strategy}
                    </Typography>{' '}
                    is used in following workflows:
                  </Typography>
                  {data?.usedList.map((item) => (
                    <ListItem sx={{ pt: 0, pb: 0 }}>
                      <ListItemText
                        primary={item}
                        sx={{ display: 'list-item' }}
                      />
                    </ListItem>
                  ))}
                </Box>
              );
            })}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                marginTop: '2rem',
                '& button': {
                  color: '#fff',
                  padding: '8px 4rem',
                  borderRadius: '8px',
                },
              }}
            >
              <button
                style={{ background: '#6AC5AB' }}
                onClick={handleForceDelete}
              >
                <Typography>Confirm</Typography>
              </button>
              <button
                style={{ background: '#DAA09A' }}
                onClick={() => setOpenForceDelete(false)}
              >
                <Typography>Discard</Typography>
              </button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default StrategyScreenV2;
