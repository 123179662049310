import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { addCustomerNote } from 'store/reducer/customerReducer/action';

import {
  FormikCustomerInput,
  FormikCustomerTextarea,
} from 'components/formik/customer';
import Loader from 'components/loader';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { selectedCustomersArr } from 'store/reducer/customerReducer';
import { successToast } from 'utils/toast';

interface AddNotesFormProps {
  actionType: string;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  comments: yup.string().required('Required'),
});

const AddNotesForm: React.FC<AddNotesFormProps> = ({ onClose, actionType }) => {
  const dispatch = useAppDispatch();
  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const odf_customer_id =
        selectedCustomers && selectedCustomers?.length
          ? selectedCustomers?.map((customer) => customer?.odf_customer_id)
          : [customerOdfId];
      const payload = {
        odf_customer_id,
        notes: values.comments,
        Heading: values.heading,
        action_type: actionType,
      };
      await dispatch(addCustomerNote(payload));
      successToast('Note has been saved successfully');
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />

      <Formik
        enableReinitialize
        initialValues={{ heading: '', comments: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '1rem',
            }}
          >
            <Typography>Heading</Typography>
            <FormikCustomerInput name='heading' />
          </Box>

          <FormikCustomerTextarea
            name='comments'
            label='Please add notes/comments'
          />

          <ActionButtonWrapper>
            <ActionButton type='submit' color='#6AC5AB'>
              <Typography>Update</Typography>
            </ActionButton>
            <ActionButton type='button' color='#DAA09A' onClick={onClose}>
              <Typography>Cancel</Typography>
            </ActionButton>
          </ActionButtonWrapper>
        </Form>
      </Formik>
    </>
  );
};

export default AddNotesForm;
