import { Typography } from '@material-ui/core';
import OverviewBox from '../overviewBox';

const CustomerSegment = () => {
  return (
    <div style={{ marginBottom: '3em' }}>
      <Typography variant='body1' style={{ marginBottom: '1em' }}>
        Customer segments - Based on Engagement
      </Typography>
      <OverviewBox
        overviewText='Right Party Contact rate'
        overviewNumber='40%'
      />
      <OverviewBox overviewText='PTP Customer' overviewNumber='20%' />
      <OverviewBox overviewText='Call required' overviewNumber='20%' />
      <OverviewBox overviewText='Not Workable' overviewNumber='20%' />
    </div>
  );
};

export default CustomerSegment;
