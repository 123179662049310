import {
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { SlArrowDown } from 'react-icons/sl';

interface FormikCustomerSelectProps {
  placeholder?: string;
  label?: string;
  width?: number;
  name: string;
  options: any;
  handleChange?: (x: string) => any;
}

const FormikCustomerSelectWrapper = styled('div')(
  ({ label }: { label?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: label ? '1rem' : 0,
  })
);

const FormikCustomerSelect: React.FC<FormikCustomerSelectProps> = ({
  placeholder,
  label,
  width = 200,
  name,
  options,
  handleChange,
}) => {
  return (
    <FormikCustomerSelectWrapper label={label}>
      {label && (
        <label style={{ marginRight: '1rem' }}>
          <Typography fontFamily='Overpass'>{label}</Typography>
        </label>
      )}
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value, name } = field;

          return (
            <FormControl sx={{ width, zIndex: 10 }}>
              <Select
                displayEmpty
                value={value || ''}
                onChange={(event: SelectChangeEvent) => {
                  const { value } = event.target;
                  setFieldValue(name, value);
                  handleChange && handleChange(value);
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  position: 'relative',
                  borderRadius: 0,
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  textAlign: 'center',
                  '.MuiSelect-select': {
                    padding: '12px 10px 10px 14px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                IconComponent={() => (
                  <SlArrowDown
                    style={{
                      fontSize: '1.1rem',
                      marginRight: '1rem',
                      position: 'absolute',
                      right: 0,
                    }}
                  />
                )}
              >
                <MenuItem disabled value=''>
                  <Typography color='#757575'>{placeholder}</Typography>
                </MenuItem>
                {options?.map((option: any, index: number) => (
                  // <MenuItem value={option?.user_id} key={index}>
                  //   {option?.name}
                  // </MenuItem>
                  <MenuItem value={option?.value} key={index}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          );
        }}
      </Field>
    </FormikCustomerSelectWrapper>
  );
};

export default FormikCustomerSelect;
