import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateTimeBoxMainCont: {
      display: 'flex',
      flexDirection: 'row',
      height: '36px',
      backgroundColor: theme.kollectAITheme.BluishSolitude,
      color: theme.kollectAITheme.purpleTransparent,
      borderRadius: '3px',
      padding: '0px 10px',
      marginRight: '12px',
      '&:hover': {
        backgroundColor: theme.kollectAITheme.BluishSolitude,
        color: theme.kollectAITheme.purpleTransparent,
      },
      '&.MuiButton-root.Mui-disabled': {
        backgroundColor: theme.kollectAITheme.BluishSolitude,
        color: theme.kollectAITheme.lightGrey,
      },
    },
    dateTimeBoxTextStyle: {
      fontSize: '14px',
      marginRight: '30px',
    },
    calendarContainer: {
      position: 'absolute',
      bottom: (props: any) => (props?.isPositionBottom ? -275 : 38),
      left: 0,
      zIndex: 500,
    },
  })
);

export default useStyles;
