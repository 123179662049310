import { Box, Typography } from '@mui/material';
import { FormikValues } from 'formik';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import useStyles from './index.styles';

import CustomIcon from 'components/customIcons';

interface SegmentsListProps {
  segmentOptions: any;
  selectedSegments: string[];
  allowedSegmentData: any;
  values: FormikValues;
  setSelectedSegments: (segments: string[]) => void;
  setShowError: (show: boolean) => void;
}

const SegmentsList: React.FC<SegmentsListProps> = ({
  segmentOptions,
  allowedSegmentData,
  values,
  setSelectedSegments,
  selectedSegments,
  setShowError,
}) => {
  const classes = useStyles();

  const handleSelection = (segment: string) => {
    const isSelected = selectedSegments.includes(segment);
    const selected = isSelected
      ? selectedSegments.filter((item: string) => item !== segment)
      : selectedSegments.concat([segment]);
    setSelectedSegments(selected);
    setShowError(false);
  };

  return (
    <Box>
      <Box className={classes.segmentListWrapper}>
        <Typography>Segments</Typography>
        <button
          type='button'
          style={{ color: '#6AC5AB', textDecoration: 'underline' }}
          onClick={() => {
            const option: any = allowedSegmentData.find(
              (segment: any) => Object.keys(segment)[0] === values.segment
            );
            const segments = Object.values(option)[0] as string[];
            setSelectedSegments(segments);
          }}
        >
          Add All
        </button>
      </Box>

      <Box sx={{ border: '1px solid #E6E6E6', borderRadius: '10px' }}>
        {segmentOptions?.map((item: string, index: number) => {
          const isSelected = selectedSegments?.includes(item);
          return (
            <Box
              key={index}
              style={{ borderTop: `${index === 0 ? '' : '1px solid #E6E6E6'}` }}
              className={classes.segmentCont}
            >
              <Box display='flex' gap='10px' alignItems='center'>
                {isSelected && (
                  <CustomIcon
                    name='selected_outlined_circle'
                    size={16}
                    color='#6AC5AB'
                  />
                )}
                <Typography sx={{ fontSize: '0.8rem', color: '#A8ABC3' }}>
                  {item}
                </Typography>
              </Box>
              <button type='button' onClick={() => handleSelection(item)}>
                {!isSelected ? (
                  <AiFillPlusCircle color='#6ac5ab' fontSize='1.1rem' />
                ) : (
                  <AiFillMinusCircle color='#DAA09A' fontSize='1.1rem' />
                )}
              </button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default SegmentsList;
