import * as React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Form, Formik, FormikValues } from 'formik';
import Editor from '@monaco-editor/react';
import * as yup from 'yup';
import useStyles from './index.styles';

import { apiMethodContentColor } from '.';
import {
  FormikIntegrationsEditor,
  FormikIntegrationsInput,
} from 'components/formik/integrations';
import { lambdaUpload } from 'apis/aws/lambdaUpload';
import Loader from 'components/loader';
import { useAppSelector } from 'hooks/useRedux';
import { awsUrl } from 'store/reducer/auth';

const validationSchema = yup.object().shape({
  api_key: yup.string().required('Required'),
  request: yup.string().required('Required'),
});

const TestAPIModal = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: any;
}) => {
  // const { api_method, getApiScreen, api_url, request, base_url } = data;
  const { api_method, getApiScreen, api_url, request } = data;

  const classes = useStyles({ getApiScreen });

  const urlObj = useAppSelector(awsUrl)?.integration_api;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [apiCalled, setApiCalled] = React.useState<boolean>(false);
  const [responseData, setResponseData] = React.useState<{
    success: boolean;
    response: string;
  }>({ success: false, response: '' });

  const handleSubmit = async (values: FormikValues) => {
    if (urlObj) {
      setLoading(true);
      const { success, data } = await lambdaUpload({
        // api_url,
        // base_url,
        urlObj,
        request: values.request,
        api_key: values?.api_key,
      });
      setResponseData({
        success,
        response: data ? JSON.stringify(data, null, 2) : '',
      });
      setApiCalled(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setResponseData({ success: false, response: '' });
          setApiCalled(false);
        }}
        disableEnforceFocus={false}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box className={classes.testModalCont}>
          <Typography
            sx={{ fontSize: '1.3rem', fontWeight: 600, marginBottom: '1rem' }}
          >
            Try this API
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: apiCalled ? 'auto auto' : 'auto',
              alignItems: 'start',
              gap: '2rem',
            }}
          >
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    width: 220,
                    color: '#272D3B',
                    opacity: '0.5',
                    fontWeight: 600,
                  }}
                >
                  URL
                </Typography>

                <Box className={classes.contentUrlBox}>
                  <Box
                    className={classes.contentApiMethodBox}
                    sx={{ background: apiMethodContentColor(api_method) }}
                  >
                    <Typography fontWeight={500}>{api_method}</Typography>
                  </Box>
                  <Typography color={kollectAITheme.primaryColor}>
                    {api_url}
                  </Typography>
                </Box>
              </Box>
              <Formik
                initialValues={{
                  api_key: '',
                  request: JSON.stringify(request, null, 2) || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form>
                    <FormikIntegrationsInput
                      name='api_key'
                      label='API Key'
                      placeholder='Test API Key'
                    />

                    <FormikIntegrationsEditor
                      name='request'
                      label='Request Body'
                      disabled={apiCalled}
                    />

                    <Box
                      mt='2rem'
                      sx={{
                        display: 'flex',
                        justifyContent: apiCalled ? 'end' : 'center',
                      }}
                    >
                      <button className={classes.endpointBtn}>
                        <Typography>
                          {apiCalled ? 'Re-Hit' : 'Hit'} Endpoint
                        </Typography>
                      </button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            {apiCalled && (
              <Box>
                <Typography
                  sx={{
                    color: '#272D3B',
                    opacity: '0.5',
                    fontWeight: 600,
                    marginBottom: '0.6rem',
                  }}
                >
                  Status
                </Typography>

                <Box
                  sx={{
                    background: responseData.success
                      ? '#6AC5AB82'
                      : '#E4897B73',
                    border: '1px solid #E6E6E6',
                    padding: '10px 2rem',
                    borderRadius: '5px',
                    display: 'inline-block',
                    marginBottom: '1.2rem',
                  }}
                >
                  <Typography sx={{ opacity: 0.5, fontWeight: 600 }}>
                    {responseData.success ? 'Success' : 'Error'}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    color: '#272D3B',
                    opacity: '0.5',
                    fontWeight: 600,
                    marginBottom: '0.8rem',
                  }}
                >
                  Response
                </Typography>

                <Editor
                  width={500}
                  height={280}
                  defaultLanguage='json'
                  value={responseData.response}
                  theme='light'
                  options={{
                    readOnly: true,
                    minimap: { enabled: false },
                    formatOnPaste: true,
                  }}
                  onChange={() => {}}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TestAPIModal;
