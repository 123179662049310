import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useStyles from "./index.styles";
import CircleIcon from "@mui/icons-material/Circle";

const FREQUENCY = [
  { label: "Weekly", value: 7 },
  { label: "Bi-weekly", value: 14 },
  { label: "Monthly", value: 30 },
];

interface FormFieldSelectProps {
  selectedStrategyGroup: any;
}
interface IRef {}

const StrategyRepeatFields = forwardRef<IRef, FormFieldSelectProps>(
  ({ selectedStrategyGroup }, ref) => {
    const classes = useStyles();
    const [frequency, setFrequency] = useState<number | string>(7);
    const [endType, setEndType] = useState<string>("occurence");

    const [customFrequency, setCustomFrequency] = useState<number>(1);
    const [occurenceDays, setOccurenceDays] = useState<number>(1);
    const [endDays, setEndDays] = useState<number>(1);

    useEffect(() => {
      const filteredFrequency = FREQUENCY.find(
        (freq) => freq.value === selectedStrategyGroup.days_range
      );
      if (filteredFrequency) {
        setFrequency(filteredFrequency.value);
      } else {
        setFrequency("every");
        setCustomFrequency(selectedStrategyGroup.days_range);
      }
      setEndType("days");
      setEndDays(selectedStrategyGroup.valid_for);
    }, [selectedStrategyGroup]);

    useImperativeHandle(ref, () => ({
      getFrequency: () => {
        if (frequency === "every") {
          return Number(customFrequency);
        }
        return Number(frequency);
      },
      getValidity: () => {
        if (endType === "occurence") {
          return (
            Number(occurenceDays) *
            (frequency === "every"
              ? Number(customFrequency)
              : Number(frequency))
          );
        }
        return Number(endDays);
      },
    }));

    const handleChangeFrequency = (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      setFrequency(value);
    };

    const onStategyEndChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string
    ) => {
      setEndType(value);
    };

    const onChangeFrequencyInput = (e: any) => {
      const frequencyInput = Number(e.target.value) || 1;
      setCustomFrequency(frequencyInput);
    };

    const onChangeOccurenceDays = (e: any) => {
      const occurenceInput = Number(e.target.value) || 1;
      setOccurenceDays(occurenceInput);
    };
    const onChangeEndDays = (e: any) => {
      const endDaysInput = Number(e.target.value) || 1;
      setEndDays(endDaysInput);
    };

    return (
      <>
        <Grid
          item
          xs={6}
          lg={3}
          className={classes.formControl}
          margin="auto 0"
        >
          <label className={classes.formLabel}>
            <Typography
              fontFamily="Overpass"
              fontWeight="700"
              fontSize="16px"
              color="#272D3B"
              lineHeight="20px"
              sx={{ opacity: 0.5 }}
            >
              Frequency
            </Typography>
          </label>
        </Grid>
        <Grid item xs={6} lg={9} className={classes.formControl}>
          <FormControl style={{ width: "85%" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={false}
              name="radio-buttons-group"
              row
              onChange={handleChangeFrequency}
              value={frequency}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {FREQUENCY.map((frequency) => (
                <FormControlLabel
                  className={classes.radioLabel}
                  value={frequency.value}
                  key={frequency.value}
                  control={
                    <Radio
                      className={classes.radio}
                      style={{
                        marginRight: 10,
                      }}
                      checkedIcon={
                        <CircleIcon
                          style={{
                            color: "#004357",
                            width: "12px",
                            height: "12px",
                          }}
                        />
                      }
                      icon={<div className={classes.uncheckedRadio} />}
                    />
                  }
                  label={frequency.label}
                />
              ))}
              <Box display="flex" alignItems="center" width={"30%"}>
                <Radio
                  value={"every"}
                  className={classes.radio}
                  style={{
                    marginRight: 10,
                  }}
                  checkedIcon={
                    <CircleIcon
                      style={{
                        color: "#004357",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  }
                  icon={<div className={classes.uncheckedRadio} />}
                />
                <Typography>Every</Typography>
                {frequency !== "every" ? (
                  <div
                    style={{
                      backgroundColor: "#E6E6E6",
                      padding: 10,
                      margin: "0 8px",
                    }}
                  >
                    <Typography> {customFrequency} days</Typography>
                  </div>
                ) : (
                  <Box display="flex" alignItems="center">
                    <input
                      type="text"
                      name="name"
                      style={{
                        margin: "0 8px",
                        padding: "8px 12px",
                        width: "30%",
                        height: "60%",
                        fontSize: 18,
                        fontFamily: "Overpass",
                        boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                        border: " 1px solid #CCCCCC",
                      }}
                      value={customFrequency}
                      onChange={onChangeFrequencyInput}
                    />
                    <Typography>days</Typography>
                  </Box>
                )}
              </Box>
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={6}
          lg={3}
          className={classes.formControl}
          margin="auto 0"
        >
          <label className={classes.formLabel}>
            <Typography
              fontFamily="Overpass"
              fontWeight="700"
              fontSize="16px"
              color="#272D3B"
              lineHeight="20px"
              sx={{ opacity: 0.5 }}
            >
              Ends
            </Typography>
          </label>
        </Grid>
        <Grid item xs={6} lg={9} className={classes.formControl}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="occurence"
            value={endType}
            name="radio-buttons-group"
            row
            onChange={onStategyEndChange}
            sx={{
              columnGap: "20px",
            }}
          >
            <Box display="flex" alignItems="center">
              <Radio
                value="occurence"
                className={classes.radio}
                style={{
                  marginRight: 10,
                }}
                checkedIcon={
                  <CircleIcon
                    style={{
                      color: "#004357",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                }
                icon={<div className={classes.uncheckedRadio} />}
              />
              {endType !== "occurence" ? (
                <Box
                  style={{ backgroundColor: "#E6E6E6", padding: "10px 6px" }}
                >
                  <Typography display="flex">
                    after repeating{" "}
                    <Typography margin="0 4px" width="38px" textAlign="center">
                      {occurenceDays}
                    </Typography>{" "}
                    occurences
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography> after repeating</Typography>
                  <input
                    type="text"
                    name="name"
                    style={{
                      margin: "0 4px",
                      width: "38px",
                      height: "33px",
                      textAlign: "center",
                      fontSize: 18,
                      fontFamily: "Overpass",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                      border: " 1px solid #CCCCCC",
                    }}
                    value={occurenceDays}
                    onChange={onChangeOccurenceDays}
                  />
                  <Typography>occurences</Typography>
                </>
              )}
            </Box>

            <Box display="flex" alignItems="center">
              <Radio
                value="days"
                className={classes.radio}
                style={{
                  marginRight: 10,
                }}
                checkedIcon={
                  <CircleIcon
                    style={{
                      color: "#004357",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                }
                icon={<div className={classes.uncheckedRadio} />}
              />
              <Typography>after</Typography>
              {endType !== "days" ? (
                <Box
                  style={{
                    backgroundColor: "#E6E6E6",
                    margin: "0px 2px",
                    padding: "10px 6px",
                  }}
                >
                  <Typography display="flex">
                    <Typography width="38px" textAlign="center">
                      {endDays}
                    </Typography>
                    days
                  </Typography>
                </Box>
              ) : (
                <>
                  <input
                    type="text"
                    name="name"
                    style={{
                      margin: "0 4px",
                      width: "38px",
                      height: "33px",
                      textAlign: "center",
                      fontSize: 18,
                      fontFamily: "Overpass",
                      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                      border: " 1px solid #CCCCCC",
                    }}
                    value={endDays}
                    onChange={onChangeEndDays}
                  />
                  <Typography>days</Typography>
                </>
              )}
            </Box>
          </RadioGroup>
        </Grid>
      </>
    );
  }
);

export default StrategyRepeatFields;
