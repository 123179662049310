import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { userPermissions } from 'store/reducer/auth';
// import { userAccess } from 'utils/helper';
import useStyles from './index.styles';
import _ from 'lodash';
import { MODULELIST } from 'constants/types';

const DashboardManager = ({ ...props }) => {
  const { name, description, img, url } = props;
  const navigate = useNavigate();
  const classes = useStyles();

  // const userRole = useAppSelector((state: any) => state.auth.role);
  const permissions = useAppSelector(userPermissions);

  if (name === 'template manager' && !_.has(permissions, MODULELIST.TEMPLATE))
    return <></>;
  if (name === 'strategy manager' && !_.has(permissions, MODULELIST.STRATEGY))
    return <></>;
  if (name === 'workflow manager' && !_.has(permissions, MODULELIST.WORKFLOW))
    return <></>;
  if (name === 'campaign manager' && !_.has(permissions, MODULELIST.CAMPAIGN))
    return <></>;
  if (
    name === 'customer management' &&
    !_.has(permissions, MODULELIST.CUSTOMER)
  )
    return <></>;
  if (
    name === 'contact center' &&
    !_.has(permissions, MODULELIST.CONTACTCENTER)
  )
    return <></>;

  // if (name === 'template manager' && !userAccess(userRole)?.template.access)
  //   return <></>;
  // if (name === 'strategy manager' && !userAccess(userRole)?.strategy.access)
  //   return <></>;
  // if (name === 'workflow manager' && !userAccess(userRole)?.workflow.access)
  //   return <></>;
  // if (name === 'campaign manager' && !userAccess(userRole)?.campaign.access)
  //   return <></>;
  // if (name === 'customer management' && !userAccess(userRole)?.customer.access)
  //   return <></>;

  const children = (
    <>
      <Typography
        align='center'
        sx={{
          textTransform: 'uppercase',
          fontSize: '1.4rem',
          fontWeight: 500,
        }}
      >
        {name}
      </Typography>

      <Box className={classes.dashboardImageContentCont}>
        <img src={img} alt='' />
        <ul>
          {description.map((desc: string, index: number) => {
            return (
              <li key={index}>
                <Typography>{desc}</Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </>
  );

  if (name === 'contact center') {
    return (
      <a
        href={url}
        className={classes.dashboardManagerWrapper}
        target='_blank'
        rel='noreferrer'
      >
        {children}
      </a>
    );
  }

  return (
    <Box
      className={classes.dashboardManagerWrapper}
      onClick={() => navigate(url)}
    >
      {children}
    </Box>
  );
};

export default DashboardManager;
