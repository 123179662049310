import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryWrapper: {
      minWidth: '600px',
      backgroundColor: '#fff',
      outline: 'none',
      padding: '20px 2rem',
    },
    detailsBlockCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    tableStyle: {
      borderCollapse: 'collapse',
      width: '100%',
      '& td, th': {
        textAlign: 'center',
        padding: '8px',
        '& p': { fontWeight: 500 },
      },
      '& th': { color: '#6F6F6F' },
    },
    saveButtonCont: {
      display: 'flex',
      justifyContent: 'center',
      margin: '2rem 0 1rem',
      '& button': {
        background: '#6AC5AB',
        borderRadius: '8px',
        padding: '8px 1.5rem',
        color: '#fff',
      },
    },
  })
);

export default useStyles;
