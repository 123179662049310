import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      maxWidth: "1000px",
    },
    formLabel: {
      width: "120px",
    },
    formInput: {
      // width: "100%",
      width: "80%",
      // border: "1px solid #E0E7FF",
      border: " 1px solid #CCCCCC",
      height: "38px",
      borderRadius: "5px",
      padding: "0 1em",
      fontSize: "0.9em",
      fontFamily: "inherit",
      background: "rgba(224, 231, 255, 0.2)",

      "&:focus": {
        outline: "none",
      },
    },
    formTextarea: {
      width: "80%",
      height: 50,
      background: "rgba(224, 231, 255, 0.2)",
      // border: "1px solid #E0E7FF",
      border: " 1px solid #CCCCCC",
      borderRadius: "5px",
      padding: "0.5em 1em",
      fontFamily: "inherit",
      fontSize: "0.9em",

      "&:focus": {
        outline: "none",
      },
    },
    formErrorMessage: {
      marginTop: 10,
      color: "#d32f2f",
      fontSize: "0.8em",
    },
    createSubmitCont: {
      // marginTop: "1.5rem",
      // marginBottom: "1rem",
      display: "flex",
      justifyContent: "center",
      margin: "1.5rem auto 1rem",
    },
    createSubmitButton: {
      borderRadius: "8px",
      border: "transparent",
      color: "#fff",
      fontSize: "1rem",
      padding: "0.625rem 4.5rem",
      background: "#004357",
      fontWeight: 500,
    },
    switch: {
      width: 80,
      height: 34,
      padding: 7,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        "&.Mui-checked": {
          color: "#fff",
          transform: "translateX(30px)",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#D9D9D9",
          },
        },
      },
      "& .Mui-checked .MuiSwitch-thumb": {
        backgroundColor: "#6AC5AB",
        width: 28,
        height: 28,
      },
      "&  .MuiSwitch-thumb": {
        backgroundColor: "#6F6F6F",
        minWidth: 28,
        minHeight: 28,
        marginTop: 5,
      },

      "& .MuiSwitch-track": {
        opacity: 1,
        width: 36,
        height: 16,
        backgroundColor: "#D9D9D9",
        borderRadius: 20 / 2,
        boxShadow:
          "inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      },

      "& .MuiFormControlLabel-labelPlacementStart": {
        gap: "5px",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "50px",
        color: "red",
      },
    },
    slider: {
      "& .MuiSlider-track": {
        border: "none",
        backgroundColor: "#6AC5AB",
      },
      "& .MuiSlider-thumb": {
        height: 22,
        width: 22,
        backgroundColor: "#6AC5AB",
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);",
        },
        "&:before": {
          display: "none",
        },
      },
      "& .MuiSlider-rail": {
        color: "#D9D9D9",
        height: "8px",
        opacity: 1,
      },
      "& .MuiSlider-mark": {
        height: "13px",
        backgroundColor: "#969696",
      },
      "& .MuiSlider-markActive": {
        height: "13px",
        backgroundColor: "#6AC5AB",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
    checkboxWrapper: {
      '& .input[type="checkbox"]': {
        backgroundColor: "red !important",
        position: "relative",
      },
    },
    radio: {
      width: 28,
      height: 28,
      border: "1px solid rgba(150, 150, 150, 0.11)",
      boxShadow:
        "inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      margin: 0,
    },
    radioLabel: {
      "& .MuiFormControlLabel-label": {
        fontFamily: "Overpass",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "19px",
        color: "#272D3B",
      },
      marginLeft: "0 !important",
    },
    uncheckedRadio: {
      width: 12,
      height: 12,
    },
  })
);
export default useStyles;
