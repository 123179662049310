import { Box, styled, Typography } from '@mui/material';

const GroupRow = styled('div')(({ color = '#000' }: { color?: string }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
  '& p': {
    width: '140px',
    color,
    fontWeight: 500,
    textAlign: 'center',
    '&:first-child': { width: '20px' },
  },
}));

const GroupAsOne = ({ data }: { data: any }) => {
  return (
    <Box mt='1rem'>
      <Typography>
        Will allow you to set same channels and templates for all selected
        population as a whole
      </Typography>

      <Box ml='5rem' mt='2rem'>
        <GroupRow color='#6F6F6F'>
          <Typography></Typography>
          <Typography># Customers</Typography>
          <Typography>$ Outstanding</Typography>
          <Typography>Average DPD</Typography>
        </GroupRow>
        <GroupRow>
          <Typography>1.</Typography>
          <Typography>{data && data['#customers']}</Typography>
          <Typography>
            {data?.outstanding_balance
              ? `$ ${data?.outstanding_balance.toLocaleString()}`
              : '-'}
          </Typography>
          <Typography>{data?.average_dpd}</Typography>
        </GroupRow>
      </Box>
    </Box>
  );
};

export default GroupAsOne;
