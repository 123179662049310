import { Box, Checkbox, Typography } from '@mui/material';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CiSearch } from 'react-icons/ci';
import { searchStyles } from 'constants/styles';
import useStyles from './index.styles';

import { MODULELIST } from 'constants/types';
import { userPermissions } from 'store/reducer/auth';
import { useAppSelector } from 'hooks/useRedux';

interface TemplateAddFilterProps {
  selectedFilterOption: any;
  filterOptions: any;
  templateSearchChange: (x: any) => void;
  onSelectTwoWay: () => void;
  showTwoWay: string;
  selectedChannel: string;
}

const TemplateAddFilter: React.FC<TemplateAddFilterProps> = ({
  showTwoWay,
  selectedFilterOption,
  filterOptions,
  selectedChannel,
  templateSearchChange,
  onSelectTwoWay,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const permissions = useAppSelector(userPermissions);

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box className={classes.templateLeftContSearch}>
        <CiSearch fontSize={20} />
        <Box className={classes.templateLeftContSearchLabel}>
          <Typography>name:</Typography>
        </Box>
        <Select
          placeholder='Search Templates...'
          value={selectedFilterOption}
          options={filterOptions}
          isClearable={true}
          onChange={(value) => templateSearchChange(value)}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={searchStyles}
        />
      </Box>

      {permissions[MODULELIST.TEMPLATE].includes('create') && (
        <button
          className={classes.addTemplateButton}
          onClick={() =>
            navigate('create', {
              state: { channel: selectedChannel, isBack: false },
            })
          }
        >
          <AiFillPlusCircle />
          <Typography color='#fff'>Add New Template</Typography>
        </button>
      )}

      {selectedChannel !== 'email' && (
        <Box display='flex' alignItems='center'>
          <Typography>2 Way</Typography>
          <Checkbox
            checked={showTwoWay === selectedChannel ? true : false}
            onChange={onSelectTwoWay}
          />
        </Box>
      )}
    </Box>
  );
};

export default TemplateAddFilter;
