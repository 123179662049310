import {
  memo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { kollectAITheme } from 'config/theme';
import schemaJson from '../../../db_connect_schema_sample.json';
import { createSelectOption } from 'utils/helper';

import mappingVerifiedImg from 'assets/mapping_verified.svg';
import mappingErrorImg from 'assets/mapping_error.svg';
import mappingSaved from 'assets/attribute_saved.svg';

const styles = {
  control: (styles: any) => ({
    ...styles,
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#24254A',
    fontFamily: 'Overpass',
    fontSize: '1.05rem',
  }),
  menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
  menu: (styles: any) => ({ ...styles, zIndex: 9999 }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#24254A',
    fontFamily: 'Overpass',
    fontSize: '1rem',
  }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#24254A',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};

interface SelectOptionType {
  label: string;
  value: string;
}

interface AttributeMappingTableProps {
  attributeJson: any;
  response: any;
  saved: boolean;
  ref: any;
}

const AttributeMappingTable: React.FC<AttributeMappingTableProps> = forwardRef(
  ({ attributeJson, response, saved }, ref) => {
    const navigate = useNavigate();
    const dbConnectSchemaJson: any = schemaJson;

    const [tableOptions, setTableOptions] = useState<SelectOptionType[] | []>(
      []
    );
    const [columnOptions, setColumnOptions] = useState<any>({});
    const [selectedTableOption, setSelectedTableOption] = useState<any>({});
    const [selectedColumnOption, setSelectedColumnOption] = useState<any>({});

    useEffect(() => {
      const tableOpt = Object.keys(dbConnectSchemaJson).map(
        (schema: string) => ({
          label: schema,
          value: schema,
        })
      );
      setTableOptions(tableOpt);
    }, [dbConnectSchemaJson]);

    useImperativeHandle(ref, () => ({
      selectedTableOption,
      selectedColumnOption,
    }));

    const handleTableSelect = (value: any, attribute: string) => {
      const schema = dbConnectSchemaJson[value.value];
      const opt = createSelectOption({ arr: schema });
      setColumnOptions((prev: any) => ({
        ...prev,
        [attribute]: opt,
      }));
      setSelectedTableOption((prev: any) => ({
        ...prev,
        [attribute]: value,
      }));
    };

    return (
      <TableContainer
        sx={{
          // border: '1px solid #E6E6E6',
          // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: '1px solid #6F6F6F',
              padding: '10px',
            },
          }}
          aria-label='simple table'
        >
          <TableHead>
            <TableRow
              sx={{
                // color: '#A8ABC3',
                '& th': {
                  color: '##6F6F6F',
                  fontWeight: 700,
                  fontSize: '1rem',
                },
              }}
            >
              <TableCell width='59%' sx={{ borderRight: '1px solid #6F6F6F' }}>
                Required Attributes
              </TableCell>
              <TableCell
                width='18%'
                align='center'
                sx={{ borderRight: '1px solid #6F6F6F' }}
              >
                Table Name
              </TableCell>
              <TableCell
                width='18%'
                align='center'
                sx={{ borderRight: '1px solid #6F6F6F' }}
              >
                Column Name
              </TableCell>
              <TableCell width='5%' align='center'>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(attributeJson).map(
              (attribute: string, index: number) => {
                const { attribute_description } = attributeJson[attribute];
                const status = response && response[attribute];

                return (
                  <TableRow key={index}>
                    <TableCell sx={{ borderRight: '1px solid #6F6F6F' }}>
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '200px 1fr',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontWeight={600} color='#6AC5AB'>
                          {attribute}
                        </Typography>
                        <Typography fontWeight={500} color='#6F6F6F'>
                          {attribute_description}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ borderRight: '1px solid #6F6F6F' }}
                    >
                      <Select
                        placeholder='table_name'
                        options={tableOptions}
                        value={selectedTableOption[attribute] ?? ''}
                        onChange={(value: any) =>
                          handleTableSelect(value, attribute)
                        }
                        styles={styles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      sx={{ borderRight: '1px solid #6F6F6F' }}
                    >
                      <Select
                        placeholder='column_name'
                        options={columnOptions[attribute] ?? []}
                        value={selectedColumnOption[attribute] ?? ''}
                        onChange={(value: any) => {
                          setSelectedColumnOption((prev: any) => ({
                            ...prev,
                            [attribute]: value,
                          }));
                        }}
                        styles={styles}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      {saved ? (
                        <img src={mappingSaved} alt='' />
                      ) : (
                        <>
                          {!status ? (
                            <Typography>--</Typography>
                          ) : (
                            <Box>
                              <Tooltip
                                title={!status.mapping ? status.message : ''}
                                placement='top'
                              >
                                <img
                                  src={
                                    !status.mapping
                                      ? mappingErrorImg
                                      : mappingVerifiedImg
                                  }
                                  alt=''
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default memo(AttributeMappingTable);
