import { useNavigate } from 'react-router-dom';
import { styled, Box, Typography } from '@mui/material';
import { BsChevronRight } from 'react-icons/bs';
import { setSelectedIntegrationOption } from 'store/reducer/IntegrationReducer';
import { useAppDispatch } from 'hooks/useRedux';

interface DashboardTileProps {
  label: string;
  sublabel?: string;
  url: string;
}

const DashboardTileWrapper = styled('div')({
  border: '1px solid #E6E6E6',
  borderRadius: '10px',
  boxShadow:
    'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
  padding: '1rem 0',
  position: 'relative',
  cursor: 'pointer',
  marginTop: '1rem',
});

const DashboardTile: React.FC<DashboardTileProps> = ({
  label,
  sublabel,
  url,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <DashboardTileWrapper
      onClick={() => {
        dispatch(setSelectedIntegrationOption(''));
        navigate(url);
      }}
    >
      <Typography
        align='center'
        sx={{
          textTransform: 'uppercase',
          fontSize: '1.2rem',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
      {sublabel && (
        <Typography color='#969696' align='center'>
          {sublabel}
        </Typography>
      )}
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
          top: sublabel ? '2rem' : '1.2rem',
          fontSize: '20px',
        }}
      >
        <BsChevronRight />
      </Box>
    </DashboardTileWrapper>
  );
};

export default DashboardTile;
