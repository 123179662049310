import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

const ErrorFallback = () => {
  const styles = useStyles();

  return (
    <Box className={styles.mainWrapper} sx={{ height: '100vh' }}>
      <Box className={styles.textContainer}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography color='#32475CDE' variant='h4'>
            Data Not Found ⚠️
          </Typography>
          <Typography color='#32475C99'>
            we couldn't find the data you are looking for
          </Typography>
        </Box>

        <img src={require('assets/not-found.png')} alt='' />

        <a href='/' className={styles.gotoDashbaordButton}>
          <Typography>Back To DASHBOARD</Typography>
        </a>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
