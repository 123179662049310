import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smsPreviewWrapper: {
      // border: '10px solid #333333',
      // borderRadius: '20px',
      height: (props: any) => (props.isCreate ? 'calc(100vh - 200px)' : '100%'),
      margin: '0 auto',
      width: '70%',
    },
    smsPreviewCont: {
      color: '#fff',
      padding: '1rem 0',
      background: '#7C9BA5 !important',
      borderRadius: '10px 10px 0px 0px',
    },
    smsPreviewMessageCont: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: '91%',
      height: (props: any) =>
        // props.isCreate ? 'calc(100vh - 326px)' : 'calc(100vh - 525px)',
        props.isCreate ? 'calc(100vh - 326px)' : 'calc(100% - 130px)',
      background: '#fff',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    smsPreviewMessageBox: {
      display: 'flex',
      gap: '10px',
      margin: '1rem',
    },
    smsPreviewMessage: {
      background: '#E5E5E5',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px 5px 5px 0px',
      minHeight: '100px',
      padding: '0.4rem',
    },
    smsPreviewInputCont: {
      display: 'flex',
      padding: '1rem',
      gap: '1rem',
      borderRadius: '0 0 10px 10px',
      background: '#fff',

      '& input': {
        width: '95%',
        background: 'rgba(0, 67, 87, 0.09)',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        border: 'transparent',
        outline: 'none',
        padding: '10px',
        fontFamily: 'inherit',
      },
      '& button': {
        background: theme.kollectAITheme.primaryColor,
        borderRadius: '5px',
        padding: '0 0.8rem',
      },
      '& svg': {
        fontSize: '1.2rem',
        color: '#fff',
      },
    },
  })
);

export default useStyles;
