import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { SelectOptionType } from 'constants/types';
import { SlArrowDown } from 'react-icons/sl';

const BasicSelect = ({
  width = '70px',
  placeholder = '',
  options,
  value,
  size = 'md',
  onChange,
}: {
  width?: string;
  placeholder?: string;
  options: SelectOptionType[];
  value: string;
  size?: string;
  onChange: (x: string) => void;
}) => {
  return (
    <Select
      displayEmpty
      value={value}
      onChange={(e: SelectChangeEvent) => onChange(e.target.value)}
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{
        width,
        position: 'relative',
        borderRadius: 0,
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        textAlign: 'center',
        '.MuiSelect-select': {
          padding: size === 'sm' ? '8px 8px 5px 12px' : '12px 8px 10px 10px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      IconComponent={() => (
        <SlArrowDown
          style={{
            fontSize: size === 'sm' ? '0.9rem' : '1.1rem',
            marginRight: '1rem',
            position: 'absolute',
            right: 0,
            top: size === 'sm' ? '12px' : 0,
          }}
        />
      )}
    >
      {/* {placeholder && (
        <MenuItem disabled value=''>
          <Typography color='#757575'>{placeholder}</Typography>
        </MenuItem>
      )} */}
      {options?.map((type: any, index: number) => (
        <MenuItem value={type.value} key={index}>
          {type?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BasicSelect;
