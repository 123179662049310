import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { SlArrowDown } from 'react-icons/sl';
import useStyles from './index.styles';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
// import { useAppSelector } from 'hooks/useRedux';
// import { customerOdfID } from 'store/reducer/customerReducer';

export default function LoanLevelDetails({ loanData }: { loanData: any }) {
  const classes = useStyles();
  const location = useLocation();

  const [loanDetails, setLoanDetails] = React.useState<any>({});
  const [options, setOptions] = React.useState<any>([]);
  const [selectedLoan, setSelectedLoan] = React.useState<string>('');

  // const loanData: any = useAppSelector(
  //   (state) => state.customer.customerProfile?.[0]
  // )?.loan_details;

  React.useEffect(() => {
    if (loanData?.length) {
      const opt = loanData.map((item: any) => ({
        label: item.loan_id,
        value: item.loan_id,
      }));
      setLoanDetails(loanData[0]);
      setOptions(opt);

      const state = location.state as any;
      if (state?.loanView) {
        setSelectedLoan(state.loanId);
      } else {
        setSelectedLoan(loanData[0].loan_id);
      }
    }
  }, [loanData]);

  const handleLoanChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const loan = loanData.find((item: any) => item.loan_id === value);
    setLoanDetails(loan);
    setSelectedLoan(value);
  };

  if (!loanData) return <></>;

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            padding: '10px 0',
          },
        }}
        aria-label='caption table'
      >
        <TableBody>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#6F6F6F' fontSize='1.1rem'>
                Select Loan ({loanData.length} Loans)
              </Typography>
            </TableCell>
            <TableCell>
              <Box className={classes.selectLoanCont}>
                <Typography>ID:</Typography>
                <FormControl sx={{ minWidth: 200, zIndex: 10 }}>
                  <Select
                    value={selectedLoan}
                    onChange={handleLoanChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                      position: 'relative',
                      borderRadius: 0,
                      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                      '	.MuiSelect-select': {
                        padding: '12px 10px 10px 14px',
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.04)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.04)',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                    IconComponent={() => (
                      <SlArrowDown
                        style={{
                          fontSize: '1.1rem',
                          marginRight: '1rem',
                          position: 'absolute',
                          right: 0,
                        }}
                      />
                    )}
                  >
                    {options?.map((type: any, index: number) => (
                      <MenuItem value={type.value} key={index}>
                        {type?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </TableCell>
          </TableRow>

          {/* Loan Details */}
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Amount Outstanding</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails?.amount_outstanding &&
                  `$ ${loanDetails.amount_outstanding?.toLocaleString()}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Due date</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{loanDetails.due_date}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>DPD</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{loanDetails.dpd}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Delinquent since</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails?.delinquent_since &&
                  moment(loanDetails?.delinquent_since).format('DD/MM/YYYY')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Loan Maturity Date</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails?.loan_maturity_date &&
                  moment(loanDetails.loan_maturity_date).format('DD/MM/YYYY')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Tenure</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{loanDetails?.tenure}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>EMI Amount</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails?.emi_amount &&
                  `$ ${loanDetails.emi_amount?.toLocaleString()}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Interest Rate</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails.interest_rate && `${loanDetails.interest_rate}%`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Outstanding Principal</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails.outstanding_principal &&
                  `$ ${loanDetails.outstanding_principal?.toLocaleString()}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>Outstanding Fee</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {loanDetails.outstanding_fee &&
                  `$ ${loanDetails.outstanding_fee?.toLocaleString()}`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
