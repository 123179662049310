import { useState } from "react";
import { Box, Typography } from "@mui/material";
import useStyles from "./template.styles";
import { ImCloudDownload } from "react-icons/im";
import { useAppSelector } from "hooks/useRedux";
import { userPermissions } from "store/reducer/auth";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { MODULELIST } from "constants/types";

import Previews from "./previews/previews";
import WorkflowButton from "components/workflow/landing/header/button";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import BasicModal from "components/Modal";
import TestTemplateModal from "./testTemplateModal";

interface TemplatePreviewProps {
  selectedChannel: string;
  selectedTemplates: any[];
  previewTemplate: any;
}

const TemplatePreviews = ({
  selectedChannel,
  selectedTemplates,
  previewTemplate,
}: TemplatePreviewProps) => {
  const navigate = useNavigate();
  const isTemplateSelected = true;
  const selectedTemplate = previewTemplate;
  // const selectedTemplate = selectedTemplates[0];

  const classes = useStyles({ isTemplateSelected });

  const permissions = useAppSelector(userPermissions);

  const [openTestModal, setOpenTestModal] = useState<boolean>(false);

  const handleTemplateDownload = () => {
    if (selectedChannel === "email") {
      const html = new Blob([selectedTemplate.template], {
        type: "text/html",
      });
      const htmlURL = window.URL.createObjectURL(html);
      let tempLink = document.createElement("a");
      tempLink.href = htmlURL;
      tempLink.setAttribute(
        "download",
        `${selectedTemplate.template_name}.html`
      );
      tempLink.click();
    } else {
      if (selectedTemplate.two_way_flag) {
        const json = new Blob(
          [JSON.stringify(selectedTemplate.template_json)],
          {
            type: "application/json",
          }
        );
        const jsonURL = window.URL.createObjectURL(json);
        let tempLink = document.createElement("a");
        tempLink.href = jsonURL;
        tempLink.setAttribute(
          "download",
          `${selectedTemplate.template_name}.json`
        );
        tempLink.click();
      } else {
        const json = new Blob([JSON.stringify(selectedTemplate.template)], {
          type: "text/plain",
        });
        const jsonURL = window.URL.createObjectURL(json);
        let tempLink = document.createElement("a");
        tempLink.href = jsonURL;
        tempLink.setAttribute(
          "download",
          `${selectedTemplate.template_name}.txt`
        );
        tempLink.click();
      }
    }
  };

  const handleTestModalClose = () => setOpenTestModal(false);

  return (
    <>
      {isTemplateSelected && (
        <Box className={classes.templateDownloadCont}>
          <Box className={classes.templateDownloadBox}>
            <ImCloudDownload color="#004357" fontSize="20px" />
            <button onClick={handleTemplateDownload}>
              <Typography color="#004357" fontWeight={600}>
                Download{" "}
                {selectedChannel === "email"
                  ? "HTML"
                  : selectedTemplate?.two_way_flag
                  ? "JSON"
                  : "Text"}
              </Typography>
            </button>
          </Box>
        </Box>
      )}

      <Box className={classes.templatePreviewCont}>
        {isTemplateSelected && selectedChannel === "email" ? (
          <Box
            sx={{
              height: `calc(100vh - ${isTemplateSelected ? "380px" : "300px"})`,
              overflow: "auto",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: selectedTemplate?.template,
              }}
            ></div>
          </Box>
        ) : (
          <Previews
            selectedChannel={selectedChannel}
            selectedTemplates={selectedTemplate}
          />
        )}
      </Box>

      {isTemplateSelected && (
        <>
          <Box className={classes.templateRightContButtonCont}>
            {/* {userPermissions.includes('update') && ( */}
            {permissions[MODULELIST.TEMPLATE].includes("update") && (
              <WorkflowButton
                text="Edit"
                bgColor="#6AC5AB"
                icon={<EditIcon />}
                onClick={() => {
                  navigate(`/template-management/${selectedTemplate.id}`, {
                    state: {
                      channel: selectedChannel,
                      template: selectedTemplate,
                      isBack: false,
                    },
                  });
                }}
              />
            )}
            {/* {userPermissions.includes('test') && ( */}
            {permissions[MODULELIST.TEMPLATE].includes("execute") && (
              <WorkflowButton
                text="Test"
                bgColor=" #004357"
                icon={<PlayArrowRoundedIcon />}
                onClick={() => setOpenTestModal(true)}
              />
            )}
          </Box>
        </>
      )}

      <BasicModal
        open={openTestModal}
        onClose={handleTestModalClose}
        children={
          <TestTemplateModal
            selectedChannel={selectedChannel}
            selectedTemplate={selectedTemplate}
            onClose={handleTestModalClose}
          />
        }
      />
    </>
  );
};

export default TemplatePreviews;
