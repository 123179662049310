import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { IoIosArrowForward, IoIosArrowUp } from 'react-icons/io';
import useStyles from './index.styles';
import { campaignOptions } from '../constants';
import AddFilter from './CollapseOption/add_filter';
import SelectChannelTemplate from './CollapseOption/select_channel';
import CampaignSchedule from './CollapseOption/schedule';
import { useNavigate } from 'react-router-dom';

const CampaignOption = ({
  option,
  openCollapse,
  handleCollapse,
}: {
  option: 'add_filter' | 'select_channel' | 'schedule';
  openCollapse: string[];
  handleCollapse: (x: string) => void;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const index = campaignOptions[option]['index'];
  const label = campaignOptions[option]['label'];
  const isOpen = openCollapse.includes(index);

  return (
    <Box mb='1rem'>
      <Box
        className={classes.optionWrapper}
        onClick={() => handleCollapse(index)}
      >
        <Box className={classes.indexCont}>
          <Typography fontSize='1.4rem' mt='6px'>
            {index}
          </Typography>
        </Box>
        <Box className={classes.optionCont}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            {isOpen ? (
              <IoIosArrowUp fontSize='1.2rem' />
            ) : (
              <IoIosArrowForward fontSize='1.2rem' />
            )}

            <Typography fontSize='1.2rem' fontWeight={600} mt='5px'>
              {label}
            </Typography>
          </Box>

          {option === 'add_filter' && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              onClick={() => navigate('/campaigns/customer-data')}
            >
              <Typography>View Customer Data</Typography>
              <CustomIcon name='share' color='#49F0AC' size={30} />
            </Box>
          )}
        </Box>
      </Box>

      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <Box className={classes.filterDataCont}>
          {option === 'add_filter' && <AddFilter />}
          {option === 'select_channel' && <SelectChannelTemplate />}
          {option === 'schedule' && <CampaignSchedule />}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CampaignOption;
