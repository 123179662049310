import {
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';
import { SlArrowDown } from 'react-icons/sl';

interface FormikTemplateActionSelectProps {
  placeholder?: string;
  label?: string;
  width?: number;
  name: string;
  options: any;
  small?: boolean;
  handleUpdate: (x: string) => void;
}

const FormikTemplateActionSelectWrapper = styled('div')(
  ({ label }: { label?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: label ? '1rem' : 0,
    gap: '10px',

    '& label': {
      color: kollectAITheme.primaryColor,
      '& p': {
        fontFamily: 'Open Sans',
        fontSize: '0.8rem',
        fontWeight: 700,
      },
    },
  })
);

const FormikTemplateActionSelect: React.FC<FormikTemplateActionSelectProps> = ({
  placeholder,
  label,
  width = 110,
  name,
  options,
  handleUpdate,
}) => {
  return (
    <FormikTemplateActionSelectWrapper label={label}>
      {label && (
        <label>
          <Typography fontFamily='Overpass'>{label}</Typography>
        </label>
      )}
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { value } = field;

          return (
            <FormControl
              sx={{
                width,
                zIndex: 10,
                '@media (min-width: 1801px)': {
                  minWidth: '180px !important',
                },
                '@media (min-width: 1601px)': {
                  minWidth: 140,
                },
              }}
            >
              <Select
                displayEmpty
                value={value || ''}
                onChange={(e: SelectChangeEvent) =>
                  handleUpdate(e.target.value)
                }
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  position: 'relative',
                  borderRadius: 0,
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  textAlign: 'center',
                  height: '38px',
                  '.MuiSelect-select': {
                    padding: '12px 10px 10px 14px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                IconComponent={() => (
                  <SlArrowDown
                    style={{
                      fontSize: '1.1rem',
                      marginRight: '1rem',
                      position: 'absolute',
                      right: 0,
                    }}
                  />
                )}
              >
                {placeholder && (
                  <MenuItem disabled value=''>
                    <Typography color='#757575'>{placeholder}</Typography>
                  </MenuItem>
                )}
                {options?.map((type: any, index: number) => (
                  <MenuItem value={type.value} key={index}>
                    {type?.label}
                  </MenuItem>
                ))}
              </Select>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          );
        }}
      </Field>
    </FormikTemplateActionSelectWrapper>
  );
};

export default FormikTemplateActionSelect;
