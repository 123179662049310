import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginSubmitButton: {
      width: '100%',
      background: theme.kollectAITheme.primaryColor,
      borderRadius: '8px',
      border: 'transparent',
      color: '#fff',
      fontSize: '1.2em',
      height: '45px',
      cursor: 'pointer',
    },
    forgotPasswordButton: {
      color: theme.kollectAITheme.primaryColor,
      cursor: 'pointer',
      marginTop: '5px',
      display: 'inline-block',
    },
    signUpButtonStyle: {
      color: theme.kollectAITheme.primaryColor,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
