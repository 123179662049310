import api from '../api';
import kollectAiAuth from './config';

const authApi = {
  async login(payload: any) {
    return api.post(kollectAiAuth.login, payload);
  },

  async signup(payload: any) {
    return api.post(kollectAiAuth.signup, payload);
  },

  async verifyEmailToken(payload: any) {
    const url = `${kollectAiAuth.verifyEmailToken}?token=${payload.token}`;
    return api.get(url);
  },

  async generateOtp(payload: any) {
    return api.post(kollectAiAuth.generateOtp, payload);
  },

  async resendOtp(payload: any) {
    return api.post(kollectAiAuth.resendOtp, payload);
  },

  async verifyOtp(payload: any) {
    return api.post(kollectAiAuth.verifyOtp, payload);
  },

  async resendEmailVerification(payload: any) {
    return api.post(kollectAiAuth.resendEmailVerification, payload);
  },

  async forgotPassword(payload: any) {
    return api.post(kollectAiAuth.forgotPassword, payload);
  },

  async resetPassword(payload: any) {
    return api.post(kollectAiAuth.resetPassword, payload);
  },

  async refreshToken(payload: any) {
    return api.post(kollectAiAuth.refreshToken, payload);
  },

  async getUserConfig() {
    return api.get(kollectAiAuth.userConfig);
  },

  async duplicatePhoneCheck(payload: {
    phone_country_code: string;
    phone: string;
  }) {
    return api.post(`${kollectAiAuth.duplicatePhoneCheck}/`, payload);
  },

  async defaultSubscription(payload: {
    token: string;
    subscription_start_ts: string;
  }) {
    return api.post(kollectAiAuth.defaultSubscription, payload);
  },

  async getSubscriptionConfig() {
    return api.get(kollectAiAuth.subscriptionConfig);
  },
};

export default authApi;
