import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const ActionButton = styled('button')(
  ({ bg, isBack }: { bg: string; isBack?: boolean }) => ({
    background: bg,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isBack ? '4px' : '7px',
    padding: isBack ? '12px 16px 10px' : '6px 25px 4px',
    '& p': { color: '#fff !important' },
  })
);

export const selectStyle = {
  position: 'relative',
  borderRadius: 0,
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '.MuiSelect-select': {
    padding: '12px 10px 10px 14px',
    color: kollectAITheme.primaryColor,
    fontWeight: 600,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.04)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.04)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.04)',
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    testButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '7px',
      color: '#004357',
      padding: '6px 25px 4px',
      border: '1px solid #004357',
    },
    actionButtonCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
      marginRight: '2rem',
    },
    selectIcon: {
      fontSize: '2rem',
      marginRight: '1rem',
      position: 'absolute',
      right: 0,
      color: theme.kollectAITheme.primaryColor,
    },
  })
);

export default useStyles;
