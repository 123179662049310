import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Form, Formik, FormikValues } from 'formik';
import CustomFormikInput from 'components/formik/FormikInput';
import CustomFormikTextarea from 'components/formik/FormikTextarea';
import useStyles, { SubmitButton } from './index.styles';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  FormikCampaignCheckbox,
  FormikCampaignSelectFile,
} from 'components/formik/campaign';
import { lambdaUserVerification } from 'apis/aws/lambdaUserVerification';
import {
  setCampaignActionType,
  // setInitialCampaign,
} from 'store/reducer/campaignReducer';
import Loader from 'components/loader';
// import CustomModal from 'components/Modal/CustomModal';
import InvalidEntries from './InvalidEntries';
import { awsUrl } from 'store/reducer/auth';

const CreateCampaignModal = ({ onClose }: { onClose: () => void }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const campaignNames = useAppSelector(
    (state: any) => state.campaign.campaignSummary
  )?.search_lists?.campaign_name_list;
  const urlObj = useAppSelector(awsUrl)?.file_upload;

  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(null);
  const [createError, setCreateError] = useState<any>(null);
  const [campaignNameList, setCampaignNameList] = useState<string[]>([]);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (campaignNames?.length) {
      const list = campaignNames.map((name: string) => name.toLowerCase());
      setCampaignNameList(list);
    }
  }, [campaignNames]);

  const handleSubmit = async (values: FormikValues) => {
    const { name, description, population } = values;
    //  let file = population;
    //  let newFile = new File([file], 'new_file_name.csv', {
    //    type: file.type,
    //  });

    if (population && urlObj) {
      setLoading(true);
      const res = await lambdaUserVerification({
        urlObj,
        file: population,
        type: 'campaign',
        campaign_name: name,
        campaign_desc: description,
      });
      if (res!.success) {
        dispatch(setCampaignActionType('create'));
        navigate(`${res?.data?.campaign_id}`);
      } else {
        if (res?.status === 500) {
          setCreateError(res!.data);
          setFormValues(values);
          setShowInvalid(true);
        }
      }
      setLoading(false);
    }
  };

  const handleInvalidConfirm = async () => {
    const { name, description, population } = formValues;

    if (population && urlObj) {
      setLoading(true);
      const res = await lambdaUserVerification({
        urlObj,
        file: population,
        type: 'campaign',
        campaign_name: name,
        campaign_desc: description,
        ignore_errors: 'true',
      });
      if (res!.success) {
        dispatch(setCampaignActionType('create'));
        navigate(`${res?.data?.campaign_id}`);
        onClose();
      }
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Required')
      .test('duplicate', 'Campaign name already exists!', (value) => {
        if (value && campaignNameList.includes(value.toLowerCase())) {
          return false;
        }
        return true;
      }),
    population: yup.mixed().required('File is required'),
  });

  return (
    <>
      <Loader loading={loading} />

      {!showInvalid ? (
        <Box sx={{ position: 'relative', '& p': { fontFamily: 'Overpass' } }}>
          <Box className={classes.createCloseButton} onClick={() => onClose()}>
            <AiFillCloseCircle />
          </Box>

          <Typography fontWeight={600} fontSize='1.5rem' mb='1rem'>
            Create a New Campaign
          </Typography>

          <Formik
            initialValues={{
              name: '',
              description: '',
              population: null,
              has_pii: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <CustomFormikInput
                  label='Add Name'
                  name='name'
                  type='text'
                  placeholder='Campaign Name'
                />

                <CustomFormikTextarea
                  label='Add Description'
                  name='description'
                  placeholder='Description'
                />

                <FormikCampaignSelectFile name='population' />

                <FormikCampaignCheckbox name='has_pii' label='Has PII Data?' />

                <Box display='flex' justifyContent='center' mt='2rem'>
                  <SubmitButton type='submit'>Create</SubmitButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box position='relative'>
          <InvalidEntries
            errors={createError}
            onClose={onClose}
            handleConfirm={handleInvalidConfirm}
          />
        </Box>
      )}
    </>
  );
};

export default CreateCampaignModal;
