import { Box } from '@mui/material';
import ChannelSummaryCalendar from './calendar';

const ChannelSchedule = (props: any) => {
  return (
    <Box marginBottom='2rem' paddingX='1.2rem' marginTop={'3.12rem'}>
      <ChannelSummaryCalendar {...props} />
    </Box>
  );
};

export default ChannelSchedule;
