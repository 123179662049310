import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import useStyles from './index.styles';

interface CustomSelectBoxProps {
  labels: string[];
  // filterChange: (x: string, y: string) => void;
}

const CustomRadioSelect = (props: CustomSelectBoxProps) => {
  const { labels } = props;
  // const { labels, filterChange } = props;
  const classes = useStyles();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (labels.length) setValue(labels[0]);
  }, [labels]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setValue(selectedValue);
    // filterChange(selectedValue);
  };

  return (
    <div>
      <div className={classes.radioGroupContainer}>
        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='controlled-radio-buttons-group'
          defaultValue={value}
          value={value}
          onChange={handleChange}
        >
          {labels.map((label, index) => {
            return (
              <FormControlLabel
                key={index}
                value={label}
                classes={{ label: classes.label }}
                control={
                  <Radio
                    size='small'
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={label}
              />
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
};

export default CustomRadioSelect;
