import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    channelBoxContainer: {
      padding: '4px 25px',
      borderRadius: '11px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
    },
  })
);

export default useStyles;
