import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import {
  FormikCustomerCheckbox,
  FormikCustomerDatePicker,
  FormikCustomerInput,
  FormikCustomerSelect,
  FormikCustomerTextarea,
  FormikCustomerTimePicker,
} from 'components/formik/customer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { customerAgentUpdate } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import { customerAgentsList } from 'store/reducer/customerReducer';
import { PrioritySlider, PrioritySliderCont } from '../AddRequest/index.styles';

interface AddTaskFormProps {
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  heading: yup.string().required('Required'),
  agent: yup.string().required('Required'),
  priority: yup.number().required('Required'),
  date: yup
    .date()
    .typeError('Invalid date')
    .nullable()
    .when('setTime', {
      is: true,
      then: yup.date().typeError('Invalid date').required('Required'),
    }),
  time: yup
    .string()
    .typeError('Required')
    .nullable()
    .when('setTime', {
      is: true,
      then: yup.string().typeError('Required').required('Required'),
    }),
});

const marks = [
  { value: 0, label: 'Low' },
  { value: 50, label: 'Med' },
  { value: 100, label: 'High' },
];

const initialValues = {
  heading: '',
  agent: '',
  setTime: false,
  date: '',
  time: '',
  priority: 0,
  task_details: '',
};

const AddTaskForm: React.FC<AddTaskFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { id: customer_id = '' } = useParams();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const agentsList = useAppSelector(customerAgentsList);
  const loggedInEmail = useAppSelector((state: any) => state.auth.email);

  const [loading, setLoading] = useState<boolean>(false);
  const [agentOptions, setAgentOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    if (agentsList && agentsList.length) {
      let options = [];
      const loggedAgent: any = agentsList.find(
        (agent: Record<string, any>) => agent.email === loggedInEmail
      );
      if (loggedAgent)
        options.push({ label: 'Myself', value: loggedAgent.user_id });
      const agents = agentsList.filter(
        (agent: Record<string, any>) => agent.email !== loggedInEmail
      );
      if (agents.length) {
        const agentOptions = agents.map((agent: Record<string, any>) => ({
          label: agent.name,
          value: agent.user_id,
        }));
        options = [...options, ...agentOptions];
      }
      setAgentOptions(options);
    }
  }, [agentsList, loggedInEmail]);

  const handleSubmit = async (values: FormikValues) => {
    try {
      setLoading(true);
      const { heading, agent, setTime, date, time, priority, task_details } =
        values;
      const payload = {
        odf_customer_id: customerOdfId,
        customer_id,
        agent_id: agent,
        priority: getPriority(+priority),
        task_details,
        heading,
        ...(setTime && { due_date: date, due_time: time }),
      };
      await dispatch(customerAgentUpdate(payload));
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const getPriority = (priority: number) => {
    if (priority === 0) return 'Low';
    if (priority === 50) return 'Med';
    if (priority === 100) return 'High';
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '1rem',
              }}
            >
              <Typography>Task Heading</Typography>
              <FormikCustomerInput name='heading' width={178} />
            </Box>

            <Box mb='1rem'>
              <FormikCustomerSelect
                name='agent'
                label='Assign this task to'
                placeholder='Agent Name'
                options={agentOptions}
              />
            </Box>

            <PrioritySliderCont>
              <Typography>Priority</Typography>
              <PrioritySlider
                track={false}
                aria-labelledby='track-false-slider'
                defaultValue={0}
                step={50}
                marks={marks}
                value={values.priority}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setFieldValue('priority', value);
                }}
              />
            </PrioritySliderCont>

            <Box mb='1.5rem' mt='2rem'>
              <FormikCustomerCheckbox
                name='setTime'
                label='Want to set a due date/time for this task'
              />
              {values?.setTime && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <FormikCustomerDatePicker name='date' />
                  <FormikCustomerTimePicker name='time' />
                </Box>
              )}
            </Box>

            <FormikCustomerTextarea
              name='task_details'
              label='Please add notes/comments for agent to review'
            />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Update</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTaskForm;
