import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const styles = useStyles();
  return (
    <Box className={styles.mainWrapper} sx={{ height: '100%' }}>
      <Box className={styles.textContainer}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography color='#32475CDE' variant='h5'>
            Page Not Found ⚠️
          </Typography>
          <Typography color='#32475C99'>
            we couldn't find the page you are looking for
          </Typography>
        </Box>

        <img
          src={require('assets/not-found.png')}
          alt=''
          className={styles.notFoundImg}
        />

        <Link to='/' className={styles.gotoDashbaordButton}>
          <Typography>Back To DASHBOARD</Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
