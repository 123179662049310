import Editor, { Monaco } from '@monaco-editor/react';
import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikIntegrationsEditorProps {
  name: string;
  label: string;
  disabled: boolean;
}

const FormikIntegrationsEditorWrapper = styled('div')({
  marginBottom: '1rem',
  display: 'flex',
  alignItems: 'start',
  width: '100%',

  '& label': { width: 200, color: '#272D3B', opacity: '0.5' },
  // '& textarea': {
  //   outline: 'none',
  //   border: '1px solid #CCCCCC',
  //   height: '100px',
  //   padding: '10px',
  //   fontFamily: 'Overpass',
  //   fontSize: '1rem',
  //   color: '#000',
  // },
});

// const editorOptions = {
//   formatOnPaste: true,
//   minimap: { enabled: false },
// };

// function handleEditorWillMount(monaco: Monaco) {
//   monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
//     allowComments: true,
//     comments: 'ignore',
//   });
// }

const FormikIntegrationsEditor: React.FC<FormikIntegrationsEditorProps> = ({
  name,
  label,
  disabled,
}) => {
  return (
    <FormikIntegrationsEditorWrapper>
      <label>
        <Typography fontWeight={600}>{label}</Typography>
      </label>

      <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box>
              {/* <textarea placeholder={placeholder} {...field} /> */}
              <Editor
                width={500}
                height={300}
                defaultLanguage='json'
                value={value}
                theme='light'
                options={{
                  readOnly: disabled,
                  formatOnPaste: true,
                  minimap: { enabled: false },
                }}
                onChange={(value) => setFieldValue(name, value)}
                // beforeMount={handleEditorWillMount}
              />
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </FormikIntegrationsEditorWrapper>
  );
};

export default FormikIntegrationsEditor;
