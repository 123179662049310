import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  CustomerCallInput,
  CustomerCallSelect,
} from 'components/formik/customer/call';

const UnableToPay = () => {
  return (
    <Box>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <Form>
            <CustomerCallSelect
              label='Specify more*'
              name='specify_more'
              options={[]}
            />

            <CustomerCallSelect
              label='Type of Hardship'
              name='hardship'
              options={[]}
            />

            <CustomerCallInput name='income' label='Monthly Income' />

            <CustomerCallInput name='expense' label='Monthly Expense' />

            <CustomerCallInput
              name='make_easier'
              label='What would make it easier for you to pay?'
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UnableToPay;
