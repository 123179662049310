import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import useStyles from './index.styles';

import { useAppDispatch } from 'hooks/useRedux';
import {
  allWorkflowExecution,
  getFunnelMapping,
  getWorkflowById,
} from 'store/reducer/workflowReducer/action';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import { getStrategyList } from 'store/reducer/strategyReducer/action';
import {
  setFunnelData,
  setWorkflowFunnel,
} from 'store/reducer/workflowReducer';

import Loader from 'components/loader';
import EmptyScreen from 'components/EmptyScreen';

import funnelImg from 'assets/funnel.svg';

interface Data {
  workflow_id: string;
  workflow_name: string;
  published: string;
  active: string;
  author: string;
  created_on: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue =
    // @ts-ignore
    typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const bValue =
    // @ts-ignore
    typeof a[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'workflow_id',
    label: 'ID',
  },
  {
    id: 'workflow_name',
    label: 'NAME',
  },
  {
    id: 'published',
    label: 'PUBLISHED ',
  },
  {
    id: 'active',
    label: 'STATUS',
  },
  {
    id: 'author',
    label: 'AUTHOR',
  },
  {
    id: 'created_on',
    label: 'CREATED ON',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

interface WorkflowDetailsTableProps {
  data: any;
  isSearchData: boolean;
  selected: readonly string[];
  setSelected: (x: any) => void;
  hanldeCreateModal: () => void;
}

export default function WorkflowDetailsTable({
  data,
  selected,
  isSearchData,
  setSelected,
  hanldeCreateModal,
}: WorkflowDetailsTableProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('created_on');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.workflow_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const viewWorkflow = async (workflowId: string) => {
    try {
      setLoading(true);
      await dispatch(getWorkflowById({ workflow_id: workflowId })).unwrap();
      await dispatch(viewChannelTemplate({ channel_type: 'all' }));
      await dispatch(getStrategyList());
      navigate('/workflow-editor');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleFunnelView = async (workflow: any) => {
    try {
      setLoading(true);
      const { workflow_id, last_execution_date, last_execution_id } = workflow;
      dispatch(
        setWorkflowFunnel({
          viewFunnel: true,
          workflowId: workflow_id,
          executionId: last_execution_id,
        })
      );
      const date = moment(last_execution_date).format('YYYY-MM-DD');
      await dispatch(
        getFunnelMapping({ workflow_id, date, execution_id: last_execution_id })
      ).unwrap();
      await dispatch(allWorkflowExecution({ workflowId: workflow_id, date }));
      dispatch(
        setFunnelData({
          executionId: last_execution_id,
          workflowId: workflow_id,
          date,
        })
      );
      viewWorkflow(workflow_id);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Loader loading={loading} />
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .slice()
                .sort(getComparator(order, orderBy))
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.workflow_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.workflow_id}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleClick(event, row.workflow_id)
                          }
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell scope='row' padding='none'>
                        {row.workflow_id}
                      </TableCell>
                      <TableCell align='left'>{row.workflow_name}</TableCell>
                      <TableCell align='left'>
                        {row.published ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align='left'>
                        {row.active ? (
                          <Box
                            sx={{
                              padding: '5px 10px',
                              background: '#6AC5AB',
                              borderRadius: '7px',
                              color: '#fff',
                              textAlign: 'center',
                              display: 'inline',
                            }}
                          >
                            Active
                          </Box>
                        ) : (
                          <Box>Inactive</Box>
                        )}
                      </TableCell>
                      <TableCell align='left'>{row.author}</TableCell>
                      <TableCell align='left'>{row.created_on}</TableCell>
                      <TableCell align='left'>
                        <button
                          className={classes.viewWorkflowButton}
                          onClick={() => {
                            dispatch(
                              setWorkflowFunnel({
                                viewFunnel: false,
                                workflowId: '',
                              })
                            );
                            viewWorkflow(row.workflow_id);
                          }}
                        >
                          View
                        </button>
                      </TableCell>
                      <TableCell>
                        {/* {row.active && row.published ? ( */}
                        {row.last_execution_id && row.last_execution_date ? (
                          <button onClick={() => handleFunnelView(row)}>
                            <img src={funnelImg} alt='' />
                          </button>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {(!data || !data?.length) && (
          <EmptyScreen
            table='workflow'
            onClick={hanldeCreateModal}
            isSearchData={isSearchData}
          />
        )}
      </Paper>
    </Box>
  );
}
