import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import './styles.css';
import TemplatePreviewModal from 'components/templates/templatePreview';
import CustomIcon from 'components/customIcons';
import { kollectAITheme } from 'config/theme';

interface OptionsList {
  id?: string;
  template: string;
  template_name: string;
}

type CustomSelectProps = {
  channel: string;
  optionsList?: OptionsList[];
  selected?: boolean;
  selectedOptionValue: OptionsList;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  channel,
  optionsList = [],
  selected,
  selectedOptionValue,
}) => {
  const [showOptionList, setShowOptionList] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOption(selectedOptionValue);
  }, [selectedOptionValue]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setShowOptionList(false);
  };

  const handleListDisplay = () => setShowOptionList((state) => !state);

  const handleTemplatePreview = () => {
    setOpenPreviewModal(true);
  };

  const handlePreviewClose = () => setOpenPreviewModal(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className='customSelectContainer'>
        <div
          className={showOptionList ? 'selectedText active' : 'selectedText'}
          onClick={handleListDisplay}
        >
          {selectedOption?.template_name}
          <BsChevronDown />
        </div>

        {showOptionList && (
          <ul className='selectOptions'>
            {optionsList?.map((option) => {
              return (
                <div key={option.id}>
                  <li
                    className={`customSelectOption ${
                      option.id === selectedOption.id
                        ? 'customOptionSelected'
                        : ''
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.template_name}
                  </li>
                </div>
              );
            })}
          </ul>
        )}
      </div>

      {selected ? (
        <div className='previewButton' onClick={handleTemplatePreview}>
          <CustomIcon
            name={'template-preview'}
            color={kollectAITheme.primaryColor}
          />
        </div>
      ) : (
        <div className='previewButton' onClick={handleTemplatePreview}>
          <CustomIcon
            name={'template-preview'}
            color={kollectAITheme.lightPrimaryColor}
          />
        </div>
      )}

      <TemplatePreviewModal
        open={openPreviewModal}
        onClose={handlePreviewClose}
        selectedOption={selectedOption}
        channel={channel}
      />
    </div>
  );
};

export default CustomSelect;
