import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikFilterInputProps {
  name: string;
  placeholder?: string;
}

const FormikFilterInputWrapper = styled('div')(() => ({
  // marginBottom: '1rem',
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '& label': { width: '240px' },
  '& input': {
    border: '1px solid rgba(0, 0, 0, 0.04)',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '5px 0.7rem 0',
    fontFamily: 'Overpass',
    outline: 'none',
    fontSize: '1rem',
    minHeight: '38px',
    width: 180,
  },
}));

const FormikFilterInput: React.FC<FormikFilterInputProps> = ({
  name,
  placeholder = '',
}) => {
  return (
    <FormikFilterInputWrapper>
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <Box width='100%'>
            <input type='text' placeholder={placeholder} {...field} />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        )}
      </Field>
    </FormikFilterInputWrapper>
  );
};

export default FormikFilterInput;
