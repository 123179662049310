import { useState } from 'react';
import './styles.css';

interface PropsType {
  channelName: string;
  channelFlag: boolean;
  channelSelected: boolean;
  handleClick: (x: string) => void;
  handleSelect: (x: string, y: boolean) => void;
}

const ChannelBox = (props: PropsType) => {
  const {
    channelSelected,
    channelName,
    channelFlag,
    handleClick,
    handleSelect,
  } = props;

  const [isChecked, setIsChecked] = useState<boolean>(channelFlag);

  const handleChannelSelect = () => handleClick(channelName);

  const handleChange = () => {
    handleSelect(channelName, !isChecked);
    setIsChecked((prev) => !prev);
  };

  return (
    <div
      className={`channelBoxWrapper ${
        channelSelected ? 'channelBoxSelected' : ''
      }`}
      onClick={handleChannelSelect}
    >
      <div className='channelItemContainer'>
        <div className='channelName'>{channelName}</div>
        <div className='checkboxWrapper'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleChange}
            className={isChecked ? 'checked' : ''}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ChannelBox;
