import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      width: '100vw',
      display: 'grid',
      placeContent: 'center',
      backgroundColor: '#fff',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
    },
    gotoDashbaordButton: {
      background: theme.kollectAITheme.primaryColor,
      color: '#fff',
      borderRadius: '6px',
      textDecoration: 'none',
      padding: '0.5rem 1.5rem',
    },
    notFoundImg: {
      width: '400px',
    },
  })
);

export default useStyles;
