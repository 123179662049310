const kollectAiWorkflow = {
  getWorkflow: 'kollectai/api/v1/workflow/get_workflow',
  createWorkflow: 'kollectai/api/v1/workflow/create_workflow',
  getWorkflowById: 'kollectai/api/v1/workflow/get_workflow_by_id',
  workflowHandler: 'kollectai/api/v1/workflow/workflow_handler',
  getStrategyGroup: 'kollectai/api/v1/strategy/get_strategy_groups',
  getStrategyByGroupId:
    '/kollectai/api/v1/strategy/get_strategies_by_strategy_group_id',
  filterWorkflow: '/kollectai/api/v1/workflow/get_workflow',
  getFunnelMapping: '/kollectai/api/v1/workflow/get_funnel_mapping',
  runWorkflow: '/kollectai/api/v1/workflow/run_realtime_workflow',
  allWorkflowExecution: '/kollectai/api/v1/workflow/all_workflow_execution',
  getSpecificFunnel: '/kollectai/api/v1/workflow/get_specific_customer_funnel',
  workflowCustomerDetails:
    '/kollectai/api/v1/workflow/workflow_customer_details',
  workflowProperties: '/kollectai/api/v1/workflow/get_workflow_properties',
};

export default kollectAiWorkflow;
