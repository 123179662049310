import IcoMoon from "react-icomoon";
import iconPaths from "./selection.json";

export type CustomIconProps = {
  name?: string;
  color?: string;
  size?: number | string;
  disableFill?: boolean;
};

const CustomIcon: React.FC<CustomIconProps> = ({
  name = "homeIcon",
  color,
  size,
  disableFill = true,
  ...props
}) => {
  return (
    <IcoMoon
      iconSet={iconPaths}
      icon={name}
      color={color}
      size={size}
      disableFill={disableFill}
      {...props}
    />
  );
};

export default CustomIcon;
