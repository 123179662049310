import { AiOutlineSend } from 'react-icons/ai';
import {
  BiCommentAdd,
  BiDonateBlood,
  BiMeh,
  BiPencil,
  BiShuffle,
  BiSupport,
} from 'react-icons/bi';
import { FaExchangeAlt } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';

export const actions = [
  {
    label: 'Update Status',
    apiIdentifier: 'change_customer_status',
    icon: BiShuffle,
    color: '#2C62EC',
  },
  {
    label: 'Move to Queue',
    apiIdentifier: 'move_specific_queue',
    icon: FaExchangeAlt,
    color: '#FF7A00',
    size: 20,
  },
  {
    label: 'Edit Data',
    apiIdentifier: 'edit_customer_data',
    icon: BiPencil,
    color: '#0069AA',
  },
  {
    label: 'Customer Request',
    apiIdentifier: 'add_customer_request',
    icon: BiMeh,
    color: '#C07CA9',
    size: 26,
  },
  {
    label: 'Custom Plan',
    apiIdentifier: 'create_custom_plan',
    icon: BiDonateBlood,
    color: '#49F0AC',
    size: 26,
  },
  // {label:'Assign to Agent',apiIdentifier:"assign_to_agent"},
  {
    label: 'Add Task',
    apiIdentifier: 'add_task',
    icon: BiSupport,
    color: '#FFAB00',
  },
  {
    label: 'Send communication',
    apiIdentifier: 'send_communication',
    icon: AiOutlineSend,
    color: '#0F91D2',
    size: 26,
  },
  {
    label: 'Add Notes',
    apiIdentifier: 'add_notes',
    icon: BiCommentAdd,
    color: '#969696',
  },
  {
    label: 'Delete Customer',
    apiIdentifier: 'delete_customer',
    icon: MdDeleteForever,
    color: '#BB7065',
    size: 26,
  },
];

export const listingActions = {
  change_customer_status: 'True',
  move_specific_queue: 'True',
  send_communication: 'True',
  add_notes: 'True',
  delete_customer: 'True',
};
