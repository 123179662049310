import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikCustomerSelect } from 'components/formik/customer';
import { Form, Formik } from 'formik';
import { createSelectOption } from 'utils/helper';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';

const categories = [
  'Update issue',
  'Delay in payment',
  'Incorrect communication',
  'Unsubscription',
  'Need Restructuring',
  'Waiver Request',
  'Email Change',
  'Contact Change',
  'General/Misc',
];
const categoryOptions = createSelectOption({ arr: categories });

const validationSchema = yup.object().shape({
  category: yup.string().required('Required'),
});

const AddCustomerRequestForm: React.FC<FormPropsType> = ({
  path,
  value,
  actionPath,
  onClose,
  setFieldValue,
}) => {
  const [inititalState, setInititalState] = useState({ category: '' });

  useEffect(() => {
    if (value) {
      setInititalState({ category: value });
    }
  }, [value]);

  const handleSubmit = (values: any) => {
    setFieldValue(actionPath, 'add_customer_request');
    setFieldValue(path, values.category);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box>
            <Typography mb='1rem'>
              Please select the type of category under which this request will
              be saved
            </Typography>

            <FormikCustomerSelect
              name='category'
              placeholder='Select Category'
              options={categoryOptions}
            />
          </Box>

          <ActionButtonWrapper>
            <ActionButton type='submit' color='#6AC5AB'>
              <Typography>Save</Typography>
            </ActionButton>
          </ActionButtonWrapper>
        </Form>
      </Formik>
    </>
  );
};

export default AddCustomerRequestForm;
