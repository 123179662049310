import moment from 'moment';
import { SlotDuration } from 'containers/campaigns/CampaignDetails/Performance/PenetrationGraph';

export const storageName = 'campaignData';

export const customFilterData = (data: any) => {
  return data.map((filter: any) => {
    if (filter?.stats) {
      return {
        '#customers': filter?.stats?.number_of_customer,
        outstanding_balance: filter?.stats?.outstanding_amount,
        average_dpd: filter?.stats?.average_dpd,
        condition: filter.condition,
        priority: filter?.filter_priority,
        group_id: filter?.group_id,
      };
    }
    return filter;
  });
};

export const getCampaignGroups = ({
  filter,
  value,
}: {
  filter: string;
  value: any;
}) => {
  let groups = [];

  switch (filter) {
    case 'group':
      let data = { ...value };
      data.condition = 'All';
      groups.push(data);
      break;
    case 'split':
      let segments: any = [];
      const condition = Object.keys(value)[0];
      const segData = value[condition];
      let segArr = [];
      for (let key in segData) {
        segArr.push(segData[key]);
      }
      segArr.forEach((item: any) => {
        let segment = { ...item };
        segment.condition = `${condition} ${segment.filter}`;
        segments.push(segment);
      });
      groups = segments;
      break;
    case 'add_custom':
      let filters: any = [];
      Object.values(value).forEach((item: any) => {
        if (typeof item === 'object') {
          let filter = { ...item };
          filters.push(filter);
        }
      });
      groups = filters;
      break;
    case 'ab_testing':
      let rows = [];
      for (let key in value) {
        const row = {
          ...value[key],
          condition: `Split ${key}`,
        };
        rows.push(row);
      }
      groups = rows;
      break;
  }

  return groups;
};

export const getCampaignPayload = (data: any) => {
  const based_on = data.based_on;
  let add_filter = {
    filter: 'group',
    value: {},
  };
  let select_channel_template: any[] = [];
  const segments = [
    'dpd_bucket',
    'product_type',
    'balance_bucket',
    'customer_status',
  ];

  if (based_on === 'all') {
    const groupData = data?.group_as_one;
    const { filteredData, channels } = getValueChannel(groupData);
    add_filter.value = filteredData;
    select_channel_template = [channels];
  }

  if (segments.includes(based_on)) {
    const segData = data?.default_data?.[based_on];
    add_filter.filter = 'split';
    let selectedChannel: any[] = [];
    let value: any = {};
    const entries = Object.entries(segData);
    entries.forEach((item: any, index: number) => {
      const { filteredData, channels } = getValueChannel(item[1]);
      Object.keys(channels).length && selectedChannel.push(channels);
      value[index] = filteredData;
    });
    add_filter.value = { [based_on]: value };
    select_channel_template = selectedChannel;
  }

  if (based_on === 'custom_filter') {
    add_filter.filter = 'add_custom';
  }

  if (based_on === 'A_B') {
    const abData = data?.default_ab_data;
    add_filter.filter = 'ab_testing';

    let selectedChannel: any[] = [];
    let ab: any = { A: {}, B: {} };
    const entries = Object.entries(abData);
    entries.forEach((item: any) => {
      const { filteredData, channels } = getValueChannel(item[1]);
      Object.keys(channels).length && selectedChannel.push(channels);
      ab[item[0]] = filteredData;
    });
    add_filter.value = ab;
    select_channel_template = selectedChannel;
  }

  let schedule_time: any = 'now';
  if (
    data?.schedule_time &&
    // @ts-ignore
    new Date(data.schedule_time) !== 'Invalid Date' &&
    // @ts-ignore
    !isNaN(new Date(data.schedule_time))
  ) {
    const date = moment(data.schedule_time).format('YYYY-MM-DD');
    const time = moment(data.schedule_time).format('hh:mm');
    schedule_time = { date, time };
  }

  return {
    add_filter,
    select_channel_template,
    schedule_time,
  };
};

export const getCustomSelectedChannelPayload = (data: any) => {
  let selectedChannels: any[] = [];
  data.forEach((item: any) => {
    const { channels } = getValueChannel(item?.template_info);
    if (Object.keys(channels).length) {
      let chann = { ...channels, group_id: item.group_id };
      selectedChannels.push(chann);
    }
  });
  return selectedChannels;
};

export const filterAbData = (data: any) => {
  let ab: any = { A: {}, B: {} };
  const entries = Object.entries(data);
  entries.forEach((item: any) => {
    const { filteredData } = getValueChannel(item[1]);
    ab[item[0]] = filteredData;
  });
  return ab;
};

export const getValueChannel = (data: any, isAb?: boolean) => {
  let dataKeys = [
    'product_type',
    'outstanding_balance',
    'average_dpd',
    'avg_dpd',
    'group_id',
    '#customers',
    'distribution',
    'avg_dpd',
    'filter',
  ];
  const entries = Object.entries(data);
  let filteredData = Object.fromEntries(
    entries.filter(([k]) => dataKeys.includes(k))
  );
  let channelData: any = Object.fromEntries(
    entries.filter(([k]) => !dataKeys.includes(k))
  );
  channelData = Object.fromEntries(
    Object.entries(channelData).filter(([_, v]) => v)
  );

  let channels: any = {};
  if (Object.keys(channelData).length) {
    channels.group_id = filteredData?.group_id;
    if (channelData?.email)
      channels = {
        ...channels,
        email: 'True',
        email_template_id: channelData?.email_template_id,
      };
    if (channelData?.sms)
      channels = {
        ...channels,
        sms: 'True',
        sms_template_id: channelData?.sms_template_id,
      };
    if (channelData?.ivr)
      channels = {
        ...channels,
        ivr: 'True',
        ivr_template_id: channelData?.ivr_template_id,
      };
    if (channelData?.whatsapp)
      channels = {
        ...channels,
        whatsapp: 'True',
        whatsapp_template_id: channelData?.whatsapp_template_id,
      };
  }

  return { filteredData, channels };
};

export const getSelectedChannels = (templates: any[], groups: any[]) => {
  let selectedChannels: any = {};
  templates.forEach((template) => {
    const index = groups.findIndex(
      (item) => item.group_id === template.group_id
    );
    let temp: any = {};
    for (let key in template) {
      if (key === 'group_id') {
        temp[key] = template[key];
      } else {
        if (key.split('_').length <= 1) {
          temp[key] = template[`${key}_template_id`];
        }
      }
    }
    if (index !== -1) selectedChannels[index] = temp;
  });

  return selectedChannels;
};

export const getPayloadGroups = (campaignPayload: any) => {
  const groups = getCampaignGroups(campaignPayload?.add_filter);
  const channelTemplate = campaignPayload?.select_channel_template;
  const newGroups = groups.map((group: any) => {
    let newGroup = { ...group };
    channelTemplate.forEach((selected: any) => {
      if (group.group_id === selected?.group_id) {
        newGroup['channels'] = selected;
      }
    });
    return newGroup['channels'];
  });
  return newGroups.filter((group: any) => group);
};

export const getRequestGroups = (campaignGroups: any[]) => {
  const groups = campaignGroups.map((group) => {
    let groupCopy = { ...group };
    if (!groupCopy.email) groupCopy.email = 'False';
    if (!groupCopy.sms) groupCopy.sms = 'False';
    if (!groupCopy.whatsapp) groupCopy.whatsapp = 'False';
    if (!groupCopy.ivr) groupCopy.ivr = 'False';
    return groupCopy;
  });
  return groups;
};

export const getScheduleTime = (timeObj: { date: string; time: string }) => {
  const concatTime = moment(
    timeObj.date + '' + timeObj.time,
    'YYYY-MM-DD HH:mm'
  ).format();
  // const UTCTime = new Date(concatTime);
  const ISOTime = new Date(concatTime).toISOString();
  return ISOTime;
};

export const getCampaignFilter = (allowedAttributes: any[]) => {
  return allowedAttributes.map((attribute) => {
    let allowedValues = attribute?.allowed_values;
    if (attribute.type === 'List') {
      allowedValues = JSON.parse(attribute?.allowed_values.replace(/'/g, '"'));
    }
    return {
      ...attribute,
      allowed_operator: attribute.allowed_operator
        .split(',')
        .map((operator: string) => operator.trim()),
      allowed_values: allowedValues,
    };
  });
};

export const getBasedOn = (payload: any) => {
  const filter = payload?.add_filter?.filter;
  const value = payload?.add_filter?.value;
  let basedOn = 'all';
  switch (filter) {
    case 'split':
      if (Object.keys(value).length === 1) {
        basedOn = Object.keys(value)[0];
      } else {
        basedOn = 'product_type';
      }
      break;
    case 'ab_testing':
      basedOn = 'A_B';
      break;
    case 'add_custom':
      basedOn = 'custom_filter';
      break;
  }

  return basedOn;
};

export const getAbTesting = (abData: any, defaultAb: any) => {
  delete abData.message;
  const aSplit = abData['A distribution'];
  const bSplit = abData['B distribution'];
  return {
    A: {
      ...defaultAb['A'],
      distribution: aSplit?.group_distribution?.a_dist,
      outstanding_balance: aSplit?.outstanding_amount,
      '#customers': aSplit['#customers'],
      average_dpd: aSplit?.average_dpd,
    },
    B: {
      ...defaultAb['B'],
      distribution: bSplit?.group_distribution?.b_dist,
      outstanding_balance: bSplit?.outstanding_amount,
      '#customers': bSplit['#customers'],
      average_dpd: bSplit?.average_dpd,
    },
  };
};

export const getCampaignFunnelData = (data: any, channel: string) => {
  if (channel === 'email') {
    const { send, delivered, open, click } = data;
    const deliveryRate = (delivered / send) * 100;
    const openRate = (open / delivered) * 100;
    const clickRate = (click / open) * 100;
    const engagement = (click / send) * 100;
    const conversion = (delivered / send) * 100;
    return {
      sent: send,
      delivered,
      open,
      click,
      deliveryRate,
      openRate,
      clickRate,
      engagement,
      conversion,
    };
  }
  if (channel === 'sms') {
    const { sent, delivered } = data;
    const deliveryRate = (delivered / sent) * 100;
    return {
      sent,
      delivered,
      deliveryRate,
      engagement: deliveryRate,
    };
  }
  if (channel === 'whatsapp') {
    const { sent, delivered, read } = data;
    const deliveryRate = (delivered / sent) * 100;
    const readRate = (read / delivered) * 100;
    return {
      sent,
      delivered,
      read,
      deliveryRate,
      readRate,
      engagement: deliveryRate,
    };
  }
  if (channel === 'ivr') {
    const { initiated, completed, busy } = data;
    const noAnswer = data?.['no-answer'];
    const completedRate = (completed / initiated) * 100;
    const busyRate = (busy / completed) * 100;
    const noAnswerRate = (noAnswer / busy) * 100;
    const engagement = (completed / initiated) * 100;
    return {
      initiated,
      completed,
      busy,
      noAnswer,
      completedRate,
      busyRate,
      noAnswerRate,
      engagement,
    };
  }
  return {};
};

export const getGoodBadContact = (data: any, channel: string) => {
  if (channel === 'email') {
    const { send, hardbounce, rejected } = data[channel];
    const badContact = hardbounce + rejected;
    const bad = (badContact / send) * 100;
    const good = ((send - badContact) / send) * 100;
    return { good, bad };
  }
  if (channel === 'sms' || channel === 'whatsapp') {
    const sent =
      channel === 'sms'
        ? data.total_sms_customers
        : data.total_whatsapp_customers;
    // const { sent, failed } = data[channel];
    const { failed } = data[channel];
    console.log({ sent, failed });
    const good = ((sent - failed) / sent) * 100;
    const bad = (failed / sent) * 100;
    return { good, bad };
  }
  if (channel === 'ivr') {
    const sent = data.total_ivr_customers;
    // const { initiated, failed } = data[channel];
    const { failed } = data[channel];
    // const good = ((initiated - failed) / initiated) * 100;
    // const bad = (failed / initiated) * 100;
    const good = ((sent - failed) / sent) * 100;
    const bad = (failed / sent) * 100;
    return { good, bad };
  }
};

export const getSlotRange = (slot_duration: SlotDuration) => {
  if (slot_duration === 1) {
    return [
      '0-1',
      '1-2',
      '2-3',
      '3-4',
      '4-5',
      '5-6',
      '6-7',
      '7-8',
      '8-9',
      '9-10',
      '10-11',
      '11-12',
      '12-13',
      '13-14',
      '14-15',
      '15-16',
      '16-17',
      '17-18',
      '18-19',
      '19-20',
      '20-21',
      '21-22',
      '22-23',
      '23-24',
    ];
  }
  if (slot_duration === 2) {
    return [
      '0-2',
      '2-4',
      '4-6',
      '6-8',
      '8-10',
      '10-12',
      '12-14',
      '14-16',
      '16-18',
      '18-20',
      '20-22',
      '22-24',
    ];
  }
  if (slot_duration === 3) {
    return ['0-3', '3-6', '6-9', '9-12', '12-15', '15-18', '18-21', '21-24'];
  }
  if (slot_duration === 4) {
    return ['0-4', '4-8', '8-12', '12-16', '16-20', '20-24'];
  }
  if (slot_duration === 6) {
    return ['0-6', '6-12', '12-18', '18-24'];
  }
  if (slot_duration === 8) {
    return ['0-8', '8-16', '16-24'];
  }
  if (slot_duration === 12) {
    return ['0-12', '12-24'];
  }
  if (slot_duration === 24) {
    return ['0-24'];
  }
  return [];
};

export const getPenetrationGraphData = (
  dayTimeData: {
    day: number;
    norm: number[];
    status: number[];
    weekday: string;
  }[],
  slot: SlotDuration
) => {
  const slotRange = getSlotRange(slot);
  let penData: any = {};
  slotRange.forEach((range) => (penData[range] = []));
  dayTimeData.forEach((element, index: number) => {
    // const { day, norm, status, weekday } = element;
    const { day, norm, weekday } = element;
    norm.forEach((item, normInd: number) => {
      const slot = slotRange[normInd];
      const data = {
        x: [`${weekday.substring(0, 3)}`, `D+${day}`],
        y: item,
      };
      const slotDataCopy = [...penData[slot]];
      slotDataCopy.push(data);
      penData[slot] = slotDataCopy;
    });
  });
  let series = [];
  for (let key in penData) {
    series.push({ name: key, data: penData[key] });
  }
  return series;
};

export const campaignCustomerType = (add_filter: {
  filter: string;
  value: any;
}) => {
  // let categories: any = [];
  let type = '';
  switch (add_filter?.filter) {
    case 'group':
      // categories = [{ label: 'Total Customer', value: 300 }];
      type = 'all';
      break;
    case 'split':
      const based_on = Object.keys(add_filter?.value)[0];
      if (based_on === 'dpd_bucket') {
        // categories = [
        //   { label: 'All', value: 700 },
        //   { label: 'DPD 1-30', value: 220 },
        //   { label: 'DPD 31-60', value: 12 },
        //   { label: 'DPD 61-90', value: 121 },
        //   { label: 'DPD 91-120', value: 100 },
        //   { label: 'DPD >120', value: 100 },
        // ];
        type = 'dpd_bucket';
      }
      if (based_on === 'product_type') {
        // categories = [
        //   { label: 'All', value: 700 },
        //   { label: 'LOC', value: 220 },
        //   { label: 'INSTALMENT LOAN', value: 12 },
        // ];
        type = 'product_type';
      }
      if (based_on === 'balance_bucket') {
        // categories = [
        //   { label: 'All', value: 700 },
        //   { label: 'Balance - Low', value: 220 },
        //   { label: 'Balance - Med', value: 412 },
        //   { label: 'Balance - High', value: 121 },
        // ];
        type = 'balance_bucket';
      }
      if (based_on === 'customer_status') {
        // categories = [
        //   { label: 'All', value: 700 },
        //   { label: 'PTP', value: 220 },
        //   { label: 'PTP Broken', value: 12 },
        //   { label: 'Call required', value: 121 },
        //   { label: 'Hardship', value: 100 },
        //   { label: 'Not engaged', value: 100 },
        //   { label: 'Bad Contact', value: 12 },
        // ];
        type = 'customer_status';
      }
      break;
    case 'add_custom':
      type = 'custom_filter';
      break;
    case 'ab_testing':
      // categories = [
      //   { label: 'Split A', value: 700 },
      //   { label: 'Split B', value: 220 },
      // ];
      type = 'A_B';
      break;
  }
  // return { categories, type };
  return { type };
};

export const campaignCutomerDataCategories = (summary: any) => {
  delete summary.group_type;
  let categories = [];
  for (let key in summary) {
    const data = summary[key];
    categories.push(data);
  }
  return categories;
};

export const setLocalCampaignData = (data: any, name: string = storageName) => {
  if (data) {
    localStorage.setItem(name, JSON.stringify(data));
  }
};

export const getLocalCampaignData = () => {
  try {
    const data = localStorage.getItem(storageName);
    if (data) {
      const parsedData = JSON.parse(data) || {};
      return parsedData;
    }
  } catch (err) {
    return {};
  }
};
