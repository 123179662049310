import { Box } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Breadcrumb from 'components/breadcrumbs';
import CustomerSegment from 'components/dashboard/customerSegments';
import DayWiseAcrossChannel from 'components/dashboard/dayWise';
import PortfolioOverview from 'components/dashboard/portfolioOverview';
import useStyles from './index.styles';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const data = {
  labels: ['High', 'Medium', 'Low'],
  datasets: [
    {
      data: [59, 36, 5],
      datalabels: {
        color: '#FFCE56',
      },
      backgroundColor: ['#D325CC', '#7192EB', '#8C34EA'],
      borderColor: ['#ffffff'],
      borderWidth: 2,

      options: {
        responsive: false,
        plugins: {
          datalabels: {
            color: 'blue',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
              value: {
                color: 'green',
              },
            },
          },
        },
      },
    },
  ],
};

const PortfolioDashboardScreen = () => {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumb main='Dashboard' nested='Collection Performance' />
      <Box
        bgcolor='#fff'
        sx={{
          maxWidth: '90%',
          margin: '0 auto 2rem',
          border: '1px solid #E6E6E6',
          padding: '2rem',
        }}
      >
        <PortfolioOverview />
        <CustomerSegment />
        <DayWiseAcrossChannel />
      </Box>
    </div>
  );
};

export default PortfolioDashboardScreen;
