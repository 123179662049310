import api from '../api';
import kollectAiCampaign from './config';

const campaignApi = {
  async createCampaign(payload: FormData) {
    return api.post(kollectAiCampaign.createCampaign, payload);
  },
  async downloadTemplates(payload: any) {
    const url = `${kollectAiCampaign.downloadTemplates}?template_type=${payload.templateType}&template_id=${payload.templateId}`;
    return api.get(url);
  },
  async createCampaignGroup(payload: any) {
    return api.post(kollectAiCampaign.createCampaignGroup, payload);
  },
  async createCommunicationCampaign(payload: any) {
    return api.post(kollectAiCampaign.createCommunicationCampaign, payload);
  },
  async campaignGroupFilter(payload: any) {
    return api.get(
      `${kollectAiCampaign.campaignGroupFilter}?client_id=${payload.client_id}`
    );
    // return api.get(`${kollectAiCampaign.campaignGroupFilter}`);
  },
  async campaignAddGroupFilter(payload: any) {
    return api.post(kollectAiCampaign.campaignAddGroupFilter, payload);
  },
  async viewTemplate(payload: any) {
    const url = `${kollectAiCampaign.viewTemplate}?client_key=${payload.client_key}`;
    return api.get(url);
  },
  async createConfiguration(payload: any) {
    return api.post(kollectAiCampaign.createConfiguration, payload);
  },
  async createScheduler(payload: any) {
    return api.post(kollectAiCampaign.createScheduler, payload);
  },
  async campaignGroupGetFilter(payload: any) {
    return api.post(kollectAiCampaign.campaignGroupGetFilter, payload);
  },
  async campaignGroupApplyFilter(payload: any) {
    return api.post(kollectAiCampaign.campaignGroupApplyFilter, payload);
  },
  async campaignGroupSummary(payload: any) {
    return api.post(kollectAiCampaign.campaignGroupSummary, payload);
  },
  async triggerNow(payload: any) {
    return api.post(kollectAiCampaign.triggerNow, payload);
  },

  // CampaignV2
  async campaignSummary(payload: any) {
    const url = payload.status
      ? `${kollectAiCampaign.campaignSummary}?limit=${payload.limit}&offset=${payload.offset}&status_value=${payload.status}`
      : `${kollectAiCampaign.campaignSummary}?limit=${payload.limit}&offset=${payload.offset}`;
    return api.get(url);
  },
  async createCampaignV2(payload: any) {
    return api.post(kollectAiCampaign.createCampaignV2, payload);
  },
  async getCampaignData(payload: { campaign_id: string }) {
    return api.get(
      `${kollectAiCampaign.createCampaignV2}?campaign_id=${payload.campaign_id}`
    );
  },
  // async updateAbTesting(payload: any) {
  //   let url = payload.a_dist
  //     ? `${kollectAiCampaign.createCampaignV2}?campaign_id=${payload.campaign_id}&a_dist=${payload.a_dist}`
  //     : `${kollectAiCampaign.createCampaignV2}?campaign_id=${payload.campaign_id}&b_dist=${payload.b_dist}`;
  //   return api.get(url);
  // },
  async updateAbTesting(payload: any) {
    let url = `${kollectAiCampaign.addABTesting}?campaign_id=${payload.campaign_id}&a_dist=${payload.a_dist}&b_dist=${payload.b_dist}`;
    return api.patch(url);
  },
  async addABTesting(payload: any) {
    return api.post(kollectAiCampaign.addABTesting, payload);
  },
  async addChannelTemplate(payload: any) {
    return api.post(kollectAiCampaign.addChannelTemplate, payload);
  },
  async updateChannelTemplate(payload: any) {
    return api.patch(kollectAiCampaign.addChannelTemplate, payload);
  },
  async rerunCampaign(payload: any) {
    return api.post(kollectAiCampaign.rerunCampaign, payload);
  },
  async groupBasedCustomerData(payload: any) {
    const url = `${kollectAiCampaign.groupBasedCustomerData}?campaign_id=${payload.campaignId}&group_type=${payload.groupType}&group_id=${payload.groupId}&limit=${payload.limit}&offset=${payload.offset}`;
    return api.get(url, payload);
  },

  async getCustomFilter(payload: any) {
    let url = `${kollectAiCampaign.campaignCustomFilter}?campaign_id=${payload.campaign_id}`;
    return api.get(url);
  },
  async getCampaignFilterAttributes() {
    let url = `${kollectAiCampaign.campaignFilterAttributes}`;
    return api.get(url);
  },
  async addCustomFilter(payload: any) {
    return api.post(kollectAiCampaign.campaignCustomFilter, payload);
  },
  async updateCustomFilter(payload: any) {
    return api.patch(kollectAiCampaign.campaignCustomFilter, payload);
  },
  async deleteCustomFilter(payload: any) {
    return api.delete(kollectAiCampaign.campaignCustomFilter, {
      data: payload,
    });
  },
  async getCampaignDetails(payload: any) {
    let url = `${kollectAiCampaign.campaignDetails}?campaign_id=${payload.campaign_id}`;
    return api.get(url);
  },
  async campaignAbTesting(payload: any) {
    let url = `${kollectAiCampaign.addCampaignAbTesting}?campaign_id=${payload.campaign_id}&a_dist=${payload.a_dist}&b_dist=${payload.b_dist}`;
    return api.get(url);
  },
  async deleteCampaign(payload: any) {
    return api.post(kollectAiCampaign.deleteCampaign, payload);
  },
  async copyCampaignWithoutFile(payload: any) {
    // const url = `${kollectAiCampaign.copyCampaignWithoutFile}?campaign_id=${payload.campaignId}&campaign_name=${payload.campaignName}&campaign_desc=${payload.campaignDesc}`;
    return api.post(kollectAiCampaign.copyCampaignWithoutFile, payload);
  },
  async campaignCommStatus({
    campaignId,
    executionId,
  }: {
    campaignId: string;
    executionId: string;
  }) {
    const url = `${kollectAiCampaign.campaignCommStatus}?campaign_id=${campaignId}&execution_id=${executionId}`;
    return api.get(url);
  },
  async changeBasedOn(payload: {
    campaign_id: string;
    based_on_group_type: string;
  }) {
    return api.patch(kollectAiCampaign.changeBasedOn, payload);
  },
  async getDayTimePenetration({
    executionId,
    campaignId,
    slot,
    channel,
    limit,
    offset,
  }: {
    executionId: string;
    campaignId: string;
    slot: number;
    channel: string;
    limit: number;
    offset: number;
  }) {
    let url = `${kollectAiCampaign.dayTimePenetration}?campaign_id=${campaignId}&slot_duration=${slot}&comm_type=${channel}&limit=${limit}&offset=${offset}&execution_id=${executionId}`;
    return api.get(url);
  },
  async getCampaignSummary(payload: {
    search_type?: string;
    query?: string;
    status_value?: string;
    limit: number;
    offset: number;
  }) {
    return api.get(kollectAiCampaign.campaignData, { params: payload });
  },

  async getCampaignExecutionList(payload: { campaign_id: string }) {
    return api.get(kollectAiCampaign.campaignExecutionList, {
      params: payload,
    });
  },

  async getCampaignCustomerDetail(payload: {
    campaign_id: string;
    customer_id: string;
    loan_id: string;
  }) {
    return api.get(kollectAiCampaign.campaignCustomerDetail, {
      params: payload,
    });
  },

  async customerResegmentation(payload: { campaign_id: string }) {
    return api.post(kollectAiCampaign.customerResegmentation, payload);
  },
};

export default campaignApi;
