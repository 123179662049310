import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { LoanDetailsType } from 'constants/types';
import { useAppDispatch } from 'hooks/useRedux';
import {
  setCustomerOdfID,
  setSelectedCustomerProduct,
} from 'store/reducer/customerReducer';

const ViewButton = styled('button')({
  '& p': {
    fontWeight: 600,
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#00C087',
  },
});

export default function CustomerLoanDetailsTable({
  data,
  odfID,
  customerId,
}: {
  data: LoanDetailsType[];
  odfID: string;
  customerId: string;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleView = (loan: any) => {
    console.log('🚀 ~ file: index.tsx:44 ~ handleView ~ loan:', loan);
    dispatch(setCustomerOdfID(odfID));
    dispatch(setSelectedCustomerProduct(loan?.product || ''));
    navigate(`/customer-details/${customerId}`, {
      state: { loanId: loan?.odf_loan_id, loanView: true },
    });
  };

  return (
    <>
      <TableContainer
        sx={{
          background: '#fff',
          border: '1px solid #E6E6E6',
          boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: '1px solid #ECF1FE',
            },
          }}
          aria-label='simple table'
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  color: '#818397',
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}
            >
              <TableCell>
                <Typography>LOAN ID</Typography>
              </TableCell>
              <TableCell>
                <Typography>PRODUCT</Typography>
              </TableCell>
              <TableCell>
                <Typography>NET BALANCE</Typography>
                <Typography>DUE</Typography>
              </TableCell>
              <TableCell>
                <Typography>DPD</Typography>
              </TableCell>
              <TableCell>
                <Typography>LOAN AMOUNT</Typography>
              </TableCell>
              <TableCell>
                <Typography>PAYMENTS</Typography>
              </TableCell>
              <TableCell>
                <Typography>TENURE</Typography>
              </TableCell>
              <TableCell>
                <Typography>LOAN MATURITY</Typography>
                <Typography>DATE</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: LoanDetailsType, index: number) => (
              <TableRow
                key={index}
                sx={{
                  cursor: 'pointer',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell align='center' component='th' scope='row'>
                  <Typography>{row.loan_id}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>{row.product}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>
                    {row.net_balance_due
                      ? `$ ${row.net_balance_due.toLocaleString()}`
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>{row.dpd}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>
                    {row.loan_amount
                      ? `$ ${row.loan_amount.toLocaleString()}`
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>
                    {row.payments ? `$ ${row.payments.toLocaleString()}` : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>{row.tenure || '-'}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>
                    {row.loan_maturity_date
                      ? moment(row.loan_maturity_date).format('D MMM, YYYY')
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <ViewButton onClick={() => handleView(row)}>
                    <Typography>View Details</Typography>
                  </ViewButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
