import { Typography } from '@material-ui/core';
import { useAppSelector } from 'hooks/useRedux';
import useStyles from './index.styles';

import ChannelBoxContainer from 'components/strategyDetails/channelSummary/header/channelBoxContainer';

interface ChannelSummaryHeaderProps {
  strategyGroup: any;
}

const ChannelSummaryHeader: React.FC<ChannelSummaryHeaderProps> = ({
  strategyGroup,
}) => {
  const classes = useStyles();

  const strategyDetailsData: any = useAppSelector(
    (state) => state.strategy.strategyDetailsFilter
  );

  const strategySummaryData =
    strategyDetailsData?.strategy_summary?.[0] ||
    strategyDetailsData?.strategy_summary;

  return (
    <div className={classes.channelSummaryHeaderWrapper}>
      <div>
        <Typography variant='subtitle1'>
          Channel Summary{' '}
          {strategyGroup?.based_on === 'dpd'
            ? '(As per dpd)'
            : `(As per ${strategyGroup?.based_on})`}
        </Typography>
      </div>
      <div className={classes.channelBoxContainerWrapper}>
        <ChannelBoxContainer
          channelName='IVR'
          channelValue={strategySummaryData?.ivr}
          channelValueColor='#0085FF'
        />
        <ChannelBoxContainer
          channelName='Email'
          channelValue={strategySummaryData?.email}
          channelValueColor='#EE4037'
        />
        <ChannelBoxContainer
          channelName='SMS'
          channelValue={strategySummaryData?.sms}
          channelValueColor='#00C087'
        />
        <ChannelBoxContainer
          channelName='Whatsapp'
          channelValue={strategySummaryData?.whatsapp}
          channelValueColor='#FFB600'
        />
      </div>
    </div>
  );
};

export default ChannelSummaryHeader;
