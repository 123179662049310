import { Box, Typography } from '@mui/material';

interface CustomerDetailedInfoBoxProps {
  label: string;
  mb?: string | number;
  children: React.ReactNode;
}

const CustomerDetailedInfoBox: React.FC<CustomerDetailedInfoBoxProps> = ({
  label,
  mb = 0,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: mb,
      }}
    >
      <Typography color='#6F6F6F' fontSize='1.1rem'>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default CustomerDetailedInfoBox;
