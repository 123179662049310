import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImCloudDownload } from 'react-icons/im';

import WorkflowButton from 'components/workflow/landing/header/button';
import { MODULELIST } from 'constants/types';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { deleteTemplate } from 'store/reducer/templateReducer/action';
import { isTemplateDeletable } from 'utils/helper';
import TemplateDeleteModal from 'components/templates/DeleteModal';
import useStyles from './template.styles';

const TemplateActions = ({
  selectedChannel,
  selectedTemplates,
  setLoading,
  deleteReset,
}: {
  selectedChannel: string;
  selectedTemplates: any[];
  setLoading: (x: boolean) => void;
  deleteReset: () => void;
}) => {
  const permissions = useAppSelector(userPermissions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteData, setDeleteData] = useState<any>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [nonDeletableData, setNonDeletableData] = useState<any>([]);

  const isTemplateSelected =
    selectedTemplates && selectedTemplates.length === 1;

  const classes = useStyles({ isTemplateSelected });

  const handleDelete = async () => {
    setLoading(true);
    let payload: any = {
      channel_type: selectedChannel,
      id: selectedTemplates.map((template) => template.id),
    };

    try {
      const { data, meta } = await dispatch(deleteTemplate(payload)).unwrap();
      if (meta?.success) {
        const deletableTemplates = data.filter((item: any) => !item.is_used);
        const nonDeletableTemplates = data.filter((item: any) => item.is_used);

        if (deletableTemplates.length) {
          const deletePayload = {
            channel_type: selectedChannel,
            id: deletableTemplates.map((item: any) => item.id),
            force_delete: 'True',
          };
          const { meta } = await dispatch(
            deleteTemplate(deletePayload)
          ).unwrap();
          if (meta?.success) {
            deleteReset();
            setOpenDelete(false);
          }
        }

        if (nonDeletableTemplates.length) {
          let newData: any = [];
          nonDeletableTemplates.forEach((el: any) => {
            const { usedList } = isTemplateDeletable(el.output);
            const templateName = selectedTemplates.find(
              (template) => template.id === el.id
            ).template_name as string;
            newData.push({ template: templateName, data: usedList });
          });

          setNonDeletableData(nonDeletableTemplates);
          setDeleteData(newData);
          setOpenDelete(true);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForceDelete = async () => {
    try {
      setLoading(true);

      const { meta } = await dispatch(
        deleteTemplate({
          channel_type: selectedChannel,
          id: nonDeletableData.map((template: any) => template.id),
          force_delete: 'True',
        })
      ).unwrap();
      if (meta?.success) {
        deleteReset();
        setOpenDelete(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = () => {
    if (selectedChannel === 'email') {
      selectedTemplates.forEach((selectedTemplate) => {
        const html = new Blob([selectedTemplate.template], {
          type: 'text/html',
        });
        const htmlURL = window.URL.createObjectURL(html);
        let tempLink = document.createElement('a');
        tempLink.href = htmlURL;
        tempLink.setAttribute(
          'download',
          `${selectedTemplate.template_name}.html`
        );
        tempLink.click();
      });
    } else {
      selectedTemplates.forEach((selectedTemplate) => {
        if (selectedTemplate.two_way_flag) {
          const json = new Blob(
            [JSON.stringify(selectedTemplate.template_json)],
            {
              type: 'application/json',
            }
          );
          const jsonURL = window.URL.createObjectURL(json);
          let tempLink = document.createElement('a');
          tempLink.href = jsonURL;
          tempLink.setAttribute(
            'download',
            `${selectedTemplate.template_name}.json`
          );
          tempLink.click();
        } else {
          const json = new Blob([JSON.stringify(selectedTemplate.template)], {
            type: 'text/plain',
          });
          const jsonURL = window.URL.createObjectURL(json);
          let tempLink = document.createElement('a');
          tempLink.href = jsonURL;
          tempLink.setAttribute(
            'download',
            `${selectedTemplate.template_name}.txt`
          );
          tempLink.click();
        }
      });
    }
  };

  return (
    <>
      {selectedTemplates.length > 0 && (
        <Box display='flex' justifyContent='center' gap='1rem'>
          {selectedTemplates.length === 1 ? (
            <>
              {permissions[MODULELIST.TEMPLATE].includes('create') && (
                <WorkflowButton
                  text='Duplicate & Edit'
                  bgColor='#6AC5AB'
                  icon={<EditIcon />}
                  onClick={() => {
                    if (isTemplateSelected) {
                      navigate(`/template-management/duplicate`, {
                        state: {
                          channel: selectedChannel,
                          template: selectedTemplates[0],
                          isBack: false,
                        },
                      });
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Box className={classes.templateDownloadCont}>
                <Box
                  className={classes.templateDownloadBox}
                  sx={{ padding: '0.5rem', border: '1px solid #f5f5f5' }}
                >
                  <ImCloudDownload color='#004357' fontSize='20px' />
                  <button onClick={handleTemplateDownload}>
                    <Typography color='#004357' fontWeight={600}>
                      Download templates
                    </Typography>
                  </button>
                </Box>
              </Box>
            </>
          )}
          {permissions[MODULELIST.TEMPLATE].includes('delete') && (
            <WorkflowButton
              text='Delete'
              bgColor='#DAA09A'
              icon={<DeleteIcon />}
              onClick={() => {
                if (selectedTemplates.length) {
                  handleDelete();
                }
              }}
            />
          )}
        </Box>
      )}

      <TemplateDeleteModal
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onDelete={handleForceDelete}
        deleteData={deleteData}
      />
    </>
  );
};

export default TemplateActions;
