import { Grid, Typography } from "@mui/material";
import CustomIcon from "components/customIcons";
import { useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";

const ChampionSummary = () => {
  const [randomMin, setRandomMin] = useState(0);
  const [randomMax, setRandomMax] = useState(0);
  const [population, setPopulation] = useState(100);
  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );

  const strategySliderValue: number = useAppSelector(
    (state: any) => state.strategy.sliderValue
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const championSummary = Array.isArray(strategyDetailsData?.strategy_summary)
    ? strategyDetailsData?.strategy_summary?.filter(
        (strategy: any) => strategy.ab_testing_group === "A"
      )[0] || {}
    : strategyDetailsData?.strategy_summary || {};

  useEffect(() => {
    setRandomMax(100 - strategySliderValue);
    setPopulation(100 - strategySliderValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategySliderValue]);

  useEffect(() => {
    setRandomMin(championSummary.random_min || 0);
    setRandomMax(championSummary.random_max || 0);
    setPopulation(championSummary.random_max || 100);
  }, [championSummary]);

  return (
    <>
      <Typography fontSize={"1.2rem"} textAlign="center" marginTop={"1rem"}>
        Champion Summary (A)
      </Typography>
      <Grid
        container
        justifyContent="center"
        columnGap={9}
        marginTop={"4.3rem"}
      >
        <Grid item>
          <Typography
            fontSize={"1.8rem"}
            textAlign="center"
            fontWeight={"bold"}
          >
            {population}%
          </Typography>
          <Typography
            fontSize={"0.75rem"}
            textAlign="center"
            color="rgba(51, 51, 51, 0.6)"
          >
            Population %
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize={"1.5rem"}
            textAlign="center"
            color="#2C62EC"
            fontWeight={"bold"}
          >
            {randomMin}-{randomMax}
          </Typography>
          <Typography
            fontSize={"0.75rem"}
            textAlign="center"
            color="rgba(51, 51, 51, 0.6)"
          >
            Random Number
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        columnGap={6.5}
        marginTop="3.75rem"
      >
        <Grid item>
          <CustomIcon name="email-icon" size={20} color="#E92C2C" />
          <Typography fontSize={"1.5rem"} textAlign="center" color="#E92C2C">
            {championSummary?.email || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name="smsTemplate" size={20} color="#FFB600" />
          <Typography fontSize={"1.5rem"} textAlign="center" color="#FFB600">
            {championSummary?.sms || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name="whatsapp-icon" size={20} color="#00C087" />
          <Typography fontSize={"1.5rem"} textAlign="center" color="#00C087">
            {championSummary?.whatsapp || 0}
          </Typography>
        </Grid>
        <Grid item>
          <CustomIcon name="phone-icon" size={20} color="#115180" />
          <Typography fontSize={"1.5rem"} textAlign="center" color="#115180">
            {championSummary?.ivr || 0}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChampionSummary;
