// import userPermissions from './role_permissions.json';
import ptpImg from 'assets/customer_ptp.svg';
import ptpBorkenImg from 'assets/customer_ptp_broken.svg';
import notEngagedImg from 'assets/customer_not_engaged.svg';
import addTriggerImg from 'assets/add_trigger.svg';
import workflowImg from 'assets/run_workflow.svg';
import { RoleType } from 'constants/types';
import { ModuleType } from 'components/rolesPermissions/permissions/modals/create/Create';

interface CreateSelectOptionProps {
  arr: any[];
  isObj?: boolean;
  objKey?: string;
}

export const getWorkflowTypeName = (type: string) => {
  if (type === 'RUN_STRATEGY') return 'Run Strategy';
  if (type === 'CHECK_ATTRIBUTE') return 'Check Attribute';
  if (type === 'ADD_TRIGGER') return 'Add Trigger';
  if (type === 'MOVE_TO_QUEUE') return 'Move to Queue';
  if (type === 'UPDATE_ATTRIBUTE') return 'Update Attribute';
  if (type === 'GET_SEGMENTS') return 'Get Segments';
  if (type === 'AB_TESTING') return 'AB Testing';
  if (type === 'RUN_WORKFLOW') return 'Run Workflow';
  if (type === 'WAIT') return 'Set Wait';
  if (type === 'ADD_LOOP') return 'Add Loop';
  if (type === 'END') return 'End';
};

export const workflowActionOptions = [
  { label: 'Check Attribute', value: 'CHECK_ATTRIBUTE' },
  { label: 'Run Strategy', value: 'RUN_STRATEGY' },
  { label: 'Add Trigger', value: 'ADD_TRIGGER' },
  { label: 'Move to Queue', value: 'MOVE_TO_QUEUE' },
  { label: 'Update Attribute', value: 'UPDATE_ATTRIBUTE' },
  { label: 'Get Segments', value: 'GET_SEGMENTS' },
  { label: 'AB Testing', value: 'AB_TESTING' },
  { label: 'Run Workflow', value: 'RUN_WORKFLOW' },
  { label: 'Set Wait', value: 'WAIT' },
  { label: 'Add Loop', value: 'ADD_LOOP' },
  { label: 'End', value: 'END' },
];

export const getWorkflowBoxValue = (
  type: string,
  parent: string
): {
  title: string;
  icon: string;
  color: string;
  fontSize?: number;
  img?: string;
} => {
  if (parent === 'START') return { title: 'Start', icon: '', color: '#fff' };

  if (type === 'CHECK_ATTRIBUTE')
    return {
      title: 'Check Attribute',
      icon: 'check_attribute',
      color: '#0A91E4',
    };

  if (type === 'RUN_STRATEGY')
    return { title: 'Run Strategy', icon: 'run_strategy', color: '#664672' };

  if (type === 'UPDATE_ATTRIBUTE')
    return {
      title: 'Update Attribute',
      icon: 'update_attribute',
      color: '#468965',
    };

  if (type === 'GET_SEGMENTS')
    return { title: 'Get Segments', icon: 'branches', color: '#036482' };

  if (type === 'MOVE_TO_QUEUE')
    return { title: 'Move to Queue', icon: 'queue', color: '#C07CA9' };

  if (type === 'ADD_TRIGGER')
    return {
      title: 'Add Trigger',
      icon: 'add_trigger',
      color: '#BB9B4C',
      img: addTriggerImg,
    };

  if (type === 'AB_TESTING')
    return { title: 'AB Testing', icon: 'ab_testing', color: '#0084FF' };

  if (type === 'RUN_WORKFLOW')
    return {
      title: 'Run Workflow',
      icon: 'branches',
      color: '#ED9144',
      img: workflowImg,
    };

  if (type === 'WAIT')
    return { title: 'Set Wait', icon: 'alarm', color: '#95261F' };

  if (type === 'ADD_LOOP')
    return { title: 'Add Loop', icon: 'loop', color: '#47587A' };

  return { title: '', icon: '', color: '' };
};

export const createSelectOption = ({
  arr,
  isObj,
  objKey,
}: CreateSelectOptionProps) => {
  if (isObj && objKey) {
    const options = arr?.map((item: any) => ({
      value: item[objKey],
      label: item[objKey],
    }));
    return options;
  }

  const options = arr?.map((item: string) => ({ label: item, value: item }));
  return options;
};

export const getGraphConfig = (config: any) => {
  const json = typeof config === 'string' ? JSON.parse(config) : config;
  const startArr = json.Steps[0].haschildren.start;
  let children = {};
  if (startArr.length === 1) {
    children = startArr[0];
  } else {
    children = startArr;
  }

  return {
    type: 'START',
    name: 'start',
    position: 1,
    haschildren: children,
  };
};

export const concatenatedTemplate = (obj: any) => {
  return Object.keys(obj).reduce((res, v) => res.concat(obj[v]), []);
};

export const allAllowedSegments = (arr: any) => {
  return arr
    .reduce((res: string[], v: any) => res.concat(Object.values(v)), [])
    .flat();
};

export const isDateValue = (dateStr: string) => {
  return !isNaN(new Date(dateStr).getDate());
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function arrayCompare(_arr1: any, _arr2: any) {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  ) {
    return false;
  }

  // .concat() to not mutate arguments
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if ('' + arr1[i] !== '' + arr2[i]) {
      return false;
    }
  }

  return true;
}

export const getStatusData = (status: string, stats: any) => {
  if (status === 'PTP') {
    return {
      icon: 'customer_ptp',
      svg: ptpImg,
      color: '#0069AA',
      label: 'PTP',
      value: stats?.ptp,
    };
  }
  if (status === 'Broken_Promise') {
    return {
      icon: 'customer_ptp_broken',
      svg: ptpBorkenImg,
      color: '#BB7065',
      label: 'PTP Broken',
      value: stats?.ptp_broken,
    };
  }
  if (status === 'Call_Required') {
    return {
      icon: 'customer_call_required',
      color: '#00C087',
      label: 'Call Required',
      value: stats?.call_required,
    };
  }
  if (status === 'Hardship') {
    return {
      icon: 'customer_hardship',
      color: '#ED9144',
      label: 'Hardship',
      value: stats?.hardship,
    };
  }
  if (status === 'Not_engaged') {
    return {
      icon: 'customer_not_engaged',
      svg: notEngagedImg,
      svgWidth: 22,
      color: '#6F6F6F',
      label: 'Not engaged',
      value: stats?.not_engaged,
    };
  }
  if (status === 'Bad_Contact') {
    return {
      icon: 'customer_bad_contact',
      color: '#FF646B',
      label: 'Bad Contact',
      value: stats?.bad_contact,
    };
  }
  if (status === 'Default' || status === '') {
    return {
      icon: 'customer_uncategoriesed',
      color: '#004357',
      label: 'New or Uncategorised',
      value: stats?.not_categorized,
    };
  }
  if (status === 'show_all') {
    return {
      icon: 'show',
      color: '#004357',
      label: 'Show All',
      value: '1000',
      svg: '',
    };
  }
};

export const getBaseURL = () => {
  let endpoint = process.env.REACT_APP_BASE_URL;
  if (
    window.location.hostname.indexOf('feat') >= 0 ||
    window.location.hostname.indexOf('stage') >= 0
  ) {
    endpoint = process.env.REACT_APP_STAGE_URL;
  }
  if (window.location.hostname.indexOf('master') >= 0) {
    endpoint = process.env.REACT_APP_PROD_URL;
  }
  return endpoint;
};

// export const userAccess = (role: string) => {
//   if (role) {
//     // @ts-ignore
//     const permissions = userPermissions[role];
//     const { template, campaign, strategy, workflow, customer, integrations } =
//       permissions;
//     return {
//       strategy: { access: Boolean(strategy.length), permissions: strategy },
//       template: { access: Boolean(template.length), permissions: template },
//       campaign: { access: Boolean(campaign.length), permissions: campaign },
//       workflow: { access: Boolean(workflow.length), permissions: workflow },
//       customer: { access: Boolean(customer.length), permissions: customer },
//       integration: {
//         access: Boolean(integrations.length),
//         permissions: integrations,
//       },
//     };
//   }
// };

export const getCreatePermissionModule = (modules: any) => {
  let mod: any = [];
  modules.forEach((el: any) => {
    let actions = { module_name: el.module };
    el.actions.forEach((action: any) => {
      actions = { ...actions, [action.operation]: action.checked };
    });
    mod.push(actions);
  });
  return mod;
};

export const createPermissions = (config: any) => {
  let permissions: any = {};
  for (let key in config) {
    permissions[key] = config[key]
      .map((item: { status: number; operation: string }) => {
        if (item.status === 1) return item.operation;
        return '';
      })
      .filter((el: string) => el);
  }
  return permissions;
};

export const isTemplateDeletable = (res: {
  campaign: string[];
  strategy: string[];
  workflow: string[];
}) => {
  const isUsed = Object.values(res).some((el) => el.length);
  let data = [];
  if (isUsed) {
    for (let key in res) {
      // @ts-ignore
      if (res[key].length) data.push({ module: key, usedList: res[key] });
    }
  }
  return { deletable: !isUsed, usedList: data };
};

export const isStrategyWorkflowDeletable = (res: any) => {
  let resObj: any = {};
  res?.workflow.forEach((item: any) => {
    resObj = { ...resObj, ...item };
  });
  const isUsed = Object.values(resObj).some((el: any) => el.length);
  let data = [];
  if (isUsed) {
    for (let key in resObj) {
      if (resObj[key].length)
        data.push({ strategy: key, usedList: resObj[key] });
    }
  }

  return { deletable: !isUsed, usedList: data };
};

export const generateRoleColorPalette = (roles: RoleType[]) => {
  let colorPalette: any = {};
  roles?.forEach((role) => {
    const hex = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
      .toUpperCase();

    const color = `#${hex}`;
    const background = `#${hex}40`;
    colorPalette = { ...colorPalette, [role.role_name]: { color, background } };
  });
  return colorPalette;
};

// ********* INTER MODULE PERMISSIONS HEIRARCHY ********** //
function getActionsByModule(moduleName: string, modules: ModuleType[]) {
  const filteredModule = modules.find((module) => module.module === moduleName);
  if (filteredModule) {
    const getCheckedByOperation = (operation: string) =>
      filteredModule.actions.find((item) => item.operation === operation)
        ?.checked;
    const create = getCheckedByOperation('create');
    const update = getCheckedByOperation('update');
    const execute = getCheckedByOperation('execute');
    return { create, update, execute };
  }
  return { create: false, update: false, execute: false };
}

export const interHierarchyModules = (modules: ModuleType[]) => {
  const heirarcyModule = modules.map((item) => {
    if (item.module === 'template_management') {
      const customerActions = getActionsByModule(
        'customer_management',
        modules
      );
      const workflowActions = getActionsByModule(
        'workflow_management',
        modules
      );
      const strategyActions = getActionsByModule(
        'strategy_management',
        modules
      );
      const campaignActions = getActionsByModule(
        'campaign_management',
        modules
      );
      if (
        workflowActions.create ||
        workflowActions.update ||
        strategyActions.create ||
        strategyActions.update ||
        campaignActions.create ||
        campaignActions.update ||
        customerActions.execute
      ) {
        const newActions = item.actions.map((action) => {
          if (action.operation === 'read') return { ...action, checked: true };
          return action;
        });
        return { ...item, actions: newActions };
      }
    }
    if (item.module === 'strategy_management') {
      const workflowActions = getActionsByModule(
        'workflow_management',
        modules
      );
      if (workflowActions.create || workflowActions.update) {
        const newActions = item.actions.map((action) => {
          return { ...action, checked: true };
        });
        return { ...item, actions: newActions };
      }
    }
    return item;
  });

  return heirarcyModule;
};
