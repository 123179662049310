import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useSearchParams } from 'react-router-dom';
import useStyles from './index.styles';
// import { Backdrop, CircularProgress } from '@material-ui/core';

import {
  getStrategyDetails,
  // strategyDetailsFilter,
} from 'store/reducer/strategyReducer/action';
import {
  changeActiveAmount,
  changeActiveDpd,
  changeActiveProduct,
} from 'store/reducer/strategyReducer';
import CustomSelectBox from '../customSelectBox';
import Loader from 'components/loader';
// import { arrayCompare } from "utils/helper";

// interface PayloadType {
//   bucket: string;
//   amount: string;
//   product: string;
//   dpd: string;
//   client_id: string;
//   auth_token: string;
//   client_token: string;
// }

interface StrategyDetailsProps {
  strategy?: any;
  strategyGroup?: any;
}

const StrategyDetailsHeader = ({
  strategy,
  strategyGroup,
}: StrategyDetailsProps) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('group_id');
  const [productLabels, setProductLabels] = useState<string[]>([]);
  const [dpdLabels, setDpdLabels] = useState<string[]>([]);
  const [balanceLabels, setBalanceLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<string>('');
  const [dpd, setDpd] = useState<string>('');
  const [balance, setBalance] = useState<string>('');

  const filterData = useAppSelector(
    (state: any) => state.uploadedFileDetail.groupFilterList
  );
  // const strategyDetailsData: any = useAppSelector(
  //   (state: any) => state.strategy.strategyDetailsFilter
  // );

  const { activeDpd, activeProduct, activeAmount }: any = useAppSelector(
    (state: any) => state.strategy
  );

  // const strategy_summary = Array.isArray(strategyDetailsData?.strategy_summary)
  //   ? strategyDetailsData?.strategy_summary[0]
  //   : "";

  const dispatch = useAppDispatch();

  // const [payloadData, setPayloadData] = useState({});

  useEffect(() => {
    setProduct(strategy?.product || '');
    setDpd(filterData?.DPD?.[strategy?.bucket - 1] || '');
    setBalance(strategy?.balance_level || '');
  }, [strategy, filterData]);

  useEffect(() => {
    if (activeDpd !== dpd) {
      dispatch(changeActiveDpd(dpd));
    }
    if (activeProduct !== product) {
      dispatch(changeActiveProduct(product));
    }
    if (activeAmount !== balance) {
      dispatch(changeActiveAmount(balance));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpd, product, balance]);

  useEffect(() => {
    if (filterData) {
      // const payload: PayloadType = {
      //   bucket: '1',
      //   amount: filterData?.AMOUNT.LOW,
      //   product: filterData?.PRODUCT['0'],
      //   dpd: filterData?.DPD['0'],
      //   client_id: '1',
      //   auth_token: '',
      //   client_token: '',
      // };
      // setPayloadData(payload);

      if (strategyGroup?.segment?.bucket[0]) {
        const dpdLabels =
          strategyGroup?.segment?.bucket?.map(
            (eachBucket: number) => filterData?.DPD[eachBucket - 1]
          ) || [];
        setDpdLabels(dpdLabels);
      } else {
        setDpdLabels(['All']);
      }

      if (!['0', null].includes(strategyGroup?.segment?.product[0])) {
        setProductLabels(
          strategyGroup?.segment?.product ||
            Object.values(filterData?.PRODUCT ?? {})
        );
      } else {
        setProductLabels(['All']);
      }

      if (!['0', null].includes(strategyGroup?.segment?.balance_level[0])) {
        setBalanceLabels(
          strategyGroup?.segment?.balance_level ||
            Object.values(filterData?.AMOUNT ?? {})
        );
      } else {
        setBalanceLabels(['All']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const filterChange = async (title: string, value: string) => {
    setLoading(true);

    let filteredProduct = product;
    let filteredDpd = dpd;
    let filteredBalance = balance;
    if (title === 'Product') {
      filteredProduct = value;
    }
    if (title === 'DPD Category') {
      filteredDpd = value;
    }
    if (title === 'Balance Level') {
      filteredBalance = value;
    }
    const bucket =
      Number(
        Object.keys(filterData.DPD).find(
          (key) => filterData.DPD[key] === filteredDpd
        )
      ) + 1;

    const balanceLevel = ['0', null, 'All'].includes(filteredBalance)
      ? '0'
      : filteredBalance;
    const productLabel = ['0', null, 'All'].includes(filteredProduct)
      ? '0'
      : filteredProduct;
    const bucketLabel = bucket ? bucket : 0;

    const query = `strategy_group_id=${groupId}&balance_level=${balanceLevel}&product=${productLabel}&bucket=${bucketLabel}`;
    await dispatch(getStrategyDetails({ query }));
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.strategyDetailsHeader}>
        <CustomSelectBox
          title='Product'
          labels={productLabels}
          filterChange={filterChange}
          value={product}
          setValue={setProduct}
        />
        <CustomSelectBox
          title='DPD Category'
          labels={dpdLabels}
          filterChange={filterChange}
          value={dpd}
          setValue={setDpd}
        />
        <CustomSelectBox
          title='Balance Level'
          labels={balanceLabels}
          filterChange={filterChange}
          value={balance}
          setValue={setBalance}
        />
      </Box>
    </>
  );
};

export default StrategyDetailsHeader;
