import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      border: '1px solid #E6E6E6',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      background: '#fff',
    },
  })
);

export default useStyles;
