import { styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikCustomerInputProps {
  label?: string;
  name: string;
  placeholder?: string;
  width?: number;
  type?: string;
}

const FormikCustomerInputWrapper = styled('div')(
  ({ label, width }: { label?: string; width?: number }) => ({
    marginBottom: label ? '1rem' : 0,

    '& input': {
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0.7rem 0',
      fontFamily: 'Overpass',
      outline: 'none',
      fontSize: '1rem',
      minHeight: '38px',
      width,
    },
  })
);

const FormikCustomerInput: React.FC<FormikCustomerInputProps> = ({
  label,
  name,
  placeholder = '',
  width = 205,
  type = 'text',
}) => {
  return (
    <FormikCustomerInputWrapper label={label} width={width}>
      {label && (
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            marginBottom: '0.3rem',
          }}
        >
          <Typography fontSize='1rem'>{label}</Typography>
        </label>
      )}
      <Field name={name}>
        {({ form, field, meta }: FieldProps) => (
          <div>
            <input
              type='text'
              placeholder={placeholder}
              {...field}
              min='0'
              onChange={(e) => {
                const { setFieldValue } = form;
                const { value } = e.target;

                if (type === 'number') {
                  if (Number(value) > 0 || value === '')
                    setFieldValue(name, value);
                } else {
                  setFieldValue(name, value);
                }
              }}
            />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </div>
        )}
      </Field>
    </FormikCustomerInputWrapper>
  );
};

export default FormikCustomerInput;
