import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import {
  FormikTemplateInput,
  FormikTemplateTextarea,
} from "components/formik/template";
import useStyles from "./index.styles";
import { kollectAITheme } from "config/theme";
import { FiDownload, FiUploadCloud } from "react-icons/fi";
import { FormikValues } from "formik";
import { onFileChange } from "..";

interface EmailFormProps {
  type: string;
  values: FormikValues;
  isMapped: boolean;
  setFieldValue: (x: string, y: any) => void;
  openAttribute: () => void;
}

const EmailForm: React.FC<EmailFormProps> = ({
  type,
  values,
  isMapped,
  setFieldValue,
  openAttribute,
}) => {
  const classes = useStyles();
  const uploadRef: any = useRef(null);

  const handlFileUpload = () => {
    uploadRef.current && uploadRef.current.click();
  };

  const onFileDrop = async (e: any) => {
    const newFile = e.target.files[0];
    if (newFile.type === "text/html") {
      if (newFile) {
        let content = await onFileChange(newFile);
        setFieldValue("message", content);
      }
    }
  };

  const handleDownload = () => {
    const { message, name } = values;
    const html = new Blob([message], { type: "text/html" });
    const htmlURL = window.URL.createObjectURL(html);
    let tempLink = document.createElement("a");
    tempLink.href = htmlURL;
    tempLink.setAttribute("download", `${name}.html`);
    tempLink.click();
  };

  return (
    <>
      <Box className={classes.nameFieldCont}>
        <FormikTemplateInput
          label="Name of the template"
          name="name"
          placeholder="Template Name"
          disabled={type === "update" ? true : false}
        />

        <Box mb="10px" sx={{ cursor: "pointer" }} onClick={openAttribute}>
          <Typography fontWeight={700} className={classes.allowedVariableText}>
            Allowed Variable List
          </Typography>
        </Box>
      </Box>

      <FormikTemplateInput
        label="Subject Line"
        name="subject"
        placeholder="Subject"
      />

      <Box>
        <Box className={classes.htmlMessageHeader}>
          <Box display="flex" alignItems="cener" gap="1rem">
            <Typography fontWeight={700} color={kollectAITheme.primaryColor}>
              HTML Body
            </Typography>

            {!isMapped && (
              <Typography className={classes.unmapMessage}>
                Undefined Variable used; Please refer{" "}
                <span>Allowed Variable List</span>
              </Typography>
            )}
          </Box>

          <Box className={classes.htmlMessageHeaderIconCont}>
            <Box sx={{ cursor: "pointer" }} onClick={handleDownload}>
              <FiDownload color="#49F0AC" />
            </Box>
            <Box sx={{ cursor: "pointer" }}>
              <input
                style={{ display: "none" }}
                ref={uploadRef}
                type="file"
                value=""
                accept=".html"
                onChange={onFileDrop}
              />
              <FiUploadCloud color="#004357" onClick={handlFileUpload} />
            </Box>
          </Box>
        </Box>

        <FormikTemplateTextarea name="message" fullHeight />
      </Box>
    </>
  );
};

export default EmailForm;
