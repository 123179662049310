import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { EditRoleCont, RoleName, RoleWrapper } from './styles';

import { CreateEditRole } from '../modal/createEditRole';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { deleteRole, getAllRole } from 'store/reducer/roleReducer/action';
import Loader from 'components/loader';
import { MdDelete } from 'react-icons/md';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST, RoleType } from 'constants/types';
import { BsInfoCircle } from 'react-icons/bs';
import { kollectAITheme } from 'config/theme';

export const RoleTile = ({ data }: { data: RoleType }) => {
  const dispatch = useAppDispatch();

  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleEdit = () => {
    setEditData(data);
    setOpenEdit(true);
  };

  const handleModalClose = () => {
    setOpenEdit(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await dispatch(deleteRole({ role_id: data?.role_id }));
      await dispatch(getAllRole());
    } catch (error) {}
    setLoading(false);
  };

  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <RoleWrapper>
        <Typography color='#32475C99' fontSize='0.9rem'>
          Total {data?.user_count} users
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <RoleName>{data?.role_name}</RoleName>
          {!data?.editable && (
            <button onClick={handleEdit}>
              <BsInfoCircle
                color={kollectAITheme.primaryColor}
                fontSize='1rem'
              />
            </button>
          )}
        </Box>

        {data?.editable && (
          <>
            <EditRoleCont>
              {permissions[MODULELIST.USER].includes('update') ? (
                <Typography
                  color='#004357'
                  fontSize='0.9rem'
                  fontWeight={500}
                  onClick={handleEdit}
                >
                  Edit Role
                </Typography>
              ) : (
                <div></div>
              )}
              {permissions[MODULELIST.USER].includes('delete') && (
                <Box sx={{ cursor: 'pointer' }} onClick={handleDelete}>
                  <MdDelete color='#32475C8A' fontSize='1.2rem' />
                </Box>
              )}
            </EditRoleCont>
          </>
        )}
      </RoleWrapper>

      <CreateEditRole
        open={openEdit}
        data={editData}
        onClose={handleModalClose}
      />
    </>
  );
};
