import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IProps {
  type: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphBoxWrapperStyle: {
      cursor: 'default',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    graphBoxContainer: {
      display: (props: IProps) => (props.type ? 'inline-flex' : 'block'),
      alignItems: 'center',
      background: '#fff',
      width: '100%',
      cursor: 'default',
      position: 'relative',
      border: '1px solid #EAEAEA',
      boxShadow: '6px 6px 14px rgba(0, 0, 0, 0.04)',
      borderRadius: '8px',

      '& svg': {
        fontSize: '15px',
      },
    },
    graphIconContainer: {
      height: '60px',
      minWidth: '50px',
      display: 'grid',
      placeItems: 'center',
    },
    customerNumberCont: {
      display: 'flex',
      padding: '5px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: '1px solid #1a192b',

      '& p': {
        fontSize: '10px',
      },
      '& button': {
        fontSize: '10px',
        color: theme.kollectAITheme.primaryColor,
        textDecoration: 'underline',
        fontWeight: 500,
      },
    },
    addStepIconBox: {
      cursor: 'pointer',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      bottom: '-35%',
      display: 'block',
      zIndex: 999,
      filter: 'drop-shadow(1px 4px 2px #000)',
    },
    addStepIconCont: {
      background: '#fff',
      borderRadius: '50%',
      border: '1px solid #00C087',
      height: '13px',
      width: '13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
