import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whatsappPreviewWrapper: {
      // border: '10px solid #333333',
      // borderRadius: '20px',
      height: '100%',
      margin: '0 auto',
      width: '70%',
    },
    whatsappPreviewHeaderCont: {
      background: '#F6F6F6',
      padding: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '10px 10px 0px 0px',
    },
    whatsappPreviewHeaderLeft: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    whatsappPreviewHeaderRight: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginRight: '10px',
    },
    whatsappPreviewHeaderBackIcon: {
      color: '#007AFF',
      marginRight: '10px',
      position: 'relative',
    },
    whatsappPreviewHeaderBackIconText: {
      position: 'absolute',
      right: '-10px',
      top: '5px',
    },
    whatsappPreviewHeaderMiddleBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    whatsappPreviewHeaderAvatarCont: {
      background: '#fff',
      height: '30px',
      width: '30px',
      borderRadius: '60%',
    },
    whatsappPreviewHeaderUserText: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },

    whatsappPreviewMessageCont: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: (props: any) => (props.isCreate ? '79%' : 'calc(100vh - 538px)'),
      height: (props: any) =>
        props.isCreate ? 'calc(100vh - 326px)' : 'calc(100% - 130px)',
      overflow: 'auto',
      scrollbarWidth: 'none',
      background:
        '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    whatsappPreviewMessageBox: {
      height: '100%',
      padding: '1rem',
    },
    whatsappPreviewTodayStyle: {
      background: '#DBDFEB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
      padding: '0.2rem 0.8rem',
      display: 'inline',
      color: '#414350',
      fontSize: '0.8rem',
    },
    whatsappPreviewLearnMoreStyle: {
      background: '#EDF9F5',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
      fontSize: '0.8rem !important',
      padding: '0.4rem',
    },
    whatsappPreviewMessage: {
      background: '#F9F9F9',
      borderRadius: '6px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      minHeight: '100px',
      padding: '0.4rem 0.4rem 1rem 0.4rem',
      wordWrap: 'break-word',
    },
    whatsappPreviewMessageTime: {
      position: 'absolute',
      right: '6px',
      bottom: 0,
    },
    whatsappPreviewInputCont: {
      display: 'flex',
      padding: '0.5rem 1rem',
      gap: '1rem',
      background: '#F6F6F6',

      '& input': {
        width: '95%',
        outline: 'none',
        padding: '10px',
        fontFamily: 'inherit',
        background: '#FFFFFF',
        border: '1px solid #DEDFE0',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
      },
      '& svg': {
        color: ' #2C62EC',
      },
    },
    whatsappPreviewBottomLine: {
      maxWidth: '50%',
      background: '#000',
      height: '5px',
      borderRadius: '40px',
      margin: '5px auto',
    },
  })
);

export default useStyles;
