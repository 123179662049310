import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';

import { CUSTOMER_ACTIONS } from './constants';
import ChangeCustomerStatusForm from './ChangeStatus';
import MoveToQueueForm from './MoveToQueue';
import EditCustomerDataForm from './EditData';
import AddTaskForm from './AddTask/addTask';
import AddNotesForm from './AddNotes/addNotes';
import AddRequestForm from './AddRequest/addRequest';
import DeleteCustomerForm from './Delete/delete';
import SendCommunicationForm from './SendCommunication/sendCommunication';
import {
  selectedCustomersArr,
  setRefetchSidetableCustomer,
} from 'store/reducer/customerReducer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

interface CustomerActionModalProps {
  action: string;
  // status: string;
  onClose: () => void;
  fetchData: () => void;
}

const CustomerActionModal: React.FC<CustomerActionModalProps> = ({
  action,
  // status,
  onClose,
  fetchData,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const handleClose = () => {
    onClose();
    fetchData();
    if (!selectedCustomers || !selectedCustomers.length) {
      dispatch(setRefetchSidetableCustomer(true));
    }
  };

  const onStatusDeleteClose = () => {
    onClose();
    if (selectedCustomers && selectedCustomers.length) {
      fetchData();
    }
  };

  return (
    <Box className={classes.modalWrapper}>
      <Box className={classes.headerStyle}>
        <Typography>{action}</Typography>
      </Box>

      <Box p='2rem'>
        {action === CUSTOMER_ACTIONS.CHANGE_STATUS && (
          // <ChangeCustomerStatusForm onClose={onClose} status={status} />
          <ChangeCustomerStatusForm onClose={onStatusDeleteClose} />
        )}
        {action === CUSTOMER_ACTIONS.MOVE_TO_QUEUE && (
          <MoveToQueueForm onClose={handleClose} />
        )}
        {action === CUSTOMER_ACTIONS.EDIT_DATA && (
          <EditCustomerDataForm onClose={handleClose} />
        )}
        {action === CUSTOMER_ACTIONS.ADD_TASK && (
          <AddTaskForm onClose={handleClose} />
        )}
        {action === CUSTOMER_ACTIONS.ADD_REQUEST && (
          <AddRequestForm onClose={handleClose} />
        )}
        {action === CUSTOMER_ACTIONS.SEND_COMMUNICATION && (
          <SendCommunicationForm onClose={handleClose} />
        )}
        {action === CUSTOMER_ACTIONS.ADD_NOTES && (
          <AddNotesForm onClose={handleClose} actionType='notes' />
        )}
        {action === CUSTOMER_ACTIONS.DELETE && (
          <DeleteCustomerForm onClose={onStatusDeleteClose} />
        )}
      </Box>
    </Box>
  );
};

export default CustomerActionModal;
