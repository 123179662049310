import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PlainModal from 'components/Modal/PlainModal';
import { CgExport } from 'react-icons/cg';

import {
  AddUser,
  ExportButton,
  RoleButton,
  UserTableHeader,
  UserTableWrapper,
} from './styles';
import UsersTable from './table/usersTable';
import { AddUserModal } from '../modal';
import Loader from 'components/loader';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  exportUserDetails,
  getRoleList,
  getUserSummary,
} from 'store/reducer/userReducer/action';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { errorToast } from 'utils/toast';

export const UsersList = () => {
  const dispatch = useAppDispatch();

  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const [searchUser, setSearchUser] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch(getUserSummary({ limit: 10, offset: 0 })),
        dispatch(getRoleList()),
      ]);
    } catch (error) {}
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUser(e.target.value);
    if (!e.target.value) {
      setLoading(true);
      try {
        await dispatch(getUserSummary({ limit: 10, offset: 0 }));
      } catch (error) {}
      setLoading(false);
    }
  };

  const handleUserSearch = async () => {
    setLoading(true);
    try {
      await dispatch(
        getUserSummary({ limit: 10, offset: 0, search_query: searchUser })
      );
    } catch (error) {}
    setLoading(false);
  };

  const handleExport = async () => {
    try {
      const { download_link } = await dispatch(exportUserDetails()).unwrap();
      if (download_link) {
        window.open(download_link, '_self');
      }
    } catch (error) {
      errorToast('Unable to export');
    }
  };

  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <Box my='2rem'>
        <Box>
          <Typography fontSize='1.5rem' fontWeight={500}>
            Total users with their roles
          </Typography>
          <Typography color='#32475C99'>
            Find all of your company’s administrator accounts and their
            associate roles.
          </Typography>
        </Box>

        <UserTableWrapper>
          <UserTableHeader>
            <ExportButton onClick={handleExport}>
              <CgExport />
              <Typography>Export</Typography>
            </ExportButton>

            <AddUser>
              <input
                type='text'
                placeholder='Search User'
                value={searchUser}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleUserSearch();
                  }
                }}
              />
              {permissions[MODULELIST.USER].includes('create') && (
                <RoleButton onClick={() => setOpenInvite(true)}>
                  <Typography>Add User</Typography>
                </RoleButton>
              )}
            </AddUser>
          </UserTableHeader>

          <UsersTable />
        </UserTableWrapper>
      </Box>

      <PlainModal
        open={openInvite}
        onClose={() => setOpenInvite(false)}
        children={<AddUserModal />}
      />
    </>
  );
};
