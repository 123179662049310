import * as React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { ActionsWrapper, AssignedToBox } from './style';

import { AssignedTo } from './assignedTo';
import { CreateEditPermission } from 'components/rolesPermissions/permissions/modals/create/Create';
import { CustomHeadCell } from 'components/rolesPermissions/CustomHeadCell';
import PlainModal from 'components/Modal/PlainModal';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
// import { permissionSummary } from 'store/reducer/permissionReducer';
import Loader from 'components/loader';
import {
  deletePermission,
  getPermission,
  getPermissionSummary,
} from 'store/reducer/permissionReducer/action';
import { successToast } from 'utils/toast';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { BsInfoCircle } from 'react-icons/bs';

export default function PermissionTable({
  permissions,
}: {
  permissions: any[];
}) {
  const dispatch = useAppDispatch();

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  // const handlePageChange = (event: unknown, newPage: number) => {
  //   console.log(newPage);
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };

  const handleEdit = async (permission_id: string, editable: boolean) => {
    setLoading(true);
    try {
      const res = await dispatch(getPermission({ permission_id })).unwrap();
      setEditData({ ...res, permission_id, editable });
      setOpenEdit(true);
    } catch (error) {}
    setLoading(false);
    // setEditData(data);
    // setOpenEdit(true);
  };

  const handleDelete = async (permission_id: string) => {
    setLoading(true);
    try {
      const msg = await dispatch(deletePermission({ permission_id })).unwrap();
      successToast(msg);
      await dispatch(getPermissionSummary({ limit: 100, offset: 0 }));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setEditData({});
    setOpenEdit(false);
  };

  const userPerms = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ width: '100%', mb: '2rem' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='medium'
          >
            <TableHead>
              <TableRow>
                <CustomHeadCell label='name' align='left' pl='2rem' />
                <CustomHeadCell label='assigned to' align='left' />
                <CustomHeadCell label='created date' />
                <CustomHeadCell label='action' />
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography pl='2rem'>{row?.permission_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <AssignedToBox>
                        {row?.assigned_to?.map((item: string) => (
                          <AssignedTo assigned={item} />
                        ))}
                      </AssignedToBox>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>{row?.created_ts}</Typography>
                    </TableCell>
                    <TableCell>
                      {row?.editable ? (
                        <ActionsWrapper>
                          {userPerms[MODULELIST.USER].includes('update') && (
                            <BiEdit
                              onClick={() =>
                                handleEdit(row?.permission_id, row?.editable)
                              }
                            />
                          )}
                          {userPerms[MODULELIST.USER].includes('delete') && (
                            <AiOutlineDelete
                              onClick={() => handleDelete(row?.permission_id)}
                            />
                          )}
                        </ActionsWrapper>
                      ) : (
                        <ActionsWrapper svgSize='1.2rem'>
                          <BsInfoCircle
                            onClick={() =>
                              handleEdit(row?.permission_id, row?.editable)
                            }
                          />
                        </ActionsWrapper>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component='div'
          count={permissionData?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Box>

      <PlainModal
        open={openEdit}
        onClose={handleModalClose}
        children={
          <CreateEditPermission data={editData} onClose={handleModalClose} />
        }
      />
    </>
  );
}
