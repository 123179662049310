import api from 'apis/api';
import kollectAiUser from './config';

const userApi = {
  async getUserSummary(payload: {
    client_id?: number;
    search_query?: string;
    status?: string;
    two_fa?: string;
    role?: string;
    limit: number;
    offset: number;
  }) {
    return api.get(kollectAiUser.userSummary, { params: payload });
  },

  async getRoleList() {
    return api.get(kollectAiUser.user);
  },

  async addUser(payload: {
    first_name: string;
    last_name: string;
    email: string;
    role_id: string;
    two_f_a: boolean;
  }) {
    return api.post(kollectAiUser.user, payload);
  },

  async updateUser(payload: {
    notify: boolean;
    user_id: string;
    is_active: number;
    first_name: string;
    last_name: string;
    email: string;
    role_id: string;
    two_f_a: boolean;
  }) {
    return api.patch(kollectAiUser.editUser, payload);
  },

  async deleteUser(payload: { user_id: string }) {
    return api.delete(kollectAiUser.user, { data: payload });
  },

  async verifyUserEmail(payload: { token: string; two_f_a: string }) {
    return api.patch(kollectAiUser.verifyUserEmail, payload);
  },

  async createNewUserPassword(payload: {
    token: string;
    phone: string;
    phone_country_code: string;
    new_password: string;
    confirm_new_password: string;
  }) {
    return api.patch(kollectAiUser.newUserPassword, payload);
  },

  // async resendUserEmail(payload: { email: string }) {
  //   return api.post(kollectAiUser.resendUserEmail, payload);
  // },
  async resendUserEmail(payload: { email: string }) {
    return api.post(kollectAiUser.resendUserEmail, payload);
  },

  async exportUserDetails() {
    return api.post(kollectAiUser.exportUserDetails);
  },
};

export default userApi;
