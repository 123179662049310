import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useStyles from './index.styles';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowAttributes } from 'store/reducer/workflowReducer';

import { createSelectOption } from 'utils/helper';
import FormikWorkflowDatePicker from 'components/formik/workflow/FormikWorkflowDatePicker';
import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { getworkflowProperties } from 'store/reducer/workflowReducer/action';
import { SelectOptionType, WorkflowAttributeType } from 'constants/types';
import CheckConditions from './conditions';
import CheckActionButtons from './actionButtons';
import FormikWorkflowMultiSelect from 'components/formik/workflow/WorkflowMultiSelect';

interface CheckAttributeProps {
  setLoading: (x: boolean) => void;
  handleDelete: () => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const booleanOptions = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 },
];

const formulaUsage = (
  <Box sx={{ '& p': { fontSize: '12px' } }}>
    <Typography>Relative Formula Usage</Typography>
    <ul style={{ marginLeft: '25px' }}>
      <li>
        <Typography>For today: CURRENT_DATE</Typography>
      </li>
      <li>
        <Typography>For 5 days before today: CURRENT_DATE-5</Typography>
      </li>
      <li>
        <Typography>For 10 days after today: CURRENT_DATE+10</Typography>
      </li>
    </ul>
  </Box>
);

const CheckAttributeDrawer: React.FC<CheckAttributeProps> = ({
  setLoading,
  handleDelete,
  updateJsonConfig,
  validationSchema,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();

  const [attributeOptions, setAttributeOptions] = useState<any>([]);
  const [operatorOptions, setOperatorOptions] = useState<any>([]);
  const [isFormula, setIsFormula] = useState<{
    start: boolean;
    end: boolean;
  }>({ start: false, end: false });
  const [listOptions, setListOptions] = useState<SelectOptionType[] | []>([]);
  const [attribute, setAttribute] = useState<WorkflowAttributeType | null>(
    null
  );

  const allowedCheckAttributes: any = useAppSelector(workflowAttributes)?.check;

  const fetchCheckAttributes = useCallback(async () => {
    try {
      await dispatch(getworkflowProperties({ action_type: 'check' }));
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    fetchCheckAttributes();
  }, [fetchCheckAttributes]);

  useEffect(() => {
    if (allowedCheckAttributes) {
      const option = createSelectOption({
        arr: allowedCheckAttributes,
        isObj: true,
        objKey: 'attribute',
      });
      setAttributeOptions(option);
    }
  }, [allowedCheckAttributes]);

  useEffect(() => {
    if (allowedCheckAttributes && workflowActionData?.check) {
      const attr: any = allowedCheckAttributes.find(
        (item: WorkflowAttributeType) =>
          item.attribute === workflowActionData?.check[0].attribute
      );

      if (attr) updateOptions(attr);
    }
  }, [allowedCheckAttributes, workflowActionData]);

  const updateOptions = (attr: any) => {
    if (!['Boolean'].includes(attr.type)) {
      const option = createSelectOption({
        arr: JSON.parse(attr.allowed_operator),
      });
      // if (attr.type === 'Date') {
      //   setIsFormula((prev) => ({ ...prev, start: true }));
      // }
      if (attr.type === 'List') {
        const opt = createSelectOption({
          arr: JSON.parse(attr.allowed_values),
        });
        setListOptions(opt);
      }
      setOperatorOptions(option);
    }
    if (attr.type === 'Var') {
      const option = createSelectOption({
        arr: JSON.parse(attr.allowed_values),
      });
      setListOptions(option);
    }
  };

  const handleAttributeChange = (value: string) => {
    const attr = allowedCheckAttributes?.find(
      (item: any) => item.attribute === value
    );
    if (attr) {
      updateOptions(attr);
      setAttribute(attr as WorkflowAttributeType);
    }
  };

  const handleXVariableChange = () => {
    if (attribute && attribute.allowed_values) {
      const allowedValues = JSON.parse(attribute.allowed_operator as any);
      const options = createSelectOption({
        arr: allowedValues,
      });
      setOperatorOptions(options);
    }
  };

  const handleSubmit = async (values: any) => {
    const { stepName, check } = values;
    let configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'CHECK_ATTRIBUTE',
          check,
          haschildren: {
            True: [
              { parent: stepName, parentValue: 'True', position: 1, name: '' },
            ],
            False: [
              { parent: stepName, parentValue: 'False', position: 1, name: '' },
            ],
          },
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'check');
      updateWorkflow(configObj, keys, check);
    }

    updateJsonConfig(configObj);
  };

  const initialValues = useMemo(
    () => ({
      stepName: workflowActionData?.name,
      precursorName: workflowActionData?.parent,
      check: workflowActionData?.check,
      attribute: '',
      operator: '',
      value: '',
      x_var: '',
      upper_value: '',
      start_date: null,
      end_date: null,
      start_value: 'CURRENT_DATE',
      end_value: 'CURRENT_DATE',
    }),
    [workflowActionData]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, resetForm, setValues }) => (
        <Form className={classes.formWrapper}>
          <Box>
            <Box>
              <CustomFormikWorkflowInput
                label='Step Name'
                name='stepName'
                placeholder='Step Name'
                disabled={workflowEditor === 'create' ? false : true}
              />

              <CustomFormikWorkflowInput
                label='Precursor Name'
                name='precursorName'
                placeholder='Precursor Name'
                disabled={true}
              />

              <CustomFormikWorkflowSelect
                name='attribute'
                label='Check Attribute'
                placeholder='Select Attribute'
                onChange={(value) => {
                  handleAttributeChange(value);
                  setFieldValue('operator', '');
                }}
                options={attributeOptions}
              />

              {attribute?.type === 'Var' && (
                <CustomFormikWorkflowSelect
                  name='x_var'
                  label='Select X Variable'
                  options={listOptions}
                  onChange={handleXVariableChange}
                />
              )}

              {attribute &&
                attribute?.allowed_operator &&
                // !['Boolean', 'Var'].includes(attribute.type) &&
                (!['Boolean', 'Var'].includes(attribute.type) ||
                  (attribute.type === 'Var' && values.x_var)) && (
                  <CustomFormikWorkflowSelect
                    name='operator'
                    placeholder='Select Operator'
                    label='Operator'
                    onChange={() => {
                      setFieldValue('value', '');
                      setFieldValue('upper_value', '');
                      setFieldValue('start_date', '');
                    }}
                    options={operatorOptions}
                  />
                )}

              {values.operator &&
                attribute &&
                // !['List', 'Date', 'Var'].includes(attribute.type) &&
                !['List', 'Date'].includes(attribute.type) &&
                [
                  'equals_to',
                  'not_equals_to',
                  'greater_than',
                  'less_than',
                ].includes(values.operator) && (
                  <CustomFormikWorkflowInput
                    label='Value'
                    name='value'
                    placeholder='Enter Value'
                    type='number'
                  />
                )}

              {values.operator &&
                attribute &&
                // ['Number', 'Boolean'].includes(attribute.type) &&
                attribute.type === 'Number' &&
                ['in_range', 'not_in_range'].includes(values.operator) && (
                  <>
                    <CustomFormikWorkflowInput
                      label={`${
                        values.operator === 'in_range'
                          ? 'Value between'
                          : 'Value not in between'
                      }`}
                      name='value'
                      placeholder='Enter Lower Limit'
                      noMargin={true}
                      type='number'
                    />
                    <Box display='flex' justifyContent='center'>
                      <Typography fontSize='0.8rem'>and</Typography>
                    </Box>
                    <CustomFormikWorkflowInput
                      name='upper_value'
                      placeholder='Enter Upper Limit'
                      type='number'
                    />
                  </>
                )}

              {values.operator &&
                attribute?.type !== 'List' &&
                ['listed_in', 'not_listed_in'].includes(values.operator) && (
                  <CustomFormikWorkflowInput
                    label='Values'
                    labelTag='enter comma separated values'
                    name='value'
                    placeholder='Enter values'
                  />
                )}

              {/* new */}
              {values.operator &&
                attribute?.type === 'List' &&
                ['listed_in', 'not_listed_in'].includes(values.operator) && (
                  <FormikWorkflowMultiSelect
                    name='value'
                    label='Select Value'
                    options={listOptions}
                  />
                )}

              {values.operator && attribute?.type === 'Date' && (
                <Box className={classes.datePickerWrapper}>
                  <Box>
                    {isFormula.start ? (
                      <CustomFormikWorkflowInput
                        label='Enter Value'
                        name='start_value'
                        placeholder='Enter Formula'
                        noMargin={true}
                      />
                    ) : (
                      <FormikWorkflowDatePicker
                        label='Enter Value'
                        name='start_date'
                      />
                    )}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isFormula.start}
                            onChange={(e) => {
                              setFieldValue('start_date', null);
                              setFieldValue('start_value', 'CURRENT_DATE');
                              setIsFormula((prev) => ({
                                ...prev,
                                start: Boolean(e.target.checked),
                              }));
                            }}
                            disableRipple={true}
                            disabled={graphData.published === 1 ? true : false}
                          />
                        }
                        label='Add Formula'
                      />
                    </FormGroup>
                  </Box>

                  {['in_range', 'not_in_range'].includes(values.operator) && (
                    <Box>
                      {isFormula.end ? (
                        <CustomFormikWorkflowInput
                          label=''
                          name='end_value'
                          placeholder='Enter Formula'
                          noMargin={true}
                        />
                      ) : (
                        <FormikWorkflowDatePicker label='' name='end_date' />
                      )}

                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isFormula.end}
                              onChange={(e) => {
                                setFieldValue('end_date', null);
                                setFieldValue('end_value', 'CURRENT_DATE');
                                setIsFormula((prev) => ({
                                  ...prev,
                                  end: Boolean(e.target.checked),
                                }));
                              }}
                              disableRipple={true}
                              disabled={
                                graphData.published === 1 ? true : false
                              }
                            />
                          }
                          label='Add Formula'
                        />
                      </FormGroup>
                    </Box>
                  )}
                  {(isFormula.start || isFormula.end) && formulaUsage}
                </Box>
              )}

              {/* If the selected attribute is boolean type */}
              {attribute?.type === 'Boolean' && (
                <CustomFormikWorkflowSelect
                  name='value'
                  label='Select Value'
                  options={booleanOptions}
                />
              )}

              {(values.operator || !attribute?.allowed_operator) &&
                attribute?.type === 'List' &&
                !['listed_in', 'not_listed_in'].includes(values.operator) && (
                  <CustomFormikWorkflowSelect
                    name='value'
                    label='Select Value'
                    options={listOptions}
                  />
                )}
            </Box>

            <CheckConditions
              values={values}
              resetForm={resetForm}
              setFieldValue={setFieldValue}
              setAttribute={setAttribute}
              updateOptions={updateOptions}
              isFormula={isFormula}
              setIsFormula={setIsFormula}
              resetToInitial={() => setValues(initialValues)}
            />
          </Box>

          {!graphData.published && (
            <Box className={classes.buttonWrapper}>
              <CheckActionButtons values={values} handleDelete={handleDelete} />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CheckAttributeDrawer;
