import { makeStyles, Theme } from '@material-ui/core';
import CustomIcon from 'components/customIcons';

interface ChannelDisplayBoxProps {
  channelName: string;
  channelIconName: string;
  channelColor: string;
  channelBackgroundColor: string;
  channelIconColor: string;
}

export interface StyleProps extends ChannelDisplayBoxProps {}

const useStyles = makeStyles<Theme, StyleProps>({
  channelDisplayBoxContainer: {
    display: 'inline-block',
    padding: '3px 10px',
    borderRadius: '4px',
    marginLeft: '5px',
    marginBottom: '2px',
  },
});

const ChannelDisplayBox = (props: ChannelDisplayBoxProps) => {
  const { channelIconName, channelIconColor } = props;
  const { channelDisplayBoxContainer } = useStyles(props);
  return (
        <div className={channelDisplayBoxContainer}>
            <CustomIcon name={channelIconName} size={16} color={channelIconColor}/>
        </div>
        );
};

export default ChannelDisplayBox;
