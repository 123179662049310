import {
  Box,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Slider } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import { changeSliderValue } from 'store/reducer/strategyReducer';
import {
  getStrategyDetails,
  updateStrategyDetails,
} from 'store/reducer/strategyReducer/action';

const useStyles: any = makeStyles<Theme>({
  container: {
    height: '100%',
    maxWidth: '600px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerCircle: {
    height: 10,
    width: 10,
    borderRadius: 100,
    backgroundColor: '#2C62EC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerCircle: {
    height: 6,
    width: 6,
    borderRadius: 100,
    backgroundColor: '#fff',
  },
  line: {
    height: 2,
    backgroundColor: 'blue',
    width: '14%',
    // flex:1,
    marginTop: -32,
    marginLeft: -18,
  },
});
const population = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
];

const PopulationLine = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [sliderDisabled, setSliderDisabled] = useState(true);
  const [sliderValue, setSliderValue] = useState(0);

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );

  const strategySliderValue: any = useAppSelector(
    (state: any) => state.strategy.sliderValue
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const challengerSummary = Array.isArray(strategyDetailsData?.strategy_summary)
    ? strategyDetailsData?.strategy_summary?.filter(
        (strategy: any) => strategy.ab_testing_group === 'B'
      )[0] || {}
    : strategyDetailsData?.strategy_summary || {};

  useEffect(() => {
    if (!challengerSummary.random_min || !challengerSummary.random_max) {
      setSliderValue(0);
    } else {
      setSliderValue(100 - challengerSummary.random_min + 1);
    }
  }, [challengerSummary]);

  const handleChangeSlider = (sliderEvent: any) => {
    dispatch(changeSliderValue(sliderEvent.target.value));
    setSliderValue(sliderEvent.target.value);
  };

  const handleUpdatePopulation = async () => {
    const populationRangePayload = {
      random_max: challengerSummary.random_max || 100,
      random_min: !strategySliderValue
        ? 100 - strategySliderValue
        : 100 - strategySliderValue + 1,
      strategy_summary_id: challengerSummary?.strategy_summary_id,
    };
    await dispatch(updateStrategyDetails(populationRangePayload));
    await dispatch(
      getStrategyDetails({ id: challengerSummary?.strategy_summary_id || '' })
    );
    setSliderValue(0);
    setSliderDisabled(true);
  };

  return (
    <Box className={classes.container}>
      <Slider
        aria-label='Custom marks'
        defaultValue={0}
        // getAriaValueText={"" + populationSelected}
        step={10}
        valueLabelDisplay='auto'
        marks={population}
        onChange={handleChangeSlider}
        max={50}
        value={sliderValue}
        disabled={sliderDisabled}
      />
      {sliderDisabled ? (
        <Box marginX={4}>
          <button
            style={{
              background: '#2C62EC',
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '1rem',
            }}
            onClick={() => setSliderDisabled(false)}
          >
            Edit
          </button>
        </Box>
      ) : (
        <Box marginX={4}>
          <button
            style={{
              background: '#2C62EC',
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '1rem',
            }}
            onClick={handleUpdatePopulation}
          >
            Update
          </button>
        </Box>
      )}
    </Box>
  );
};

export default PopulationLine;
