import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createButton: {
      borderRadius: "8px",
      border: "transparent",
      color: theme.kollectAITheme.white,
      fontSize: "1rem",
      padding: "0.625rem 4.5rem",
      background: theme.kollectAITheme.mediumAquamarine,
      fontWeight: 500,
    },
    cancelButton: {
      borderRadius: "8px",
      border: "transparent",
      color: theme.kollectAITheme.white,
      fontSize: "1rem",
      padding: "0.625rem 4.5rem",
      background: theme.kollectAITheme.parrotPink,
      fontWeight: 500,
    },
    title: {
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "30px",
      color: theme.kollectAITheme.primaryText,
    },
    description: {
      marginTop: "3rem",
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "23px",
      color: theme.kollectAITheme.primaryText,
      opacity: 0.5,
    },

    buttonContainer: {
      margin: "48px auto 28px",
      display: "flex",
      justifyContent: "center",
      columnGap: "1.5rem",
    },

    formInput: {
      marginTop: 20,
      width: "80%",
      border: " 1px solid #CCCCCC",
      height: "38px",
      borderRadius: "5px",
      padding: "0 1em",
      fontSize: "0.9em",
      fontFamily: "inherit",
      background: "rgba(224, 231, 255, 0.2)",

      "&:focus": {
        outline: "none",
      },
    },
    formErrorMessage: {
      marginTop: 10,
      color: "#d32f2f",
      fontSize: "0.8em",
    },
  })
);

export default useStyles;
