import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikCustomerInput } from 'components/formik/customer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';
import {
  PrioritySlider,
  PrioritySliderCont,
} from 'components/customer/ActionModals/AddRequest/index.styles';

const marks = [
  { value: 0, label: 'Low' },
  { value: 50, label: 'Med' },
  { value: 100, label: 'High' },
];

const validationSchema = yup.object().shape({
  priority: yup.number().required('Required'),
  task_category: yup.string().required('Required'),
});

const AssignAgentTask: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  const [inititalState, setInititalState] = useState({
    priority: 0,
    task_category: '',
  });

  useEffect(() => {
    if (value) {
      setInititalState({
        priority: defaultPriority(value.priority) || 0,
        task_category: value.task_category,
      });
    }
  }, [value]);

  const defaultPriority = (priorityText: string) => {
    if (priorityText === 'Low') return 0;
    if (priorityText === 'Med') return 50;
    if (priorityText === 'High') return 100;
  };

  const getPriority = (priority: number) => {
    if (priority === 0) return 'Low';
    if (priority === 50) return 'Med';
    if (priority === 100) return 'High';
  };

  const handleSubmit = (values: any) => {
    const payload = {
      priority: getPriority(+values.priority)!,
      task_category: values.task_category,
    };
    setFieldValue(actionPath, 'assign_agent_task');
    setFieldValue(path, payload);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(form) => (
          <Form>
            <PrioritySliderCont>
              <Typography>Priority</Typography>
              <PrioritySlider
                track={false}
                aria-labelledby='track-false-slider'
                defaultValue={0}
                step={50}
                marks={marks}
                value={form.values.priority}
                onChange={(event: any) => {
                  const { value } = event.target;
                  form.setFieldValue('priority', value);
                }}
              />
            </PrioritySliderCont>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Task Category</Typography>
              <FormikCustomerInput name='task_category' />
            </Box>

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AssignAgentTask;
