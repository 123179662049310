import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    lineChartLabelWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '2em',
    },
    lineChartColorCircle: {
      width: '15px',
      height: '15px',
      background: (props: any) => props.background,
      borderRadius: '50%',
      marginRight: '10px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  })
);

export default useStyles;
