import { Typography } from "@material-ui/core";
import { styled, Box } from "@mui/material";
import { useAppDispatch } from "hooks/useRedux";
import { useState } from "react";
import useStyles from "./index.styles";

interface IDuplicateStrategyProps {
  strategyNames: Array<string>;
  close: () => void;
  handleDuplicateStrategy: (x: string) => void;
}

const DuplicateStrategy: React.FC<IDuplicateStrategyProps> = ({
  strategyNames,
  close,
  handleDuplicateStrategy,
}) => {
  const classes = useStyles();
  const [duplicateStrategyName, setDuplicateStrategyName] =
    useState<string>("");
  const isSameName = strategyNames[0] === duplicateStrategyName;

  return (
    <Box width="714px">
      <Typography className={classes.title}>
        Copying Strategy: {strategyNames[0]}
      </Typography>
      <Typography className={classes.description}>
        Please add name for your newly copied strategy group
      </Typography>
      <Box display="flex" alignItems="center" flexDirection="column">
        <input
          type="text"
          name="strategy_group_name"
          placeholder="Strategy Group Name"
          value={duplicateStrategyName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDuplicateStrategyName(e.target.value)
          }
          className={classes.formInput}
        />
        {isSameName && (
          <p className={classes.formErrorMessage}>Conflicting Strategy Name</p>
        )}
      </Box>

      <Box className={classes.buttonContainer}>
        <button
          type="submit"
          className={classes.createButton}
          onClick={() => handleDuplicateStrategy(duplicateStrategyName)}
        >
          Create
        </button>
        <button type="submit" className={classes.cancelButton} onClick={close}>
          Cancel
        </button>
      </Box>
    </Box>
  );
};

export default DuplicateStrategy;
