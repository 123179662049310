import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCont: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      height: '100vh',
      '& p': { fontFamily: 'Inter' },
    },
    leftBoxCont: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      background: theme.kollectAITheme.lightPrimaryColor,
    },
    leftBoxImgCont: {
      width: '450px',
      marginBottom: '2rem',

      '& img': {
        height: 'auto',
        width: '100%',
      },
    },
    leftBoxTextCont: {
      width: '380px',
      textAlign: 'center',
    },
    rightBoxCont: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      position: 'relative',
    },
    topDotsStyle: {
      position: 'absolute',
      top: 5,
      left: 20,
      '& img': { width: 150 },
    },
    bottomDotsStyle: {
      position: 'absolute',
      bottom: 30,
      right: 40,
      '& img': { width: 100 },
    },
  })
);

export default useStyles;
