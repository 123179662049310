import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionWrapper: {
      display: 'grid',
      gridTemplateColumns: '50px 1fr',
      alignItems: 'center',
      columnGap: '1rem',
    },
    indexCont: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      border: '2px dashed #000000',
      display: 'grid',
      placeItems: 'center',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    optionCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#333333',
      color: '#fff',
      padding: '10px 1rem',
      cursor: 'pointer',
    },
    filterDataCont: {
      background: 'rgba(217, 217, 217, 0.2)',
      marginLeft: 'calc(50px + 1rem)',
      padding: '1rem 2rem',
    },
  })
);

export default useStyles;
