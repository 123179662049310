import { useState, useEffect } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { MdDeleteForever } from 'react-icons/md';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { toggleDrawer } from 'store/reducer/workflowReducer';

import { CustomFormikWorkflowInput } from 'components/formik/workflow';
import {
  getGraphData,
  getWorkflowActionData,
  // getWorkflowSteps,
} from 'utils/workflow/graphHandler';
import AttibuteFields from './attributeField/attributeFields';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface UpdateAttributeDrawerProps {
  validationSchema: any;
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
}

const Button = styled('button')((props: { backgroundColor: string }) => ({
  background: props.backgroundColor,
  borderRadius: '4px',
  color: '#fff',
  padding: '5px 16px',
}));

const UpdateAttributeDrawer: React.FC<UpdateAttributeDrawerProps> = ({
  setLoading,
  validationSchema,
  updateJsonConfig,
}) => {
  const dispatch = useAppDispatch();

  // const userEmail = useAppSelector((state: any) => state.auth.email);
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const workflowActionData = getWorkflowActionData();
  const graphData: any = getGraphData();
  // const workflowSteps = getWorkflowSteps(
  //   graphData.config.Steps,
  //   workflowActionData?.name
  // );
  const [attributeList, setAttributeList] = useState([
    { attribute: '', value: '' },
  ]);
  const [isValidated, setIsValidated] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    workflowActionData.updates && setAttributeList(workflowActionData.updates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validated = attributeList.every((obj: any) =>
      Object.values(obj).every(
        (value) => value !== '' && value !== null && value !== undefined
      )
    );
    setIsValidated(validated);
    setShowError(false);
  }, [attributeList]);

  const handleSubmit = async (values: any) => {
    if (!isValidated) {
      setShowError(true);
      return;
    }

    const { stepName } = values;
    setLoading(true);
    let configObj = { ...graphData.config };
    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'UPDATE_ATTRIBUTE',
          updates: attributeList,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'updates');
      updateWorkflow(configObj, keys, attributeList);
    }

    updateJsonConfig(configObj);
  };

  const handleDelete = async () => {
    setLoading(true);
    let configObj = { ...graphData.config };
    const { parent, parentValue } = workflowActionData;

    const path = getPath(configObj, parent, parentValue);
    const oldData = getStepValue(configObj, path);
    let data = [{ name: '', parentValue, parent, position: 1 }];
    if (oldData.length > 1) {
      data = oldData
        .filter((item: any) => item.name !== workflowActionData.name)
        .map((el: any, index: number) => ({ ...el, position: index + 1 }));
    }
    updateConfigObj(configObj, path, data);

    updateJsonConfig(configObj);
  };

  const permissions = useAppSelector(userPermissions);

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form
          style={{
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              // type='text'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              // type='text'
              placeholder='Precursor Name'
              disabled={true}
            />

            <AttibuteFields
              inputList={attributeList}
              setInputList={setAttributeList}
            />
            {showError && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                Attribute name or value cannot be empty.
              </Typography>
            )}
          </Box>

          {!graphData.published && (
            <Box display='flex' gap='3rem' justifyContent='center' mt='1rem'>
              {permissions[MODULELIST.WORKFLOW].includes('update') && (
                <Button type='submit' backgroundColor='#6ac5ab'>
                  <Typography>Update</Typography>
                </Button>
              )}
              {/* {userEmail === 'aayushi@opendatafabric.com' && */}
              {permissions[MODULELIST.WORKFLOW].includes('delete') &&
              workflowEditor === 'edit' ? (
                <Button
                  type='button'
                  backgroundColor='#daa09a'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                  onClick={handleDelete}
                >
                  <MdDeleteForever fontSize={20} color='#fff' />
                  <Typography>Delete</Typography>
                </Button>
              ) : (
                <Button
                  backgroundColor='#daa09a'
                  onClick={() => dispatch(toggleDrawer(false))}
                >
                  <Typography>Discard</Typography>
                </Button>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateAttributeDrawer;
