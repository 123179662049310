import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {
  FormikCustomerCheckbox,
  FormikCustomerSelect,
  FormikCustomerInput,
} from 'components/formik/customer';
import FormikTemplateActionSelect from 'components/formik/template/FormikActionSelect';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { FormPropsType } from '../..';

import useStyles, { ActionButton, ActionButtonWrapper } from '../index.styles';

const formats = [
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'DD/MM/YY', value: 'DD/MM/YY' },
  { label: 'MM/DD/YY', value: 'MM/DD/YY' },
  { label: 'DD-MMM-YY', value: 'DD-MMM-YY' },
  { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
  { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
  { label: 'DD-MM-YY', value: 'DD-MM-YY' },
  { label: 'MM-DD-YY', value: 'MM-DD-YY' },
];

const specifyDateOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const promiseTypeOptions = [
  { label: 'Response Based', value: 'response' },
  { label: 'Relative Date', value: 'relative' },
];

const validationSchema = yup.object().shape({
  specifyDate: yup.string().required('Required'),
  promiseType: yup.string(),
  relativeDate: yup
    .string()
    .nullable()
    .when('promiseType', {
      is: 'relative',
      then: yup.string().required('Required'),
    }),
  wantFormatValidation: yup.boolean(),
  validationFormat: yup
    .string()
    .nullable()
    .when('wantFormatValidation', {
      is: true,
      then: yup.string().required('Required'),
    }),
  wantMaxDate: yup.boolean(),
  maxDate: yup
    .string()
    .nullable()
    .when('wantMaxDate', {
      is: true,
      then: yup.string().required('Required'),
    }),
});

const UpdatePTPCommitmentForm: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
}) => {
  const classes = useStyles();

  const [inititalState, setInititalState] = useState({
    specifyDate: 'yes',
    promiseType: 'response',
    wantFormatValidation: false,
    validationFormat: '',
    wantMaxDate: false,
    maxDate: '',
    relativeDate: '',
  });

  useEffect(() => {
    if (value) {
      let payload = {
        specifyDate: 'yes',
        promiseType: 'response',
        wantFormatValidation: false,
        validationFormat: '',
        wantMaxDate: false,
        maxDate: '',
        relativeDate: '',
      };
      if ('response_validation' in value) {
        const resp = value.response_validation[0];
        if ('format' in resp) {
          payload = {
            ...payload,
            wantFormatValidation: true,
            validationFormat: resp.format,
          };
        }
        if ('max' in resp) {
          const date = resp.max.split('+')[1].trim();
          payload = {
            ...payload,
            wantMaxDate: true,
            maxDate: date,
          };
        }
      }
      if ('relative_date' in value) {
        const date = value.relative_date.split('+')[1].trim();
        payload = {
          ...payload,
          promiseType: 'relative',
          relativeDate: date,
        };
      }
      if (!Object.keys(value).length) {
        payload = { ...payload, specifyDate: 'no' };
      }
      setInititalState(payload);
    }
  }, [value]);

  const handleSubmit = (values: FormikValues) => {
    console.log({ values });
    const {
      specifyDate,
      promiseType,
      relativeDate,
      wantFormatValidation,
      validationFormat,
      wantMaxDate,
      maxDate,
    } = values;
    let payload = {};
    if (specifyDate === 'yes') {
      if (promiseType === 'response') {
        let validation: any = {};
        if (wantFormatValidation) {
          validation['format'] = validationFormat;
        }
        if (wantMaxDate) {
          validation['max'] = `CURRENT_DATE + ${maxDate}`;
        }
        payload = { response_validation: [validation] };
      }
      if (promiseType === 'relative') {
        payload = { relative_date: `CURRENT_DATE + ${relativeDate}` };
      }
    }
    setFieldValue(actionPath, 'update_ptp_commitment');
    setFieldValue(path, payload);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, setTouched, setFieldValue }) => (
          <Form>
            <FormikTemplateActionSelect
              label='Want to specify PTP commitment date attribute?'
              name='specifyDate'
              options={specifyDateOptions}
            />

            {values.specifyDate === 'yes' ? (
              <>
                <FormikTemplateActionSelect
                  label='What should be saved as promise to pay date?'
                  name='promiseType'
                  options={promiseTypeOptions}
                />

                {values.promiseType === 'response' ? (
                  <>
                    <Box className={classes.checkboxWrapper}>
                      <FormikCustomerCheckbox
                        name='wantFormatValidation'
                        label='Want to put format validation on response'
                      />
                      {values.wantFormatValidation ? (
                        <FormikCustomerSelect
                          name='validationFormat'
                          placeholder='Select Format'
                          options={formats}
                        />
                      ) : null}
                    </Box>

                    <Box className={classes.checkboxWrapper}>
                      <FormikCustomerCheckbox
                        name='wantMaxDate'
                        label='Want to put max commitment date limit'
                      />
                      {values.wantMaxDate ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography mr='10px'>CURRENT_DATE + </Typography>
                          <FormikCustomerInput name='maxDate' width={16} />
                          <Typography ml='10px'>Days</Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>Provide the relative date</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography mr='10px'>CURRENT_DATE + </Typography>
                      <FormikCustomerInput name='relativeDate' width={16} />
                      <Typography ml='10px'>Days</Typography>
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <Box>
                <Typography fontStyle='italic' color='#272D3B'>
                  The default commitment date of CURRENT_DATE + 7 days will be
                  saved on getting the response
                </Typography>
              </Box>
            )}

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdatePTPCommitmentForm;
