import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AiOutlineCalendar } from 'react-icons/ai';

interface DatePickerProps {
  name: string;
  label: string;
}

const DatePickeWrapper = styled('div')({
  position: 'relative',
  marginTop: '0.5rem',
  width: '100%',
  border: '1px solid #D4DCEB',
  borderRadius: '4px',

  '& .react-datepicker__input-container input': {
    width: '185px !important',
    background: 'transparent !important',
    cursor: 'default',
    zIndex: 10,
    position: 'relative',
    padding: '0.5rem',
    fontSize: '0.9rem !important',
  },
});

const CustomerCallDatePicker = ({ name, label }: DatePickerProps) => {
  return (
    <Box mb='1rem'>
      <label>
        <Typography
          sx={{ opacity: '0.6', fontFamily: 'Overpass', fontSize: '0.9rem' }}
          mb='5px'
        >
          {label}
        </Typography>
      </label>

      <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box>
              <DatePickeWrapper>
                <DatePicker
                  id={name}
                  {...field}
                  selected={value}
                  onChange={(val) => setFieldValue(name, val)}
                  placeholderText='DD/MM/YYYY'
                />
                <Box sx={{ position: 'absolute', right: '10px', top: '7px' }}>
                  <AiOutlineCalendar color='#294E9B' fontSize='1.2rem' />
                </Box>
              </DatePickeWrapper>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </Box>
  );
};

export default CustomerCallDatePicker;
