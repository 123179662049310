import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  getCampaignFilter,
  getCampaignPayload,
} from 'utils/campaign/campaignModifier';
import {
  addCustomFilter,
  deleteCustomFilter,
  getCampaignCommStatus,
  getCampaignData,
  getCampaignDetails,
  getCampaignExecutionList,
  getCampaignFilterAttributes,
  getCampaignSummary,
  getCustomFilter,
  getDayTimePenetration,
  updateCustomFilter,
} from './action';

interface InitialStateType {
  campaignSummary: any;
  campaign: any;
  customFilter: any;
  campaignPayload: null | {
    add_filter: { filter: string; value: any };
    select_channel_template: any[];
    schedule_time: string;
  };
  campaignActionType: string;
  campaignFilterAttributes: {
    allowed_operator: string[];
    allowed_values: string | string[];
    attribute: string;
    type: string;
  }[];
  campaignAb: any;
  commStatus: any;
  penetrationData: any;
  campaignExecutionId: string;
  campaignExecutionList: { execution_id: string; schedule_time: string }[];
  campaignDetailStatus: string;
}

const initialState: InitialStateType = {
  campaignSummary: null,
  campaign: null,
  customFilter: null,
  campaignPayload: null,
  campaignActionType: '',
  campaignFilterAttributes: [],
  campaignAb: null,
  commStatus: null,
  penetrationData: null,
  campaignExecutionId: '',
  campaignExecutionList: [],
  campaignDetailStatus: '',
};

export const CampaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignPayload: (state, { payload }) => {
      const { key, data } = payload as { key: string; data: any };
      if (state.campaignPayload) {
        switch (key) {
          case 'add_filter':
            state.campaignPayload.add_filter = data;
            break;
          case 'select_channel_template':
            state.campaignPayload.select_channel_template = data;
            break;
          case 'schedule_time':
            state.campaignPayload.schedule_time = data;
            break;
        }
      }
    },
    setCampaign: (state, { payload }) => {
      state.campaign = payload;
    },
    setCampaignActionType: (state, { payload }) => {
      state.campaignActionType = payload;
    },
    resetCampaignPayload: (state) => {
      state.campaignPayload = null;
    },
    setCustomFilter: (state, { payload }) => {
      state.customFilter = payload;
    },
    setInitialCampaign: (state, { payload }) => {
      state.campaign = payload;
      state.campaignPayload = {
        add_filter: { filter: 'group', value: payload?.group_as_one },
        select_channel_template: [],
        schedule_time: 'now',
      };
    },
    setCampaignExecutionId: (state, { payload }) => {
      state.campaignExecutionId = payload;
    },
    setCampaignExecutionList: (state, { payload }) => {
      state.campaignExecutionList = payload;
    },
    resetCampaignCommStatus: (state, actions: PayloadAction<void>) => {
      state.commStatus = null;
    },
    setCampaignDetailStatus: (state, actions: PayloadAction<string>) => {
      state.campaignDetailStatus = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCustomFilter.fulfilled, (state, { payload }) => {
      state.customFilter = payload.data;
    });
    builder.addCase(updateCustomFilter.fulfilled, (state, { payload }) => {
      if (payload?.meta.success) {
        state.customFilter = payload.data;
      } else {
        state.customFilter = null;
      }
    });
    builder.addCase(deleteCustomFilter.fulfilled, (state, { payload }) => {
      if (payload?.meta.success) {
        state.customFilter = payload.data;
      } else {
        state.customFilter = null;
      }
    });
    builder.addCase(getCampaignData.fulfilled, (state, { payload }) => {
      state.campaign = payload;
    });
    builder.addCase(getCampaignDetails.fulfilled, (state, { payload }) => {
      state.campaign = payload;
      state.campaignPayload = getCampaignPayload(payload);
    });

    builder.addCase(getCampaignSummary.fulfilled, (state, { payload }) => {
      state.campaignSummary = payload;
    });
    builder.addCase(getCustomFilter.fulfilled, (state, { payload }) => {
      const { data, meta } = payload;
      if (meta?.success) {
        if (data.length === 1 && data[0]?.data === 'No custom filter') {
          state.customFilter = null;
        } else {
          state.customFilter = data;
        }
      }
    });
    builder.addCase(
      getCampaignFilterAttributes.fulfilled,
      (state, { payload }) => {
        const { data, meta } = payload;
        if (meta?.success) {
          state.campaignFilterAttributes = getCampaignFilter(
            data.allowed_attribute
          );
        }
      }
    );
    builder.addCase(getCampaignCommStatus.fulfilled, (state, { payload }) => {
      state.commStatus = payload;
    });
    builder.addCase(getDayTimePenetration.fulfilled, (state, { payload }) => {
      state.penetrationData = payload;
    });
    builder.addCase(
      getCampaignExecutionList.fulfilled,
      (state, { payload }) => {
        state.campaignExecutionList = Object.values(payload?.execution_dict);
      }
    );
  },
});

export const {
  setCampaignPayload,
  resetCampaignPayload,
  setCustomFilter,
  setInitialCampaign,
  setCampaignActionType,
  setCampaign,
  setCampaignExecutionId,
  setCampaignExecutionList,
  resetCampaignCommStatus,
  setCampaignDetailStatus,
} = CampaignSlice.actions;

export const campaignSummaryData = (state: any) =>
  state.campaign.campaignSummary;

export default CampaignSlice.reducer;

export const campaignFilters = (state: RootState) =>
  state.campaign.campaignFilterAttributes;
export const campaignData = (state: RootState) => state.campaign.campaign;
export const campaignCommStatus = (state: RootState) =>
  state.campaign.commStatus;
export const penetrationData = (state: RootState) =>
  state.campaign.penetrationData;
export const campaignExecutionId = (state: RootState) =>
  state.campaign.campaignExecutionId;
export const campaignExecutionList = (state: RootState) =>
  state.campaign.campaignExecutionList;
export const campaignDetailStatus = (state: RootState) =>
  state.campaign.campaignDetailStatus;
