import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem',
    },
    optionText: {
      color: theme.kollectAITheme.primaryColor,
      fontFamily: 'Open Sans',
      fontSize: '0.8rem !important',
    },

    formValueWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      background: '#FFFFFF',
      border: '1px solid #E6E6E6',
      padding: '0.8rem',
    },
    emptyBox: {
      background: 'rgba(150, 150, 150, 0.11)',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      width: 110,
      height: 38,

      '@media (min-width: 1601px)': { width: 138 },
      '@media (min-width: 1801px)': { width: 180 },
    },
    addOptionButton: {
      position: 'absolute',
      right: '5px',
      top: '-60px',
    },
    addOptionCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '& p': { color: theme.kollectAITheme.primaryColor },
      '& svg': { fontSize: '2rem', color: '#6AC5AB' },
    },
    optionCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginRight: '1rem',
    },
    addActionCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#D9D9D9',
      margin: '0.5rem 0',
    },
    defaultWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      justifyContent: 'center',
    },
    defaultAction: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginRight: 'calc(44px + 1rem)',
    },
  })
);

export default useStyles;
