import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  SelectChangeEvent,
  Pagination,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { useNavigate, useParams } from 'react-router-dom';
import { SlArrowDown } from 'react-icons/sl';
import { kollectAITheme } from 'config/theme';
import useStyles from './index.styles';

import CustomerOptionBox from './OptionBox';
import { getStatusData } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getCustomerDetails,
  getCustomerProfile,
} from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import {
  refetchSidetableCustomer,
  setCustomerProfile,
  setRefetchSidetableCustomer,
} from 'store/reducer/customerReducer';

const customerStatus = [
  { name: 'New or Uncategorised', value: 'Default' },
  { name: 'PTP', value: 'PTP' },
  { name: 'PTP Broken', value: 'Broken_Promise' },
  { name: 'Call Required', value: 'Call_Required' },
  { name: 'Hardship', value: 'Hardship' },
  { name: 'Not Engaged', value: 'Not_engaged' },
  { name: 'Bad Contact', value: 'Bad_Contact' },
  { name: 'Show All', value: 'show_all' },
];

interface CustomerSideTableProps {
  status: string;
  setStatus: (x: string) => void;
}

const CustomerSideTable: React.FC<CustomerSideTableProps> = ({
  status,
  setStatus,
}) => {
  const { id: customerId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customerCategory = useAppSelector(
    (state) => state.customer.customerCategory
  );
  const refetchCustomer = useAppSelector(refetchSidetableCustomer);
  const customerStats = useAppSelector((state) => state.customer.customerStats);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);
  const [statusData, setStatusData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const fetchCustomerDetails: any = useCallback(
    async (value: any) => {
      const res = await dispatch(
        getCustomerDetails({ category: value, page: 1 })
      ).unwrap();
      const total = Math.ceil(res.count / 10);
      setTotalPage(total);
      return res;
    },
    [dispatch]
  );

  useEffect(() => {
    const data = getStatusData(customerCategory, customerStats);
    setStatusData(data);
    fetchCustomerDetails(customerCategory);
  }, [customerCategory, customerStats, fetchCustomerDetails]);

  useEffect(() => {
    if (refetchCustomer) {
      fetchCustomerDetails(customerCategory);
      dispatch(setRefetchSidetableCustomer(false));
    }
  }, [customerCategory, dispatch, fetchCustomerDetails, refetchCustomer]);

  const handleStatusChange = async (event: SelectChangeEvent) => {
    setLoading(true);
    try {
      const { value } = event.target;
      setStatus(value);
      const data = getStatusData(value, customerStats);
      setStatusData(data);
      const { results } = await fetchCustomerDetails(value);

      if (results.length) {
        const id = results[0]['customer_id'];
        await dispatch(getCustomerProfile({ customerId: id }));
        navigate(`/customer-details/${id}`);
        setSelectedCustomerId(id);
      } else {
        dispatch(setCustomerProfile([]));
      }
      setCurrentPage(1);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomer = async (customer_id: string) => {
    setLoading(true);
    // await dispatch(getCustomerProfile({ customerId: customer_id }));
    setSelectedCustomerId(customer_id);
    navigate(`/customer-details/${customer_id}`);
    setLoading(false);
  };

  const handlePagination = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      await dispatch(getCustomerDetails({ category: status, page })).unwrap();
      setCurrentPage(page);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box>
        <Box position='relative'>
          <FormControl sx={{ m: 1, minWidth: 309, zIndex: 10 }}>
            <Select
              value={status}
              onChange={handleStatusChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.selectStyle}
              MenuProps={{ classes: { paper: classes.paperStyle } }}
              IconComponent={() => (
                <SlArrowDown
                  color={kollectAITheme.primaryColor}
                  fontSize='2.5rem'
                />
              )}
            >
              {customerStatus?.map((type: any, index: number) => (
                <MenuItem value={type.value} key={index}>
                  {type?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ position: 'absolute', top: 13, left: 15 }}>
            <CustomerOptionBox {...statusData} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            my: '10px',
          }}
        >
          <button onClick={() => navigate('/customer-management')}>
            <Typography
              sx={{
                color: kollectAITheme.primaryColor,
                fontSize: '0.9rem',
                fontWeight: 700,
              }}
            >{`Show List View >>`}</Typography>
          </button>
        </Box>

        <TableContainer
          sx={{
            background: '#fff',
            border: '1px solid #E6E6E6',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
            height: 'calc(100vh - 300px)',
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: '1px solid #E6E6E6',
              },
              '& p': {
                fontFamily: 'Inter',
              },
            }}
            aria-label='simple table'
          >
            <TableHead
              sx={{
                filter:
                  'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              }}
            >
              <TableRow
                sx={{
                  '& th': {
                    color: '#818397',
                    fontWeight: 500,
                    background: '#fff',
                    textAlign: 'center',
                  },
                }}
              >
                <TableCell>CUSTOMER ID</TableCell>
                <TableCell width={100}>NET BALANCE DUE</TableCell>
                <TableCell width={100}>DAYS PAST DUE</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customerDetails?.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    background:
                      row.customer_id === selectedCustomerId
                        ? 'rgba(0, 67, 87, 0.12)'
                        : '#fff',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  onClick={() => handleCustomer(row.customer_id)}
                >
                  <TableCell align='center' component='th' scope='row'>
                    {row.customer_id}
                  </TableCell>
                  <TableCell align='center'>
                    $ {row.net_balance_due.toLocaleString()}
                  </TableCell>
                  <TableCell align='center'>{row.max_dpd}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display='flex' justifyContent='center'>
          <Pagination
            count={totalPage}
            variant='outlined'
            shape='rounded'
            page={currentPage}
            onChange={handlePagination}
            sx={{
              '.Mui-selected': {
                background: '#004357 !important',
                color: '#fff',
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomerSideTable;
