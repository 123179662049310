import { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
// import { useParams } from 'react-router-dom';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import { postCustomerCommunicationUpdate } from 'store/reducer/customerReducer/action';

import Loader from 'components/loader';
import TemplatePreview from './preview';
import { FormikCustomerSelect } from 'components/formik/customer';
import { concatenatedTemplate } from 'utils/helper';
import SendCommunicationPreview from './nonEmailPreview';
import { selectedCustomersArr } from 'store/reducer/customerReducer';
import { successToast } from 'utils/toast';

const channels = [
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'IVR', value: 'ivr' },
];

interface SendCommunicationFormProps {
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  channel: yup.string().required('Required'),
  template: yup.string().required('Required'),
});

const SendCommunicationForm: React.FC<SendCommunicationFormProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  // const { id: customerId } = useParams();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [loading, setLoading] = useState<boolean>(false);
  const [allTemplates, setAllTemplates] = useState<any>({});
  const [templateOptions, setTemplateOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [template, setTemplate] = useState<any>({});

  const getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const {
        payload: { data, meta },
      } = await dispatch(viewChannelTemplate({ channel_type: 'all' }));
      if (meta?.message === 'success') {
        let templates: any = {};
        for (let key in data) {
          let template = concatenatedTemplate(data[key]);
          // template = template.filter((item: any) => !item.two_way_flag);
          templates = { ...templates, [key]: template };
        }
        const options = templates['email']?.map((template: any) => ({
          label: template.template_name,
          value: template.id,
        }));
        setTemplateOptions(options);
        setAllTemplates(templates);
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const handleChannelChange = (channel: string) => {
    const options = allTemplates[channel]?.map((template: any) => ({
      label: template.template_name,
      value: template.id,
    }));
    setTemplateOptions(options);
    setTemplate({});
  };

  const handleTemplateChange = (template: string, channel: string) => {
    const selected = allTemplates[channel].find(
      (item: any) => item.id === template
    );
    setTemplate(selected);
  };

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const odf_customer_id =
        selectedCustomers && selectedCustomers?.length
          ? selectedCustomers?.map((customer) => customer?.odf_customer_id)
          : [customerOdfId];
      const payload = {
        ...values,
        odf_customer_id,
        // customer_id: customerId,
      };
      await dispatch(postCustomerCommunicationUpdate(payload)).unwrap();
      successToast('Communication has been sent successfully');
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={{ channel: 'email', template: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: '2rem',
                marginBottom: '2rem',
              }}
            >
              <Box>
                <Typography mb='0.5rem'>
                  Please select the channel and template to send to the customer
                </Typography>
                <Box
                  mb='1rem'
                  sx={{ display: 'flex', alignItems: 'start', gap: '1rem' }}
                >
                  <FormikCustomerSelect
                    name='channel'
                    placeholder='Select Channel'
                    options={channels}
                    handleChange={(channel) => {
                      handleChannelChange(channel);
                      setFieldValue('channel', channel);
                      setFieldValue('template', '');
                    }}
                  />
                  <FormikCustomerSelect
                    name='template'
                    placeholder='Select Template'
                    options={templateOptions}
                    handleChange={(template) => {
                      handleTemplateChange(template, values.channel);
                      setFieldValue('template', template);
                    }}
                  />
                </Box>

                <TemplatePreview channel={values.channel} template={template} />
              </Box>

              {values.channel !== 'email' && (
                // <SidePreview channel={values.channel} template={template} />
                <Box
                  sx={{
                    width: 450,
                    height:
                      values.channel === 'ivr' && !template?.two_way_flag
                        ? 300
                        : 500,
                  }}
                >
                  <SendCommunicationPreview
                    channel={values.channel}
                    template={template}
                  />
                </Box>
              )}
            </Box>

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Send</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SendCommunicationForm;
