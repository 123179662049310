import React from 'react';
import { Box, TableCell, Typography } from '@mui/material';

export const CustomHeadCell = ({
  width = '12%',
  label,
  align = 'center',
  pl = 0,
  showLine = true,
}: {
  width?: string;
  label: string;
  align?: 'center' | 'left';
  pl?: string | number;
  showLine?: boolean;
}) => {
  return (
    <TableCell align={align} width={width} sx={{ position: 'relative' }}>
      <Typography pl={pl} fontWeight={500} textTransform='uppercase'>
        {label}
      </Typography>
      {showLine && (
        <Box
          sx={{
            position: 'absolute',
            height: '20px',
            background: '#32475C1F',
            width: '2px',
            top: 0,
            bottom: 0,
            right: 0,
            margin: 'auto 0',
          }}
        ></Box>
      )}
    </TableCell>
  );
};
