import { Typography, Box } from "@mui/material";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface WorkflowButtonProps {
  text: string;
  icon: JSX.Element;
  bgColor: string;
  onClick?: () => void;
  disabled?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    workflowButtonStyle: {
      display: "flex",
      background: (props: any) =>
        props.disabled ? theme.kollectAITheme.lightGrey : props.bgColor,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "7px",
      color: "#fff",
      padding: "0.3rem 0.8rem",
      gap: "5px",
      alignItems: "center",
      cursor: "pointer",
      height: "32px",
      maxWidth: 180,
      marginRight: 14,
      justifyContent: "center",
      "& svg": {
        fontSize: "1.2rem",
      },
    },
  })
);

const WorkflowButton: React.FC<WorkflowButtonProps> = ({
  text,
  icon,
  bgColor,
  onClick,
  disabled,
}) => {
  const classes = useStyles({ bgColor, disabled });
  return (
    <Box
      className={classes.workflowButtonStyle}
      onClick={disabled ? () => {} : onClick}
    >
      {icon}
      <Typography>{text}</Typography>
    </Box>
  );
};

export default WorkflowButton;
