import { useState, useEffect, useCallback } from 'react';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { Canvas, Edge, ElkRoot } from 'reaflow';
import styled from 'styled-components';

import { parser } from 'utils/template/jsonParser';
import { CustomNode } from 'components/templates/CustomNode';

interface FlowCanvasProps {
  json: string;
  fullView: boolean;
  direction: 'DOWN' | 'RIGHT';
}

const CanvasWrapper = styled.div<{ fullView: boolean }>`
  width: 100%;
  height: 100%;
  background: #f2f3f5;
  background-image: ${({ theme }) =>
    `radial-gradient(#505050 0.5px, #f2f3f5 0.5px)`};
  background-size: 15px 15px;

  :active {
    cursor: move;
  }

  .dragging,
  .dragging button {
    pointer-events: none;
  }

  rect {
    fill: #fff;
  }
`;

const FlowCanvas: React.FC<FlowCanvasProps> = ({
  json,
  fullView,
  direction,
}) => {
  const [nodes, setNodes] = useState<any>([]);
  const [edges, setEdges] = useState<any>([]);
  const [zoomPanPinch, setZoomPanPinch] = useState<
    ReactZoomPanPinchRef | undefined
  >(undefined);
  const [size, setSize] = useState({
    width: 1,
    height: 1,
  });

  const centerView = () => {
    const canvas = document.querySelector('.jsoncrack-canvas') as HTMLElement;
    if (zoomPanPinch && canvas) zoomPanPinch.zoomToElement(canvas);
  };

  useEffect(() => {
    const { nodes, edges } = parser(json, false);

    setNodes(nodes);
    setEdges(edges);
  }, [json]);

  const onInit = useCallback((ref: ReactZoomPanPinchRef) => {
    setZoomPanPinch(ref);
  }, []);

  const onLayoutChange = useCallback(
    (layout: ElkRoot) => {
      if (layout.width && layout.height) {
        const areaSize = layout.width * layout.height;
        const changeRatio = Math.abs(
          (areaSize * 100) / (size.width * size.height) - 100
        );

        setSize({ width: layout.width + 400, height: layout.height + 400 });

        requestAnimationFrame(() => {
          setTimeout(() => {
            // setLoading(false);
            setTimeout(() => changeRatio > 75 && centerView(), 0);
          }, 0);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size.width, size.height]
  );

  const onCanvasClick = useCallback(() => {
    const input = document.querySelector('input:focus') as HTMLInputElement;
    if (input) input.blur();
  }, []);

  return (
    <>
      <CanvasWrapper fullView={fullView}>
        <TransformWrapper
          maxScale={2}
          minScale={0.05}
          initialScale={0.4}
          wheel={{ step: 0.08 }}
          zoomAnimation={{ animationType: 'linear' }}
          doubleClick={{ disabled: true }}
          onInit={onInit}
          onPanning={(ref) =>
            ref.instance.wrapperComponent?.classList.add('dragging')
          }
          onPanningStop={(ref) =>
            ref.instance.wrapperComponent?.classList.remove('dragging')
          }
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              display: 'block',
            }}
          >
            <Canvas
              className='jsoncrack-canvas'
              nodes={nodes}
              edges={edges}
              maxWidth={size.width}
              maxHeight={size.height}
              direction={direction}
              onLayoutChange={onLayoutChange}
              onCanvasClick={onCanvasClick}
              zoomable={false}
              animated={false}
              readonly={true}
              dragEdge={null}
              dragNode={null}
              fit={true}
              key={direction}
              node={(props) => <CustomNode {...props} onClick={() => {}} />}
              edge={(props) => (
                <Edge {...props} containerClassName={`edge-${props.id}`} />
              )}
            />
          </TransformComponent>
        </TransformWrapper>
      </CanvasWrapper>
    </>
  );
};

export default FlowCanvas;
