import { createSlice } from '@reduxjs/toolkit';
import { saveFBData } from './action';

const initialState = {
  fbData: {},
};

export const FBConnectSlice = createSlice({
  name: 'fbConnect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveFBData.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      console.log('🚀 ~ builder.addCase ~ data:', data);
      console.log('🚀 ~ builder.addCase ~ meta:', meta);

      // if (meta?.message === 'success') {
      //   state.fbData = data;
      // }
    });
  },
});

export const fbData = (state: any) => state.fbConnect.fbData;

export default FBConnectSlice.reducer;
