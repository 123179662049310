import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideMenuComponentCont: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: theme.kollectAITheme.white,
      borderRight: '1px solid #DFE0E2',
      justifyContent: 'space-between',
      paddingBottom: '20px',
    },
    logoStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontSize: '30px',
      fontWeight: 'bold',
      color: theme.kollectAITheme.primaryColor,
    },
    sideOptionStyle: {
      position: 'absolute',
      width: '237px',
      top: '0',
      left: '80px',
      background: theme.kollectAITheme.primaryColor,
      zIndex: 1,
      borderRadius: '0px 10px 10px 0px',
    },
    sideOptionButton: {
      background: 'transparent',
      padding: '10px',
      border: 'transparent',
      color: '#fff',
      cursor: 'pointer',
      '&:hover': {
        borderBottom: '#fff',
      },
    },
  })
);

export default useStyles;
