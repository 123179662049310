import * as React from 'react';
import { FormikValues } from 'formik';
import { Box, Typography } from '@mui/material';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import moment from 'moment';

import { useAppSelector } from 'hooks/useRedux';
import { workflowAttributes } from 'store/reducer/workflowReducer';
import useStyles, { AddConditionButton } from './index.styles';

import { isDateValue } from 'utils/helper';
import { WorkflowAttributeType } from 'constants/types';

interface CheckConditionProps {
  values: FormikValues;
  resetForm: () => void;
  setFieldValue: (x: string, y: any) => void;
  setAttribute: (x: any) => void;
  updateOptions: (x: any) => void;
  setIsFormula: (x: { start: boolean; end: boolean }) => void;
  isFormula: { start: boolean; end: boolean };
  resetToInitial: () => void;
}

const CheckConditions = ({
  values,
  resetForm,
  setFieldValue,
  setAttribute,
  updateOptions,
  setIsFormula,
  isFormula,
  resetToInitial,
}: CheckConditionProps) => {
  const classes = useStyles();

  const [updateConditionObj, setUpdateConditionObj] = React.useState<any>(null);

  const allowedAttributes: any = useAppSelector(workflowAttributes)?.check;

  const getPayload = (values: FormikValues) => {
    const {
      attribute,
      operator,
      value,
      x_var,
      upper_value,
      start_date,
      end_date,
      start_value,
      end_value,
    } = values;

    const attr = allowedAttributes?.find(
      (item: WorkflowAttributeType) => item.attribute === attribute
    );
    let parameters = value;
    let attributeValue = attribute;
    let originalAttributeValue = attribute;
    if (
      attr.type === 'Number' &&
      ['in_range', 'not_in_range'].includes(operator)
    ) {
      parameters = { lower_limit: value, upper_limit: upper_value };
    }

    if (['listed_in', 'not_listed_in'].includes(operator)) {
      if (attr.type === 'List') parameters = [value];
      if (attr.type === 'Number') {
        const arr = value
          .split(',')
          .map((str: string) => str.replace(/\s/g, ''));
        parameters = JSON.stringify(arr);
      }
    }

    if (attr.type === 'Date') {
      const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');
      parameters = isFormula?.start ? start_value : formatDate(start_date);
      if (['in_range', 'not_in_range'].includes(values.operator)) {
        parameters = {
          start_date: isFormula?.start ? start_value : formatDate(start_date),
          end_date: isFormula?.end ? end_value : formatDate(end_date),
        };
      }
    }

    if (attr.type === 'Var') {
      attributeValue = values.attribute.replace('<x>', x_var);
    }

    return {
      attribute: attributeValue,
      condition: operator || 'equals_to',
      ...((parameters || parameters === 0) && { parameters }),
      originalAttributeValue,
    };
  };

  const setCheckState = (
    values: any,
    resetForm: any,
    setFieldValue: (x: string, value: any) => void,
    action: string
  ) => {
    const { stepName, precursorName } = values;
    const check = values.check || [];

    if (check.length < 5) {
      const payload = getPayload(values);
      if (Object.values(payload).every((el) => el !== '')) {
        resetForm();
        let newCheck = [];
        if (action === 'update') {
          newCheck = values.check.filter(
            (item: any) =>
              JSON.stringify(item) !== JSON.stringify(updateConditionObj)
          );
          newCheck = [...newCheck, payload];
          setUpdateConditionObj(null);
        } else {
          newCheck = [...check, payload];
        }
        setFieldValue('stepName', stepName);
        setFieldValue('precursorName', precursorName);
        setFieldValue('check', newCheck);
        setAttribute(null);
      }
    }
  };

  const handleConditionClick = React.useCallback(
    (check: any, setFieldValue: (x: string, value: any) => void) => {
      const { attribute, condition, parameters, originalAttributeValue } =
        check;

      let checkAttribute = originalAttributeValue || attribute;
      const attr: any = allowedAttributes?.find(
        (item: WorkflowAttributeType) => item.attribute === checkAttribute
      );

      setUpdateConditionObj(check);
      updateOptions(attr);

      if (typeof parameters === 'object') {
        if (attr?.type === 'Date') {
          const { start_date, end_date } = parameters;
          const isStartDate = isDateValue(start_date);
          const isEndDate = isDateValue(end_date);
          const startField = isStartDate ? 'start_date' : 'start_value';
          const startValue = isStartDate ? new Date(start_date) : start_date;
          const endField = isEndDate ? 'end_date' : 'end_value';
          const endValue = isEndDate ? new Date(end_date) : end_date;
          setFieldValue(startField, startValue);
          setFieldValue(endField, endValue);
          setIsFormula({
            start: isStartDate ? false : true,
            end: isEndDate ? false : true,
          });
        } else if (['listed_in', 'not_listed_in'].includes(condition)) {
          // ['Number', 'List'].includes(attr?.type) &&
          setFieldValue('value', parameters.join(', '));
        } else {
          setFieldValue('value', parameters?.lower_limit);
          setFieldValue('upper_value', parameters?.upper_limit);
        }
      } else {
        if (attr.type === 'Date') {
          if (isDateValue(parameters)) {
            setFieldValue('start_date', new Date(parameters));
            setIsFormula({ start: false, end: false });
          } else {
            setFieldValue('start_value', parameters);
            setIsFormula({ start: true, end: false });
          }
        } else if (['listed_in', 'not_listed_in'].includes(condition)) {
          setFieldValue('value', JSON.parse(parameters).join(', '));
        } else {
          setFieldValue('value', parameters);
        }

        if (attr.type === 'Var') {
          const orgAttrArr = originalAttributeValue.split('_');
          const attrArr = attribute.split('_');
          let xValue = '';
          orgAttrArr.forEach((attr: string, ind: number) => {
            if (attr === '<x>') xValue = attrArr[ind];
          });
          setFieldValue('x_var', xValue);
        }
      }

      if (attr?.type === 'Boolean') {
        setFieldValue('operator', '');
      } else {
        setFieldValue('operator', condition);
      }
      setAttribute(attr);
      // setFieldValue('attribute', attribute);
      setFieldValue('attribute', checkAttribute);
    },
    [allowedAttributes, setAttribute, setIsFormula, updateOptions]
  );

  const handleConditionRemove = (
    values: any,
    setFieldValue: any,
    condition: any
  ) => {
    handleUpdateDiscard();
    const newCheck = values.check.filter(
      (item: any) => JSON.stringify(item) !== JSON.stringify(condition)
    );
    setFieldValue('check', newCheck);
  };

  const getParameters = React.useCallback(
    (check: any) => {
      if (allowedAttributes?.length) {
        const para = check.parameters;
        const attr: any = allowedAttributes?.find(
          (item: WorkflowAttributeType) => item.attribute === check.attribute
        );

        if (typeof para === 'object') {
          if (Array.isArray(para)) {
            return para.join(', ').replace(/,/g, ', ');
          }
          return Object.values(para).join(' and ');
        }
        if (typeof para === 'number' && attr?.type === 'Boolean') {
          return para ? 'true' : 'false';
        }
        return para;
      }
    },
    [allowedAttributes]
  );

  const handleUpdateDiscard = () => {
    setAttribute(null);
    setUpdateConditionObj(null);
    resetToInitial();
    setIsFormula({ start: false, end: false });
  };

  return (
    <Box my='2rem'>
      <Box className={classes.conditionButtonCont}>
        {!updateConditionObj ? (
          <AddConditionButton
            check={values.check}
            onClick={() =>
              setCheckState(values, resetForm, setFieldValue, 'add')
            }
          >
            <AiFillPlusCircle />
            <Typography>Add Condition</Typography>
          </AddConditionButton>
        ) : (
          <Box className={classes.updateCondition}>
            <Typography
              onClick={() =>
                setCheckState(values, resetForm, setFieldValue, 'update')
              }
            >
              Update
            </Typography>
            <Typography onClick={handleUpdateDiscard}>Discard</Typography>
          </Box>
        )}
      </Box>

      {values.check && values.check.length > 0 && (
        <Box>
          <Typography mb='5px' sx={{ textDecoration: 'underline' }}>
            Conditions
          </Typography>

          <Box className={classes.conditionsWrapper}>
            {values.check.map((item: any, index: number) => {
              const para = getParameters(item);

              return (
                <Box
                  key={index}
                  className={classes.conditionsCont}
                  sx={{
                    borderTop: `${index === 0 ? '' : '1px solid #E6E6E6'}`,
                  }}
                  onClick={(e: any) =>
                    handleConditionClick(item, setFieldValue)
                  }
                >
                  <Typography className={classes.conditionText}>
                    {item.attribute} {item.condition} {para}
                  </Typography>

                  <AiFillMinusCircle
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConditionRemove(values, setFieldValue, item);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CheckConditions;
