import { Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import React from 'react';

interface CustomerOptionBoxProps {
  name: string;
  selectedOption: string;
  borderDir?: string;
  onClick: (x: string) => void;
}

const CustomerOptionBox: React.FC<CustomerOptionBoxProps> = ({
  name,
  selectedOption,
  borderDir = 'bottom',
  onClick,
}) => {
  const selected = name === selectedOption && true;
  const borderRight =
    borderDir === 'right'
      ? `2px solid ${
          selected ? kollectAITheme.primaryColor : 'rgba(44, 98, 236, 0.15)'
        }`
      : 0;
  const borderBottom =
    borderDir === 'bottom'
      ? `2px solid ${
          selected ? kollectAITheme.primaryColor : 'rgba(44, 98, 236, 0.15)'
        }`
      : 0;

  return (
    <button
      style={{
        padding: '6px 18px',
        borderRight,
        borderBottom,
      }}
      onClick={() => onClick(name)}
    >
      <Typography
        color={selected ? kollectAITheme.primaryColor : '#717B8C'}
        fontWeight={selected ? 600 : 500}
      >
        {name}
      </Typography>
    </button>
  );
};

export default CustomerOptionBox;
