const kollectAiUser = {
  userSummary: '/account/api/v1/roles_and_permissions/user_summary',
  user: '/account/api/v1/roles_and_permissions/add_user',
  verifyUserEmail: '/account/api/v1/roles_and_permissions/verify_user_email',
  newUserPassword:
    '/account/api/v1/roles_and_permissions/create_new_user_password',
  editUser: '/account/api/v1/roles_and_permissions/edit_user',
  resendUserEmail:
    '/account/api/v1/roles_and_permissions/resend_user_email_verification',
  exportUserDetails:
    '/account/api/v1/roles_and_permissions/export_user_details',
};

export default kollectAiUser;
