import { Box, Typography } from '@mui/material';
import BasicModal from 'components/Modal';

interface ViewDetailsModalProps {
  open: boolean;
  onClose: () => void;
  data: Record<string, any>;
}

const ViewDetailsModal: React.FC<ViewDetailsModalProps> = ({
  open,
  onClose,
  data,
}) => {
  return (
    <>
      <BasicModal
        open={open}
        onClose={onClose}
        children={
          <Box>
            {data &&
              Object.keys(data)?.map((key, index) => {
                return (
                  <Box
                    key={`view-data-${index}`}
                    sx={{ display: 'flex', gap: '2rem', mb: '1rem' }}
                  >
                    <Typography minWidth={120} fontWeight={600}>
                      {key}
                    </Typography>
                    <Typography maxWidth={300}>{data[key]}</Typography>
                  </Box>
                );
              })}
          </Box>
        }
      />
    </>
  );
};
export default ViewDetailsModal;
