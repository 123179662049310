import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikValues } from 'formik';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import useStyles from './index.styles';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  attributeCurrentValue,
  customerAttributeUpdate,
  getCustomerAllowedAttributes,
} from 'store/reducer/customerReducer/action';
import { selectedCustomerProduct } from 'store/reducer/customerReducer';

import {
  FormikCustomerTextarea,
  FormikCustomerInput,
  FormikCustomerSelect,
  FormikCustomerDatePicker,
} from 'components/formik/customer';
import Loader from 'components/loader';
import { createSelectOption } from 'utils/helper';
import { successToast } from 'utils/toast';

interface EditCustomerDataFormProps {
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  attributes: yup.array().of(
    yup.object().shape({
      attribute: yup.string().required('Required'),
      newValue: yup.string().required('Required'),
    })
  ),
});

const booleanOptions = [
  { label: 'True', value: 'True' },
  { label: 'False', value: 'False' },
];

const EditCustomerDataForm: React.FC<EditCustomerDataFormProps> = ({
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  // const currrentAttributes: any = useAppSelector(
  //   (state) => state.customer.customerCurrentAttribute
  // );
  const selectedProduct = useAppSelector(selectedCustomerProduct);
  const customerProfile = useAppSelector(
    (state) => state.customer.customerProfile
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [loanOptions, setLoanOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [attributeOptions, setAttributeOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [allowedAttributes, setAllowedAttributes] = useState<any[]>([]);
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [currentAttributeValue, setCurrentAttributeValue] = useState<any>({});

  const fetchAttribute = useCallback(async () => {
    const odfId = customerProfile.find(
      (el) => el?.product_suscribed === selectedProduct
    )?.odf_customer_id;
    if (odfId) {
      try {
        setLoading(true);
        const { data: currentValue } = await dispatch(
          attributeCurrentValue({ customerOdfId: odfId })
        ).unwrap();

        const { data } = await dispatch(
          getCustomerAllowedAttributes({
            action_type: 'update',
            module: 'cust_mgmt',
          })
        ).unwrap();
        if (data && data?.allowed_attribute && currentValue) {
          const allowedAttributeArr = data.allowed_attribute.map(
            (allowed: any) => allowed.attribute
          );
          const currentValueOptions: any[] = [];
          Object.keys(currentValue).forEach((current) => {
            if (allowedAttributeArr.includes(current))
              currentValueOptions.push({ label: current, value: current });
          });
          console.log({ currentValueOptions });
          setAttributeOptions(currentValueOptions);
          setCurrentAttributeValue(currentValue);
          setAllowedAttributes(data.allowed_attribute);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [customerProfile, dispatch, selectedProduct]);

  useEffect(() => {
    fetchAttribute();
  }, [fetchAttribute, customerProfile, selectedProduct]);

  useEffect(() => {
    const loanDetails = customerProfile.find(
      (el) => el?.product_suscribed === selectedProduct
    )?.loan_details;
    if (loanDetails && loanDetails.length) {
      const options = loanDetails.map((loan: any) => ({
        label: loan.loan_id,
        value: loan.loan_id,
      }));
      setLoanOptions(options);
    }
  }, [customerProfile, selectedProduct]);

  // useEffect(() => {
  //   if (Object.keys(currrentAttributes).length) {
  //     const options = currrentAttributes
  //       ? Object.keys(currrentAttributes).map((item) => ({
  //           label: item,
  //           value: item,
  //         }))
  //       : [];
  //     setAttributeOptions(options);
  //   }
  // }, [currrentAttributes, selectedProduct]);

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      setLoading(true);
      try {
        const attributes = values.attributes.map((item: any) => ({
          attribute: item?.attribute,
          value:
            item.type === 'Date'
              ? item?.newValue.toISOString()
              : item?.newValue,
          ...(item.loan_id && { loan_id: item.loan_id }),
        }));
        const payload = {
          odf_customer_id: customerOdfId,
          attributes,
          comments: values.comments,
          customer_id: id,
        };
        const { meta } = await dispatch(
          customerAttributeUpdate(payload)
        ).unwrap();
        if (meta?.success) {
          await dispatch(attributeCurrentValue({ customerOdfId }));
          successToast('Data has been updated successfully');
          onClose();
        }
      } finally {
        setLoading(false);
      }
    },
    [customerOdfId, dispatch, id, onClose]
  );

  const onAttributeChange = useCallback(
    (
      attr: string,
      setFieldValue: (field: string, value: any) => void,
      index: number
    ) => {
      if (loanOptions && loanOptions?.length === 1) {
        setFieldValue(
          `attributes.${index}.loan_id`,
          loanOptions?.[0]?.value || ''
        );
      }

      const selectedAttribute = allowedAttributes?.find(
        (el) => el.attribute === attr
      );
      if (selectedAttribute?.type) {
        if (selectedAttribute?.type === 'List') {
          const options = createSelectOption({
            arr: JSON.parse(selectedAttribute.allowed_values),
          });
          setListOptions(options);
        } else {
          setListOptions([]);
        }
        setFieldValue(`attributes.${index}.type`, selectedAttribute.type);
      } else {
        setFieldValue(`attributes.${index}.type`, '');
      }

      const value = currentAttributeValue[attr];
      setFieldValue(`attributes.${index}.currentValue`, value);
      setFieldValue(`attributes.${index}.newValue`, '');
    },
    [allowedAttributes, currentAttributeValue, loanOptions]
  );

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={{
          attributes: [
            {
              attribute: '',
              currentValue: '',
              newValue: '',
              loan_id: '',
              type: '',
            },
          ],
          comments: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }) => (
          <Form>
            <Typography mb='1rem'>
              Select Attribute(s) to update and assign value
            </Typography>

            <Box display='flex' alignItems='center' gap='1rem' mb='0.4rem'>
              <Typography align='center' width={200}>
                Attribute Name
              </Typography>
              <Typography align='center' width={200}>
                Current Value
              </Typography>
              <Typography align='center' width={200}>
                New Value
              </Typography>
              <Typography align='center' width={200}>
                Loan ID
              </Typography>
            </Box>
            <FieldArray
              name='attributes'
              render={(arrayHelpers) => (
                <>
                  {values.attributes &&
                    values.attributes.length > 0 &&
                    values.attributes.map((attribute, index) => (
                      <Box key={index}>
                        <Box className={classes.fieldArrayCont}>
                          <FormikCustomerSelect
                            name={`attributes.${index}.attribute`}
                            placeholder='Attribute'
                            options={attributeOptions}
                            handleChange={(attr: string) =>
                              onAttributeChange(attr, setFieldValue, index)
                            }
                          />

                          <Box className={classes.fieldArrayCurrentBox}>
                            <Typography>
                              {values.attributes[index].currentValue ||
                                (values.attributes[index].attribute && 'NA')}
                            </Typography>
                          </Box>

                          {values.attributes[index]?.type === '' && (
                            <FormikCustomerInput
                              name={`attributes.${index}.newValue`}
                              width={177}
                            />
                          )}

                          {values.attributes[index]?.type === 'Number' && (
                            <FormikCustomerInput
                              name={`attributes.${index}.newValue`}
                              type='number'
                              width={177}
                            />
                          )}

                          {values.attributes[index]?.type === 'Boolean' && (
                            <FormikCustomerSelect
                              name={`attributes.${index}.newValue`}
                              placeholder='Select'
                              options={booleanOptions}
                            />
                          )}

                          {values.attributes[index]?.type === 'Date' && (
                            <FormikCustomerDatePicker
                              name={`attributes.${index}.newValue`}
                            />
                          )}

                          {values.attributes[index]?.type === 'List' && (
                            <FormikCustomerSelect
                              name={`attributes.${index}.newValue`}
                              placeholder='Select'
                              options={listOptions}
                            />
                          )}

                          <FormikCustomerSelect
                            name={`attributes.${index}.loan_id`}
                            placeholder='Loan ID'
                            options={loanOptions}
                          />

                          <Box>
                            {values.attributes.length > 1 && (
                              <button
                                type='button'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <AiFillMinusCircle
                                  fontSize='1.5rem'
                                  color='#DAA09A'
                                />
                              </button>
                            )}

                            {index === values.attributes.length - 1 && (
                              <button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.insert(
                                    values.attributes.length + 1,
                                    {
                                      attribute: '',
                                      currentValue: '',
                                      newValue: '',
                                      type: '',
                                    }
                                  )
                                }
                              >
                                <AiFillPlusCircle
                                  fontSize='1.5rem'
                                  color='#6AC5AB'
                                />
                              </button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            />

            <FormikCustomerTextarea
              name='comments'
              label='Comments'
              labelDesc='Reason for change or generic notes'
            />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Update</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      />
    </>
  );
};

export default EditCustomerDataForm;
