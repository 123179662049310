import { Check, Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './index.styles';

const StrategySegmentTable = ({ group }: any) => {
  const navigate = useNavigate();

  const css = useStyles();

  const getBucketRange = (bucket: number) => {
    if (bucket === 1) return '1-30';
    if (bucket === 2) return '31-60';
    if (bucket === 3) return '61-90';
    if (bucket === 4) return '91-120';
    if (bucket === 5) return '>120';
    return 'All';
  };

  const checkStrategy = (value: string) => {
    if (!value || value === '0') return 'All';
    return value;
  };

  return (
    <table style={{}} className={css.strategyDetailsTable}>
      <thead>
        <tr>
          <td rowSpan={2} align='center' className={css.tableHeader}>
            S.No.
          </td>
          <td>
            <table className={css.nestedTable}>
              <tr>
                <td className={css.tableHeader}>Segments Covered</td>
              </tr>
              <tr>
                <table>
                  <td>Product</td>
                  <td>Balance</td>
                  <td>Bucket</td>
                </table>
              </tr>
            </table>
          </td>
          <td>
            <table className={css.nestedTable}>
              <tr>
                <td className={css.tableHeader}>Channel Touchpoints</td>
              </tr>
              <tr>
                <table>
                  <td>Email</td>
                  <td>SMS</td>
                  <td>Whatsapp</td>
                  <td>IVR</td>
                </table>
              </tr>
            </table>
          </td>
          <td rowSpan={2} align='center' className={css.tableHeader}>
            AB Testing Enabled
          </td>
        </tr>
      </thead>
      <tbody>
        {group?.strategy_detail.map((strategy: any, index: number) => (
          <tr key={strategy.strategy_id}>
            <td style={{ width: '10%', textAlign: 'center' }}>{index + 1}</td>
            <td style={{ width: '30%' }}>
              <table className={css.nestedTable}>
                <tr>
                  <td>{checkStrategy(strategy.product)}</td>
                  <td>{checkStrategy(strategy.balance_level)}</td>
                  <td>{getBucketRange(strategy.bucket)}</td>
                </tr>
              </table>
            </td>
            <td style={{ width: '30%' }}>
              <table className={css.nestedTable}>
                <tr>
                  <td
                    style={{
                      color: '#C00000',
                    }}
                  >
                    {strategy?.ab_testing_flag
                      ? `${strategy?.touchpoint?.email} | ${strategy?.touchpoint2?.email}`
                      : strategy?.touchpoint?.email}
                  </td>
                  <td
                    style={{
                      color: '#00BA34',
                    }}
                  >
                    {strategy?.ab_testing_flag
                      ? `${strategy?.touchpoint?.sms} | ${strategy?.touchpoint2?.sms}`
                      : strategy?.touchpoint?.sms}
                  </td>
                  <td
                    style={{
                      color: '#FFB600',
                    }}
                  >
                    {strategy?.ab_testing_flag
                      ? `${strategy?.touchpoint?.whatsapp} | ${strategy?.touchpoint2?.whatsapp}`
                      : strategy?.touchpoint?.whatsapp}
                  </td>
                  <td
                    style={{
                      color: '#2C62EC',
                    }}
                  >
                    {strategy?.ab_testing_flag
                      ? `${strategy?.touchpoint?.ivr} | ${strategy?.touchpoint2?.ivr}`
                      : strategy?.touchpoint?.ivr}
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ width: '10%' }}>
              <div className={css.abtestingColumn}>
                {strategy?.ab_testing_flag ? (
                  <Check
                    fontSize='large'
                    sx={{
                      color: '#00C087',
                    }}
                  />
                ) : (
                  <Close
                    fontSize='large'
                    sx={{
                      color: '#6F6F6F',
                    }}
                  />
                )}
              </div>
            </td>
            <td
              style={{ width: '10%' }}
              className={css.strategyViewColumn}
              onClick={() =>
                navigate(
                  `/strategy-details/${strategy.strategy_id}?group_id=${group.strategy_group_id}`,
                  {
                    state: {
                      groupName: group.strategy_group_name,
                      strategyName: strategy.strategy_name,
                      groupId: group.strategy_group_id,
                    },
                  }
                )
              }
            >
              <Typography
                fontSize={'0.87rem'}
                color='#00C087'
                fontFamily='Overpass'
                sx={{ textDecoration: 'underline' }}
              >
                View
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StrategySegmentTable;
