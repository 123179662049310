import AddWorkflowStep from 'components/workflow/addStep';
import GraphBox from 'components/workflow/graphBox';
import { parse } from 'jsonc-parser';
import { isArray } from 'lodash';
import { workflowData } from 'constants/workflowData';
import { allAllowedSegments } from 'utils/helper';
import { getGraphData } from 'utils/workflow/graphHandler';

import AddStartWorkflowStep from 'components/workflow/addStep/startBox';

const allowedSegments = allAllowedSegments(workflowData?.allowed_segment);

// const noDisplay = [
//   '1',
//   '2',
//   '3',
//   '4',
//   '5',
//   '1-10000',
//   '10000-30000',
//   '10001-30000',
//   '>30000',
//   'True',
//   'False',
//   'After_waiting',
//   'A',
//   'Broken_Promise',
//   'Call_Required',
//   'Not_Engaged',
//   'Bad_Contact',
// ];

const filterChild = ([_, v]: any[]) => {
  const isNull = v === null;
  const isArray = Array.isArray(v) && v.length;
  const isObject = v instanceof Object;

  return !isNull && (isArray || isObject);
};

function generateChildren(
  object: Object,
  isExpanded = true,
  nextId: () => string
) {
  const workflowGraphData = getGraphData();

  if (!(object instanceof Object)) object = [object];

  return Object.entries(object)
    .filter(filterChild)
    .flatMap(([key, v]: any[]) => {
      if (key === 'check') return [];
      if (key === 'updates') return [];
      if (key === 'trigger') return [];
      if (key === 'split') return [];
      if (key === 'waitTill') return [];

      if (workflowGraphData.published && Array.isArray(v)) {
        v = v.filter((item) => item.name);
      }

      const children: any = extract(v, isExpanded, nextId);
      if (key === 'hasChildren') return children;
      if (key === 'haschildren') return children;

      const child = isArray(v) ? v[0] : '';

      return [
        {
          id: nextId(),
          text: key,
          children,
          ...(child && { child }),
        },
      ];
    });
}

function generateNodeData(object: any) {
  if (object instanceof Object) {
    return object;
  }

  return String(object);
}

const extract = (
  os: string[] | object[] | null,
  isExpanded = true,
  nextId = (
    (id) => () =>
      String(++id)
  )(0)
) => {
  if (!os) return [];

  return [os].flat().map((o: any) => {
    const text = generateNodeData(o);
    return {
      id: nextId(),
      text,
      children: generateChildren(o, isExpanded, nextId),
    };
  });
};

const flatten: any = (xs: { id: string; children: never[] }[]) =>
  xs.flatMap(({ children, ...rest }) => [rest, ...flatten(children)]);

const relationships: any = (xs: { id: string; children: never[] }[]) => {
  return xs.flatMap(({ id: source, children = [] }) => [
    ...children.map(({ id: target }) => ({
      id: `e${source}-${target}`,
      source,
      target,
      type: 'smoothstep',
      // animated: true,
    })),
    ...relationships(children),
  ]);
};

export const parser = (jsonStr: string, isExpanded = true) => {
  try {
    let json = parse(jsonStr);
    if (!Array.isArray(json)) json = [json];
    const nodes: any = [];
    const edges: any = [];

    const mappedElements = extract(json, isExpanded);
    const res = [...flatten(mappedElements), ...relationships(mappedElements)];

    let noDisplay: string[] = [];
    res.forEach((data) => {
      if (isNode(data)) {
        const {
          text: { type, name },
          id,
        } = data;

        if (Object.values(data.text).every((item) => Array.isArray(item))) {
          noDisplay = noDisplay.concat(Object.keys(data.text));
        }

        const node: any = {
          id,
          data: {
            label:
              name === '' ? (
                <AddWorkflowStep {...data} />
              ) : type === 'START' ? (
                <AddStartWorkflowStep {...data} />
              ) : data.text instanceof Object &&
                (noDisplay.includes(Object.keys(data.text)[0]) ||
                  allowedSegments.includes(Object.keys(data.text)[0])) ? (
                ''
              ) : (
                <GraphBox
                  name={data.text instanceof Object ? name : data.text}
                  {...data}
                />
              ),
          },
          className:
            name === ''
              ? 'add'
              : type === 'START' || type === 'END'
              ? 'circle'
              : data.text instanceof Object &&
                // ('True' in data.text ||
                //   'After_waiting' in data.text ||
                //   'A' in data.text ||
                //   allowedSegments.includes(Object.keys(data.text)[0]))
                (noDisplay.includes(Object.keys(data.text)[0]) ||
                  allowedSegments.includes(Object.keys(data.text)[0]))
              ? 'decisioncircle'
              : type
              ? 'label'
              : 'text',
          style: {
            padding: 0,
            wordBreak: 'break-word',
            width:
              name === ''
                ? '220px'
                : type === 'START' || type === 'END'
                ? '60px'
                : data.text instanceof Object &&
                  (noDisplay.includes(Object.keys(data.text)[0]) ||
                    allowedSegments.includes(Object.keys(data.text)[0]))
                ? '3px'
                : type
                ? '220px'
                : '120px',
            textAlign: type ? 'start' : 'center',
          },
          position: null,
        };
        nodes.push(node);
      } else {
        edges.push(data);
      }
    });

    return { nodes, edges };
  } catch (error) {
    console.error(error);
    return {
      nodes: [],
      edges: [],
    };
  }
};

function isNode(element: any) {
  if ('text' in element) return true;
  return false;
}
