import * as React from 'react';
import { Box } from '@mui/material';

import Loader from 'components/loader';
import CampaignHeader from 'components/campaigns/listing/CampaignHeader';
import CampaignTable from 'components/campaigns/listing/CampaignHeader/CampaignTable';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCampaignSummary } from 'store/reducer/campaignReducer/action';
import BasicModal from 'components/Modal';
import CreateCampaignModal from 'components/campaigns/modals/CreateCampaign';
import {
  campaignSummaryData,
  resetCampaignCommStatus,
  resetCampaignPayload,
  setCampaignExecutionList,
  setCustomFilter,
} from 'store/reducer/campaignReducer';
import { setLocalCampaignData } from 'utils/campaign/campaignModifier';
import { CampaignFilter } from 'components/campaigns/listing';

export interface SummaryDataType {
  Completed: number;
  Draft: number;
  Todays: number;
  Scheduled: number;
}

const CampaignManagementScreen = () => {
  const dispatch = useAppDispatch();

  const campaigns = useAppSelector(campaignSummaryData);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [summaryData, setSummaryData] = React.useState<SummaryDataType>({
    Completed: 0,
    Draft: 0,
    Todays: 0,
    Scheduled: 0,
  });
  const [campaignData, setCampaignData] = React.useState<any[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<string[]>(
    []
  );
  const [campaignType, setCampaignType] = React.useState<string>('');
  const [openCreate, setOpenCreate] = React.useState<boolean>(false);
  const [totalPage, setTotalPage] = React.useState<number>(1);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [selectedFilter, setSelectedFilter] = React.useState({
    search_type: '',
    query: '',
  });
  const [isSearchData, setIsSearchData] = React.useState(false);

  React.useEffect(() => {
    fetchInitData();
    setSelectedCampaigns([]);
    dispatch(resetCampaignPayload());
    setLocalCampaignData({});
    dispatch(setCampaignExecutionList([]));
    dispatch(resetCampaignCommStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (campaigns) {
      const total = Math.ceil(campaigns.campaigns?.count / 10);
      setTotalPage(total);
      setSummaryData(campaigns?.summary);
      setCampaignData(campaigns?.campaigns?.results);
      setSelectedCampaigns([]);
    }
  }, [campaigns]);

  const fetchInitData = async () => {
    setLoading(true);
    try {
      await dispatch(getCampaignSummary({ limit: 10, offset: 0 })).unwrap();
      dispatch(setCustomFilter(null));
      setLoading(false);
    } finally {
      setLoading(false);
      setIsSearchData(false);
    }
  };

  const handleCreate = () => setOpenCreate(true);

  const handlePagination = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setLoading(true);
    const offset = 10 * (page - 1);
    let payload: any = { limit: 10, offset };
    if (campaignType) {
      payload.status_value = getStatusValue;
    }
    if (selectedFilter?.query) {
      payload.search_type = selectedFilter?.search_type;
      payload.query = selectedFilter?.query;
    }
    await dispatch(getCampaignSummary(payload)).unwrap();
    setCurrentPage(page);
    setLoading(false);
  };

  const handleSearch = async (filterParam: string, value: any) => {
    try {
      setLoading(true);
      if (value) {
        let payload: any = {
          limit: 10,
          offset: 0,
          search_type: filterParam,
          query: value,
        };
        await dispatch(getCampaignSummary(payload)).unwrap();
        setSelectedFilter({ search_type: filterParam, query: value });
      } else {
        await dispatch(getCampaignSummary({ limit: 10, offset: 0 })).unwrap();
        setSelectedFilter({ search_type: '', query: '' });
      }
      setCurrentPage(1);
    } finally {
      setLoading(false);
      setIsSearchData(true);
    }
  };

  const getStatusValue = React.useMemo(() => {
    let status_value = 'completed';
    if (campaignType === 'Upcoming Campaigns') status_value = 'upcoming';
    if (campaignType === 'Today’s Campaigns') status_value = 'todays';
    if (campaignType === 'Draft Campaigns') status_value = 'draft';
    return status_value;
  }, [campaignType]);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ margin: '2rem 2rem 0' }}>
        <CampaignHeader
          data={summaryData}
          campaignData={campaignData}
          selected={selectedCampaigns}
          campaignType={campaignType}
          handleCreate={handleCreate}
          setCampaignType={setCampaignType}
          setCurrentPage={setCurrentPage}
          selectedFilter={selectedFilter}
        />

        <CampaignFilter
          totalPage={totalPage}
          currentPage={currentPage}
          handlePagination={handlePagination}
          handleSearch={handleSearch}
        />

        <CampaignTable
          data={campaignData}
          selected={selectedCampaigns}
          setSelected={setSelectedCampaigns}
          handleCreate={handleCreate}
          isSearchData={isSearchData}
        />
      </Box>

      <BasicModal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        children={<CreateCampaignModal onClose={() => setOpenCreate(false)} />}
      />
    </>
  );
};

export default CampaignManagementScreen;
