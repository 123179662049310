import { Typography } from '@mui/material';

const LinkSent = ({ email }: { email: string }) => {
  return (
    <>
      <Typography
        fontWeight={600}
        color='rgba(50, 71, 92, 0.87)'
        fontSize='1.4rem'
      >
        Reset link has been sent ✉️
      </Typography>

      <Typography m='1rem auto 2.5rem' color='rgba(50, 71, 92, 0.6)'>
        Account activation link sent to your email address:{' '}
        <strong>{email}</strong>. Please follow the link inside to continue.
      </Typography>

      <Typography align='center' color='rgba(50, 71, 92, 0.6)'>
        Didn't get the mail?{' '}
        <span style={{ cursor: 'pointer', color: '#6AC5AB' }}>Resend</span>
      </Typography>
    </>
  );
};

export default LinkSent;
