import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    fieldArrayCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      marginBottom: '1rem',
    },
    fieldArrayCurrentBox: {
      background: '#E3E5E8',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      width: 200,
      height: 45,
      '& p': { height: 45, display: 'grid', placeItems: 'center' },
    },
  })
);

export default useStyles;
