import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1.8rem',
      gap: '12px',
    },
    categoryBox: {
      border: '1px solid #E6E6E6',
      width: '145px',
      gap: '0.8em',
      padding: '1rem 1.4rem 0.8rem',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
      borderRadius: '10px',
      cursor: 'pointer',
      '& p': { fontWeight: 500 },
    },
  })
);

export default useStyles;
