import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    channelSummaryHeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    channelBoxContainerWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, auto)',
      placeItems: 'center',
      columnGap: '1rem',
      marginRight: '2rem',
    },
  })
);

export default useStyles;
