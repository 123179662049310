import { Box, Typography } from '@mui/material';
import { BiPhoneCall, BiVideo } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { GoPrimitiveDot } from 'react-icons/go';

const CustomerChatHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        borderBottom: '1px solid #32475C1F',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Box position='relative'>
          <Box
            sx={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              backgroundColor: '#808080',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color='#fff' mt='5px'>
              C
            </Typography>
          </Box>
          <Box sx={{ position: 'absolute', right: -8, bottom: -8 }}>
            <GoPrimitiveDot color='#71DD37' size={20} />
          </Box>
        </Box>
        <Typography>Customer</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          '& svg': {
            color: '#32475C8A',
            fontSize: '1.5rem',
            cursor: 'pointer',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
          <BiPhoneCall />
          <BiVideo />
          <FiSearch />
        </Box>
        <HiOutlineDotsVertical />
      </Box>
    </Box>
  );
};
export default CustomerChatHeader;
