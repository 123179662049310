import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardManagerWrapper: {
      border: '0.5px solid #E6E6E6',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      padding: '1rem',
      height: '100%',
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#000',
    },
    dashboardImageContentCont: {
      padding: '1rem 2rem 0',

      '& img': {
        marginBottom: '1rem',
        width: '100%',
        height: '135px',
      },

      '& li': {
        fontSize: '1.2rem',
        color: theme.kollectAITheme.primaryColor,
      },
      '& p': { color: theme.kollectAITheme.primaryColor },
    },
  })
);

export default useStyles;
