import { Box, ListItem, ListItemText, Typography } from '@mui/material';

import CustomModal from 'components/Modal/CustomModal';

const WorkflowForceDelete = ({
  open,
  onClose,
  deleteData,
  handleForceDelete,
}: {
  open: boolean;
  onClose: () => void;
  deleteData: { strategy: string; usedList: string[] }[];
  handleForceDelete: () => void;
}) => {
  return (
    <CustomModal
      header='Confirm Delete'
      open={open}
      onClose={onClose}
      children={
        <Box>
          {deleteData?.map((data, ind) => {
            return (
              <Box mb='1rem' key={`${data?.strategy}-${ind}`}>
                <Typography>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: '1.2rem',
                      fontWeight: 600,
                      display: 'inline',
                    }}
                  >
                    {data?.strategy}
                  </Typography>{' '}
                  is used in following workflows:
                </Typography>
                {data?.usedList.map((item, index) => (
                  <ListItem sx={{ pt: 0, pb: 0 }} key={`${item}-${index}`}>
                    <ListItemText
                      primary={item}
                      sx={{ display: 'list-item' }}
                    />
                  </ListItem>
                ))}
              </Box>
            );
          })}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              marginTop: '2rem',
              '& button': {
                color: '#fff',
                padding: '8px 4rem',
                borderRadius: '8px',
              },
            }}
          >
            <button
              style={{ background: '#6AC5AB' }}
              onClick={handleForceDelete}
            >
              <Typography>Confirm</Typography>
            </button>
            <button style={{ background: '#DAA09A' }} onClick={onClose}>
              <Typography>Discard</Typography>
            </button>
          </Box>
        </Box>
      }
    />
  );
};

export default WorkflowForceDelete;
