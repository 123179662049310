import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { HiOutlineDownload } from 'react-icons/hi';
import { MdOutlineComputer } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import { Form, Formik, FormikValues } from 'formik';
import { ImMobile } from 'react-icons/im';
import * as yup from 'yup';
import useStyles, { HtmlPreviewButton } from './index.styles';

import { useAppDispatch } from 'hooks/useRedux';
import {
  createTemplate,
  fetchVariableResolverAttributes,
  updateTemplate,
  validateTemplate,
} from 'store/reducer/templateReducer/action';
import { getMappingState } from '..';
import EmailForm from './form';
import TemplateCreateHeader from '../createHeader';
import TempalateActionButtons from '../actionButtons';
import BasicModal from 'components/Modal';
import TestTemplateModal from 'containers/Templates/testTemplateModal';
// import jsPDF from "jspdf";

interface VariableResolverType {
  pii: string[];
  non_pii: string[];
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  subject: yup.string().required('Required'),
  message: yup.string().required('Required'),
});

const formikValues = { name: '', subject: '', message: '' };

const ChannelEmail = ({
  type,
  template,
  setLoading,
  openAttribute,
}: {
  type: string;
  template: any;
  setLoading: (x: boolean) => void;
  openAttribute: () => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<any>(formikValues);
  const [htmlView, sethtmlView] = useState<string>('preview');
  const [htmlDevice, setHtmlDevice] = useState<string>('desktop');
  const [variableResolver, setVariableResolver] =
    useState<VariableResolverType | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [openTest, setOpenTest] = useState<boolean>(false);
  const [isMapped, setIsMapped] = useState<boolean>(true);
  const contentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  useEffect(() => {
    if (type === 'update') {
      setInitialValues({
        name: template.template_name,
        message: template.template,
        subject: template.template_subject,
      });
    }
    if (type === 'duplicate') {
      setInitialValues({
        name: '',
        message: template.template,
        subject: '',
      });
    }
  }, [type, template]);

  const file_type = 'html';
  const verifyAttribute = async (values: any) => {
    const template = values.message;
    if (template) {
      setLoading(true);
      try {
        const { data, meta } = await dispatch(
          fetchVariableResolverAttributes({ template, file_type })
        ).unwrap();
        if (meta?.success) {
          const { variable_resolver } = data;
          setVariableResolver(variable_resolver);
          // const { mapped } = getMappingState(variable_resolver);
          // setIsMapped(mapped);
          const { data: validateData } = await dispatch(
            validateTemplate(variable_resolver as VariableResolverType)
          ).unwrap();
          const { mapped } = mappedState(validateData);
          setIsMapped(mapped);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const mappedState = (validateData: {
    pii?: string[];
    non_pii?: string[];
  }) => {
    let unmappedVariables: string[] = [];
    if (Object.keys(validateData)) {
      const piiArr = validateData.hasOwnProperty('pii')
        ? validateData['pii']
        : [];
      const non_piiArr = validateData.hasOwnProperty('non_pii')
        ? validateData['non_pii']
        : [];
      unmappedVariables = [...piiArr!, ...non_piiArr!];
    }
    return { mapped: unmappedVariables.length === 0, unmappedVariables };
  };

  const handleTest = async (values: FormikValues) => {
    const { name, message, subject } = values;
    let variable_resolver = JSON.stringify(variableResolver);
    if (!variableResolver) {
      const { data } = await dispatch(
        fetchVariableResolverAttributes({ template: message, file_type })
      ).unwrap();
      variable_resolver = JSON.stringify(data.variable_resolver);
    }
    const template = {
      template_name: name,
      template: message,
      two_way_flag: false,
      template_json: null,
      variable_resolver,
      template_subject: subject,
    };
    setSelectedTemplate(template);
    setOpenTest(true);
  };

  const handleTestClose = () => setOpenTest(false);

  const handlePDF = (values: any) => {
    // const { name, message } = values;
    handlePrint();
  };

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    const { name, message, subject } = values;
    const payload: any = {
      channel_type: 'email',
      // client_key: '1',
      template_name: name,
      template: message,
      bucket: 1,
      two_way_flag: false,
      template_subject: subject,
    };
    try {
      const { data, meta } = await dispatch(
        fetchVariableResolverAttributes({
          template: message,
          file_type: 'html',
        })
      ).unwrap();
      if (meta?.success) {
        const { variable_resolver } = data;
        const { data: validateData } = await dispatch(
          validateTemplate(variable_resolver as VariableResolverType)
        ).unwrap();
        const { mapped } = mappedState(validateData);
        // const { mapped } = getMappingState(variable_resolver);
        setIsMapped(mapped);
        if (mapped) {
          let res;
          if (type === 'create' || type === 'duplicate') {
            res = await dispatch(createTemplate(payload)).unwrap();
          }
          if (type === 'update') {
            payload.id = template.id;
            res = await dispatch(updateTemplate(payload)).unwrap();
          }
          if (res.meta.status === 'SUCCESS') {
            navigate('/template-management', {
              state: { channel: 'email', isTemplateCreatedUpdated: true },
            });
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, submitForm, setTouched }) => (
          <Form>
            <TemplateCreateHeader type={type} channel='email' />

            <Grid
              container
              spacing={4}
              mt='10px'
              sx={{ '& p': { fontFamily: 'Open Sans' } }}
            >
              <Grid item xs={6}>
                <EmailForm
                  type={type}
                  values={values}
                  isMapped={isMapped}
                  setFieldValue={setFieldValue}
                  openAttribute={openAttribute}
                />
              </Grid>

              <Grid item xs={6} pr='2rem'>
                <Box className={classes.previewHeader}>
                  <Box>
                    <button
                      type='button'
                      className={classes.pdfDowloadButton}
                      onClick={() => handlePDF(values)}
                    >
                      <HiOutlineDownload />
                      <Typography>PDF</Typography>
                    </button>{' '}
                  </Box>

                  <Box className={classes.htmlViewCont}>
                    {/* <HtmlPreviewButton
                      active={htmlView === 'editor' && true}
                      onClick={() => sethtmlView('editor')}
                    >
                      <Typography>Inline Editor</Typography>
                    </HtmlPreviewButton> */}

                    <HtmlPreviewButton
                      active={htmlView === 'preview' && true}
                      onClick={() => sethtmlView('preview')}
                    >
                      <Typography color='#fff'>Preview</Typography>
                    </HtmlPreviewButton>
                  </Box>

                  <Box className={classes.htmlDeviceCont}>
                    <button
                      type='button'
                      onClick={() => setHtmlDevice('desktop')}
                    >
                      <MdOutlineComputer
                        color={htmlDevice === 'desktop' ? '#00C087' : '#2E384D'}
                      />
                    </button>
                    <button
                      type='button'
                      onClick={() => setHtmlDevice('mobile')}
                    >
                      <ImMobile
                        color={htmlDevice === 'mobile' ? '#00C087' : '#2E384D'}
                      />
                    </button>
                  </Box>
                </Box>

                <Box
                  className={classes.previewCont}
                  sx={{
                    width: htmlDevice === 'mobile' ? '60%' : '100%',
                    margin: htmlDevice === 'mobile' ? '0 auto' : '0',
                  }}
                >
                  {values.message && (
                    <div
                      ref={contentRef}
                      dangerouslySetInnerHTML={{
                        __html: values.message,
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <TempalateActionButtons
              type={type}
              handleVerify={() => verifyAttribute(values)}
              handleSubmit={() => submitForm()}
              handleDiscard={() =>
                navigate('/template-management', {
                  state: { channel: 'email' },
                })
              }
              handleTest={() => {
                setTouched({ name: true, message: true, subject: true }, true);
                if (values.name && values.message) {
                  handleTest(values);
                }
              }}
            />
          </Form>
        )}
      </Formik>

      <BasicModal open={openTest} onClose={handleTestClose}>
        <TestTemplateModal
          selectedChannel='email'
          selectedTemplate={selectedTemplate}
          onClose={handleTestClose}
        />
      </BasicModal>
    </>
  );
};

export default ChannelEmail;
