import {
  IvrPreview,
  SmsPreview,
  WhatsappPreview,
} from 'containers/Templates/previews';
import IvrTwoWayPreview from 'containers/Templates/previews/twoWay/ivr';
import SmsTwoWayPreview from 'containers/Templates/previews/twoWay/sms';
import WhatsappTwoWayPreview from 'containers/Templates/previews/twoWay/whatsapp';

const SendCommunicationPreview = ({
  channel,
  template,
}: {
  channel: string;
  template: any;
}) => {
  if (!Object.keys(template).length) return <></>;

  const isTwoWay = template?.two_way_flag;
  const json =
    typeof template.template_json === 'object'
      ? JSON.stringify(template.template_json)
      : template.template_json;
  if (channel === 'sms') {
    if (isTwoWay) {
      return <SmsTwoWayPreview json={json} />;
    } else {
      return <SmsPreview isText={true} text={template.template} />;
    }
  }
  if (channel === 'whatsapp') {
    if (isTwoWay) {
      return <WhatsappTwoWayPreview json={json} />;
    } else {
      return <WhatsappPreview isText={true} text={template.template} />;
    }
  }
  if (channel === 'ivr') {
    if (isTwoWay) {
      return <IvrTwoWayPreview json={json} />;
    } else {
      return <IvrPreview isText={true} text={template.template} />;
    }
  }
  return <></>;
};

export default SendCommunicationPreview;
