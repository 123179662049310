import { Box, Typography } from '@mui/material';

interface CampaignBoxProps {
  color: string;
  name: string;
  value: number;
  selected: boolean;
  onClick: (x: string) => void;
}

const CampaignBox: React.FC<CampaignBoxProps> = ({
  color,
  name,
  value,
  selected,
  onClick,
}) => {
  return (
    <Box
      sx={{
        background: selected ? '#004357' : color,
        borderRadius: '16px',
        display: 'inline-flex',
        flexDirection: 'column',
        padding: '1.2rem 1.5rem',
        width: '180px',
        boxShadow:
          'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
        cursor: 'pointer',
        '& p': {
          color: selected ? '#fff' : '#1C1C1C',
          fontFamily: 'Inter',
          fontWeight: 600,
        },
      }}
      onClick={() => onClick(name)}
    >
      <Typography mb='2rem'>{name}</Typography>
      <Typography fontSize='1.5rem'>
        {value ? Number(value).toLocaleString() : 0}
      </Typography>
    </Box>
  );
};

export default CampaignBox;
