import { Box, Typography, styled } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikAuthInputProps {
  label: string;
  name: string;
  placeholder?: string;
}

const InputWrapper = styled('div')(() => ({
  '& input': {
    width: '93%',
    background: '#ECF2F7',
    border: 'transparent',
    height: '45px',
    borderRadius: '5px',
    padding: '0 1em',
    fontSize: '1em',
    outline: 'none',
    fontFamily: 'Inter',
  },
}));

const FormikAuthInput: React.FC<FormikAuthInputProps> = ({
  label,
  name,
  placeholder = '',
}) => {
  return (
    <Box mb='1rem'>
      <Typography mb='5px'>{label}</Typography>

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <InputWrapper>
            <input type='text' placeholder={placeholder} {...field} />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </InputWrapper>
        )}
      </Field>
    </Box>
  );
};

export default FormikAuthInput;
