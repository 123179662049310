import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { RiSendPlane2Fill } from 'react-icons/ri';

import useStyles from './index.styles';
import TypingAnimation from 'components/typingAnimation';
import { parseFormData } from 'utils/template/jsonParser';

interface SmsTwoWayPreviewProps {
  json?: any;
}

const SmsTwoWayPreview: React.FC<SmsTwoWayPreviewProps> = ({ json }) => {
  const classes = useStyles();
  const messageBoxRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState<string>('');
  const [chats, setChats] = useState<any>([{ message: '', response: '' }]);
  const [botTyping, setBotTyping] = useState<number>(0);
  const [responses, setResponses] = useState<any[]>([]);
  const [responseMeta, setResponseMeta] = useState<{
    type: string;
    expected: boolean;
  }>({ type: '', expected: false });

  useEffect(() => {
    if (typeof json === 'string') {
      const isJson = isJsonString(json);
      if (!isJson) return;
    }

    let obj;
    if (json) {
      obj = typeof json === 'string' ? JSON.parse(json).Message : json.Message;

      if (obj) {
        let msgFile;
        if (!Array.isArray(obj)) msgFile = [obj];

        const msgArr = parseFormData(msgFile);
        const msgs = msgArr
          .filter((el: any) => el.msg_id && typeof el.msg !== 'undefined')
          .sort((a: any, b: any) => a.msg_id - b.msg_id);
        const message = msgs[0];
        if (message && message.responseExpected) {
          setMessages(msgs);
          setTimeout(() => {
            const msg = createResponseOptions(message);
            setBotTyping(1);
            setChats([{ message: msg, response: '' }]);
            setResponses(message.responses);
            setResponseMeta({
              type: message.responseType,
              expected: message.responseExpected,
            });
          }, 1000);
        }
      }
    }
  }, [json]);

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleSend = () => {
    if (responseMeta.type === 'text') {
      const index = chats.length - 1;
      let chatCopy = chats.map((chat: any, ind: number) => {
        if (index === ind) return { ...chat, response: input };
        return { ...chat };
      });

      if (responses.length === 1) {
        const msgObj = messages[+responses[0].message];
        chatCopy[index + 1] = { message: msgObj.msg, response: '' };
        let resMeta = {
          type: msgObj.responseType,
          expected: msgObj.responseExpected,
        };
        if (msgObj?.defaultResponse?.message) {
          setResponses([msgObj?.defaultResponse]);
        } else {
          setResponses([]);
          resMeta = { type: '', expected: false };
        }
        setResponseMeta(resMeta);
        setChats(chatCopy);
        setTimeout(() => {
          setBotTyping(chats.length + 1);
        }, 1000);
      } else {
        setResponses([]);
        setChats(chatCopy);
        setResponseMeta({ type: '', expected: false });
      }
      setInput('');
    } else {
      const response = responses.find((resp: any) => resp.option === input);
      if (response) {
        const index = chats.length - 1;
        const msgObj = messages[+response.message];
        if (responseMeta.expected) {
          let chatCopy = chats.map((chat: any, ind: number) => {
            if (index === ind) return { ...chat, response: input };
            return { ...chat };
          });

          if (msgObj?.responseExpected) {
            if (msgObj.responseType === 'predefined') {
              chatCopy[index + 1] = {
                message: createResponseOptions(msgObj),
                response: '',
              };
              setResponses(msgObj.responses);
            } else {
              chatCopy[index + 1] = { message: msgObj.msg, response: '' };
              if (msgObj?.defaultResponse?.message) {
                setResponses([msgObj?.defaultResponse]);
              } else {
                setResponses([]);
              }
            }
            setResponseMeta({
              type: msgObj.responseType,
              expected: msgObj.responseExpected,
            });
          } else {
            chatCopy[index + 1] = { message: msgObj.msg, response: '' };
            setResponses([]);
            setResponseMeta({ type: '', expected: false });
          }
          setInput('');
          setChats(chatCopy);
          setTimeout(() => {
            setBotTyping(chats.length + 1);
          }, 1000);
        }
      }
    }
  };

  const createResponseOptions = (message: any) => {
    return (
      <div>
        <Typography>{message.msg}</Typography>
        {message.responseExpected &&
        message.responseType === 'predefined' &&
        message.responses.length ? (
          <>
            <Typography>Please send any of the below options:</Typography>
            <ul style={{ marginLeft: '1.5rem' }}>
              {message.responses.map((res: any, index: number) => (
                <li key={index}>
                  <Typography>{res.option}</Typography>
                </li>
              ))}
            </ul>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    const messageDiv = messageBoxRef?.current as HTMLDivElement;
    messageDiv.scrollTop = messageDiv.scrollHeight;
  }, [chats, botTyping]);

  return (
    <Box className={classes.smsTwoWayPreviewWrapper}>
      <Box className={classes.smsTwoWayPreviewCont}>
        <Typography align='center'>KollectAI Messenger</Typography>
      </Box>
      <Box ref={messageBoxRef} className={classes.smsTwoWayPreviewMessageCont}>
        {chats.map((chat: any, index: number) => {
          return (
            <div key={index}>
              <div className={classes.smsTwoWayPreviewChatMessageWrapper}>
                {botTyping === index ? (
                  <TypingAnimation />
                ) : (
                  <div className={classes.smsTwoWayPreviewChatMessageBox}>
                    {chat.message}
                  </div>
                )}
              </div>

              {chat.response && (
                <div className={classes.smsTwoWayPreviewChatResponseWrapper}>
                  <div className={classes.smsTwoWayPreviewChatMessageBox}>
                    {chat.response}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Box>

      <Box className={classes.smsTwoWayPreviewInputCont}>
        <input
          type='text'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder='Enter Message....'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <button type='button' onClick={handleSend}>
          <RiSendPlane2Fill />
        </button>
      </Box>
    </Box>
  );
};

export default SmsTwoWayPreview;
