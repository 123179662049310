import { Box, Modal, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../index.styles';

const ProceedButton = styled('button')(() => ({
  marginTop: '1rem',
  width: '95%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const OTPVerifiedModal = ({
  open,
  isPhoneVerified,
  onClose,
}: {
  open: boolean;
  isPhoneVerified: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEnforceFocus={false}
      className={classes.modalStyle}
    >
      <Box className={classes.modalBox}>
        <Box sx={{ width: 380, '& p': { fontFamily: 'Open sans' } }}>
          <Typography
            fontWeight={500}
            color='rgba(50, 71, 92, 0.87)'
            align='center'
            fontSize='1.4rem'
          >
            {isPhoneVerified ? 'Already' : 'Successfully'} Verified 💬
          </Typography>

          <Typography m='1rem auto 2rem' color='rgba(50, 71, 92, 0.6)'>
            {isPhoneVerified
              ? 'It seems you have already verified your phone number.'
              : 'Congratulations! Your phone number has been successfully verified.'}
          </Typography>

          <ProceedButton onClick={() => navigate('/login')}>
            <Typography>GO TO LOGIN</Typography>
          </ProceedButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default OTPVerifiedModal;
