import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerInfoCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      margin: '1rem auto',
    },
    productDetailsHeaderCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px 0',
      borderTop: '2px solid rgba(44, 98, 236, 0.15)',
      borderBottom: '2px solid rgba(44, 98, 236, 0.15)',
    },
    productDetailsWrapper: {
      display: 'grid',
      gridTemplateColumns: '190px 1fr',
      columnGap: '1rem',
      padding: '1rem 0',
    },
    productDetailsCont: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0 1rem',
    },
    customerStatusStyle: {
      color: '#24254A',
      background: 'rgba(255, 182, 0, 0.31)',
      borderRadius: '9px',
      padding: '4px 30px 2px',
    },
    smartkollectSubscribe: {
      color: '#00C087',
      textDecoration: 'underline',
    },
  })
);

export default useStyles;
