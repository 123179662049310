import * as React from 'react';
import { Box, Modal, styled, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import Loader from 'components/loader';
import { getSecondsRemaining, getUserCookie, setUserCookie } from 'utils/auth';
import { useAppDispatch } from 'hooks/useRedux';
import { refreshToken } from 'store/reducer/auth/actions';
import { handleLogOut } from 'store/reducer/auth';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '3rem 3rem 2rem 2.5rem',
  outline: 'none',
  fontFamily: 'Open sans',
  borderRadius: '5px',
};

const Button = styled('button')(({ bg }: { bg?: boolean }) => ({
  border: '1px solid #004357',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '6px',
  textTransform: 'uppercase',
  padding: '10px 1.8rem',
  fontFamily: 'Montserrat',
  background: bg ? kollectAITheme.primaryColor : '#fff',
  color: bg ? '#fff' : kollectAITheme.primaryColor,
  fontSize: '0.9rem',
}));

const SessionExpirationModal = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = React.useState(
    getSecondsRemaining()
  );

  React.useEffect(() => {
    const secRem = getSecondsRemaining();
    if (secRem < 121 && secRem > 0) setShowModal(true);
    // if (secRem < 21 && secRem > 0) setShowModal(true);
  }, []);

  React.useEffect(() => {
    let allSecondsRemaining = setInterval(() => {
      setSecondsRemaining(getSecondsRemaining());

      if (secondsRemaining === 121) setShowModal(true);
      // if (secondsRemaining === 21) setShowModal(true);

      if (secondsRemaining <= 1) {
        setShowModal(false);
        dispatch(handleLogOut());
        clearInterval(allSecondsRemaining);
      }
    }, 1000);

    return () => {
      clearInterval(allSecondsRemaining);
    };
  }, [dispatch, secondsRemaining]);

  const handleContinueSession = async () => {
    setLoading(true);
    try {
      const userCookie = getUserCookie();
      const { data, meta } = await dispatch(
        refreshToken({ refresh: userCookie?.refresh_token })
      ).unwrap();
      if (meta?.success && data?.access) {
        const userData = {
          ...userCookie,
          access_token: data.access,
          // loginTime: new Date(),
          access_token_expiry_time: data?.access_token_expiry_time,
          access_token_generate_time: data?.access_token_generate_time,
        };
        setUserCookie(userData);
        setShowModal(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal open={showModal}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'start', gap: '2rem' }}>
            <Box fontSize='2rem'>⚠️</Box>
            <Box
              sx={{
                '& p': { fontSize: '1.1rem', color: 'rgba(50, 71, 92, 0.6)' },
              }}
            >
              <Typography mb='10px'>Your session is about to expire</Typography>
              <Typography>
                You will be logged out in {secondsRemaining} seconds
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: '3rem',
            }}
          >
            <Button onClick={() => dispatch(handleLogOut())}>log out</Button>
            <Button bg onClick={handleContinueSession}>
              continue session
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SessionExpirationModal;
