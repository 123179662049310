import api from '../api';
import kollecAiIntegration from './config';

const integrationApi = {
  async generateApiKey(payload: any) {
    return api.post(kollecAiIntegration.generateApiKey, payload);
  },
  async uploadDataApi(payload: any) {
    return api.post(kollecAiIntegration.uploadDataApi, payload);
  },
  async integrationSampleFile(payload: any) {
    const url = `${kollecAiIntegration.integrationSampleFile}?file_type=${payload.fileType}`;
    return api.get(url);
  },
};

export default integrationApi;
