import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const ActionButton = styled('div')(
  ({
    background,
    radius = '8px',
  }: {
    background: string;
    radius?: string;
  }) => ({
    background,
    padding: '6px 1.5rem',
    color: '#fff',
    borderRadius: radius,
    cursor: 'pointer',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campaignHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& button': {
        background: '#004357',
        color: '#fff',
        padding: '0.8rem 1rem 0.6rem',
        borderRadius: '3px',
      },
    },
    campaignFormCont: {
      display: 'grid',
      gridTemplateColumns: '500px 1fr',
      alignItems: 'center',
      gap: '2rem',
      paddingRight: '22px',
    },
    actionCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#FFFFFF',
      border: '3px solid #EAEAEA',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '1.2rem 2rem 1rem',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    modalStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export default useStyles;
