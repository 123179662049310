import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ivrPreviewCont: {
      height: (props: any) => (props.notModal ? 'calc(100vh - 270px)' : '100%'),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    ivrPreviewMessageCont: {
      width: '80%',
      padding: '0.4rem',
      background: '#FFFFFF',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      minHeight: '40px',
    },

    ivrPreviewPlayerCont: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      border: '1px solid rgba(150, 150, 150, 0.34)',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '47px',
      padding: '0.5rem 1.5rem 0.5rem 1rem',
      background: '#fff',
    },
    ivrPreviewWaveBox: {
      height: '2rem',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
    },
  })
);

export default useStyles;
