import { Box, Typography } from '@mui/material';

import BasicModal from 'components/Modal';

const HardshipDetailsModal = ({
  open,
  onClose,
  viewData,
}: {
  open: boolean;
  onClose: () => void;
  viewData: any;
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      children={
        <Box
          sx={{
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
          }}
        >
          {viewData.length ? (
            viewData.map((qa: any) => {
              return (
                <Box
                  key={`view-data-${qa.hq_id}`}
                  sx={{
                    border: '1px solid #d3d3d3',
                    borderRadius: '10px',
                    mb: viewData.length > 1 ? '1rem' : 0,
                  }}
                >
                  <Typography
                    sx={{
                      padding: '5px 1rem',
                      borderBottom: '1px solid #d3d3d3',
                      fontWeight: 700,
                    }}
                  >
                    Questionaire ID: {qa.hq_id}
                  </Typography>
                  <Box mt='10px'>
                    {Object.entries(qa.hardship_response).map(
                      (item: any, ind: number) => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '2rem',
                              mb: '10px',
                              padding: '0 1rem',
                              justifyContent: 'space-between',
                            }}
                            key={ind}
                          >
                            <Typography>{item[0]}</Typography>
                            <Typography>{item[1]}</Typography>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography>No Hardship Data Found</Typography>
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default HardshipDetailsModal;
