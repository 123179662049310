import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setVerifyEmailData } from 'store/reducer/auth';
import { duplicatePhoneCheck } from 'store/reducer/auth/actions';

const InputWrapper = styled('div')(() => ({
  margin: '1.5rem auto 3rem',
}));

const UpdateButton = styled('button')(() => ({
  width: '100%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const UpdateNumber = ({
  setAction,
  setLoading,
}: {
  setAction: (x: string) => void;
  setLoading: (x: boolean) => void;
}) => {
  const dispatch = useAppDispatch();

  const verifyEmailData = useAppSelector((state) => state.auth.verifyEmailData);

  const [fullNumber, setFullNumber] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<string>('');
  const [validation, setValidation] = React.useState<string>('');

  const handleUpdate = async () => {
    if (!phone) {
      setValidation('Required');
    } else {
      setLoading(true);
      try {
        const { meta } = await dispatch(
          duplicatePhoneCheck({ phone, phone_country_code: countryCode })
        ).unwrap();
        if (meta?.success) {
          dispatch(
            setVerifyEmailData({
              ...verifyEmailData,
              phone,
              phone_country_code: countryCode,
            })
          );
          setAction('verify');
        }
      } catch (error) {}
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography fontSize='1.6rem' fontWeight={500}>
        Update your number
      </Typography>

      <Typography color='rgba(50, 71, 92, 0.6)'>
        Please provide the SMS enabled contact number for OTP verification.
      </Typography>

      <InputWrapper>
        {/* <input
          type='text'
          placeholder='Enter your number '
          value={phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e.target.value)
          }
        /> */}
        <PhoneInput
          country={'in'}
          value={fullNumber}
          countryCodeEditable={false}
          onChange={(e, country: any) => {
            const phoneNumber = e.replace(country?.dialCode, '');
            setCountryCode(country.dialCode);
            setPhone(phoneNumber);
            setFullNumber(e);
            setValidation('');
          }}
        />
        {validation && (
          <Typography fontSize='0.8rem' color='#d32f2f'>
            {validation}
          </Typography>
        )}
      </InputWrapper>

      <UpdateButton onClick={handleUpdate}>
        <Typography>Update</Typography>
      </UpdateButton>
    </Box>
  );
};

export default UpdateNumber;
