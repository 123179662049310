import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getAllRole, getPermissionList } from './action';
import { generateRoleColorPalette } from 'utils/helper';
import { RoleType } from 'constants/types';

type IntialType = {
  permissionList: { permission_id: string; permission_name: string }[];
  roles: RoleType[];
  rolePalette: any;
};

const initialState: IntialType = {
  permissionList: [],
  roles: [],
  rolePalette: null,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPermissionList.fulfilled, (state, { payload }) => {
      state.permissionList = payload?.data;
    });
    builder.addCase(getAllRole.fulfilled, (state, { payload }) => {
      if (payload?.data) {
        const rolePalette = generateRoleColorPalette(payload.data);
        state.roles = payload?.data;
        state.rolePalette = rolePalette;
      }
    });
  },
});

export default roleSlice.reducer;

export const permissionList = (state: RootState) => state.role.permissionList;
export const roleList = (state: RootState) => state.role.roles;
export const rolePalette = (state: RootState) => state.role.rolePalette;
