import { Box, Typography } from '@mui/material';
import underConstructionImg from 'assets/under_construction.svg';

const ComingSoonPage = () => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
      height='80%'
    >
      <img src={underConstructionImg} alt='' height='400px' width='450px' />
      <Typography fontSize='1.5=2rem' color='#004357'>
        Coming soon......
      </Typography>
    </Box>
  );
};

export default ComingSoonPage;
