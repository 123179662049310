import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { SlArrowDown } from 'react-icons/sl';
import { setCampaignPayload } from 'store/reducer/campaignReducer';
import { updateBasedOn } from 'store/reducer/campaignReducer/action';

const options = [
  { label: 'Product Type', value: 'product_type' },
  { label: 'DPD Bucket', value: 'dpd_bucket' },
  { label: 'Balance Level', value: 'balance_bucket' },
  { label: 'Customer Status', value: 'customer_status' },
];

const SelectSegment = ({
  segments,
  selectedSegment,
  setSelectedSegment,
}: {
  segments: any;
  selectedSegment: string;
  setSelectedSegment: (x: string) => void;
}) => {
  const dispatch = useAppDispatch();

  const campaignData: any = useAppSelector(
    (state: any) => state.campaign.campaign
  );

  return (
    <Select
      displayEmpty
      value={selectedSegment}
      onChange={async (event: SelectChangeEvent) => {
        const { value } = event.target;
        const data = segments;
        let segData = {};
        Object.keys(data).forEach((key) => {
          if (key === value) segData = { [key]: data[key] };
        });
        dispatch(
          setCampaignPayload({
            key: 'add_filter',
            data: { filter: 'split', value: segData },
          })
        );
        dispatch(
          updateBasedOn({
            campaign_id: campaignData?.campaign_id,
            based_on_group_type: value,
          })
        );
        setSelectedSegment(value);
      }}
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{
        position: 'relative',
        borderRadius: 0,
        minWidth: '140px',
        textAlign: 'center',
        background: '#fff',
        '.MuiSelect-select': {
          padding: '12px 10px 10px 14px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      IconComponent={() => (
        <SlArrowDown
          style={{
            fontSize: '12px',
            marginRight: '0.8rem',
            position: 'absolute',
            right: 0,
          }}
        />
      )}
    >
      {options?.map((type: any, index: number) => (
        <MenuItem value={type.value} key={index}>
          {type?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectSegment;
