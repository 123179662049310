import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smsTwoWayPreviewWrapper: {
      height: 'calc(100vh - 200px)',
      margin: '0 auto',
      width: '70%',
    },
    smsTwoWayPreviewCont: {
      color: '#fff',
      padding: '1rem 0',
      background: '#7C9BA5 !important',
      borderRadius: '10px 10px 0px 0px',
    },
    smsTwoWayPreviewMessageCont: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 325px)',
      padding: '5px 20px 16px',
      background: '#fff',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    smsTwoWayPreviewChatMessageWrapper: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'start',
    },
    smsTwoWayPreviewChatMessageBox: {
      padding: '5px',
      display: 'inline',
      background: '#E5E5E5',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
      borderRadius: '5px 5px 5px 0px',
    },
    smsTwoWayPreviewChatResponseWrapper: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'end',
    },

    smsTwoWayPreviewInputCont: {
      display: 'flex',
      padding: '1rem',
      gap: '1rem',
      background: '#fff',
      borderRadius: '0 0 10px 10px',

      '& input': {
        width: '95%',
        background: 'rgba(0, 67, 87, 0.09)',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        border: 'transparent',
        outline: 'none',
        padding: '10px',
        fontFamily: 'inherit',
      },
      '& button': {
        background: theme.kollectAITheme.primaryColor,
        borderRadius: '5px',
        padding: '0 0.8rem',
      },
      '& svg': {
        fontSize: '1.2rem',
        color: '#fff',
      },
    },
  })
);

export default useStyles;
