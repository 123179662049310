import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '0.8em',
      marginTop: '3px',
    },
    radio: {
      '&$checked': {
        color: '#3552CC',
      },
    },
    checked: {},
    radioGroupContainer: {
      padding: '3px 10px',
      borderRadius: '11px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',

      '& .MuiSvgIcon-root': {
        width: '0.9em',
        height: '0.9em',
      },
    },
    howCreateWorkflowLinkStyle: {
      position: 'absolute',
      top: '5px',
      right: '16px',
      color: theme.kollectAITheme.primaryColor,
    },
    createWorkflowWrapper: {
      filter: 'drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2))',
      width: '250px',
      height: '75px',
      padding: '1.2rem 1rem 0.8rem',
      borderRadius: '10px',
      cursor: 'pointer',
      background: theme.kollectAITheme.primaryColor,
      display: 'flex',
      justifyContent: 'center',
      color: '#fff',
    },
    workflowCreateBoxStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
    },
  })
);

export default useStyles;
