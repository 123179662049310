import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const AvailableAttributeTable = ({ data }: { data: any }) => {
  return (
    <TableContainer
      sx={{
        marginTop: '1rem',
        height: 'calc(100vh - 350px)',
        overflow: 'auto',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Table
        sx={{
          width: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: '1px solid #6F6F6F',
          },
        }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight={600}>attributeName</Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography fontWeight={600}>attributeType</Typography>
            </TableCell>
            <TableCell align='center' width={400}>
              <Typography fontWeight={600}>Description</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell>
                <Typography>{row.attribute}</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>{row.attribute_type}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.description}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AvailableAttributeTable;
