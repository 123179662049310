// import { styled } from '@material-ui/core';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
// import Checkbox from "components/checkbox";
import { useAppSelector } from 'hooks/useRedux';
import React, { useEffect, useState } from 'react';
// import { arrayCompare } from 'utils/helper';
import useStyles from './index.styles';
import './index.css';

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import ComingSoonPage from 'components/comingSoon';

const SegmentList = (props: any) => {
  const { filterData, formik, filterList } = props;
  const classes = useStyles();

  const { groupFilterList } = useAppSelector(
    (state) => state.uploadedFileDetail
  );

  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    values,
    setFieldValue,
  }: {
    values: any;
    handleChange: any;
    errors: any;
    touched: any;
    handleBlur: any;
    handleSubmit: any;
    setFieldValue: any;
  } = formik;

  useEffect(() => {
    if (values?.based_on === 'dpd') {
      if (filterData === 'DPD') {
        setDisabled(false);
        const segmentObj = filterList?.['DPD'];
        const segments = Object.values(segmentObj);
        setFieldValue('segment', { dpd: segments, product: [], amount: [] });
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
      setFieldValue('segment', { product: [], dpd: [], amount: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.based_on]);

  const handleSegmentChange = (filterType: string, filterValue: string) => {
    let product: string[] = values.segment?.product || [];
    let dpd: string[] = values.segment?.dpd || [];
    let amount: string[] = values.segment?.amount || [];

    if (filterType === 'PRODUCT') {
      if (!product.includes(filterValue)) {
        product.push(filterValue);
      } else {
        product.splice(product.indexOf(filterValue), 1);
      }
      setFieldValue('segment', { ...values.segment, product });
    }
    if (filterType === 'DPD') {
      if (!dpd.includes(filterValue)) {
        dpd.push(filterValue);
      } else {
        dpd.splice(dpd.indexOf(filterValue), 1);
      }
      setFieldValue('segment', { ...values.segment, dpd });
    }
    if (filterType === 'AMOUNT') {
      if (!amount.includes(filterValue)) {
        amount.push(filterValue);
      } else {
        amount.splice(amount.indexOf(filterValue), 1);
      }
      setFieldValue('segment', { ...values.segment, amount });
    }
  };
  const handleSelectAllClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    filterType: string
  ) => {
    setDisabled(!disabled);
    if (e.target.checked) {
      const segmentObj = filterList?.[filterData];
      if (segmentObj) {
        const segments = Object.values(segmentObj);
        setFieldValue('segment', {
          ...values.segment,
          [filterType.toLowerCase()]: segments,
        });
      }
    } else {
      setFieldValue('segment', {
        ...values.segment,
        [filterType.toLowerCase()]: [],
      });
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={!disabled}
              onChange={(e) => handleSelectAllClick(e, filterData)}
              className={classes.switch}
              size='medium'
            />
          }
          label={
            <Typography
              fontFamily='Overpass'
              fontSize='1rem'
              lineHeight='20px'
              color='#A8ABC3'
              sx={{ marginLeft: 2 }}
            >
              {filterData}
            </Typography>
          }
        />
      </FormGroup>

      {groupFilterList[filterData] &&
        Object.values(groupFilterList[filterData]).map(
          (eachFilterValue: any) => {
            return (
              <div
                key={`filter-item-${eachFilterValue}`}
                style={{
                  pointerEvents: disabled ? 'none' : 'auto',
                  opacity: disabled ? 0.5 : 1,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <FormControlLabel
                  label={
                    filterData === 'DPD'
                      ? '[' + eachFilterValue + ']'
                      : eachFilterValue
                  }
                  control={
                    <Checkbox
                      checked={values?.segment[
                        filterData.toLowerCase()
                      ]?.includes(eachFilterValue)}
                      onChange={() =>
                        handleSegmentChange(filterData, eachFilterValue)
                      }
                      checkedIcon={
                        <CheckBoxOutlinedIcon
                          style={{
                            color: '#004357',
                            width: '30px',
                            height: '30px',
                          }}
                        />
                      }
                      icon={
                        <CheckBoxOutlineBlankIcon
                          style={{
                            color: '#004357',
                            width: '30px',
                            height: '30px',
                          }}
                        />
                      }
                    />
                  }
                />
              </div>
            );
          }
        )}
    </div>
  );
};

export default SegmentList;
