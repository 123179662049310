import api from '../api';
import kollectAiCustomerManagement from './config';

const customerManagementApi = {
  async getCustomerStats() {
    return api.get(kollectAiCustomerManagement.customerStats);
  },
  async getCustomerDetails(payload: { category: string; page: number }) {
    const { category, page } = payload;
    let url = `${kollectAiCustomerManagement.customerDetails}/?category=${category}&page=${page}`;
    return api.get(url);
  },
  async getCustomerProfile({ customerId }: { customerId: string }) {
    let url = `${kollectAiCustomerManagement.customerProfile}/?customer_id=${customerId}`;
    return api.get(url);
  },
  async getCustomerActivity({ customerId }: { customerId: string }) {
    let url = `${kollectAiCustomerManagement.customerActivity}/?customer_id=${customerId}`;
    return api.get(url);
  },
  async getCustomerRequests({ customerId }: { customerId: string }) {
    let url = `${kollectAiCustomerManagement.customerRequests}/?customer_id=${customerId}`;
    return api.get(url);
  },
  async getCustomerActions({ customerId }: { customerId: string }) {
    let url = `${kollectAiCustomerManagement.customerActions}/?customer_id=${customerId}`;
    return api.get(url);
  },
  async getCustomerPreferences({
    customerId,
    odf_customer_id,
  }: {
    customerId: string;
    odf_customer_id: string;
  }) {
    let url = `${kollectAiCustomerManagement.customerPreferences}/?customer_id=${customerId}&odf_customer_id=${odf_customer_id}`;
    return api.get(url);
  },
  async updateCustomerPreferences(payload: {
    customer_id: string;
    odf_customer_id: string;
    emai: boolean;
    sms: boolean;
    ivr: boolean;
    whatsapp: boolean;
  }) {
    return api.post(
      `${kollectAiCustomerManagement.customerPreferences}/`,
      payload
    );
  },
  async postCustomerRequests(payload: {
    req_id?: string;
    // client_id: string;
    odf_customer_id: string;
    customer_id: string;
    priority: string;
    category: string;
    issue_title: string;
    issue_description: string;
  }) {
    let url = `${kollectAiCustomerManagement.customerRequests}/`;
    return api.post(url, payload);
  },
  async postCustomerStatusUpdate(payload: any) {
    let url = `${kollectAiCustomerManagement.customerStatusUpdate}/`;
    return api.patch(url, payload);
  },
  async getCustomerQueue({ customerId }: { customerId: string }) {
    let url = `${kollectAiCustomerManagement.customerQueue}?customer_id=${customerId}`;
    return api.get(url);
  },
  async customerQueueUpdate(payload: any) {
    return api.patch(kollectAiCustomerManagement.customerQueue, payload);
  },
  async customerAgentUpdate(payload: any) {
    return api.post(kollectAiCustomerManagement.customerAgentUpdate, payload);
  },
  async postCustomerCommuncationUpdate(payload: any) {
    let url = `${kollectAiCustomerManagement.customerCommunicationUpdate}/`;
    return api.post(url, payload);
  },
  async postCustomerDelete(payload: any) {
    let url = `${kollectAiCustomerManagement.customerDelete}/`;
    return api.post(url, payload);
  },
  async postCustomerNotes(payload: any) {
    let url = `${kollectAiCustomerManagement.customerNotes}`;
    return api.post(url, payload);
  },
  async customerWorkableUpdate(payload: {
    odf_customer_id: string;
    workable_flag: boolean;
  }) {
    let url = `${kollectAiCustomerManagement.customerWorkableUpdate}`;
    return api.patch(url, payload);
  },
  async getCustomerById(payload: { customerId: string }) {
    return api.get(
      `${kollectAiCustomerManagement.getCustomerById}?customer_id=${payload.customerId}`
    );
  },
  async attributeCurrentValue({ customerOdfId }: { customerOdfId: string }) {
    const url = `${kollectAiCustomerManagement.customerAttribute}?odf_customer_id=${customerOdfId}`;
    return api.get(url);
  },
  async customerAttributeUpdate(payload: {
    odf_customer_id: string;
    attributes: any[];
  }) {
    let url = `${kollectAiCustomerManagement.customerAttribute}`;
    return api.patch(url, payload);
  },

  async customerProfileFilter(payload: {
    customer_id: string;
    workflow_date?: string;
    communication_date?: string;
    communication_mode?: string;
  }) {
    const { customer_id, ...rest } = payload;
    let url = `${kollectAiCustomerManagement.profileFilter}/?customer_id=${customer_id}`;
    return api.post(url, rest);
  },

  async getCustomerActivityDetails(payload: {
    customer_id: string;
    id: string;
    message: string;
    step_name?: string;
  }) {
    const { customer_id, ...rest } = payload;
    let url = `${kollectAiCustomerManagement.customerActivityDetails}/?customer_id=${customer_id}`;
    return api.post(url, rest);
  },

  async customerActivityFilter(payload: {
    customer_id: string;
    activity_filter_type: string;
    date: string;
  }) {
    const { customer_id, ...rest } = payload;
    let url = `${kollectAiCustomerManagement.customerActivityFilter}/?customer_id=${customer_id}`;
    return api.post(url, rest);
  },

  async getCustomerPaymentDetails(payload: { odf_customer_id: string }) {
    let url = `${kollectAiCustomerManagement.customerPaymentDetails}/?odf_customer_id=${payload.odf_customer_id}`;
    return api.get(url);
  },

  async getCustomerAgentsList(payload: { customer_id: string }) {
    let url = `${kollectAiCustomerManagement.customerAgentsList}?customer_id=${payload.customer_id}`;
    return api.get(url);
  },

  async customerRequestFilter(payload: {
    customer_id: string;
    category: string;
    priority: string;
    date: string;
  }) {
    // const { customer_id, category, priority, date } = payload;
    const { customer_id, ...rest } = payload;

    let url = `${kollectAiCustomerManagement.customerRequestFilter}/?customer_id=${customer_id}`;
    if (Object.keys(rest).length) {
      for (let key in rest) {
        // @ts-ignore
        url = url + `&${key}=${rest[key]}`;
      }
    }
    return api.post(url);
  },
  async getHardshipDetails(payload: { customer_id: string }) {
    return api.get(kollectAiCustomerManagement.hardshipDetails, {
      params: payload,
    });
  },

  async getAgentActionLists(payload: { customer_id: string }) {
    return api.get(
      `${kollectAiCustomerManagement.agentActionLists}/?customer_id=${payload.customer_id}`
    );
  },

  async getCallRequestAttempts() {
    return api.get(kollectAiCustomerManagement.callRequestAttemts);
  },

  async getCustomerAllowedAttributes(payload: {
    action_type: string;
    module: string;
  }) {
    return api.get(kollectAiCustomerManagement.customerAllowedAttributes, {
      params: payload,
    });
  },

  async getCustomerNotes(payload: {
    odf_customer_id: string;
    customer_id: string;
  }) {
    return api.get(kollectAiCustomerManagement.allCustomerNotes, {
      params: payload,
    });
  },
};

export default customerManagementApi;
