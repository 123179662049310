import { Box, Typography } from '@mui/material';
import { FormikCustomerDatePicker } from 'components/formik/customer';

const PTP = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
      }}
    >
      <Typography>PTP Commitment Date</Typography>
      <FormikCustomerDatePicker
        name='ptp_commitment_date'
        minDate={new Date()}
      />
    </Box>
  );
};

export default PTP;
