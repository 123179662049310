import api from 'apis/api';
import kollectAiRoles from './config';

const roleApi = {
  async getPermissionList(payload: { search_query?: string } | void) {
    return api.get(kollectAiRoles.permissionList, { params: payload });
  },

  async getRole() {
    return api.get(kollectAiRoles.roles);
  },

  async createRole(payload: { role_name: string; perms_to_add: string[] }) {
    return api.post(kollectAiRoles.roles, payload);
  },

  async updateRole(payload: {
    role_id: string;
    perms_to_add?: string[];
    perms_to_del?: string[];
    deactivate: boolean;
  }) {
    return api.patch(kollectAiRoles.roles, payload);
  },

  async deleteRole(payload: { role_id: string }) {
    return api.delete(kollectAiRoles.roles, { data: payload });
  },
};

export default roleApi;
