import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../../index.styles';

const SplitIntoSegments = ({
  segments,
  selectedSegment,
}: {
  segments: any;
  selectedSegment: string;
}) => {
  const classes = useStyles();

  const [segmentData, setSegmentData] = React.useState<any>([]);

  React.useEffect(() => {
    const data = segments[selectedSegment];
    let segData = [];
    for (let key in data) {
      segData.push(data[key]);
    }
    setSegmentData(segData);
  }, [segments, selectedSegment]);

  const getHeader = () => {
    let value = '';
    if (selectedSegment === 'dpd_bucket') value = 'DPD Bucket';
    if (selectedSegment === 'product_type') value = 'Product Type';
    if (selectedSegment === 'customer_status') value = 'Customer Status';
    if (selectedSegment === 'balance_bucket') value = 'Balance Bucket';
    return value;
  };

  // const getData = (item: any) => {
  //   let value = '';
  //   if (selectedSegment === 'dpd_bucket') value = item['dpd_bucket'];
  //   if (selectedSegment === 'product_type') value = item['product_type'];
  //   if (selectedSegment === 'customer_status') value = item['customer_status'];
  //   if (selectedSegment === 'balance_bucket') value = item['balance_bucket'];
  //   return value;
  // };

  return (
    <Box mt='1rem'>
      <Typography>
        Will allow you to split your population into selected segments (DPD
        buckets, Balance Category, Product Type or Current Status of the
        customer) and have specific channels and templates for each group.
      </Typography>

      <Box ml='5rem' mt='2rem'>
        <table className={classes.tableStyle}>
          <tr>
            <th></th>
            <th>
              <Typography># Customers</Typography>
            </th>
            <th>
              <Typography>$ Outstanding</Typography>
            </th>
            <th>
              <Typography>Average DPD</Typography>
            </th>
            <th>
              <Typography>{getHeader()}</Typography>
            </th>
          </tr>
          {segmentData.length ? (
            segmentData?.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Typography>{index + 1}.</Typography>
                  </td>
                  <td>
                    <Typography>{row['#customers']}</Typography>
                  </td>
                  <td>
                    <Typography>
                      {row.outstanding_balance &&
                        `$ ${row.outstanding_balance.toLocaleString()}`}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{row.average_dpd}</Typography>
                  </td>
                  <td>
                    <Typography>{row?.filter}</Typography>
                  </td>
                </tr>
              );
            })
          ) : (
            <Box sx={{ minHeight: 150 }}></Box>
          )}
        </table>
      </Box>
    </Box>
  );
};

export default SplitIntoSegments;
