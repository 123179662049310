import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '2rem',
    },
    actionButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '10px',
    },
    actionCont: {
      // background: '#115180',
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      color: '#fff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '7px',
      width: '135px',
      padding: '8px 10px',
      position: 'relative',
      cursor: 'pointer',
      '& svg': {
        color: '#fff',
        fontSize: '1.3rem',
      },
      '& p': {
        position: 'absolute',
        transform: 'translate(0, -50%)',
        left: '40%',
        top: '50%',
      },
    },
    createCampaign: {
      display: 'flex',
      alignItems: 'center',
      background: '#004357',
      color: '#fff',
      borderRadius: '16px',
      gap: '1rem',
      height: '135px',
      width: '230px',
      justifyContent: 'center',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
      cursor: 'pointer',
      '& p': {
        lineHeight: 1.3,
        textAlign: 'center',
        fontSize: '20px',
      },
    },
  })
);

export default useStyles;
