import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import { ActionWrapper, RoleNameBox, SearchBar, TableWrapper } from './style';

import { PermissionButton } from 'containers/Permissions/styles';
import { PermissionList } from './permissionList';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { permissionList } from 'store/reducer/roleReducer';
import {
  createRole,
  getAllRole,
  updateRole,
} from 'store/reducer/roleReducer/action';
import Loader from 'components/loader';
import PlainModal from 'components/Modal/PlainModal';
import { RoleType } from 'constants/types';

export type PermissionType = { permission_id: string; permission_name: string };

export const CreateEditRole = React.memo(
  ({
    open,
    data,
    onClose,
  }: {
    data?: RoleType;
    open: boolean;
    onClose: () => void;
  }) => {
    const dispatch = useAppDispatch();

    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [roleName, setRoleName] = React.useState('');
    const [searchPermission, setSearchPermission] = React.useState('');
    const [selectedPermission, setSelectedPermission] = React.useState<
      string[]
    >([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [permsList, setPermsList] = React.useState<any[]>([]);

    const permissions = useAppSelector(permissionList);

    React.useEffect(() => {
      setPermsList(permissions || []);
    }, [permissions]);

    React.useEffect(() => {
      if (data) {
        setIsEdit(true);
        setRoleName(data?.role_name || '');
        setSelectedPermission(data?.permission_id_list || []);
      }
    }, [data]);

    const handleRoleName = (e: React.ChangeEvent<HTMLInputElement>) => {
      setRoleName(e.target.value);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      let searchValue = e.target.value;
      if (searchValue) {
        const data = permissions.filter((res: any) =>
          res?.permission_name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setPermsList(data);
      } else {
        setPermsList(permissions);
      }
      setSearchPermission(searchValue);
    };

    const handleSave = async () => {
      if (roleName && selectedPermission.length) {
        setLoading(true);
        try {
          if (!isEdit) {
            await dispatch(
              createRole({
                role_name: roleName,
                perms_to_add: selectedPermission,
              })
            );
          } else {
            if (data) {
              const initialPerms = data?.permission_id_list;
              const perms_to_del = initialPerms.filter(
                (item: string) => !selectedPermission.includes(item)
              );
              const perms_to_add = selectedPermission.filter(
                (item: string) => !initialPerms.includes(item)
              );
              await dispatch(
                updateRole({
                  role_id: data?.role_id,
                  perms_to_add,
                  perms_to_del,
                  deactivate: false,
                })
              );
            }
          }
          await dispatch(getAllRole());
        } finally {
          setLoading(false);
        }
        onClose();
        resetRolePermission();
      }
    };

    const resetRolePermission = () => {
      setSelectedPermission([]);
      setRoleName('');
    };

    const onModalClose = () => {
      if (!isEdit) resetRolePermission();
      onClose();
    };

    return (
      <>
        <Loader loading={loading} />
        <PlainModal
          open={open}
          onClose={onModalClose}
          children={
            <Box sx={{ width: '60vw', maxHeight: '80vh', overflow: 'auto' }}>
              <RoleNameBox isEdit={isEdit}>
                <input
                  type='text'
                  placeholder='Enter the role name'
                  value={roleName}
                  onChange={handleRoleName}
                  disabled={isEdit ? true : false}
                />
                {isEdit && (
                  <Typography color='#32475C75 !important'>
                    {selectedPermission.length} permission groups added under
                    this role
                  </Typography>
                )}
              </RoleNameBox>

              <Typography px='2rem'>
                Click on the check box of the permission group that you want to
                add under this role. All the rights under that permission group
                will come under this role automatically.
              </Typography>

              <SearchBar>
                <CiSearch />
                <input
                  type='text'
                  placeholder='Search permission to add under this role'
                  value={searchPermission}
                  onChange={handleSearch}
                />
              </SearchBar>

              <TableWrapper>
                <PermissionList
                  permissions={permsList}
                  selected={selectedPermission}
                  setSelected={setSelectedPermission}
                />
              </TableWrapper>

              <ActionWrapper>
                <PermissionButton
                  background='#004357'
                  color='#fff'
                  disabled={
                    !roleName ||
                    !selectedPermission.length ||
                    (data && !data?.editable)
                  }
                  onClick={handleSave}
                >
                  <Typography>Save Changes</Typography>
                </PermissionButton>
                <PermissionButton
                  background='#fff'
                  color='#8592A3'
                  onClick={onModalClose}
                >
                  <Typography>Discard</Typography>
                </PermissionButton>
              </ActionWrapper>
            </Box>
          }
        />
      </>
    );
  }
);
