import { createStyles, makeStyles } from '@material-ui/core';
import { Typography, styled } from '@mui/material';

export const StrategyName = styled(Typography)({
  align: 'center',
  fontWeight: 600,
  fontSize: '1.2rem',
  mb: '0.5rem',
  textAlign: 'center',
});

const useStyles = makeStyles(() =>
  createStyles({
    startegyView: {
      position: 'absolute',
      right: 0,
      top: 5,
      fontSize: 18,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
