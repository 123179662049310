import { Box, Typography } from '@mui/material';

const BlockedExpired = ({ status }: { status: string }) => {
  return (
    <Box
      sx={{
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        width: '70%',
        margin: 'auto',
      }}
    >
      <Typography variant='h6'>
        {status === '4' ? 'Blocked' : 'Expired'} Campaign
      </Typography>
      <Typography mb='1rem'>
        {status === '4'
          ? 'You are not subscribed to send to x customers'
          : 'Since no update happened, this campaign communication has been archived.'}
      </Typography>
      {status === '4' && (
        <Box
          sx={{
            background: 'rgba(255, 182, 0, 0.31)',
            borderRadius: '9px',
            padding: '5px 10px',
          }}
        >
          <Typography color='#24254A'>
            Please upgrade your subscription in order to resume this
            communication else recreate another campaign with less communication
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default BlockedExpired;
