import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './index.styles';
import { Form, Formik } from 'formik';
import FormikCampaignDatePicker from 'components/formik/campaign/FormikCampaignDatePicker';
import FormikCampaignTimePicker from 'components/formik/campaign/FormikCampaignTimePicker';
import { setCampaignPayload } from 'store/reducer/campaignReducer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moment from 'moment';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const CampaignSchedule = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );
  const permissions = useAppSelector(userPermissions);

  const [schedulePreference, setSchedulePreference] =
    React.useState<string>('now');

  React.useEffect(() => {
    if (typeof campaignPayload?.schedule_time === 'object')
      setSchedulePreference('later');
  }, []);

  const handleScheduleChange = (schedule: string) => {
    if (permissions[MODULELIST.CAMPAIGN].includes('update')) {
      setSchedulePreference(schedule);
      const data = schedule === 'now' ? 'now' : {};
      dispatch(setCampaignPayload({ key: 'schedule_time', data }));
    }
  };

  const handleChange = (type: string, value?: any) => {
    if (permissions[MODULELIST.CAMPAIGN].includes('update')) {
      let data = { ...campaignPayload?.schedule_time };
      if (type === 'date') {
        data['date'] = moment(value).format('YYYY-MM-DD');
      }
      if (type === 'time') {
        data['time'] = value;
      }
      dispatch(setCampaignPayload({ key: 'schedule_time', data }));
    }
  };

  return (
    <Box>
      <Box className={classes.scheduleOptionsCont}>
        <Typography
          className={`${classes.scheduleOptions} ${
            schedulePreference === 'now' && classes.scheduleSelected
          }`}
          onClick={() => handleScheduleChange('now')}
        >
          Trigger Now
        </Typography>
        <Typography
          className={`${classes.scheduleOptions} ${
            schedulePreference === 'later' && classes.scheduleSelected
          }`}
          onClick={() => handleScheduleChange('later')}
        >
          Schedule for Later
        </Typography>
      </Box>

      <Box>
        {schedulePreference === 'now' && (
          <Typography my='1rem'>
            Campaign will be triggered in 1 minute of submission
          </Typography>
        )}
        {schedulePreference === 'later' && (
          <Box>
            <Typography my='1rem'>
              Please select the date and time for this campaign to get
              triggered. You can edit this campaign any time before the
              scheduled time. If you choose any time less that the time of
              submission, the campaign schedule will consider ‘trigger now’
              action and gets triggered in 1 minute of submission.
            </Typography>

            <Formik
              initialValues={{
                date: campaignPayload?.schedule_time?.date
                  ? new Date(campaignPayload.schedule_time.date)
                  : null,
                time: campaignPayload?.schedule_time?.time || '',
              }}
              onSubmit={() => {}}
            >
              <Form>
                <Box className={classes.formCont}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    <Typography mt='5px'>Date of the Campaign</Typography>
                    <FormikCampaignDatePicker
                      name='date'
                      handleChange={handleChange}
                    />
                  </Box>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    <Typography mt='5px'>Time of the Campaign</Typography>
                    <FormikCampaignTimePicker
                      name='time'
                      handleChange={handleChange}
                    />
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CampaignSchedule;
