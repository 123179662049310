import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragAndDropWrapper: {
      background: '#F9F9F9',
      borderRadius: '8.5pxx 8.5px 0px 0px',
      padding: '0 1rem 1rem 1rem',
      height: '98%',
    },
    headerCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      padding: '10px 0',
      borderBottom: (props: any) => `4px solid ${props.column.color}`,

      '& p': {
        fontFamily: 'Inter',
      },
    },
    headerBullet: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      background: (props: any) => props.column.color,
    },
    headerRequestCount: {
      color: '#625F6D',
      background: '#E0E0E0',
      borderRadius: '50%',
      height: 22,
      width: 22,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
    },
    draggableItemCont: {
      background: '#fff',
      borderRadius: '17px',
      padding: '0.8rem',
      marginTop: '1rem',
    },
    draggableItemHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    drggableItemHeaderPriority: {
      background: 'rgba(223, 168, 116, 0.2)',
      borderRadius: '5px',
      padding: '2px 8px 0',
      fontFamily: 'Inter !important',
      textTransform: 'capitalize',
      fontSize: '0.9rem !important',
    },
    draggableItemContent: {
      paddingTop: '0.6rem',
      paddingBottom: '1rem',
      '& p': {
        fontFamily: 'Poppins !important',
      },
    },
    commentText: {
      textDecoration: 'underline',
      color: theme.kollectAITheme.primaryColor,
      fontSize: '0.8rem',
    },
    noActionCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& button': {
        background: 'rgb(80, 48, 229, 0.2)',
        padding: '4px 4px 1px',
        borderRadius: '6px',
      },
    },
  })
);

export default useStyles;
