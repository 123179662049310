import { useState } from 'react';
import { Typography } from '@mui/material';
import { FormikCustomerTextarea } from 'components/formik/customer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { postCustomerRequests } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import {
  customerOdfID,
  setRefetchRequest,
} from 'store/reducer/customerReducer';

interface AddRequestCommentProps {
  editData?: any;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  comment: yup.string().required('Required'),
});

const AddRequestComment: React.FC<AddRequestCommentProps> = ({
  editData,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const odfId = useAppSelector(customerOdfID);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    let payload: any = {
      req_id: editData?.req_id,
      odf_customer_id: odfId,
      customer_id: editData?.customer_id,
      priority: editData?.priority,
      category: editData?.category,
      issue_title: editData?.issue_title,
      issue_description: editData?.issue_description,
      comments: [values?.comment],
    };
    await dispatch(postCustomerRequests(payload));
    dispatch(setRefetchRequest(true));
    onClose();
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={{ comment: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <FormikCustomerTextarea name='comment' label='Please add comment' />

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Update</Typography>
              </ActionButton>
              <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                <Typography>Cancel</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddRequestComment;
