import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateLeftContSearch: {
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '0 10px',
    },
    templateLeftContSearchLabel: {
      background: '#EDF0F4',
      borderRadius: '3px',
      padding: '2px 5px',
    },
    addTemplateButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      background: theme.kollectAITheme.primaryColor,
      border: '1px solid #E0E7FF',
      color: '#fff',
      padding: '0.2rem 1rem',
      borderRadius: '5px',

      '& svg': {
        fontSize: '1rem',
      },
    },
  })
);

export default useStyles;
