import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import './style.css';

import uploadImg from 'assets/upload_drag.svg';

interface IntegrationDragAndDropProps {
  setFile: (x: any) => void;
}

const IntegrationDragAndDrop: React.FC<IntegrationDragAndDropProps> = ({
  setFile,
}) => {
  const wrapperRef: any = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e: any) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <>
      <Box
        ref={wrapperRef}
        className='integration_drop-input'
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Box>
          <img src={uploadImg} alt='' />
          <Box mt='2rem' mb='3rem'>
            <Typography fontSize='1rem' mt='1rem'>
              Select a file or drag and drop here
            </Typography>
            <Typography fontSize='1rem' className='drop-file-input_subtitle'>
              CSV or XLS | file size no more than 10MB
            </Typography>
          </Box>
          <Typography className='integration_drop-input_select'>
            SELECT FILE
          </Typography>
        </Box>
        <input type='file' value='' accept='.csv' onChange={onFileDrop} />
      </Box>
    </>
  );
};

export default IntegrationDragAndDrop;
