import * as React from 'react';
import { Box } from '@mui/material';

import { EditPendingUser } from './edit';
import { DeletePendingUser } from './delete';

export const PendingUserModal = ({
  onClose,
  setPage,
  data,
  roleOptions,
}: {
  data: any;
  onClose: () => void;
  setPage: () => void;
  roleOptions: { label: string; value: string }[];
}) => {
  const [actionState, setActionState] = React.useState<string>('');

  return (
    <Box p='1.5rem 2rem'>
      {(!actionState || actionState === 'edit') && (
        <EditPendingUser
          action={actionState}
          setAction={setActionState}
          data={data}
          roleOptions={roleOptions}
          onClose={onClose}
          setPage={setPage}
        />
      )}
      {actionState === 'delete' && (
        <DeletePendingUser
          setAction={setActionState}
          userId={data?.user_id}
          onClose={onClose}
          setPage={setPage}
        />
      )}
    </Box>
  );
};
