import React from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { TableActionWrapper } from './style';
import { PermissionType } from '.';

export const PermissionList = ({
  permissions,
  selected,
  setSelected,
}: {
  permissions: PermissionType[];
  selected: string[];
  setSelected: (x: any) => void;
}) => {
  const permission = (perm: any) => {
    let moduleName = '';
    for (let key in perm?.module) {
      const access = perm?.module[key];
      let accessName = access.join(', ');
      let allAccess = ['create', 'delete', 'execute', 'read', 'update'];
      if (JSON.stringify(access) === JSON.stringify(allAccess))
        accessName = 'all';
      moduleName = moduleName + `${key} (${accessName}); `;
    }

    return (
      <Typography
        textTransform='capitalize'
        fontWeight={600}
        color='#32475CDE'
        fontSize='1.1rem'
      >
        {perm?.permission_name}{' '}
        <Typography component='span' fontSize='0.9rem'>
          {moduleName}
        </Typography>
      </Typography>
    );
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 450 }} aria-labelledby='tableTitle' size='medium'>
        <TableBody>
          {permissions?.map((row: any, index: number) => {
            return (
              <TableRow role='checkbox' key={index}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    sx={{
                      padding: '0 0 0 1rem',
                      '&.Mui-checked': { color: '#004357' },
                    }}
                    checked={selected?.includes(row?.permission_id)}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      if (checked) {
                        setSelected((prev: any) => [
                          ...prev,
                          row?.permission_id,
                        ]);
                      } else {
                        setSelected((prev: any) =>
                          prev.filter(
                            (item: any) => item !== row?.permission_id
                          )
                        );
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{permission(row)}</TableCell>
                <TableCell width='15%'>
                  <TableActionWrapper>
                    <AiOutlineInfoCircle />
                  </TableActionWrapper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
