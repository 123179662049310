import { Box } from '@mui/material';
import {
  FormikCustomerCheckbox,
  FormikCustomerInput,
} from 'components/formik/customer';

const NotEngaged = ({ values }: { values: any }) => {
  return (
    <Box
      mb='1rem'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <FormikCustomerCheckbox
        name='hasAlternateContact'
        label='Has alternate contact'
      />
      {values?.hasAlternateContact && (
        <Box display='flex' alignItems='start' gap='0.5rem' ml='2rem'>
          <FormikCustomerInput name='email' placeholder='Email' />
          <FormikCustomerInput name='phone' placeholder='Phone' />
        </Box>
      )}
    </Box>
  );
};

export default NotEngaged;
