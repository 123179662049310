import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { CiCalendar } from 'react-icons/ci';
import useStyles from './index.styles';

import workflowImg from 'assets/run_workflowsvg.svg';
import calendarImg from 'assets/calendar.svg';
import { useAppDispatch } from 'hooks/useRedux';
import {
  customerProfileFilter,
  getCustomerProfile,
} from 'store/reducer/customerReducer/action';
import { setCustomerProfile } from 'store/reducer/customerReducer';
import Loader from 'components/loader';

export const DatePickerCont = styled('div')({
  display: 'inline-flex',
  padding: '0.2rem 1rem',
  alignItems: 'center',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '& .react-datepicker-wrapper input': {
    background: 'transparent !important',
    color: '#000 !important',
  },
});

const WorkflowDetails = ({ profileData }: { profileData: any }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  // const profileData: any = useAppSelector(
  //   (state) => state.customer.customerProfile?.[0]
  // );
  // const workflowDetails = profileData?.workflow_details;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date | null>(null);
  const [workflowDetails, setWorkflowDetails] = React.useState<any>(null);

  const getCustomerDetails = React.useCallback(async () => {
    try {
      await dispatch(getCustomerProfile({ customerId: id })).unwrap();
    } catch (error) {}
  }, [dispatch, id]);

  React.useEffect(() => {
    getCustomerDetails();
  }, [getCustomerDetails]);

  React.useEffect(() => {
    if (profileData) setWorkflowDetails(profileData.workflow_details);
  }, [profileData]);

  const handleDate = async (newDate: Date) => {
    setLoading(true);
    if (newDate) {
      try {
        const filterDate = moment(newDate).format('YYYY-MM-DD');
        const res = await dispatch(
          customerProfileFilter({
            customer_id: id,
            workflow_date: filterDate,
          })
        ).unwrap();
        if (res?.[0]?.workflow_details) {
          const newProfileData = {
            ...profileData,
            workflow_details: res?.[0]?.workflow_details,
          };
          dispatch(setCustomerProfile([newProfileData]));
        }
      } catch (error) {}
    } else {
      await dispatch(getCustomerProfile({ customerId: id })).unwrap();
    }
    setDate(newDate);
    setShowCalendar(false);
    setLoading(false);
  };

  if (!workflowDetails) return <></>;

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ '& p': { fontFamily: 'Overpass' } }}>
        <Box className={classes.workflowDetailsHeader}>
          <Typography>Workflow run step sequence (today)</Typography>
          <Box
            display='flex'
            alignItems='center'
            gap='12px'
            position='relative'
          >
            <button>
              <img src={workflowImg} alt='' />
            </button>
            <button onClick={() => setShowCalendar((prev) => !prev)}>
              <img src={calendarImg} alt='' />
            </button>
            {showCalendar && (
              <Box className={classes.filterWrapper}>
                <Box mt='1rem' className={classes.flexBetween}>
                  <Typography>Date</Typography>
                  <DatePickerCont>
                    <DatePicker selected={date} onChange={handleDate} />
                    <CiCalendar />
                  </DatePickerCont>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <ul>
          {workflowDetails?.map((workflow: any, index: number) => {
            const { running_ts, step_name } = workflow;
            return (
              <li key={index} className={classes.workflowList}>
                <Box className={classes.workflowListNumber}>
                  <Typography fontSize='12px'>{index + 1}</Typography>
                </Box>

                <Typography
                  className={classes.connectectingLine}
                  sx={{
                    '&:before': {
                      top:
                        index === 0
                          ? '50%'
                          : index === workflowDetails.length - 1
                          ? '-50%'
                          : 0,
                    },
                  }}
                >
                  {step_name} at{' '}
                  {moment(running_ts).format('DD/MM/YYYY hh:mm A')}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </>
  );
};

export default WorkflowDetails;
