import { Box, Checkbox, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';

interface FormikCustomerCheckboxProps {
  label: string;
  name: string;
}

const FormikCustomerCheckbox: React.FC<FormikCustomerCheckboxProps> = ({
  label,
  name,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const { value } = field;

        return (
          <>
            <Box display='flex' alignItems='center' gap='10px'>
              <Checkbox
                disableRipple
                {...field}
                checked={value ? value : false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = event.target;
                  setFieldValue(name, checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  padding: 0,
                  color: kollectAITheme.primaryColor,
                  '&.Mui-checked': {
                    color: kollectAITheme.primaryColor,
                  },
                }}
              />
              <Typography color='#272D3B'>{label}</Typography>
            </Box>
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default FormikCustomerCheckbox;
