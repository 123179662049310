import { makeStyles, Theme, Typography } from '@material-ui/core';
import ChannelDisplayBox from '../channelDisplayBox';

interface StyleProps {
  email: boolean;
  ivr: boolean;
  sms: boolean;
  whatsapp: boolean;
}

const useStyles: any = makeStyles<Theme, StyleProps>({
  dpdBoxContainer: {
    cursor: 'pointer',
    color: '#969696',
    height: '150px',
    border: '1px solid #E1E1E1',
    background: (props) =>
      !props.email && !props.ivr && !props.sms && !props.whatsapp
        ? '#E8E8E8'
        : '#fff',
  },
  dpdNumberStyle: {
    padding: '0px 8px',
  },
});

const DpdBox = (props: any) => {
  const { dpd, email, ivr, sms, whatsapp } = props;
  const { dpdBoxContainer, dpdNumberStyle } = useStyles(props);

  return (
    <div
      className={dpdBoxContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant='h6' className={dpdNumberStyle}>
        {dpd}
      </Typography>

      <div style={{ marginBottom: '10px' }}>
        {whatsapp && (
          <div>
            <ChannelDisplayBox
              channelName='whatsapp'
              channelColor='rgb(255, 159, 45)'
              channelBackgroundColor='rgb(255, 159, 45, 0.1)'
            />
          </div>
        )}
        {sms && (
          <div>
            <ChannelDisplayBox
              channelName='sms'
              channelColor='rgb(0, 186, 52)'
              channelBackgroundColor='rgb(0, 186, 52, 0.1)'
            />
          </div>
        )}
        {email && (
          <div>
            <ChannelDisplayBox
              channelName='email'
              channelColor='rgb(233, 44, 44)'
              channelBackgroundColor='rgb(233, 44, 44, 0.1)'
            />
          </div>
        )}
        {ivr && (
          <div>
            <ChannelDisplayBox
              channelName='ivr'
              channelColor='#0085FF'
              channelBackgroundColor='rgb(0, 133, 255, 0.1)'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DpdBox;
