import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import useStyles from './styles';

import Loader from 'components/loader';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getCustomerById,
  getCustomerDetails,
  getCustomerStats,
} from 'store/reducer/customerReducer/action';
import CustomerActionBox from './ActionBox';
import CustomerManagementTable from './table';

import { CustomerDetailsType, CustomerStatsType } from 'constants/types';
import {
  customerCategory,
  setCustomerCategory,
  setSelectedCustomerProduct,
  setSelectedCustomersArr,
} from 'store/reducer/customerReducer';
import { statusData } from './statusData';
import CustomerFilter from './filter';
import CollapsedAction from 'components/customer/CustomerAction/collapsedActions';
import { listingActions } from 'components/customer/CustomerAction/actions';
import CustomerActions from 'components/customer/CustomerAction';

const initialStats = {
  bad_contact: 0,
  call_required: 0,
  hardship: 0,
  not_categorized: 0,
  not_engaged: 0,
  ptp: 0,
  ptp_broken: 0,
};

const getCategory = (status: string) => {
  let category = 'New or Uncategorised';
  if (status === 'PTP') category = 'PTP';
  if (status === 'Broken_Promise') category = 'PTP Broken';
  if (status === 'Call_Required') category = 'Call Required';
  if (status === 'Not_engaged') category = 'Not Engaged';
  if (status === 'Bad_Contact') category = 'Bad Contact';
  return category;
};

const CustomerManagementScreen = () => {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('workflow_id');
  const dispatch = useAppDispatch();

  const workflowCustomers = useAppSelector(
    (state) => state.workflow.workflowCustomerDetails
  );
  const selectedStatus = useAppSelector(customerCategory);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>(
    'New or Uncategorised'
  );
  const [stats, setStats] = useState<CustomerStatsType>(initialStats);
  const [tableData, setTableData] = useState<CustomerDetailsType[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [showStatusTabs, setShowStatusTabs] = useState<boolean>(false);
  const [collapseAction, setCollapseAction] = useState(true);
  const [selectedCustomers, setSelectedCustomers] = useState<Array<string>>([]);
  const [filterID, setFilterID] = useState<string>('');
  const [isSearchData, setIsSearchData] = useState<boolean>(false);

  const getTableData = async (page?: number, customerStatus?: string) => {
    setLoading(true);
    try {
      let customerCat = customerStatus || 'Default';
      let pagPage = page || currentPage;
      if (!customerStatus) {
        dispatch(setCustomerCategory('Default'));
      }
      const statsRes = await dispatch(getCustomerStats()).unwrap();
      setStats(statsRes);

      const { count, results } = await dispatch(
        getCustomerDetails({ category: customerCat, page: pagPage })
      ).unwrap();
      const total = Math.ceil(count / 10);
      setTableData(results);
      setTotalPage(total);
      setSelectedCustomers([]);
    } catch (error) {
      setTableData([]);
    } finally {
      setLoading(false);
      setIsSearchData(false);
    }
  };

  useEffect(() => {
    if (!workflowId) {
      getTableData(1, selectedStatus);
      setSelectedAction(getCategory(selectedStatus));
    }
    dispatch(setSelectedCustomerProduct(''));
    dispatch(setSelectedCustomersArr([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workflowCustomers) {
      const { results, count } = workflowCustomers;
      const total = Math.ceil(count / 10);
      setTableData(results);
      setTotalPage(total);
    }
  }, [workflowCustomers]);

  useEffect(() => {
    if (!workflowId) {
      setShowStatusTabs(true);
    } else {
      setShowStatusTabs(false);
    }
  }, [workflowId]);

  useEffect(() => {
    if (showStatusTabs) {
      setCurrentPage(1);
      // getTableData(1, selectedStatus);
      setSelectedAction(getCategory(selectedStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStatusTabs]);

  const handleActionSelect = (label: string) => {
    let category = label;
    if (category === 'New or Uncategorised') category = 'Default';
    if (category === 'PTP Broken') category = 'Broken_Promise';
    if (category === 'Call Required') category = 'Call_Required';
    if (category === 'Not Engaged') category = 'Not_engaged';
    if (category === 'Bad Contact') category = 'Bad_Contact';
    getTableData(1, category);
    setCurrentPage(1);
    setSelectedAction(label);
    dispatch(setCustomerCategory(category));
    setFilterID('');
  };

  const onFilter = async () => {
    if (filterID) {
      setLoading(true);
      try {
        const res = await dispatch(
          getCustomerById({ customerId: filterID })
        ).unwrap();
        setTableData(res);
      } catch (error) {
        setTableData([]);
      } finally {
        setLoading(false);
        setIsSearchData(true);
      }
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns:
            showStatusTabs && collapseAction
              ? '1fr 70px'
              : showStatusTabs && !collapseAction
              ? '1fr 220px'
              : '1fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ margin: '2rem 2rem 0' }}>
          {showStatusTabs && (
            <Box className={classes.statusWrapper}>
              {statusData.map((status) => (
                <CustomerActionBox
                  {...status}
                  value={stats[status.valueKey as keyof CustomerStatsType]}
                  selectedAction={selectedAction}
                  onClick={handleActionSelect}
                  key={status.label}
                />
              ))}
            </Box>
          )}

          <CustomerFilter
            currentPage={currentPage}
            totalPage={totalPage}
            setLoading={setLoading}
            setTableData={setTableData}
            getTableData={getTableData}
            setCurrentPage={setCurrentPage}
            setTotalPage={setTotalPage}
            setSelectedAction={setSelectedAction}
            onFilter={onFilter}
            filterID={filterID}
            setFilterID={setFilterID}
          />

          <CustomerManagementTable
            data={tableData}
            status={selectedAction}
            selected={selectedCustomers}
            setSelected={(customers: string[]) =>
              setSelectedCustomers(customers)
            }
            isSearchData={isSearchData}
          />
        </Box>

        {showStatusTabs && (
          <>
            {collapseAction ? (
              <Box sx={{ marginTop: '35vh' }}>
                <CollapsedAction
                  availableActions={listingActions}
                  fetchData={() => {
                    getTableData(1, selectedStatus);
                    dispatch(setSelectedCustomersArr([]));
                  }}
                  onExpand={() => setCollapseAction(false)}
                  disabled={!selectedCustomers.length}
                />
              </Box>
            ) : (
              <Box sx={{ position: 'relative', margin: '2rem 1rem 0 0' }}>
                <Box
                  className={classes.actionsWrapper}
                  onClick={() => setCollapseAction(true)}
                >
                  <BiChevronRight size={22} />
                </Box>
                <CustomerActions
                  availableActions={listingActions}
                  fetchData={() => {
                    getTableData(1, selectedStatus);
                    dispatch(setSelectedCustomersArr([]));
                  }}
                  disabled={!selectedCustomers.length}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CustomerManagementScreen;
