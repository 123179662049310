import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const ActionButton = styled('div')(
  ({
    background,
    radius = '8px',
  }: {
    background: string;
    radius?: string;
  }) => ({
    background,
    padding: '6px 1.5rem',
    color: '#fff',
    borderRadius: radius,
    cursor: 'pointer',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#FFFFFF',
      border: '3px solid #EAEAEA',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '1.2rem 2rem 1rem',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    detailsBlockCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    tableStyle: {
      borderCollapse: 'collapse',
      width: '100%',
      '& td, th': {
        textAlign: 'center',
        padding: '8px',
        '& p': { fontWeight: 500 },
      },
      '& th': { color: '#6F6F6F' },
    },
    campaignDetailsContent: {
      display: 'grid',
      gridTemplateColumns: '45% 55%',
      alignItems: 'start',
      gap: '1rem',
    },
    summaryDetailsCont: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      padding: '1.5rem 2rem 1rem',
      marginTop: '10px',
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    summaryText: {
      fontSize: '1.3rem !important',
      color: '#004357',
      textAlign: 'center',
    },
  })
);

export default useStyles;
