import { Typography } from '@mui/material';
import useStyles from './index.styles';

const FilterBox = ({
  label,
  selected,
  handleFilterChange,
}: {
  label: string;
  selected: boolean;
  handleFilterChange: () => void;
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${classes.filterOptions} ${
        selected && classes.filterSelected
      }`}
      onClick={handleFilterChange}
    >
      {label}
    </Typography>
  );
};

export default FilterBox;
