import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import useStyles from './index.styles';

interface CustomSelectBoxProps {
  title: string;
  labels: string[];
  filterChange: (x: string, y: string) => void;
  value: string;
  setValue: (x: string) => void;
}

const CustomSelectBox = (props: CustomSelectBoxProps) => {
  const { title, labels, filterChange, value, setValue } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setValue(selectedValue);
    filterChange(title, selectedValue);
  };

  return (
    <div>
      <div className={classes.customSelectTitle}>{title}</div>
      <div className={classes.radioGroupContainer}>
        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='controlled-radio-buttons-group'
          value={['0', 0, null, ''].includes(value) ? 'All' : value}
          onChange={handleChange}
        >
          {labels.map((label, index) => {
            return (
              <FormControlLabel
                key={index}
                value={label}
                classes={{ label: classes.label }}
                control={
                  <Radio
                    size='small'
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label={label}
              />
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
};

export default CustomSelectBox;
