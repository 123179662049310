import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import useStyles from './index.styles';

import { useAppDispatch } from '../../../hooks/useRedux';
import { login, setVerifyEmailData } from '../../../store/reducer/auth';

import Loader from 'components/loader';
import { FormikAuthInput, FormikAuthPassword } from 'components/formik/auth';
import { ForgotPasswordModal, VerifyEmailModal } from 'components/auth/modals';
import {
  getSubscriptionConfig,
  getUserConfig,
} from 'store/reducer/auth/actions';

const validationSchema = yup.object().shape({
  email: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

const LoginScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [openVerify, setOpenVerify] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      setLoading(true);
      const payload = {
        email: values.email,
        password: values.password,
      };
      try {
        const { data, meta } = await dispatch(login(payload)).unwrap();

        if (meta?.success) {
          if (data.access_token) {
            await dispatch(getUserConfig());
            await dispatch(getSubscriptionConfig());
            navigate('/');
          } else {
            if (!data?.email_verified) {
              setEmail(values.email);
              setOpenVerify(true);
              setLoading(false);
              return;
            }

            if (!data?.phone_verified) {
              dispatch(
                setVerifyEmailData({
                  phone: data?.phone_number,
                  email: data?.email,
                  phone_country_code: data?.country_code,
                  token: data?.token,
                  phone_verified: data?.phone_verified,
                })
              );
              navigate('/otp-verification');
              return;
            }
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, navigate]
  );

  return (
    <>
      <Loader loading={loading} />

      <Box width='55%'>
        <Box mb='2rem'>
          <Typography fontSize='1.6rem' fontWeight={500}>
            Welcome back!
          </Typography>
          <Typography>Please login to access your account.</Typography>
        </Box>

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <FormikAuthInput
                name='email'
                label='E-mail or phone number'
                placeholder='Type your e-mail or phone number'
              />

              <FormikAuthPassword
                name='password'
                label='Password'
                placeholder='Type your password'
                show={showPassword}
                handleShow={() => setShowPassword(!showPassword)}
              />

              <Typography
                className={classes.forgotPasswordButton}
                fontWeight={500}
                onClick={() => setOpenForgot(true)}
              >
                Forgot Pasword?
              </Typography>

              <Box mt='1.5rem' mb='1rem'>
                <button type='submit' className={classes.loginSubmitButton}>
                  <Typography>Log In</Typography>
                </button>
              </Box>
            </Form>
          )}
        </Formik>

        <Box>
          <Typography align='center'>
            Don't have an account?{' '}
            <span
              className={classes.signUpButtonStyle}
              onClick={() => navigate('/sign-up')}
            >
              Sign Up
            </span>
          </Typography>
        </Box>
      </Box>

      <ForgotPasswordModal
        open={openForgot}
        onClose={() => setOpenForgot(false)}
      />

      <VerifyEmailModal
        open={openVerify}
        email={email}
        onClose={() => setOpenVerify(false)}
      />
    </>
  );
};

export default LoginScreen;
