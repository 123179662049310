import Typography from '@material-ui/core/Typography';

import useStyles from './index.styles';
import CustomIcon from '../customIcons';
import { kollectAITheme } from 'config/theme';

export type SideMenuItemProps = {
  title: string;
  className?: string;
  onClick?: () => void;
  isActive?: boolean;
  iconName?: string;
  disabled?: boolean;
};

const SideMenuItem: React.FC<SideMenuItemProps> = ({
  title,
  className,
  onClick,
  isActive,
  iconName,
  disabled,
}) => {
  const classes = useStyles({ isActive });

  return (
    <button
      className={`${classes.sideMenuItemMainCont} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {isActive && <div className={classes.sideMenuItemActiveBarStyle}></div>}
      <div className={classes.sideMenuIteIconContStyle}>
        <CustomIcon
          name={iconName}
          size={25}
          color={kollectAITheme.primaryColor}
          // className={classes.sideMenuItemRightIconStyle}
        />
      </div>
      <Typography className={classes.sideMenuItemRightTitleStyle}>
        {title}
      </Typography>
    </button>
  );
};

export default SideMenuItem;
