import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  checkboxClasses,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import useStyles from './index.styles';

import { CustomerDetailsType } from 'constants/types';
import { useAppDispatch } from 'hooks/useRedux';

import TableData from './tableData/tableData';
import EmptyScreen from './EmptyScreen';
import { setSelectedCustomersArr } from 'store/reducer/customerReducer';

export default function CustomerManagementTable({
  status,
  data,
  selected,
  isSearchData,
  setSelected,
}: {
  selected: Array<string>;
  status: string;
  isSearchData: boolean;
  data: CustomerDetailsType[] | [];
  setSelected: (x: string[]) => void;
}) {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [numSelected, setNumSelected] = useState<number>(0);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setNumSelected(selected.length);
  }, [selected]);

  useEffect(() => {
    setRowCount(data ? data.length : 0);
  }, [data]);

  const setSelectedCustomers = useCallback(
    (customerIds: string[]) => {
      const customers = customerIds.map((id) =>
        data.find((el) => el.customer_id === id)
      );
      dispatch(setSelectedCustomersArr(customers));
    },
    [data, dispatch]
  );

  const onSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newSelected = [];
      if (event.target.checked) {
        newSelected = data.map((n: any) => n.customer_id);
      }
      setSelectedCustomers(newSelected);
      setSelected(newSelected);
    },
    [data, setSelected, setSelectedCustomers]
  );

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomers(newSelected);

    setSelected(newSelected);
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <TableContainer
          className={classes.tableContainer}
          sx={{ marginBottom: data?.length ? '1rem' : 0 }}
        >
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: '1px solid #ECF1FE',
              },
            }}
          >
            <TableHead
              sx={{
                filter:
                  'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              }}
            >
              <TableRow
                sx={{
                  '& th': {
                    color: '#fff',
                    fontWeight: 500,
                    background: 'rgba(0, 67, 87, 0.66)',
                  },
                }}
              >
                <TableCell padding='checkbox'>
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAll}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: '#fff',
                      },
                      [`&, &.${checkboxClasses.indeterminate}`]: {
                        color: '#fff',
                      },
                    }}
                  />
                </TableCell>
                <TableCell align='center'>
                  <Typography>CUSTOMER ID</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>NUMBER OF</Typography>
                  <Typography>LOANS</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>NET BALANCE</Typography>
                  <Typography>DUE</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>MAX</Typography>
                  <Typography>DPD</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>LAST</Typography>
                  <Typography>COMMUNICATION</Typography>
                  <Typography>SENT</Typography>
                </TableCell>
                <TableCell align='center' width={160}>
                  <Typography>LATEST NOTES</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>STATUS</Typography>
                  <Typography>UPDATES</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography>NOTES</Typography>
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>

            <TableData data={data} isSelected={isSelected} onClick={onClick} />
          </Table>
        </TableContainer>
        {(!data || !data?.length) && (
          <EmptyScreen status={status} isSearchData={isSearchData} />
        )}
      </Box>
    </>
  );
}
