import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseContainerStyle: {
      display: 'flex',
    },
    baseContainerLeftCont: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    baseContainerRightCont: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      background: '#f4f7fA',
      paddingLeft: 75,
      minHeight: 'calc(100vh - 70px)',
    },
  })
);

export default useStyles;
