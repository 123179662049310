import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiTemplateSelectCont: {
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '0 10px',
    },
    multiTemplateSelectLabel: {
      background: '#EDF0F4',
      borderRadius: '3px',
      padding: '2px 5px',
    },
  })
);

export default useStyles;
