import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    createTemplateContainer: {
      padding: '1rem 2rem',
      position: 'relative',
      // width: '100% !important',
    },
    formControl: {
      marginBottom: '1.2rem',
      display: 'flex',
    },
    formLabel: {
      width: '170px',
    },
    formInput: {
      width: '200px',
      background: 'rgba(224, 231, 255, 0.2)',
      border: '1px solid #E0E7FF',
      height: '35px',
      borderRadius: '5px',
      padding: '0 1em',
      fontSize: '0.8em',
      fontFamily: 'inherit',

      '&:focus': {
        outline: 'none',
      },
    },
    formTextarea: {
      width: 500,
      height: 280,
      background: 'rgba(224, 231, 255, 0.2)',
      border: '1px solid #E0E7FF',
      borderRadius: '5px',
      padding: '0.5em 1em',
      fontSize: '0.8em',
      fontFamily: 'inherit',

      '&:focus': {
        outline: 'none',
      },
    },
    formErrorMessage: {
      marginTop: '5px',
      marginBottom: 0,
      color: '#d32f2f',
      fontSize: '0.8em',
    },
    saveButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '4rem',
    },
    saveButtonStyle: {
      fontSize: '1.1rem',
      border: 'transparent',
      cursor: 'pointer',
      color: '#fff',
      textTransform: 'capitalize',
      padding: '0.6em 4rem',
      borderRadius: '20px',
      background: '#004357',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
      bordeRadius: '7px',
    },
    templatePreviewCont: {
      height: '515px',
      overflow: 'auto',
      padding: '5px',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    testFormStyle: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
