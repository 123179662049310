import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCampaignCustomerDetail } from 'store/reducer/campaignReducer/action';
import BasicModal from 'components/Modal';
import ViewCustomerData from './viewData';
import Loader from 'components/loader';

export default function ViewCampaignTable({ data }: { data: any }) {
  const dispatch = useAppDispatch();

  const campaign_id = useAppSelector(
    (state: any) => state.campaign.campaign
  )?.campaign_id;

  const [viewData, setViewData] = React.useState<any>(null);
  const [openView, setOpenView] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleView = async (row: any) => {
    if (campaign_id) {
      setLoading(true);
      try {
        const payload = {
          campaign_id,
          customer_id: row?.customer_id,
          loan_id: row?.loan_id,
        };
        const res = await dispatch(getCampaignCustomerDetail(payload)).unwrap();
        setViewData(res);
        setOpenView(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Loader loading={loading} />

      <TableContainer
        sx={{
          border: '1px solid #E6E6E6',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          background: '#fff',
          marginBottom: '1rem',
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: '1px solid #ECF1FE',
            },
          }}
          aria-label='simple table'
        >
          <TableHead
            sx={{
              filter:
                'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            <TableRow
              sx={{
                '& th': {
                  color: '#A8ABC3',
                  fontWeight: 500,
                  background: '#fff',
                },
              }}
            >
              <TableCell align='center'>
                <Typography>CustomerID</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>Due</Typography>
                <Typography>Amount</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>Net</Typography>
                <Typography>Outstanding</Typography>
                <Typography>Balance</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>DPD</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>Customer</Typography>
                <Typography>Status</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>Due Date</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography>Updated At</Typography>
              </TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align='center'>
                      <Typography>{row?.customer_id}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>
                        {row?.overdue_amount
                          ? `$ ${row.overdue_amount?.toLocaleString()}`
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>
                        {row?.outstanding_balance
                          ? `$ ${row.outstanding_balance?.toLocaleString()}`
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>{row?.days_past_due}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>{row.customer_status}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>
                        {row.delinquent_since_date
                          ? moment(row.delinquent_since_date).format(
                              'D MMM, YYYY'
                            )
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography>
                        {row?.lastmodified_at
                          ? moment(row?.lastmodified_at).format('D MMM, YYYY')
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <button
                        style={{
                          background: '#004357',
                          color: '#fff',
                          borderRadius: '30px',
                          padding: '10px 1.5rem 8px',
                        }}
                        onClick={() => handleView(row)}
                      >
                        <Typography fontWeight={600}>View</Typography>
                      </button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <BasicModal
        open={openView}
        onClose={() => {
          setOpenView(false);
          setViewData(null);
        }}
        children={<ViewCustomerData data={viewData} />}
      />
    </>
  );
}
