import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import { CiCalendar } from 'react-icons/ci';
import { useParams } from 'react-router-dom';
import useStyles from './index.styles';

import { useAppDispatch } from 'hooks/useRedux';

import Loader from 'components/loader';
import BasicSelect from 'components/Select';

const filters = [
  { label: 'All', value: '' },
  { label: 'Action', value: 'Action' },
  { label: 'Notes', value: 'Notes' },
];

export const DatePickerCont = styled('div')({
  display: 'inline-flex',
  padding: '0.2rem 1rem',
  alignItems: 'center',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '& .react-datepicker-wrapper input': {
    background: 'transparent !important',
    color: '#000 !important',
  },
});

const CustomerNotesFilter = ({
  filter,
  date,
  setFilter,
  setDate,
  onClose,
}: {
  filter: string;
  date: Date | null;
  onClose: () => void;
  setFilter: (x: string) => void;
  setDate: (x: Date | null) => void;
}) => {
  const classes: any = useStyles();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleFilterChange = async (newFilter: string) => {
    onClose();
  };

  const handleDate = async (newDate: Date) => {
    onClose();
  };

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.filterWrapper}>
        <Typography fontSize='1.1rem' mb='1rem'>
          Filter
        </Typography>
        <Box className={classes.flexBetween}>
          <Typography>Filter Type</Typography>
          <BasicSelect
            value={filter}
            options={filters}
            onChange={handleFilterChange}
            width='120px'
            size='sm'
          />
        </Box>

        <Box mt='1rem' className={classes.flexBetween}>
          <Typography>Date</Typography>
          <DatePickerCont>
            <DatePicker selected={date} onChange={handleDate} />
            <CiCalendar />
          </DatePickerCont>
        </Box>
      </Box>
    </>
  );
};

export default CustomerNotesFilter;
