import { styled, Box, Typography } from '@mui/material';
import { MdDeleteForever } from 'react-icons/md';
import { Form, Formik } from 'formik';
// import * as yup from 'yup';

import { CustomFormikWorkflowInput } from 'components/formik/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { toggleDrawer } from 'store/reducer/workflowReducer';
import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
// import { workflowHandler } from 'store/reducer/workflowReducer/action';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const Button = styled('button')((props: { backgroundColor: string }) => ({
  background: props.backgroundColor,
  borderRadius: '4px',
  color: '#fff',
  padding: '5px 16px',
}));

// const validationSchema = yup.object().shape({
//   stepName: yup.string().required('Required'),
//   precursorName: yup.string().required('Required'),
// });

interface EndDrawerProps {
  setLoading: (X: boolean) => void;
  validationSchema: any;
  updateJsonConfig: (x: any) => void;
}

const EndDrawer: React.FC<EndDrawerProps> = ({
  setLoading,
  validationSchema,
  updateJsonConfig,
}) => {
  const dispatch = useAppDispatch();

  // const userEmail = useAppSelector((state: any) => state.auth.email);
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();

  const handleSubmit = async (values: any) => {
    // if (workflowEditor === 'create') {
    //   setLoading(true);
    //   const { stepName } = values;
    //   let configObj = { ...graphData.config };
    //   const path = getPath(
    //     configObj,
    //     workflowActionData?.parent,
    //     workflowActionData?.parentValue
    //   );
    //   const data = [{ ...workflowActionData, name: stepName, type: 'END' }];
    //   updateConfigObj(configObj, path, data);
    // }

    setLoading(true);
    const { stepName } = values;
    const configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'END',
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'strategy');
      updateWorkflow(configObj, keys, values.strategy);
    }

    updateJsonConfig(configObj);
  };

  const handleDelete = async () => {
    setLoading(true);
    let configObj = { ...graphData.config };
    const { parent, parentValue } = workflowActionData;

    const path = getPath(configObj, parent, parentValue);
    const oldData = getStepValue(configObj, path);
    let data = [{ name: '', parentValue, parent, position: 1 }];
    if (oldData.length > 1) {
      data = oldData
        .filter((item: any) => item.name !== workflowActionData.name)
        .map((el: any, index: number) => ({ ...el, position: index + 1 }));
    }
    updateConfigObj(configObj, path, data);

    updateJsonConfig(configObj);
  };

  // const updateJsonConfig = async (configObj: any) => {
  //   try {
  //     await dispatch(
  //       workflowHandler({
  //         workflow_id: [graphData.workflow_id],
  //         config: JSON.stringify(configObj),
  //         action: 'save',
  //       })
  //     );
  //     setGraphData('graphData', { ...graphData, config: configObj });
  //     dispatch(setWorkflowConfig(true));

  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const permissions = useAppSelector(userPermissions);

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form
          style={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              type='text'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              type='text'
              placeholder='Precursor Name'
              disabled={true}
            />
          </Box>

          {!graphData.published && (
            <Box display='flex' gap='3rem' justifyContent='center' mt='1rem'>
              {permissions[MODULELIST.WORKFLOW].includes('update') && (
                <Button type='submit' backgroundColor='#6ac5ab'>
                  <Typography>Update</Typography>
                </Button>
              )}
              {/* {userEmail === 'aayushi@opendatafabric.com' && */}
              {permissions[MODULELIST.WORKFLOW].includes('delete') &&
              workflowEditor === 'edit' ? (
                <Button
                  type='button'
                  backgroundColor='#daa09a'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                  onClick={handleDelete}
                >
                  <MdDeleteForever fontSize={20} color='#fff' />
                  <Typography>Delete</Typography>
                </Button>
              ) : (
                <Button
                  backgroundColor='#daa09a'
                  onClick={() => dispatch(toggleDrawer(false))}
                >
                  <Typography>Discard</Typography>
                </Button>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EndDrawer;
