import { Box } from "@mui/material";
import useStyles from "./index.styles";
import abtestingimg from "assets/abtesting.png";
import { AiFillPlusCircle } from "react-icons/ai";

import campaignManagerImg from "assets/campaign-manager.svg";
import strategyMangerImg from "assets/strategy-manager.svg";
import workflowMangerImg from "assets/workflow-manager.svg";
import { Grid, Typography } from "@material-ui/core";
import Textarea from "components/Textarea";
import BasicModal from "components/Modal";
import React, { useState } from "react";

const Managers = [
  {
    name: "campaign",
    img: campaignManagerImg,
    customStyle: {},
  },
  {
    name: "strategy",
    img: strategyMangerImg,
    customStyle: { marginTop: 38 },
  },
  {
    name: "workflow",
    img: workflowMangerImg,
    customStyle: { marginTop: 38 },
  },
];

const CreateABTestingModal = (props: any) => {
  const { onSubmit } = props;
  const classes = useStyles();
  const [quote, setQuote] = useState<string>("");

  const onChangeQuotes = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setQuote(e.target.value);
  };

  return (
    <Box className={classes.requirementPopupWrapper}>
      <Typography className={classes.requirementPopupTitle}>
        AB Testing Requirement
      </Typography>
      <Typography className={classes.requirementPopupDescription}>
        Please provide a brief on requirement and we will get back to you with
        the quote & assistance.
      </Typography>
      <div className={classes.popupFormWrapper}>
        <Textarea
          value={quote}
          onChange={onChangeQuotes}
          placeholder=""
          containerClass={classes.popupTextAreaContainer}
        />
        <button
          className={classes.submitQuotesButton}
          onClick={() => {
            onSubmit();
            setQuote("");
          }}
          disabled={!quote}
        >
          <Typography>GET QUOTES</Typography>
        </button>
      </div>
    </Box>
  );
};
const CongratulationsModal: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.congratulationModalWrapper}>
      <Typography className={classes.congratulationTitle}>
        Thanks for providing your requirement
      </Typography>
      <Typography className={classes.congratulationDescription}>
        We will be revieweing your requirement and get back to you with the
        strategy and quotes for implementation. For more queries, reach out to
        support@opendatafabric.com
      </Typography>
    </Box>
  );
};

const ABTestingScreen: React.FC = () => {
  const classes = useStyles();
  const [createABTestingModal, setCreateABTestingModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quotes, setQuotes] = useState<string>("");

  const onChangeQuotes = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setQuotes(e.target.value);
  };

  const handleSubmitQuotes = (): void => {
    setCreateABTestingModal(false);
    setIsSubmitted(true);
  };

  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box flex={1} paddingRight="10%">
          <Grid
            container
            justifyContent="space-between"
            className={classes.banner}
          >
            <Grid item xs={12} md={6} className={classes.bannerContent}>
              <Typography className={classes.bannerHeading}>
                A/B testing?
              </Typography>
              <Typography className={classes.bannerDescription}>
                A/B testing, also known as split testing, refers to a randomized
                experimentation process wherein two or more versions of a
                variable{" "}
              </Typography>
              <button
                className={classes.createABTestingButton}
                onClick={() => {
                  setCreateABTestingModal(true);
                }}
              >
                <AiFillPlusCircle />
                <Typography>Create A/B Testing</Typography>
              </button>
            </Grid>
            <Grid item xs={12} md={6} className={classes.bannerImageWrapper}>
              <img src={abtestingimg} alt="" />
            </Grid>
          </Grid>
          <div>
            <Typography className={classes.title}>A/B testing?</Typography>
            <Typography className={classes.description}>
              A/B testing, also known as split testing, refers to a randomized
              experimentation process wherein two or more versions of a
              variable, steps, process (channel, creative, strategy, workflow)
              are shown to different segments of customers at the same time to
              determine which version leaves the maximum impact and drives
              business metrics.
            </Typography>
          </div>
          <div>
            <Typography className={classes.title}>
              Why should you consider A/B testing?
            </Typography>
            <Typography className={classes.description}>
              Your collection conversion determines the fate of your business.
              Therefore, every piece of content that reaches your target
              audience via must be optimized to its maximum potential. This is
              especially true for elements that have the potential to influence
              the behavior of your customers to increase the conversion rate.
            </Typography>
          </div>
          <div>
            <Typography className={classes.title}>
              What can you A/B test?
            </Typography>
            <Typography className={classes.description}>
              You can do split testing to compare two:
              <br /> 1. Campaigns (with different template or channels covered)
              <br /> 2. Strategies (with different template, channel, frequency,
              distribution or segment)
              <br /> 3. Workflows (with different communication, strategies and
              conditions)
            </Typography>
          </div>
        </Box>
        <Box width={350}>
          {Managers.map(
            ({
              name,
              img,
              customStyle,
            }: {
              name: string;
              img: string;
              customStyle: any;
            }) => (
              <Box
                style={{ ...customStyle }}
                className={classes.dashboardManagerWrapper}
                onClick={() => {
                  setCreateABTestingModal(true);
                }}
              >
                <Typography className={classes.cardTitle}>{name}</Typography>

                <img
                  src={img}
                  alt=""
                  style={{
                    maxHeight: "160px",
                    width: "100%",
                  }}
                />
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box className={classes.requirementWrapper}>
        <Typography className={classes.requirementTitle}>
          AB Testing Requirement
        </Typography>
        <Typography className={classes.requirementDescription}>
          Please provide a brief on requirement and we will get back to you with
          the quote & assistance.
        </Typography>
        <div className={classes.footerForm}>
          <Textarea
            value={quotes}
            onChange={onChangeQuotes}
            placeholder=""
            containerClass={classes.textAreaContainer}
          />
          <button
            className={classes.getQuotesButton}
            onClick={() => {
              handleSubmitQuotes();
              setQuotes("");
            }}
            disabled={!quotes}
          >
            <Typography>GET QUOTES</Typography>
          </button>
        </div>
      </Box>
      <BasicModal
        customBoxClassName={classes.popupbox}
        open={createABTestingModal}
        onClose={() => setCreateABTestingModal(false)}
        children={<CreateABTestingModal onSubmit={handleSubmitQuotes} />}
      />
      <BasicModal
        customBoxClassName={classes.popupbox}
        open={isSubmitted}
        onClose={() => setIsSubmitted(false)}
        children={<CongratulationsModal />}
      />
    </div>
  );
};

export default ABTestingScreen;
