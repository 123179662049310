import { Box, Typography } from '@mui/material';
// @ts-ignore
import { useSpeechSynthesis } from 'react-speech-kit';
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs';
import useStyles from './index.styles';
import './ivr.styles.css';
import { useLocation } from 'react-router-dom';

interface IvrPreviewProps {
  selectedTemplate?: any;
  isText?: boolean;
  text?: string;
}

const IvrPreview: React.FC<IvrPreviewProps> = ({
  isText,
  selectedTemplate,
  text,
}) => {
  const { speaking, speak, voices, cancel } = useSpeechSynthesis();

  const { pathname } = useLocation();
  const notModal = pathname === '/template-management/create' && true;
  const classes = useStyles({ notModal });

  return (
    <Box className={classes.ivrPreviewCont}>
      <Box className={classes.ivrPreviewMessageCont}>
        <Typography fontSize='0.9rem'>
          {isText ? text : selectedTemplate.template}
        </Typography>
      </Box>

      <Box className={classes.ivrPreviewPlayerCont}>
        <button
          type='button'
          onClick={() => {
            if (!speaking)
              speak({
                text: isText ? text : selectedTemplate.template,
                voice: voices[2],
              });
            if (speaking) cancel();
          }}
        >
          {!speaking ? (
            <BsPlayCircle fontSize={40} color='#6AC5AB' />
          ) : (
            <BsPauseCircle fontSize={40} color='#6AC5AB' />
          )}
        </button>

        <Box className={classes.ivrPreviewWaveBox}>
          {Array.from({ length: 21 }, (_, i) => i + 1).map((item) => {
            return (
              <span
                style={{
                  height: speaking ? '100%' : item % 2 === 0 ? '100%' : '50%',
                  width: '2px',
                  background: 'rgba(0, 67, 87, 0.37)',
                }}
                key={item}
                className={speaking ? 'stroke' : ''}
              ></span>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default IvrPreview;
