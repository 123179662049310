import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    multiTemplateButton: {
      fontSize: '1rem',
      padding: '0.5rem 1rem',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0px 1px 1px rgb(0 0 0 / 16%)',
      borderRadius: '5px',
    },
  })
);

export default useStyles;
