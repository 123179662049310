import { createAsyncThunk } from '@reduxjs/toolkit';
import workflowApi from 'apis/workflow';

import workflowEndpoint from 'apis/workflow/config';

export const getAllWorkflow = createAsyncThunk(
  `${workflowEndpoint.getWorkflow}Get`,
  async () => {
    const { data } = await workflowApi.getAllWorkflow();
    return data;
  }
);

export const createWorkflow = createAsyncThunk(
  `${workflowEndpoint.createWorkflow}Post`,
  async (payload: any) => {
    const { data } = await workflowApi.createWorkflow(payload);
    return data;
  }
);

export const getWorkflowById = createAsyncThunk(
  `${workflowEndpoint.getWorkflowById}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.getWorkflowById(payload);
    return data;
  }
);

export const workflowHandler = createAsyncThunk(
  `${workflowEndpoint.workflowHandler}Post`,
  async (payload: any) => {
    const { data } = await workflowApi.workflowHandler(payload);
    return data;
  }
);

export const getStrategyGroup = createAsyncThunk(
  `${workflowEndpoint.getStrategyGroup}Get`,
  async () => {
    const { data } = await workflowApi.getStrategyGroup();
    return data;
  }
);

export const getStrategyByGroupId = createAsyncThunk(
  `${workflowEndpoint.getStrategyByGroupId}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.getStrategyByGroupId(payload);
    return data;
  }
);

export const filterWorkflow = createAsyncThunk(
  `${workflowEndpoint.filterWorkflow}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.filterWorkflow(payload);
    return data;
  }
);

export const getFunnelMapping = createAsyncThunk(
  `${workflowEndpoint.getFunnelMapping}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.getFunnelMapping(payload);
    return data;
  }
);

export const getSpecificFunnel = createAsyncThunk(
  `${workflowEndpoint.getSpecificFunnel}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.getSpecificFunnel(payload);
    return data;
  }
);

export const workflowCustomerDetails = createAsyncThunk(
  `${workflowEndpoint.workflowCustomerDetails}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.workflowCustomerDetails(payload);
    return data;
  }
);

export const runWorkflow = createAsyncThunk(
  `${workflowEndpoint.runWorkflow}Post`,
  async (payload: any) => {
    const { data } = await workflowApi.runWorkflow(payload);
    return data;
  }
);

export const allWorkflowExecution = createAsyncThunk(
  `${workflowEndpoint.allWorkflowExecution}Get`,
  async (payload: any) => {
    const { data } = await workflowApi.allWorkflowExecution(payload);
    return data;
  }
);

export const getworkflowProperties = createAsyncThunk(
  `${workflowEndpoint.workflowProperties}Get`,
  async (payload: { action_type: string; module?: string }) => {
    const { data } = await workflowApi.getworkflowProperties(payload);
    return data;
  }
);
