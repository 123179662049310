import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardMainCont: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    dashboardWhiteBoardStyle: {
      background: theme.kollectAITheme.white,
      border: `1px solid ${theme.kollectAITheme.borderGrey}`,
      borderRadius: '5px',
    },
  })
);

export default useStyles;
