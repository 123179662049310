import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

import excelFile from 'assets/excel_file.svg';
import removeBig from 'assets/remove_big.svg';
import CampaignDragAndDrop from 'components/campaigns/DragAndDrop';
import { useAppDispatch } from 'hooks/useRedux';
import { integrationSampleFile } from 'store/reducer/IntegrationReducer/action';
import { errorToast } from 'utils/toast';

interface FormikCampaignSelectFileProps {
  name: string;
  subLabel?: string;
  placeholder?: string;
  width?: number | string;
}

const FormikCampaignSelectFileWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const DragAndDropWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '350px',
  marginBottom: '10px',
  '& p': {
    color: '#00C087',
    textDecoration: 'underline',
  },
}));

const CreatePreviewWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '500px',
  marginBottom: '2rem',
}));

const FormikCampaignSelectFile: React.FC<FormikCampaignSelectFileProps> = ({
  name,
}) => {
  const dispatch = useAppDispatch();

  const handleTemplateDownload = async () => {
    const { meta, data } = await dispatch(
      integrationSampleFile({ fileType: 'campaign' })
    ).unwrap();
    if (meta?.success) {
      const url = data?.url;
      if (url) {
        window.open(url);
      } else {
        errorToast('Download template failed');
      }
    } else {
      errorToast('Download template failed');
    }
  };

  return (
    <FormikCampaignSelectFileWrapper>
      <label>
        <Typography sx={{ minWidth: '250px', opacity: '0.6' }}>
          Target Population
        </Typography>
      </label>

      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          const size = +value?.size / 1024;

          return (
            <Box>
              <DragAndDropWrapper>
                <Typography>Upload from a file (csv)</Typography>
                <button type='button' onClick={handleTemplateDownload}>
                  <Typography>Sample Template</Typography>
                </button>
              </DragAndDropWrapper>

              {value ? (
                <CreatePreviewWrapper>
                  <Box display='flex' alignItems='center' gap='10px'>
                    <img src={excelFile} alt='' />
                    <Typography width='55%'>{(value as File).name}</Typography>
                    <button>
                      <Typography color='#00C087'>Download</Typography>
                    </button>
                  </Box>
                  <Typography>{size.toFixed(2)} kB</Typography>
                  <button onClick={() => setFieldValue('population', '')}>
                    <img src={removeBig} alt='' />
                  </button>
                </CreatePreviewWrapper>
              ) : (
                <>
                  <Box sx={{ height: '100px', width: '500px' }}>
                    <CampaignDragAndDrop
                      onFileChange={(files) => {
                        setFieldValue('population', files);
                      }}
                    />
                  </Box>
                  {meta.touched && meta.error && (
                    <Typography fontSize='0.8rem' color='#d32f2f'>
                      {meta.error}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          );
        }}
      </Field>
    </FormikCampaignSelectFileWrapper>
  );
};

export default FormikCampaignSelectFile;
