import { createAsyncThunk } from '@reduxjs/toolkit';

import authApi from '../../../apis/auth';
import authEndPoint from '../../../apis/auth/config';

export const login = createAsyncThunk(
  `${authEndPoint.login}Post`,
  async (payload: any) => {
    const { data } = await authApi.login(payload);
    return data;
  }
);

export const signup = createAsyncThunk(
  `${authEndPoint.signup}Post`,
  async (payload: any) => {
    const { data } = await authApi.signup(payload);
    return data;
  }
);

export const verifyEmailToken = createAsyncThunk(
  `${authEndPoint.verifyEmailToken}Get`,
  async (payload: any) => {
    const { data } = await authApi.verifyEmailToken(payload);
    return data;
  }
);

export const generateOtp = createAsyncThunk(
  `${authEndPoint.generateOtp}Post`,
  async (payload: any) => {
    const { data } = await authApi.generateOtp(payload);
    return data;
  }
);

export const resendOtp = createAsyncThunk(
  `${authEndPoint.resendOtp}Post`,
  async (payload: any) => {
    const { data } = await authApi.resendOtp(payload);
    return data;
  }
);

export const verifyOtp = createAsyncThunk(
  `${authEndPoint.verifyOtp}Post`,
  async (payload: any) => {
    const { data } = await authApi.verifyOtp(payload);
    return data;
  }
);

export const resendEmailVerification = createAsyncThunk(
  `${authEndPoint.resendEmailVerification}Post`,
  async (payload: any) => {
    const { data } = await authApi.resendEmailVerification(payload);
    return data;
  }
);

export const forgotPassword = createAsyncThunk(
  `${authEndPoint.forgotPassword}Post`,
  async (payload: any) => {
    const { data } = await authApi.forgotPassword(payload);
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  `${authEndPoint.resetPassword}Post`,
  async (payload: any) => {
    const { data } = await authApi.resetPassword(payload);
    return data;
  }
);

export const refreshToken = createAsyncThunk(
  `${authEndPoint.refreshToken}Post`,
  async (payload: any) => {
    const { data } = await authApi.refreshToken(payload);
    return data;
  }
);

export const getUserConfig = createAsyncThunk(
  `${authEndPoint.userConfig}Get`,
  async () => {
    const { data } = await authApi.getUserConfig();
    return data;
  }
);

export const duplicatePhoneCheck = createAsyncThunk(
  `${authEndPoint.duplicatePhoneCheck}Post`,
  async (payload: { phone_country_code: string; phone: string }) => {
    const { data } = await authApi.duplicatePhoneCheck(payload);
    return data;
  }
);

export const defaultSubscription = createAsyncThunk(
  `${authEndPoint.defaultSubscription}Post`,
  async (payload: { token: string; subscription_start_ts: string }) => {
    const { data } = await authApi.defaultSubscription(payload);
    return data;
  }
);

export const getSubscriptionConfig = createAsyncThunk(
  `${authEndPoint.subscriptionConfig}Get`,
  async () => {
    const { data } = await authApi.getSubscriptionConfig();
    return data;
  }
);
