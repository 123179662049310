import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import {
  FormikCustomerDisplay,
  FormikCustomerSelect,
  FormikCustomerTextarea,
} from 'components/formik/customer';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ActionButton, ActionButtonWrapper } from '../index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectedCustomersArr } from 'store/reducer/customerReducer';
import {
  customerQueueUpdate,
  getCustomerQueue,
} from 'store/reducer/customerReducer/action';

import Loader from 'components/loader';
import { createSelectOption } from 'utils/helper';
import { successToast } from 'utils/toast';

interface MoveToQueueFormProps {
  onClose: () => void;
}

const initials = {
  currentQueue: 'NA',
  newQueue: '',
  comments: '',
};

const validationSchema = yup.object().shape({
  newQueue: yup.string().required('Required'),
});

const MoveToQueueForm: React.FC<MoveToQueueFormProps> = ({ onClose }) => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  const customerOdfId = useAppSelector((state) => state.customer.customerOdfID);
  const queueData: any = useAppSelector(
    (state) => state.customer.customerQueueData
  );
  const selectedCustomers = useAppSelector(selectedCustomersArr);

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(initials);
  const [queueOptions, setQueueOptions] = useState<any[]>([]);

  useEffect(() => {
    if (queueData) {
      setInitialValues({
        currentQueue: queueData?.current_queue,
        newQueue: '',
        comments: '',
      });
      const options = createSelectOption({ arr: queueData?.client_queue });
      setQueueOptions(options);
    }
  }, [queueData]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const odf_customer_id =
        selectedCustomers && selectedCustomers?.length
          ? selectedCustomers?.map((customer) => customer?.odf_customer_id)
          : [customerOdfId];
      const payload = {
        // customer_id: id,
        queue_name: values.newQueue,
        odf_customer_id,
        comments: values.comments,
      };
      const res = await dispatch(customerQueueUpdate(payload));
      if (res?.payload) {
        await dispatch(getCustomerQueue({ customerId: id }));
        successToast('Queue has been updated successfully');
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          {selectedCustomers && selectedCustomers.length ? null : (
            <FormikCustomerDisplay
              name='currentQueue'
              label='Current Customer Queue'
            />
          )}

          <FormikCustomerSelect
            name='newQueue'
            label='Move Customer to'
            placeholder='Select Queue'
            options={queueOptions}
          />

          <FormikCustomerTextarea
            name='comments'
            label='Comments'
            labelDesc='Reason for change or generic notes'
          />

          <ActionButtonWrapper>
            <ActionButton type='submit' color='#6AC5AB'>
              <Typography>Update</Typography>
            </ActionButton>
            <ActionButton type='button' color='#DAA09A' onClick={onClose}>
              <Typography>Cancel</Typography>
            </ActionButton>
          </ActionButtonWrapper>
        </Form>
      </Formik>
    </>
  );
};

export default MoveToQueueForm;
