import { useState, useEffect, useMemo } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
// import useStyles from './index.styles';

import ViewCampaignTable from './table';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { groupBasedCustomerData } from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import {
  campaignCustomerType,
  campaignCutomerDataCategories,
} from 'utils/campaign/campaignModifier';
import CustomerDataCategory from './dataCategory';

const CampaignCustomerDataScreen = () => {
  // const classes = useStyles();
  const dispatch = useAppDispatch();

  const campaignData = useAppSelector((state: any) => state.campaign.campaign);
  const campaignPayload = useAppSelector(
    (state: any) => state.campaign.campaignPayload
  );

  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [groupType, setGroupType] = useState<string>('');
  const [customerData, setCustomerData] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const { type } = campaignCustomerType(campaignPayload?.add_filter);
    setGroupType(type);
    fetchCustomerData(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignPayload]);

  useEffect(() => {
    fetchCustomerData(groupType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const groupId = useMemo(
    () =>
      categories.find((el: any) => el.filter_name === selectedCategory)
        ?.group_id,
    [selectedCategory, categories]
  );

  const fetchCustomerData = async (type: string) => {
    if (type) {
      setLoading(true);
      try {
        const payload = {
          campaignId: campaignData?.campaign_id,
          groupType: type,
          limit: 10,
          offset: 0,
          groupId: groupId || '',
        };
        const { summary, customers } = await dispatch(
          groupBasedCustomerData(payload)
        ).unwrap();
        const categories = campaignCutomerDataCategories(summary);
        const total = Math.ceil(customers?.count / 10);
        setTotalPage(total);
        setCategories(categories);
        setCustomerData(customers.results || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handlePagination = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (groupType) {
      setLoading(true);
      const offset = 10 * (page - 1);
      const payload = {
        campaignId: campaignData?.campaign_id,
        groupType,
        limit: 10,
        offset,
        groupId: groupId || '',
      };
      const { customers } = await dispatch(
        groupBasedCustomerData(payload)
      ).unwrap();
      setCustomerData(customers.results || []);
      setCurrentPage(page);
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ margin: '2rem 1.5rem 0', '& p': { fontFamily: 'Overpass' } }}>
        <Box position='relative' mb='2rem'>
          <Typography fontSize='1.5rem' align='center'>
            Campaign Manager - Customer Data
          </Typography>
          <Box sx={{ position: 'absolute', right: 0, top: 5 }}>
            <Typography>
              Campaign Name: {campaignData?.campaign_name}
            </Typography>
            <Typography>Based on: {groupType}</Typography>
          </Box>
        </Box>

        <CustomerDataCategory
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        <Pagination
          count={totalPage}
          variant='outlined'
          shape='rounded'
          page={currentPage}
          onChange={handlePagination}
          sx={{
            margin: '1rem auto 10px',
            '.Mui-selected': {
              background: '#004357 !important',
              color: '#fff',
            },
          }}
        />

        <ViewCampaignTable data={customerData} />
      </Box>
    </>
  );
};

export default CampaignCustomerDataScreen;
