import React from 'react';
import useStyles from './index.styles';

export type LineChartLabelProps = {
  background: string;
  labelName: string;
};

const LineChartLabel: React.FC<LineChartLabelProps> = ({
  background,
  labelName,
}) => {
  const classes = useStyles({ background });
  return (
    <div className={classes.lineChartLabelWrapper}>
      <div className={classes.lineChartColorCircle}></div>
      <div>{labelName}</div>
    </div>
  );
};

export default LineChartLabel;
