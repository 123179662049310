import styled from '@emotion/styled';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { tableCellClasses } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const Circle = styled('div')({
  background: '#D9D9D9',
  border: '1px solid #004357',
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  zIndex: 10,
  position: 'relative',
});

export const Line = styled('div')(
  ({ index, actionLists }: { index: number; actionLists: any[] }) => ({
    '&:before': {
      position: 'absolute',
      borderLeft: '1px solid #004357',
      width: 0,
      height: '100%',
      display: 'block',
      content: '""',
      left: 8,
      top: index === 0 ? '50%' : index === actionLists.length - 1 ? '-50%' : 0,
    },
  })
);

export const ViewButton = styled('button')({
  '& p': {
    textDecoration: 'underline',
    color: kollectAITheme.primaryColor,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerActivityHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '10px',
      marginBottom: '1rem',

      '& p': {
        color: '#969696',
        fontStyle: 'italic',
      },

      '& img': {
        width: '26px',
      },
    },
    table: {
      minWidth: 650,
      [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none',
        padding: '10px 0',
      },
    },
    filterWrapper: {
      position: 'absolute',
      zIndex: 100,
      background: '#fff',
      width: 250,
      borderRadius: '10px',
      padding: '1rem',
      right: '5px',
      top: '34px',
      boxShadow:
        '0px 2px 9px rgb(50 71 92 / 6%), 0px 4px 9px 1px rgb(50 71 92 / 4%), 0px 2px 6px 4px rgb(50 71 92 / 2%)',
      '& p': { color: '#000 !important', fontStyle: 'normal !important' },
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
