import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import {
  AiOutlineClose,
  AiOutlineExclamationCircle,
  AiOutlineCheck,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css';

export const successToast = (
  message: string | React.ReactNode = 'Done!',
  options = {}
) => {
  const data = {
    title: 'Success Message',
    icon: AiOutlineCheck,
    iconColor: '#107A54',
    message,
    backgroundColor: '#e8fcf5',
  };
  toast(<Message toastData={data} />);
};

export const errorToast = (
  message: string | React.ReactNode = 'Something went wrong',
  options = {}
) => {
  const data = {
    title: 'Error Message',
    icon: AiOutlineExclamationCircle,
    iconColor: '#EA4643',
    message,
    backgroundColor: '#FBEFEE',
  };
  toast(<Message toastData={data} />);
};

export const infoToast = (message: string | React.ReactNode, options = {}) => {
  const data = {
    title: 'Information',
    icon: AiOutlineInfoCircle,
    iconColor: '#000',
    message,
    backgroundColor: '#EEF4FB',
  };
  toast(<Message toastData={data} />);
};

export const alertToast = (message: string | React.ReactNode, options = {}) => {
  const data = {
    title: 'Alert Message',
    icon: AiOutlineInfoCircle,
    iconColor: '#FFBC00',
    message,
    backgroundColor: '#FBF8EE',
  };
  toast(<Message toastData={data} />);
};

function Message(props: any) {
  const {
    title,
    icon: Icon,
    iconColor,
    message,
    backgroundColor,
  } = props.toastData;
  return (
    <Box sx={{ backgroundColor, borderRadius: '4px', padding: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
          <Icon color={iconColor} size={20} />
          <Typography fontWeight={500} color='#000' fontSize='1.1rem'>
            {title}
          </Typography>
        </Box>

        <button onClick={props.closeToast}>
          <AiOutlineClose size={18} style={{ marginBottom: '5px' }} />
        </button>
      </Box>

      <Box sx={{ margin: '0.5rem 0 0 2.1rem' }}>
        {typeof message === 'string' ? (
          <Typography>{message}</Typography>
        ) : (
          <>{message}</>
        )}
      </Box>
    </Box>
  );
}
