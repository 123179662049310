import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    groupWrapper: {
      display: 'grid',
      gridTemplateColumns: '40px 200px 1fr',
      alignItems: 'center',
      columnGap: '1rem',
      marginBottom: '1rem',
    },
    channelBoxCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1rem',
      gap: '1rem',
    },
  })
);

export default useStyles;
