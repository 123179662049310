import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const SubmitButton = styled('button')({
  background: kollectAITheme.primaryColor,
  borderRadius: '4px',
  color: '#fff',
  padding: '0.5rem 1rem',
  fontSize: '1rem',
});

export const ActionButton = styled('button')(
  ({ color }: { color: string }) => ({
    background: color,
    color: '#fff',
    padding: '8px 4rem',
    borderRadius: '8px',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalCotent: {
      backgroundColor: '#fff',
      outline: 'none',
      borderRadius: '10px',
    },
    contentTitle: {
      background: '#004357',
      borderRadius: '10px 10px 0px 0px',
      padding: '1rem 0',
      color: '#fff',
    },
    createDragAndDropCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '350px',
      marginBottom: '10px',
      '& p': {
        color: '#00C087',
        textDecoration: 'underline',
      },
    },
    createPreviewCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '500px',
      marginBottom: '2rem',
    },
    createCloseButton: {
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
      top: '-22px',
      '& svg': {
        fontSize: '1.8rem',
        color: '#004357',
      },
    },
    createCampaignText: {
      fontWeight: 600,
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    scheduleOptionsCont: {
      display: 'inline-flex',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
    scheduleOptions: {
      background: '#EBEEF2',
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 500,
      color: '#969696',
    },
    scheduleSelected: {
      color: '#fff',
      background: '#036482',
    },
    actionButtonCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      justifyContent: 'center',
      margin: '2rem auto 0',
    },
    rerunBody: {
      padding: '2rem 0',
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
    campaignNameCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      margin: '1rem auto 2rem',
    },
    campaignName: {
      background: '#E6E6E6',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      borderRadius: '2px',
      padding: '8px 16px',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  })
);

export default useStyles;
