import { Box, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import Select from 'react-select';
import useStyles from './index.styles';

interface MultiTemplateChannelSelectProps {
  selectedChannel: any;
  channelOptions: any;
  onChange: (x: any) => void;
}

const styles = {
  control: (styles: any) => ({
    ...styles,
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
    width: '290px',
    minHeigth: '35px',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#000',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#000',
  }),
  menuList: (styles: any) => ({ ...styles, padding: 0 }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};

const MultiTemplateChannelSelect: React.FC<MultiTemplateChannelSelectProps> = ({
  selectedChannel,
  channelOptions,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box className={classes.multiTemplateSelectCont}>
        <Box className={classes.multiTemplateSelectLabel}>
          <Typography>channel:</Typography>
        </Box>
        <Select
          placeholder='Select Channel'
          value={selectedChannel}
          options={channelOptions}
          onChange={(value) => onChange(value)}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={styles}
        />
      </Box>
    </Box>
  );
};

export default MultiTemplateChannelSelect;
