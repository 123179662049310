import { Box, Typography } from '@mui/material';

import { useAppSelector } from 'hooks/useRedux';
import { callRequestAttempts } from 'store/reducer/customerReducer';

const CallAttempt = () => {
  const callAttempts: any = useAppSelector(callRequestAttempts);

  if (!callAttempts) {
    return <Typography>-</Typography>;
  }

  return (
    <>
      {!callAttempts?.call_attempt ? (
        <Typography>No</Typography>
      ) : (
        <Box display='flex' alignItems='center' gap='1rem'>
          <Typography>Yes</Typography>
          <Box display='flex' alignItems='center' gap='5px'>
            <Typography color='#A8ABC3'>Attempts:</Typography>
            <Box display='flex' alignItems='center' gap='10px'>
              {callAttempts?.recent_call_logs.map(
                (log: boolean, index: number) => (
                  <Box
                    sx={{
                      height: 10,
                      width: 10,
                      background: log ? '#00C087' : '#BB7065',
                      borderRadius: '50%',
                    }}
                    key={index}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default CallAttempt;
