import { styled, Box, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { workflowHandler } from 'store/reducer/workflowReducer/action';

interface UnpublishedActivationModalProps {
  workflowId: string;
  onClose: () => void;
}

interface ButtonProps {
  backgroundColor: string;
}

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const Button = styled('button')((props: ButtonProps) => ({
  background: props.backgroundColor,
  color: '#fff',
  fontFamily: 'inherit',
  padding: '6px 30px',
  fontSize: '1rem',
  borderRadius: '3px',
}));

const UnpublishedActivationModal: React.FC<UnpublishedActivationModalProps> = ({
  workflowId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePublish = async () => {
    try {
      // setLoading(true);
      await dispatch(
        workflowHandler({
          workflow_id: [workflowId],
          action: 'publish',
        })
      ).unwrap();
      navigate('/workflow');
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  return (
    <>
      <Typography align='center' fontSize='1.2rem' sx={{ fontWeight: 600 }}>
        Trying to activate the workflow?
      </Typography>

      <Box sx={{ maxWidth: '450px', margin: '2rem auto' }}>
        <Typography sx={{ fontWeight: 600, opacity: '0.5' }}>
          A draft workflow cannot get activated. In order to activate the
          workflow, please publish it first and retry.
        </Typography>
      </Box>

      <ButtonWrapper>
        <Button backgroundColor='#6AC5AB' onClick={handlePublish}>
          Publish
        </Button>
        <Button backgroundColor='#DAA09A' onClick={onClose}>
          Cancel
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default UnpublishedActivationModal;
