import api from '../api';
import kollectAiDashboard from './config';

const dashboardApi = {
  async dashboardPortfolio(payload: any) {
    return api.post(kollectAiDashboard.dashboardProtfolio, payload);
  },
};

export default dashboardApi;
