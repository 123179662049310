import { Box } from '@mui/material';
import ChannelSummaryHeader from './Header';
import ChannelSummaryCalendar from './Calendar';

interface ChannelSummaryProps {
  strategyGroup: any;
}

const ChannelSummary: React.FC<ChannelSummaryProps> = ({ strategyGroup }) => {
  return (
    <Box padding='0 2rem'>
      <ChannelSummaryHeader strategyGroup={strategyGroup} />
      <ChannelSummaryCalendar strategyGroup={strategyGroup} />
    </Box>
  );
};

export default ChannelSummary;
