import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import './styles.css';

interface ObservationOptionsList {
  id?: number;
  name: string;
}

type CustomSelectProps = {
  optionsList?: ObservationOptionsList[];
  selected?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  optionsList = [],
  selected,
}) => {
  const [showOptionList, setShowOptionList] = useState(false);
  const [selectedOption, setSelectedOption] = useState(optionsList[0]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setShowOptionList(false);
  };

  const handleListDisplay = () => setShowOptionList((state) => !state);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className='dashboardDayWiseCustomSelectContainer'>
        <div
          className={
            showOptionList
              ? 'dashboardDayWiseSelectedText active'
              : 'dashboardDayWiseSelectedText'
          }
          onClick={handleListDisplay}
        >
          {selectedOption?.name}
          <BsChevronDown />
        </div>

        {showOptionList && (
          <ul className='dashboardDayWiseSelectOptions'>
            {optionsList?.map((option) => {
              return (
                <div key={option.id}>
                  <li
                    className={`dashboardDayWiseCustomSelectOption ${
                      option.id === selectedOption.id
                        ? 'dashboardDayWiseCustomOptionSelected'
                        : ''
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.name}
                  </li>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
