import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import useStyles from "./template.styles";

import { useAppDispatch } from "hooks/useRedux";
import { viewChannelTemplate } from "store/reducer/templateReducer/action";

import Loader from "components/loader";
import TemplateChannelSelectBox from "./ChannelSelectBox";
import { concatenatedTemplate } from "utils/helper";

import TemplateAddFilter from "./templateAddFilter";
import TemplatePreviews from "./templatePreviews";
import TemplateManagementHeader from "./header";
import TemplateActions from "./templateActions";
import TemplateTable from "./table";

const templateChannels = [
  { channel: "email", icon: "emailTemplate" },
  { channel: "sms", icon: "smsTemplate" },
  { channel: "whatsapp", icon: "whatsapp" },
  { channel: "ivr", icon: "ivrTemplate" },
];

const Template = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<string>("email");
  const [templatesData, setTemplatesData] = useState<any>({});
  const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState<any>("");
  const [tableData, setTableData] = useState<any>([]);
  const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);
  const [showTwoWay, setShowTwoWay] = useState<string>("");
  const [previewTemplate, setPreviewTemplate] = useState<any>({});

  const classes = useStyles({ isTemplateSelected });

  useEffect(() => {
    const { state } = location;
    if (state) {
      const { channel } = state as { channel: string };
      setSelectedChannel(channel);
      getAllTemplates(channel);
    } else {
      getAllTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTemplates.length && selectedTemplates.length === 1) {
      setIsTemplateSelected(true);
    } else {
      setIsTemplateSelected(false);
    }
  }, [selectedTemplates]);

  useEffect(() => {
    setSelectedTemplates([]);
  }, [selectedChannel]);

  const getAllTemplates = async (stateChannel?: string) => {
    setLoading(true);
    try {
      const { data, meta } = await dispatch(
        viewChannelTemplate({ channel_type: "all" })
      ).unwrap();
      if (meta?.success) {
        let templates: any = {};
        let channel = stateChannel ?? selectedChannel;
        for (let key in data) {
          const template = concatenatedTemplate(data[key]);
          templates = { ...templates, [key]: template };
        }
        setTemplatesData(templates);
        const options = templates[channel]?.map((template: any) => ({
          label: template.template_name,
          value: template.id,
        }));

        if (options) {
          let data = templates[channel];
          if (showTwoWay === channel)
            data = data.filter((item: any) => item.two_way_flag);
          setTableData(data);
          setFilterOptions(options);
        } else {
          setTableData([]);
          setFilterOptions([]);
        }

        // updating selected template after template update
        if (location.state?.isTemplateCreatedUpdated) {
          const updatedTemplate = templates[channel]
            ?.slice()
            ?.sort(
              (a: any, b: any) =>
                new Date(b?.update_ts).valueOf() -
                new Date(a?.update_ts).valueOf()
            )[0];
          setSelectedTemplates(updatedTemplate ? [updatedTemplate] : []);
        } else {
          setSelectedTemplates([]);
        }
      }
    } finally {
      setLoading(false);
      window.history.replaceState({}, "");
    }
  };

  const handleChannelChange = (channel: string) => {
    const options = templatesData[channel]?.map((template: any) => ({
      label: template.template_name,
      value: template.id,
    }));
    setSelectedTemplates([]);
    setSelectedChannel(channel);
    setTableData(templatesData[channel]);
    setFilterOptions(options);
    setSelectedFilterOption("");
  };

  const templateSearchChange = (value: any) => {
    if (value) {
      const template = templatesData[selectedChannel].find(
        (item: any) => item.id === value.value
      );
      setSelectedFilterOption(value);
      setTableData([template]);
    } else {
      setSelectedFilterOption("");
      setTableData(templatesData[selectedChannel]);
    }
  };

  const handleTwoWay = () => {
    if (showTwoWay !== selectedChannel) {
      const data = tableData.filter((item: any) => item.two_way_flag);
      setTableData(data);
      setShowTwoWay(selectedChannel);
    } else {
      setTableData(templatesData[selectedChannel]);
      setShowTwoWay("");
    }
  };

  const deleteReset = () => {
    setSelectedTemplates([]);
    setSelectedFilterOption("");
    getAllTemplates();
  };

  return (
    <Box className={classes.templateWrapper}>
      <Loader loading={loading} />

      <TemplateManagementHeader
        className={classes.addMultipleTemplateButton}
        getAllTemplates={getAllTemplates}
      />

      <Box className={classes.templateContainer}>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
          {templateChannels.map((item, index) => {
            const { channel, icon } = item;
            return (
              <TemplateChannelSelectBox
                icon={icon}
                channel={channel}
                selected={selectedChannel === channel ? true : false}
                onClick={() => handleChannelChange(channel)}
                key={index}
              />
            );
          })}
        </Box>

        <Box sx={{ background: "#fff", padding: "2rem" }}>
          <Grid container spacing={6}>
            <Grid
              item
              xs={selectedChannel === "email" ? 6 : 8}
              marginTop={isTemplateSelected ? "45px" : ""}
            >
              <TemplateAddFilter
                selectedFilterOption={selectedFilterOption}
                filterOptions={filterOptions}
                templateSearchChange={templateSearchChange}
                showTwoWay={showTwoWay}
                selectedChannel={selectedChannel}
                onSelectTwoWay={handleTwoWay}
              />

              {/* tables */}
              <Box
                id="template_table_container"
                className={classes.templateTableCont}
                sx={{
                  height: isTemplateSelected
                    ? "calc(100vh - 440px)"
                    : "calc(100vh - 370px)",
                }}
              >
                <TemplateTable
                  selectedChannel={selectedChannel}
                  data={tableData}
                  selectedTemplates={selectedTemplates}
                  setSelectedTemplates={setSelectedTemplates}
                  setPreviewTemplate={setPreviewTemplate}
                  previewTemplate={previewTemplate}
                />
              </Box>

              <TemplateActions
                selectedChannel={selectedChannel}
                selectedTemplates={selectedTemplates}
                setLoading={setLoading}
                deleteReset={deleteReset}
              />
            </Grid>

            <Grid item xs={selectedChannel === "email" ? 6 : 4}>
              <TemplatePreviews
                selectedChannel={selectedChannel}
                selectedTemplates={selectedTemplates}
                previewTemplate={previewTemplate}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Template;
