import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PermissionHeader, RoleLink } from './styles';

import { PermissionLists } from './tables/permissionTable';
import Loader from 'components/loader';
import { useAppDispatch } from 'hooks/useRedux';
import {
  getClientConfig,
  getPermissionSummary,
} from 'store/reducer/permissionReducer/action';

export const PermissionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPermissions = React.useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch(getPermissionSummary({ limit: 100, offset: 0 })),
        dispatch(getClientConfig()),
      ]);
    } catch (error) {}
    setLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <>
      <Loader loading={loading} />
      <Box
        sx={{
          margin: '2rem 2rem 0',
          '& p': { color: '#32475CDE', fontFamily: 'Open sans' },
        }}
      >
        <Box>
          <PermissionHeader>
            <Typography fontSize='1.5rem' fontWeight={500}>
              Permissions List
            </Typography>

            <RoleLink onClick={() => navigate('/roles')}>
              <Typography>Users & Roles List</Typography>
            </RoleLink>
          </PermissionHeader>

          <Typography mt='5px' color='#32475C99'>
            A permission is a set of rights that will help you create a
            permission group in order to assign to one or more roles. You can
            have one permission per role or can assign same to many.
          </Typography>
        </Box>

        <PermissionLists />
      </Box>
    </>
  );
};
