import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';

type SelectProps = {
  name: string;
  placeholder?: string;
  width?: number;
  options: any;
  disabled?: boolean;
};

export const FormikRolesSelect: React.FC<SelectProps> = ({
  name,
  placeholder = '',
  width = 335,
  options,
  disabled = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const { value, name } = field;
        return (
          <FormControl sx={{ width, zIndex: 10 }}>
            <Select
              displayEmpty
              value={value || ''}
              onChange={(event: SelectChangeEvent) => {
                const { value } = event.target;
                setFieldValue(name, value);
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                borderRadius: '6px',
                border: '1px solid rgba(50, 71, 92, 0.22)',
                background: disabled ? '#F0F0F0' : '#fff',
                '.MuiSelect-select': { padding: '10px 10px 8px 14px' },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.04)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.04)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
              disabled={disabled}
            >
              <MenuItem disabled value=''>
                <Typography color='#757575'>{placeholder}</Typography>
              </MenuItem>
              {options?.map((type: any, index: number) => (
                <MenuItem value={type.value} key={index}>
                  {type?.label}
                </MenuItem>
              ))}
            </Select>
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f !important'>
                {meta.error}
              </Typography>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};
