import { Box, Typography } from '@mui/material';
import {
  FormikCustomerInput,
  FormikCustomerSelect,
} from 'components/formik/customer';
import useStyles from './index.styles';

const easierPayOptions = [
  { label: 'Change in repayment date', value: 'Change in repayment date' },
  { label: 'Better communication in advance', value: 'Partial Payment' },
  { label: 'Partial Payment', value: 'Partial Payment' },
];
const reasonOptions = [
  { label: 'Lost job', value: 'Lost job' },
  { label: 'Family Emergency', value: 'Family Emergency' },
  { label: 'Financial Dependency', value: 'Financial Dependency' },
  { label: 'Financial Loss', value: 'Financial Loss' },
  { label: 'Forgot Dates', value: 'Forgot Dates' },
];

const Hardship = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.wrapper}>
        <Typography>What is your monthly EMI?</Typography>
        <FormikCustomerInput
          name='monthly_emi'
          placeholder='EMI'
          type='number'
          width={180}
        />
      </Box>

      <Box className={classes.wrapper}>
        <Typography>What is your monthly income from all sources?</Typography>
        <FormikCustomerInput
          name='monthly_income'
          placeholder='Income'
          type='number'
          width={180}
        />
      </Box>

      <FormikCustomerSelect
        name='easier_pay'
        label='What would make it easier for you to pay?'
        placeholder='Select'
        options={easierPayOptions}
      />
      <FormikCustomerSelect
        name='reason'
        label='Please help us understand the reason of your delinquency?'
        placeholder='Select'
        options={reasonOptions}
      />
    </Box>
  );
};

export default Hardship;
