import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import calendarImg from 'assets/calendar.svg';

interface FormikCustomerDatePickerProps {
  name: string;
  minDate?: Date | null;
}

const DatePickeWrapper = styled('div')({
  position: 'relative',
  marginTop: '0.5rem',
  width: 200,
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',

  '& .react-datepicker__input-container input': {
    width: '185px !important',
    background: 'transparent !important',
    cursor: 'default',
    zIndex: 10,
    position: 'relative',
    padding: '0.5rem',
  },
});

const FormikCustomerDatePicker = ({
  name,
  minDate = null,
}: FormikCustomerDatePickerProps) => {
  return (
    <>
      <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box>
              <DatePickeWrapper>
                <DatePicker
                  id={name}
                  {...field}
                  selected={value}
                  minDate={minDate}
                  onChange={(val) => setFieldValue(name, val)}
                />
                <Box sx={{ position: 'absolute', right: '10px', top: '7px' }}>
                  <img src={calendarImg} alt='' width={25} />
                </Box>
              </DatePickeWrapper>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </>
  );
};

export default FormikCustomerDatePicker;
