import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import * as yup from 'yup';

import Loader from 'components/loader';
import { Form, Formik, FormikValues } from 'formik';
import { FormikAuthPassword } from 'components/formik/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useAppDispatch } from 'hooks/useRedux';
import { createNewUserPassword } from 'store/reducer/userReducer/action';
import { setVerifyEmailData } from 'store/reducer/auth';

const UpdateButton = styled('button')(() => ({
  width: '100%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
  marginTop: '1rem',
}));

const LoginWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  marginTop: '1rem',
  '& p': { fontWeight: 500 },
});

const validationSchema = yup.object().shape({
  phone: yup.string().required('Required'),
  password: yup.string().required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords doesnot match')
    .required('Required'),
});

const InvitationSignup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const clientName = searchParams.get('client_name');
  const twoFa = searchParams.get('two_f_a');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [phoneTouched, setPhoneTouched] = React.useState<boolean>(false);

  const handleSubmit = async (values: FormikValues) => {
    if (token) {
      setLoading(true);
      try {
        const { phone, countryCode, password, confirmPassword } = values;
        const payload = {
          token,
          phone,
          phone_country_code: countryCode,
          new_password: password,
          confirm_new_password: confirmPassword,
        };
        const { meta } = await dispatch(
          createNewUserPassword(payload)
        ).unwrap();
        if (meta?.success) {
          if (twoFa === 'True') {
            dispatch(
              setVerifyEmailData({
                token,
                phone,
                phone_country_code: countryCode,
                phone_verified: false,
              })
            );
            navigate('/otp-verification');
          } else {
            navigate('/login');
          }
        }
      } catch (error) {
        // console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box>
        <Box mb='2rem'>
          <Typography
            fontWeight={600}
            color='rgba(50, 71, 92, 0.87)'
            fontSize='1.4rem'
          >
            Just a step away
          </Typography>
          <Typography mt='5px' color='rgba(50, 71, 92, 0.6)'>
            Please enter below details to create your account under{' '}
            {clientName ?? `{{ company_name }}`}
          </Typography>
        </Box>

        <Formik
          initialValues={{
            fullNumber: '',
            phone: '',
            countryCode: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleChange, handleBlur }) => (
            <Form>
              <Box mb='1.2rem'>
                <label>
                  <Typography mb='5px'>Phone Number</Typography>
                </label>
                <PhoneInput
                  country={'in'}
                  value={values.fullNumber}
                  countryCodeEditable={false}
                  onChange={(e, country: any) => {
                    const phoneNumber = e.replace(country?.dialCode, '');
                    setFieldValue('countryCode', country.dialCode);
                    setFieldValue('phone', phoneNumber);
                    handleChange(e);
                    setPhoneTouched(true);
                  }}
                  onBlur={handleBlur}
                />
                {values.phone === '' && phoneTouched && (
                  <Typography
                    sx={{ marginTop: 0, color: '#d32f2f', fontSize: '0.8em' }}
                  >
                    Required
                  </Typography>
                )}
              </Box>

              <FormikAuthPassword
                name='password'
                label='Password'
                placeholder='Type your password'
                show={showPassword}
                handleShow={() => setShowPassword(!showPassword)}
              />

              <Box mt='1rem' mb='1rem'>
                <FormikAuthPassword
                  name='confirmPassword'
                  label='Confirm your password'
                  placeholder='Confirm Password'
                  show={showConfirm}
                  handleShow={() => setShowConfirm(!showConfirm)}
                />
              </Box>

              <UpdateButton onClick={() => {}}>
                <Typography>Create Account</Typography>
              </UpdateButton>

              <LoginWrapper>
                <Typography>Already have an account?</Typography>
                <Typography
                  sx={{ cursor: 'pointer', color: '#004357' }}
                  onClick={() => navigate('/login')}
                >
                  Log in instead
                </Typography>
              </LoginWrapper>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default InvitationSignup;
