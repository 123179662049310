import { Box, Typography } from '@mui/material';
import moment from 'moment';

import emailImg from 'assets/comm_email.svg';
import ivrImg from 'assets/comm_ivr.svg';
import smsImg from 'assets/comm_sms.svg';
import whatsappImg from 'assets/comm_whatsapp.svg';

const LastCommunication = ({
  lastCommunication,
}: {
  lastCommunication: any;
}) => {
  const lastComm =
    moment(lastCommunication.latest_date).format('D MMM, YYYY') !==
    'Invalid date'
      ? moment(lastCommunication.latest_date).format('D MMM, YYYY')
      : 'NA';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        {'email' in lastCommunication.channels && <img src={emailImg} alt='' />}
        {'sms' in lastCommunication.channels && <img src={smsImg} alt='' />}
        {'whatsapp' in lastCommunication.channels && (
          <img src={whatsappImg} alt='' />
        )}
        {'ivr' in lastCommunication.channels && <img src={ivrImg} alt='' />}
      </Box>
      <Typography>{lastComm}</Typography>
    </>
  );
};
export default LastCommunication;
