import { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';

interface FormikTemplateInputProps {
  label?: string;
  name: string;
  width?: number;
  placeholder?: string;
  small?: boolean;
  disabled?: boolean;
  type?: string;
  handleUpdate?: (x: string, y: string, z: string) => void;
  onBoxClick?: () => void;
}

const FormikTemplateInputWrapper = styled('div')(
  ({
    label,
    small,
    disabled,
    inputWidth,
  }: {
    label?: string;
    small: boolean;
    disabled: boolean;
    inputWidth?: number;
  }) => ({
    marginBottom: label ? '1rem' : 0,
    display: 'grid',
    gridTemplateColumns: !label ? '122px' : small ? '80px 150px' : '200px 1fr',
    alignItems: 'center',

    '& label': {
      marginBottom: '0.3rem',
      color: kollectAITheme.primaryColor,
      '& p': {
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        fontWeight: 700,
      },
    },
    '& input': {
      background: disabled ? 'rgba(150, 150, 150, 0.11)' : '#fff',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: disabled ? 'unset' : 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '3px 0.7rem 0',
      fontFamily: 'Open Sans',
      outline: 'none',
      fontSize: small ? '0.8rem' : '1rem',
      minHeight: small ? '32px' : '38px',
      textAlign: disabled ? 'center' : 'left',
      // width: inputWidth || '96%',
      width: small ? '100px' : '96%',
    },

    // '@media (min-width: 1801px)': {
    //   gridTemplateColumns: !label
    //     ? '220px !important'
    //     : small
    //     ? '80px 150px'
    //     : '200px 1fr',
    // },
    // '@media (min-width: 1601px)': {
    //   gridTemplateColumns: !label
    //     ? '174px'
    //     : small
    //     ? '80px 150px'
    //     : '200px 1fr',
    // },
  })
);

const FormikTemplateInput: React.FC<FormikTemplateInputProps> = ({
  label,
  name,
  width,
  type = 'text',
  placeholder = '',
  small = false,
  disabled = false,
  handleUpdate,
  onBoxClick,
}) => {
  const [previousValue, setPreviousValue] = useState<string>('');
  return (
    <FormikTemplateInputWrapper
      label={label}
      small={small}
      inputWidth={width}
      disabled={disabled}
      onClick={onBoxClick}
    >
      {label && (
        <label>
          <Typography>{label}</Typography>
        </label>
      )}
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value, name } = field;

          return (
            <Box width='100%'>
              <input
                type={type}
                placeholder={placeholder}
                {...field}
                value={value}
                onChange={(e) => {
                  const regex = /^[0-9]$/;
                  if (
                    type === 'number' &&
                    e.target.value &&
                    !regex.test(e.target.value)
                  )
                    return;
                  setFieldValue(name, e.target.value);
                  if (e.target.value) {
                    handleUpdate &&
                      handleUpdate(e.target.value, value, previousValue);
                    setPreviousValue(value);
                  }
                }}
                disabled={disabled}
              />
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </FormikTemplateInputWrapper>
  );
};

export default FormikTemplateInput;
