import * as React from "react";
import Box from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import useStyles from "./style";

export type InputProps = {
  children?: React.ReactNode;
  open?: boolean;
  onClose?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customBoxClassName?: any;
  disableEnforceFocus?: any;
};

const BasicModal: React.FC<InputProps> = ({
  children,
  open = false,
  onClose,
  customBoxClassName,
  disableEnforceFocus = false,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.basicModalStyle}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Box
        component={"div"}
        className={`${classes.boxStyle} ${customBoxClassName}`}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default BasicModal;
