import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getStrategyDetails,
  getStrategyList,
  strategyChannelTemplate,
  // strategySummary,
  // strategyDistribution,
  strategyDetailsFilter,
} from "./action";

const initialState = {
  // strategySummary: {},
  // strategyDistribution: [],
  strategyDetailsFilter: {},
  strategyEmailTemplate: [],
  strategySMSTemplate: [],
  strategyIVRTemplate: [],
  strategyWhatsappTemplate: [],
  strategyList: [],
  sliderValue: 0,
  activeDpd: "",
  activeProduct: "",
  activeAmount: "",
};

export const StratgeyDetailSlice = createSlice({
  name: "strategyDetail",
  initialState,
  reducers: {
    changeSliderValue(state, action: PayloadAction<number>) {
      state.sliderValue = action.payload;
    },
    changeActiveDpd(state, action: PayloadAction<string>) {
      state.activeDpd = action.payload;
    },
    changeActiveProduct(state, action: PayloadAction<string>) {
      state.activeProduct = action.payload;
    },
    changeActiveAmount(state, action: PayloadAction<string>) {
      state.activeAmount = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(strategySummary.fulfilled, (state, { payload }) => {
    //   const { meta, data } = payload as any;
    //   if (meta?.success === true) {
    //     state.strategySummary = data[0];
    //   }
    // });
    // builder.addCase(strategyDistribution.fulfilled, (state, { payload }) => {
    //   const { meta, data } = payload as any;
    //   if (meta?.success === true) {
    //     state.strategyDistribution = data;
    //   }
    // });
    builder.addCase(strategyDetailsFilter.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (meta?.message === "success") {
        state.strategyDetailsFilter = data;
      }
    });

    builder.addCase(getStrategyDetails.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (meta?.message === "success") {
        state.strategyDetailsFilter = data;
      }
    });

    builder.addCase(strategyChannelTemplate.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (meta?.message === "success") {
        if (data.email) state.strategyEmailTemplate = data.email;
        if (data.sms) state.strategySMSTemplate = data.sms;
        if (data.ivr) state.strategyIVRTemplate = data.ivr;
        if (data.whatsapp) state.strategyWhatsappTemplate = data.whatsapp;
      }
    });
    builder.addCase(getStrategyList.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.message === "success") {
        state.strategyList = data;
      }
    });
  },
});

export const {
  changeSliderValue,
  changeActiveDpd,
  changeActiveProduct,
  changeActiveAmount,
} = StratgeyDetailSlice.actions;

// export { strategySummary, strategyDistribution };

// export const strategySummaryData = (state: any) =>
//   state.strategyDetail.strategySummary;
// export const strategyDistributionData = (state: any) =>
//   state.strategyDetail.strategyDistribution;
export const strategyDetailsFilterData = (state: any) =>
  state.strategyDetail.strategyDetailsFilter;
export const strategyEmailTemplate = (state: any) =>
  state.strategyDetail.strategyEmailTemplate;
export const strategySMSTemplate = (state: any) =>
  state.strategyDetail.strategySMSTemplate;
export const strategyIVRTemplate = (state: any) =>
  state.strategyDetail.strategyIVRTemplate;
export const strategyWhatsappTemplate = (state: any) =>
  state.strategyDetail.strategyWhatsappTemplate;
export const strategyList = (state: any) => state.strategyDetail.strategyList;
export const strategySliderValue = (state: any) =>
  state.strategyDetail.sliderValue;

export default StratgeyDetailSlice.reducer;
