import { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStyles from './index.styles';

import { campaignGroupFilter } from 'store/reducer/uploadedFileDetailReducer';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import { getStrategyDetails } from 'store/reducer/strategyReducer/action';

import Loader from 'components/loader';
import ChannelSummary from './ChannelSummary';
import ABTestSummary from './ABTestSummary';
import StrategyDetailsHeader from './Header';

interface StrategyDetailsProps {
  strategyGroup: any;
}

const StrategyDetails: React.FC<StrategyDetailsProps> = ({ strategyGroup }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const id = strategyGroup?.strategy_detail[0]['strategy_id'];
  const groupId = strategyGroup?.strategy_group_id;

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );

  const selectedStrategy = strategyGroup?.strategy_detail?.find(
    (strategy: any) => strategy.strategy_id === id
  );

  const strategySummaryData =
    strategyDetailsData?.strategy_summary?.[0] ||
    strategyDetailsData?.strategy_summary;

  useEffect(() => {
    getStrategyData();
  }, []);

  const getStrategyData = async () => {
    setLoading(true);
    try {
      await dispatch(viewChannelTemplate({ channel_type: 'all' }));
      await dispatch(campaignGroupFilter({ client_id: 1 })).unwrap();
      if (selectedStrategy) {
        const balanceLevel = ['0', null].includes(
          selectedStrategy?.balance_level
        )
          ? '0'
          : selectedStrategy?.balance_level;
        const product = ['0', null].includes(selectedStrategy?.product)
          ? '0'
          : selectedStrategy?.product;
        const bucket = selectedStrategy?.bucket ? selectedStrategy?.bucket : 0;

        await dispatch(
          getStrategyDetails({
            query: `strategy_group_id=${
              groupId || ''
            }&balance_level=${balanceLevel}&product=${product}&bucket=${bucket}`,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!groupId)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80%',
        }}
      >
        <Typography fontSize='1.5rem'>No such strategy found</Typography>
      </Box>
    );

  return (
    <>
      <Loader loading={loading} />

      <Box className={classes.strategyContentContainer}>
        <StrategyDetailsHeader
          strategy={selectedStrategy}
          strategyGroup={strategyGroup}
        />
        <Divider />

        {strategySummaryData?.ab_testing_flag ? (
          <ABTestSummary strategyGroup={strategyGroup} />
        ) : (
          <ChannelSummary strategyGroup={strategyGroup} />
        )}
      </Box>
    </>
  );
};

export default StrategyDetails;
