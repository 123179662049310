import { createStyles, makeStyles } from '@material-ui/core';
import { styled } from '@mui/material';

export const SplitBox = styled('div')({
  display: 'flex',
  gap: '2rem',
  alignItems: 'center',
  marginBottom: '0.5rem',

  '& input': {
    padding: '0 1rem',
    fontFamily: 'Overpass',
    outline: 'none',
    width: '40px',
    background: 'inherit',
    color: '#fff',
    fontSize: '16px',
    minHeight: '38px',
    border: 'transparent',
    textAlign: 'center',
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    splitPercentInput: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #EBEBEB',
      borderRadius: '4px',
      paddingRight: '1rem',
    },
    splitActionButtons: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      '& svg': {
        fontSize: '1.4rem',
        cursor: 'pointer',
      },
    },
    splitDistributionCont: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',

      '& p': {
        border: '1px solid #A8ABC3',
        padding: '10px 0',
        textAlign: 'center',
      },
    },
  })
);

export default useStyles;
