import { createAsyncThunk } from '@reduxjs/toolkit';

import campaignApi from 'apis/campaign';
import campaignEndpoint from 'apis/campaign/config';

export const campaignSummary = createAsyncThunk(
  `${campaignEndpoint.campaignSummary}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignSummary(payload);
    return data;
  }
);

// export const filterCampaignSummary = createAsyncThunk(
//   `${campaignEndpoint.campaignSummary}Get`,
//   async (payload) => {
//     const { data } = await campaignApi.filterCampaignSummary(payload);
//     return data;
//   }
// );

export const createCampaignV2 = createAsyncThunk(
  `${campaignEndpoint.createCampaignV2}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.createCampaignV2(payload);
    return data;
  }
);

export const getCampaignData = createAsyncThunk(
  `${campaignEndpoint.createCampaignV2}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.getCampaignData(payload);
    return data;
  }
);

export const updateAbTesting = createAsyncThunk(
  `${campaignEndpoint.createCampaignV2}Patch`,
  async (payload: any) => {
    const { data } = await campaignApi.updateAbTesting(payload);
    return data;
  }
);

export const addABTesting = createAsyncThunk(
  `${campaignEndpoint.addABTesting}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.addABTesting(payload);
    return data;
  }
);

export const addChannelTemplate = createAsyncThunk(
  `${campaignEndpoint.addChannelTemplate}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.addChannelTemplate(payload);
    return data;
  }
);

export const updateChannelTemplate = createAsyncThunk(
  `${campaignEndpoint.addChannelTemplate}Patch`,
  async (payload: any) => {
    const { data } = await campaignApi.updateChannelTemplate(payload);
    return data;
  }
);

export const campaignAbTesting = createAsyncThunk(
  `${campaignEndpoint.addCampaignAbTesting}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.campaignAbTesting(payload);
    return data;
  }
);

export const groupBasedCustomerData = createAsyncThunk(
  `${campaignEndpoint.groupBasedCustomerData}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.groupBasedCustomerData(payload);
    return data;
  }
);

export const getCampaignDetails = createAsyncThunk(
  `${campaignEndpoint.campaignDetails}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.getCampaignDetails(payload);
    return data;
  }
);

// custom filters
export const getCustomFilter = createAsyncThunk(
  `${campaignEndpoint.campaignCustomFilter}Get`,
  async (payload: any) => {
    const { data } = await campaignApi.getCustomFilter(payload);
    return data;
  }
);

export const getCampaignFilterAttributes = createAsyncThunk(
  `${campaignEndpoint.campaignFilterAttributes}Get`,
  async () => {
    const { data } = await campaignApi.getCampaignFilterAttributes();
    return data;
  }
);

export const addCustomFilter = createAsyncThunk(
  `${campaignEndpoint.campaignCustomFilter}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.addCustomFilter(payload);
    return data;
  }
);

export const updateCustomFilter = createAsyncThunk(
  `${campaignEndpoint.campaignCustomFilter}Patch`,
  async (payload: any) => {
    const { data } = await campaignApi.updateCustomFilter(payload);
    return data;
  }
);

export const deleteCustomFilter = createAsyncThunk(
  `${campaignEndpoint.campaignCustomFilter}Delete`,
  async (payload: any) => {
    const { data } = await campaignApi.deleteCustomFilter(payload);
    return data;
  }
);

export const deleteCampaign = createAsyncThunk(
  `${campaignEndpoint.deleteCampaign}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.deleteCampaign(payload);
    return data;
  }
);

export const rerunCampaign = createAsyncThunk(
  `${campaignEndpoint.rerunCampaign}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.rerunCampaign(payload);
    return data;
  }
);

export const copyCampaignWithoutFile = createAsyncThunk(
  `${campaignEndpoint.copyCampaignWithoutFile}Post`,
  async (payload: any) => {
    const { data } = await campaignApi.copyCampaignWithoutFile(payload);
    return data;
  }
);

export const getCampaignCommStatus = createAsyncThunk(
  `${campaignEndpoint.campaignCommStatus}Get`,
  async (payload: { campaignId: string; executionId: string }) => {
    const { data } = await campaignApi.campaignCommStatus(payload);
    return data;
  }
);

export const getDayTimePenetration = createAsyncThunk(
  `${campaignEndpoint.dayTimePenetration}Get`,
  async (payload: {
    executionId: string;
    campaignId: string;
    slot: number;
    channel: string;
    limit: number;
    offset: number;
  }) => {
    const { data } = await campaignApi.getDayTimePenetration(payload);
    return data;
  }
);

export const updateBasedOn = createAsyncThunk(
  `${campaignEndpoint.changeBasedOn}Patch`,
  async (payload: { campaign_id: string; based_on_group_type: string }) => {
    const { data } = await campaignApi.changeBasedOn(payload);
    return data;
  }
);

export const getCampaignSummary = createAsyncThunk(
  `${campaignEndpoint.campaignData}Get`,
  async (payload: {
    search_type?: string;
    query?: string;
    status_value?: string;
    limit: number;
    offset: number;
  }) => {
    const { data } = await campaignApi.getCampaignSummary(payload);
    return data;
  }
);

export const getCampaignExecutionList = createAsyncThunk(
  `${campaignEndpoint.campaignExecutionList}Get`,
  async (payload: { campaign_id: string }) => {
    const { data } = await campaignApi.getCampaignExecutionList(payload);
    return data;
  }
);

export const getCampaignCustomerDetail = createAsyncThunk(
  `${campaignEndpoint.campaignCustomerDetail}Get`,
  async (payload: {
    campaign_id: string;
    customer_id: string;
    loan_id: string;
  }) => {
    const { data } = await campaignApi.getCampaignCustomerDetail(payload);
    return data;
  }
);

export const customerResegmentation = createAsyncThunk(
  `${campaignEndpoint.customerResegmentation}Post`,
  async (payload: { campaign_id: string }) => {
    const { data } = await campaignApi.customerResegmentation(payload);
    return data;
  }
);
