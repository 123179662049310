import * as React from 'react';
import { Typography } from '@mui/material';
import { AddRoleText, AddRoleWrapper, RoleButton } from './styles';

import addRoleImg from 'assets/add-role.svg';
import { CreateEditRole } from '../modal';

export const AddRole = () => {
  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleModalClose = () => {
    setOpenCreate(false);
  };

  return (
    <>
      <AddRoleWrapper onClick={() => setOpenCreate(true)}>
        <img src={addRoleImg} alt='' />

        <RoleButton>
          <Typography>Add Role</Typography>
        </RoleButton>

        <AddRoleText>Add new role, if it doesn't exist.</AddRoleText>
      </AddRoleWrapper>

      <CreateEditRole open={openCreate} onClose={handleModalClose} />
    </>
  );
};
