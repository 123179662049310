import { Typography } from '@mui/material';

export const HeaderMenuButton = ({
  menu,
  onClick,
}: {
  menu: string;
  onClick: () => void;
}) => {
  return (
    <button onClick={onClick}>
      <Typography>{menu}</Typography>
    </button>
  );
};
