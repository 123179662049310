import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperStyle: {
      top: '188px !important',
      left: '117px !important',
      minWidth: '305px !important',
    },
    selectStyle: {
      padding: '10px',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      outline: 'none',
      borderRadius: 0,

      '& .MuiSelect-select': {
        opacity: 0,
      },
    },
    actionButtonCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1rem auto 0.5rem',

      '& p': {
        textDecoration: 'underline',
      },
    },
  })
);

export default useStyles;
