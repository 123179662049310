import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

function createData(
  group: string,
  filterCondition: string,
  channel: string,
  totalCustomer: number,
  totalCommunication: number,
  totalConnected: number
) {
  return {
    group,
    filterCondition,
    channel,
    totalCustomer,
    totalCommunication,
    totalConnected,
  };
}

const rows = [
  createData('Group Name 1', 'Filter 1', 'SMS', 240, 430, 340),
  createData('One time Campaign', 'Filter 2', 'Whatsapp', 370, 543, 120),
  createData('Group Name 3', 'Filter 3', 'Email', 254, 670, 300),
];

const CampaignDashboardDetails = () => {
  return (
    <Box sx={{ padding: '1.5rem 2rem', minHeight: '70vh' }}>
      <Typography
        sx={{ fontWeight: 600, fontSize: '1.4rem', marginBottom: '1.5rem' }}
      >
        Attempt Details
      </Typography>
      <Box
        sx={{
          boxShadow:
            'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
          border: '1px solid rgba(150, 150, 150, 0.11)',
          borderRadius: '10px',
          padding: '0.9em 1.5em',
          maxWidth: '122px',
          margin: '0 auto 1rem',
        }}
      >
        <Typography sx={{ fontWeight: 500, marginBottom: '10px' }}>
          Total Customers
        </Typography>
        <Typography sx={{ color: '#FFB600' }} align='center'>
          7
        </Typography>
      </Box>
      <Box sx={{ padding: '0.5em 0' }}>
        <Typography sx={{ margin: '10px 14px', fontWeight: 600 }}>
          Details
        </Typography>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                fontSize: '0.8em',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Group</TableCell>
                <TableCell align='left'>Filter Condition</TableCell>
                <TableCell align='left'>Channel</TableCell>
                <TableCell align='left'>Total Customer</TableCell>
                <TableCell align='left'>Total Communication</TableCell>
                <TableCell align='left'>Total Connected</TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {row.group}
                  </TableCell>
                  <TableCell align='left'>{row.filterCondition}</TableCell>
                  <TableCell align='left'>{row.channel}</TableCell>
                  <TableCell align='left'>{row.totalCustomer}</TableCell>
                  <TableCell align='left'>{row.totalCommunication}</TableCell>
                  <TableCell align='left'>{row.totalConnected}</TableCell>
                  <TableCell align='left'>
                    <button
                      style={{
                        background: '#2C62EC',
                        border: 'transparent',
                        color: '#fff',
                        padding: '7px 20px',
                        borderRadius: '10px',
                        fontSize: '0.9em',
                        cursor: 'pointer',
                      }}
                    >
                      Download Data
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CampaignDashboardDetails;
