import { createAsyncThunk } from '@reduxjs/toolkit';

import integrationApi from 'apis/integration';
import integrationEndpoint from 'apis/integration/config';

export const generateApiKey = createAsyncThunk(
  `${integrationEndpoint.generateApiKey}Post`,
  async (payload: any) => {
    const { data } = await integrationApi.generateApiKey(payload);
    return data;
  }
);

export const uploadDataApi = createAsyncThunk(
  `${integrationEndpoint.uploadDataApi}Get`,
  async (payload: any) => {
    const { data } = await integrationApi.uploadDataApi(payload);
    return data;
  }
);

export const integrationSampleFile = createAsyncThunk(
  `${integrationEndpoint.integrationSampleFile}Get`,
  async (payload: { fileType: string }) => {
    const { data } = await integrationApi.integrationSampleFile(payload);
    return data;
  }
);
