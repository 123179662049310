import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strategyDetailsHeader: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, auto)',
      placeItems: 'center',
      marginBottom: '20px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

export default useStyles;
