import * as React from 'react';
import Chart from 'react-apexcharts';
import { useAppSelector } from 'hooks/useRedux';
import { penetrationData } from 'store/reducer/campaignReducer';
import { getPenetrationGraphData } from 'utils/campaign/campaignModifier';
import { SlotDuration } from '.';

export default function PenetrationGraph({ slot }: { slot: SlotDuration }) {
  const [series, setSeries] = React.useState([
    {
      name: '0000-0300',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 5 },
        { x: ['Wed', 'D+2'], y: 5 },
        { x: ['Thur', 'D+3'], y: 5 },
        { x: ['Fri', 'D+4'], y: 5 },
        { x: ['Sat', 'D+5'], y: 5 },
        { x: ['Sun', 'D+6'], y: 5 },
      ],
    },
    {
      name: '0300-0600',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 45 },
        { x: ['Wed', 'D+2'], y: 10 },
        { x: ['Thur', 'D+3'], y: 10 },
        { x: ['Fri', 'D+4'], y: 10 },
        { x: ['Sat', 'D+5'], y: 10 },
        { x: ['Sun', 'D+6'], y: 10 },
      ],
    },
    {
      name: '0600-0900',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 10 },
        { x: ['Wed', 'D+2'], y: 45 },
        { x: ['Thur', 'D+3'], y: 20 },
        { x: ['Fri', 'D+4'], y: 20 },
        { x: ['Sat', 'D+5'], y: 20 },
        { x: ['Sun', 'D+6'], y: 10 },
      ],
    },
    {
      name: '0900-1200',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 10 },
        { x: ['Wed', 'D+2'], y: 20 },
        { x: ['Thur', 'D+3'], y: 45 },
        { x: ['Fri', 'D+4'], y: 45 },
        { x: ['Sat', 'D+5'], y: 20 },
        { x: ['Sun', 'D+6'], y: 10 },
      ],
    },
    {
      name: '1200-1500',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 10 },
        { x: ['Wed', 'D+2'], y: 20 },
        { x: ['Thur', 'D+3'], y: 45 },
        { x: ['Fri', 'D+4'], y: 45 },
        { x: ['Sat', 'D+5'], y: 20 },
        { x: ['Sun', 'D+6'], y: 10 },
      ],
    },
    {
      name: '1500-1800',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 10 },
        { x: ['Wed', 'D+2'], y: 20 },
        { x: ['Thur', 'D+3'], y: 20 },
        { x: ['Fri', 'D+4'], y: 20 },
        { x: ['Sat', 'D+5'], y: 45 },
        { x: ['Sun', 'D+6'], y: 10 },
      ],
    },
    {
      name: '1800-2100',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 10 },
        { x: ['Wed', 'D+2'], y: 10 },
        { x: ['Thur', 'D+3'], y: 10 },
        { x: ['Fri', 'D+4'], y: 10 },
        { x: ['Sat', 'D+5'], y: 10 },
        { x: ['Sun', 'D+6'], y: 45 },
      ],
    },
    {
      name: '2100-0000',
      data: [
        { x: ['Mon', 'D+0'], y: 5 },
        { x: ['Tue', 'D+1'], y: 5 },
        { x: ['Wed', 'D+2'], y: 5 },
        { x: ['Thur', 'D+3'], y: 5 },
        { x: ['Fri', 'D+4'], y: 5 },
        { x: ['Sat', 'D+5'], y: 5 },
        { x: ['Sun', 'D+6'], y: 5 },
      ],
    },
  ]);
  // const [first, setfirst] = useState(second)

  const dayTimeData = useAppSelector(penetrationData);

  React.useEffect(() => {
    if (dayTimeData) {
      const dayTimeRes = dayTimeData?.distribution?.results;
      const penetrationSeries = getPenetrationGraphData(dayTimeRes, slot);
      setSeries(penetrationSeries);
    }
  }, [dayTimeData]);

  return (
    <div>
      <Chart
        options={{
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: { show: false },
            fontFamily: 'Open Sans',
          },
          dataLabels: { enabled: false },
          tooltip: {
            enabled: true,
            x: {
              show: true,
            },
            // y: {
            //   formatter: function (
            //     value,
            //     { series, seriesIndex, dataPointIndex, w }
            //   ) {
            //     return value;
            //   },
            // },
          },
          colors: ['#115180'],
          xaxis: {
            labels: { style: { colors: 'rgba(50, 71, 92, 0.38)' } },
            axisBorder: { show: false },
            axisTicks: { show: false },
            tooltip: { enabled: false },
          },
          yaxis: { labels: { style: { colors: 'rgba(50, 71, 92, 0.38)' } } },
          plotOptions: { heatmap: { radius: 4 } },
          states: {
            hover: { filter: { type: 'none' } },
            active: { filter: { type: 'none' } },
          },
        }}
        series={series}
        type='heatmap'
        height={350}
      />
    </div>
  );
}
