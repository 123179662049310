import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeBoxWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      filter: 'drop-shadow(0px 4px 4px rgba(50, 71, 92, 0.06))',
      borderRadius: '8px',
      backgroundColor: '#fff',
      padding: '0.5rem 0.8rem',
      marginTop: '10px',
      '& input': {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        fontFamily: 'Overpass',
        fontSize: '1rem',
        '&::placeholder': {
          fontFamily: 'Overpass',
          color: '#32475C61',
        },
      },
    },
    typeBoxIconCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.8rem',
      '& svg': {
        color: '#32475CDE',
        fontSize: '1.5rem',
      },
    },
  })
);

export default useStyles;
