import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProceedButton = styled('button')(() => ({
  marginTop: '2rem',
  width: '95%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const EmailVerfied = () => {
  const navigate = useNavigate();

  return (
    <>
      <Typography
        fontWeight={600}
        color='rgba(50, 71, 92, 0.87)'
        fontSize='1.4rem'
      >
        Successfully Verified ✉️
      </Typography>

      <Typography mt='5px' color='rgba(50, 71, 92, 0.6)'>
        Congratulations! Your email has been successfully verified. Please
        proceed with your phone number verification.
      </Typography>

      <ProceedButton onClick={() => navigate('/otp-verification')}>
        <Typography>Proceed</Typography>
      </ProceedButton>
    </>
  );
};

export default EmailVerfied;
