import api from '../api';
import kollectAiFBConnect from './config';

export interface FBData {
  phone_number_id: string;
  waba_id: string;
  token: string;
  fb_login_response: fb.StatusResponse;
}

const fbConnectApi = {
  async saveFBData(payload: FBData) {
    return api.post(kollectAiFBConnect.saveFBData, payload);
  },
};

export default fbConnectApi;
