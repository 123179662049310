import { useState, useEffect, useCallback, memo } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { CiCalendar } from 'react-icons/ci';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useStyles from './index.styles';

// import { workflowData } from 'constants/workflowData';
import { reactSelectStyles } from 'components/formik/workflow/reactSelectStyles';
import { isDateValue, createSelectOption } from 'utils/helper';
import { getGraphData } from 'utils/workflow/graphHandler';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getworkflowProperties } from 'store/reducer/workflowReducer/action';
import { workflowAttributes } from 'store/reducer/workflowReducer';

interface AttibuteFieldsProps {
  inputList: any;
  setInputList: (x: any) => void;
}

const AttibuteFields: React.FC<AttibuteFieldsProps> = ({
  inputList,
  setInputList,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [stateUpdated, setStateUpdated] = useState(false);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [startDate, setStartDate] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [listOptions, setListOptions] = useState<any>({});
  const [addFormula, setAddFormula] = useState<any>({ 0: true });
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const allowedUpdateAttributes = useAppSelector(workflowAttributes)?.update;

  const handleremove = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAdd = () => {
    setInputList([...inputList, { attribute: '', value: '' }]);
  };

  const fetchUpdateAttributes = useCallback(async () => {
    try {
      !allowedUpdateAttributes &&
        (await dispatch(getworkflowProperties({ action_type: 'update' })));
    } catch (error) {}
  }, [allowedUpdateAttributes, dispatch]);

  useEffect(() => {
    fetchUpdateAttributes();
  }, [fetchUpdateAttributes]);

  useEffect(() => {
    if (allowedUpdateAttributes) {
      const options: any = createSelectOption({
        arr: allowedUpdateAttributes,
        isObj: true,
        objKey: 'attribute',
      });
      setAttributeOptions(options);
      if (!stateUpdated && inputList[0].attribute) {
        const { published } = getGraphData();
        const pub: boolean = published === 1 ? true : false;
        setIsPublished(pub);
        const selOpt: any[] = [];
        let attributeOpt = {};
        inputList.forEach((element: any, i: number) => {
          const attributeObj: any = (allowedUpdateAttributes as any[]).find(
            (item: any) => item.attribute === element.attribute
          );

          if (attributeObj) {
            if (attributeObj.type === 'List') {
              const listOpt = createSelectOption({
                arr: JSON.parse(attributeObj.allowed_values),
              });
              attributeOpt = { ...attributeOpt, [i]: listOpt };
            }

            if (attributeObj.type === 'Boolean') {
              const listOpt = [
                { label: 'True', value: 1 },
                { label: 'False', value: 0 },
              ];
              attributeOpt = { ...attributeOpt, [i]: listOpt };
              setListOptions(options);
            }

            if (attributeObj.type === 'Date') {
              console.log({ element }, isDateValue(element.value));
              const dateReg = /^\d{4}-\d{2}-\d{2}$/;
              // if (isDateValue(element.value)) {
              if (dateReg.test(element.value)) {
                const date = moment(element.value).toDate();
                setStartDate((prev: any) => ({ ...prev, [i]: date }));
                setAddFormula((prev: any) => ({ ...prev, [i]: false }));
                // setAddFormula((prev: any) => ({ ...prev, [i]: true }));
              } else {
                // attributeObj.value = '';
                // const date = moment(element.value).toDate();
                // setStartDate((prev: any) => ({ ...prev, [i]: date }));
                // setAddFormula((prev: any) => ({ ...prev, [i]: false }));
                setAddFormula((prev: any) => ({ ...prev, [i]: true }));
              }
            }

            selOpt.push(attributeObj);
          }
        });
        setListOptions(attributeOpt);
        setSelectedOption(selOpt);
        setStateUpdated(true);
      }
    }
  }, [allowedUpdateAttributes, inputList]);

  const handleAddFormula = (event: any, index: number) => {
    const newAddFormula = { ...addFormula, [index]: event.target.checked };

    setAddFormula(newAddFormula);
    const list: any = [...inputList];
    list[index]['value'] = '';
    setInputList(list);
    setStartDate((prev: any) => ({ ...prev, [index]: null }));
  };

  const defaultValue = (options: any, value: string) => {
    return options ? options.find((option: any) => option.value === value) : '';
  };

  return (
    <Box>
      <Typography mb='0.5rem'>Update Attribute(s)</Typography>
      {inputList.map((x: any, i: number) => {
        return (
          <Box key={i} mb='0.5rem'>
            <Select
              placeholder='Select Attribute'
              options={attributeOptions}
              value={defaultValue(attributeOptions, inputList[i]['attribute'])}
              onChange={(value: any) => {
                if (allowedUpdateAttributes) {
                  const attributeObj: any = (
                    allowedUpdateAttributes as any[]
                  ).find((item: any) => item.attribute === value.value);

                  const option: any = [...selectedOption];
                  option[i] = attributeObj;

                  const list: any = [...inputList];
                  list[i]['attribute'] = value.value;
                  list[i]['value'] = '';
                  setInputList(list);
                  setSelectedOption(option);

                  if (attributeObj.type === 'List') {
                    const listOpt = createSelectOption({
                      arr: JSON.parse(attributeObj.allowed_values),
                    });
                    setListOptions((prev: any) => ({ ...prev, [i]: listOpt }));
                  }

                  if (attributeObj.type === 'Boolean') {
                    const listOpt = [
                      { label: 'True', value: 1 },
                      { label: 'False', value: 0 },
                    ];
                    setListOptions((prev: any) => ({ ...prev, [i]: listOpt }));
                  }
                }
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={reactSelectStyles}
              isDisabled={isPublished}
            />

            {selectedOption[i] && (
              <Box
                sx={{
                  width: '80%',
                  margin: '0 auto',
                  padding: '10px',
                  background: 'rgba(0, 0, 0, 0.25)',
                  alignItems: 'center',
                  borderRadius: '0px 0px 5px 5px',
                }}
              >
                {selectedOption[i]['type'] === 'Number' && (
                  <div>
                    <input
                      type='text'
                      className={classes.attributeFieldInput}
                      placeholder='Provide value of the attribute'
                      value={inputList[i]['value']}
                      onChange={(e) => {
                        const list: any = [...inputList];
                        list[i]['value'] = e.target.value;
                        setInputList(list);
                      }}
                      disabled={isPublished}
                    />
                  </div>
                )}

                {selectedOption[i]['type'] === 'List' && (
                  <div>
                    <Select
                      placeholder='Select value'
                      options={listOptions[i]}
                      value={defaultValue(
                        listOptions[i],
                        inputList[i]['value']
                      )}
                      onChange={(value: any) => {
                        const list: any = [...inputList];
                        list[i]['value'] = value.value;
                        setInputList(list);
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={reactSelectStyles}
                      isDisabled={isPublished}
                    />
                  </div>
                )}

                {selectedOption[i]['type'] === 'Boolean' && (
                  <div>
                    <Select
                      placeholder='Select value'
                      options={listOptions[i]}
                      value={defaultValue(
                        listOptions[i],
                        inputList[i]['value']
                      )}
                      onChange={(value: any) => {
                        const list: any = [...inputList];
                        list[i]['value'] = value.value;
                        setInputList(list);
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={reactSelectStyles}
                      isDisabled={isPublished}
                    />
                  </div>
                )}

                {selectedOption[i]['type'] === 'Date' && (
                  <Box className={classes.attributeFieldDatePickerWrapper}>
                    {!addFormula[i] ? (
                      <Box className={classes.attributeFieldDatePicker}>
                        <DatePicker
                          selected={startDate[i]}
                          onChange={(date: Date) => {
                            const list: any = [...inputList];
                            list[i]['value'] =
                              moment(date).format('YYYY-MM-DD');
                            setInputList(list);
                            setStartDate((prev: any) => ({
                              ...prev,
                              [i]: date,
                            }));
                          }}
                          readOnly={isPublished}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '7px',
                          }}
                        >
                          <CiCalendar />
                        </Box>
                      </Box>
                    ) : (
                      <input
                        type='text'
                        className={classes.attributeFieldInput}
                        placeholder='Enter value'
                        value={inputList[i]['value']}
                        onChange={(e) => {
                          const list: any = [...inputList];
                          list[i]['value'] = e.target.value;
                          setInputList(list);
                        }}
                        disabled={isPublished}
                      />
                    )}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addFormula[i]}
                            onChange={(e) => handleAddFormula(e, i)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disableRipple={true}
                            disabled={isPublished}
                          />
                        }
                        label='Add Formula'
                      />
                    </FormGroup>
                  </Box>
                )}
              </Box>
            )}

            {selectedOption[i] &&
              selectedOption[i]['type'] === 'Date' &&
              addFormula[i] && (
                <Box fontSize='12px'>
                  <p>Relative Formula Usage</p>
                  <ul style={{ marginLeft: '25px' }}>
                    <li>For today: CURRENT_DATE</li>
                    <li>For 5 days before today: CURRENT_DATE-5</li>
                    <li>For 10 days after today: CURRENT_DATE+10</li>
                  </ul>
                </Box>
              )}

            <Box display='flex' justifyContent='center' mt='0.5rem'>
              {inputList.length !== 1 && (
                <button
                  type='button'
                  onClick={() => handleremove(i)}
                  disabled={isPublished}
                >
                  <AiFillMinusCircle color=' #DAA09A' fontSize='1.5rem' />
                </button>
              )}
              {inputList.length - 1 === i && (
                <button
                  type='button'
                  onClick={handleAdd}
                  disabled={isPublished}
                >
                  <AiFillPlusCircle color='#6ac5ab' fontSize='1.5rem' />
                </button>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default memo(AttibuteFields);
