import { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { VerifyEmailModal } from 'components/auth/modals';
import { useAppDispatch } from 'hooks/useRedux';
import { resendEmailVerification } from 'store/reducer/auth/actions';
import { errorToast } from 'utils/toast';
import Loader from 'components/loader';

const ProceedButton = styled('button')(() => ({
  marginTop: '2rem',
  width: '95%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const LinkExpired = ({ email }: { email: string | undefined }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [openVerify, setOpenVerify] = useState<boolean>(false);

  const handleResend = async () => {
    if (email) {
      setLoading(true);
      const { meta } = await dispatch(
        resendEmailVerification({ email })
      ).unwrap();
      if (meta?.success) {
        setOpenVerify(true);
      } else {
        errorToast(meta?.message || 'Internal server error');
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Loader loading={loading} />

      <Box>
        <Typography
          fontWeight={600}
          color='rgba(50, 71, 92, 0.87)'
          fontSize='1.4rem'
        >
          Link Expired! ✉️
        </Typography>

        <Typography mt='5px' color='rgba(50, 71, 92, 0.6)'>
          Hey it seems your verification link has been expired. Please click
          resend link to get a fresh email link for verification
        </Typography>

        <ProceedButton onClick={() => handleResend}>
          <Typography>Resend Link</Typography>
        </ProceedButton>
      </Box>

      <VerifyEmailModal
        open={openVerify}
        email={email || ''}
        onClose={() => setOpenVerify(false)}
      />
    </>
  );
};

export default LinkExpired;
