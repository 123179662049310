import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateWrapper: {
      margin: '1rem 0 0',
    },
    addMultipleTemplateButton: {
      position: 'absolute',
      right: '1rem',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      background: '#41B895',
      border: '1px solid #E0E7FF',
      color: '#fff',
      padding: '0.2rem 1rem',
      borderRadius: '5px',

      '& svg': {
        fontSize: '1rem',
      },
    },
    templateContainer: {
      margin: '1rem auto',
      maxWidth: '95%',
    },

    templateSelectBoxIconStyle: {
      width: '40px',
      background: theme.kollectAITheme.primaryColor,
      borderRadius: '10px',
      display: ' flex',
      justifyContent: 'center',
      alignItems: ' center',
      height: '40px',
      marginRight: '1.5em',
    },

    templateTableCont: {
      margin: '1rem 0 2rem',
      // maxHeight: 'calc(100vh - 370px)',
      // height: 'calc(100vh - 370px)',
      overflowY: 'auto',
    },

    templatePreviewCont: {
      // height: (props: any) =>
      //   `calc(100% - ${props.isTemplateSelected ? '120px' : '80px'})`,
      height: (props: any) =>
        `calc(100vh - ${props.isTemplateSelected ? '380px' : '300px'})`,
    },
    templatePreviewText: {
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      background: 'rgba(150, 150, 150, 0.08)',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
    },
    templateRightContButtonCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '2rem',
      gap: '1rem',
    },
    templateDownloadCont: {
      display: 'flex',
      justifyContent: 'end',
      marginBottom: '10px',
    },
    templateDownloadBox: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '7px',
      padding: '5px 10px',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useStyles;
