import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AiOutlineEye } from 'react-icons/ai';
import { useAppSelector } from 'hooks/useRedux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import useStyles, { StrategyName } from './index.styles';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { getGraphData } from 'utils/workflow/graphHandler';
import BasicModal from 'components/Modal';
import StrategyDetails from './strategy';

import WorkflowActionButtons from '../actionButtons';

interface RunStrategyDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const RunStrategyDrawer: React.FC<RunStrategyDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const classes = useStyles();

  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const strategyList = useAppSelector(
    (state: any) => state.strategy.strategyList
  );

  const graphData: any = getGraphData();

  const [strategyModal, setStrategyModal] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [strategyName, setStrategyName] = useState<string>(
    workflowActionData?.strategy
  );

  const strategyOptions = strategyList?.map((item: any) => ({
    value: item.strategy_group_name,
    label: item.strategy_group_name,
  }));

  useEffect(() => {
    const strategyGroup = strategyList.find(
      (list: any) => list.strategy_group_name === workflowActionData?.strategy
    );
    setSelectedGroup(strategyGroup);
  }, [strategyList, workflowActionData]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName, strategy } = values;
    const configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'RUN_STRATEGY',
          strategy,
        },
      ];
      let data = [...oldData, ...newData];
      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'strategy');
      updateWorkflow(configObj, keys, values.strategy);
    }

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      strategy: yup.string().required('Required'),
    })
  );

  return (
    <>
      <Formik
        initialValues={{
          stepName: workflowActionData?.name,
          precursorName: workflowActionData?.parent,
          strategy: workflowActionData?.strategy,
        }}
        validationSchema={validation}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form
            style={{
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <CustomFormikWorkflowInput
                label='Step Name'
                name='stepName'
                placeholder='Step Name'
                disabled={workflowEditor === 'create' ? false : true}
              />

              <CustomFormikWorkflowInput
                label='Precursor Name'
                name='precursorName'
                placeholder='Precursor Name'
                disabled={true}
              />

              <Box position='relative'>
                <CustomFormikWorkflowSelect
                  name='strategy'
                  placeholder='Select Strategy'
                  label='Select Strategy'
                  onChange={(value) => {
                    const strategyGroup = strategyList.find(
                      (list: any) => list.strategy_group_name === value
                    );
                    setSelectedGroup(strategyGroup);
                    setStrategyName(value);
                  }}
                  options={strategyOptions}
                />
                <AiOutlineEye
                  className={classes.startegyView}
                  onClick={() => {
                    if (selectedGroup) setStrategyModal(true);
                  }}
                />
              </Box>
            </Box>

            {!graphData.published && (
              <WorkflowActionButtons
                setLoading={setLoading}
                updateJsonConfig={updateJsonConfig}
              />
            )}
          </Form>
        )}
      </Formik>

      <BasicModal
        open={strategyModal}
        onClose={() => {
          setStrategyModal(false);
          setSelectedGroup(null);
        }}
        children={
          <Box width='95vw' height='80vh' overflow='auto'>
            <StrategyName>Strategy: {strategyName}</StrategyName>
            <StrategyDetails strategyGroup={selectedGroup} />
          </Box>
        }
      />
    </>
  );
};

export default RunStrategyDrawer;
