import notEngagedImg from 'assets/customer_not_engaged.svg';
import ptpImg from 'assets/customer_ptp.svg';
import ptpBorkenImg from 'assets/customer_ptp_broken.svg';

export const statusData = [
  {
    label: 'New or Uncategorised',
    valueKey: 'not_categorized',
    icon: 'customer_uncategoriesed',
    color: '#004357',
  },
  {
    label: 'PTP',
    valueKey: 'ptp',
    icon: 'customer_ptp',
    color: '#0069aa',
    svg: ptpImg,
  },
  {
    label: 'PTP Broken',
    valueKey: 'ptp_broken',
    icon: 'customer_ptp_broken',
    color: '#bb7065',
    svg: ptpBorkenImg,
  },
  {
    label: 'Call Required',
    valueKey: 'call_required',
    icon: 'customer_call_required',
    color: '#00C087',
  },
  {
    label: 'Hardship',
    valueKey: 'hardship',
    icon: 'customer_hardship',
    color: '#ED9144',
  },
  {
    label: 'Not Engaged',
    valueKey: 'not_engaged',
    icon: 'customer_not_engaged',
    color: '#notEngagedImg',
    svg: notEngagedImg,
    svgWidth: 22,
  },
  {
    label: 'Bad Contact',
    valueKey: 'bad_contact',
    icon: 'customer_bad_contact',
    color: '#FF646B',
  },
];
