import { kollectAITheme } from 'config/theme';

export const searchStyles = {
  control: (styles: any) => ({
    ...styles,
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
    width: '290px',
    minHeigth: '35px',
    fontFamily: 'Overpass !important',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#000',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#000',
    fontFamily: 'Overpass !important',
    fontSize: '0.9rem !important',
  }),
  menuList: (styles: any) => ({ ...styles, padding: 0 }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
      fontFamily: 'Overpass !important',
      fontSize: '1rem !important',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};
