import { Box, styled, Typography } from '@mui/material';
import { useField } from 'formik';

interface CustomInputProps {
  label: string;
  inputWidth?: number;
  [x: string]: any;
}

const FormikInputWrapper = styled('div')(
  ({ inputWidth }: { inputWidth: number }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    opacity: '0.5',

    '& label': {
      minWidth: '130px',
    },

    '& input': {
      border: '1px solid #EBEBEB',
      color: '#000',
      borderRadius: '4px',
      padding: '0.5rem',
      fontFamily: 'Overpass',
      outline: 'none',
      minWidth: inputWidth,
    },
    '& ::placeholder': {
      color: '#000',
    },
  })
);

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  inputWidth = 275,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <FormikInputWrapper inputWidth={inputWidth}>
      <label>
        <Typography color='#2F2F2F'>{label}</Typography>
      </label>
      <Box>
        <input {...field} {...props} />
        {meta.touched && meta.error && (
          <Box
            sx={{
              fontSize: '0.8rem',
              color: '#d32f2f',
            }}
          >
            {meta.error}
          </Box>
        )}
      </Box>
    </FormikInputWrapper>
  );
};

export default CustomInput;
