import { createAsyncThunk } from '@reduxjs/toolkit';

import fbConnectApi, { FBData } from 'apis/fbconnect';
import fbConnectEndpoint from 'apis/fbconnect/config';

export const saveFBData = createAsyncThunk(
  `${fbConnectEndpoint.saveFBData}Post`,
  async (payload: FBData) => {
    const { data } = await fbConnectApi.saveFBData(payload);
    return data;
  }
);
