import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ivrPreviewCont: {
      height: (props: any) => (props.notModal ? 'calc(100vh - 270px)' : '100%'),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    ivrPreviewMessageCont: {
      width: '80%',
      padding: '0.4rem',
      background: '#FFFFFF',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      minHeight: '40px',
    },
    ivrPreviewPlayerCont: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      border: '1px solid rgba(150, 150, 150, 0.34)',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '47px',
      padding: '0.5rem 1.5rem 0.5rem 1rem',
      background: '#fff',
    },
    ivrPreviewWaveBox: {
      height: '2rem',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
    },
    ivrPreviewPhoneWrapper: {
      background:
        'linear-gradient(0deg, rgba(127, 127, 127, 0.3), rgba(127, 127, 127, 0.3)), linear-gradient(0deg, #DEDEDE, #DEDEDE), linear-gradient(0deg, rgba(235, 240, 247, 0.5), rgba(235, 240, 247, 0.5)), rgba(255, 255, 255, 0.5)',
      backgroundBlendMode: 'normal, color-burn, normal, normal',
      backdropFilter: 'blur(26.5761px)',
      width: '70%',
      padding: '1rem',
    },
    ivrPreviewPhoneKeyCont: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '1rem',
      marginBottom: '1rem',
      cursor: 'default',
    },
    ivrPreviewPhoneKeyBox: {
      background: '#FCFCFE',
      boxShadow: '0px 1px 0px #898A8D',
      borderRadius: '5px',
      display: 'grid',
      placeItems: 'center',
      height: '48px',
      outline: 'none',

      '&:active': {
        boxShadow:
          'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 0px #898A8D',
        border: '2px solid #00C087',
      },
    },
  })
);

export default useStyles;
