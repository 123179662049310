export const kollectAiStrategy = {
  strategySummary: "/kollectai/api/v1/strategy/summary",
  strategyDistribution: "/kollectai/api/v1/strategy/distribution",
  strategyDetailsFilter: "/kollectai/api/v1/strategy/details-filter",
  strategyUpdate: "/kollectai/api/v1/strategy/update",
  strategyChannelTemplates: "/kollectai/api/v1/template/view-channel-template",
  strategyList: `/kollectai/api/v1/strategy/get_strategy_client`,
  strategyDetails: `/kollectai/api/v1/strategy/get_ab_testing`,
  updateSrategyDistribution: `/kollectai/api/v1/strategy/update_distribution`,
  strategyDelete: `/kollectai/api/v1/strategy/delete_strategy_group`,
  strategyCreateGroup: `/kollectai/api/v1/group`,
  strategyCopyGroup: `/kollectai/api/v1/copy_group`,
  strategyGroupUpdate: `/kollectai/api/v1/group_update`,
};

export default kollectAiStrategy;
