export const variableResolver: { non_pii: string[]; pii: string[] } =
  Object.freeze({
    non_pii: [
      'product_type',
      'loan_id',
      'loan_amount',
      'outstanding_balance',
      'overdue_amount',
      'days_past_due',
      'total_number_of_loans',
      'number_of_loans_outstanding',
      'delinquent_since_date',
      'emi_amount',
      'interest_rate',
      'outstanding_principal',
      'outstanding_fee',
      'delinquent_amount',
      'ptp_start_date',
      'ptp_commitment_date',
      'hardship_start_date',
      'hardship_end_date,',
      'need_call_start_date',
      'broken_promise_start_date',
      'tenure',
    ],
    pii: [
      'first_name',
      'middle_name',
      'last_name',
      'email_address',
      'phone_number',
      'alternate_phone_number',
    ],
  });
