import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scheduleOptionsCont: {
      display: 'inline-flex',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
    scheduleOptions: {
      background: '#EBEEF2',
      borderRadius: '20px',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 500,
      color: '#969696',
    },
    scheduleSelected: {
      color: '#fff',
      background: '#036482',
    },
    formCont: {
      width: '80%',
      margin: '1rem auto 2rem',
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
    },
  })
);

export default useStyles;
