import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { kollectAITheme } from 'config/theme';
import useStyles, { ActionButton, selectStyle } from './index.styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const modeOptions = [
  { value: 'editor', label: 'Editor Mode' },
  { value: 'flow', label: 'Flow Mode' },
];

const TemplateCreateHeader = ({
  type,
  channel,
  mode,
  handleMode,
}: {
  type: string;
  channel: string;
  mode?: string;
  handleMode?: (x: string) => void;
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const getChannelName = () => {
    if (channel === 'email') return 'Email';
    if (channel === 'sms') return 'SMS';
    if (channel === 'whatsapp') return 'Whatsapp';
    if (channel === 'ivr') return 'IVR';
  };

  return (
    <Box sx={{ position: 'relative', marginTop: '1rem' }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <ActionButton
          type='button'
          bg={kollectAITheme.primaryColor}
          isBack
          onClick={() =>
            navigate('/template-management', { state: { channel } })
          }
        >
          <Typography sx={{ fontWeight: 600, textTransform: 'none' }}>
            Back to List
          </Typography>
        </ActionButton>

        {channel !== 'email' && (
          <FormControl sx={{ minWidth: 150, zIndex: 10 }}>
            <Select
              value={mode}
              onChange={(e: SelectChangeEvent) =>
                handleMode && handleMode(e.target.value)
              }
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={selectStyle}
              IconComponent={() => (
                <KeyboardArrowDownIcon className={classes.selectIcon} />
              )}
            >
              {modeOptions?.map((type: any, index: number) => (
                <MenuItem value={type.value} key={index}>
                  {type?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: channel === 'email' ? 0 : 10,
          left: channel === 'email' ? '40%' : '35%',
        }}
      >
        <Typography fontSize='1.5rem'>
          {type === 'create'
            ? 'Create New'
            : type === 'update'
            ? 'Update'
            : 'Create a copy of'}{' '}
          {getChannelName()} Template
        </Typography>
      </Box>
    </Box>
  );
};

export default TemplateCreateHeader;
