import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GiPadlock } from 'react-icons/gi';
import { useLocation } from 'react-router-dom';
import useStyles from './index.styles';

import CustomerInfoBox from '../../CustomerInfoBox';
import { kollectAITheme } from 'config/theme';
import CustomerOptionBox from '../../CustomerOptionBox';
import ProductLevelDetails from '../../ProductDetails/ProductLevel';
import LoanLevelDetails from '../../ProductDetails/LoanLevel';
import PaymentLevelDetails from '../../ProductDetails/PaymentLevel';
import CommunicationDetails from '../../ProductDetails/Communication';
import WorkflowDetails from '../../ProductDetails/Workflow';
import CustomerStatusAttributes from './statusAttributes';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  customerOdfID,
  selectedCustomerProduct,
  setSelectedCustomerProduct,
} from 'store/reducer/customerReducer';

const details = [
  'Product level Details',
  'Loan level Details',
  'Payment level Details',
  'Communication Details',
  'Workflow run Details',
];

const piiColumn = ['Name', 'DOB', 'Security'];

const CustomerProfile = ({ status }: { status: string }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const profileData: any = useAppSelector(
    (state) => state.customer.customerProfile
  );
  const customerProduct = useAppSelector(selectedCustomerProduct);
  const odfId = useAppSelector(customerOdfID);

  const [selectedProfileData, setSelectedProfileData] = useState<any>({});
  const [selectedProductOption, setSelectedProductOption] = useState<string>(
    'Product level Details'
  );
  const [selectedProduct, setSelectedProduct] = useState<string>('loc');

  useEffect(() => {
    let state = location.state as any;
    if (state?.loanView) {
      setSelectedProductOption('Loan level Details');
    } else {
      setSelectedProductOption('Product level Details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odfId]);

  useEffect(() => {
    if (profileData && customerProduct) {
      const data = profileData.find(
        (item: any) => item.product_suscribed === customerProduct
      );
      if (data) {
        setSelectedProfileData(data);
      } else {
        dispatch(
          setSelectedCustomerProduct(profileData[0]?.product_suscribed || '')
        );
        setSelectedProfileData(profileData[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, customerProduct]);

  const handleProductOption = (option: string) => {
    setSelectedProductOption(option);
  };

  return (
    <>
      <Box className={classes.customerInfoCont}>
        {piiColumn.map((col) => (
          <CustomerInfoBox label={col} key={col} />
        ))}
      </Box>

      <Box display='flex' alignItems='center' gap='1rem'>
        <GiPadlock fontSize='1.5rem' />
        <Typography
          sx={{ color: kollectAITheme.primaryColor, fontStyle: 'italic' }}
        >
          You are not authorised to view PII details. Please reach out to admin
          if you want to get PII details on this view
        </Typography>
      </Box>

      <CustomerStatusAttributes
        status={status}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setSelectedProfileData={setSelectedProfileData}
        selectedProfileData={selectedProfileData}
        {...selectedProfileData}
      />

      <Box mt='1rem'>
        <Box className={classes.productDetailsHeaderCont}>
          <Typography fontSize='1.1rem'>
            Details of {selectedProduct}
          </Typography>
        </Box>

        <Box className={classes.productDetailsWrapper}>
          <Box className={classes.productDetailsCont}>
            {details.map((detail) => (
              <CustomerOptionBox
                name={detail}
                selectedOption={selectedProductOption}
                borderDir='right'
                onClick={handleProductOption}
                key={detail}
              />
            ))}
          </Box>

          <Box>
            {selectedProductOption === 'Product level Details' && (
              <ProductLevelDetails
                productData={selectedProfileData?.product_details}
              />
            )}
            {selectedProductOption === 'Loan level Details' && (
              <LoanLevelDetails loanData={selectedProfileData?.loan_details} />
            )}
            {selectedProductOption === 'Payment level Details' && (
              <PaymentLevelDetails />
            )}
            {selectedProductOption === 'Communication Details' && (
              <CommunicationDetails
                communicationDetails={
                  selectedProfileData?.communication_details || []
                }
              />
            )}
            {selectedProductOption === 'Workflow run Details' && (
              <WorkflowDetails profileData={selectedProfileData} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomerProfile;
