import { Box, Switch, Typography } from '@mui/material';
import useStyles from './index.styles';

import emailImg from 'assets/comm_email.svg';
import smsImg from 'assets/comm_sms.svg';
import whatsappImg from 'assets/comm_whatsapp.svg';
import ivrImg from 'assets/ivr_preference.svg';

interface PreferencesBoxProps {
  // channel: 'email' | 'sms' | 'ivr' | 'whatsapp';
  channel: string;
  subscription: boolean;
  onSubscriptionChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    x: string
  ) => void;
}

const PreferencesBox: React.FC<PreferencesBoxProps> = ({
  channel,
  subscription,
  onSubscriptionChange,
}) => {
  const classes = useStyles({ subscription });

  const imgSrc =
    channel === 'email'
      ? emailImg
      : channel === 'sms'
      ? smsImg
      : channel === 'ivr'
      ? ivrImg
      : whatsappImg;

  return (
    <Box className={classes.preferenceBoxWrapper}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' gap='10px' alignItems='center'>
          <img src={imgSrc} alt='' />
          <Typography
            sx={{
              textTransform:
                channel === 'email' || channel === 'whatsapp'
                  ? 'capitalize'
                  : 'uppercase',
            }}
          >
            {channel}
          </Typography>
        </Box>

        <Box display='flex' gap='10px' alignItems='center'>
          <Typography className={classes.subscriptionText}>
            {subscription ? 'Subscribed' : 'Unsubscribed'}
          </Typography>
          <Switch
            defaultChecked
            checked={subscription}
            onChange={(e) => onSubscriptionChange(e, channel)}
            size='medium'
            className={classes.switchStyle}
          />
        </Box>
      </Box>

      <Box mt='10px'>
        <Typography color='#787486' fontSize='0.8rem'>
          No preferred days or timings.
        </Typography>
      </Box>
    </Box>
  );
};

export default PreferencesBox;
