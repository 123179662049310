import React from 'react';
import { Box, Typography } from '@mui/material';

import sentImg from 'assets/invitation_sent.svg';

export const MemberInvitationSent = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '700px',
        gap: '3rem',
        padding: '3rem',
      }}
    >
      <Box>
        <img src={sentImg} alt='' />
      </Box>

      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        <Box sx={{ '& p': { fontSize: '1.2rem', fontWeight: 500 } }}>
          <Typography mb='1rem'>
            The invitation has been sent to the user successfully.
          </Typography>
          <Typography>
            You can check the status of your registration in ‘User list’ section
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
