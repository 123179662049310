import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import {kollectAITheme} from '../../../../src/config/theme'

const DAYS = [
  {
    label:'Mon',
    value:'Monday',
  },
  {
    label:'Tue',
    value:'Tuesday',
  },
  {
    label:'Wed',
    value:'Wednesday',
  },
  {
    label:'Thur',
    value:'Thursday',
  },
  {
    label:'Fri',
    value:'Friday',
  },
  {
    label:'Sat',
    value:'Saturday',
  },
  {
    label:'Sun',
    value:'Sunday',
  },
]

interface FormikCustomerDaySelectorProps {
  name: string;
}

const DaysBox = styled(Box)({
  boxShadow:"inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
  padding:"8px 5px 2px 6px",
  minWidth:'35px'
})

const FormikCustomerDaySelector = ({ name }: FormikCustomerDaySelectorProps) => {
  return (
    <Field name={name}>
      {({ form, field, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const { value = [] } = field;

        return (
          <Box mt={2} display="flex" columnGap={4} sx={{cursor:'pointer'}}>
            {DAYS.map(day=> {
              const isSelected = value.includes(day.value);
              return (
                <DaysBox sx={{border: isSelected? "3px solid #6AC5AB" : "1px solid rgba(0, 0, 0, 0.04)"}} 
                  onClick={()=> {
                    if(value.includes(day.value)) {
                      setFieldValue(name, value?.filter((v:any)=> v!== day.value));
                    } else {
                      setFieldValue(name, [...value,day.value]);
                    }
                }}>
                  <Typography textAlign='center' color={kollectAITheme.primaryText} fontSize="14px" fontFamily="Overpass" fontWeight={isSelected ? 500 : 400}>{day.label}</Typography>
                </DaysBox>
              )}
            )}
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        );
      }}
    </Field>
  );
};

export default FormikCustomerDaySelector;
