import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';

interface OTPFieldProps {
  otp: string[];
  setOtp: (x: any) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    otpFieldStyle: {
      width: '40px',
      height: '40px',
      border: '1px solid #C4C4C4',
      background: 'transparent',
      outline: 'none',
      textAlign: 'center',
      color: '#9CA3AF',
      marginRight: '1rem',
      borderRadius: '5px',
      '&:focus': {
        border: '1px solid #004357',
        color: '#374151',
      },
    },
  })
);

let currentOTPIndex: number = 0;

const OTPField = ({ otp, setOtp }: OTPFieldProps) => {
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === 'Backspace') setActiveOTPIndex(index - 1);
  };

  return (
    <Box>
      {otp.map((_, index) => {
        return (
          <React.Fragment key={index}>
            <input
              ref={index === activeOTPIndex ? inputRef : null}
              type='number'
              min={0}
              value={otp[index]}
              onChange={handleChange}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              className={classes.otpFieldStyle}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default OTPField;
