import { Box, Typography } from '@mui/material';
import { MODULELIST } from 'constants/types';
import { useAppSelector } from 'hooks/useRedux';
import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { userPermissions } from 'store/reducer/auth';
import useStyles, { ActionButton } from './index.styles';

interface TempalateActionButtonsProps {
  type: string;
  handleVerify: () => void;
  handleSubmit: () => void;
  handleDiscard: () => void;
  handleTest: () => void;
}

const TempalateActionButtons: React.FC<TempalateActionButtonsProps> = ({
  type,
  handleVerify,
  handleSubmit,
  handleDiscard,
  handleTest,
}) => {
  const classes = useStyles();

  const permissions = useAppSelector(userPermissions);

  return (
    <Box className={classes.actionButtonCont}>
      <ActionButton type='button' bg='#0F91D2' onClick={handleVerify}>
        <Typography fontWeight={600}>Verify</Typography>
      </ActionButton>

      <Box display='flex' gap='1rem'>
        <ActionButton type='button' bg='#6AC5AB' onClick={handleSubmit}>
          <Typography fontWeight={600}>
            {type === 'update'
              ? 'Update'
              : type === 'create'
              ? 'Create'
              : 'Duplicate'}
          </Typography>
        </ActionButton>
        <ActionButton type='button' bg='#DAA09A' onClick={handleDiscard}>
          <Typography fontWeight={600}>Discard</Typography>
        </ActionButton>
      </Box>

      {permissions[MODULELIST.TEMPLATE].includes('execute') && (
        <Box>
          <button
            type='button'
            className={classes.testButton}
            onClick={handleTest}
          >
            <FaPlay />
            <Typography fontWeight={600}>Test</Typography>
          </button>
        </Box>
      )}
    </Box>
  );
};

export default TempalateActionButtons;
