export const kollectAiAuth = {
  login: '/account/api/v1/login/',
  signup: '/account/api/v1/register/',
  verifyEmailToken: '/account/api/v1/email-verify/',
  generateOtp: '/otp/generate/',
  resendOtp: '/otp/resend/',
  verifyOtp: '/otp/verify/',
  resendEmailVerification: '/account/api/v1/resend-email/',
  forgotPassword: '/account/api/v1/forgot-password/',
  resetPassword: '/account/api/v1/new-password/',
  refreshToken: '/account/api/v1/token_refresh/',
  userConfig: '/account/api/v1/roles_and_permissions/get_user_config',
  duplicatePhoneCheck: '/account/api/v1/duplicate_phone_check',
  defaultSubscription:
    '/account/api/v1/subscription_management/give_default_subscription',
  subscriptionConfig:
    '/account/api/v1/subscription_management/get_subscription_config',
};

export default kollectAiAuth;
