import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircleIcon from '@mui/icons-material/Circle';
import useStyles from './index.styles';

import { campaignGroupFilter } from 'store/reducer/uploadedFileDetailReducer';
import {
  getStrategyDetails,
  getStrategyList,
  strategyCreateGroup,
} from 'store/reducer/strategyReducer/action';

import SegmentList from './SegmentList';
import StrategyRepeatFields from './StrategyRepeatFields';
import Loader from 'components/loader';

const NUMBER_OF_DAYS = Array(12)
  .fill({})
  .map((_, idx: number) => ({
    value: (idx + 1) * 5,
    label: (idx + 1) * 5,
  }));

const CreateStrategyModal = (props: any) => {
  const { close } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [repeat, setRepeat] = useState<boolean>(false);
  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(false);

  const strategyRepeatFieldRef = useRef<any>(null);

  const { groupFilterList } = useAppSelector(
    (state) => state.uploadedFileDetail
  );

  useEffect(() => {
    const fetchFilterData = async () => {
      await dispatch(campaignGroupFilter({ client_id: 1 })).unwrap();
    };
    fetchFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object().shape({
    strategy_group_name: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      strategy_group_name: '',
      description: '',
      based_on: 'dpd',
      segment: { product: [], dpd: [], amount: [] },
      days_range: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const repeatDayRange = strategyRepeatFieldRef?.current?.getFrequency();
      const validity = strategyRepeatFieldRef?.current?.getValidity();

      const bucket = values.segment.dpd?.map(
        (itm) => Object.values(groupFilterList['DPD']).indexOf(itm) + 1
      );
      let payload: any = {
        ...values,
        segment: JSON.stringify({
          bucket,
          product: values.segment?.product,
          balance_level: values.segment?.amount,
        }),
      };

      if (values?.based_on !== 'dpd' && repeat) {
        payload.days_range = repeatDayRange;
        payload.valid_for = validity;
      }

      if (values.based_on === 'dpd') {
        delete payload.days_range;
      }

      try {
        const res = await dispatch(strategyCreateGroup(payload)).unwrap();
        await dispatch(getStrategyList());

        const { data } = await dispatch(
          getStrategyDetails({
            query: `strategy_group_id=${
              res?.data?.strategy_group_id || ''
            }&balance_level=${
              res?.data?.segment?.balance_level[0] || 0
            }&product=${res?.data?.segment?.product[0] || ''}&bucket=${
              res?.data?.segment?.bucket[0] || 0
            }`,
          })
        ).unwrap();

        close();

        navigate(
          `/strategy-details/${data?.strategy_summary[0]?.strategy_summary_id}?group_id=${res?.data?.strategy_group_id}`,
          {
            state: {
              groupName: res?.data?.strategy_group_name,
              groupId: res?.data?.strategy_group_id,
            },
          }
        );
        resetForm();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
  });

  const onChangeDayRange = (event: Event, newValue: number | number[]) => {
    setFieldValue('days_range', newValue);
  };

  const onChangeInput = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      e.target.value <= 60
    ) {
      setFieldValue('days_range', e.target.value);
    }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  }: {
    values: any;
    handleChange: any;
    errors: any;
    touched: any;
    handleBlur: any;
    handleSubmit: any;
    setFieldValue: any;
  } = formik;

  const handleChangeRepeatStrategy = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setRepeat(value === 'true');
  };

  const totalSegments = Object.values(values.segment || {}).reduce(
    (acc: number, curr: any) => {
      if (acc || curr.length) {
        return acc * (curr.length || 1);
      }
      return acc;
    },
    1
  );

  useEffect(() => {
    if (values.based_on === 'dpd' && values.segment.dpd.length === 0) {
      setIsCreateDisabled(true);
    } else {
      setIsCreateDisabled(false);
    }
  }, [values.segment, values.based_on]);

  return (
    <>
      <Loader loading={loading} />
      <Box maxWidth='1000px' maxHeight='600px' sx={{ overflow: 'auto' }}>
        <Typography fontSize='1.5rem' fontWeight={700} fontFamily='Overpass'>
          Create a New Strategy Group
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container rowGap={4} mt={4}>
            <Grid
              item
              xs={6}
              lg={3}
              className={classes.formControl}
              marginTop='0.9em'
            >
              <label className={classes.formLabel}>
                <Typography
                  color='#272D3B'
                  fontSize='1rem'
                  fontWeight={700}
                  fontFamily='Overpass'
                  sx={{ opacity: 0.5 }}
                >
                  Add Name
                </Typography>
              </label>
            </Grid>
            <Grid item xs={6} lg={9} className={classes.formControl}>
              <input
                type='text'
                name='strategy_group_name'
                placeholder='Strategy Group Name'
                value={values.strategy_group_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.formInput}
              />
              {errors.strategy_group_name && touched.strategy_group_name && (
                <p className={classes.formErrorMessage}>
                  {errors.strategy_group_name}
                </p>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              lg={3}
              className={classes.formControl}
              margin='auto 0'
            >
              <label className={classes.formLabel}>
                <Typography
                  color='#272D3B'
                  fontSize='1rem'
                  fontWeight={700}
                  fontFamily='Overpass'
                  sx={{ opacity: 0.5 }}
                >
                  Add Description
                </Typography>
                <Typography
                  color='#272D3B'
                  fontSize='1rem'
                  fontWeight={700}
                  fontFamily='Overpass'
                  sx={{ opacity: 0.5 }}
                >
                  (Optional)
                </Typography>
              </label>
            </Grid>
            <Grid item xs={6} lg={9} className={classes.formControl}>
              <textarea
                name='description'
                placeholder='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.formTextarea}
              />
              {/* {errors.description && touched.description && (
              <p className={classes.formErrorMessage}>{errors.description}</p>
            )} */}
            </Grid>

            <Grid
              item
              xs={6}
              lg={3}
              className={classes.formControl}
              margin='auto 0'
            >
              <label className={classes.formLabel}>
                <Typography
                  fontFamily='Overpass'
                  fontWeight='700'
                  fontSize='16px'
                  color='#272D3B'
                  lineHeight='20px'
                  sx={{ opacity: 0.5 }}
                >
                  Strategy run based on
                </Typography>
              </label>
              <div>
                {errors.based_on && touched.based_on && (
                  <p className={classes.formErrorMessage}>{errors.based_on}</p>
                )}
              </div>
            </Grid>
            <Grid item xs={6} lg={9} className={classes.formControl}>
              <FormControl sx={{ width: '85%' }}>
                <Select
                  value={values.based_on}
                  onChange={handleChange}
                  name='based_on'
                  displayEmpty
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                  style={{
                    border: ' 1px solid #CCCCCC',
                    background: 'rgba(224, 231, 255, 0.2)',
                  }}
                >
                  <MenuItem value={'dpd'}>Days Past Due (dpd)</MenuItem>
                  <MenuItem value={'ptp_start_date'}>
                    When customer promised to pay (ptp_start_date)
                  </MenuItem>
                  <MenuItem value={'broken_promise_start_date'}>
                    When customer broke the PTP (broken_promise_start_date)
                  </MenuItem>
                  <MenuItem value={'need_call_start_date'}>
                    When customer requested for call (need_call_start_date)
                  </MenuItem>
                  <MenuItem value={'hardship_start_date'}>
                    When customer started on hardship (hardship_start_date)
                  </MenuItem>
                  <MenuItem value={'not_engaged_start_date'}>
                    When customer got non engaged (not_engaged_start_date)
                  </MenuItem>
                  <MenuItem value={'bad_contact_start_date'}>
                    When customer got uncontactable (bad_contact_start_date)
                  </MenuItem>
                  <MenuItem value={'first_engagement_date'}>
                    When customer got added onto this system
                    (first_engagement_date)
                  </MenuItem>
                  <MenuItem value={'partial_payment_date'}>
                    When customer made partial payment(partial_payment_date)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} lg={3} className={classes.formControl}>
              <label className={classes.formLabel}>
                <Typography
                  fontFamily='Overpass'
                  fontWeight='700'
                  fontSize='16px'
                  color='#272D3B'
                  lineHeight='20px'
                  sx={{ opacity: 0.5 }}
                >
                  Strategy Segments:
                </Typography>
              </label>
            </Grid>
            <Grid item xs={6} lg={9} className={classes.formControl}>
              <label className={classes.formLabel}>
                <Typography
                  fontFamily='Overpass'
                  fontSize='14px'
                  lineHeight='18px'
                  sx={{ opacity: 0.5 }}
                >
                  Strategy for {totalSegments} segment(s) need(s) to be set up
                </Typography>
              </label>
            </Grid>
            <Grid item xs={6} lg={3} className={classes.formControl}></Grid>
            <Grid item xs={6} lg={9} className={classes.formControl}>
              <div style={{ display: 'flex', width: '90%' }}>
                {groupFilterList?.FILTERS &&
                  Object.values(groupFilterList.FILTERS).map(
                    (filterData: any) => {
                      if (groupFilterList[filterData]) {
                        return (
                          <SegmentList
                            formik={formik}
                            key={`filter-${filterData}`}
                            filterData={filterData}
                            filterList={groupFilterList}
                          />
                        );
                      }
                      return <></>;
                    }
                  )}
              </div>
              {isCreateDisabled && (
                <Typography fontSize='0.9rem' color='#d32f2f'>
                  Atleast 1 bucket should be selected.
                </Typography>
              )}
            </Grid>

            {values?.based_on !== 'dpd' && (
              <>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  className={classes.formControl}
                  margin='auto 0'
                >
                  <label className={classes.formLabel}>
                    <Typography
                      fontFamily='Overpass'
                      fontWeight='700'
                      fontSize='16px'
                      color='#272D3B'
                      lineHeight='20px'
                      sx={{ opacity: 0.5 }}
                    >
                      Want to repeat?
                    </Typography>
                  </label>
                </Grid>
                <Grid item xs={6} lg={9} className={classes.formControl}>
                  {/* <FormControl> */}
                  <RadioGroup
                    defaultValue={false}
                    name='repeat'
                    row
                    onChange={handleChangeRepeatStrategy}
                  >
                    <FormControlLabel
                      className={classes.radioLabel}
                      value={false}
                      control={
                        <Radio
                          className={classes.radio}
                          style={{
                            marginRight: 10,
                          }}
                          checkedIcon={
                            <CircleIcon
                              style={{
                                color: '#004357',
                                width: '12px',
                                height: '12px',
                              }}
                            />
                          }
                          icon={<div className={classes.uncheckedRadio} />}
                        />
                      }
                      label='NO'
                    />
                    <FormControlLabel
                      className={classes.radioLabel}
                      value={true}
                      control={
                        <Radio
                          className={classes.radio}
                          style={{
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                          checkedIcon={
                            <CircleIcon
                              style={{
                                color: '#004357',
                                width: '12px',
                                height: '12px',
                              }}
                            />
                          }
                          icon={<div className={classes.uncheckedRadio} />}
                        />
                      }
                      label='YES'
                    />
                  </RadioGroup>
                  {/* </FormControl> */}
                </Grid>
              </>
            )}

            {values?.based_on !== 'dpd' && repeat && (
              <StrategyRepeatFields ref={strategyRepeatFieldRef} />
            )}

            {values?.based_on !== 'dpd' && !repeat && (
              <>
                <Grid item xs={6} lg={3} className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <Typography
                      fontFamily='Overpass'
                      fontWeight='700'
                      fontSize='16px'
                      color='#272D3B'
                      lineHeight='20px'
                      sx={{ opacity: 0.5 }}
                    >
                      No. of days in the strategy
                    </Typography>
                  </label>
                </Grid>
                <Grid item xs={6} lg={9} className={classes.formControl}>
                  <Box display='flex' width={'85%'}>
                    <div
                      style={{
                        width: 40,
                        height: 32,
                        marginRight: 38,
                      }}
                    >
                      <input
                        type='text'
                        name='name'
                        style={{
                          padding: '12px 9px 7px 9px',
                          width: '60%',
                          height: '60%',
                          fontSize: 18,
                          fontFamily: 'Overpass',
                          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                          border: ' 1px solid #CCCCCC',
                        }}
                        value={values.days_range}
                        onChange={onChangeInput}
                      />
                    </div>
                    <Box width='100%'>
                      <Slider
                        aria-label='Always visible'
                        step={5}
                        min={5}
                        max={60}
                        marks={NUMBER_OF_DAYS}
                        value={values.days_range}
                        className={classes.slider}
                        onChange={onChangeDayRange}
                      />
                    </Box>
                  </Box>
                  <div>
                    {errors.description && touched.description && (
                      <p className={classes.formErrorMessage}>
                        {errors.description}
                      </p>
                    )}
                  </div>
                </Grid>
              </>
            )}

            <div className={classes.createSubmitCont}>
              <button
                type='submit'
                disabled={isCreateDisabled}
                className={classes.createSubmitButton}
              >
                Create
              </button>
            </div>
          </Grid>
        </form>
        {/* )}
      </Formik> */}
      </Box>
    </>
  );
};

export default CreateStrategyModal;
