import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const ResponseButton = styled('div')(
  ({ active }: { active: boolean }) => ({
    background: active ? '#2E384D' : '#EBEEF2',
    padding: active ? '6px 1.5rem' : '6px 1rem',
    borderRadius: '20px',
    color: active ? '#fff' : '#8F9499',
    cursor: 'pointer',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameFieldCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    allowedVariableText: {
      color: '#0F91D2',
      textDecoration: 'underline',
      marginBottom: '2px',
    },
    allowedActionText: {
      color: '#00C087',
      textDecoration: 'underline',
      marginBottom: '2px',
    },
    switch: {
      width: 80,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(30px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#D9D9D9',
          },
        },
      },
      '& .Mui-checked .MuiSwitch-thumb': {
        backgroundColor: '#6AC5AB',
        width: 22,
        height: 22,
      },
      '&  .MuiSwitch-thumb': {
        backgroundColor: '#6F6F6F',
        minWidth: 22,
        minHeight: 22,
        marginTop: 8,
      },

      '& .MuiSwitch-track': {
        opacity: 1,
        width: 36,
        height: 16,
        backgroundColor: '#D9D9D9',
        borderRadius: 20 / 2,
        boxShadow:
          'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      },

      '& .MuiFormControlLabel-labelPlacementStart': {
        gap: '5px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '50px',
        color: 'red',
      },
    },
    messageCont: {
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      background: '#FFFFFF',
      border: '1px solid #E6E6E6',
      padding: '1rem 2rem',
    },
    messageHeaderBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    unmapMessage: {
      fontStyle: 'italic',
      color: '#E92C2C',
      marginTop: '2px',
      fontSize: '0.9rem !important',

      '& span': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    responseTypeCont: {
      display: 'inline-flex',
      alignItems: 'center',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
    previewCont: {
      background: '#004357',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
      height: 'calc(100vh - 105px)',
      padding: '1rem 2rem',
    },
    previewButtonCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2rem',
      '& svg': {
        color: '#49F0AC',
        fontSize: '1.5rem',
      },
    },
    responseTypeWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      '& p': {
        fontSize: '0.9rem',
      },
    },
    responseDynamicFormCont: {
      background: '#F5F5F5',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '1rem',
    },
    allowedButtonCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '2rem',
      marginBottom: '5px',
    },
    formWrapper: {
      margin: '1rem auto',
      height: 'calc(100vh - 270px)',
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
      '& p': { fontFamily: 'Open Sans' },
    },
    canvasButtonCont: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      marginBottom: '5px',
    },
  })
);

export default useStyles;
