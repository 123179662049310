import { Box, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { kollectAITheme } from 'config/theme';
import useStyles from './index.styles';

interface TemplateChannelSelectBoxProps {
  channel: string;
  icon: string;
  selected: boolean;
  onClick: (x: string) => void;
}

const TemplateChannelSelectBox: React.FC<TemplateChannelSelectBoxProps> = ({
  channel,
  icon,
  selected,
  onClick,
}) => {
  const classes = useStyles({ selected });

  return (
    <Box
      className={`${classes.templateChannelSelectBox} ${
        selected ? classes.templateChannelSelectBoxSelected : ''
      }`}
      onClick={() => onClick(channel)}
    >
      <Box
        className={`${classes.templateSelectBoxIconStyle} ${
          selected ? classes.templateSelectBoxIconSelected : ''
        }`}
      >
        <CustomIcon
          name={icon}
          size={18}
          color={selected ? kollectAITheme.primaryColor : '#fff'}
        />
      </Box>
      <Typography
        textTransform='uppercase'
        fontWeight={600}
        fontSize='1rem'
        color={selected ? '#fff' : kollectAITheme.primaryColor}
      >
        {channel}
      </Typography>
    </Box>
  );
};

export default TemplateChannelSelectBox;
