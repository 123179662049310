import { Box, Typography } from '@mui/material';
import { FormikValues } from 'formik';
import { ACTION_TRIGGER } from './constants';
import {
  AddCustomerRequestForm,
  ResendMessageForm,
  RunWorkflowForm,
  SendCommunicationForm,
  UpdateCallRequiredForm,
  UpdatePTPCommitmentForm,
} from './forms';
import useStyles from './index.styles';
import ConnectToAnAgent from './forms/ConnectToAnAgent';
import AssignAgentTask from './forms/AssignAgentTask';

interface ActionTriggerModalProps {
  action: string;
  path: string;
  onClose: () => void;
  setFieldValue: (x: string, y: any) => void;
  value: any;
  formValues: FormikValues;
  actionPath: string;
}

export interface FormPropsType {
  path: string;
  value: any;
  onClose: () => void;
  setFieldValue: (x: string, y: any) => void;
  formValues: FormikValues;
  actionPath: string;
}

const ActionTriggerModal: React.FC<ActionTriggerModalProps> = (props) => {
  const { action } = props;
  const classes = useStyles();

  return (
    <Box className={classes.modalWrapper}>
      <Box className={classes.headerStyle}>
        <Typography>{ACTION_TRIGGER[action]}</Typography>
      </Box>

      <Box p='2rem'>
        {action === 'add_customer_request' && (
          <AddCustomerRequestForm {...props} />
        )}
        {action === 'run_workflow' && <RunWorkflowForm {...props} />}
        {action === 'resend_from_this_template' && (
          <ResendMessageForm {...props} />
        )}
        {action === 'send_another_communication' && (
          <SendCommunicationForm {...props} />
        )}
        {action === 'update_call_required_details' && (
          <UpdateCallRequiredForm {...props} />
        )}
        {action === 'update_ptp_commitment' && (
          <UpdatePTPCommitmentForm {...props} />
        )}
        {action === 'connect_to_an_agent' && <ConnectToAnAgent {...props} />}
        {action === 'assign_agent_task' && <AssignAgentTask {...props} />}
      </Box>
    </Box>
  );
};

export default ActionTriggerModal;
