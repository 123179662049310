import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles, { ActionButton } from './index.styles';
import Loader from 'components/loader';
import { useAppSelector } from 'hooks/useRedux';
import RerunCampaign from 'components/campaigns/modals/RerunCampaign';
import BasicModal from 'components/Modal';
import CopyCampaignModal from 'components/campaigns/modals/CopyCampaign';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const FooterActions = () => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openRerun, setOpenRerun] = React.useState<boolean>(false);
  const [openCopy, setOpenCopy] = React.useState<boolean>(false);

  const campaignData = useAppSelector((state) => state.campaign.campaign);
  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.actionCont}>
        <ActionButton background='#000000' onClick={() => {}}>
          <Typography>ARCHIVE</Typography>
        </ActionButton>
        {permissions[MODULELIST.CAMPAIGN].includes('update') ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <ActionButton
              background='#115180'
              radius='20px'
              onClick={() => setOpenCopy(true)}
            >
              <Typography>COPY & EDIT</Typography>
            </ActionButton>
            <ActionButton
              background='#6AC5AB'
              onClick={() => setOpenRerun(true)}
            >
              <Typography>RERUN</Typography>
            </ActionButton>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <RerunCampaign
        open={openRerun}
        onClose={() => setOpenRerun(false)}
        campaign={campaignData}
        setLoading={setLoading}
      />

      <BasicModal
        open={openCopy}
        onClose={() => setOpenCopy(false)}
        children={
          <CopyCampaignModal
            onClose={() => setOpenCopy(false)}
            campaign={campaignData}
          />
        }
      />
    </>
  );
};

export default FooterActions;
