import * as React from 'react';
import { Typography } from '@mui/material';
import { PermissionButton } from '../styles';
import { AddPermission, PermissionTableWrapper } from './style';

import PermissionTable from './table';
import { CreateEditPermission } from 'components/rolesPermissions/permissions/modals/create/Create';
import PlainModal from 'components/Modal/PlainModal';
import { useAppSelector } from 'hooks/useRedux';
import { permissionSummary } from 'store/reducer/permissionReducer';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

export const PermissionLists = () => {
  const [permissions, setPermissions] = React.useState<any[]>([]);
  const [searchPermission, setSearchPermission] = React.useState('');
  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const permissionData = useAppSelector(permissionSummary);

  React.useEffect(() => {
    if (permissionData?.results) {
      setPermissions(permissionData.results);
    }
  }, [permissionData?.results]);

  const handleModalClose = () => setOpenCreate(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchValue = e.target.value;
    if (searchValue) {
      let results = permissionData?.results;
      const data = results.filter((res: any) =>
        res?.permission_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setPermissions(data);
    } else {
      setPermissions(permissionData.results);
    }
    setSearchPermission(searchValue);
  };

  const userPerms = useAppSelector(userPermissions);

  return (
    <>
      <PermissionTableWrapper>
        <AddPermission>
          <input
            type='text'
            placeholder='Search Permission'
            value={searchPermission}
            onChange={handleSearch}
          />
          {userPerms[MODULELIST.USER].includes('create') && (
            <PermissionButton
              background='#004357'
              onClick={() => setOpenCreate(true)}
            >
              <Typography>add permission</Typography>
            </PermissionButton>
          )}
        </AddPermission>

        <PermissionTable permissions={permissions} />
      </PermissionTableWrapper>

      <PlainModal
        open={openCreate}
        onClose={handleModalClose}
        children={<CreateEditPermission onClose={handleModalClose} />}
      />
    </>
  );
};
