import createApiInstance from './createApiInstance';

let endpoint = process.env.REACT_APP_BASE_URL;
if (
  window.location.hostname.indexOf('feat') >= 0 ||
  window.location.hostname.indexOf('stage') >= 0
) {
  endpoint = process.env.REACT_APP_STAGE_URL;
}
if (window.location.hostname.indexOf('master') >= 0) {
  endpoint = process.env.REACT_APP_PROD_URL;
}

const api = createApiInstance(endpoint, 'KollectAi');

export default api.instance;
