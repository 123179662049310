import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileUploadWrapper: {
      maxWidth: '70%',
      margin: '2em auto 0',
      textAlign: 'center',
      '& p': { fontFamily: 'Overpass' },
    },
    infoText: {
      width: '50% !important',
      margin: '1rem auto 0 !important',
      '& span': {
        textDecoration: 'underline',
        color: theme.kollectAITheme.primaryColor,
        cursor: 'pointer',
      },
    },
    copyUrlCont: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      background: '#FBFDFE',
      border: '1px solid #BFBFBF',
      borderRadius: '5px',
      marginTop: '1rem',

      '& input': {
        background: 'transparent',
        border: 'transparent',
        outline: 'none',
        height: '25px',
        width: '260px',
        padding: '0 10px',
        fontSize: '1em',
        fontFamily: 'Overpass',
        color: theme.kollectAITheme.primaryColor,
      },

      '& ::placeholder': {
        color: theme.kollectAITheme.primaryColor,
      },
    },
    copyUrlButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      background: '#6AC5AB',
      color: '#fff',
      padding: '12px 20px',
      cursor: 'pointer',
    },
    actionButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.5em 2em',
      border: '1px solid #E8E8E8',
    },
    uploadButton: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.16)',
      borderRadius: '5px',
      padding: '5px 16px',
      color: '#fff',
    },
    fileCont: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '100px 1fr 100px',
      padding: '0.5rem 1rem',
    },
  })
);

export default useStyles;
