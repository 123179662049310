import React from 'react';
import { Box, Typography } from '@mui/material';

const ChannelBox = ({
  channel,
  selected,
  onClick,
}: {
  channel: string;
  selected: string;
  onClick: (x: string) => void;
}) => {
  const isSelected = selected === channel;

  return (
    <Box
      sx={{
        pointer: 'cursor',
        color: isSelected ? '#fff' : '#8592A3',
        fontFamily: 'Open Sans',
        cursor: 'pointer',
        background: isSelected ? '#004357' : '#fff',
        padding: '5px 10px',
        borderRadius: '6px',
      }}
      onClick={() => onClick(channel)}
    >
      <Typography>{channel}</Typography>
    </Box>
  );
};

export default ChannelBox;
