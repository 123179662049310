import AuthLayout from 'components/auth/AuthLayout';
import Protected from 'components/Protected';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InvitationSignup from './auth/InvitationSignup';

import LoginScreen from './auth/login/index';
import ResetPasswordScreen from './auth/resetPassword';
import SignUpScreen from './auth/signup';
import TwoStepVerification from './auth/twoStepVerification';
// import SignupVerificationScreen from './auth/verification';
import EmailVerification from './auth/verifyEmail';
import BaseContainer from './baseContainer/index';

const MainContainer = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path='/*'
            element={
              <Protected>
                <BaseContainer />
              </Protected>
            }
          />
          <Route element={<AuthLayout />}>
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/sign-up' element={<SignUpScreen />} />
            <Route path='/otp-verification' element={<TwoStepVerification />} />
            <Route path='/email-verify' element={<EmailVerification />} />
            <Route path='/reset-password' element={<ResetPasswordScreen />} />
            <Route path='/invitation-signup' element={<InvitationSignup />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default MainContainer;
