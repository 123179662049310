import { useEffect, useState } from 'react';
import { styled, Box, Typography } from '@mui/material';
import CustomFormikInput from 'components/formik/FormikInput';
import CustomFormikTextarea from 'components/formik/FormikTextarea';
import { Form, Formik, FormikValues } from 'formik';
import { useAppDispatch } from 'hooks/useRedux';
import { Link, useNavigate } from 'react-router-dom';
import {
  createWorkflow,
  getAllWorkflow,
  getWorkflowById,
} from 'store/reducer/workflowReducer/action';
import * as yup from 'yup';

import { setGraphData } from 'utils/workflow/graphHandler';
import { kollectAITheme } from 'config/theme';
import CreateDuplicateSelect from './createDuplicateSelect';
import { setRefetchWorkflow } from 'store/reducer/workflowReducer';
import { SelectOptionType } from 'constants/types';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';

interface CreateDuplicateWorkflowModalProps {
  selectedWorkflow?: any;
  workflowData: any;
  isCreate?: boolean;
  handleClose: () => void;
  setLoading: (x: boolean) => void;
}

const SubmitButton = styled('button')({
  background: kollectAITheme.primaryColor,
  borderRadius: '4px',
  color: '#fff',
  padding: '0.5rem 1rem',
  fontSize: '1rem',
});

const CreateDuplicateWorkflowModal: React.FC<
  CreateDuplicateWorkflowModalProps
> = ({ isCreate, workflowData, handleClose, selectedWorkflow, setLoading }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [workflowOptions, setWorkflowOptions] = useState<SelectOptionType[]>(
    []
  );
  const [selectedWorkflowOption, setSelectedWorkflowOption] = useState<
    SelectOptionType | ''
  >('');
  const [configJson, setConfigJson] = useState<any>({});

  useEffect(() => {
    if (!isCreate) {
      const options = selectedWorkflow.map((workflowId: string) => {
        const workflow = workflowData.find(
          (workflow: any) => workflow.workflow_id === workflowId
        );
        return { label: workflow.workflow_name, value: workflowId };
      });
      setWorkflowOptions(options);
      setSelectedWorkflowOption(options[0]);
      selectedWorkflow.length === 1 && getJsonConfig(selectedWorkflow[0]);
    }
  }, [selectedWorkflow, isCreate, workflowData]);

  const getJsonConfig = async (workflow_id: string) => {
    const { meta, data } = await dispatch(
      getWorkflowById({ workflow_id })
    ).unwrap();
    if (meta.success) setConfigJson(data.config);
  };

  const onSubmit = async (values: FormikValues, actions: any) => {
    setLoading(true);
    let config = {
      Steps: [
        {
          type: 'START',
          name: 'start',
          position: 1,
          haschildren: {
            start: [
              { parent: 'start', parentValue: 'start', position: 1, name: '' },
            ],
          },
        },
      ],
    };

    try {
      const { name, description } = values;
      const { value } = selectedWorkflowOption as any;
      if (!isCreate) {
        const { data } = await dispatch(
          getWorkflowById({ workflow_id: value })
        ).unwrap();
        config = data?.config;
      }
      const payload = {
        ...(!isCreate && { workflow_id: value }),
        wf_name: name,
        ...(description && { wf_description: description }),
        config: JSON.stringify(config),
      };

      const { data, meta } = await dispatch(createWorkflow(payload)).unwrap();
      if (meta.status === 'SUCCESS') {
        await dispatch(getAllWorkflow());
        const configObj = JSON.parse(data.config);
        const payload = {
          ...data,
          workflow_id: data.wf_id,
          config: configObj,
        };
        await dispatch(viewChannelTemplate({ channel_type: 'all' }));
        setGraphData('graphData', payload);
        dispatch(setRefetchWorkflow(true));
        actions.resetForm();
        handleClose();
        navigate('/workflow-editor');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Required'),
  });

  const handleWorkflowSelect = async (value: any) => {
    setSelectedWorkflowOption(value);
    getJsonConfig(value.value);
  };

  return (
    <Box>
      <Typography mb='1.5rem' fontWeight={600}>
        {isCreate ? 'Create a New Workflow' : 'Duplicate and edit a workflow'}
      </Typography>

      <Box px='1.5rem'>
        <Formik
          initialValues={{
            name: '',
            description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {!isCreate && (
                <CreateDuplicateSelect
                  label='Base Workflow'
                  options={workflowOptions}
                  selected={selectedWorkflowOption}
                  onChange={handleWorkflowSelect}
                />
              )}
              <CustomFormikInput
                label='Add Name'
                name='name'
                type='text'
                placeholder='Workflow Name'
              />
              <CustomFormikTextarea
                label='Add Description'
                name='description'
                placeholder='Description'
              />

              <Box display='flex' justifyContent='center'>
                <SubmitButton disabled={isSubmitting} type='submit'>
                  {isCreate ? 'Create' : 'Duplicate & Edit'}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>

        <Box display='flex' justifyContent='center' mt='1rem'>
          <Link to='#'>
            <Typography fontSize='0.8rem' color='#2C62EC'>
              {isCreate ? 'How to create a workflow?' : 'How To Edit Workflow?'}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateDuplicateWorkflowModal;
