import { Box, Typography } from '@mui/material';
import useStyles from './styles';

const PaymentResponse = ({
  pay,
  img,
  isSelected,
  onClick,
}: {
  pay: string;
  img: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const classes = useStyles();

  const payText = pay.charAt(0).toUpperCase() + pay.slice(1);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box className={classes.circle}>
        {isSelected && <Box className={classes.activeSubCircle} />}
      </Box>
      <Box
        className={classes.paymentWrapper}
        sx={{
          border: `1px solid ${isSelected ? '#6AC5AB' : '#D4DCEB'}`,
          boxShadow: isSelected
            ? '2px 3px 6px rgba(36, 81, 60, 0.14)'
            : 'unset',
        }}
        onClick={onClick}
      >
        <Typography fontSize='0.9rem' mt='5px'>
          {payText} to pay
        </Typography>
        <img src={img} alt='' />
      </Box>
    </Box>
  );
};

export default PaymentResponse;
