import { Slider, styled } from '@mui/material';

export const PrioritySliderCont = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

export const PrioritySlider = styled(Slider)({
  width: 300,
  color: '#6AC5AB',

  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
  },
  '& .MuiSlider-mark': {
    height: 14,
    width: 4,
    borderRadius: '30px',
    color: '#aaaaaa',
  },
  '& .MuiSlider-rail': {
    background: '#aaaaaa',
    boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '5px',
    height: '5px',
  },
});
