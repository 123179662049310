import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import ChannelDisplayBox from '../channelDisplayBox';

interface StyleProps {
  email: boolean;
  ivr: boolean;
  sms: boolean;
  whatsapp: boolean;
}

const useStyles: any = makeStyles<Theme, StyleProps>({
  dpdBoxContainer: {
    cursor: 'pointer',
    color: '#969696',
    height: '80px',
    border: '1px solid #E1E1E1',
    background: (props) =>
      !props.email && !props.ivr && !props.sms && !props.whatsapp
        ? '#E8E8E8'
        : '#fff',
  },
  dpdNumberStyle: {
    padding: '0px 8px',
  },
  emptyChannelContainer: {
    padding: '4px 3px',
    backgroundColor: '#E8E8E8',
    fontSize: '0.5rem',
    borderRadius: 4,
  },
});

const DpdBox = (props: any) => {
  const { dpd, email, ivr, sms, whatsapp } = props;
  const { dpdBoxContainer, dpdNumberStyle, emptyChannelContainer } =
    useStyles(props);

  const isChannelEmpty = !whatsapp && !sms && !ivr && !email;

  return (
    <div
      className={dpdBoxContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant='h6' className={dpdNumberStyle}>
        {dpd}
      </Typography>

      <Box
        marginBottom={1.2}
        display='flex'
        flexDirection='row'
        flexWrap='wrap'
      >
        {whatsapp && (
          <div>
            <ChannelDisplayBox
              channelName='whatsapp'
              channelIconName='whatsapp-icon'
              channelIconColor='#00C087'
              channelColor='rgb(255, 159, 45)'
              channelBackgroundColor='rgb(255, 159, 45, 0.1)'
            />
          </div>
        )}
        {sms && (
          <div>
            <ChannelDisplayBox
              channelName='sms'
              channelIconName='smsTemplate'
              channelIconColor='#FFB600'
              channelColor='rgb(0, 186, 52)'
              channelBackgroundColor='rgb(0, 186, 52, 0.1)'
            />
          </div>
        )}
        {email && (
          <div>
            <ChannelDisplayBox
              channelName='email'
              channelIconName='email-icon'
              channelIconColor='#E92C2C'
              channelColor='rgb(233, 44, 44)'
              channelBackgroundColor='rgb(233, 44, 44, 0.1)'
            />
          </div>
        )}
        {ivr && (
          <div>
            <ChannelDisplayBox
              channelName='ivr'
              channelIconName='phone-icon'
              channelIconColor='#115180'
              channelColor='#0085FF'
              channelBackgroundColor='rgb(0, 133, 255, 0.1)'
            />
          </div>
        )}
        {isChannelEmpty && <div className={emptyChannelContainer}>NIL</div>}
      </Box>
    </div>
  );
};

export default DpdBox;
