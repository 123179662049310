import { Box } from '@mui/material';
import ToolBox from '../ToolBox';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { actions } from './data';
// import { useAppSelector } from 'hooks/useRedux';

const ActionTool = () => {
  // const userEmail = useAppSelector((state: any) => state.auth.email);

  // if (userEmail !== 'aayushi@opendatafabric.com') {
  //   return (
  //     <Box>
  //       {actions.map((action, index) => (
  //         <ToolBox key={index} {...action} />
  //       ))}
  //     </Box>
  //   );
  // }

  return (
    <Droppable droppableId='actions'>
      {(droppableProvided, droppableSnapshot) => (
        <Box
          sx={{
            height: 'calc(100vh - 380px)',
            overflow: 'auto',
            scrollbarWidth: 'none',
            marginBottom: '0.8rem',

            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {actions.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(draggableProvided, draggableSnapshot) => (
                <Box
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                >
                  <ToolBox {...action} />
                </Box>
              )}
            </Draggable>
          ))}
        </Box>
      )}
    </Droppable>
  );
};

export default ActionTool;
