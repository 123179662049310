import { Box, Typography } from '@mui/material';
import CustomModal from 'components/Modal/CustomModal';

const ViewRequestModal = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: any;
}) => {
  if (!data) return <></>;

  const {
    deleted,
    client_id,
    created_by,
    lastmodified_by,
    created_at,
    lastmodified_at,
    odf_customer_id,
    ...rest
  } = data;
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      header='Request Details'
      width='40vw'
      children={
        <Box>
          {data && Object.keys(rest) && (
            <>
              {Object.entries(rest).map((item: any, index) => (
                <Box
                  display='flex'
                  alignItems='center'
                  gap='1rem'
                  mb='0.8rem'
                  key={`request-${index}`}
                >
                  <Typography width={180}>{item[0]}: </Typography>
                  <Typography>
                    {item[1] === null ? '-' : JSON.stringify(item[1])}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
      }
    />
  );
};

export default ViewRequestModal;
