import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import calendarImg from 'assets/calendar.svg';

interface FormikCampaignDatePickerProps {
  name: string;
  handleChange?: (x: string, y: Date) => void;
}

const DatePickeWrapper = styled('div')({
  position: 'relative',
  // marginTop: '0.5rem',
  width: 180,
  border: '1px solid rgba(0, 0, 0, 0.04)',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: '0rem 12px',

  '& .react-datepicker__input-container input': {
    width: '185px !important',
    background: 'transparent !important',
    cursor: 'default',
    zIndex: 10,
    position: 'relative',
    padding: '0.8rem 0.8rem 0.5rem',
  },
});

const FormikCampaignDatePicker = ({
  name,
  handleChange,
}: FormikCampaignDatePickerProps) => {
  return (
    <>
      <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box>
              <DatePickeWrapper>
                <DatePicker
                  id={name}
                  {...field}
                  selected={value}
                  onChange={(val) => {
                    setFieldValue(name, val);
                    handleChange && val && handleChange('date', val);
                  }}
                />
                <Box sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                  <img src={calendarImg} alt='' width={25} />
                </Box>
              </DatePickeWrapper>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </>
  );
};

export default FormikCampaignDatePicker;
