import { Box, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { kollectAITheme } from 'config/theme';
import useStyles from './index.styles';

interface ToolBoxProps {
  iconSize?: number;
  iconName?: string;
  title: string;
  desc: string;
  img?: string;
  color?: string;
}

const ToolBox: React.FC<ToolBoxProps> = ({
  iconSize = 22,
  iconName,
  title,
  desc,
  img,
  color,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.toolboxWrapperStyle}
      sx={{ borderBottom: `5px solid ${color}` }}
    >
      <Box className={classes.toolboxIconCont}>
        {img ? (
          <img src={img} alt='' width={26} />
        ) : (
          <CustomIcon name={iconName} size={iconSize} color={color} />
        )}
      </Box>

      <Box>
        <Typography fontSize={'1rem'} fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize={'0.9rem'}>{desc}</Typography>
      </Box>
    </Box>
  );
};

export default ToolBox;
