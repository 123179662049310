import { createAsyncThunk } from '@reduxjs/toolkit';
import userEndpoint from 'apis/user/config';
import userApi from 'apis/user';

export const getUserSummary = createAsyncThunk(
  `${userEndpoint.userSummary}Get`,
  async (payload: {
    client_id?: number;
    search_query?: string;
    status?: string;
    two_fa?: string;
    role?: string;
    limit: number;
    offset: number;
  }) => {
    const { data } = await userApi.getUserSummary(payload);
    return data;
  }
);

export const getRoleList = createAsyncThunk(
  `${userEndpoint.user}Get`,
  async () => {
    const { data } = await userApi.getRoleList();
    return data;
  }
);

export const addUser = createAsyncThunk(
  `${userEndpoint.user}Post`,
  async (payload: {
    first_name: string;
    last_name: string;
    email: string;
    role_id: string;
    two_f_a: boolean;
  }) => {
    const { data } = await userApi.addUser(payload);
    return data;
  }
);

export const updateUser = createAsyncThunk(
  `${userEndpoint.editUser}Post`,
  async (payload: {
    notify: boolean;
    user_id: string;
    is_active: number;
    first_name: string;
    last_name: string;
    email: string;
    role_id: string;
    two_f_a: boolean;
  }) => {
    const { data } = await userApi.updateUser(payload);
    return data;
  }
);

export const deleteUser = createAsyncThunk(
  `${userEndpoint.user}Delete`,
  async (payload: { user_id: string }) => {
    const { data } = await userApi.deleteUser(payload);
    return data;
  }
);

export const verifyUserEmail = createAsyncThunk(
  `${userEndpoint.verifyUserEmail}Patch`,
  async (payload: { token: string; two_f_a: string }) => {
    const { data } = await userApi.verifyUserEmail(payload);
    return data;
  }
);

export const createNewUserPassword = createAsyncThunk(
  `${userEndpoint.newUserPassword}Patch`,
  async (payload: {
    token: string;
    phone: string;
    phone_country_code: string;
    new_password: string;
    confirm_new_password: string;
  }) => {
    const { data } = await userApi.createNewUserPassword(payload);
    return data;
  }
);

export const resendUserEmail = createAsyncThunk(
  `${userEndpoint.resendUserEmail}Post`,
  async (payload: { email: string }) => {
    const { data } = await userApi.resendUserEmail(payload);
    return data;
  }
);

export const exportUserDetails = createAsyncThunk(
  `${userEndpoint.exportUserDetails}Post`,
  async () => {
    const { data } = await userApi.exportUserDetails();
    return data;
  }
);
