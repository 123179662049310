import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
// import { kollectAITheme } from "config/theme";
import { BsThreeDots } from 'react-icons/bs';
import useStyles from './index.styles';
import { AiOutlinePlus } from 'react-icons/ai';
import CustomModal from 'components/Modal/CustomModal';
import AddRequestForm from 'components/customer/ActionModals/AddRequest/addRequest';
import AddRequestComment from 'components/customer/ActionModals/AddRequest/requestComment';
import ViewRequestModal from './viewData';
import RequestMenu from './menu';

interface RequestDragAndDropColumnProps {
  requests: any;
  column: any;
}

const RequestDragAndDropColumn: React.FC<RequestDragAndDropColumnProps> = ({
  requests,
  column,
}) => {
  const classes = useStyles({ column });

  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [openView, setOpenView] = React.useState<boolean>(false);
  const [openComment, setOpenComment] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<any>(null);
  const [showOption, setShowOption] = React.useState<any>(null);

  // const handleEdit = (request: any) => {
  //   setEditData(request);
  //   setOpenEdit(true);
  // };

  const handleView = () => {
    setOpenView(true);
    setShowOption(null);
  };

  const handleEdit = () => {
    setOpenEdit(true);
    setShowOption(null);
  };

  return (
    <>
      <Box className={classes.dragAndDropWrapper}>
        <Box className={classes.headerCont}>
          <Box className={classes.headerBullet}></Box>
          <Typography fontWeight={500} color='#0D062D'>
            {column.title}
          </Typography>
          <Typography className={classes.headerRequestCount}>
            {column?.requestIds?.length}
          </Typography>
        </Box>

        <Droppable droppableId={column.id}>
          {(droppableProvided, droppableSnapshot) => (
            <Box
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              sx={{
                minHeight: 'calc(100vh - 350px)',
              }}
            >
              {requests.map((request: any, index: number) => {
                const {
                  req_id: requestId,
                  issue_title: title,
                  issue_description: desc,
                  priority,
                  comments,
                } = request;
                return (
                  <Draggable
                    key={requestId}
                    draggableId={`${requestId}`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <Box
                        className={classes.draggableItemCont}
                        sx={{
                          border: draggableSnapshot.isDragging
                            ? '1px dashed rgba(80, 48, 229, 0.59)'
                            : 'transparent',
                          boxShadow: draggableSnapshot.isDragging
                            ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                            : 'unset',
                        }}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Box className={classes.draggableItemHeader}>
                          <Typography
                            className={classes.drggableItemHeaderPriority}
                            sx={{
                              color:
                                priority === 'high' ? '#D8727D' : '#D58D49',
                            }}
                          >
                            {priority}
                          </Typography>
                          <Box position='relative'>
                            <button
                              onClick={() => {
                                setEditData(request);
                                const show =
                                  showOption &&
                                  Object.keys(showOption).includes(
                                    index.toString()
                                  )
                                    ? false
                                    : true;
                                setShowOption((prev: any) => ({
                                  ...prev,
                                  [index]: show,
                                }));
                              }}
                            >
                              <BsThreeDots fontSize='1.2rem' />
                            </button>
                            {showOption && showOption[index] && (
                              <RequestMenu
                                handleEdit={handleEdit}
                                handleView={handleView}
                              />
                            )}
                          </Box>
                        </Box>

                        <Box className={classes.draggableItemContent}>
                          <Typography fontWeight={600} color='#0D062D'>
                            {title}
                          </Typography>
                          <Typography fontSize='0.8rem' color='#787486'>
                            {desc}
                          </Typography>
                        </Box>

                        {comments ? (
                          <Box display='flex' alignItems='center' gap='10px'>
                            <Typography>{comments}</Typography>
                            <button>
                              <Typography className={classes.commentText}>
                                Comments
                              </Typography>
                            </button>
                          </Box>
                        ) : (
                          <Box className={classes.noActionCont}>
                            <Typography fontSize='0.8rem' color='#787486'>
                              No Action yet
                            </Typography>
                            <button onClick={() => setOpenComment(true)}>
                              <AiOutlinePlus color='#5030E5' fontWeight={700} />
                            </button>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            </Box>
          )}
        </Droppable>
      </Box>

      <CustomModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        header='Edit Customer Request/Query'
        width='40vw'
        children={
          <AddRequestForm
            onClose={() => setOpenEdit(false)}
            editData={editData}
          />
        }
      />

      <CustomModal
        open={openComment}
        onClose={() => setOpenComment(false)}
        header='Add a comment'
        width='40vw'
        children={
          <AddRequestComment
            onClose={() => setOpenComment(false)}
            editData={editData}
          />
        }
      />

      <ViewRequestModal
        open={openView}
        onClose={() => {
          setOpenView(false);
          setEditData(null);
        }}
        data={editData}
      />
    </>
  );
};

export default RequestDragAndDropColumn;
