import { useState } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import { DBFormValue } from '../index';
import CustomInput from '../form/CustomInput';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { kollectAITheme } from 'config/theme';
// import { useAppSelector } from 'hooks/useRedux';
// import { userAccess } from 'utils/helper';

interface DBConnectLandingScreenProps {
  attributeJson: any;
  setIsDBConnected: (x: boolean) => void;
  setLoading: (x: boolean) => void;
  setConnectionData: (x: DBFormValue) => void;
}

const SubmitButton = styled('button')({
  borderRadius: '10px',
  color: '#fff',
  padding: '0.7rem 4rem',
  background: '#6AC5AB',
});

const validationSchema = yup.object().shape({
  host: yup.string().required('Required'),
  port: yup.string().required('Required'),
  database: yup.string().required('Required'),
  username: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

const credentials = {
  host: 'www.kollectaiDB.com',
  port: '8080',
  database: 'collections',
  username: 'aayushi',
  password: 'test123',
};

const DBConnectLandingScreen: React.FC<DBConnectLandingScreenProps> = ({
  attributeJson,
  setIsDBConnected,
  setConnectionData,
  setLoading,
}) => {
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const [connectionError, setConnectionError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // const userPermissions = userAccess(userRole)?.integration.permissions;

  const initialValues: DBFormValue = {
    host: '',
    port: '',
    database: '',
    username: '',
    password: '',
  };

  const handleSubmit = (values: DBFormValue) => {
    // if (userPermissions.includes('db_connect_access')) {
    setLoading(true);
    // const { host, port, database, username, password } = values;
    setTimeout(() => {
      const check = _.isEqual(credentials, values);
      if (check) {
        setConnectionData(values);
        setIsDBConnected(true);
        setConnectionError('');
      } else {
        setConnectionError('Incorrect credentials, please try again!');
      }
      setLoading(false);
    }, 3000);
    // }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            border: '2px solid #969696',
            borderRadius: '10px',
            padding: '1rem',

            '& span': {
              color: '#00C087',
            },
          }}
        >
          <Typography color='#616161' align='center'>
            Please provide DB details with <span>read only credentials</span>{' '}
            that can allow access to attributes mentioned in the required list
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  sx={{
                    width: '60%',
                    margin: '1rem auto',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2rem',
                    }}
                  >
                    <CustomInput
                      label='Host Name'
                      name='host'
                      type='text'
                      placeholder='www.example.com'
                    />
                    <CustomInput
                      label='Port Number'
                      name='port'
                      type='text'
                      placeholder='8080'
                      inputWidth={60}
                    />
                  </Box>

                  <Box>
                    <CustomInput
                      label='Database Name'
                      name='database'
                      type='text'
                      placeholder='Collection DB'
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2rem',
                    }}
                  >
                    <CustomInput
                      label='Username'
                      name='username'
                      type='text'
                      placeholder='Username'
                      inputWidth={200}
                    />
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CustomInput
                        label='Password'
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        inputWidth={200}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          right: 5,
                          top: 5,

                          '& svg': {
                            fontSize: '1.5rem',
                            color: kollectAITheme.primaryColor,
                          },
                        }}
                      >
                        <button onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display='flex' justifyContent='center'>
                  <SubmitButton type='submit'>
                    <Typography fontWeight={600} textTransform='uppercase'>
                      Connect
                    </Typography>
                  </SubmitButton>
                </Box>
              </Form>
            )}
          </Formik>

          <Typography color='#989898' align='center' mt='1rem'>
            Note: You can either create a view to provide info within one table
            schema and provide access to that or provide exact mapping for each
            direct or derived attribute
          </Typography>
        </Box>

        {connectionError && (
          <Typography align='center' color='#FF646B'>
            Incorrect credentials, please try again!
          </Typography>
        )}

        <Box
          sx={{
            border: '1px solid #6F6F6F',
            borderRadius: '10px',
            width: '58%',
            margin: '1rem auto 0',
            height: 'calc(100vh - 625px)',
            overflow: 'auto',
            scrollbarWidth: 'none',

            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box padding='10px 20px'>
            <Typography color='#6F6F6F' fontWeight={600}>
              Required Attributes
            </Typography>
          </Box>

          <Box>
            {Object.keys(attributeJson).map(
              (attribute: string, index: number) => {
                const { attribute_description } = attributeJson[attribute];
                return (
                  <Box borderTop='1px solid #6F6F6F' key={index}>
                    <Box
                      sx={{
                        padding: '10px 20px',
                        display: 'grid',
                        gridTemplateColumns: '200px 1fr',
                        alignItems: 'center',
                      }}
                    >
                      <Typography color='#6AC5AB' fontWeight={600}>
                        {attribute}
                      </Typography>
                      <Typography color='#6F6F6F' fontWeight={500}>
                        {attribute_description}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DBConnectLandingScreen;
