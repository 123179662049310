import { Box, Typography } from '@mui/material';
import { AiOutlineCaretDown } from 'react-icons/ai';

const LoanDetails = ({ loanDetails }: { loanDetails: any }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Typography>
        {loanDetails.length === 0 ? '-' : loanDetails.length}
      </Typography>
      <AiOutlineCaretDown fontSize='1.2rem' color='#6AC5AB' />
    </Box>
  );
};
export default LoanDetails;
