import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolboxWrapperStyle: {
      display: 'flex',
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #EAEAEA',
      boxShadow: '6px 6px 14px rgba(0, 0, 0, 0.04)',
      borderRadius: '8px',
      gap: '1.2rem',
      marginBottom: '0.5rem',
    },
    toolboxIconCont: {
      display: 'grid',
      placeItems: 'center',
      width: '55px',
      height: '55px',
      borderRadius: '6px 0px 0px 6px',
    },
  })
);

export default useStyles;
