import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import './drag-and-drop.css';

import uploadImg from 'assets/upload_drag.svg';

const CampaignDragAndDrop = ({
  onFileChange,
}: {
  onFileChange: (x: any) => void;
}) => {
  const wrapperRef: any = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e: any) => {
    const newFile = e.target.files[0];
    if (newFile && newFile.type === 'text/csv') {
      onFileChange(newFile);
    }
  };

  return (
    <>
      <Box
        ref={wrapperRef}
        className='campaign-drop-input'
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Box className='drop-file-input__label_small'>
          <img src={uploadImg} alt='' />
          <Box>
            <Typography fontSize={'0.9rem'} mt='1rem'>
              Select a file or drag and drop here
            </Typography>
            <Typography fontSize='0.9rem' className='drop-file-input_subtitle'>
              CSV file format , file size no more than 10MB
            </Typography>
          </Box>
          <Box className='campaign-drop-input_select'>
            <Typography fontWeight={600} sx={{ textDecoration: 'underline' }}>
              SELECT FILE
            </Typography>
          </Box>
        </Box>
        <input type='file' value='' accept='.csv' onChange={onFileDrop} />
      </Box>
    </>
  );
};

export default CampaignDragAndDrop;
