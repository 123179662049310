import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerDetailsWrapper: {
      display: 'grid',
      background: '#fff',

      '& p': {
        fontFamily: 'Overpass',
      },
    },
    customerDetailsCont: {
      border: '1px solid #E6EFF9',
      borderRadius: '10px',
      padding: '0 0 1rem 1rem',
    },
    customerProfileCont: {
      display: 'grid',
      gap: '1rem',
      margin: '1rem 0 1rem 1rem',
    },
    activeStatus: {
      background: '#6AC5AB',
      color: '#fff',
      borderRadius: '5px',
      padding: '4px 20px 2px',
    },
    inactiveStatus: {
      background: '#6F6F6F',
    },
    detailsContentCont: {
      display: 'grid',
      gap: '1rem',
    },
    switch: {
      width: 80,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(30px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#D9D9D9',
          },
        },
      },
      '& .Mui-checked .MuiSwitch-thumb': {
        backgroundColor: '#6AC5AB',
        width: 22,
        height: 22,
      },
      '&  .MuiSwitch-thumb': {
        backgroundColor: '#6F6F6F',
        minWidth: 22,
        minHeight: 22,
        marginTop: 8,
      },

      '& .MuiSwitch-track': {
        opacity: 1,
        width: 36,
        height: 16,
        backgroundColor: '#D9D9D9',
        borderRadius: 20 / 2,
        boxShadow:
          'inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      },

      '& .MuiFormControlLabel-labelPlacementStart': {
        gap: '5px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '50px',
        color: 'red',
      },
    },
    hideBtn: {
      position: 'absolute',
      left: '-10px',
      top: '20px',
      height: '25px',
      width: '25px',
      background: '#fff',
      zIndex: 100,
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      border: '2px solid #E7EBF0',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
