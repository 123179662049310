import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { EditHeader } from './styles';
import * as yup from 'yup';

import {
  FormikRolesInput,
  FormikRolesSelect,
  FormikRolesSwitch,
} from 'components/formik/rolesPermissions';
import { PermissionButton } from 'containers/Permissions/styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Loader from 'components/loader';
import { getUserSummary, updateUser } from 'store/reducer/userReducer/action';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const validationSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  email: yup.string().required('Required'),
  role_id: yup.string().required('Required'),
});

export const EditActiveUser = ({
  action,
  data,
  setAction,
  onClose,
  setPage,
  roleOptions,
}: {
  data: any;
  action: string;
  setAction: (x: string) => void;
  onClose: () => void;
  setPage: () => void;
  roleOptions: { label: string; value: string }[];
}) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsDisabled(action === 'edit' ? false : true);
  }, [action]);

  const handleSave = async (values: any, notify: boolean) => {
    updateUserData({ values, notify });
  };

  const permissions = useAppSelector(userPermissions);

  const handleDeactivation = (values: any) => {
    updateUserData({ values, is_active: 0 });
  };

  const updateUserData = async ({
    values,
    notify = false,
    is_active = 2,
  }: {
    values: any;
    notify?: boolean;
    is_active?: number;
  }) => {
    setLoading(true);
    let payload = { ...values, notify, is_active, user_id: data?.user_id };
    try {
      await dispatch(updateUser(payload));
      await dispatch(getUserSummary({ limit: 10, offset: 0 }));
      onClose();
      setPage();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <EditHeader background={isDisabled ? '#EEFBE5' : '#32475C14'}>
        <Typography
          color={!isDisabled ? '#32475C99 !important' : '#7CB45F !important'}
        >
          Member Status (Active)
        </Typography>
      </EditHeader>

      <Formik
        initialValues={{
          first_name: data?.first_name || '',
          last_name: data?.last_name || '',
          email: data?.email || '',
          role_id: data?.role_id || '',
          two_f_a: data?.two_f_a,
        }}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values }) => (
          <Form>
            <Stack direction='row' spacing={2} alignItems='center' mb='1rem'>
              <FormikRolesInput
                name='first_name'
                placeholder='First Name of the Member'
                disabled={isDisabled}
              />
              <FormikRolesInput
                name='last_name'
                placeholder='Last Name of the Member'
                disabled={isDisabled}
              />
            </Stack>

            <FormikRolesInput
              name='email'
              placeholder='Enter the email (where invitation will be sent)'
              width={650}
              disabled={true}
            />

            <Box my='1rem'>
              <FormikRolesSelect
                name='role_id'
                placeholder='Select Role for the Member'
                options={roleOptions}
                disabled={isDisabled}
              />
            </Box>

            <FormikRolesSwitch
              name='two_f_a'
              label='Want user to do two factor authentication for verification?'
              disabled={isDisabled}
            />

            <Stack direction='row' justifyContent='center' mt='2rem' gap='1rem'>
              {!isDisabled &&
              permissions[MODULELIST.USER].includes('update') ? (
                <>
                  <PermissionButton
                    background='#004357'
                    onClick={() => handleSave(values, true)}
                  >
                    <Typography>save & notify user</Typography>
                  </PermissionButton>
                  <PermissionButton
                    background='#0069AA'
                    onClick={() => handleSave(values, false)}
                  >
                    <Typography>save & don't notify</Typography>
                  </PermissionButton>
                </>
              ) : (
                <>
                  {permissions[MODULELIST.USER].includes('update') && (
                    <>
                      <PermissionButton
                        background='#0069AA'
                        onClick={() => setAction('edit')}
                      >
                        <Typography>edit</Typography>
                      </PermissionButton>
                      <PermissionButton
                        background='#6F6F6F'
                        onClick={() => handleDeactivation(values)}
                      >
                        <Typography>deactivate</Typography>
                      </PermissionButton>
                    </>
                  )}

                  {permissions[MODULELIST.USER].includes('delete') && (
                    <PermissionButton
                      background='#BB7065'
                      onClick={() => setAction('delete')}
                    >
                      <Typography>delete</Typography>
                    </PermissionButton>
                  )}
                </>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
