import { Box, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { useAppSelector } from 'hooks/useRedux';

const actionAttributes = [
  'update_ptp_commitment',
  'update_call_required_details',
  'add_customer_request',
  'send_another_communication',
  'run_workflow',
  'resend_from_this_template',
  'connect_to_an_agent',
  'assign_agent_task',
];

const StaticTextBox = ({
  value,
  action = '',
  onClick,
}: {
  value: string;
  action?: string;
  onClick?: () => void;
}) => {
  const workflows = useAppSelector((state: any) => state.workflow.allWorkflow);

  let label = '';
  if (!action && value === 'default') label = `{{ Response }}`;
  if (action === 'resend_from_this_template' && value)
    label = `msg_id: ${value}`;
  if (value && ['add_customer_request'].includes(action)) label = value;
  if (action === 'run_workflow' && value) {
    const workflow = workflows.find((el: any) => el.workflow_id === value);
    label = workflow?.workflow_name;
  }
  if (
    value &&
    [
      'send_another_communication',
      'update_call_required_details',
      'update_ptp_commitment',
    ].includes(action)
  ) {
    label = Object.keys(value).join(', ');
  }
  if (value && action === 'assign_agent_task') {
    label = Object.values(value).join(', ');
  }
  if (action === 'connect_to_an_agent') {
    if (typeof value === 'string') {
      label = value;
    }
  }

  return (
    <Box
      sx={{
        color: kollectAITheme.primaryColor,
        width: '125px',
        height: '37px',
        background: '#EBEEF2',
        boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
        display: 'grid',
        placeItems: 'center',
        cursor:
          action && actionAttributes.includes(action) ? 'pointer' : 'default',
      }}
      onClick={() => onClick && onClick()}
    >
      <Typography fontSize='0.8rem' fontWeight={600}>
        {label}
      </Typography>
    </Box>
  );
};

export default StaticTextBox;
