import * as React from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';
import useStyles from './styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCustomerDetails } from 'store/reducer/customerReducer/action';
import { CustomerDetailsType } from 'constants/types';
import { setCustomerCategory } from 'store/reducer/customerReducer';
import api from 'apis/api';
import { getBaseURL } from 'utils/helper';

interface CustomerFilterProps {
  currentPage: number;
  totalPage: number;
  filterID: string;
  setFilterID: (value: string) => void;
  setLoading: (x: boolean) => void;
  setTableData: (data: CustomerDetailsType[]) => void;
  getTableData: () => void;
  setCurrentPage: (page: number) => void;
  setTotalPage: (totalPage: number) => void;
  setSelectedAction: (action: string) => void;
  onFilter: () => void;
}

const CustomerFilter: React.FC<CustomerFilterProps> = ({
  currentPage,
  totalPage,
  filterID,
  setFilterID,
  setLoading,
  setTableData,
  getTableData,
  setCurrentPage,
  setTotalPage,
  setSelectedAction,
  onFilter,
}) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('workflow_id');

  const workflowCustomers: any = useAppSelector(
    (state) => state.workflow.workflowCustomerDetails
  );
  const constumerCategory = useAppSelector(
    (state) => state.customer.customerCategory
  );

  const handleFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterID(value);
    if (!value) {
      workflowId
        ? setTableData(workflowCustomers?.results || [])
        : getTableData();
    }
  };

  const handlePagination = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      let res;
      if (!workflowId) {
        res = await dispatch(
          getCustomerDetails({ category: constumerCategory, page })
        ).unwrap();
      } else {
        const baseUrl = getBaseURL();
        const customerDetailsUrl =
          workflowCustomers?.next || workflowCustomers?.previous;
        let url = customerDetailsUrl
          .split('&page=')[0]
          .replace('http', 'https');
        url = url.replace(baseUrl, '');
        const { data } = await api.get(`${url}&page=${page}`);
        res = data;
      }
      setTableData(res?.results || []);
      setCurrentPage(page);
      setFilterID('');
    } finally {
      setLoading(false);
    }
  };

  const handleShowAll = async () => {
    setLoading(true);
    const { count, results } = await dispatch(
      getCustomerDetails({ category: 'show_all', page: 1 })
    ).unwrap();
    const total = Math.ceil(count / 10);
    setTableData(results);
    setTotalPage(total);
    setCurrentPage(1);
    setSelectedAction('show_all');
    dispatch(setCustomerCategory('show_all'));
    setLoading(false);
  };

  return (
    <Box className={classes.fitlerWrapper}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        {!workflowId && (
          <button onClick={handleShowAll}>
            <Typography sx={{ textDecoration: 'underline', fontWeight: 600 }}>
              Show All
            </Typography>
          </button>
        )}
        <Pagination
          count={totalPage}
          variant='outlined'
          shape='rounded'
          page={currentPage}
          onChange={handlePagination}
          sx={{
            '.Mui-selected': {
              background: '#004357 !important',
              color: '#fff',
            },
          }}
        />
      </Box>

      <Box className={classes.searchWrapper}>
        <Box className={classes.idWrapper}>
          <Typography>ID:</Typography>
        </Box>
        <input
          type='text'
          value={filterID}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              onFilter();
            }
          }}
          onChange={handleFilterChange}
        />
        <CiSearch fontSize={20} onClick={onFilter} />
      </Box>
    </Box>
  );
};

export default CustomerFilter;
