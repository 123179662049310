import { styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikCustomerTextareaProps {
  name: string;
  label: string;
  labelDesc?: string;
  placeholder?: string;
  height?: string;
}

const FormikCustomerTextareaWrapper = styled('div')(
  ({ height }: { height: string }) => ({
    marginBottom: '1rem',
    width: '100%',

    '& textarea': {
      outline: 'none',
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: 'inset 0px 4px 4px rgb(0 0 0 / 25%)',
      width: 'calc(100% - 1rem)',
      height,
      padding: '10px',
      fontFamily: 'Overpass',
      fontSize: '1rem',
      color: '#000',
    },
  })
);

const FormikCustomerTextarea: React.FC<FormikCustomerTextareaProps> = ({
  name,
  label,
  labelDesc,
  placeholder = '',
  height = '100px',
}) => {
  return (
    <FormikCustomerTextareaWrapper height={height}>
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '0.3rem',
        }}
      >
        <Typography>{label}</Typography>
        {labelDesc && <Typography fontSize='0.9rem'>({labelDesc})</Typography>}
      </label>
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <div>
            <textarea placeholder={placeholder} {...field} />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </div>
        )}
      </Field>
    </FormikCustomerTextareaWrapper>
  );
};

export default FormikCustomerTextarea;
