import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    integrationLandingOptionWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: '8px',
      marginTop: '2rem',
    },
    integrationLandingOptionCont: {
      cursor: 'pointer',
      display: 'flex',
      position: 'relative',
    },
    optionBoxStyle: {
      background: '#BCE2EE',
      borderRadius: '15px',
      display: 'inline-block',
      padding: '2px 20px',
      position: 'absolute',
      left: 15,
      top: -10,

      '& p': {
        color: theme.kollectAITheme.primaryColor,
        fontSize: '14px',
      },
    },
    contentBoxStyle: {
      padding: '1rem 2rem',
      border: '0.5px solid #E6E6E6',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      width: '100%',

      '& p': {
        fontFamily: 'Overpass',
      },
    },
    contentLabelStyle: {
      fontSize: '1.3rem !important',
      textAlign: 'center',

      [theme.breakpoints.up('xl')]: {
        fontSize: '1.8rem !important',
      },
    },
    contentBottomSection: {
      padding: '1rem 2rem 0',

      '& img': {
        marginBottom: '1rem',
        width: '100%',
        height: '180px',
        [theme.breakpoints.up('xl')]: {
          height: '300px',
          margin: '2rem auto',
        },
      },

      '& p': {
        color: '#969696',
        [theme.breakpoints.up('xl')]: {
          fontSize: '1.4rem',
        },
      },

      [theme.breakpoints.up('xl')]: {
        padding: '1.4rem 4rem 0',
      },
    },
  })
);

export default useStyles;
