import { useState } from 'react';
import { Box, Modal, Tooltip } from '@mui/material';
import { BiChevronLeft } from 'react-icons/bi';
import useStyles from './index.styles';

import { actions } from './actions';
import CustomerActionModal from '../ActionModals';
import { kollectAITheme } from 'config/theme';

const CollapsedAction = ({
  // status,
  availableActions,
  fetchData,
  onExpand,
  disabled,
}: {
  // status: string;
  availableActions: any;
  fetchData: () => void;
  onExpand: () => void;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const handleAction = (action: string) => {
    if (!disabled) {
      setSelectedAction(action);
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);

  const filteredActions = actions.filter(
    (action) => availableActions[action.apiIdentifier] === 'True'
  );

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box className={classes.expandAction} onClick={onExpand}>
          <BiChevronLeft size={22} />
        </Box>
        <Box className={classes.actionIconWrapper}>
          {filteredActions.map((action, index) => {
            const { icon: Icon, color, size = 24, label } = action;
            return (
              <Tooltip
                arrow
                title={label}
                placement='left'
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '1rem',
                      bgcolor: kollectAITheme.primaryColor,
                      '& .MuiTooltip-arrow': {
                        color: kollectAITheme.primaryColor,
                      },
                    },
                  },
                }}
              >
                <Box
                  key={index}
                  className={classes.iconCont}
                  onClick={() => handleAction(action.label)}
                  sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                >
                  <Icon color={color} size={size} />
                </Box>
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'grid', placeItems: 'center' }}
        disableEnforceFocus={false}
        children={
          <CustomerActionModal
            action={selectedAction}
            onClose={handleClose}
            fetchData={fetchData}
            // status={status}
          />
        }
      />
    </>
  );
};

export default CollapsedAction;
