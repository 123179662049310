import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerDetailsWrapper: {
      padding: '0 1rem',
    },
    tabsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      mb: '0.5rem',
    },
    contentWrapper: {
      height: 'calc(100vh - 250px)',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  })
);

export default useStyles;
