import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campaignDashboardWrapper: {
      margin: '0 3rem',
    },
    detailsButtonStyle: {
      background: theme.kollectAITheme.primaryColor,
      border: 'transparent',
      color: '#fff',
      padding: '7px 20px',
      borderRadius: '10px',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
