export const campaignOptions = {
  add_filter: {
    index: '1',
    label: 'Add filters/conditions to split your population into groups',
  },
  select_channel: {
    index: '2',
    label: 'Select Channel & Template for your population (or groups)',
  },
  schedule: {
    index: '3',
    label: 'Schedule your campaign',
  },
};
