import * as React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useStyles } from './index.styles';

import { useAppDispatch } from 'hooks/useRedux';
import { resendEmailVerification } from 'store/reducer/auth/actions';

import Loader from 'components/loader';
import { errorToast } from 'utils/toast';

const VerifyEmailModal = ({
  open,
  email,
  onClose,
}: {
  open: boolean;
  email: string;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   email && resendVerification();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const resendVerification = async () => {
    // await dispatch(resendEmailVerification({ email }));
    setLoading(true);
    const { meta } = await dispatch(
      resendEmailVerification({ email })
    ).unwrap();
    if (!meta?.success) {
      errorToast(meta?.message || 'Internal server error');
    }
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal
        open={open}
        onClose={onClose}
        disableEnforceFocus={false}
        className={classes.modalStyle}
      >
        <Box className={classes.modalBox}>
          <Box sx={{ width: 380, '& p': { fontFamily: 'Open sans' } }}>
            <Typography
              fontWeight={500}
              color='rgba(50, 71, 92, 0.87)'
              align='center'
              fontSize='1.4rem'
            >
              Verify your email ✉️
            </Typography>

            <Typography m='1rem auto 2.5rem' color='rgba(50, 71, 92, 0.6)'>
              Account activation link sent to your email address:{' '}
              <strong>{email}</strong>. Please follow the link inside to
              continue.
            </Typography>

            <Typography align='center' color='rgba(50, 71, 92, 0.6)'>
              Didn't get the mail?{' '}
              <span
                style={{ cursor: 'pointer', color: '#6AC5AB' }}
                onClick={resendVerification}
              >
                Resend
              </span>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default VerifyEmailModal;
