import { Typography } from '@material-ui/core';
import CustomRadioSelect from '../customRadioSelect';
import CustomSelect from '../customSelect';
import useStyles from './index.styles';

const optionsList = [
  {
    id: 1,
    name: 'Janaury-2022',
  },
  {
    id: 2,
    name: 'February-2022',
  },
  {
    id: 3,
    name: 'March-2022',
  },
  {
    id: 4,
    name: 'April-2022',
  },
  {
    id: 5,
    name: 'May-2022',
  },
  {
    id: 6,
    name: 'June-2022',
  },
  {
    id: 7,
    name: 'July-2022',
  },
  {
    id: 8,
    name: 'August-2022',
  },
  {
    id: 9,
    name: 'September-2022',
  },
  {
    id: 10,
    name: 'October-2022',
  },
  {
    id: 11,
    name: 'November-2022',
  },
  {
    id: 12,
    name: 'December-2022',
  },
];

const data = {
  DPD: {
    0: '0-30',
    1: '31-60',
    2: '61-90',
    4: '91-120',
    5: '>120',
  },
};

const ObservationSelectors = () => {
  const classes = useStyles();
  return (
    <div className={classes.observationSelectorsWrapper}>
      <Typography variant='subtitle2'>Observation Month</Typography>
      <CustomSelect optionsList={optionsList} />
      <CustomRadioSelect
        labels={Object.values(data?.DPD ?? {})}
        // filterChange={filterChange}
      />
    </div>
  );
};

export default ObservationSelectors;
