import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Backdrop, CircularProgress, Dialog } from '@material-ui/core';
import { BsChevronDown } from 'react-icons/bs';
import {
  getStrategyDetails,
  strategyDistributionUpdate,
} from 'store/reducer/strategyReducer/action';
import useStyles from './index.styles';

import { concatenatedTemplate } from 'utils/helper';
import ChannelBox from 'components/strategyDetails/channelSummary/calendar/channelBox';
import DpdBox from 'components/strategyDetails/channelSummary/calendar/dpdBox';

interface DPDType {
  id: string;
  dpd: number;
  email: boolean;
  ivr: boolean;
  sms: boolean;
  whatsapp: boolean;
  template_email: string;
  template_ivr: string;
  template_whatsapp: string;
  template_sms: string;
}

interface ChannelSummaryCalendarProps {
  strategyGroup: any;
}

const ChannelSummaryCalendar: React.FC<ChannelSummaryCalendarProps> = ({
  strategyGroup,
}) => {
  const groupId = strategyGroup?.strategy_group_id;

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedDPD, setSelectedDPD] = useState<DPDType | any>({});
  const [channelBoxSelected, setChannelBoxSelected] = useState<string>('ivr');
  const [showOptionList, setShowOptionList] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [optionLists, setOptionLists] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );
  const emailTemplate = useAppSelector((state: any) =>
    concatenatedTemplate(state.template.emailTemplate)
  );
  const smsTemplate = useAppSelector((state: any) =>
    concatenatedTemplate(state.template.smsTemplate)
  );
  const ivrTemplate = useAppSelector((state: any) =>
    concatenatedTemplate(state.template.ivrTemplate)
  );
  const whatsappTemplate = useAppSelector((state: any) =>
    concatenatedTemplate(state.template.whatsappTemplate)
  );
  const filterList: any = useAppSelector(
    (state) => state.uploadedFileDetail.groupFilterList
  );

  const { activeDpd, activeProduct, activeAmount }: any = useAppSelector(
    (state: any) => state.strategy
  );

  const strategyDistributionData: any =
    strategyDetailsData.strategy_distribution;

  useEffect(() => {
    if (ivrTemplate) {
      setOptionLists(ivrTemplate);
    }
  }, []);

  const handleDialogClose = () => setOpenDialog(false);

  const handleDpdClick = (dpdItem: any) => {
    if (ivrTemplate) {
      const templateOption = ivrTemplate?.find(
        (item: any) => item.id === dpdItem.template_ivr
      );
      templateOption
        ? setSelectedOption(templateOption)
        : setSelectedOption(ivrTemplate[0]);
    }
    handleTemplate('ivr');
    setChannelBoxSelected('ivr');
    setSelectedDPD(dpdItem);
    setOpenDialog(true);
  };

  const handleBoxClick = (channelName: string) => {
    setChannelBoxSelected(channelName.toLowerCase());
    handleTemplate(channelName);
  };

  const handleTemplate = (channelName: any) => {
    let templateName, templateOption;
    if (channelName.toLowerCase() === 'ivr') {
      const templateIVR = concatenatedTemplate(ivrTemplate) || [];
      templateName = templateIVR ? templateIVR : [];
      templateOption = templateIVR?.find(
        (item: any) => item.id === selectedDPD.template_ivr
      );
    }
    if (channelName.toLowerCase() === 'sms') {
      const templateSMS = concatenatedTemplate(smsTemplate);
      templateName = templateSMS ? templateSMS : [];
      templateOption = templateSMS?.find(
        (item: any) => item.id === selectedDPD.template_sms
      );
    }
    if (channelName.toLowerCase() === 'email') {
      const templateEmail = concatenatedTemplate(emailTemplate);
      templateName = templateEmail ? templateEmail : [];
      templateOption = templateEmail?.find(
        (item: any) => item.id === selectedDPD.template_email
      );
    }
    if (channelName.toLowerCase() === 'whatsapp') {
      const templateWhatsapp = concatenatedTemplate(whatsappTemplate);
      templateName = templateWhatsapp ? templateWhatsapp : [];
      templateOption = templateWhatsapp?.find(
        (item: any) => item.id === selectedDPD.template_whatsapp
      );
    }

    setOptionLists(templateName);
    templateOption
      ? setSelectedOption(templateOption)
      : setSelectedOption(templateName?.[0]);
  };

  const handleListDisplay = () => setShowOptionList((state) => !state);

  const handleOptionClick = (option: any) => {
    const dpdCopy = { ...selectedDPD };
    if (channelBoxSelected === 'sms') dpdCopy.template_sms = option.id;
    if (channelBoxSelected === 'ivr') dpdCopy.template_ivr = option.id;
    if (channelBoxSelected === 'email') dpdCopy.template_email = option.id;
    if (channelBoxSelected === 'whatsapp')
      dpdCopy.template_whatsapp = option.id;
    setSelectedOption(option);
    setSelectedDPD(dpdCopy);
    setShowOptionList(false);
  };

  const hanldeDpdSave = async () => {
    setLoading(true);
    setOpenDialog(false);

    const { strategy_summary } = strategyDetailsData;
    const {
      id,
      email,
      ivr,
      sms,
      whatsapp,
      template_email,
      template_ivr,
      template_sms,
      template_whatsapp,
    } = selectedDPD;

    let updatePayload: any = {
      strategy_summary_id: strategy_summary?.[0]?.strategy_summary_id || '',
      dpd: selectedDPD?.dpd || '',
    };
    if (email) {
      updatePayload = {
        ...updatePayload,
        email,
        template_email: email
          ? template_email
            ? template_email
            : emailTemplate[0]['id']
          : null,
      };
    }
    if (sms) {
      updatePayload = {
        ...updatePayload,
        sms,
        template_sms: sms
          ? template_sms
            ? template_sms
            : smsTemplate[0]['id']
          : null,
      };
    }
    if (whatsapp) {
      updatePayload = {
        ...updatePayload,
        whatsapp,
        template_whatsapp: whatsapp
          ? template_whatsapp
            ? template_whatsapp
            : whatsappTemplate[0]['id']
          : null,
      };
    }
    if (ivr) {
      updatePayload = {
        ...updatePayload,
        ivr,
        template_ivr: ivr
          ? template_ivr
            ? template_ivr
            : ivrTemplate[0]['id']
          : null,
      };
    }

    const res = await dispatch(strategyDistributionUpdate(updatePayload));

    if (res?.payload?.meta?.success) {
      // const { AMOUNT, DPD, PRODUCT } = filterList;
      const { DPD } = filterList;
      // const filterPayload = {
      //   bucket: '1',
      //   amount: AMOUNT.LOW,
      //   product: PRODUCT['0'],
      //   dpd: DPD['0'],
      //   client_id: '1',
      //   auth_token: '',
      //   client_token: '',
      // };
      // await dispatch(strategyDetailsFilter(filterPayload));
      const bucket = Number(
        Object.values(DPD || {}).findIndex((dpd) => dpd === activeDpd)
      );

      await dispatch(
        getStrategyDetails({
          query: `strategy_group_id=${
            groupId || ''
          }&balance_level=${activeAmount}&product=${activeProduct}&bucket=${
            bucket + 1
          }`,
        })
      );
    }
    handleTemplate('ivr');
    setChannelBoxSelected('ivr');
    setLoading(false);
  };

  const handleChannelSelect = (channel: string, flag: boolean) => {
    const dpdCopy = { ...selectedDPD };
    dpdCopy[channel.toLowerCase()] = flag;
    setSelectedDPD(dpdCopy);
  };

  const getCalendarList = () => {
    if (strategyGroup?.based_on !== 'dpd') {
      return (
        Array(strategyGroup?.days_range || 0)
          .fill({})
          // .map((_, idx) => ({ dpd: idx + 1, id: idx + 1 }));
          .map((_, idx) => {
            const distribution = strategyDistributionData?.find(
              (st: any) => st.dpd === idx + 1
            );

            if (distribution) {
              return distribution;
            } else {
              return { dpd: idx + 1, id: idx + 1 };
            }
          })
      );
    }

    // const startDpdIndex: number = activeDpd?.includes('>')
    //   ? Number(activeDpd?.split('>')[1] + 1 || 0)
    //   : Number(activeDpd?.split('-')[0] || 0) || 0;
    let startDpdIndex: number = 1;
    if (activeDpd) {
      startDpdIndex = activeDpd?.includes('>')
        ? Number(activeDpd?.split('>')[1]) + 1 || 0
        : Number(activeDpd?.split('-')[0] || 0) || 0;
    }

    const distribution = Array(30)
      .fill('')
      .map((_, idx) => ({ dpd: startDpdIndex + idx }));

    const mergedDistribution = distribution
      .map((dis: any) => {
        const distribution = strategyDistributionData?.find(
          (st: any) => st.dpd === dis.dpd
        );
        if (distribution) {
          return distribution;
        } else {
          return dis;
        }
      })
      ?.sort((a: DPDType, b: DPDType) => a.dpd - b.dpd);

    return mergedDistribution;
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>

      <div className={classes.channelSummaryCalendarContainer}>
        {getCalendarList()?.map((item: any) => {
          return (
            <div key={item.dpd}>
              <div onClick={() => handleDpdClick(item)}>
                <DpdBox {...item} />
              </div>
            </div>
          );
        })}
      </div>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        classes={{ paperFullWidth: classes.dpdBoxDialogCustomizedWidth }}
      >
        <div className={classes.dpdBoxDialogBoxWrapper}>
          <div className={classes.dpdBoxDialogHeader}>
            Set up your communication strategy
          </div>
          <div className={classes.channelBoxContainer}>
            <ChannelBox
              channelSelected={channelBoxSelected === 'ivr' ? true : false}
              channelName='IVR'
              channelFlag={selectedDPD?.ivr}
              handleClick={handleBoxClick}
              handleSelect={handleChannelSelect}
            />
            <ChannelBox
              channelSelected={channelBoxSelected === 'email' ? true : false}
              channelName='Email'
              channelFlag={selectedDPD?.email}
              handleClick={handleBoxClick}
              handleSelect={handleChannelSelect}
            />
            <ChannelBox
              channelSelected={channelBoxSelected === 'sms' ? true : false}
              channelName='SMS'
              channelFlag={selectedDPD?.sms}
              handleClick={handleBoxClick}
              handleSelect={handleChannelSelect}
            />
            <ChannelBox
              channelSelected={channelBoxSelected === 'whatsapp' ? true : false}
              channelName='Whatsapp'
              channelFlag={selectedDPD?.whatsapp}
              handleClick={handleBoxClick}
              handleSelect={handleChannelSelect}
            />
          </div>

          <div className={classes.dpdBoxTemplateSelect}>
            <div
              className={`customSelectContainer customSelectLargerContainer`}
            >
              <div
                className={
                  showOptionList ? 'selectedText active' : 'selectedText'
                }
                style={{ width: '250px' }}
                onClick={handleListDisplay}
              >
                {selectedOption?.template_name}
                <BsChevronDown />
              </div>

              {showOptionList && (
                <ul className='selectOptions'>
                  {optionLists?.map((option: any) => {
                    return (
                      <div key={option.id}>
                        <li
                          className={`customSelectOption ${
                            option.id === selectedOption?.id
                              ? 'customOptionSelected'
                              : ''
                          }`}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.template_name}
                        </li>
                      </div>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>

          <div className={classes.dpdBoxTemplateDisplay}>
            {channelBoxSelected === 'email' ? (
              <div
                dangerouslySetInnerHTML={{ __html: selectedOption?.template }}
              ></div>
            ) : (
              <div>{selectedOption?.template}</div>
            )}
          </div>

          <div className={classes.dpdBoxDialogButtonContainer}>
            <button
              className={`${classes.dpdBoxDialogButton} ${classes.dpdBoxDialogSaveButton}`}
              onClick={hanldeDpdSave}
            >
              save
            </button>
            <button
              className={classes.dpdBoxDialogButton}
              onClick={handleDialogClose}
            >
              cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ChannelSummaryCalendar;
