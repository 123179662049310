import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikIntegrationsInputProps {
  label: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
}

const FormikIntegrationsInputWrapper = styled('div')(() => ({
  marginBottom: '1rem',
  display: 'flex',
  alignItems: 'center',

  '& label': { width: 220, color: '#272D3B', opacity: '0.5' },
  '& input': {
    border: '1px solid #EAEAEA',
    borderRadius: '2px',
    padding: '5px 0.7rem 0',
    fontFamily: 'Overpass',
    outline: 'none',
    fontSize: '1rem',
    minHeight: '38px',
    width: 440,
  },
}));

const FormikIntegrationsInput: React.FC<FormikIntegrationsInputProps> = ({
  label,
  name,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <FormikIntegrationsInputWrapper>
      <label>
        <Typography fontWeight={600}>{label}</Typography>
      </label>

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <Box>
            <input
              type='text'
              placeholder={placeholder}
              {...field}
              disabled={disabled}
            />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        )}
      </Field>
    </FormikIntegrationsInputWrapper>
  );
};

export default FormikIntegrationsInput;
