import { styled, Box, Typography } from '@mui/material';

import emptyImg from 'assets/empty_img.svg';
import { Add } from '@mui/icons-material';
import { useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface EmptyScreenProps {
  table?: string;
  isSearchData: boolean;
  onClick: () => void;
}

const EmptyScreenWrapper = styled('div')(() => ({
  height: 'calc(100vh - 420px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F7F7F7',
  border: '1px solid #E6E6E6',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',

  '& p': {
    fontFamily: 'Overpass',
    color: '#969696',
    margin: '0.5rem auto',
  },

  '@media (max-width: 1600px)': {
    '& img': {
      height: 100,
    },
  },
}));

const ClickBox = styled('div')(() => ({
  color: '#fff',
  cursor: 'pointer',
  filter: 'drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2))',
  display: 'flex',
  background: '#004357',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  padding: '1rem 2rem',
  borderRadius: '16px',

  '& svg': {
    marginBottom: '4px',
    fontSize: '2rem',
  },
}));

const EmptyScreen: React.FC<EmptyScreenProps> = ({ onClick, isSearchData }) => {
  const permissions = useAppSelector(userPermissions);

  return (
    <EmptyScreenWrapper>
      <img src={emptyImg} alt='' />
      {isSearchData ? (
        <Typography>No campaign for this search</Typography>
      ) : (
        <>
          <Typography>
            Seems like you haven’t created any campaign yet!{' '}
          </Typography>

          {permissions[MODULELIST.CAMPAIGN].includes('create') && (
            <>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}
              >
                <Typography>Click</Typography>

                <ClickBox onClick={onClick}>
                  <Add />
                  <Box
                    sx={{
                      '& p': {
                        color: '#fff !important',
                        lineHeight: 1,
                        textAlign: 'center',
                      },
                    }}
                  >
                    <Typography>Create</Typography>
                    <Typography>New</Typography>
                    <Typography>Campaign</Typography>
                  </Box>
                </ClickBox>
              </Box>
              <Typography> to create your first campaign </Typography>
            </>
          )}
        </>
      )}
    </EmptyScreenWrapper>
  );
};

export default EmptyScreen;
