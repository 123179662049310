import { styled, Box, Typography } from '@mui/material';

import emptyImg from 'assets/empty_img.svg';
import { Add } from '@mui/icons-material';
import { AiFillPlusCircle } from 'react-icons/ai';
// import { userAccess } from 'utils/helper';
import { useAppSelector } from 'hooks/useRedux';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface EmptyScreenProps {
  table?: string;
  isSearchData?: boolean;
  onClick: () => void;
}

const EmptyScreenWrapper = styled('div')(({ height }: { height: string }) => ({
  height,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F7F7F7',
  border: '1px solid #E6E6E6',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',

  '& p': {
    fontFamily: 'Overpass',
    color: '#969696',
    margin: '0.5rem auto',
  },

  '@media (max-width: 1600px)': {
    '& img': {
      height: 100,
    },
  },
}));

const ClickBox = styled('div')(
  ({
    height,
    padding,
    borderRadius,
  }: {
    height: string;
    padding: string;
    borderRadius: string;
  }) => ({
    color: '#fff',
    cursor: 'pointer',
    filter: 'drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2))',
    height,
    display: 'flex',
    padding,
    background: '#004357',
    borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.6rem',

    '& svg': {
      marginBottom: '4px',
    },
  })
);

const EmptyScreen: React.FC<EmptyScreenProps> = ({
  table = 'template',
  onClick,
  isSearchData,
}) => {
  const icon =
    table === 'template' ? <AiFillPlusCircle fontSize='1.1rem' /> : <Add />;
  const text =
    table === 'template'
      ? 'Add New Template'
      : table === 'workflow'
      ? 'Create New Workflow'
      : 'Create New Strategy';

  // styles
  const height = table === 'workflow' ? '40px' : 'auto';
  const borderRadius =
    table === 'template' ? '5px' : table === 'workflow' ? '10px' : '16px';
  const padding =
    table === 'template'
      ? '0 1rem'
      : table === 'workflow'
      ? '1.2rem 1rem 0.8rem'
      : '7px 17px';

  const permissions = useAppSelector(userPermissions);

  const permitted =
    table === 'template'
      ? permissions?.[MODULELIST.TEMPLATE]?.includes('create')
      : permissions?.[MODULELIST.WORKFLOW]?.includes('create');

  return (
    <EmptyScreenWrapper
      height={
        table !== 'template' ? 'calc(100vh - 450px)' : 'calc(100vh - 405px)'
      }
    >
      <img src={emptyImg} alt='' />
      {isSearchData ? (
        <Typography>No {table} for this search</Typography>
      ) : (
        <>
          <Typography>
            Seems like you haven’t created any {table} yet!{' '}
          </Typography>

          {permitted && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.6rem',
                }}
              >
                <Typography>Click</Typography>

                <ClickBox
                  height={height}
                  padding={padding}
                  borderRadius={borderRadius}
                  onClick={onClick}
                >
                  {icon}
                  <Typography color='#fff !important'>{text}</Typography>
                </ClickBox>
              </Box>
              <Typography> to create your first {table} </Typography>
            </>
          )}
        </>
      )}
    </EmptyScreenWrapper>
  );
};

export default EmptyScreen;
