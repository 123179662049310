import api from '../api';
import kollectAiTemplate from './config';

const templateApi = {
  async viewBucketChannelTemplate(payload: any) {
    // const url = `${kollectAiTemplate.viewBucketChannelTemplate}?channel_type=${payload.channel_type}`;
    return api.get(kollectAiTemplate.viewBucketChannelTemplate, {
      params: payload,
    });
  },
  async createTwoWayTemplate(payload: any) {
    return api.post(kollectAiTemplate.viewChannelTemplateTwoWay, payload);
  },
  async createTemplate(payload: any) {
    return api.post(kollectAiTemplate.viewBucketChannelTemplate, payload);
  },
  async updateTwoWayTemplate(payload: any) {
    return api.patch(kollectAiTemplate.viewChannelTemplateTwoWay, payload);
  },
  async updateTemplate(payload: any) {
    return api.patch(kollectAiTemplate.viewBucketChannelTemplate, payload);
  },
  async deleteTemplate(payload: any) {
    const url = payload?.force_delete
      ? `${kollectAiTemplate.viewBucketChannelTemplate}?force_delete=${payload.force_delete}`
      : `${kollectAiTemplate.viewBucketChannelTemplate}`;

    return api.delete(url, {
      data: { id: payload.id, channel_type: payload.channel_type },
    });
  },
  async fetchVariableResolverAttributes(payload: any) {
    return api.post(kollectAiTemplate.fetchVariableResolverAttributes, payload);
  },
  async bulkTemplateUpload(payload: any) {
    return api.post(kollectAiTemplate.bulkTemplateUpload, payload);
  },
  async validateTemplate(payload: { pii: string[]; non_pii: string[] }) {
    return api.post(kollectAiTemplate.validateTemplate, payload);
  },

  async getTemplateAttributes() {
    return api.get(kollectAiTemplate.templateAttributes);
  },
};

export default templateApi;
