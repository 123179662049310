export const availableActions = [
  {
    name: 'update_ptp_commitment',
    description:
      'This is to save PTP commitment details on getting confirmation from the customer (i.e. PTP flag as True and PTP commitment date)',
  },
  {
    name: 'update_hardship_flag',
    description:
      'This is to save Hardship acknowledgement details on getting confirmation from the customer (i.e. Hardship flag)',
  },
  {
    name: 'update_hardship_details',
    description:
      'This is to save Hardship details from the customer (i.e. Type and details on hardship inputs)',
  },
  {
    name: 'update_call_required_details',
    description:
      'This is to save Call Requested details on getting confirmation from the customer (i.e. Call Required flag as True and date & time preferences)',
  },
  {
    name: 'send_another_communication',
    description:
      'This is to send message through different channels with selected template',
  },
  {
    name: 'connect_to_an_agent',
    description:
      'This is to redirect user to Agent to have a live call and live chat',
  },
  {
    name: 'add_customer_request',
    description: 'This is to save any request/query/feedback from the customer',
  },
  {
    name: 'unsubscribe_channel',
    description: 'This is to unsubscribe respective channel for communication',
  },
  {
    name: 'run_workflow',
    description:
      'This is your custom action trigger from your workflow list (This will execute steps in real time mentioned within the workflow)',
  },
  {
    name: 'resend_from_this_template',
    description:
      'Resend any msg id from the message list of the current template',
  },
  {
    name: 'assign_agent_task',
    description:
      'Add in the task list for any agent to pick up and take necessary action',
  },
  {
    name: 'update_select_payment_plan_flag',
    description:
      'This is to save payment plan acknowledgement on getting confirmation from the customer (i.e. Payment_Plan flag)',
  },
  {
    name: 'update_settelment_plan_flag',
    description:
      'This is to save Settelment acknowledgement details on getting confirmation from the customer (i.e. Settelment_Plan flag)',
  },
];
