import { Box, Typography } from '@mui/material';
import React from 'react';

const RequestMenu = ({
  handleView,
  handleEdit,
}: {
  handleView: () => void;
  handleEdit: () => void;
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 100,
        background: '#fff',
        borderRadius: '10px',
        padding: '1rem',
        right: '0',
        top: '34px',
        boxShadow:
          '0px 2px 9px rgb(50 71 92 / 6%), 0px 4px 9px 1px rgb(50 71 92 / 4%), 0px 2px 6px 4px rgb(50 71 92 / 2%)',
        cursor: 'default',
        transition: 'all 0.5s',
        '& p': {
          color: '#000 !important',
          fontStyle: 'normal !important',
          cursor: 'pointer',
          padding: '0 1rem',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      <Typography onClick={handleView} mb='10px'>
        View
      </Typography>
      <Typography onClick={handleEdit}>Edit</Typography>
    </Box>
  );
};

export default RequestMenu;
