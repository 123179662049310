import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useStyles from './index.styles';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import CampaignDetailsPerformance from './Performance/Performance';
import {
  getCampaignDetails,
  getCampaignExecutionList,
  getCustomFilter,
} from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import CampaignSummaryDetails from 'components/campaigns/summary/summary';
import FooterActions from './FooterActions';
import {
  campaignDetailStatus,
  campaignExecutionId,
  campaignExecutionList,
  setCampaignExecutionId,
  setCampaignPayload,
} from 'store/reducer/campaignReducer';
import { workflowSelectStyles } from 'containers/Workflow/editor/tools/workflowSelect';
import {
  customFilterData,
  getCustomSelectedChannelPayload,
} from 'utils/campaign/campaignModifier';
import useLocalStorage from 'hooks/useLocalStorage';
import BlockedExpired from './BlockedExpiredBox';

export const InfoBox = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        background: 'rgba(255, 182, 0, 0.31)',
        borderRadius: '9px',
        padding: '5px 10px',
        marginBottom: '1rem',
        display: 'inline-block',
      }}
    >
      <Typography color='#24254A'>{text}</Typography>
    </Box>
  );
};

const CampaignDetailsScreen = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id: campaignId } = useParams();

  const executionId = useAppSelector(campaignExecutionId);
  const executionList = useAppSelector(campaignExecutionList);
  const campaignStatus = useAppSelector(campaignDetailStatus);
  const { storageValue, setStorageValue } = useLocalStorage(
    'customer_execution_id',
    ''
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [executionOptions, setExecutionOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [value, setValue] = React.useState<any>('');
  const [completedSchedule, setCompletedSchedule] = React.useState('');

  React.useEffect(() => {
    campaignId && fetchCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const fetchCampaignData = async () => {
    setLoading(true);
    try {
      if (campaignId) {
        await dispatch(getCampaignExecutionList({ campaign_id: campaignId }));

        const detailsRes = await dispatch(
          getCampaignDetails({ campaign_id: campaignId })
        ).unwrap();
        if (detailsRes?.status === 'Completed') {
          const time = detailsRes?.schedule_time;
          setCompletedSchedule(time);
        }
        const customerRes = await dispatch(
          getCustomFilter({ campaign_id: campaignId })
        ).unwrap();
        if (detailsRes?.based_on === 'custom_filter') {
          const filters = customFilterData(customerRes?.data || []);
          const channels = getCustomSelectedChannelPayload(
            customerRes?.data || []
          );
          dispatch(
            setCampaignPayload({
              key: 'add_filter',
              data: { filter: 'add_custom', value: filters },
            })
          );
          dispatch(
            setCampaignPayload({
              key: 'select_channel_template',
              data: channels,
            })
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (executionList.length) {
      const options = executionList.map((list: any) => ({
        label: list.schedule_time,
        value: list.execution_id,
      }));
      setExecutionOptions(options);
    }
  }, [executionList]);

  React.useEffect(() => {
    if (executionId || storageValue) {
      setValue(executionId || storageValue);
    }
  }, [executionId, storageValue]);

  const handleExecutionChange = (e: any) => {
    const exec = e.value;
    const date = moment(e.label).format('YYYY-MM-DD');
    const time = moment(e.label).format('hh:mm');
    setValue(exec);
    dispatch(setCampaignExecutionId(exec));
    setStorageValue(exec);
    dispatch(
      setCampaignPayload({
        key: 'schedule_time',
        data: { date, time },
      })
    );
  };

  const execValue = React.useMemo(
    () =>
      executionOptions && value
        ? executionOptions.find((option: any) => option.value === value)
        : '',
    [executionOptions, value]
  );

  const scheduledAgo = React.useMemo(
    () =>
      campaignStatus === '1' &&
      completedSchedule &&
      moment().diff(moment(completedSchedule), 'minutes'),
    [campaignStatus, completedSchedule]
  );

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ margin: '2rem 2rem 0', '& p': { fontFamily: 'Overpass' } }}>
        {/* @ts-ignore */}
        {campaignStatus === '1' && scheduledAgo < 15 && (
          <Box display='flex' justifyContent='end'>
            <InfoBox text='It may take some time to get the updated data for completed campaigns' />
          </Box>
        )}

        <Box position='relative' mb='2rem'>
          <Typography fontSize='1.5rem' align='center'>
            Campaign Details
          </Typography>
          {campaignStatus === '1' && (
            <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
              <Box display='flex' alignItems='center' gap='1rem'>
                <Typography>Executed on:</Typography>
                <Select
                  value={execValue}
                  onChange={handleExecutionChange}
                  options={executionOptions}
                  components={{ IndicatorSeparator: () => null }}
                  styles={workflowSelectStyles}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box className={classes.campaignDetailsContent}>
          <Box>
            <Typography className={classes.summaryText} fontWeight={700}>
              Summary
            </Typography>

            <Box
              className={classes.summaryDetailsCont}
              sx={{
                height: campaignStatus === '1' ? '665px' : '500px',
              }}
            >
              <CampaignSummaryDetails />
            </Box>
          </Box>

          {campaignStatus === '1' && <CampaignDetailsPerformance />}
          {campaignStatus === '3' && (
            <Box
              sx={{ height: '500px', display: 'grid', placeItems: 'center' }}
            >
              <Typography fontSize='1.1rem'>
                Reports will get updated once/if campaign gets completed
              </Typography>
            </Box>
          )}
          {['4', '5'].includes(campaignStatus) && (
            <BlockedExpired status={campaignStatus} />
          )}
        </Box>

        {campaignStatus === '1' && <FooterActions />}
      </Box>
    </>
  );
};

export default CampaignDetailsScreen;
