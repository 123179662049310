import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionWrapper: {
      background: '#E0E8EB',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '12px 12px 12px 0px',
      padding: '1rem',

      '& p': {
        fontFamily: 'Overpass',
      },
    },
    actionCont: {
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      marginTop: '1rem',
      height: 'calc(100vh - 232px)',
      overflow: 'auto',
      scrollbarWidth: 'none',
      paddingBottom: '1rem',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    actionStyle: {
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      color: '#272D3B',
      background: '#fff',
      padding: '10px 1rem 10px 1rem',
      borderRadius: '10px',
      minHeight: 70,
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      // gap: '1rem',
      // flex: 1,
      display: 'grid',
      gridTemplateColumns: '30px 1fr',
      alignItems: 'center',
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
    expandAction: {
      position: 'absolute',
      left: '-15px',
      top: '20px',
      height: '22px',
      width: '22px',
      background: '#fff',
      zIndex: 100,
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      border: '2px solid #E7EBF0',
      cursor: 'pointer',
    },
    actionIconWrapper: {
      filter: 'drop-shadow(-3px 0px 6px rgba(39, 45, 59, 0.2))',
      background: '#fff',
      borderRadius: '10px',
      display: 'grid',
      placeItems: 'center',
      padding: '1rem 0',
      gap: '0.8rem',
    },
    iconCont: {
      background: '#fff',
      border: '1px solid #E7EBF0',
      borderRadius: '50%',
      filter: 'drop-shadow(-3px 0px 6px rgba(39, 45, 59, 0.2))',
      display: 'grid',
      placeItems: 'center',
      height: '40px',
      width: '40px',
      // cursor: 'pointer',
    },
  })
);

export default useStyles;
