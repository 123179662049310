import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textareaMainCont: {
      display: "flex",
      flexDirection: "column",
      width: "700px",
      height: "90px",
    },
    textarealabelStyle: {
      fontSize: "15px",
      lineHeight: "22px",
      fontWeight: 600,
      marginBottom: "8px",
    },
    textareaStyle: {
      // width: "100%",
      height: "100%",
      resize: "none",
      outline: "none !important",
      border: "1px solid #EBEBEB",
      padding: "10px 12px",
      borderRadius: "3px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: '1.2',
      fontFamily: 'Overpass',
      color: '#272D3B',
      "&::placeholder": {/* Chrome, Firefox, Opera, Safari 10.1+ */
        color: "#94979E",
        opacity: 1 /* Firefox */,
      },
      "&.:-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: "#94979E",
      },
      "&::-ms-input-placeholder": {
        /* Microsoft Edge */  color: "#94979E",
      },
    },
  })
);

export default useStyles;
