import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    contactiblityWrapper: {
      width: '160px',
      height: '70px',
      display: 'inline-block',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
      border: '1px solid rgba(150, 150, 150, 0.11)',
      borderRadius: '10px',
      paddingTop: '10px',
      textAlign: 'center',
    },
    contactiblityNumberStyle: {
      fontSize: '1.2em',
      color: '#FFB600',
    },
  })
);

export default useStyles;
