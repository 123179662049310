import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Select from 'react-select';
import * as yup from 'yup';
import useStyles from './index.styles';

import {
  AddTriggerDrawer,
  CheckAttributeDrawer,
  EndDrawer,
  MoveToQueueDrawer,
  RunStrategyDrawer,
  UpdateAttributeDrawer,
  GetSegmentDrawer,
  ABTestingDrawer,
  RunWorkflowDrawer,
  SetWaitDrawer,
  AddLoopDrawer,
} from 'components/workflow/drawer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActionOptions } from 'utils/helper';
import Loader from 'components/loader';
import {
  setWorkflowConfig,
  setWorkflowType,
} from 'store/reducer/workflowReducer';
import { kollectAITheme } from 'config/theme';
import { workflowHandler } from 'store/reducer/workflowReducer/action';
import {
  getGraphData,
  getWorkflowSteps,
  setGraphData,
} from 'utils/workflow/graphHandler';
import {
  getPath,
  getStepValue,
  updateConfigObj,
} from 'utils/workflow/jsonModifier';

const styles = {
  control: (styles: any) => ({
    ...styles,
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
    width: '170px',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: kollectAITheme.primaryColor,
    fontSize: '0.9rem',
    fontFamily: 'Overpass',
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: kollectAITheme.primaryColor,
  }),
  menuList: (styles: any) => ({ ...styles, padding: 0 }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      fontSize: '0.9rem',
      fontFamily: 'Overpass',
      color: isSelected ? '#fff' : kollectAITheme.primaryColor,
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};

const WorkflowDrawerContent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const workflowActionData = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowType = useAppSelector(
    (state: any) => state.workflow.workflowType
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();

  const workflowSteps = getWorkflowSteps(
    graphData.config.Steps,
    workflowActionData?.name
  );

  const [loading, setLoading] = useState(false);

  const defaultOption = () => {
    const type = workflowType ? workflowType : 'CHECK_ATTRIBUTE';
    return workflowActionOptions.find((action) => action.value === type) ?? '';
  };

  const updateJsonConfig = async (configObj: any) => {
    try {
      await dispatch(
        workflowHandler({
          workflow_id: [graphData.workflow_id],
          config: JSON.stringify(configObj),
          action: 'save',
        })
      );
      setGraphData('graphData', { ...graphData, config: configObj });
      dispatch(setWorkflowConfig(true));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    let configObj = { ...graphData.config };
    const { parent, parentValue } = workflowActionData;

    const path = getPath(configObj, parent, parentValue);
    const oldData = getStepValue(configObj, path);
    let data = [{ name: '', parentValue, parent, position: 1 }];
    if (oldData.length > 1) {
      data = oldData
        .filter((item: any) => item.name !== workflowActionData.name)
        .map((el: any, index: number) => ({ ...el, position: index + 1 }));
    }
    updateConfigObj(configObj, path, data);

    updateJsonConfig(configObj);
  };

  const validationSchema = yup.object().shape({
    stepName: yup
      .string()
      .notOneOf(workflowSteps, 'Name used already!')
      .matches(/[a-zA-Z]/, 'Must contain at least one alphabet character')
      .required('Required'),
    precursorName: yup.string().required('Required'),
  });

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.workflowDrawerCont}>
        <Box display='flex' justifyContent='end' mb='1rem'>
          <Box className={classes.workflowTypeBoxStyle}>
            <Select
              placeholder=''
              options={workflowActionOptions}
              value={defaultOption()}
              onChange={(value: any) => dispatch(setWorkflowType(value.value))}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={styles}
              isSearchable={false}
              isDisabled={workflowEditor === 'create' ? false : true}
            />
          </Box>
        </Box>

        <Box>
          <Typography>Config</Typography>
          <Box className={classes.workflowHorizontalLine}></Box>

          {(workflowType === 'CHECK_ATTRIBUTE' || !workflowType) && (
            <CheckAttributeDrawer
              setLoading={setLoading}
              handleDelete={handleDelete}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'RUN_STRATEGY' && (
            <RunStrategyDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'UPDATE_ATTRIBUTE' && (
            <UpdateAttributeDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'ADD_TRIGGER' && (
            <AddTriggerDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'MOVE_TO_QUEUE' && (
            <MoveToQueueDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'GET_SEGMENTS' && (
            <GetSegmentDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'AB_TESTING' && (
            <ABTestingDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'RUN_WORKFLOW' && (
            <RunWorkflowDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'WAIT' && (
            <SetWaitDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}
          {workflowType === 'ADD_LOOP' && (
            <AddLoopDrawer
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
              validationSchema={validationSchema}
            />
          )}

          {workflowType === 'END' && (
            <EndDrawer
              setLoading={setLoading}
              validationSchema={validationSchema}
              updateJsonConfig={updateJsonConfig}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default WorkflowDrawerContent;
