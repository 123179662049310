import { styled, Box, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { kollectAITheme } from 'config/theme';

interface CustomerActionBoxProps {
  color: string;
  icon: string;
  label: string;
  value: number;
  selectedAction: string;
  svg?: string;
  svgWidth?: number;
  onClick: (x: string) => void;
}

const CustomerActionBoxWrapper = styled('div')(
  ({ selected }: { selected: boolean }) => ({
    background: selected ? kollectAITheme.primaryColor : '#fff',
    color: selected ? '#fff' : '#000',
    border: '1px solid #E6E6E6',
    display: 'flex',
    // width: '135px',
    gap: '0.8em',
    padding: '1em 1.4em',
    boxShadow:
      'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
    borderRadius: '10px',
    cursor: 'pointer',
  })
);

const CustomerActionBox: React.FC<CustomerActionBoxProps> = ({
  color,
  icon,
  label,
  value,
  selectedAction,
  svg,
  svgWidth,
  onClick,
}) => {
  const selected = label === selectedAction ? true : false;

  return (
    <CustomerActionBoxWrapper
      selected={selected}
      onClick={() => onClick(label)}
    >
      <Box mt={label === 'Call Required' && !svg ? '5px' : '3px'}>
        {svg ? (
          <img src={svg} alt='' width={svgWidth ?? 45} />
        ) : (
          <CustomIcon name={icon} size={22} color={selected ? '#fff' : color} />
        )}
      </Box>

      <Box
        sx={{
          height: '4.3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography lineHeight={1.2}>{label}</Typography>
        <Typography color={selected ? '#fff' : color} fontSize='1.2rem'>
          {value}
        </Typography>
      </Box>
    </CustomerActionBoxWrapper>
  );
};

export default CustomerActionBox;
