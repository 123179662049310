import { Checkbox } from '@mui/material';

export const CRUDCheckbox = ({
  operation,
  checked,
  disabled = false,
  handleCheck,
}: {
  operation?: string;
  checked: boolean;
  disabled?: boolean;
  handleCheck?: (x: string, y: boolean) => void;
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleCheck && handleCheck(operation!, e.target.checked)
      }
      disabled={disabled}
      sx={{ padding: 0, '&.Mui-checked': { color: disabled ? '' : '#004357' } }}
    />
  );
};
