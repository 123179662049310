import { Box, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { MultiSelect } from 'react-multi-select-component';

import { getGraphData } from 'utils/workflow/graphHandler';

interface FormikWorkflowMultiSelectProps {
  name: string;
  label?: string;
  options: any;
  noMargin?: boolean;
}

const FormikWorkflowMultiSelect: React.FC<FormikWorkflowMultiSelectProps> = ({
  name,
  label,
  options,
  noMargin,
}) => {
  const defaultValue = (value: string[]) => {
    return options
      ? options.filter((option: any) => value.includes(option.value))
      : [];
  };

  const graphData = getGraphData();

  return (
    <Box mb={`${noMargin ? 0 : '1rem'}`}>
      <label>
        <Typography sx={{ fontSize: '1rem' }}>{label}</Typography>
      </label>

      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Box mt='0.3rem'>
              <MultiSelect
                options={options}
                value={defaultValue(value)}
                onChange={(e: any) => {
                  const selected = e.map((item: any) => item.value);
                  setFieldValue(name, selected);
                }}
                hasSelectAll={false}
                disableSearch={true}
                labelledBy='Select'
                disabled={graphData.published === 1 ? true : false}
              />

              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </Box>
  );
};

export default FormikWorkflowMultiSelect;
