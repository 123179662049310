import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbContainer: {
      position: 'relative',
      padding: '1rem 2rem',
    },
    breadcrumbItems: {
      display: 'flex',

      '& p': {
        marginRight: '5px',
      },
    },
    breadcrumbPageTitle: {
      position: 'absolute',
      left: '45%',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  })
);

export default useStyles;
