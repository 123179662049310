import { Box, Typography } from '@mui/material';

const Breadcrumb = () => {
  return (
    <Box position='relative'>
      <Typography
        sx={{
          position: 'absolute',
          left: '45%',
          top: '50%',
          transform: 'translate(0, -50%)',
          fontSize: '1.5rem',
        }}
      >
        Campaign Manager
      </Typography>
    </Box>
  );
};

export default Breadcrumb;
