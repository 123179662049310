import { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import Select from 'react-select';
import { workflowSelectStyles } from './workflowSelect';

interface WorkflowSelectProps {
  selected: string;
  handleChange: (x: string) => void;
  setLoading: (x: boolean) => void;
}

const WorkflowExecutionSelect: React.FC<WorkflowSelectProps> = ({
  selected,
  setLoading,
  handleChange,
}) => {
  const workflowExecutions = useAppSelector(
    (state) => state.workflow.workflowExecutions
  );

  const [executionOptions, setExecutionOptions] = useState<any>([]);
  const [value, setValue] = useState<any>('');

  useEffect(() => {
    const options = workflowExecutions.map((item: any) => ({
      label: item.we_id,
      value: item.we_id,
    }));
    setExecutionOptions(options);
  }, [workflowExecutions]);

  useEffect(() => {
    const exec =
      executionOptions && selected
        ? executionOptions.find((option: any) => option.value === selected)
        : '';
    setValue(exec);
  }, [selected, executionOptions]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      options={executionOptions}
      components={{ IndicatorSeparator: () => null }}
      styles={workflowSelectStyles}
    />
  );
};

export default WorkflowExecutionSelect;
