import { Typography } from '@material-ui/core';
import useStyles from './index.styles';

interface ChannelBoxContainerProps {
  channelName: string;
  channelValue: number;
  channelValueColor: string;
}

const ChannelBoxContainer = (props: ChannelBoxContainerProps) => {
  const { channelName, channelValue, channelValueColor } = props;
  const classes = useStyles();
  return (
    <div className={classes.channelBoxContainer}>
      <Typography variant='caption' style={{ marginRight: '1.5rem' }}>
        {channelName}
      </Typography>
      <Typography variant='caption' style={{ color: channelValueColor }}>
        {channelValue}
      </Typography>
    </div>
  );
};

export default ChannelBoxContainer;
