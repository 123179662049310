import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { customerPiiData } from 'store/reducer/customerReducer';

interface CustomerInfoBoxProps {
  label: string;
}

const CustomerInfoBox: React.FC<CustomerInfoBoxProps> = ({ label }) => {
  const piiData = useAppSelector(customerPiiData);

  let value;
  if (label === 'Name') value = `${piiData?.first_name} ${piiData?.last_name}`;
  if (label === 'DOB') value = piiData?.date_of_birth;
  if (label === 'Security') value = piiData?.identification_sources;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1.4rem' }}>
      <Typography color='#6F6F6F'>{label}</Typography>
      <Typography sx={{ textShadow: '0 0 7px #000', color: 'transparent' }}>
        {value || 'NA'}
      </Typography>
    </Box>
  );
};

export default CustomerInfoBox;
