import { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import useStyles from './index.styles';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  filterWorkflow,
  getAllWorkflow,
  workflowHandler,
} from 'store/reducer/workflowReducer/action';
import { setWorkflowFunnel } from 'store/reducer/workflowReducer';
import { userPermissions } from 'store/reducer/auth';

import BasicModal from 'components/Modal';
import WorkflowTypeBox from './workflowTypeBox';
import {
  WorkflowDetailsTable,
  WorkflowDetailsHeader,
  CreateDuplicateWorkflowModal,
} from 'components/workflow/landing';
import Loader from 'components/loader';
import MultiDeleteModal from 'components/workflow/landing/multiDeleteModal';
import { MODULELIST } from 'constants/types';
import { isStrategyWorkflowDeletable } from 'utils/helper';
import WorkflowForceDelete from 'components/workflow/landing/forceDeleteModal';
import WorkflowHeader from './workflowHeader';

const WorkflowManagementScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [multiDeleteModal, setMultiDeleteModal] = useState<boolean>(false);
  const [workflowData, setWorkflowData] = useState<any>([]);
  const [workflowSelected, setWorkflowSelected] = useState<readonly string[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>('');
  const [workflowFilterData, setWorkflowFilterData] = useState<any>({});
  const [selectedWorkflowStatus, setSelectedWorkflowStatus] =
    useState<string>('');
  const [openForceDelete, setOpenForceDelete] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<
    { strategy: string; usedList: string[] }[]
  >([]);
  const [isSearchData, setIsSearchData] = useState(false);

  const getWorkflow = useCallback(async () => {
    try {
      setLoading(true);
      const { data, message, status } = await dispatch(
        getAllWorkflow()
      ).unwrap();
      if (message === 'success' && status === 'SUCCESS') {
        const published = data.filter((item: any) => item.published);
        const unpublished = data.filter((item: any) => !item.published);
        setWorkflowData(data);
        setWorkflowFilterData({ published, unpublished });
      }
      dispatch(setWorkflowFunnel({ viewFunnel: false, workflowId: '' }));
      setLoading(false);
    } finally {
      setLoading(false);
      setIsSearchData(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getWorkflow();
  }, [getWorkflow]);

  const handleCreateModalClose = () => setOpenCreateModal(false);
  const handleEditModalClose = () => setOpenEditModal(false);
  const handleMultiDeleteClose = () => setMultiDeleteModal(false);

  const handleDelete = async () => {
    try {
      let payload: any = { workflow_id: workflowSelected, action: 'delete' };
      const { meta, data } = await dispatch(workflowHandler(payload)).unwrap();
      if (meta?.success) {
        const { deletable, usedList } = isStrategyWorkflowDeletable(data);
        if (!deletable) {
          setDeleteData(usedList);
          setMultiDeleteModal(false);
          setOpenForceDelete(true);
        } else {
          await dispatch(
            workflowHandler({ ...payload, force_delete: true })
          ).unwrap();
          setWorkflowSelected([]);
          handleMultiDeleteClose();
          getWorkflow();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleForceDelete = async () => {
    setLoading(true);
    try {
      await dispatch(
        workflowHandler({
          workflow_id: workflowSelected,
          action: 'delete',
          force_delete: true,
        })
      ).unwrap();
      setWorkflowSelected([]);
      handleMultiDeleteClose();
      getWorkflow();
      setOpenForceDelete(false);
    } finally {
      setLoading(false);
    }
  };

  const onEdit = () => {
    if (workflowSelected.length > 0) {
      setOpenEditModal(true);
      setActionType('edit');
    } else {
      setOpenInfoModal(true);
    }
  };

  const onDelete = () => {
    if (workflowSelected.length < 1) {
      setOpenInfoModal(true);
    } else {
      setActionType('delete');
      if (workflowSelected.length > 1) {
        setMultiDeleteModal(true);
      } else {
        handleDelete();
      }
    }
  };

  const onSearch = useCallback(
    async (filterParam: string, value: any) => {
      try {
        setLoading(true);
        let res;
        if (value !== '') {
          let payload: any = { field_name: filterParam };
          if (filterParam === 'created') {
            payload = {
              ...payload,
              startDate: moment(value?.startDate).format('YYYY-MM-DD'),
              endDate: moment(value?.endDate).format('YYYY-MM-DD'),
            };
          } else {
            payload.value = value;
          }
          res = await dispatch(filterWorkflow(payload)).unwrap();
        } else {
          res = await dispatch(getAllWorkflow()).unwrap();
        }

        const { data, message } = res;
        if (message === 'success') {
          const published = data.filter((item: any) => item.published);
          const unpublished = data.filter((item: any) => !item.published);
          setWorkflowData(data);
          setWorkflowFilterData({ published, unpublished });
        } else {
          setWorkflowData([]);
          setWorkflowFilterData({ published: [], unpublished: [] });
        }
      } finally {
        setLoading(false);
        setIsSearchData(true);
      }
    },
    [dispatch]
  );

  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ marginLeft: '6px' }}>
        <WorkflowHeader />

        <Grid container justifyContent='center'>
          <Grid item md={3} sm={4} xs={6}>
            <WorkflowTypeBox
              title='Published workflow'
              iconName='published'
              iconColor='#00C087'
              value={
                workflowFilterData?.published
                  ? workflowFilterData?.published.length
                  : 0
              }
              selected={selectedWorkflowStatus === 'published' ? true : false}
              onClick={() => {
                const status =
                  selectedWorkflowStatus === 'published' ? '' : 'published';
                setSelectedWorkflowStatus(status);
              }}
            />
          </Grid>

          <Grid item md={3} sm={4} xs={6}>
            <WorkflowTypeBox
              title='Un-Published workflow'
              iconName='unpublished'
              iconColor='#FFB600'
              value={
                workflowFilterData?.unpublished
                  ? workflowFilterData?.unpublished.length
                  : 0
              }
              selected={selectedWorkflowStatus === 'unpublished' ? true : false}
              onClick={() => {
                const status =
                  selectedWorkflowStatus === 'unpublished' ? '' : 'unpublished';
                setSelectedWorkflowStatus(status);
              }}
            />
          </Grid>

          {permissions[MODULELIST.WORKFLOW].includes('create') && (
            <Grid item md={3} sm={4} xs={6}>
              <Box
                className={classes.createWorkflowWrapper}
                onClick={() => setOpenCreateModal(true)}
              >
                <Box className={classes.workflowCreateBoxStyle}>
                  <Add style={{ fontSize: '2rem' }} />
                  <Typography fontSize={'1.2rem'}>
                    Create New Workflow
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        <Box sx={{ margin: '3rem 2rem 0' }}>
          <WorkflowDetailsHeader
            isEmpty={!workflowData.length}
            onEditClick={onEdit}
            onDelete={onDelete}
            onSearch={onSearch}
          />

          <WorkflowDetailsTable
            data={
              selectedWorkflowStatus === 'published'
                ? workflowFilterData?.published
                : selectedWorkflowStatus === 'unpublished'
                ? workflowFilterData?.unpublished
                : workflowData
            }
            selected={workflowSelected}
            setSelected={setWorkflowSelected}
            hanldeCreateModal={() => setOpenCreateModal(true)}
            isSearchData={isSearchData}
          />
        </Box>

        <BasicModal
          open={openCreateModal}
          onClose={handleCreateModalClose}
          children={
            <CreateDuplicateWorkflowModal
              setLoading={setLoading}
              isCreate={true}
              handleClose={handleCreateModalClose}
              workflowData={workflowData}
            />
          }
        />

        <BasicModal
          open={openEditModal}
          onClose={handleEditModalClose}
          children={
            <CreateDuplicateWorkflowModal
              setLoading={setLoading}
              selectedWorkflow={workflowSelected}
              handleClose={handleEditModalClose}
              workflowData={workflowData}
            />
          }
        />

        <BasicModal
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          children={
            <Box>
              <Typography>
                {actionType === 'edit'
                  ? 'Please select a workflow to duplicate and edit'
                  : 'Please select a workflow to delete'}
              </Typography>
            </Box>
          }
        />

        <BasicModal
          open={multiDeleteModal}
          onClose={handleMultiDeleteClose}
          children={
            <MultiDeleteModal
              onClose={handleMultiDeleteClose}
              workflowSelected={workflowSelected}
              onConfirm={handleDelete}
            />
          }
        />

        <WorkflowForceDelete
          open={openForceDelete}
          deleteData={deleteData}
          onClose={() => setOpenForceDelete(false)}
          handleForceDelete={handleForceDelete}
        />
      </Box>
    </>
  );
};

export default WorkflowManagementScreen;
