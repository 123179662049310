import { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
} from 'reactflow';
import dagre from 'dagre';
import { GrBladesVertical, GrBladesHorizontal } from 'react-icons/gr';

import { parser } from 'utils/workflow/jsonParser';

import 'reactflow/dist/style.css';
import './index.css';

import { getGraphConfig } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getGraphConfigData } from 'utils/workflow/graphHandler';
import { setWorkflowConfig } from 'store/reducer/workflowReducer';

const getNodeWidthHeight = (className: string) => {
  if (className === 'circle') {
    return { height: 60, width: 60 };
  }
  if (className === 'add') {
    return { height: 70, width: 220 };
  }
  if (className === 'decisioncircle') {
    return { height: 3, width: 3 };
  }
  if (className === 'label') {
    return { height: 70, width: 220 };
  }
  if (className === 'text') {
    return { height: 27, width: 120 };
  }
  return { height: 0, width: 0 };
};

const getLayoutedElements = (nodes: any, edges: any, direction = 'TB') => {
  const isHorizontal = direction === 'LR';

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node: any) => {
    const { height, width } = getNodeWidthHeight(node.className);
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    const { height, width } = getNodeWidthHeight(node.className);
    node.position = {
      x: nodeWithPosition.x - width / 2,
      y: nodeWithPosition.y - height / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const LayoutFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const dispatch = useAppDispatch();
  const [layoutDirection, setLayoutDirection] = useState('TB');
  const refetchWorkflow = useAppSelector(
    (state) => state.workflow.refetchWorkflow
  );

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  const onLayout = useCallback(
    (direction: string) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      setLayoutDirection(direction);
    },
    [nodes, edges]
  );

  useEffect(() => {
    const config = getGraphConfigData();
    const jsonConfig = getGraphConfig(config);

    const { nodes, edges } = parser(JSON.stringify(jsonConfig));
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
    dispatch(setWorkflowConfig(false));
  }, [refetchWorkflow]);

  return (
    <Box className='layoutflow'>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        nodesConnectable={false}
        nodesDraggable={false}
        elementsSelectable={true}
      >
        <Background />
        <Controls showInteractive={false} />
      </ReactFlow>
      <div className='graphDisplayControls'>
        {layoutDirection === 'LR' ? (
          <button onClick={() => onLayout('TB')}>
            <GrBladesVertical />
          </button>
        ) : (
          <button onClick={() => onLayout('LR')}>
            <GrBladesHorizontal />
          </button>
        )}
      </div>
    </Box>
  );
};

export default LayoutFlow;
