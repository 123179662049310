import { styled } from '@mui/material';

export const RoleButton = styled('div')(() => ({
  background: '#004357',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '6px',
  cursor: 'pointer',
  padding: '8px 1.2rem',
  '& p': {
    color: '#fff',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));

export const UserTableWrapper = styled('div')(() => ({
  background: '#fff',
  boxShadow:
    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  marginTop: '1rem',
}));

export const UserTableHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
}));

export const ExportButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 1.2rem',
  cursor: 'pointer',
  border: '1px solid rgba(109, 120, 141, 0.5)',
  borderRadius: '6px',
  '& svg': { fontSize: '1.2rem', color: '#8592A3' },
  '& p': { color: '#8592A3', textTransform: 'uppercase' },
}));

export const AddUser = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  '& input': {
    border: '1px solid rgba(50, 71, 92, 0.22)',
    borderRadius: '6px',
    fontSize: '1rem',
    width: '350px',
    padding: '10px',
    outline: 'none',
  },
}));
