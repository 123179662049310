import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupCellTitle: {},
    strategyDetailsTable: {
      borderCollapse: 'collapse',
      background: '#fff',
      width: '100%',
      marginBottom: 8,
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid #CCCCCC',
      '& td': {
        border: '1px solid #E6E6E6',
        padding: 8,
      },
      '& table': {
        width: '100%',
        tableLayout: 'fixed',
      },
    },
    tableHeader: {
      fontWeight: 500,
      color: theme.kollectAITheme.primaryColor,
    },
    nestedTable: {
      tableLayout: 'fixed',
      '& td': {
        border: 'none',
        textAlign: 'center',
      },
    },
    abtestingColumn: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },

    strategyViewColumn: {
      cursor: 'pointer',
      textAlign: 'center',
    },
  })
);

export default useStyles;
