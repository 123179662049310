import * as React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import {
  campaignSummary,
  rerunCampaign,
} from 'store/reducer/campaignReducer/action';
import { successToast } from 'utils/toast';
import useStyles, { ActionButton } from './index.styles';
import { Form, Formik, FormikValues } from 'formik';
import FormikCampaignDatePicker from 'components/formik/campaign/FormikCampaignDatePicker';
import FormikCampaignTimePicker from 'components/formik/campaign/FormikCampaignTimePicker';
import moment from 'moment';
import * as yup from 'yup';
import { CampaignScheduleType } from 'constants/types';
import { getScheduleTime } from 'utils/campaign/campaignModifier';

const RerunCampaign = ({
  open,
  campaign,
  onClose,
  setLoading,
  setCurrentPage,
}: {
  open: boolean;
  campaign: any;
  onClose: () => void;
  setLoading: (x: boolean) => void;
  setCurrentPage?: (x: number) => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [schedulePreference, setSchedulePreference] =
    React.useState<CampaignScheduleType>(CampaignScheduleType.NOW);

  const handleSubmit = async (values: FormikValues) => {
    try {
      setLoading(true);
      // const schedule_time =
      //   schedulePreference === CampaignScheduleType.NOW
      //     ? 'now'
      //     : {
      //         date: moment(values.date).format('YYYY-MM-DD'),
      //         time: values.time,
      //       };
      const schedule_time =
        schedulePreference === CampaignScheduleType.LATER
          ? getScheduleTime({
              date: moment(values.date).format('YYYY-MM-DD'),
              time: values.time,
            })
          : 'now';
      const { message } = await dispatch(
        rerunCampaign({
          campaign_id: campaign?.campaign_id,
          schedule_time,
        })
      ).unwrap();
      successToast(message || '');
      // @ts-ignore
      await dispatch(campaignSummary({ limit: 10, offset: 0 }));
      setCurrentPage && setCurrentPage(1);
      onClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const validationSchema =
    schedulePreference === CampaignScheduleType.NOW
      ? yup.object().shape({})
      : yup.object().shape({
          date: yup.date().required('Required').nullable(),
          time: yup.string().required('Required'),
        });

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setSchedulePreference(CampaignScheduleType.NOW);
      }}
      disableEnforceFocus={false}
      className={classes.modal}
    >
      <Box sx={{ width: '650px' }} className={classes.modalCotent}>
        <Box className={classes.contentTitle}>
          <Typography align='center' fontSize='1.2rem'>
            Please confirm below details to rerun mentioned campaign
          </Typography>
        </Box>

        <Box className={classes.rerunBody}>
          <Box className={classes.campaignNameCont}>
            <Typography minWidth={250}>Campaign to run again</Typography>
            <Typography className={classes.campaignName}>
              {campaign?.campaign_name}
            </Typography>
          </Box>

          <Box className={classes.scheduleOptionsCont}>
            <Typography
              className={`${classes.scheduleOptions} ${
                schedulePreference === CampaignScheduleType.NOW &&
                classes.scheduleSelected
              }`}
              onClick={() => setSchedulePreference(CampaignScheduleType.NOW)}
            >
              Trigger Now
            </Typography>
            <Typography
              className={`${classes.scheduleOptions} ${
                schedulePreference === CampaignScheduleType.LATER &&
                classes.scheduleSelected
              }`}
              onClick={() => setSchedulePreference(CampaignScheduleType.LATER)}
            >
              Schedule for Later
            </Typography>
          </Box>

          <Box>
            <Formik
              initialValues={{ date: null, time: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                {schedulePreference === CampaignScheduleType.NOW && (
                  <Typography my='2rem'>
                    Campaign will be triggered in 1 minute of submission
                  </Typography>
                )}

                {schedulePreference === CampaignScheduleType.LATER && (
                  <Box mt='1.5rem'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography minWidth={250}>
                        Date of the Campaign
                      </Typography>
                      <FormikCampaignDatePicker name='date' />
                    </Box>
                    <Box display='flex' alignItems='center' mt='1rem'>
                      <Typography minWidth={250}>
                        Time of the Campaign
                      </Typography>
                      <FormikCampaignTimePicker name='time' />
                    </Box>
                  </Box>
                )}

                <Box className={classes.actionButtonCont}>
                  <ActionButton color='#6AC5AB'>
                    <Typography>Rerun</Typography>
                  </ActionButton>

                  <ActionButton type='button' color='#DAA09A' onClick={onClose}>
                    <Typography>Cancel</Typography>
                  </ActionButton>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RerunCampaign;
