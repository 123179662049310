import {
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { SlArrowDown } from 'react-icons/sl';

interface FormikCampaignSelectProps {
  placeholder?: string;
  name: string;
  options: any;
  handleChange?: (x: string) => void;
}

const FormikCampaignSelectWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FormikCampaignSelect: React.FC<FormikCampaignSelectProps> = ({
  placeholder,
  name,
  options,
  handleChange,
}) => {
  return (
    <FormikCampaignSelectWrapper>
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <FormControl sx={{ minWidth: 200, zIndex: 10 }}>
              <Select
                // displayEmpty
                value={value || ''}
                onChange={(e: SelectChangeEvent) => {
                  setFieldValue(name, e.target.value);
                  handleChange && handleChange(e.target.value);
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  position: 'relative',
                  borderRadius: 0,
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  textAlign: 'center',
                  '.MuiSelect-select': {
                    padding: '12px 10px 10px 14px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                IconComponent={() => (
                  <SlArrowDown
                    style={{
                      fontSize: '1.1rem',
                      marginRight: '1rem',
                      position: 'absolute',
                      right: 0,
                    }}
                  />
                )}
              >
                {/* <MenuItem disabled value=''>
                  <Typography color='#757575'>{placeholder}</Typography>
                </MenuItem> */}
                {options?.map((type: any, index: number) => (
                  <MenuItem value={type.value} key={index}>
                    {type?.label}
                  </MenuItem>
                ))}
              </Select>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </FormControl>
          );
        }}
      </Field>
    </FormikCampaignSelectWrapper>
  );
};

export default FormikCampaignSelect;
