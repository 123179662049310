import React from 'react';
import { Box, Switch, Typography } from '@mui/material';
import useStyles from './index.styles';

const CustomResponseBox = ({
  index,
  checked,
  onChange,
}: {
  index: number;
  checked: boolean;
  onChange: (x: any, y: number) => void;
}) => {
  const classes = useStyles();
  return (
    <Box mt='5px' display='flex' alignItems='center' gap='1rem'>
      <Typography color='#004357' fontWeight={600} fontSize='0.9rem'>
        Customer Response Expected
      </Typography>
      <Switch
        checked={checked}
        onChange={(e: any) => onChange(e, index)}
        className={classes.switch}
      />
    </Box>
  );
};

export default CustomResponseBox;
