import * as React from 'react';
import { Box } from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useAppSelector } from 'hooks/useRedux';
import { penetrationData } from 'store/reducer/campaignReducer';

// const data = [
//   { name: 'Mon', sent: 240 },
//   { name: 'Tue', sent: 360 },
//   { name: 'Wed', sent: 220 },
//   { name: 'Thu', sent: 456 },
//   { name: 'Fri', sent: 334 },
//   { name: 'Sat', sent: 428 },
//   { name: 'Sun', sent: 367 },
// ];

export default function CommunicationGraph() {
  const penetration = useAppSelector(penetrationData)?.distribution?.results;

  const [engagementData, setEngagementData] = React.useState([]);

  React.useEffect(() => {
    if (penetration && penetration.length) {
      const engagement = penetration.map((item: any) => ({
        name: item.weekday.substring(0, 3),
        total_engagement: item.this_day_engagement_sum,
      }));
      setEngagementData(engagement);
    }
  }, [penetration]);

  return (
    <Box style={{ width: '100%', height: 180 }}>
      <ResponsiveContainer>
        <AreaChart
          width={230}
          height={200}
          data={engagementData}
          margin={{ top: 10, right: 30, left: 60, bottom: 0 }}
        >
          <defs>
            <linearGradient id='sent' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#004357' stopOpacity={0.5} />
              <stop offset='100%' stopColor='#004357' stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey='name' tickLine={false} axisLine={false} />
          <CartesianGrid strokeDasharray='5' vertical={false} />
          <Tooltip />
          <Area
            type='monotone'
            dataKey='total_engagement'
            stroke='#004357'
            strokeWidth={3}
            fillOpacity={1}
            fill='url(#sent)'
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}
