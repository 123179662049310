import { useState } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { BsAlarm } from 'react-icons/bs';
import TimePicker from 'react-time-picker';
import { useAppDispatch } from 'hooks/useRedux';
import { workflowHandler } from 'store/reducer/workflowReducer/action';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getScheduleTime } from 'utils/campaign/campaignModifier';

interface ActivatingWorkflowModalProps {
  data: any;
  onClose: () => void;
}

interface ButtonProps {
  backgroundColor: string;
}

const ActivationInput = styled('input')({
  outline: 'none',
  border: '1px solid rgba(150, 150, 150, 0.11)',
  padding: '5px 10px',
  fontSize: '1rem',
  opacity: '0.5',
  boxShadow:
    'inset 0px 4px 4px rgb(0 0 0 / 25%), inset 0px 4px 4px rgb(0 0 0 / 25%)',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const Button = styled('button')((props: ButtonProps) => ({
  background: props.backgroundColor,
  color: '#fff',
  fontFamily: 'inherit',
  padding: '6px 30px',
  fontSize: '1rem',
  borderRadius: '3px',
}));

const ActivatingWorkflowModal: React.FC<ActivatingWorkflowModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workflow_id, wf_name } = data;

  const [time, setTime] = useState<string>('');

  const handleConfirm = async () => {
    if (time) {
      const date = moment().format('YYYY-MM-DD');
      const scheduleTime = getScheduleTime({ date, time });
      try {
        await dispatch(
          workflowHandler({
            workflow_id: [workflow_id],
            action: 'activate',
            schedule_to_run: scheduleTime,
          })
        );
        navigate('/workflow');
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Typography align='center' fontSize='1.2rem' sx={{ fontWeight: 600 }}>
        Activating Workflow
      </Typography>

      <Box sx={{ maxWidth: '450px', margin: '2rem auto 1.5rem' }}>
        <Typography sx={{ fontWeight: 600, color: '#8E8E8E' }}>
          Please confirm that you wish to activate{' '}
          <span style={{ color: '#000' }}>{wf_name}</span>. Enter the daily run
          schedule for your workflow
        </Typography>
      </Box>

      <Box display='flex' justifyContent='center' mb='1.5rem'>
        <ActivationInput
          type='time'
          onChange={(e) => setTime(e.target.value)}
          value={time}
        />
      </Box>

      <ButtonWrapper>
        <Button backgroundColor='#6AC5AB' onClick={handleConfirm}>
          Confirm
        </Button>
        <Button backgroundColor='#DAA09A' onClick={onClose}>
          Cancel
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ActivatingWorkflowModal;
