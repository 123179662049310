import { Box, Drawer, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import WorkflowDrawerContent from 'containers/Workflow/editor/drawerContent';
import {
  setAddBranch,
  setWorkflowActionData,
  setWorkflowEditor,
  setWorkflowType,
  toggleDrawer,
} from 'store/reducer/workflowReducer';
import { getGraphData, setWorkflowAction } from 'utils/workflow/graphHandler';
import { getPath, getStepValue } from 'utils/workflow/jsonModifier';
import { BiPlus } from 'react-icons/bi';
// import { userAccess } from 'utils/helper';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

interface AddStartWorkflowStepProps {
  id: string;
  text: any;
}

const AddStartWorkflowStep: React.FC<AddStartWorkflowStepProps> = (props) => {
  const dispatch = useAppDispatch();

  const graphData: any = getGraphData();
  // const userEmail = useAppSelector((state: any) => state.auth.email);
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const handleStep = () => {
    const configObj = { ...graphData.config };
    const path = getPath(configObj, 'start', 'start');
    const value = getStepValue(configObj, path);
    const position =
      value.length === 1 && !value[0].name
        ? 1
        : value.length === 1 && value[0].name
        ? 2
        : value.length + 1;
    const data = { name: '', parent: 'start', parentValue: 'start', position };
    dispatch(setWorkflowEditor('create'));
    dispatch(setWorkflowType(''));
    dispatch(setWorkflowActionData(data));
    setWorkflowAction('workflowActionData', data);
    dispatch(toggleDrawer(true));
    dispatch(setAddBranch(true));
  };

  // const userPermissions = userAccess(userRole)?.workflow.permissions;
  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          '& svg': {
            cursor: 'pointer',
            color: '#00C087',
          },
        }}
      >
        <Typography fontSize='0.9rem' fontWeight={600}>
          Start
        </Typography>
        {/* {!graphData.published && userPermissions.includes('update') && ( */}
        {!graphData.published &&
          permissions[MODULELIST.WORKFLOW].includes('update') && (
            <Box
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)',
                bottom: '-9px',
                display: 'block',
                zIndex: 999,
                filter: 'drop-shadow(1px 4px 2px #000)',
              }}
              onClick={handleStep}
            >
              <Box
                sx={{
                  background: '#fff',
                  borderRadius: '50%',
                  border: '1px solid #00C087',
                  height: '13px',
                  width: '13px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // filter: 'drop-shadow(1px 15px 8px #000)',
                }}
              >
                <BiPlus color='#00C087' />
              </Box>
            </Box>
          )}
      </Box>

      <Drawer
        anchor={'right'}
        open={false}
        onClose={() => dispatch(toggleDrawer(false))}
      >
        <WorkflowDrawerContent />
      </Drawer>
    </>
  );
};

export default AddStartWorkflowStep;
