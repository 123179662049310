import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: '1.2rem',
      display: 'flex',
      gap: '1rem',
    },
    formLabel: {
      width: '120px',
    },
    formInput: {
      width: '100%',
      border: '1px solid #E0E7FF',
      height: '38px',
      borderRadius: '5px',
      padding: '0 1em',
      fontSize: '0.9em',
      fontFamily: 'inherit',

      '&:focus': {
        outline: 'none',
      },
    },
    formTextarea: {
      width: 400,
      height: 120,
      background: 'rgba(224, 231, 255, 0.2)',
      border: '1px solid #E0E7FF',
      borderRadius: '5px',
      padding: '0.5em 1em',
      fontFamily: 'inherit',
      fontSize: '0.9em',

      '&:focus': {
        outline: 'none',
      },
    },
    formErrorMessage: {
      marginTop: 0,
      color: '#d32f2f',
      fontSize: '0.8em',
    },
    createSubmitCont: {
      marginTop: '1.5rem',
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'center',
    },
    createSubmitButton: {
      background: '#2C62EC',
      borderRadius: '8px',
      border: 'transparent',
      color: '#fff',
      fontSize: '1rem',
      padding: '0.8rem 2rem',
      fontWeight: 500,
    },
  })
);

export default useStyles;
