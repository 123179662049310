import { Box, Typography } from '@mui/material';
import { AiFillAudio } from 'react-icons/ai';
import { IoIosAttach } from 'react-icons/io';
import useStyles from './index.styles';

import { ChatButton } from '../index.styles';

const CustomerChatTypeBox = () => {
  const classes = useStyles();
  return (
    <Box className={classes.typeBoxWrapper}>
      <input
        type='text'
        placeholder='Type your message or select template to send'
      />
      <Box className={classes.typeBoxIconCont}>
        <AiFillAudio />
        <IoIosAttach />
        <ChatButton>
          <Typography>SEND</Typography>
        </ChatButton>
      </Box>
    </Box>
  );
};
export default CustomerChatTypeBox;
