import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { AiOutlineDelete } from 'react-icons/ai';
import { PermissionButton } from 'containers/Permissions/styles';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { DeleteBackButton, DeleteIconBox } from './styles';
import { useAppDispatch } from 'hooks/useRedux';
import Loader from 'components/loader';
import {
  deleteUser,
  getUserSummary,
  updateUser,
} from 'store/reducer/userReducer/action';

export const DeleteActiveUser = ({
  setAction,
  onClose,
  data,
  setPage,
}: {
  setAction: (x: string) => void;
  onClose: () => void;
  setPage: () => void;
  data: any;
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await dispatch(deleteUser({ user_id: data?.user_id }));
      await dispatch(getUserSummary({ limit: 10, offset: 0 }));
      onClose();
      setPage();
    } catch (error) {}
    setLoading(false);
  };

  const handleDeactivate = async () => {
    setLoading(true);
    let payload = {
      ...data,
      notify: false,
      is_active: 0,
      user_id: data?.user_id,
    };
    try {
      await dispatch(updateUser(payload));
      await dispatch(getUserSummary({ limit: 10, offset: 0 }));
      onClose();
      setPage();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <Box width='650px'>
        <DeleteBackButton onClick={() => setAction('')}>
          <HiOutlineArrowSmLeft />
        </DeleteBackButton>

        <Stack direction='row' justifyContent='center' mt='4rem'>
          <DeleteIconBox>
            <AiOutlineDelete />
          </DeleteIconBox>
        </Stack>

        <Box p='2rem 3.5rem'>
          <Box
            sx={{
              '& p': {
                fontWeight: 500,
                color: '#32475C99',
                fontSize: '1.1rem',
              },
            }}
          >
            <Typography mb='1rem' align='center'>
              Are you sure you want to permanently delete this account?
            </Typography>
            <Typography align='center'>
              If you intend to reuse this account later, deactivate the account
              instead. That will stop the access to your user now but will allow
              you to activate it in future.
            </Typography>
          </Box>
        </Box>

        <Stack
          direction='row'
          justifyContent='space-between'
          mt='2rem'
          gap='1rem'
        >
          <PermissionButton background='#6F6F6F' onClick={handleDeactivate}>
            <Typography>deactivate instead</Typography>
          </PermissionButton>
          <PermissionButton background='#BB7065' onClick={handleDelete}>
            <Typography>confirm & delete</Typography>
          </PermissionButton>
        </Stack>
      </Box>
    </>
  );
};
