import { Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { kollectAITheme } from 'config/theme';

const ViewButton = styled('button')({
  color: '#fff',
  padding: '12px 22px',
  fontSize: '1em',
  background: kollectAITheme.primaryColor,
  borderRadius: '30px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

  '& p': {
    fontWeight: 500,
    fontSize: '0.9rem',
  },
});

const ViewDetails = ({ customerId }: { customerId: string }) => {
  const navigate = useNavigate();

  return (
    <ViewButton onClick={() => navigate(`/customer-details/${customerId}`)}>
      <Typography>View Details</Typography>
    </ViewButton>
  );
};
export default ViewDetails;
