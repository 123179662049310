import * as React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from '../../index.styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
// import { updateAbTesting } from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import {
  // getCampaignDetails,
  updateAbTesting,
} from 'store/reducer/campaignReducer/action';
import { setCampaign, setCampaignPayload } from 'store/reducer/campaignReducer';
import { getAbTesting } from 'utils/campaign/campaignModifier';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
// import { setCampaignPayload } from 'store/reducer/campaignReducer';
// import { filterAbData } from 'utils/helper';

const ABTestingSplit = ({ campaignData }: { campaignData: any }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const splits = campaignData?.default_ab_data;

  const permissions = useAppSelector(userPermissions);

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (campaignData) {
      let rows = [];
      for (let key in splits) {
        const row = { ...splits[key], split: key };
        rows.push(row);
      }
      setData(rows);
    }
  }, [splits, campaignData]);

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    row: any
  ) => {
    const { value } = e.target;
    if (+value <= 100) {
      setLoading(true);
      try {
        let payload: any = {
          campaign_id: campaignData?.campaign_id,
        };
        let splits = { a: '', b: '' };
        if (row.split === 'A') {
          splits.a = value;
          splits.b = `${100 - +value}`;
          payload.a_dist = value;
        }
        if (row.split === 'B') {
          splits.a = `${100 - +value}`;
          splits.b = value;
          payload.b_dist = value;
        }
        const newData = data.map((item: any) => {
          if (item.split === 'A') return { ...item, distribution: splits.a };
          if (item.split === 'B') return { ...item, distribution: splits.b };
          return {};
        });

        setData(newData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSplits = async () => {
    setLoading(true);
    try {
      const campaign_id = campaignData?.campaign_id;
      const res = await dispatch(
        updateAbTesting({
          campaign_id,
          a_dist: data['0']?.distribution,
          b_dist: data['1']?.distribution,
        })
      ).unwrap();
      const value = getAbTesting(res, campaignData?.default_ab_data);
      const newCampaign = { ...campaignData, default_ab_data: value };
      dispatch(setCampaign(newCampaign));
      dispatch(
        setCampaignPayload({
          key: 'add_filter',
          data: { filter: 'ab_testing', value },
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box mt='1rem'>
        <Typography>
          Will allow you to split your population into random groups with
          specific distribution percentage, thereby allow you to do AB Testing
          using different channels and creatives. You can even add more
          distribution splits (ABC or more) or have only sub-section of split (A
          with a limited range) to evaluate treatment vs no treatment.
        </Typography>

        <Box ml='5rem' mt='2rem' position='relative'>
          <table className={classes.tableStyle}>
            <tr>
              <th></th>
              <th></th>
              <th>
                <Typography># Customers</Typography>
              </th>
              <th>
                <Typography>$ Outstanding</Typography>
              </th>
              <th>
                <Typography>Average DPD</Typography>
              </th>
              <th>
                <Typography>% Distribution</Typography>
              </th>
            </tr>
            {data.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Typography>{index + 1}.</Typography>
                  </td>
                  <td>
                    <Typography>
                      Split{' '}
                      <span style={{ textTransform: 'uppercase' }}>
                        {row.split}
                      </span>
                    </Typography>
                  </td>
                  <td>
                    <Typography>{row['#customers']}</Typography>
                  </td>
                  <td>
                    <Typography>
                      {row.outstanding_balance &&
                        `$ ${row.outstanding_balance.toLocaleString()}`}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{row.average_dpd}</Typography>
                  </td>
                  <td>
                    <input
                      type='number'
                      value={row.distribution}
                      onChange={(e) => handleChange(e, row)}
                      className={classes.splitInput}
                    />
                  </td>
                </tr>
              );
            })}
          </table>

          {permissions[MODULELIST.CAMPAIGN].includes('update') && (
            <Box sx={{ position: 'absolute', right: '25%', bottom: '5px' }}>
              <button className={classes.updateSplitBtn} onClick={handleSplits}>
                <Typography fontWeight={500} mt='2px'>
                  Update Splits
                </Typography>
                <BsFillCheckCircleFill color='#fff' fontSize='1.1rem' />
              </button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ABTestingSplit;
