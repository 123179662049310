import { Box, Checkbox, styled, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';
import { Field, FieldProps } from 'formik';

interface FormikCampaignCheckboxProps {
  label: string;
  name: string;
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const FormikCampaignCheckbox: React.FC<FormikCampaignCheckboxProps> = ({
  label,
  name,
}) => {
  return (
    <Wrapper>
      <label>
        <Typography sx={{ minWidth: '250px', opacity: '0.6' }}>
          {label}
        </Typography>
      </label>

      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;

          return (
            <>
              <Box display='flex' alignItems='center' gap='10px'>
                <Checkbox
                  disableRipple
                  {...field}
                  checked={value ? value : false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = event.target;
                    setFieldValue(name, checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    padding: 0,
                    color: kollectAITheme.primaryColor,
                    '&.Mui-checked': {
                      color: kollectAITheme.primaryColor,
                    },
                  }}
                />
              </Box>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </>
          );
        }}
      </Field>
    </Wrapper>
  );
};

export default FormikCampaignCheckbox;
