import { styled } from '@mui/material';

export const RoleNameBox = styled('div')(({ isEdit }: { isEdit: boolean }) => ({
  padding: '1.5rem 2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& input': {
    background: isEdit ? '#F4F8FB' : '#fff',
    border: '1px solid rgba(150, 150, 150, 0.11)',
    boxShadow: !isEdit ? 'inset 0px 4px 4px rgb(0 0 0 / 25%)' : 'unset',
    borderRadius: isEdit ? '6px' : '2px',
    textTransform: isEdit ? 'capitalize' : 'none',
    fontSize: '1rem',
    outline: 'none',
    padding: '12px 1rem 10px',
    // minWidth: '250px',
    fontFamily: 'Open Sans',
    '&::placeholder': { color: '#32475C61', fontFamily: 'Open sans' },
  },
}));

export const SearchBar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px 2rem',
  '& svg': { fontSize: '1.3rem', color: '#32475C61' },
  '& input': {
    border: 'transparent',
    padding: '10px',
    fontSize: '1rem',
    width: '100%',
    color: '#32475CDE',
    outline: 'none',
    fontFamily: 'Open sans',
    '&::placeholder': { color: '#32475C61', fontFamily: 'Open sans' },
  },
}));

export const TableWrapper = styled('div')(() => ({
  background: '#fff',
  borderTop: '1px solid rgba(50, 71, 92, 0.50)',
  borderBottom: '1px solid rgba(50, 71, 92, 0.50)',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
}));

export const ActionWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  padding: '1rem',
}));

export const TableActionWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& svg': {
    color: '#32475C8A',
    fontSize: '1.4rem',
    cursor: 'pointer',
  },
}));
