import { SplitType } from 'components/workflow/drawer/abTesting/abTesting';

export const getGraphData = (key: string = 'graphData') => {
  return JSON.parse(localStorage.getItem(key) || '{}');
};

export const getWorkflowActionData = (key: string = 'workflowActionData') => {
  return JSON.parse(localStorage.getItem(key) || '{}');
};

export const setGraphData = (key: string = 'graphData', value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const setWorkflowAction = (
  key: string = 'workflowActionData',
  value: any
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getGraphConfigData = (key: string = 'graphData') => {
  const graphData = JSON.parse(localStorage.getItem(key) || '{}');
  return graphData?.config;
};

export const getWorkflowSteps: any = (jsonArr: any, current: string) => {
  let children: any = [];

  const flattenMembers = jsonArr.map((m: any) => {
    if (m.haschildren && Object.keys(m.haschildren).length) {
      let childrens: any = [];
      for (const [_, value] of Object.entries(m.haschildren)) {
        const child: any = value;
        child.forEach((item: any) => {
          childrens.push(item);
        });
      }
      children = [...children, ...childrens];
    }
    return m.name;
  });

  const steps = flattenMembers.concat(
    children.length ? getWorkflowSteps(children) : children
  );

  return steps.filter((step: string) => step && step !== current);
};

export const getSegment = (segment: string) => {
  if (segment === 'DPD 1-30') return '1';
  if (segment === 'DPD 31-60') return '2';
  if (segment === 'DPD 61-90') return '3';
  if (segment === 'DPD 91-120') return '4';
  if (segment === 'DPD >120') return '5';
  if (segment === 'Low') return '1-10000';
  if (segment === 'Med') return '10001-30000';
  if (segment === 'High') return '>30000';
  return segment;
};

export const getSelectedSegments = (segment: string, selected: string[]) => {
  if (segment === 'DPD_bucket') {
    return selected.map((seg: string) => {
      if (seg === '1') return 'DPD 1-30';
      if (seg === '2') return 'DPD 31-60';
      if (seg === '3') return 'DPD 61-90';
      if (seg === '4') return 'DPD 91-120';
      if (seg === '5') return 'DPD >120';
      return '';
    });
  }
  if (segment === 'balance_category') {
    return selected.map((seg: string) => {
      if (seg === '1-10000') return 'Low';
      if (seg === '10001-30000') return 'Med';
      if (seg === '>30000') return 'High';
      return '';
    });
  }
  return selected;
};

export const getActionName = (name: string) => {
  if (name === '1') return 'DPD 1-30';
  if (name === '2') return 'DPD 31-60';
  if (name === '3') return 'DPD 61-90';
  if (name === '4') return 'DPD 91-120';
  if (name === '5') return 'DPD >120';
  if (name === '1-10000') return 'Low';
  if (name === '10001-30000') return 'Med';
  if (name === '>30000') return 'High';
  return name;
};

export const convertSegments = (segments: any) => {
  const allowedSegment: any = [];

  for (let key in segments) {
    const value = segments[key];
    const valueAttribute = ['DPD_bucket', 'balance_level'].includes(key)
      ? 'segment'
      : 'allowed_values';
    console.log({ valueAttribute });
    const allowedValues = value.map((segment: any) => segment[valueAttribute]);

    const segmentKey = key === 'balance_level' ? 'balance_category' : key;
    allowedSegment.push({ [segmentKey]: allowedValues });
  }

  return allowedSegment;
};

export const workflowAbTestingSplitPayload = (
  splits: SplitType[],
  stepName: string
) => {
  let splitPayload: any = {};
  let splitChildren: any = {};

  splits.forEach(({ split, value }, index) => {
    const totalPerviousDistribution = splits
      .slice(0, index)
      .reduce((acc, current) => (acc += current.value), 0);
    const startPercent =
      index !== 0 && totalPerviousDistribution !== 100
        ? totalPerviousDistribution + 1
        : totalPerviousDistribution;
    splitPayload[split] = [startPercent, totalPerviousDistribution + value];
    splitChildren[split] = [
      { parent: stepName, parentValue: split, position: 1, name: '' },
    ];
  });

  return { splitPayload, splitChildren };
};

export const parseWorkflowAbTestingSplit = (
  splits: Record<string, number[]>
) => {
  const parsedSplit = Object.entries(splits).map(([key, [start, end]], ind) => {
    return {
      split: key,
      value: ind === 0 || end - start === 0 ? end - start : end - start + 1,
    };
  });

  return parsedSplit;
};
