import { Box, Typography } from '@mui/material';

import { kollectAITheme } from 'config/theme';
import CustomerChatTypeBox from './typeBox';
import CustomerChatHeader from './header';
import CustomerChatBody from './chatBody';

const CustomerChatMessenger = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#32475C0A',
      }}
    >
      <CustomerChatHeader />

      <Box
        sx={{
          height: 'calc(100vh - 500px)',
          overflow: 'auto',
        }}
      >
        <CustomerChatBody />
      </Box>

      <Box p='1rem'>
        <Box>
          <Typography
            sx={{
              color: kollectAITheme.primaryColor,
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            Select template
          </Typography>
        </Box>

        <CustomerChatTypeBox />
      </Box>
    </Box>
  );
};

export default CustomerChatMessenger;
