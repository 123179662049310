import { styled, Box, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { workflowHandler } from 'store/reducer/workflowReducer/action';
import { useNavigate } from 'react-router-dom';

interface DeactivatingWorkflowModalProps {
  data: any;
  onClose: () => void;
}

interface ButtonProps {
  backgroundColor: string;
}

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const Button = styled('button')((props: ButtonProps) => ({
  background: props.backgroundColor,
  color: '#fff',
  fontFamily: 'inherit',
  padding: '6px 30px',
  fontSize: '1rem',
  borderRadius: '3px',
}));

const DeactivatingWorkflowModal: React.FC<DeactivatingWorkflowModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workflow_id, wf_name } = data;

  const handleConfirm = async () => {
    try {
      await dispatch(
        workflowHandler({
          workflow_id: [workflow_id],
          action: 'deactivate',
        })
      );
      navigate('/workflow');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Typography align='center' fontSize='1.2rem' sx={{ fontWeight: 600 }}>
        Deactivating Workflow?
      </Typography>

      <Box sx={{ maxWidth: '450px', margin: '2rem auto 1.5rem' }}>
        <Typography sx={{ fontWeight: 600, color: '#8E8E8E' }}>
          Please confirm that you wish to deactivate workflow{' '}
          <span style={{ color: '#000' }}>{wf_name}</span>.
        </Typography>

        <Typography mt='1.5rem' color='#8E8E8E'>
          Note: Daily communication planner will not run this workflow after
          deactivation
        </Typography>
      </Box>

      <ButtonWrapper>
        <Button backgroundColor='#6AC5AB' onClick={handleConfirm}>
          Confirm
        </Button>
        <Button backgroundColor='#DAA09A' onClick={onClose}>
          Cancel
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default DeactivatingWorkflowModal;
