import { useState, useEffect } from 'react';
import { styled, Box, Typography } from '@mui/material';
import { RiFileCopyLine } from 'react-icons/ri';
import ReactJson from 'react-json-view';
import { kollectAITheme } from 'config/theme';
import { infoToast } from 'utils/toast';
import useStyles from './index.styles';

// import jsonData from '../api_doc.json';
// import jsonData from '../integration_api_doc.json';
import jsonData from '../new_integration_api_doc.json';

import IntegrationEndpoints from './endpoints';
import GenerateAPIKey from './GenerateApiKey';
import TestAPIModal from './TestModal';
import TryAPIModal from './TryApi';

interface Endpoint {
  label: string;
  value: string;
}

const HorizontalLine = styled('div')({
  width: '100%',
  height: '1px',
  background: '#cccccc',
});

export const apiMethodContentColor = (api_method: string) => {
  if (api_method === 'GET') return '#66BCE8';
  if (api_method === 'POST') return '#6AC5AB';
  if (api_method === 'DELETE') return '#E4897B';
  return '#047D95';
};

const IntegrationApiCall = () => {
  const apiJson: any = jsonData;

  const [endPoints, setEndPoints] = useState<Endpoint[] | []>([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState<string>('');
  const [selectedResponse, setSelectedResponse] = useState<string>('success');
  const [getApiScreen, setGetApiScreen] = useState<boolean>(false);
  const [openTest, setOpenTest] = useState<boolean>(false);
  const [testData, setTestData] = useState<any>({});
  const [openTry, setOpenTry] = useState<boolean>(false);

  const classes = useStyles({ getApiScreen });

  useEffect(() => {
    let endpoints: Endpoint[] = [];
    for (const key in apiJson) {
      const spacedKey = key.split('_').join(' ');
      endpoints.push({ label: spacedKey, value: key });
    }
    setEndPoints(endpoints);
    setSelectedEndpoint(endpoints[0]['value']);
  }, [apiJson]);

  const endpointClick = (endpoint: string) => {
    setGetApiScreen(false);
    setSelectedEndpoint(endpoint);
    setSelectedResponse('success');
    document.getElementById(endpoint)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleApiScreen = () => setGetApiScreen(!getApiScreen);

  const apiMethodColor = () => {
    if (apiJson[selectedEndpoint]?.api_method === 'GET')
      return '#66BCE8 !important';
    if (apiJson[selectedEndpoint]?.api_method === 'POST')
      return '#6AC5AB !important';
    if (apiJson[selectedEndpoint]?.api_method === 'DELETE')
      return '#E4897B !important';
    return '#047D95 !important';
  };

  return (
    <>
      <Box className={classes.apiCallWrapper}>
        <IntegrationEndpoints
          endPoints={endPoints}
          selectedEndpoint={selectedEndpoint}
          handleEndpoint={endpointClick}
          handleApiScreen={handleApiScreen}
        />

        {!getApiScreen ? (
          <>
            <Box className={classes.apiCallContentWrapper}>
              {Object.keys(apiJson).map((key: string, index: number) => {
                // @ts-ignore
                const data = apiJson[key];
                const {
                  title,
                  description,
                  api_url,
                  base_url,
                  api_method,
                  attributes,
                  example_request,
                } = data;

                console.log(
                  '🚀 ~ file: index.tsx:104 ~ {Object.keys ~ data:',
                  data
                );

                return (
                  <Box padding='0 1em' id={key} key={index}>
                    <Typography
                      sx={{
                        padding: '10px 0',
                        fontWeight: 700,
                        fontSize: '1.1em',
                      }}
                    >
                      {title}
                    </Typography>
                    <HorizontalLine />

                    <Typography className={classes.contentBoxStyle}>
                      {description}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography className={classes.contentHeadingStyle}>
                        URL
                      </Typography>
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          color: '#6AC5AB',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          setTestData({
                            api_method,
                            api_url,
                            // request:
                            //   apiJson[selectedEndpoint]?.example_request,
                            request: example_request,
                            base_url,
                          });
                          if (key === 'register_new_user') {
                            setOpenTest(true);
                          }
                          if (
                            key === 'update_user_details' ||
                            key === 'get_user_details'
                          ) {
                            // setTestData({
                            //   api_method,
                            //   api_url,
                            //   request:
                            //     apiJson[selectedEndpoint]?.example_request,
                            //   base_url,
                            // });
                            setOpenTry(true);
                          }
                        }}
                      >
                        Try me
                      </Typography>
                    </Box>
                    <HorizontalLine />

                    <Box className={classes.contentUrlBox}>
                      <Box
                        className={classes.contentApiMethodBox}
                        sx={{ background: apiMethodContentColor(api_method) }}
                      >
                        <Typography fontWeight={500}>{api_method}</Typography>
                      </Box>
                      <Typography color={kollectAITheme.primaryColor}>
                        {api_url}
                      </Typography>
                    </Box>

                    <Typography className={classes.contentHeadingStyle}>
                      Headers
                    </Typography>
                    <HorizontalLine />

                    <Box className={classes.contentBoxStyle}>
                      <Typography mb='1em' fontSize='0.95em'>
                        Only API Key for respective environment is required to
                        run the request.
                      </Typography>
                      <Box>
                        <Typography>Format would be:</Typography>
                        <Box display='flex' justifyContent='space-between'>
                          <Typography>{`x-api-key: {{ api_key }}`}</Typography>
                          <button>
                            <Typography
                              sx={{
                                color: '#6AC5AB',
                                textDecoration: 'underline',
                              }}
                            >
                              get key
                            </Typography>
                          </button>
                        </Box>
                      </Box>
                    </Box>

                    <Typography className={classes.contentHeadingStyle}>
                      Attributes
                    </Typography>
                    <HorizontalLine />

                    <Box className={classes.contentBoxStyle} mb='2em'>
                      {attributes.map((attribute: any, index: number) => {
                        const attributeName = Object.keys(attribute)[0];
                        const {
                          attribute_type,
                          attribute_description,
                          child_attributes,
                        } = attribute[attributeName];

                        return (
                          <Box key={index}>
                            <Box
                              className={classes.attributeHeaderStyle}
                              mb='1em'
                            >
                              <Typography color={kollectAITheme.primaryColor}>
                                {attributeName}
                              </Typography>
                              <Typography fontSize='0.85em' color='#6F6F6F'>
                                {attribute_type}
                              </Typography>
                            </Box>

                            <Typography>{attribute_description}</Typography>

                            {child_attributes && (
                              <Box mt='1em'>
                                <Typography>Child Atrributes</Typography>
                                <Box p='1.5em'>
                                  {Object.keys(child_attributes[0]).map(
                                    (child: any, index: number) => {
                                      const childData =
                                        child_attributes[0][child];

                                      return (
                                        <Box key={index} mb='0.8em'>
                                          <Box
                                            className={
                                              classes.attributeHeaderStyle
                                            }
                                            mb='0.8em'
                                          >
                                            <Typography
                                              color={
                                                kollectAITheme.primaryColor
                                              }
                                            >
                                              {child}
                                            </Typography>
                                            <Typography
                                              fontSize='0.85em'
                                              color='#6F6F6F'
                                            >
                                              {childData.attribute_type}
                                            </Typography>
                                          </Box>
                                          <Typography>
                                            {childData.attribute_description}
                                          </Typography>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box className={classes.apiCallJsonViewerWrapper}>
              <Box className={classes.jsonViewerRequestWrapper}>
                <Box className={classes.jsonViewerRequestCont}>
                  <Typography color={apiMethodColor()}>
                    {apiJson[selectedEndpoint]?.api_method}
                  </Typography>
                  <Typography>{apiJson[selectedEndpoint]?.api_url}</Typography>
                </Box>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      apiJson[selectedEndpoint]?.api_url
                    );
                    infoToast('Copied to clipboard!');
                  }}
                >
                  <RiFileCopyLine color='#fff' fontSize='1.5em' />
                </button>
              </Box>

              <Box className={classes.jsonViewerExampleWrapper}>
                <Box className={classes.jsonViewerExampleCont}>
                  <Typography>Example Request</Typography>
                  <button
                    onClick={() => {
                      const copy = JSON.stringify(
                        apiJson[selectedEndpoint]?.example_request
                      );
                      navigator.clipboard.writeText(copy);
                      infoToast('Copied to clipboard!');
                    }}
                  >
                    <RiFileCopyLine color='#fff' fontSize='1.5em' />
                  </button>
                </Box>

                <Box>
                  <ReactJson
                    name={null}
                    src={apiJson[selectedEndpoint]?.example_request}
                    theme='monokai'
                    enableClipboard={false}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                </Box>

                <Box
                  className={classes.jsonViewerExampleCont}
                  margin='1.5em auto 0.5em'
                >
                  <Typography>Example Response</Typography>
                  <button
                    onClick={() => {
                      const json =
                        selectedResponse === 'success'
                          ? apiJson[selectedEndpoint]?.example_success_response
                          : apiJson[selectedEndpoint]?.example_error_response;
                      const copy = JSON.stringify(json);
                      navigator.clipboard.writeText(copy);
                      infoToast('Copied to clipboard!');
                    }}
                  >
                    <RiFileCopyLine color='#fff' fontSize='1.5em' />
                  </button>
                </Box>

                <Box>
                  <Box className={classes.jsonViewerResponseCont}>
                    <button
                      className={
                        selectedResponse === 'success'
                          ? classes.selectedResponseStyle
                          : ''
                      }
                      onClick={() => setSelectedResponse('success')}
                    >
                      <Typography>Success Response</Typography>
                    </button>
                    <button
                      className={
                        selectedResponse === 'error'
                          ? classes.selectedResponseStyle
                          : ''
                      }
                      onClick={() => setSelectedResponse('error')}
                    >
                      <Typography>Error Response</Typography>
                    </button>
                  </Box>
                  <Box>
                    <ReactJson
                      name={null}
                      src={
                        selectedResponse === 'success'
                          ? apiJson[selectedEndpoint]?.example_success_response
                          : apiJson[selectedEndpoint]?.example_error_response
                      }
                      theme='monokai'
                      enableClipboard={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <GenerateAPIKey />
        )}
      </Box>

      <TestAPIModal
        open={openTest}
        onClose={() => setOpenTest(false)}
        data={testData}
      />

      <TryAPIModal
        open={openTry}
        onClose={() => setOpenTry(false)}
        data={testData}
      />
    </>
  );
};

export default IntegrationApiCall;
