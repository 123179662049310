import { styled } from '@mui/material';

export const PermissionTableWrapper = styled('div')(() => ({
  background: '#fff',
  boxShadow:
    '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  marginTop: '1rem',
}));

export const AddPermission = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '1rem',
  justifyContent: 'end',
  '& input': {
    border: '1px solid rgba(50, 71, 92, 0.22)',
    borderRadius: '6px',
    fontSize: '1rem',
    width: '350px',
    padding: '10px',
    outline: 'none',
  },
}));

export const AssignedToBox = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  alignItems: 'center',
  gap: '10px',
}));

export const ActionsWrapper = styled('div')(
  ({ svgSize = '1.4rem' }: { svgSize?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '& svg': { fontSize: svgSize, color: '#32475C8A', cursor: 'pointer' },
  })
);
