import React from 'react';
import { styled, Typography } from '@mui/material';
import { IoChevronBackOutline } from 'react-icons/io5';

const ResetButton = styled('button')(() => ({
  width: '100%',
  background: '#004357',
  textAlign: 'center',
  color: '#fff',
  padding: '10px 1rem',
  boxShadow:
    '0px 1px 6px 2px rgba(50, 71, 92, 0.06), 0px 2px 6px 1px rgba(50, 71, 92, 0.04), 0px 1px 4px 2px rgba(50, 71, 92, 0.02)',
  borderRadius: '8px',
  textTransform: 'uppercase',
}));

const BackToLoginButton = styled('button')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  marginTop: '1rem',
  color: '#004357',
  '& svg': { color: '#004357', fontSize: '1.2rem' },
}));

const InputWrapper = styled('div')(() => ({
  margin: '1.5rem auto 1rem',
  '& input': {
    border: '1px solid rgba(50, 71, 92, 0.22)',
    borderRadius: '6px',
    outline: 'none',
    width: '91%',
    padding: '12px 1rem',
    fontSize: '1rem',
    fontFamily: 'Open sans',
  },
}));

const ForgotPassword = ({
  email,
  validation,
  handleEmailChange,
  handleSend,
  onClose,
}: {
  email: string;
  validation: string;
  handleEmailChange: (x: string) => void;
  handleSend: () => void;
  onClose: () => void;
}) => {
  return (
    <>
      <Typography
        fontWeight={600}
        color='rgba(50, 71, 92, 0.87)'
        fontSize='1.4rem'
      >
        Forgot Password 🔒
      </Typography>

      <Typography mt='5px' color='rgba(50, 71, 92, 0.6)'>
        Enter your email and we'll send you instructions to reset your password
      </Typography>

      <InputWrapper>
        <input
          type='text'
          placeholder='Email'
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e.target.value)
          }
        />
        {validation && (
          <Typography fontSize='0.8rem' color='#d32f2f'>
            {validation}
          </Typography>
        )}
      </InputWrapper>

      <ResetButton onClick={handleSend}>
        <Typography>Send Reset Link</Typography>
      </ResetButton>

      <BackToLoginButton onClick={onClose}>
        <IoChevronBackOutline />
        <Typography>Back to login</Typography>
      </BackToLoginButton>
    </>
  );
};

export default ForgotPassword;
