import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { BiChevronRight } from 'react-icons/bi';
import useStyles from './index.styles';

import CustomerActions from 'components/customer/CustomerAction';
import CustomerDetails from 'components/customer/CustomerDetails';
import CustomerSideTable from 'components/customer/CustomerSideTable';
import Loader from 'components/loader';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  attributeCurrentValue,
  getAgentActionLists,
  getCallRequestAttempts,
  getCustomerActions,
  getCustomerActivity,
  getCustomerAgentsList,
  getCustomerProfile,
  getCustomerQueue,
  getHardshipDetails,
} from 'store/reducer/customerReducer/action';
import { awsUrl, userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import {
  selectedCustomerProduct,
  setCustomerPiiData,
  setSelectedCustomerProduct,
  setSelectedCustomersArr,
} from 'store/reducer/customerReducer';
import DetailsHeader from './detailsHeader';
import CustomerChatScreen from 'components/customer/CustomerChat';
import CollapsedAction from 'components/customer/CustomerAction/collapsedActions';
import { lambdaCustomerPIIData } from 'apis/aws/lambdaCustomerPIIData';

const CustomerDetailsPage = () => {
  const classes = useStyles();
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  const customerCategory = useAppSelector(
    (state) => state.customer.customerCategory
  );
  const selectedCustomer = useAppSelector(selectedCustomerProduct);
  const statusUpdateAWSUrl = useAppSelector(awsUrl)?.cust_mgmt_get_pii_data_api;
  const permissions = useAppSelector(userPermissions);
  const email = useAppSelector((state: any) => state.auth.email);

  const [loading, setLoading] = useState<boolean>(false);
  const [availableActions, setAvailableActions] = useState<any>({});
  const [selectedStatus, setSelectedStatus] = useState<string>(
    customerCategory || 'Default'
  );
  const [serviceable, setServiceable] = useState<boolean>(true);
  const [customerTimestamp, setCustomerTimestamp] = useState<Date | null>(null);
  const [collapseAction, setCollapseAction] = useState(false);

  const fetchProfileData = async () => {
    setLoading(true);
    try {
      await dispatch(getCustomerQueue({ customerId: id }));

      const res = await dispatch(
        getCustomerProfile({ customerId: id })
      ).unwrap();
      if (res) {
        const product = res?.[0];
        !selectedCustomer &&
          dispatch(
            setSelectedCustomerProduct(product?.product_suscribed || '')
          );
        await Promise.all(
          res.map((el: any) =>
            dispatch(
              attributeCurrentValue({
                customerOdfId: el?.odf_customer_id,
              })
            )
          )
        );
        if (product?.current_status === 'Hardship') {
          await dispatch(getHardshipDetails({ customer_id: id }));
        }
        if (product?.current_status === 'Call_Required') {
          await dispatch(getCallRequestAttempts());
        }
      }
      await dispatch(getCustomerActivity({ customerId: id }));
      await dispatch(getAgentActionLists({ customer_id: id }));
      const timestamp = res[0]?.workable_flag_added_timestamp || null;
      const workable = res[0]?.workable_flag || false;
      setServiceable(workable);
      setCustomerTimestamp(timestamp);
      const { payload: customerActionPayload } = await dispatch(
        getCustomerActions({ customerId: id })
      );
      if (customerActionPayload?.config?.add_task === 'True')
        await dispatch(getCustomerAgentsList({ customer_id: id }));
      if (customerActionPayload?.config) {
        setAvailableActions(customerActionPayload.config);
      }
      const piiData = await lambdaCustomerPIIData({
        customer_id: id,
        awsUrl: statusUpdateAWSUrl!,
      });
      dispatch(setCustomerPiiData(piiData));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
    dispatch(setSelectedCustomersArr([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const showActions =
    !isEmpty(availableActions) &&
    permissions[MODULELIST.CUSTOMER].includes('update');

  const showCall =
    email && email === 'yash+50@opendatafabric.com' ? true : false;

  return (
    <>
      <Loader loading={loading} />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns:
            showActions && collapseAction ? '1fr 70px' : '1fr',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Box
          className={classes.customerDetailsWrapper}
          sx={{
            gridTemplateColumns: showCall ? '1fr' : '400px 1fr',
            gap: showCall ? '0px' : '1rem',
            padding: showCall ? '2rem 0 0 1rem' : '2rem 2rem 0',
          }}
        >
          {!showCall && (
            <CustomerSideTable
              status={selectedStatus}
              setStatus={setSelectedStatus}
            />
          )}

          <Box
            className={classes.customerDetailsCont}
            mr={showCall ? '1rem' : ''}
          >
            <Box
              className={classes.detailsContentCont}
              sx={{
                gridTemplateColumns:
                  showActions && !showCall && !collapseAction
                    ? '1fr 220px'
                    : showActions && !showCall && collapseAction
                    ? '1fr'
                    : showActions && showCall
                    ? // ? '1fr 550px 220px'
                      '49% 35% 14%'
                    : !showActions && showCall
                    ? '1fr 550px'
                    : '1fr',
              }}
            >
              <Box>
                <DetailsHeader
                  serviceable={serviceable}
                  setServiceable={setServiceable}
                  setLoading={setLoading}
                  customerTimestamp={customerTimestamp}
                />
                <CustomerDetails status={selectedStatus} />
              </Box>

              {/* {showCall && <CustomerCallScreen />} */}
              {showCall && <CustomerChatScreen />}

              {showActions && !collapseAction && (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    className={classes.hideBtn}
                    onClick={() => setCollapseAction(true)}
                  >
                    <BiChevronRight size={22} />
                  </Box>
                  <CustomerActions
                    // status={selectedStatus}
                    availableActions={availableActions}
                    fetchData={fetchProfileData}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {showActions && collapseAction && (
          <CollapsedAction
            // status={selectedStatus}
            availableActions={availableActions}
            fetchData={fetchProfileData}
            onExpand={() => setCollapseAction(false)}
          />
        )}
      </Box>
    </>
  );
};

export default CustomerDetailsPage;
