import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';
import { kollectAITheme } from 'config/theme';

export const HTMLPreviewButton = styled('button')(
  ({ active }: { active: boolean }) => ({
    background: active ? kollectAITheme.primaryColor : '#EBEEF2',
    padding: active ? '8px 1.5rem' : '8px 1rem',
    borderRadius: '20px',
    color: active ? '#fff' : '#8F9499',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templatePreviewBox: {
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      overflow: 'auto',
    },
    htmlSwitchButtonCont: {
      display: 'inline-flex',
      alignItems: 'center',
      background: '#EBEEF2',
      borderRadius: '20px',
    },
  })
);

export default useStyles;
