import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
// import { useAppSelector } from 'hooks/useRedux';

export default function ProductLevelDetails({
  productData,
}: {
  productData: any;
}) {
  // const productDetails: any = useAppSelector(
  //   (state) => state.customer.customerProfile?.[0]
  // )?.product_details;

  if (!productData) return <></>;

  const {
    amount_outstanding,
    balance_bucket,
    deliquent_since,
    dpd,
    dpd_bucket,
    due_date,
    number_of_loans,
    payment_collected,
  } = productData;

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            padding: '10px 0',
          },
        }}
        aria-label='caption table'
      >
        <TableBody>
          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Amount Outstanding:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>${amount_outstanding.toLocaleString()}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Due date:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{due_date}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`DPD:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{dpd}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`DPD Bucket:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{dpd_bucket}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Balance Bucket:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{balance_bucket}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Number of Loans:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography
                sx={{ textDecoration: 'underline', color: '#2C62EC' }}
              >
                {number_of_loans}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Delinquent since:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>
                {moment(deliquent_since).format('DD/MM/YYYY')}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align='left'>
              <Typography color='#115180'>{`Payment Collected:`}</Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography>{payment_collected}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
