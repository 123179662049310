import { createAsyncThunk } from '@reduxjs/toolkit';

import templateApi from 'apis/template';
import templateEndpoint from 'apis/template/config';

export const viewChannelTemplate = createAsyncThunk(
  `${templateEndpoint.viewBucketChannelTemplate}Get`,
  async (payload: any) => {
    const { data } = await templateApi.viewBucketChannelTemplate(payload);
    return data;
  }
);

export const createTwoWayTemplate = createAsyncThunk(
  `${templateEndpoint.viewChannelTemplateTwoWay}Get`,
  async (payload: any) => {
    const { data } = await templateApi.createTwoWayTemplate(payload);
    return data;
  }
);

export const createTemplate = createAsyncThunk(
  `${templateEndpoint.viewBucketChannelTemplate}Post`,
  async (payload: any) => {
    const { data } = await templateApi.createTemplate(payload);
    return data;
  }
);

export const updateTwoWayTemplate = createAsyncThunk(
  `${templateEndpoint.viewChannelTemplateTwoWay}Patch`,
  async (payload: any) => {
    const { data } = await templateApi.updateTwoWayTemplate(payload);
    return data;
  }
);

export const updateTemplate = createAsyncThunk(
  `${templateEndpoint.viewBucketChannelTemplate}Patch`,
  async (payload: any) => {
    const { data } = await templateApi.updateTemplate(payload);
    return data;
  }
);

export const deleteTemplate = createAsyncThunk(
  `${templateEndpoint.viewBucketChannelTemplate}Delete`,
  async (payload: any) => {
    const { data } = await templateApi.deleteTemplate(payload);
    return data;
  }
);

export const fetchVariableResolverAttributes = createAsyncThunk(
  `${templateEndpoint.fetchVariableResolverAttributes}Post`,
  async (payload: any) => {
    const { data } = await templateApi.fetchVariableResolverAttributes(payload);
    return data;
  }
);

export const bulkTemplateUpload = createAsyncThunk(
  `${templateEndpoint.bulkTemplateUpload}Post`,
  async (payload: any) => {
    const { data } = await templateApi.bulkTemplateUpload(payload);
    return data;
  }
);

export const validateTemplate = createAsyncThunk(
  `${templateEndpoint.validateTemplate}Post`,
  async (payload: { pii: string[]; non_pii: string[] }) => {
    const { data } = await templateApi.validateTemplate(payload);
    return data;
  }
);

export const getTemplateAttributes = createAsyncThunk(
  `${templateEndpoint.templateAttributes}Get`,
  async () => {
    const { data } = await templateApi.getTemplateAttributes();
    return data;
  }
);
