import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { BiRightArrowAlt } from 'react-icons/bi';
import { InviteButton, InviteText } from './styles';
import * as yup from 'yup';

import {
  FormikRolesInput,
  FormikRolesSelect,
  FormikRolesSwitch,
} from 'components/formik/rolesPermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { roleList } from 'store/reducer/userReducer';
import Loader from 'components/loader';
import { addUser, getUserSummary } from 'store/reducer/userReducer/action';

const validationSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  email: yup.string().required('Required'),
  role_id: yup.string().required('Required'),
});

export const MemberInvitation = ({
  setSent,
}: {
  setSent: (x: boolean) => void;
}) => {
  const dispatch = useAppDispatch();

  const [roleOptions, setRoleOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const roles = useAppSelector(roleList);

  React.useEffect(() => {
    if (roles) {
      const names = roles?.role_id_name;
      const ids = roles?.role_id_list;
      const options = ids.map((id, index) => ({
        label: names[index],
        value: id,
      }));
      setRoleOptions(options);
    }
  }, [roles]);

  const handleSubmit = async (values: {
    first_name: string;
    last_name: string;
    email: string;
    role_id: string;
    two_f_a: boolean;
  }) => {
    setLoading(true);
    try {
      const res = await dispatch(addUser(values));
      if (res?.payload) {
        await dispatch(getUserSummary({ limit: 10, offset: 0 }));
        setSent(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <InviteText>
        <Typography>Invite member to your KollectAI workspace</Typography>
      </InviteText>

      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          two_f_a: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Stack direction='row' spacing={2} alignItems='center' mb='1rem'>
              <FormikRolesInput
                name='first_name'
                placeholder='First Name of the Member'
              />
              <FormikRolesInput
                name='last_name'
                placeholder='Last Name of the Member'
              />
            </Stack>

            <FormikRolesInput
              name='email'
              placeholder='Enter the email (where invitation will be sent)'
              width={650}
            />

            <Box my='1rem'>
              <FormikRolesSelect
                name='role_id'
                placeholder='Select Role for the Member'
                options={roleOptions}
              />
            </Box>

            <FormikRolesSwitch
              name='two_f_a'
              label='Want user to do two factor authentication for verification?'
            />

            <Stack direction='row' justifyContent='center' mt='2rem'>
              <button type='submit'>
                <InviteButton>
                  <Typography>INVITE</Typography>
                  <BiRightArrowAlt />
                </InviteButton>
              </button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
