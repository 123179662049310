import * as React from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmptyScreen from "components/EmptyScreen";

export default function TemplateTable({
  data,
  selectedChannel,
  selectedTemplates,
  setSelectedTemplates,
  setPreviewTemplate,
  previewTemplate,
}: {
  data: any[];
  selectedChannel: string;
  selectedTemplates: any[];
  setSelectedTemplates: (x: any[]) => void;
  setPreviewTemplate: React.Dispatch<React.SetStateAction<any>>;
  previewTemplate: any;
}) {
  const navigate = useNavigate();
  const sortedData = data
    ?.slice()
    ?.sort(
      (a: any, b: any) =>
        new Date(b?.update_ts).valueOf() - new Date(a?.update_ts).valueOf()
    );

  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);

  const onTemplateChange = (template: any) => {
    let selected = [...selectedTemplates];
    if (!selectedTemplates.includes(template)) {
      selected.push(template);
    } else {
      selected = selected.filter((item) => item.id !== template.id);
    }
    setSelectedTemplates(selected);
  };

  const onSelectAll = () => {
    if (isAllSelected) {
      setSelectedTemplates([]);
    } else {
      setSelectedTemplates(data);
    }
  };

  React.useEffect(() => {
    if (data?.length === selectedTemplates?.length) setIsAllSelected(true);
    else setIsAllSelected(false);
  }, [selectedTemplates, data]);

  const isIndeterminate = React.useMemo(
    () =>
      selectedTemplates.length >= 1 && data.length > selectedTemplates.length,
    [data, selectedTemplates]
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onChange={onSelectAll}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
            <TableCell>
              <Typography>TEMPLATE NAME</Typography>
            </TableCell>
            <TableCell>
              <Typography>STATUS</Typography>
            </TableCell>
            {selectedChannel !== "email" && (
              <TableCell>
                <Typography>TYPE</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row: any) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
                background: previewTemplate.id === row.id ? "#e9f5df" : "",
              }}
              // onClick={() => onTemplateChange(row)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedTemplates.includes(row)}
                  onChange={() => onTemplateChange(row)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell onClick={() => setPreviewTemplate(row)}>
                <Typography>{row.template_name}</Typography>
              </TableCell>
              <TableCell onClick={() => setPreviewTemplate(row)}>
                {row.status ? (
                  <Typography
                    sx={{
                      padding: "5px 10px",
                      background: "#6AC5AB",
                      borderRadius: "7px",
                      color: "#fff",
                      textAlign: "center",
                      display: "inline",
                    }}
                  >
                    Live
                  </Typography>
                ) : (
                  <Typography>Inactive</Typography>
                )}
              </TableCell>
              {selectedChannel !== "email" && (
                <TableCell>
                  <Typography>
                    {row.two_way_flag ? "2-way" : "1-way"}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(!data || !data?.length) && (
        <EmptyScreen
          onClick={() =>
            navigate("create", {
              state: { channel: selectedChannel, isBack: false },
            })
          }
        />
      )}
    </TableContainer>
  );
}
