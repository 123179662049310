import { kollectAITheme } from 'config/theme';

export const reactSelectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    borderColor: '#fff',
    '&:hover': { borderColor: '#fff' },
  }),
  singleValue: (styles: any) => ({ ...styles, color: '#fff' }),
  placeholder: (styles: any) => ({ ...styles, color: '#fff' }),
  menuList: (styles: any) => ({ ...styles, padding: 0 }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};
