import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikCampaignInputProps {
  label: string;
  name: string;
  subLabel?: string;
  placeholder?: string;
  width?: number | string;
  disabled?: boolean;
}

const FormikCampaignInputWrapper = styled('div')(
  ({ width }: { width?: number | string }) => ({
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '& label': { width: '240px' },
    '& input': {
      border: '1px solid #EAEAEA',
      borderRadius: '2px',
      padding: '5px 0.7rem 0',
      fontFamily: 'Overpass',
      outline: 'none',
      fontSize: '1rem',
      minHeight: '38px',
      width,
    },
  })
);

const FormikCampaignInput: React.FC<FormikCampaignInputProps> = ({
  label,
  name,
  subLabel,
  placeholder = '',
  width = 240,
  disabled = false,
}) => {
  return (
    <FormikCampaignInputWrapper width={width}>
      <label>
        <Typography fontSize='1.2rem' fontWeight={600}>
          {label}{' '}
          {subLabel && <span style={{ fontSize: '0.9rem' }}>{subLabel}</span>}
        </Typography>
      </label>

      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <Box width='100%'>
            <input
              type='text'
              placeholder={placeholder}
              {...field}
              disabled={disabled}
            />
            {meta.touched && meta.error && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                {meta.error}
              </Typography>
            )}
          </Box>
        )}
      </Field>
    </FormikCampaignInputWrapper>
  );
};

export default FormikCampaignInput;
