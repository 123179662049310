import { Typography } from '@material-ui/core';
import CustomIcon from 'components/customIcons';
import useStyles from './index.styles';

export type OverviewBoxProps = {
  iconName?: string;
  iconSize?: number;
  iconColor?: string;
  overviewText: string;
  overviewNumber: number | string;
};

const OverviewBox: React.FC<OverviewBoxProps> = ({
  iconName,
  iconSize,
  iconColor,
  overviewText,
  overviewNumber,
}) => {
  const classes = useStyles({ color: iconColor ? iconColor : '#FFB600' });
  return (
    <div className={classes.overViewBoxContainer}>
      <div>
        <CustomIcon
          name={`${iconName ? iconName : 'users-customer-icon'}`}
          size={iconSize ? iconSize : 32}
          color={`${iconColor ? iconColor : '#FFB600'}`}
        />
      </div>
      <div style={{ marginLeft: '0.9em' }}>
        <Typography variant='body2'>{overviewText}</Typography>
        <Typography variant='body2' className={classes.numberStyle}>
          {overviewNumber}
        </Typography>
      </div>
    </div>
  );
};

export default OverviewBox;
