import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useRedux';
import { PermissionLink, RoleHeader } from './styles';

import { RoleLists } from 'components/rolesPermissions/roles/roleLists';
import { UsersList } from 'components/rolesPermissions/roles/users';
import {
  getAllRole,
  getPermissionList,
} from 'store/reducer/roleReducer/action';
import Loader from 'components/loader';

const RolesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchLists = React.useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch(getPermissionList()),
        dispatch(getAllRole()),
      ]);
    } catch (error) {}
    setLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <>
      <Loader loading={loading} />

      <Box
        sx={{
          margin: '2rem 2rem 0',
          '& p': { color: '#32475CDE', fontFamily: 'Open sans' },
        }}
      >
        <Box mb='1rem'>
          <RoleHeader>
            <Typography fontSize='1.5rem' fontWeight={500}>
              Roles List
            </Typography>

            <PermissionLink onClick={() => navigate('/permissions')}>
              <Typography>Permission List</Typography>
            </PermissionLink>
          </RoleHeader>

          <Typography color='#32475C99'>
            A role provided access to predefined menus and features so that
            depending on assigned role an administrator can have access to what
            they need
          </Typography>
        </Box>

        <RoleLists />

        <UsersList />
      </Box>
    </>
  );
};

export default RolesScreen;
