import { FormikValues } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

export const changeStatusHandler = (
  status: string,
  newStatus: string,
  comments: string,
  setInitials: (x: any) => void,
  setValidations: (x: any) => void
) => {
  const initials = { status, newStatus, comments };
  const schema = yup.object().shape({
    newStatus: yup.string().required('Required'),
  });

  if (newStatus === 'PTP') {
    setInitials({ ...initials, ptp_commitment_date: null });
    const newSchema = schema.concat(
      yup.object().shape({
        ptp_commitment_date: yup
          .date()
          .typeError('Invalid date')
          .required('Required'),
      })
    );
    setValidations(newSchema);
  }

  if (newStatus === 'Call_Required') {
    setInitials({
      ...initials,
      hasPreferredDate: false,
      hasPreferredTime: false,
      prefferedDate: null,
      prefferedTime: null,
    });
    const newSchema = schema.concat(
      yup.object().shape({
        hasPreferredDate: yup.boolean(),
        hasPreferredTime: yup.boolean(),
        prefferedDate: yup
          .date()
          .typeError('Invalid date')
          .nullable()
          .when('hasPreferredDate', {
            is: true,
            then: yup.date().typeError('Invalid date').required('Required'),
          }),
        prefferedTime: yup
          .string()
          .typeError('Required')
          .nullable()
          .when('hasPreferredTime', {
            is: true,
            then: yup.string().typeError('Required').required('Required'),
          }),
      })
    );
    setValidations(newSchema);
  }

  // if (newStatus === 'Bad Contact' || newStatus === 'Not Engaged') {
  //   setInitials({
  //     ...initials,
  //     hasAlternateContact: false,
  //     channel: '',
  //     contact: '',
  //   });
  //   const newSchema = schema.concat(
  //     yup.object().shape({
  //       hasAlternateContact: yup.boolean(),
  //       channel: yup.string().when('hasAlternateContact', {
  //         is: true,
  //         then: yup.string().required('Required'),
  //       }),
  //       contact: yup.string().when('hasAlternateContact', {
  //         is: true,
  //         then: yup.string().required('Required'),
  //       }),
  //     })
  //   );
  //   setValidations(newSchema);
  // }

  if (newStatus === 'Bad_Contact' || newStatus === 'Not_engaged') {
    setInitials({
      ...initials,
      hasAlternateContact: false,
      email: '',
      phone: '',
    });
    const newSchema = schema.concat(
      yup.object().shape({
        hasAlternateContact: yup.boolean(),
        // email: yup.string().when('hasAlternateContact', {
        //   is: true,
        //   then: yup.string().required('Required'),
        // }),
        // phone: yup.string().when('hasAlternateContact', {
        //   is: true,
        //   then: yup.string().required('Required'),
        // }),
        email: yup
          .string()
          .test(
            'requiredIfAlternateContact',
            'Email is required',
            function (value) {
              const hasAlternateContact = this.parent.hasAlternateContact;
              const phone = this.parent.phone;
              return hasAlternateContact ? !!value || !!phone : true;
            }
          ),
        phone: yup
          .string()
          .test(
            'requiredIfAlternateContact',
            'Phone number is required',
            function (value) {
              const hasAlternateContact = this.parent.hasAlternateContact;
              const email = this.parent.email;
              return hasAlternateContact ? !!value || !!email : true;
            }
          ),
      })
    );
    setValidations(newSchema);
  }

  if (newStatus === 'Hardship') {
    setInitials({
      ...initials,
      monthly_emi: '',
      monthly_income: '',
      easier_pay: '',
      reason: '',
    });
    const newSchema = schema.concat(
      yup.object().shape({
        monthly_emi: yup.string().required('Required'),
        monthly_income: yup.string().required('Required'),
        easier_pay: yup.string().required('Required'),
        reason: yup.string().required('Required'),
      })
    );
    setValidations(newSchema);
  }

  if (newStatus === 'Broken_Promise' || newStatus === 'Default') {
    setInitials(initials);
    setValidations(schema);
  }
};

export const statusPayload = (values: FormikValues) => {
  const { newStatus, status, comments, odf_customer_id, ...rest } = values;
  let payload: any = { status: newStatus, comments, odf_customer_id };
  if (newStatus === 'PTP') {
    payload.ptp_commitment_date = moment(values.ptp_commitment_date).format(
      'YYYY-MM-DD'
    );
  }
  if (newStatus === 'Call_Required') {
    const {
      hasPreferredDate,
      hasPreferredDays,
      hasPreferredTime,
      hasPreferredTimeSlot,
    } = values;
    if (hasPreferredDate)
      payload.preferred_date = moment(values.prefferedDate).format(
        'YYYY-MM-DD'
      );
    if (hasPreferredTime) payload.preferred_time = values?.prefferedTime;
    if (hasPreferredDays) payload.preferred_days = values?.prefferedDay;
    if (hasPreferredTimeSlot)
      payload.preferred_time_slot = values?.prefferedTimeSlot;
  }
  if (newStatus === 'Not_engaged' || newStatus === 'Bad_Contact') {
    if (values?.hasAlternateContact) {
      delete payload.status;
      payload.status_type = newStatus;
      payload.alternate_email = values?.email;
      payload.alternate_phone_number = values?.phone;
    }
  }
  if (newStatus === 'Hardship') {
    const { easier_pay, monthly_emi, monthly_income, reason } = rest;
    payload = {
      odf_customer_id,
      status: newStatus,
      hardship_answers: {
        'What is your monthly EMI ?': monthly_emi,
        'What is your monthly income from all sources?': monthly_income,
        'What would make it easier for you to pay?': easier_pay,
        'Please help us understand the reason of your delinquency?': reason,
        comments,
      },
    };
  }
  if (newStatus === 'Default') {
    payload.status = 'Default/No category';
  }

  return payload;
};
