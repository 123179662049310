import { createAsyncThunk } from '@reduxjs/toolkit';
import roleEndpoint from 'apis/roles/config';
import roleApi from 'apis/roles';

export const getPermissionList = createAsyncThunk(
  `${roleEndpoint.permissionList}Get`,
  async (payload: { search_query?: string } | void) => {
    const { data } = await roleApi.getPermissionList(payload);
    return data;
  }
);

export const getAllRole = createAsyncThunk(
  `${roleEndpoint.roles}Get`,
  async () => {
    const { data } = await roleApi.getRole();
    return data;
  }
);

export const createRole = createAsyncThunk(
  `${roleEndpoint.roles}Post`,
  async (payload: { role_name: string; perms_to_add: string[] }) => {
    const { data } = await roleApi.createRole(payload);
    return data;
  }
);

export const updateRole = createAsyncThunk(
  `${roleApi.updateRole}Patch`,
  async (payload: {
    role_id: string;
    perms_to_add?: string[];
    perms_to_del?: string[];
    deactivate: boolean;
  }) => {
    const { data } = await roleApi.updateRole(payload);
    return data;
  }
);

export const deleteRole = createAsyncThunk(
  `${roleApi.deleteRole}Delete`,
  async (payload: { role_id: string }) => {
    const { data } = await roleApi.deleteRole(payload);
    return data;
  }
);
