import { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { BsDownload, BsUpload } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from './index.styles';

import CreateTemplatePreview from '../preview';
import TemplateCreateHeader from '../createHeader';
import { useAppDispatch } from 'hooks/useRedux';
import {
  createTemplate,
  createTwoWayTemplate,
  fetchVariableResolverAttributes,
  updateTemplate,
  updateTwoWayTemplate,
  validateTemplate,
} from 'store/reducer/templateReducer/action';
import { onFileChange } from '..';
import BasicModal from 'components/Modal';
import TestTemplateModal from 'containers/Templates/testTemplateModal';
import TempalateActionButtons from '../actionButtons';
import NotEmailForm from './form';
import { MESSAGE_JSON_KEY } from 'utils/customer/jsonMoifier';
import { parseFormData } from 'utils/template/jsonParser';

interface VariableResolverType {
  pii: string[];
  non_pii: string[];
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

const formikValues = {
  name: '',
  message: '',
  messages: [
    {
      msg: '',
      responseExpected: false,
      responseType: 'predefined',
      responses: [],
      defaultResponse: { linkMessage: false },
    },
  ],
};

const ChannelNotEmail = ({
  type,
  template,
  setLoading,
  openAttribute,
}: {
  type: string;
  template: any;
  setLoading: (x: boolean) => void;
  openAttribute: () => void;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const channel = (state as { channel: string }).channel;
  const uploadRef: any = useRef(null);

  const [messageType, setMessageType] = useState<string>('txt');
  const [variableResolver, setVariableResolver] =
    useState<VariableResolverType | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [openTest, setOpenTest] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('editor');
  const [initialValues, setInitialValues] = useState<any>(formikValues);
  const [unmappedVar, setUnmappedVar] = useState<any>([]);
  const [fullView, setFullView] = useState<boolean>(false);

  useEffect(() => {
    if (type === 'update' || type === 'duplicate') {
      let messages = [
        {
          msg: template.template,
          responseExpected: false,
          responseType: 'text',
          responses: [],
          defaultResponse: { linkMessage: false },
        },
      ];
      let message = template.template;
      if (template.two_way_flag) {
        let json =
          typeof template.template_json === 'string'
            ? JSON.parse(template.template_json)
            : template.template_json;

        if (!Array.isArray(json)) json = [json.Message];

        const msgArr = parseFormData(json);
        messages = msgArr
          .filter((el: any) => el.msg_id && typeof el.msg !== 'undefined')
          .sort((a: any, b: any) => a.msg_id - b.msg_id);

        message =
          typeof template.template_json === 'string'
            ? template.template_json
            : JSON.stringify(template.template_json);
        localStorage.setItem(MESSAGE_JSON_KEY, message);
      }

      setInitialValues({
        name: type === 'duplicate' ? '' : template.template_name,
        message,
        messages,
      });
    }
  }, [type, template]);

  const file_type =
    channel === 'email' ? 'html' : messageType === 'json' ? 'json' : 'txt';
  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { name, message } = values;

    const payload: any = {
      channel_type: channel,
      template_name: name,
      template: message,
      bucket: 1,
      two_way_flag: messageType === 'txt' ? false : true,
    };
    try {
      const { data, meta } = await dispatch(
        fetchVariableResolverAttributes({ template: message, file_type })
      ).unwrap();
      if (meta?.success) {
        const { variable_resolver } = data;
        const { data: validateData } = await dispatch(
          validateTemplate(variable_resolver as VariableResolverType)
        ).unwrap();
        const { mapped, unmappedVariables } = mappedState(validateData);
        setUnmappedVar(unmappedVariables);
        if (mapped) {
          let res;
          if (type === 'create' || type === 'duplicate') {
            if (messageType === 'json') {
              delete payload.two_way_flag;
              res = await dispatch(createTwoWayTemplate(payload)).unwrap();
            } else {
              res = await dispatch(createTemplate(payload)).unwrap();
            }
          }
          if (type === 'update') {
            payload.id = template.id;
            if (messageType === 'json') {
              delete payload.two_way_flag;
              res = await dispatch(updateTwoWayTemplate(payload)).unwrap();
            } else {
              res = await dispatch(updateTemplate(payload)).unwrap();
            }
          }
          if (res.meta.status === 'SUCCESS') {
            navigate('/template-management', {
              state: { channel, isTemplateCreatedUpdated: true },
            });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const verifyAttribute = async (values: any) => {
    const template = values.message;
    if (template) {
      setLoading(true);
      try {
        const { data, meta } = await dispatch(
          fetchVariableResolverAttributes({ template, file_type })
        ).unwrap();
        if (meta?.success) {
          const { variable_resolver } = data;
          const { data: validateData, meta } = await dispatch(
            validateTemplate(variable_resolver as VariableResolverType)
          ).unwrap();
          if (meta.success) {
            // let unmappedVariables: string[] = [];
            // if (meta.success) {
            //   if (Object.keys(validateData)) {
            //     const piiArr = validateData.hasOwnProperty('pii')
            //       ? validateData['pii']
            //       : [];
            //     const non_piiArr = validateData.hasOwnProperty('non_pii')
            //       ? validateData['non_pii']
            //       : [];
            //     unmappedVariables = [...piiArr, ...non_piiArr];
            //   }
            // }

            setVariableResolver(variable_resolver);
            // const { unmappedVariable } = getMappingState(variable_resolver);
            // setIsMapped(mapped);
            const { unmappedVariables } = mappedState(validateData);
            setUnmappedVar(unmappedVariables);
          }
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const mappedState = (validateData: {
    pii?: string[];
    non_pii?: string[];
  }) => {
    let unmappedVariables: string[] = [];
    if (Object.keys(validateData)) {
      const piiArr = validateData.hasOwnProperty('pii')
        ? validateData['pii']
        : [];
      const non_piiArr = validateData.hasOwnProperty('non_pii')
        ? validateData['non_pii']
        : [];
      unmappedVariables = [...piiArr!, ...non_piiArr!];
    }
    return { mapped: unmappedVariables.length === 0, unmappedVariables };
  };

  const handleTest = async (values: any) => {
    const { name, message } = values;
    let variable_resolver = JSON.stringify(variableResolver);
    if (!variableResolver) {
      const { data } = await dispatch(
        fetchVariableResolverAttributes({ template: message, file_type })
      ).unwrap();
      variable_resolver = JSON.stringify(data.variable_resolver);
    }
    const template = {
      template_name: name,
      template: message,
      two_way_flag: false,
      template_json: null,
      variable_resolver,
    };
    setSelectedTemplate(template);
    setOpenTest(true);
  };

  const handleTestClose = () => setOpenTest(false);

  const handleDownload = (values: FormikValues) => {
    const { message, name, messages } = values;
    let tempName = name || `${channel}_template`;
    if (message) {
      if (messages.length && messages[0].responseExpected) {
        const json = new Blob([JSON.stringify(JSON.parse(message))], {
          type: 'application/json',
        });
        const jsonURL = window.URL.createObjectURL(json);
        let tempLink = document.createElement('a');
        tempLink.href = jsonURL;
        tempLink.setAttribute('download', `${tempName}.json`);
        tempLink.click();
      } else {
        const json = new Blob([message], { type: 'text/plain' });
        const jsonURL = window.URL.createObjectURL(json);
        let tempLink = document.createElement('a');
        tempLink.href = jsonURL;
        tempLink.setAttribute('download', `${tempName}.txt`);
        tempLink.click();
      }
    }
  };

  const handlFileUpload = () => {
    uploadRef.current && uploadRef.current.click();
  };

  const onFileDrop = async (
    e: any,
    setFieldValue: (x: string, y: any) => void
  ) => {
    const newFile = e.target.files[0];
    if (newFile.type === 'application/json') {
      if (newFile) {
        let content = await onFileChange(newFile);
        let json = JSON.parse(content as string);
        if (!Array.isArray(json)) json = [json.Message];
        const msgArr = parseFormData(json);
        const messages = msgArr
          .filter((el: any) => el.msg_id && typeof el.msg !== 'undefined')
          .sort((a: any, b: any) => a.msg_id - b.msg_id);
        localStorage.setItem(MESSAGE_JSON_KEY, JSON.parse(content as string));
        setFieldValue('message', content);
        setFieldValue('messages', messages);
      }
    }
    if (newFile.type === 'text/plain') {
      if (newFile) {
        let content = await onFileChange(newFile);
        setFieldValue('message', content);
        setFieldValue('messages', [
          {
            msg: content,
            responseExpected: false,
            responseType: 'predefined',
            responses: [],
            defaultResponse: {
              linkMessage: false,
            },
          },
        ]);
        setMessageType('txt');
      }
    }
  };

  const handleMode = (newMode: string) => {
    setMode(newMode);
    setFullView(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, setTouched, setFieldValue }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid
                item
                mt='1rem'
                mr={fullView ? '2rem' : 0}
                xs={fullView ? 12 : 8}
              >
                <TemplateCreateHeader
                  type={type}
                  mode={mode}
                  // setMode={setMode}
                  handleMode={handleMode}
                  channel={channel}
                />

                <NotEmailForm
                  values={values}
                  unmappedVar={unmappedVar}
                  setFieldValue={setFieldValue}
                  openAttribute={openAttribute}
                  setMessageType={setMessageType}
                  mode={mode}
                  type={type}
                  // verifyAttribute={verifyAttribute}
                  fullView={fullView}
                  setFullView={setFullView}
                />

                <TempalateActionButtons
                  type={type}
                  handleVerify={() => verifyAttribute(values)}
                  handleSubmit={() => submitForm()}
                  handleDiscard={() =>
                    navigate('/template-management', { state: { channel } })
                  }
                  handleTest={() => {
                    setTouched({ name: true, message: true }, true);
                    if (values.name && values.message) {
                      handleTest(values);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={4} sx={{ display: fullView ? 'none' : 'block' }}>
                <Box className={classes.previewCont}>
                  <Box className={classes.previewButtonCont}>
                    <Box sx={{ cursor: 'pointer' }}>
                      <input
                        style={{ display: 'none' }}
                        ref={uploadRef}
                        type='file'
                        value=''
                        accept='application/JSON, text/plain'
                        onChange={(e) => onFileDrop(e, setFieldValue)}
                      />
                      <BsUpload onClick={handlFileUpload} />
                    </Box>

                    <button
                      type='button'
                      onClick={() => handleDownload(values)}
                    >
                      <BsDownload />
                    </button>
                  </Box>

                  <CreateTemplatePreview
                    channel={channel}
                    messageType={messageType}
                    message={values.message}
                    values={values}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <BasicModal
        open={openTest}
        onClose={handleTestClose}
        children={
          <TestTemplateModal
            selectedChannel={channel}
            selectedTemplate={selectedTemplate}
            onClose={handleTestClose}
          />
        }
      />
    </>
  );
};

export default ChannelNotEmail;
