import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Breadcrumb from './Breadcrumb';
import CampaignBox from './CampaignBox';
import { BiPlus } from 'react-icons/bi';
import { RiFileCopyLine } from 'react-icons/ri';
import { MdDeleteForever } from 'react-icons/md';
import { ImPlay3 } from 'react-icons/im';

import useStyles from './index.styles';
import DeleteCampaign from 'components/campaigns/modals/DeleteCampaign';
import { SummaryDataType } from 'containers/campaigns/campaign';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCampaignSummary } from 'store/reducer/campaignReducer/action';
import Loader from 'components/loader';
import RerunCampaign from 'components/campaigns/modals/RerunCampaign';
import BasicModal from 'components/Modal';
import CopyCampaignModal from 'components/campaigns/modals/CopyCampaign';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const CampaignHeader = ({
  data,
  campaignData,
  selected,
  campaignType,
  handleCreate,
  setCampaignType,
  setCurrentPage,
  selectedFilter,
}: {
  data: SummaryDataType;
  campaignData: any[];
  selected: string[];
  campaignType: string;
  handleCreate: () => void;
  setCampaignType: (x: string) => void;
  setCurrentPage: (x: number) => void;
  selectedFilter: { search_type: string; query: string };
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [openRerun, setOpenRerun] = React.useState<boolean>(false);
  const [openCopy, setOpenCopy] = React.useState<boolean>(false);

  const reRunDisabled =
    (selected.length === 1 &&
      campaignData.find((item) => item?.campaign_id === selected[0])[
        'status'
      ] !== '1') ||
    selected.length !== 1;
  const deleteDisabled = selected.length;

  const handleCampaign = async (campaign: string) => {
    setLoading(true);
    try {
      let type = campaign;
      if (campaign === campaignType) type = '';
      let payload: any = { limit: 10, offset: 0 };
      if (type) {
        let status_value = 'completed';
        if (type === 'Upcoming Campaigns') status_value = 'upcoming';
        if (type === 'Today’s Campaigns') status_value = 'todays';
        if (type === 'Draft Campaigns') status_value = 'draft';
        payload.status_value = status_value;
      }
      if (selectedFilter?.query) {
        payload.search_type = selectedFilter?.search_type;
        payload.query = selectedFilter?.query;
      }
      await dispatch(getCampaignSummary(payload)).unwrap();
      setCampaignType(type);
      setCurrentPage(1);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />

      <Box sx={{ '& p': { fontFamily: 'Overpass' } }}>
        <Breadcrumb />

        <Box className={classes.headerCont}>
          {permissions[MODULELIST.CAMPAIGN].includes('create') ? (
            <Box className={classes.createCampaign} onClick={handleCreate}>
              <BiPlus fontSize='2.5rem' />
              <Typography>
                Create <br /> New <br /> Campaign
              </Typography>
            </Box>
          ) : (
            <div></div>
          )}

          <Box display='flex' alignItems='center' gap='1rem'>
            <CampaignBox
              color='#A8D4E1'
              name='Upcoming Campaigns'
              value={data?.Scheduled}
              selected={campaignType === 'Upcoming Campaigns'}
              onClick={handleCampaign}
            />
            <CampaignBox
              color='#B7E1F7'
              name='Today’s Campaigns'
              value={data?.Todays}
              selected={campaignType === 'Today’s Campaigns'}
              onClick={handleCampaign}
            />
            <CampaignBox
              color='#CBD1D2'
              name='Draft Campaigns'
              value={data?.Draft}
              selected={campaignType === 'Draft Campaigns'}
              onClick={handleCampaign}
            />
            <CampaignBox
              color='#D7F0E9'
              name='Completed Campaigns'
              value={data?.Completed}
              selected={campaignType === 'Completed Campaigns'}
              onClick={handleCampaign}
            />
          </Box>

          <Box className={classes.actionButtonWrapper}>
            {permissions[MODULELIST.CAMPAIGN].includes('execute') && (
              <Box
                className={classes.actionCont}
                sx={{ background: reRunDisabled ? '#A2A7AE' : '#6AC5AB' }}
                onClick={() => !reRunDisabled && setOpenRerun(true)}
              >
                <ImPlay3 />
                <Typography fontWeight={600}>RE-RUN</Typography>
              </Box>
            )}

            {permissions[MODULELIST.CAMPAIGN].includes('create') && (
              <Box
                className={classes.actionCont}
                sx={{ background: !selected.length ? '#A2A7AE' : '#115180' }}
                onClick={() => setOpenCopy(true)}
              >
                <RiFileCopyLine />
                <Typography fontWeight={600}>COPY</Typography>
              </Box>
            )}

            {permissions[MODULELIST.CAMPAIGN].includes('delete') && (
              <Box
                className={classes.actionCont}
                sx={{ background: !deleteDisabled ? '#A2A7AE' : '#DAA09A' }}
                onClick={() => selected.length && setOpenDelete(true)}
              >
                <MdDeleteForever />
                <Typography fontWeight={600}>DELETE</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <DeleteCampaign
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        campaigns={selected}
        setLoading={setLoading}
      />

      <RerunCampaign
        open={openRerun}
        onClose={() => setOpenRerun(false)}
        campaign={campaignData.find(
          (campaign) => campaign.campaign_id === selected[0]
        )}
        setLoading={setLoading}
        setCurrentPage={setCurrentPage}
      />

      <BasicModal
        open={openCopy}
        onClose={() => setOpenCopy(false)}
        children={
          <CopyCampaignModal
            onClose={() => setOpenCopy(false)}
            campaign={campaignData.find(
              (item) => item?.campaign_id === selected[0]
            )}
          />
        }
      />

      {/* <BasicModal
        open={openMultiDelete}
        onClose={handleMultiDeleteClose}
        children={
          <CampaignMultiDelete
            onClose={handleMultiDeleteClose}
            workflowSelected={workflowSelected}
            onConfirm={handleDelete}
          />
        }
      /> */}
    </>
  );
};

export default CampaignHeader;
