export interface FileDetailsObjectType {
  fileName: string;
  fileFormat: string;
  fileSize: string;
  uploadedBy: string;
}

export interface AccountReceivedDetailsObjectType {
  uniqueCustomers: string; //unique_customers
  totalNumberOfCustomers: string; //total_no_of_customers
  totalOutstandingInvoices: string; //total_outstanding_invoices
  totalOutstandingAmount: string; //total_outstanding_amt
  totalUpcomingInvoices: string; //total_upcoming_invoices
  totalUpcomingInvoiceAmt: string; //total_upcoming_invoice_amt
  totalNumberOfValidRecords: string; //total_no_of_valid_records
  totalNumberOfInvalidRecords: string; //total_no_of_invalid_records
  campaignId: string; //campaign_id
  campaignName: string; //campaign_name
  cUploadId: string; //c_upload_id
  dpd_0_to_10_days: number; //dpd_0_to_10_days
  dpd_11_to_30_days: number; //dpd_11_to_30_days
  dpd_31_to_60_days: number; //dpd_31_to_60_days
  dpd_61_to_90_days: number; //dpd_61_to_90_days
  dpd_91_to_120_days: number; //dpd_91_to_120_days
  dpd_more_than_121_days: number; //dpd_more_than_121_days
}

export interface TemplateListItemObjectType {
  id: string;
  template_name: string;
  template: string;
}

export interface TemplateListObjectType {
  sms: Array<TemplateListItemObjectType>;
  email: Array<TemplateListItemObjectType>;
  ivr: Array<TemplateListItemObjectType>;
  whatsapp: Array<TemplateListItemObjectType>;
}

export interface GroupFilterListObjectType {
  // "CONDITIONS":{"0":"OR","1":"AND"},
  // "FILTERS":{"0":"DPD","1":"AMOUNT","2":"PRODUCT"},
  // "OPERATORS":{"0":">","1":"=","2":"<"},
}

export interface SummaryListItemObjectType {
  cgrId: string;
  groupName: string;
  groupDesc: string;
  filterType: string;
  channelType: string;
  runTime: string;
  totalPopulation: number;
  totalUser: number;
}

export interface CustomerStatsType {
  bad_contact: number;
  call_required: number;
  hardship: number;
  not_categorized: number;
  not_engaged: number;
  ptp: number;
  ptp_broken: number;
}

export interface LoanDetailsType {
  dpd: number;
  loan_amount: number;
  loan_id: string;
  loan_maturity_date: string;
  net_balance_due: number;
  odf_loan_id: string;
  payments: number;
  product: string;
  tenure: number;
}

export interface CustomerDetailsType {
  customer_id: string;
  odf_customer_id: string;
  last_communication_sent: {
    latest_date: string;
    channels: any;
    latest_notes: any;
  };
  loan_details: LoanDetailsType[];
  max_dpd: number;
  net_balance_due: number;
  number_of_loans: number;
  smart_kollect: { risk_profile: string; treatment: string; status: boolean };
  status_updates: { event: string; date: string };
}

export interface SelectOptionType {
  label: string;
  value: string;
}

export enum CampaignScheduleType {
  NOW = 'now',
  LATER = 'later',
}

export type ModuleType = {
  module_name: string;
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  execute: boolean;
};

export type UserType = {
  email: string;
  first_name: string;
  last_name: string;
  is_active: number;
  role_id: string;
  role_name: string;
  two_f_a: boolean;
  user_id: string;
};

export const MODULELIST = {
  CAMPAIGN: 'campaign_management',
  STRATEGY: 'strategy_management',
  TEMPLATE: 'template_management',
  WORKFLOW: 'workflow_management',
  CUSTOMER: 'customer_management',
  USER: 'user_management',
  // CONTACTCENTER: 'contact_center',
  CONTACTCENTER: 'call_management',
};

export interface RoleType {
  editable: boolean;
  role_id: string;
  role_name: string;
  is_active: boolean;
  permission_id_list: string[];
  user_count: number;
}

export interface WorkflowAttributeType {
  attribute: string;
  type: string;
  allowed_operator?: string[] | string;
  allowed_values?: string[];
}

export interface SelectOptionType {
  label: string;
  value: string;
}

export interface AwsLinkType {
  link: string;
  api_key: string;
}
