import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UiUpdateTypes {
  stepperValue: number;
}

const initialState: UiUpdateTypes = {
  stepperValue: 1,
};

export const UiUpdateSlice = createSlice({
  name: "uiUpdate",
  initialState,
  reducers: {
    setStepperValue: (state, action: PayloadAction<number>) => {
      state.stepperValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStepperValue } = UiUpdateSlice.actions;

export default UiUpdateSlice.reducer;
