import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import useStyles from './index.styles';

import CustomerOptionBox from './CustomerOptionBox';
import CustomerProfile from './details/Profile';
import CustomerActivity from './details/Activity';
import CustomerPreferences from './details/Preferences';
import CustomerRequests from './details/Requests';
import CustomerSmartKollectAnalysis from './details/smartKollect';
import AgentNotes from './details/Notes';

const tabs = ['Profile', 'Activity', 'Requests', 'Preferences', 'Notes'];

interface CustomerDetailsProps {
  status: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ status }) => {
  const classes = useStyles();
  const { id: customerId } = useParams();

  const [selectedCustomerOption, setSelectedCustomerOption] =
    useState<string>('Profile');

  const handleOptionClick = (option: string) =>
    setSelectedCustomerOption(option);

  useEffect(() => {
    setSelectedCustomerOption('Profile');
  }, [customerId]);

  return (
    <Box px='1rem'>
      <Box className={classes.tabsWrapper}>
        {tabs.map((tab) => (
          <CustomerOptionBox
            key={tab}
            name={tab}
            selectedOption={selectedCustomerOption}
            onClick={handleOptionClick}
          />
        ))}
      </Box>

      <Box className={classes.contentWrapper}>
        {selectedCustomerOption === 'Profile' && (
          <CustomerProfile status={status} />
        )}
        {selectedCustomerOption === 'Activity' && <CustomerActivity />}
        {selectedCustomerOption === 'Requests' && <CustomerRequests />}
        {selectedCustomerOption === 'Preferences' && <CustomerPreferences />}
        {selectedCustomerOption === 'SmartKollect Analysis' && (
          <CustomerSmartKollectAnalysis />
        )}
        {selectedCustomerOption === 'Notes' && <AgentNotes />}
      </Box>
    </Box>
  );
};

export default CustomerDetails;
