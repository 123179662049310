import { useCallback, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { variableResolver } from '../variableResolver';

import Loader from 'components/loader';
import BasicModal from 'components/Modal';
import ChannelEmail from './email';
import ChannelNotEmail from './notEmail';
import TemplateAvailableAttributes from './modals/attributeAttributes/availableAttributes';
import { useAppDispatch } from 'hooks/useRedux';
import { getTemplateAttributes } from 'store/reducer/templateReducer/action';

export interface VariableResolverType {
  pii: string[];
  non_pii: string[];
}

export const getMappingState = (resolver: VariableResolverType) => {
  let mappedVariable: VariableResolverType = {
    pii: [],
    non_pii: [],
  };
  let unmappedVariable: string[] = [];

  ['pii', 'non_pii'].forEach((type: string) => {
    if (type === 'pii' || type === 'non_pii') {
      resolver[type].forEach((variable: string) => {
        if (variableResolver[type].includes(variable)) {
          mappedVariable[type].push(variable);
        } else {
          unmappedVariable.push(variable);
        }
      });
    }
  });

  return { mapped: unmappedVariable.length === 0, unmappedVariable };
};

export const onFileChange = (files: any) => {
  return new Promise((resolve) => {
    if (files) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        resolve(e.target.result);
      };
      reader.readAsText(files);
    }
  });
};

const CreateUpdateTemplateScreen = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const type = id ? 'update' : (pathname.split('/').at(-1) as string);

  const channel = (state as any)?.channel;
  const template = (state as any)?.template;

  const [loading, setLoading] = useState<boolean>(false);
  const [openAttributeModal, setOpenAttributeModal] = useState<boolean>(false);

  const fetchAvailableAttributes = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getTemplateAttributes());
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAvailableAttributes();
  }, [fetchAvailableAttributes]);

  if (!channel) return <Navigate to='/template-management' />;

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ marginLeft: '2rem', '& p': { fontFamily: 'Overpass' } }}>
        {channel === 'email' ? (
          <ChannelEmail
            type={type}
            template={template}
            setLoading={setLoading}
            openAttribute={() => setOpenAttributeModal(true)}
          />
        ) : (
          <ChannelNotEmail
            type={type}
            template={template}
            openAttribute={() => setOpenAttributeModal(true)}
            setLoading={setLoading}
          />
        )}
      </Box>

      <BasicModal
        open={openAttributeModal}
        onClose={() => setOpenAttributeModal(false)}
      >
        <TemplateAvailableAttributes />
      </BasicModal>
    </>
  );
};

export default CreateUpdateTemplateScreen;
