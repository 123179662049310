import { Box, Stack, Switch, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

type SwitchProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#004357',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const FormikRolesSwitch: React.FC<SwitchProps> = ({
  name,
  label,
  disabled = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { setFieldValue } = form;
        const { value } = field;

        return (
          <Box>
            <Stack direction='row' spacing={1} alignItems='center'>
              <AntSwitch
                inputProps={{ 'aria-label': 'ant design' }}
                disabled={disabled}
                checked={value}
                onChange={(e) => setFieldValue(name, e.target.checked)}
              />
              <Typography>{label}</Typography>
            </Stack>
          </Box>
        );
      }}
    </Field>
  );
};
