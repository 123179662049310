import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@material-ui/core/styles';
import 'react-dates/initialize';

import MainContainer from './containers/index';
import { store } from './store/index';

import theme from './config/theme';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorFallback from 'components/ErrorHandling/ErrorFallback';
import CustomToastContainer from 'components/ToastContainer';

const App = () => {
  let persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <div className='App'>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <MainContainer />
            </ErrorBoundary>
          </div>
        </ThemeProvider>
        <CustomToastContainer />
      </PersistGate>
    </Provider>
  );
};

export default App;
