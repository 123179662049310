import * as React from 'react';
import { Box } from '@mui/material';
import CommunicationGraph from './CommunicationGraph';
// import ChannelBox from './ChannelBox';
// import { useAppSelector } from 'hooks/useRedux';
// import { penetrationData } from 'store/reducer/campaignReducer';

// const channels = ['EMAIL', 'SMS', 'WHATSAPP', 'IVR'];

const ChannelsGraph = ({
  selected,
  handleChannel,
}: {
  selected: string;
  handleChannel: (x: string) => void;
}) => {
  return (
    <Box
      sx={{
        background: '#fff',
        boxShadow:
          '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
        borderRadius: '8px',
        height: '100%',
        padding: '1rem 1.5rem 0 0',
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '1rem',
          justifyContent: 'center',
          paddingBottom: '12px',
        }}
      >
        {channels.map((channel) => (
          <ChannelBox
            channel={channel}
            selected={selected}
            onClick={() => handleChannel(channel)}
          />
        ))}
      </Box> */}

      <Box>
        <CommunicationGraph />
      </Box>
    </Box>
  );
};

export default ChannelsGraph;
