import { Box, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import useStyles, { ResponseButton } from './index.styles';

const FormikTemplateActionSelect = ({
  label,
  name,
  options,
}: {
  label: string;
  name: string;
  options: { label: string; value: string }[];
}) => {
  const classes = useStyles();

  return (
    <Box mb='1.5rem'>
      <label>
        <Typography mb='0.5rem'>{label}</Typography>
      </label>
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const { setFieldValue } = form;
          const { value, name } = field;

          return (
            <>
              <Box className={classes.responseTypeCont}>
                {options.map((resp: { label: string; value: string }) => (
                  <ResponseButton
                    active={value === resp.value}
                    onClick={() => setFieldValue(name, resp.value)}
                  >
                    <Typography>{resp.label}</Typography>
                  </ResponseButton>
                ))}
              </Box>
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};

export default FormikTemplateActionSelect;
