import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useAppSelector } from 'hooks/useRedux';
import { campaignCommStatus } from 'store/reducer/campaignReducer';
import { getGoodBadContact } from 'utils/campaign/campaignModifier';

export default function ContactProgressBar({ channel }: { channel: string }) {
  const commStatus = useAppSelector(campaignCommStatus);

  const [contacts, setContacts] = React.useState<any>({ good: 0, bad: 0 });

  React.useEffect(() => {
    if (commStatus) {
      const lowerChannel = channel.toLowerCase();
      const data = getGoodBadContact(commStatus, lowerChannel);
      setContacts(data);
    }
  }, [channel, commStatus]);

  return (
    <Box
      pb='1rem'
      sx={{ display: 'grid', placeItems: 'center', height: '90%' }}
    >
      <Box style={{ width: '150px' }}>
        <CircularProgressbarWithChildren
          value={contacts?.good || 0}
          strokeWidth={8}
          styles={buildStyles({
            pathColor: '#004357',
            trailColor: '#EBEEF0',
          })}
        >
          <Box>
            <Typography textAlign='center'>
              {contacts?.good ? `${contacts?.good.toFixed(2)}%` : 'NA'}
            </Typography>
            <Typography textAlign='center'>
              {contacts?.bad ? `${contacts?.bad.toFixed(2)}%` : 'NA'}
            </Typography>
          </Box>
        </CircularProgressbarWithChildren>
      </Box>
    </Box>
  );
}
