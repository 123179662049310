import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useStyles from './index.styles';
import { campaignGroupFilter } from 'store/reducer/uploadedFileDetailReducer';
import {
  getStrategyDetails,
  getStrategyList,
  updateStrategy,
} from 'store/reducer/strategyReducer/action';
import CircleIcon from '@mui/icons-material/Circle';
import SegmentList from './SegmentList';
import StrategyRepeatFields from './StrategyRepeatFields';

const NUMBER_OF_DAYS = Array(12)
  .fill({})
  .map((_, idx: number) => ({
    value: (idx + 1) * 5,
    label: (idx + 1) * 5,
  }));

const BASED_ON = [
  { label: 'Days Past Due', value: 'dpd' },
  { label: 'When customer promised to pay', value: 'ptp_start_date' },
  { label: 'When customer broke the PTP', value: 'broken_promise_start_date' },
  { label: 'When customer requested for call', value: 'need_call_start_date' },
  { label: 'When customer started on hardship', value: 'hardship_start_date' },
  { label: 'When customer got non engaged', value: 'not_engaged_start_date' },
  { label: 'When customer got uncontactable', value: 'bad_contact_start_date' },
  {
    label: 'When customer got added onto this system',
    value: 'first_engagement_date',
  },
  {
    label: ' When customer made partial payment',
    value: 'partial_payment_date',
  },
];

const EditStrategy = (props: any) => {
  const { close, strategyNames, baseScreen } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [repeat, setRepeat] = useState<boolean>(false);
  const strategyRepeatFieldRef = useRef<any>(null);

  const { groupFilterList } = useAppSelector(
    (state) => state.uploadedFileDetail
  );

  const strategyList =
    useAppSelector((state: any) => state.strategy.strategyList) || [];

  const selectedStrategyGroup = strategyList.find(
    (strategy: any) => strategy.strategy_group_name === strategyNames[0]
  );

  const based_on = BASED_ON.find(
    (each) => each.value === selectedStrategyGroup.based_on
  );

  const fetchFilterData = async () => {
    await dispatch(campaignGroupFilter({ client_id: 1 })).unwrap();
  };
  useEffect(() => {
    fetchFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object().shape({
    strategy_group_name: yup.string().required('Required'),
  });

  const removeZeroIfNotEmpty = (segmentItem: Array<string>) => {
    if (segmentItem.length > 1 && segmentItem.includes('0')) {
      return segmentItem.filter((itm: string) => itm !== '0');
    }
    return segmentItem;
  };

  const removeZeroIfBucketNotEmpty = (segmentItem: Array<number>) => {
    if (segmentItem.length > 1 && segmentItem.includes(0)) {
      return segmentItem.filter((itm: number) => itm !== 0);
    }
    return segmentItem;
  };

  const formik = useFormik({
    initialValues: {
      strategy_group_name: '',
      description: '',
      based_on: 'dpd',
      segment: { product: [], dpd: [], amount: [] },
      days_range: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // setLoading(true);

      const repeatDayRange = strategyRepeatFieldRef?.current?.getFrequency();
      const validity = strategyRepeatFieldRef?.current?.getValidity();

      let bucket = values.segment.dpd?.map(
        (itm) => Object.values(groupFilterList['DPD']).indexOf(itm) + 1
      );

      const product = removeZeroIfNotEmpty(values.segment.product);
      const balance_level = removeZeroIfNotEmpty(values.segment.amount);
      bucket = removeZeroIfBucketNotEmpty(bucket);

      let payload: any = {
        ...values,
        strategy_group_id: selectedStrategyGroup.strategy_group_id,
        segment: JSON.stringify({
          bucket,
          product,
          balance_level,
        }),
      };

      if (values?.based_on !== 'dpd' && repeat) {
        payload.days_range = repeatDayRange;
        payload.valid_for = validity;
      }

      if (values.based_on === 'dpd') {
        delete payload.days_range;
      }

      try {
        const res = await dispatch(updateStrategy(payload)).unwrap();
        await dispatch(getStrategyList());
        close();

        if (!baseScreen) {
          const { data } = await dispatch(
            getStrategyDetails({
              query: `strategy_group_id=${
                res?.data?.strategy_group_id || ''
              }&balance_level=${values?.segment?.amount[0] || 0}&product=${
                values?.segment?.product[0] || ''
              }&bucket=${bucket[0] || 0}`,
            })
          ).unwrap();
          navigate(
            `/strategy-details/${data?.strategy_summary[0]?.strategy_summary_id}?group_id=${res?.data?.strategy_group_id}`,
            {
              state: {
                groupName: res?.data?.strategy_group_name,
                groupId: res?.data?.strategy_group_id,
              },
            }
          );
        } else {
          fetchFilterData();
        }
        resetForm();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
  });

  const onChangeDayRange = (event: Event, newValue: number | number[]) => {
    setFieldValue('days_range', newValue);
  };

  const onChangeInput = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      e.target.value <= 60
    ) {
      setFieldValue('days_range', e.target.value);
    }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  }: {
    values: any;
    handleChange: any;
    errors: any;
    touched: any;
    handleBlur: any;
    handleSubmit: any;
    setFieldValue: any;
  } = formik;

  const handleChangeRepeatStrategy = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setRepeat(value === 'true');
  };

  const totalSegments = Object.values(values.segment || {}).reduce(
    (acc: number, curr: any) => {
      if (acc || curr.length) {
        return acc * (curr.length || 1);
      }
      return acc;
    },
    1
  );

  useEffect(() => {
    setFieldValue(
      'strategy_group_name',
      selectedStrategyGroup.strategy_group_name
    );
    if (selectedStrategyGroup.segment) {
      const amount =
        selectedStrategyGroup?.segment?.balance_level?.map(
          (eachBalance: string) => eachBalance
        ) || [];
      const dpd = selectedStrategyGroup?.segment?.bucket?.map(
        (eachBucket: number) => groupFilterList?.DPD?.[eachBucket - 1]
      );
      const product =
        selectedStrategyGroup.segment?.product?.map(
          (product: string) => product
        ) || [];
      const updatedSegment = {
        product,
        amount,
        dpd,
      };
      setFieldValue('segment', updatedSegment);
    }
    if (selectedStrategyGroup.days_range) {
      setFieldValue('days_range', selectedStrategyGroup.days_range);
    }
    if (selectedStrategyGroup.based_on) {
      setFieldValue('based_on', selectedStrategyGroup.based_on);
    }
    if (selectedStrategyGroup.valid_for) {
      setRepeat(true);
      setFieldValue('valid_for', selectedStrategyGroup.valid_for);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategyGroup]);

  return (
    <Box maxWidth='1000px' maxHeight='600px' sx={{ overflow: 'auto' }}>
      <Typography
        fontSize='1.5rem'
        fontWeight={700}
        fontFamily='Overpass'
        textAlign='center'
      >
        Edit {strategyNames[0]}
      </Typography>

      <Typography
        fontSize='0.875rem'
        fontWeight={400}
        fontFamily='Overpass'
        textAlign='center'
        mt={2}
        sx={{ opacity: 0.5 }}
      >
        To edit your strategy, feel free to add/remove segments or number of
        days to be run within the strategy
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container rowGap={4} mt={4}>
          <Grid
            item
            xs={6}
            lg={3}
            className={classes.formControl}
            marginTop='0.9em'
          >
            <label className={classes.formLabel}>
              <Typography
                color='#272D3B'
                fontSize='1rem'
                fontWeight={700}
                fontFamily='Overpass'
                sx={{ opacity: 0.5 }}
              >
                Add Name
              </Typography>
            </label>
          </Grid>
          <Grid item xs={6} lg={9} className={classes.formControl}>
            <input
              type='text'
              name='strategy_group_name'
              placeholder='Strategy Group Name'
              value={values.strategy_group_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.formInput}
            />
            {errors.strategy_group_name && touched.strategy_group_name && (
              <p className={classes.formErrorMessage}>
                {errors.strategy_group_name}
              </p>
            )}
          </Grid>

          <Grid
            item
            xs={6}
            lg={3}
            className={classes.formControl}
            margin='auto 0'
          >
            <label className={classes.formLabel}>
              <Typography
                color='#272D3B'
                fontSize='1rem'
                fontWeight={700}
                fontFamily='Overpass'
                sx={{ opacity: 0.5 }}
              >
                Add Description
              </Typography>
              <Typography
                color='#272D3B'
                fontSize='1rem'
                fontWeight={700}
                fontFamily='Overpass'
                sx={{ opacity: 0.5 }}
              >
                (Optional)
              </Typography>
            </label>
          </Grid>
          <Grid item xs={6} lg={9} className={classes.formControl}>
            <textarea
              name='description'
              placeholder='Description'
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classes.formTextarea}
            />
            {/* {errors.description && touched.description && (
              <p className={classes.formErrorMessage}>{errors.description}</p>
            )} */}
          </Grid>

          <Grid
            item
            xs={6}
            lg={3}
            className={classes.formControl}
            margin='auto 0'
          >
            <label className={classes.formLabel}>
              <Typography
                fontFamily='Overpass'
                fontWeight='700'
                fontSize='16px'
                color='#272D3B'
                lineHeight='20px'
                sx={{ opacity: 0.5 }}
              >
                Strategy run based on
              </Typography>
            </label>
            <div>
              {errors.based_on && touched.based_on && (
                <p className={classes.formErrorMessage}>{errors.based_on}</p>
              )}
            </div>
          </Grid>
          <Grid item xs={6} lg={9} className={classes.formControl}>
            <Typography
              fontSize='0.875rem'
              fontWeight={400}
              fontFamily='Overpass'
              sx={{ opacity: 0.5 }}
            >
              {`${based_on?.label || ''} (${based_on?.value || ''})`}
            </Typography>
            <Typography
              fontSize='0.875rem'
              fontWeight={400}
              fontFamily='Overpass'
              sx={{ opacity: 0.5 }}
            >
              (read-only)
            </Typography>
          </Grid>

          <Grid item xs={6} lg={3} className={classes.formControl}>
            <label className={classes.formLabel}>
              <Typography
                fontFamily='Overpass'
                fontWeight='700'
                fontSize='16px'
                color='#272D3B'
                lineHeight='20px'
                sx={{ opacity: 0.5 }}
              >
                Strategy Segments:
              </Typography>
            </label>
          </Grid>
          <Grid item xs={6} lg={9} className={classes.formControl}>
            <label className={classes.formLabel}>
              <Typography
                fontFamily='Overpass'
                fontSize='14px'
                lineHeight='18px'
                sx={{ opacity: 0.5 }}
              >
                Strategy for {totalSegments} segment(s) need(s) to be set up
              </Typography>
            </label>
          </Grid>
          <Grid item xs={6} lg={3} className={classes.formControl}></Grid>
          <Grid item xs={6} lg={9} className={classes.formControl}>
            <div style={{ display: 'flex', width: '90%' }}>
              {groupFilterList?.FILTERS &&
                Object.values(groupFilterList.FILTERS).map(
                  (filterData: any) => {
                    if (groupFilterList[filterData]) {
                      return (
                        <SegmentList
                          formik={formik}
                          key={`filter-${filterData}`}
                          filterData={filterData}
                          filterList={groupFilterList}
                        />
                      );
                    }
                    return <></>;
                  }
                )}
            </div>
          </Grid>

          {values?.based_on !== 'dpd' && (
            <>
              <Grid
                item
                xs={6}
                lg={3}
                className={classes.formControl}
                margin='auto 0'
              >
                <label className={classes.formLabel}>
                  <Typography
                    fontFamily='Overpass'
                    fontWeight='700'
                    fontSize='16px'
                    color='#272D3B'
                    lineHeight='20px'
                    sx={{ opacity: 0.5 }}
                  >
                    Want to repeat?
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={6} lg={9} className={classes.formControl}>
                {/* <FormControl> */}
                <RadioGroup
                  value={repeat}
                  name='repeat'
                  row
                  onChange={handleChangeRepeatStrategy}
                >
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={false}
                    control={
                      <Radio
                        className={classes.radio}
                        style={{
                          marginRight: 10,
                        }}
                        checkedIcon={
                          <CircleIcon
                            style={{
                              color: '#004357',
                              width: '12px',
                              height: '12px',
                            }}
                          />
                        }
                        icon={<div className={classes.uncheckedRadio} />}
                      />
                    }
                    label='NO'
                  />
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={true}
                    control={
                      <Radio
                        className={classes.radio}
                        style={{
                          marginLeft: 20,
                          marginRight: 10,
                        }}
                        checkedIcon={
                          <CircleIcon
                            style={{
                              color: '#004357',
                              width: '12px',
                              height: '12px',
                            }}
                          />
                        }
                        icon={<div className={classes.uncheckedRadio} />}
                      />
                    }
                    label='YES'
                  />
                </RadioGroup>
                {/* </FormControl> */}
              </Grid>
            </>
          )}

          {values?.based_on !== 'dpd' && repeat && (
            <StrategyRepeatFields
              ref={strategyRepeatFieldRef}
              selectedStrategyGroup={selectedStrategyGroup}
            />
          )}

          {values?.based_on !== 'dpd' && !repeat && (
            <>
              <Grid item xs={6} lg={3} className={classes.formControl}>
                <label className={classes.formLabel}>
                  <Typography
                    fontFamily='Overpass'
                    fontWeight='700'
                    fontSize='16px'
                    color='#272D3B'
                    lineHeight='20px'
                    sx={{ opacity: 0.5 }}
                  >
                    No. of days in the strategy
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={6} lg={9} className={classes.formControl}>
                <Box display='flex' width={'85%'}>
                  <div
                    style={{
                      width: 40,
                      height: 32,
                      marginRight: 38,
                    }}
                  >
                    <input
                      type='text'
                      name='name'
                      style={{
                        padding: '12px 9px 7px 9px',
                        width: '60%',
                        height: '60%',
                        fontSize: 18,
                        fontFamily: 'Overpass',
                        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                        border: ' 1px solid #CCCCCC',
                      }}
                      value={values.days_range}
                      onChange={onChangeInput}
                    />
                  </div>
                  <Box width='100%'>
                    <Slider
                      aria-label='Always visible'
                      step={5}
                      min={5}
                      max={60}
                      marks={NUMBER_OF_DAYS}
                      value={values.days_range}
                      className={classes.slider}
                      onChange={onChangeDayRange}
                    />
                  </Box>
                </Box>
                <div>
                  {errors.description && touched.description && (
                    <p className={classes.formErrorMessage}>
                      {errors.description}
                    </p>
                  )}
                </div>
              </Grid>
            </>
          )}

          <div className={classes.createSubmitCont}>
            <button type='submit' className={classes.createSubmitButton}>
              Update
            </button>
          </div>
        </Grid>
      </form>
      {/* )}
      </Formik> */}
    </Box>
  );
};

export default EditStrategy;
