import { Box, Typography } from "@mui/material";
import { RiSendPlane2Fill } from "react-icons/ri";
import { MdOutlineWatchLater } from "react-icons/md";

import useStyles from "./index.styles";
import moment from "moment";

import odfLogo from "assets/odf-logo.svg";
import { useLocation, useParams } from "react-router-dom";

interface SmsPreviewProps {
  selectedTemplate?: any;
  isText?: boolean;
  text?: string;
}

const SmsPreview: React.FC<SmsPreviewProps> = ({
  isText,
  selectedTemplate,
  text,
}) => {
  const { pathname } = useLocation();
  const pathUrl = pathname.split("/")[1];
  const { id } = useParams();
  const isCreate =
    pathUrl === "template-management" &&
    (pathname === "/template-management/create" || id) &&
    true;
  const classes = useStyles({ isCreate });

  return (
    <Box className={classes.smsPreviewWrapper}>
      <Box className={classes.smsPreviewCont}>
        <Typography align="center">KollectAI Messenger</Typography>
      </Box>
      <Box className={classes.smsPreviewMessageCont}>
        <Box className={classes.smsPreviewMessageBox}>
          <img src={odfLogo} alt="" height="20px" />
          <Box maxWidth="80%">
            <Box className={classes.smsPreviewMessage}>
              <Typography fontSize="0.9rem">
                {isText ? text : selectedTemplate.template}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "5px",
                color: "#838999",
                fontSize: "0.8rem",
              }}
            >
              <MdOutlineWatchLater />
              <Typography fontSize="0.8rem">
                {moment().format("DD MMM YY, h:mm:ss a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.smsPreviewInputCont}>
        <input type="text" placeholder="Enter Message..." disabled />
        <button disabled>
          <RiSendPlane2Fill />
        </button>
      </Box>
    </Box>
  );
};

export default SmsPreview;
