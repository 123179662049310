import { styled, Typography } from '@mui/material';

interface MultiDeleteModalProps {
  workflowSelected: any;
  onClose: () => void;
  onConfirm: () => void;
}

interface ButtonProps {
  backgroundColor: string;
}

const ContentWrapper = styled('div')({
  maxWidth: '450px',
  margin: '2rem auto',

  '& p': {
    fontWeight: 600,
  },

  '& ul': {
    marginLeft: '2rem',
  },
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const Button = styled('button')((props: ButtonProps) => ({
  background: props.backgroundColor,
  color: '#fff',
  fontFamily: 'inherit',
  padding: '6px 30px',
  fontSize: '1rem',
  borderRadius: '3px',
}));

const MultiDeleteModal: React.FC<MultiDeleteModalProps> = ({
  workflowSelected,
  onClose,
  onConfirm,
}) => {
  return (
    <>
      <Typography align='center' fontSize='1.2rem' sx={{ fontWeight: 600 }}>
        Deleting multiple workflows?
      </Typography>

      <ContentWrapper>
        <Typography mb='0.5rem' sx={{ opacity: '0.5' }}>
          The selected option included published workflows as well.
        </Typography>
        <Typography sx={{ opacity: '0.5' }}>
          Please review and click confirm if you want to continue to delete
          below workflows:
        </Typography>
        <ul>
          {workflowSelected.map((workflow: any) => (
            <li>
              <Typography>{workflow}</Typography>
            </li>
          ))}
        </ul>
      </ContentWrapper>

      <ButtonWrapper>
        <Button backgroundColor='#6AC5AB' onClick={onConfirm}>
          Confirm
        </Button>
        <Button backgroundColor='#DAA09A' onClick={onClose}>
          Cancel
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default MultiDeleteModal;
