import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactWrapper: {
      background: '#fff',
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
      borderRadius: '8px',
      padding: '1rem 1.5rem',
      height: '93%',
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
