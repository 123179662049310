import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import BasicSelect from 'components/Select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CiCalendar } from 'react-icons/ci';
import useStyles from './index.styles';
import { useAppDispatch } from 'hooks/useRedux';
import { customerRequestFilter } from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import { useParams } from 'react-router-dom';
import { setRefetchRequest } from 'store/reducer/customerReducer';

const categories = [
  { label: 'All', value: '' },
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { label: 'On-Hold', value: 'On-Hold' },
];

const priorities = [
  { label: 'All', value: '' },
  { label: 'Low', value: 'Low' },
  { label: 'Med', value: 'Med' },
  { label: 'High', value: 'High' },
];

export const DatePickerCont = styled('div')({
  display: 'inline-flex',
  padding: '0.2rem 1rem',
  alignItems: 'center',
  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  '& .react-datepicker-wrapper input': {
    background: 'transparent !important',
    color: '#000 !important',
  },
});

const CustomerRequestFilter = ({
  category,
  priority,
  date,
  setCategory,
  setPriority,
  setDate,
  onClose,
  updateState,
}: {
  onClose: () => void;
  category: string;
  priority: string;
  date: Date | null;
  setCategory: (x: string) => void;
  setPriority: (x: string) => void;
  setDate: (x: Date | null) => void;
  updateState: (x: any) => void;
}) => {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCategoryChange = async (newCategory: string) => {
    setLoading(true);
    let payload: any = { customer_id: id };
    if (newCategory) payload.category = newCategory;
    if (priority) payload.priority = priority;
    if (date) payload.date = moment(date).format('YYYY-MM-DD');
    if (!newCategory && !date && !priority) {
      dispatch(setRefetchRequest(true));
    } else {
      const { results } = await dispatch(
        customerRequestFilter(payload)
      ).unwrap();
      updateState(results);
    }
    setCategory(newCategory);
    setLoading(false);
    onClose();
  };

  const handlePriorityChange = async (newPriority: string) => {
    setLoading(true);
    let payload: any = { customer_id: id };
    if (category) payload.category = category;
    if (date) payload.date = moment(date).format('YYYY-MM-DD');
    if (newPriority) payload.priority = newPriority;
    if (!category && !date && !newPriority) {
      dispatch(setRefetchRequest(true));
    } else {
      const { results } = await dispatch(
        customerRequestFilter(payload)
      ).unwrap();
      updateState(results);
    }
    setPriority(newPriority);
    setLoading(false);
    onClose();
  };

  const handleDate = async (newDate: Date) => {
    setLoading(true);
    let payload: any = { customer_id: id };
    if (category) payload.category = category;
    if (priority) payload.priority = priority;
    if (newDate) {
      payload.date = moment(newDate).format('YYYY-MM-DD');
    }
    if (!category && !newDate && !priority) {
      dispatch(setRefetchRequest(true));
    } else {
      const { results } = await dispatch(
        customerRequestFilter(payload)
      ).unwrap();
      updateState(results);
    }
    setDate(newDate);
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Loader loading={loading} />
      <Box className={classes.filterWrapper}>
        <Typography fontSize='1.1rem' mb='1rem'>
          Filter
        </Typography>
        <Box className={classes.flexBetween}>
          <Typography>Category</Typography>
          <BasicSelect
            value={category}
            options={categories}
            onChange={handleCategoryChange}
            width='120px'
            size='sm'
          />
        </Box>

        <Box mt='1rem' className={classes.flexBetween}>
          <Typography>Priority</Typography>
          <BasicSelect
            value={priority}
            options={priorities}
            onChange={handlePriorityChange}
            width='120px'
            size='sm'
          />
        </Box>

        <Box mt='1rem' className={classes.flexBetween}>
          <Typography>Date</Typography>
          <DatePickerCont>
            <DatePicker selected={date} onChange={handleDate} />
            <CiCalendar />
          </DatePickerCont>
        </Box>
      </Box>
    </>
  );
};

export default CustomerRequestFilter;
