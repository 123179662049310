import axios from 'axios';
import { getAccessToken, logout } from '../utils/auth';
import { errorToast } from '../utils/toast';

/**
 * @typedef {{
 *  type: string;
 *  code: string;
 *  status: number;
 *  message: string;
 *  details: any;
 * }} ApiException
 */

const defaultTimeout = 40000;

const noAuthCheckUrls = [
  '/account/api/v1/login/',
  '/account/api/v1/register/',
  '/account/api/v1/email-verify/',
  '/otp/generate/',
  '/otp/resend/',
  '/otp/verify/',
  '/account/api/v1/resend-email/',
  '/account/api/v1/forgot-password/',
  '/account/api/v1/new-password/',
  '/account/api/v1/roles_and_permissions/verify_user_email',
  '/account/api/v1/roles_and_permissions/create_new_user_password',
  '/account/api/v1/duplicate_phone_check/',
  '/account/api/v1/subscription_management/give_default_subscription',
];

const handleRequest = (config: any) => {
  if (config.url.split('?')[0] === '/kollectai/api/v1/etl/upload_data_api') {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Api-Key ${getAccessToken()}`,
        product: 'KOLLECT AI',
      },
    };
  }

  if (config.url.split('?')[0] === '/account/api/v1/email-verify/')
    return config;

  if (noAuthCheckUrls.indexOf(config?.url) < 0) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };
  } else {
    return config;
  }
};

/**
 *
 * @param {ApiInterceptorToast} toast
 * @param {import('react-router-dom').RouterProps['history']} history
 * @returns {(err: import('axios').AxiosError<any>) => void}
 */
// eslint-disable-next-line no-unused-vars

const handleErrorResponse = async (err: any) => {
  const { response, config } = err;
  if (noAuthCheckUrls.indexOf(config?.url) > -1) {
    const { meta } = response?.data || {};
    if (meta?.status === 'ERROR' || meta?.success === false) {
      if (meta?.message) {
        const { non_field_errors } = meta?.message;
        if (non_field_errors) {
          errorToast(non_field_errors[0]);
        } else {
          let msg = meta.message;
          if (
            typeof meta.message === 'object' &&
            Object.keys(meta.message).length
          ) {
            msg = `${Object.keys(meta.message)[0]}: ${
              Object.values(meta.message)[0]
            }`;
          }
          msg && errorToast(msg);
        }
      } else {
        errorToast('Internal server error');
      }
    }
  } else {
    if (response.status === 401) {
      const { meta } = response?.data || {};
      if (meta?.message) {
        errorToast(meta?.message);
      }
      logout();
    } else {
      const { data, meta } = response?.data;
      const errMessage =
        data?.non_field_errors?.[0] || meta?.message || 'Internal Server Error';
      errorToast(errMessage);
    }
    return;
  }
  throw err;
};

/**
 * @param {string} baseURL
 * @param {string} [name]
 */
const createApiInstance: any = (baseURL: string, name = '') => {
  const api = axios.create({ baseURL, timeout: defaultTimeout });

  if (!baseURL) {
    throw new Error(
      `${name} baseURL not set during built. Please, set baseURL`
    );
  }

  api.interceptors.request.use(handleRequest);

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => handleErrorResponse(err)
  );

  return {
    /**
     *
     * @param {ApiInterceptorToast} toast
     * @param {import('react-router-dom').RouterProps['history']} history
     */
    // initializeInterceptors: (toast, history) => {
    //   if (!privateToast) {
    //     api.interceptors.request.use(handleRequest);
    //     api.interceptors.response.use(null, handleErrorResponse(toast, history));
    //     privateToast = toast;
    //   }
    // },
    instance: api,
  };
};

export default createApiInstance;
