import { Box, styled } from '@mui/material';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

const Button = styled('button')(({ disabled }: { disabled: boolean }) => ({
  border: `1px solid ${disabled ? '#fff' : 'rgba(0, 0, 0, 0.3)'}`,
  padding: '5px 6px 3px',
  borderRadius: '4px',
}));

const PenetrationPagination = ({
  backDisable,
  forDisable,
  handlePagination,
}: {
  backDisable: boolean;
  forDisable: boolean;
  handlePagination: (x: 'forward' | 'backward') => void;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <Button
        disabled={backDisable}
        onClick={() => handlePagination('backward')}
      >
        <IoChevronBackOutline />
      </Button>
      <Button disabled={forDisable} onClick={() => handlePagination('forward')}>
        <IoChevronForwardOutline />
      </Button>
    </Box>
  );
};

export default PenetrationPagination;
