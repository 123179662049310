import { useState, useEffect } from 'react';
import { Card, Divider } from '@material-ui/core';
import useStyles from './index.styles';
import { StrategyDetailsHeader } from 'components/strategyDetails';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { campaignGroupFilter } from 'store/reducer/uploadedFileDetailReducer';
import {
  copyStrategy,
  getStrategyDetails,
  getStrategyList,
} from 'store/reducer/strategyReducer/action';
import Loader from 'components/loader';
import { viewChannelTemplate } from 'store/reducer/templateReducer/action';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import SingleStrategy from './SingleStrategy';
import ComparisionStrategy from './ComparisionStrategy';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import WorkflowButton from 'components/strategy/landing/header/button';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import BasicModal from 'components/Modal';
import DuplicateStrategy from 'components/strategy/landing/duplicateModal';
import EditStrategy from 'components/strategy/landing/editModal';
// import { userAccess } from 'utils/helper';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';

const StrategyDetails = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const { id } = useParams() || {};
  const location = useLocation();
  let { groupId = '' } = (location.state as any) || {};
  const [selectedGroupId, setSelectedGroupId] = useState<string>(groupId);

  const strategyDetailsData: any = useAppSelector(
    (state: any) => state.strategy.strategyDetailsFilter
  );
  const strategyList = useAppSelector(
    (state: any) => state.strategy.strategyList
  );
  // const userRole = useAppSelector((state) => state.auth.role);

  const strategySummaryData =
    strategyDetailsData?.strategy_summary?.[0] ||
    strategyDetailsData?.strategy_summary;

  const selectedStrategyGroup = strategyList?.find(
    (strategyGroup: any) => strategyGroup.strategy_group_id === groupId
  );

  const selectedStrategy = selectedStrategyGroup?.strategy_detail?.find(
    (strategy: any) => strategy.strategy_id === id
  );

  useEffect(() => {
    getStrategyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, selectedGroupId]);

  const getStrategyData = async () => {
    setLoading(true);
    try {
      await dispatch(viewChannelTemplate({ channel_type: 'all' }));

      const res = await dispatch(
        campaignGroupFilter({ client_id: 1 })
      ).unwrap();

      if (res.meta.message === 'success') {
        // const { AMOUNT, DPD, PRODUCT } = res.data;
        // const filterPayload = {
        //   bucket: "1",
        //   amount: AMOUNT.LOW,
        //   product: PRODUCT["0"],
        //   dpd: DPD["0"],
        //   client_id: "1",
        //   auth_token: "",
        //   client_token: "",
        // };
        // await dispatch(strategyDetailsFilter(filterPayload));
        // await dispatch(getStrategyDetails({ id }));
      }
      if (selectedStrategy) {
        const balanceLevel = ['0', null].includes(
          selectedStrategy?.balance_level
        )
          ? '0'
          : selectedStrategy?.balance_level;
        const product = ['0', null].includes(selectedStrategy?.product)
          ? '0'
          : selectedStrategy?.product;
        const bucket = selectedStrategy?.bucket ? selectedStrategy?.bucket : 0;

        // await dispatch(
        //   getStrategyDetails({
        //     query: `strategy_group_id=${groupId || ''}&balance_level=${
        //       selectedStrategy?.balance_level || ''
        //     }&product=${selectedStrategy?.product || ''}&bucket=${
        //       selectedStrategy?.bucket || ''
        //     }`,
        //   })
        // );
        await dispatch(
          getStrategyDetails({
            query: `strategy_group_id=${
              groupId || ''
            }&balance_level=${balanceLevel}&product=${product}&bucket=${bucket}`,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeGroup = (event: SelectChangeEvent) => {
    setSelectedGroupId(event.target.value);
    const filterGroup = strategyList.find(
      (group: any) => group.strategy_group_id === event.target.value
    );

    if (filterGroup) {
      navigate(
        `/strategy-details/${
          filterGroup?.strategy_detail?.[0]?.strategy_id
        }?group_id=${filterGroup?.strategy_group_id || ''}`,
        {
          state: {
            groupId: filterGroup?.strategy_group_id,
          },
        }
      );
    }
  };

  const handleDuplicateStrategy = async (strategy_group_name: string) => {
    const selectedStrategyGroupId = strategyList.find(
      (strategy: any) => strategy.strategy_group_id === selectedGroupId
    );
    if (selectedStrategyGroupId) {
      setLoading(true);
      const payload = {
        strategy_group_name,
        base_strategy_group_id: selectedStrategyGroupId.strategy_group_id,
      };
      try {
        // await dispatch(copyStrategy(payload)).unwrap();
        const { strategy_group_id, strategy_group_name } = await dispatch(
          copyStrategy(payload)
        ).unwrap();
        if (strategy_group_id) {
          const { data } = await dispatch(getStrategyList()).unwrap();
          const strategy = data.find(
            (el: any) => el?.strategy_group_id === strategy_group_id
          );
          if (strategy) {
            const { strategy_detail } = strategy;
            setSelectedGroupId(strategy_group_id);
            navigate(
              `/strategy-details/${strategy_detail[0]?.strategy_id}?group_id=${strategy_group_id}`,
              {
                state: {
                  groupName: strategy_group_name,
                  strategyName: strategy_detail[0]?.strategy_name,
                  groupId: strategy_group_id,
                },
              }
            );
          }
        }
        setOpenCopyModal(false);
        setLoading(false);
      } catch (error) {
        // setOpenCopyModal(false);
        setLoading(false);
      }
    }
  };

  const handleCopyModalClose = () => setOpenCopyModal(false);
  const handleCopyModalOpen = () => setOpenCopyModal(true);

  const handleEditModalClose = () => setOpenEditModal(false);
  const handleEditModalOpen = () => setOpenEditModal(true);

  const permissions = useAppSelector(userPermissions);

  // const userPermissions = userAccess(userRole)?.strategy.permissions;

  return (
    <div>
      <Loader loading={loading} />
      <Box
        display='flex'
        padding={4}
        position='relative'
        justifyContent='space-between'
      >
        <FormControl sx={{ m: 1, minWidth: 309, zIndex: 10 }}>
          <Select
            value={selectedGroupId}
            onChange={handleChangeGroup}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {strategyList?.map((group: any) => (
              <MenuItem
                value={group.strategy_group_id}
                key={`group-${group.strategy_group_id}`}
              >
                {group?.strategy_group_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          fontFamily='Overpass'
          fontSize='1.8rem'
          color='#333333'
          textAlign='center'
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          Strategy Details
        </Typography>

        <Box sx={{ zIndex: 1, display: 'flex' }}>
          {/* {userPermissions.includes('create') && ( */}
          {permissions[MODULELIST.STRATEGY].includes('create') && (
            <WorkflowButton
              text='COPY STRATEGY'
              bgColor='#115180'
              icon={<ContentCopy />}
              onClick={handleCopyModalOpen}
            />
          )}
          {/* {userPermissions.includes('update') && ( */}
          {permissions[MODULELIST.STRATEGY].includes('update') && (
            <WorkflowButton
              text='EDIT STRATEGY'
              bgColor='#6AC5AB'
              icon={<EditIcon />}
              onClick={handleEditModalOpen}
            />
          )}
        </Box>
      </Box>

      <Card variant='outlined' className={classes.card}>
        <div className={classes.strategyContentContainer}>
          <StrategyDetailsHeader
            strategy={selectedStrategy}
            strategyGroup={selectedStrategyGroup}
          />
          <Divider />

          {strategySummaryData?.ab_testing_flag ? (
            <ComparisionStrategy />
          ) : (
            <SingleStrategy />
          )}
        </div>
      </Card>

      <BasicModal
        disableEnforceFocus
        open={openCopyModal}
        onClose={handleCopyModalClose}
        children={
          <DuplicateStrategy
            strategyNames={[selectedStrategyGroup.strategy_group_name || '']}
            handleDuplicateStrategy={handleDuplicateStrategy}
            close={handleCopyModalClose}
          />
        }
      />

      <BasicModal
        disableEnforceFocus
        open={openEditModal}
        onClose={handleEditModalClose}
        children={
          <EditStrategy
            strategyNames={[selectedStrategyGroup.strategy_group_name || '']}
            close={handleEditModalClose}
            baseScreen='StrategyDetails'
          />
        }
      />
    </div>
  );
};

export default StrategyDetails;
