import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikCustomerSelect } from 'components/formik/customer';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { ActionButton, ActionButtonWrapper } from '../index.styles';
import { FormPropsType } from '../..';

const validationSchema = yup.object().shape({
  msg: yup.string().required('Required'),
});

const ResendMessageForm: React.FC<FormPropsType> = ({
  path,
  actionPath,
  value,
  onClose,
  setFieldValue,
  formValues,
}) => {
  const [inititalState, setInititalState] = useState({ msg: '' });
  const [msgTxt, setMsgTxt] = useState<string>('');
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (value) {
      setInititalState({ msg: value });
    }
  }, [value]);

  useEffect(() => {
    const { messages } = formValues;
    const opts = Array.from({ length: messages.length }, (_, i) => ({
      label: `msg_id: ${i + 1}`,
      value: `${i + 1}`,
    }));
    setOptions(opts);
  }, [formValues]);

  const handleSubmit = (values: any) => {
    setFieldValue(actionPath, 'resend_from_this_template');
    setFieldValue(path, values.msg);
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={inititalState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Box>
              <Typography mb='1rem'>
                Please select the message from this template that you want to
                resend
              </Typography>

              <Box display='flex' alignItems='center' gap='1rem'>
                <FormikCustomerSelect
                  name='msg'
                  placeholder='Select Message'
                  options={options}
                  handleChange={(value) => {
                    const { messages } = formValues;
                    const ind = +value - 1;
                    const msg = messages[ind]['msg'];
                    setMsgTxt(msg);
                    setFieldValue('msg', value);
                  }}
                />
                <Typography color='#6F6F6F' fontStyle='italic'>
                  {msgTxt}
                </Typography>
              </Box>
            </Box>

            <ActionButtonWrapper>
              <ActionButton type='submit' color='#6AC5AB'>
                <Typography>Save</Typography>
              </ActionButton>
            </ActionButtonWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResendMessageForm;
