import { useState, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, FormikValues } from 'formik';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import useStyles from './index.styles';

import { availableActions } from '../../modals/actionTriggers/action_trigger';
import LinkMessageBox from './linkMessage';
import {
  FormikTemplateActionSelect,
  FormikTemplateInput,
  FormikTemplateSelect,
} from 'components/formik/template';
import { createSelectOption } from 'utils/helper';
import { useLocation } from 'react-router';
import ActionTriggerModal from 'components/templates/ActionTriggerModal';
import { attributeRequiredActions } from 'components/templates/ActionTriggerModal/constants';
import StaticTextBox from './StaticTextBox';

const NotEmailDynamicForm = ({
  msgInd,
  values,
  handleLinkMessage,
  helpers,
  handleDefaultLink,
  setFieldValue,
}: {
  msgInd: number;
  values: FormikValues;
  handleLinkMessage: (x: any, y: number, z: number) => void;
  handleDefaultLink: (x: any, y: number) => void;
  helpers: FieldArrayRenderProps;
  setFieldValue: (x: string, y: any) => void;
}) => {
  const classes = useStyles();

  const { state } = useLocation();
  const channel = (state as { channel: string }).channel;

  const responses = values.messages[msgInd]['responses'];
  const defaultResp = values.messages[msgInd]['defaultResponse'];
  const basePath = `messages.${msgInd}.responses`;
  const baseDefaultPath = `messages.${msgInd}.defaultResponse`;

  const [triggerOptions, setTriggerOptions] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [attributePath, setAttributePath] = useState<string>('');
  const [attributeValue, setAttributeValue] = useState<any>(null);
  const [actionPath, setActionPath] = useState<string>('');

  useEffect(() => {
    const options = createSelectOption({
      arr: availableActions,
      isObj: true,
      objKey: 'name',
    });
    options.push({ label: 'REMOVE ACTION', value: 'remove_action' });
    setTriggerOptions(options);
  }, []);

  useEffect(() => {
    const resType = responses.length ? 'predefined' : 'text';
    setFieldValue(`messages.${msgInd}.responseType`, resType);
  }, [msgInd, responses, setFieldValue]);

  const linkMessageOptions = values.messages.map((_: any, index: number) => ({
    label: `Msg ID: ${index + 1}`,
    value: '' + index,
  }));

  const handleAddNew = () => {
    helpers.insert(values.messages.length + 1, {
      msg: '',
      responseExpected: false,
      // responseType: 'predefined',
      responseType: 'text',
      responses: [],
      defaultResponse: { linkMessage: false, action: '', action_attribute: '' },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setAttributePath('');
    setAttributeValue(null);
    setActionPath('');
  };

  const handleAddOption = (arrHelper: FieldArrayRenderProps) => {
    arrHelper.insert(responses.length + 1, {
      option: '',
      linkMessage: false,
      message: '',
      action: '',
    });
  };

  return (
    <>
      <FieldArray
        name={basePath}
        render={(arrayHelpers) => (
          <Box position='relative'>
            <button
              type='button'
              onClick={() => handleAddOption(arrayHelpers)}
              className={classes.addOptionButton}
            >
              <Box className={classes.addOptionCont}>
                <Typography fontWeight={600}>Add Option</Typography>
                <AiFillPlusCircle />
              </Box>
            </button>

            {responses &&
              responses.length > 0 &&
              responses.map((_: any, index: number) => (
                <Box key={index}>
                  <Box
                    className={classes.optionWrapper}
                    sx={{
                      marginBottom: index + 1 === responses.length ? 0 : '1rem',
                    }}
                  >
                    <Box className={classes.optionCont}>
                      <Typography
                        className={classes.optionText}
                        fontWeight={700}
                      >
                        Option {index + 1}:
                      </Typography>
                      <FormikTemplateInput
                        small
                        name={`${basePath}.${index}.option`}
                        type={channel === 'ivr' ? 'number' : 'text'}
                      />
                    </Box>

                    <Box className={classes.formValueWrapper}>
                      <LinkMessageBox
                        index={index}
                        checked={responses[index]['linkMessage']}
                        onChange={(e: any) => {
                          handleLinkMessage(e, index, msgInd);
                        }}
                      />
                      {responses[index]['linkMessage'] ? (
                        <FormikTemplateSelect
                          name={`${basePath}.${index}.message`}
                          options={linkMessageOptions}
                          handleAdd={handleAddNew}
                        />
                      ) : (
                        <Box className={classes.emptyBox}></Box>
                      )}
                    </Box>

                    <Box
                      className={`${classes.formValueWrapper} ${classes.addActionCont}`}
                    >
                      
                      <FormikTemplateActionSelect
                        name={`${basePath}.${index}.action`}
                        label='Add Action'
                        options={triggerOptions}
                        handleUpdate={(value: string) => {
                          if (value === 'remove_action') {
                            setFieldValue(`${basePath}.${index}.action`, '');
                            setFieldValue(
                              `${basePath}.${index}.action_attribute`,
                              ''
                            );
                            return;
                          }
                          if (attributeRequiredActions.includes(value)) {
                            setActionPath(`${basePath}.${index}.action`);
                            setAttributePath(
                              `${basePath}.${index}.action_attribute`
                            );
                            setSelectedAction(value);
                            setOpen(true);
                          } else {
                            setFieldValue(`${basePath}.${index}.action`, value);
                          }
                        }}
                      />
                      <StaticTextBox
                        action={responses[index]['action']}
                        value={responses[index]['action_attribute']}
                        onClick={() => {
                          const action = responses[index]['action'];
                          const value = responses[index]['action_attribute'];
                          if (attributeRequiredActions.includes(action)) {
                            setAttributePath(
                              `${basePath}.${index}.action_attribute`
                            );
                            setSelectedAction(action);
                            setAttributeValue(value);
                            setOpen(true);
                          }
                        }}
                      />
                    </Box>

                    <Box>
                      <button
                        type='button'
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <AiFillMinusCircle fontSize='2rem' color='#DAA09A' />
                      </button>
                    </Box>
                  </Box>

                  <Box className={classes.divider}></Box>
                </Box>
              ))}

            <Box className={classes.defaultWrapper}>
              <Box
                mr='1rem'
                sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <Typography className={classes.optionText} fontWeight={700}>
                  Default
                </Typography>
                <StaticTextBox value='default' />
              </Box>

              <Box className={classes.formValueWrapper}>
                <LinkMessageBox
                  index={0}
                  checked={defaultResp['linkMessage']}
                  onChange={(e) => {
                    handleDefaultLink(e, msgInd);
                  }}
                />
                {defaultResp['linkMessage'] ? (
                  <FormikTemplateSelect
                    name={`${baseDefaultPath}.message`}
                    options={linkMessageOptions}
                    handleAdd={handleAddNew}
                  />
                ) : (
                  <Box className={classes.emptyBox}></Box>
                )}
              </Box>

              <Box
                className={`${classes.formValueWrapper} ${classes.defaultAction}`}
              >
                <FormikTemplateActionSelect
                  name={`${baseDefaultPath}.action`}
                  label='Add Action'
                  options={triggerOptions}
                  handleUpdate={(value: string) => {
                    if (value === 'remove_action') {
                      setFieldValue(`${baseDefaultPath}.action`, '');
                      setFieldValue(`${baseDefaultPath}.action_attribute`, '');
                      return;
                    }
                    if (attributeRequiredActions.includes(value)) {
                      setActionPath(`${baseDefaultPath}.action`);
                      setAttributePath(`${baseDefaultPath}.action_attribute`);
                      setSelectedAction(value);
                      setOpen(true);
                    } else {
                      setFieldValue(`${baseDefaultPath}.action`, value);
                    }
                  }}
                />
                <StaticTextBox
                  action={defaultResp['action']}
                  value={defaultResp['action_attribute']}
                  onClick={() => {
                    const action = defaultResp['action'];
                    const value = defaultResp['action_attribute'];
                    if (attributeRequiredActions.includes(action)) {
                      setAttributePath(`${baseDefaultPath}.action_attribute`);
                      setSelectedAction(action);
                      setAttributeValue(value);
                      setOpen(true);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      />

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'grid', placeItems: 'center' }}
        disableEnforceFocus={false}
        children={
          <ActionTriggerModal
            action={selectedAction}
            path={attributePath}
            onClose={handleClose}
            setFieldValue={setFieldValue}
            value={attributeValue}
            formValues={values}
            actionPath={actionPath}
          />
        }
      />
    </>
  );
};

export default NotEmailDynamicForm;
