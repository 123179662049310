import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  allWorkflowExecution,
  getAllWorkflow,
  getFunnelMapping,
  getStrategyGroup,
  getWorkflowById,
  getworkflowProperties,
  workflowCustomerDetails,
} from './action';
import { RootState } from 'store';
import { WorkflowAttributeType } from 'constants/types';

const initialState = {
  allWorkflow: [],
  openDrawer: false,
  workflowType: '',
  workflowEditor: 'create' || 'edit',
  workflowData: {},
  workflowActionData: {},
  workflowIsPublished: false,
  workflowGraphData: {
    config: {},
    workflow_id: '',
    wf_description: '',
  },
  refetchWorkflow: false,
  strategyGroup: [],
  workflowFunnel: {
    viewFunnel: false,
    workflowId: '',
    executionId: '',
  },
  funnelMapping: [],
  addBranch: false,
  workflowExecutions: [],
  funnelData: {},
  workflowCustomerDetails: null,
  workflowAttributes: {
    check: null,
    update: null,
    set_wait: null,
    get_segment: null,
  },
};

export const WorkflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    setRefetchWorkflow: (state, action: PayloadAction<boolean>) => {
      state.refetchWorkflow = action.payload;
    },
    setAddBranch: (state, action: PayloadAction<boolean>) => {
      state.addBranch = action.payload;
    },
    setWorkflowEditor: (state, action: PayloadAction<string>) => {
      state.workflowEditor = action.payload;
    },
    setWorkflowType: (state, action: PayloadAction<string>) => {
      state.workflowType = action.payload;
    },
    setWorkflowActionData: (state, action: PayloadAction<any>) => {
      state.workflowActionData = action.payload;
    },
    setIsPublished: (state, action: PayloadAction<boolean>) => {
      state.workflowIsPublished = action.payload;
    },
    setWorkflowGraphData: (state, { payload }) => {
      const { config, workflow_id, wf_description } = payload as any;
      state.workflowGraphData = {
        config: typeof config === 'string' ? JSON.parse(config) : config,
        workflow_id,
        wf_description,
      };
    },
    setWorkflowConfig: (state, action: PayloadAction<boolean>) => {
      state.refetchWorkflow = action.payload;
      state.openDrawer = false;
    },
    setWorkflowFunnel: (state, { payload }) => {
      state.workflowFunnel = payload as any;
    },
    setFunnelMapping: (state, { payload }) => {
      state.funnelMapping = payload as any;
    },
    setFunnelData: (state, { payload }) => {
      state.funnelData = payload as any;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllWorkflow.fulfilled, (state, { payload }) => {
      const { message, data } = payload as any;
      if (message === 'success') {
        state.allWorkflow = data;
      }
    });
    builder.addCase(getStrategyGroup.fulfilled, (state, { payload }) => {
      const { message, data } = payload as any;
      if (message === 'success') {
        state.strategyGroup = data;
      }
    });
    builder.addCase(getWorkflowById.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.message === 'success') {
        localStorage.setItem('graphData', JSON.stringify(data));
        // state.workflowGraphData = data;
      }
    });
    builder.addCase(getFunnelMapping.fulfilled, (state, { payload }) => {
      const { result } = payload as any;
      state.funnelMapping = result;
    });
    builder.addCase(workflowCustomerDetails.fulfilled, (state, { payload }) => {
      // const { results } = payload as any;
      state.workflowCustomerDetails = payload;
    });
    builder.addCase(allWorkflowExecution.fulfilled, (state, { payload }) => {
      const { result } = payload as any;
      state.workflowExecutions = result;
    });
    builder.addCase(
      getworkflowProperties.fulfilled,
      (state, { meta, payload }) => {
        if (meta?.arg?.action_type === 'update') {
          state.workflowAttributes.update = payload?.data?.allowed_attribute;
        }
        if (meta?.arg?.action_type === 'check') {
          state.workflowAttributes.check = payload?.data?.allowed_attribute.map(
            (item: WorkflowAttributeType) => ({
              ...item,
              type: item.type.trim(),
            })
          );
        }
        if (meta?.arg?.action_type === 'set_wait') {
          state.workflowAttributes.set_wait = payload?.data?.allowed_attribute;
        }
        if (meta?.arg?.action_type === 'get_segment') {
          state.workflowAttributes.get_segment = payload?.data?.allowed_segment;
        }
      }
    );
  },
});

export const workflowDrawer = (state: any) => state.workflow.drawer;

export const {
  setWorkflowType,
  toggleDrawer,
  setWorkflowActionData,
  setWorkflowEditor,
  setIsPublished,
  setWorkflowGraphData,
  setWorkflowConfig,
  setRefetchWorkflow,
  setWorkflowFunnel,
  setAddBranch,
  setFunnelMapping,
  setFunnelData,
} = WorkflowSlice.actions;
export default WorkflowSlice.reducer;

export const workflowAttributes = (state: RootState) =>
  state.workflow.workflowAttributes;
