import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppSelector } from 'hooks/useRedux';

import { workflowData } from 'constants/workflowData';
import { createSelectOption } from 'utils/helper';
import { getGraphData } from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';

import WorkflowActionButtons from '../actionButtons';

interface MoveToQueueDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const MoveToQueueDrawer: React.FC<MoveToQueueDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );

  const graphData: any = getGraphData();

  const queueOptions = createSelectOption({ arr: workflowData.default_queues });

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const { stepName, queue } = values;
    const configObj = { ...graphData.config };

    if (workflowEditor === 'create') {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'MOVE_TO_QUEUE',
          queueName: queue,
        },
      ];
      let data = [...oldData, ...newData];

      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'queueName');
      updateWorkflow(configObj, keys, values.queue);
    }

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      queue: yup.string().required('Required'),
    })
  );

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
        queue: workflowActionData?.queueName || workflowData.default_queues[0],
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form
          style={{
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              placeholder='Precursor Name'
              disabled={true}
            />

            <CustomFormikWorkflowSelect
              name='queue'
              label='Select Queue'
              options={queueOptions}
            />
          </Box>

          {!graphData.published && (
            <WorkflowActionButtons
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MoveToQueueDrawer;
