import { createAsyncThunk } from '@reduxjs/toolkit';

import strategyApi from '../../../apis/strategy';
import strategyEndPoint from '../../../apis/strategy/config';

export const strategyChannelTemplate = createAsyncThunk(
  `${strategyEndPoint.strategyChannelTemplates}Get`,
  async (payload: any) => {
    const { data } = await strategyApi.strategyChannelTemplate(payload);
    return data;
  }
);

export const strategyDetailsFilter = createAsyncThunk(
  `${strategyEndPoint.strategyDetailsFilter}Post`,
  async (payload: any) => {
    const { data } = await strategyApi.strategyDetailsFilter(payload);
    return data;
  }
);

export const strategyUpdate = createAsyncThunk(
  `${strategyEndPoint.strategyUpdate}Patch`,
  async (payload: any) => {
    const { data } = await strategyApi.strategyUpdate(payload);
    return data;
  }
);

export const strategyDistributionUpdate = createAsyncThunk(
  `${strategyEndPoint.strategyDistribution}Post`,
  async (payload: any) => {
    const { data } = await strategyApi.strategyDistributionUpdate(payload);
    return data;
  }
);

export const strategyCreateGroup = createAsyncThunk(
  `${strategyEndPoint.strategyCreateGroup}Post`,
  async (payload: any) => {
    const { data } = await strategyApi.strategyCreateGroup(payload);
    return data;
  }
);

export const getStrategyList = createAsyncThunk(
  `${strategyEndPoint.strategyList}Get`,
  async () => {
    const { data } = await strategyApi.getStrategyList();
    return data;
  }
);

export const getStrategyDetails = createAsyncThunk(
  `${strategyEndPoint.strategyDetails}Get`,
  async ({ id, query }: { id?: string; query?: string }) => {
    const { data } = await strategyApi.getStrategyDetails({ id, query });
    return data;
  }
);

export const updateStrategyDetails = createAsyncThunk(
  `${strategyEndPoint.strategyDetails}Patch`,
  async (payload: any) => {
    const { data } = await strategyApi.updateStrategyDetails(payload);
    return data;
  }
);

export const deleteStrategy = createAsyncThunk(
  `${strategyEndPoint.strategyDelete}Delete`,
  async (payload: { group_id: Array<string>; force_delete?: boolean }) => {
    const { data } = await strategyApi.deleteStrategy(payload);
    return data;
  }
);

export const copyStrategy = createAsyncThunk(
  `${strategyEndPoint.strategyCopyGroup}POST`,
  async (payload: {
    base_strategy_group_id: string;
    strategy_group_name: string;
  }) => {
    const { data } = await strategyApi.copyStrategy(payload);
    return data;
  }
);

export const updateStrategy = createAsyncThunk(
  `${strategyEndPoint.strategyGroupUpdate}PATCH`,
  async (payload: {
    strategy_group_id: string;
    segment: string;
    days_range?: string;
    valid_for?: string;
  }) => {
    const { data } = await strategyApi.updateStrategy(payload);
    return data;
  }
);
