import * as React from 'react';
import { Box, Modal, styled, Typography } from '@mui/material';

export type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  header: string;
  width?: string | number;
  onClose: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableEnforceFocus?: any;
};

const Container = styled('div')(({ width }: { width: string | number }) => ({
  backgroundColor: '#fff',
  borderRadius: '10px',
  outline: 'none',
  width,
  '& p': { fontFamily: 'Overpass' },
}));

const Header = styled('div')({
  background: '#004357',
  borderRadius: '10px 10px 0px 0px',
  padding: '1rem 0',
  color: '#fff',
});

const CustomModal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  header,
  width = '550px',
  disableEnforceFocus = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEnforceFocus={disableEnforceFocus}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Container width={width}>
        <Header>
          <Typography fontWeight={600} align='center' fontSize='1.2rem'>
            {header}
          </Typography>
        </Header>

        <Box sx={{ padding: '2rem 3rem' }}>{children}</Box>
      </Container>
    </Modal>
  );
};

export default CustomModal;
