import * as React from 'react';
import { Box } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { getRoleList } from 'store/reducer/userReducer/action';

import Loader from 'components/loader';
import { MemberInvitationSent } from './sent';
import { MemberInvitation } from './invite';

export const AddUserModal = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [invitationSent, setInvitationSent] = React.useState<boolean>(false);

  const fetchRoles = React.useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(getRoleList());
    } catch (error) {}
    setLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <>
      <Loader loading={loading} />
      <Box p='1.5rem 2rem'>
        {invitationSent ? (
          <MemberInvitationSent />
        ) : (
          <MemberInvitation setSent={setInvitationSent} />
        )}
      </Box>
    </>
  );
};
