import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    apiCallWrapper: {
      border: '1px solid #E6E6E6',
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: (props: any) =>
        props.getApiScreen ? '250px 1fr' : '250px 1fr 450px',
    },

    selectedResponseStyle: {
      background: '#272822',
    },

    apiCallContentWrapper: {
      height: 'calc(100vh - 265px)',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    contentBoxStyle: {
      fontSize: '0.95em',
      color: '#6F6F6F',
      margin: '10px 0',
    },
    contentHeadingStyle: {
      paddingTop: '10px',
      color: '#969696',
    },
    contentUrlBox: {
      border: '1px solid #BFBFBF',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      gap: '1em',
      padding: '6px',
      margin: '10px 0',

      '& p': {
        fontSize: '0.95em',
      },
    },
    contentApiMethodBox: {
      borderRadius: '5px',
      color: '#fff',
      padding: '5px 10px',
    },
    attributeHeaderStyle: {
      display: 'flex',
      gap: '5px',
      alignItems: 'end',
    },
    apiCallJsonViewerWrapper: {
      background: '#000',
      height: 'calc(100vh - 265px)',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },

      '& p': {
        fontSize: '0.9em',
        color: '#fff',
      },
    },
    jsonViewerRequestWrapper: {
      background: '#3C3E40',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    jsonViewerRequestCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      '& p': {
        fontSize: '0.9em',
      },
    },
    jsonViewerExampleWrapper: {
      background: '#3C3E40',
      padding: '20px 10px',
      marginTop: '10px',
    },
    jsonViewerExampleCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '0.5em',
    },
    jsonViewerResponseCont: {
      display: 'flex',
      alignItems: 'center',

      '& button': {
        borderRadius: '5px 5px 0px 0px',
        padding: '8px 12px',
      },
    },
    testModalCont: {
      borderRadius: '8px',
      backgroundColor: '#fff',
      outline: 'none',
      padding: '2rem 2rem',
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
    },
    endpointBtn: {
      backgroundColor: '#004357',
      color: '#fff',
      padding: '8px 1.5rem',
      borderRadius: '3px',
    },
  })
);

export default useStyles;
