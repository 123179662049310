import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whatsappPreviewWrapper: {
      // height: (props: any) => (props.notModal ? 'calc(100vh - 200px)' : '95%'),
      height: 'calc(100vh - 200px)',
      margin: '0 auto',
      width: '70%',
    },
    whatsappPreviewTwoWayHeaderCont: {
      background: '#F6F6F6',
      padding: '0.5rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '10px 10px 0px 0px',
    },
    whatsappPreviewTwoWayHeaderLeft: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    whatsappPreviewTwoWayHeaderRight: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginRight: '10px',
    },
    whatsappPreviewTwoWayHeaderBackIcon: {
      color: '#007AFF',
      marginRight: '10px',
      position: 'relative',
    },
    whatsappPreviewTwoWayHeaderBackIconText: {
      position: 'absolute',
      right: '-10px',
      top: '5px',
    },
    whatsappPreviewTwoWayHeaderMiddleBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    whatsappPreviewTwoWayHeaderAvatarCont: {
      background: '#fff',
      height: '30px',
      width: '30px',
      borderRadius: '60%',
    },
    whatsappPreviewTwoWayHeaderUserText: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },

    whatsappPreviewTwoWayMessageCont: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: (props: any) =>
      //   props.notModal ? 'calc(100vh - 365px)' : 'calc(100vh - 570px)',
      height: 'calc(100vh - 365px)',
      padding: '1rem',
      background:
        '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    whatsappPreviewTwoWayMessageBox: {
      height: '100%',
      paddingBottom: '1rem',
    },
    whatsappPreviewTwoWayTodayStyle: {
      background: '#DBDFEB',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
      padding: '0.2rem 0.8rem',
      display: 'inline',
      color: '#414350',
      fontSize: '0.8rem',
    },
    whatsappPreviewTwoWayLearnMoreStyle: {
      background: '#EDF9F5',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
      fontSize: '0.9rem',
      padding: '0.4rem',
    },
    whatsappPreviewTwoWayChatMessageCont: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'start',
    },
    whatsappPreviewTwoWayChatMessageBox: {
      padding: '5px 5px 14px 5px',
      display: 'inline',
      background: '#F9F9F9',
      borderRadius: '6px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      position: 'relative',
      paddingBottom: '1rem',
      minWidth: '40px',
    },
    whatsappPreviewTwoWayChatMessageTime: {
      position: 'absolute',
      right: '6px',
      bottom: 0,
      fontSize: '0.7rem',
      color: 'rgba(0, 0, 0, 0.15)',
    },
    whatsappPreviewTwoWayChatResponseCont: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'end',
    },

    whatsappPreviewTwoWayInputCont: {
      display: 'flex',
      padding: '0.5rem 1rem',
      alignItems: 'center',

      '& input': {
        width: '95%',
        outline: 'none',
        padding: '10px',
        fontFamily: 'Roboto',
        background: '#FFFFFF',
        border: '1px solid #DEDFE0',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
        margin: '0 1rem',
      },

      '& svg': {
        color: ' #2C62EC',
      },
    },
    whatsappPreviewTwoWayBottomLine: {
      maxWidth: '50%',
      background: '#000',
      height: '5px',
      borderRadius: '40px',
      margin: '5px auto',
    },
  })
);

export default useStyles;
