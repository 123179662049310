import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    observationSelectorsWrapper: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'repeat(3, auto)',
      padding: '0 3rem',
    },
  })
);

export default useStyles;
