import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@mui/material';

export const ActionButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  marginTop: '3rem',
});

export const ActionButton = styled('button')(
  ({ color }: { color: string }) => ({
    background: color,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    color: '#fff',
    padding: '0.5rem 3rem',
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrapper: {
      borderRadius: '10px',
      backgroundColor: '#fff',
      outline: 'none',
      minWidth: '40vw',

      '& p': {
        fontFamily: 'Overpass',
      },
    },
    headerStyle: {
      background: theme.kollectAITheme.primaryColor,
      borderRadius: '10px 10px 0px 0px',
      padding: '1rem',

      '& p': {
        fontSize: '1.2rem',
        textAlign: 'center',
        color: '#fff',
        fontFamily: 'Inter',
      },
    },
    checkboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
  })
);

export default useStyles;
