import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusCont: {
      color: '#fff',
      borderRadius: '7px',
      padding: '5px',
      '& p': { fontWeight: 600 },
    },
    viewEditButton: {
      background: '#004357',
      borderRadius: '30px',
      boxShadow:
        'rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px',
      color: '#fff',
      padding: '0.8rem 2rem',
      '& p': { fontWeight: 600 },
    },
    headerRow: {
      color: '#A8ABC3',
      background: '#fff',
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      '& th': {
        color: '#A8ABC3',
        borderBottom: '1px solid #CCCCCC',
        borderTop: '1px solid #CCCCCC',
        '& p': { fontSize: '0.9rem' },
      },
      '& th:first-child': { borderLeft: '1px solid #CCCCCC' },
      '& th:last-child': { borderRight: '1px solid #CCCCCC' },
    },
  })
);

export default useStyles;
