import { styled, Typography } from '@mui/material';
import CustomIcon from 'components/customIcons';
import { kollectAITheme } from 'config/theme';

const WorkflowTypeBoxWrapper = styled('div')(
  (props: { selected: boolean }) => ({
    filter: 'drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2))',
    background: '#FFFFFF',
    width: '250px',
    height: '75px',
    padding: '1.2rem 1rem 0.8rem',
    borderRadius: '10px',
    cursor: 'pointer',
    border: props.selected ? `2px solid ${kollectAITheme.primaryColor}` : '',
  })
);

const WorkflowTypeBoxTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '0.6rem',
});

interface WorkflowTypeBoxProps {
  title: string;
  iconName: string;
  iconColor: string;
  value: number;
  selected: boolean;
  onClick: () => void;
}

const WorkflowTypeBox: React.FC<WorkflowTypeBoxProps> = ({
  title,
  iconName,
  iconColor,
  value,
  selected,
  onClick,
}) => {
  return (
    <WorkflowTypeBoxWrapper selected={selected} onClick={onClick}>
      <WorkflowTypeBoxTitle>
        <CustomIcon name={iconName} size={28} color={iconColor} />
        <Typography fontSize={'1.2rem'}>{title}</Typography>
      </WorkflowTypeBoxTitle>
      <Typography
        sx={{ textAlign: 'center', color: '#00C087', fontSize: '1.4rem' }}
      >
        {value}
      </Typography>
    </WorkflowTypeBoxWrapper>
  );
};

export default WorkflowTypeBox;
