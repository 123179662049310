import { styled } from '@mui/material';

export const UserStatus = styled('div')(
  ({ isActive }: { isActive: number }) => ({
    background:
      isActive === 1 ? '#FDB5281F' : isActive === 2 ? '#72E1281F' : '#6D788D1F',
    padding: '3px 14px',
    display: 'inline-block',
    borderRadius: '4px',
    '& p': {
      fontSize: '0.9rem',
      color:
        isActive === 1 ? '#FFAB00' : isActive === 2 ? '#71DD37' : '#8592A3',
      textTransform: 'uppercase',
    },
  })
);
