import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FieldArray, FormikValues } from 'formik';
import useStyles from '../index.styles';

import {
  FormikTemplateInput,
  FormikTemplateTextarea,
} from 'components/formik/template';
import CustomResponseBox from '../customResponse';
import NotEmailDynamicForm from './DynamicForm';
import BasicModal from 'components/Modal';
import TemplateActionTrigger from '../../modals/actionTriggers';
import {
  deleteMessageJson,
  getMsgObj,
  getJsonConfig,
} from 'utils/customer/jsonMoifier';
import { GrBladesHorizontal, GrBladesVertical } from 'react-icons/gr';
import FlowCanvas from './FlowCanvas';

const NotEmailForm = ({
  values,
  // verifyAttribute,
  unmappedVar,
  mode,
  type,
  fullView,
  setFullView,
  setFieldValue,
  openAttribute,
  setMessageType,
}: {
  values: FormikValues;
  unmappedVar: any;
  mode: string;
  type: string;
  fullView: boolean;
  setFullView: (x: boolean) => void;
  setFieldValue: (x: string, y: any) => void;
  openAttribute: () => void;
  setMessageType: (x: string) => void;
  // verifyAttribute: (x: FormikValues) => void;
}) => {
  const classes = useStyles();

  const [openAction, setOpenAction] = useState<boolean>(false);
  const [flowDirection, setFlowDirection] = useState<'DOWN' | 'RIGHT'>('DOWN');

  useEffect(() => {
    deleteMessageJson();
  }, []);

  useEffect(() => {
    const { messages } = values;
    if (messages.length === 1 && !messages[0]['responseExpected']) {
      setFieldValue('message', messages[0]['msg']);
      setMessageType('txt');
    }
    if (messages[0]?.responseExpected) {
      const msgObj = getMsgObj(values.messages);
      const config = { Message: getJsonConfig(msgObj) };

      const msg = JSON.stringify(config);
      setFieldValue('message', msg);
      setMessageType('json');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleLinkMessage = (e: any, index: number, msgInd: number) => {
    const { checked } = e.target;
    let newMsg = [...values.messages];
    const msgObj = values.messages[msgInd];

    msgObj.responses[index] = {
      ...msgObj.responses[index],
      linkMessage: checked,
      message: '',
    };
    newMsg[msgInd] = {
      ...msgObj,
      responses: msgObj.responses,
    };
    setFieldValue('messages', newMsg);
  };

  const handleDefaultLink = (e: any, msgInd: number) => {
    const { checked } = e.target;
    let newRes = [...values.messages];
    const resObj = values.messages[msgInd];

    resObj.defaultResponse = {
      ...resObj.defaultResponse,
      linkMessage: checked,
    };
    newRes[msgInd] = {
      ...resObj,
      defaultResponse: resObj.defaultResponse,
    };
    setFieldValue('messages', newRes);
  };

  const handleResponseChange = (e: any, index: number) => {
    const { checked } = e.target;
    let newMsg = [...values.messages];
    const msgObj = values.messages[index];

    newMsg[index] = {
      ...msgObj,
      defaultResponse: { linkMessage: false },
      responses: [],
      responseExpected: checked,
    };
    setFieldValue('messages', newMsg);
  };

  const checkMapping = (msg: string) => {
    if (msg && unmappedVar.length) {
      const variables = msg.match(/{{[^{}]*}}/g)?.map((item) => {
        let variable = item.replace(/[{}]/g, '');
        if (variable.indexOf('pii') > -1) {
          variable = variable.split('.').at(-1) as string;
        }
        return variable;
      });
      const map = unmappedVar.some((r: string) => variables?.includes(r));
      return !map;
    }
    return true;
  };

  return (
    <>
      <Box className={classes.formWrapper}>
        {mode === 'flow' ? (
          <Box
            sx={{
              width: fullView ? '91vw' : '61vw',
              height: 'calc(100vh - 300px)',
            }}
          >
            <Box className={classes.canvasButtonCont}>
              <button type='button' onClick={() => setFullView(!fullView)}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: '#004357',
                  }}
                >
                  {fullView && 'Exit'} Full View
                </Typography>
              </button>

              <button
                type='button'
                onClick={() => {
                  const dir = flowDirection === 'DOWN' ? 'RIGHT' : 'DOWN';
                  setFlowDirection(dir);
                }}
              >
                {flowDirection === 'RIGHT' ? (
                  <GrBladesVertical fontSize='1.2rem' />
                ) : (
                  <GrBladesHorizontal fontSize='1.2rem' />
                )}
              </button>
            </Box>
            <FlowCanvas
              fullView={false}
              json={values.message}
              direction={flowDirection}
            />
          </Box>
        ) : (
          <Box>
            <Box className={classes.nameFieldCont}>
              <FormikTemplateInput
                label='Name of the template'
                name='name'
                placeholder='Template Name'
                disabled={type === 'update' ? true : false}
              />

              <Box className={classes.allowedButtonCont}>
                <Box sx={{ cursor: 'pointer' }} onClick={openAttribute}>
                  <Typography
                    fontWeight={700}
                    className={classes.allowedVariableText}
                  >
                    Allowed Variable List
                  </Typography>
                </Box>

                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenAction(true)}
                >
                  <Typography
                    fontWeight={700}
                    className={classes.allowedActionText}
                  >
                    Allowed Action Triggers
                  </Typography>
                </Box>
              </Box>
            </Box>

            <FieldArray
              name='messages'
              render={(arrayHelpers) => (
                <>
                  {values.messages &&
                    values.messages.length > 0 &&
                    values.messages.map((reponse: any, index: number) => {
                      const mapping = checkMapping(values.messages[index].msg);

                      return (
                        <Box key={index} mb='1rem'>
                          <Box className={classes.messageCont}>
                            <Box className={classes.messageHeaderBox}>
                              <Box
                                display='flex'
                                alignItems='center'
                                gap='10px'
                              >
                                <Typography fontWeight={700} color='#004357'>
                                  Enter Message
                                </Typography>
                                {!mapping && (
                                  <Typography className={classes.unmapMessage}>
                                    Undefined Variable used; Please refer{' '}
                                    <span>Allowed Variable List</span>
                                  </Typography>
                                )}
                              </Box>

                              <Box
                                display='flex'
                                alignItems='center'
                                gap='1rem'
                              >
                                <Typography
                                  fontWeight={600}
                                  color='#004357'
                                  fontSize='0.9rem'
                                >
                                  Msg ID: {index + 1}
                                </Typography>
                              </Box>
                            </Box>

                            <FormikTemplateTextarea
                              // handleBlur={() => verifyAttribute(values)}
                              name={`messages.${index}.msg`}
                            />

                            <Box>
                              <Box className={classes.nameFieldCont}>
                                <CustomResponseBox
                                  checked={
                                    values.messages[index]['responseExpected']
                                  }
                                  index={index}
                                  onChange={handleResponseChange}
                                />
                              </Box>
                            </Box>
                          </Box>

                          {values.messages[index]['responseExpected'] && (
                            <Box className={classes.responseDynamicFormCont}>
                              <NotEmailDynamicForm
                                msgInd={index}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleLinkMessage={(e, optInd, msgInd) => {
                                  handleLinkMessage(e, optInd, msgInd);
                                }}
                                handleDefaultLink={handleDefaultLink}
                                helpers={arrayHelpers}
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                </>
              )}
            />
          </Box>
        )}
      </Box>

      <BasicModal
        open={openAction}
        onClose={() => setOpenAction(false)}
        children={<TemplateActionTrigger />}
      />
    </>
  );
};

export default NotEmailForm;
