import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { UserType } from 'constants/types';
import { getRoleList, getUserSummary } from './action';

type InitialType = {
  userSummary: {
    count: number;
    next: string;
    previous: string;
    results: UserType[];
  } | null;
  roleList: { role_id_list: string[]; role_id_name: string[] } | null;
};

const initialState: InitialType = { userSummary: null, roleList: null };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserSummary.fulfilled, (state, { payload }) => {
      state.userSummary = payload;
    });
    builder.addCase(getRoleList.fulfilled, (state, { payload }) => {
      state.roleList = payload;
    });
  },
});

export default userSlice.reducer;

export const userSummary = (state: RootState) => state.user.userSummary;
export const roleList = (state: RootState) => state.user.roleList;
