import { styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

type InputProps = {
  name: string;
  placeholder?: string;
  width?: number;
  disabled?: boolean;
};

export const InputWrapper = styled('div')(
  ({ width, disabled }: { width: number; disabled: boolean }) => ({
    '& input': {
      fontSize: '1rem',
      padding: '0.6rem 1rem',
      fontFamily: 'Open sans',
      color: '#32475C99',
      border: '1px solid rgba(50, 71, 92, 0.22)',
      borderRadius: '6px',
      outline: 'none',
      width,
      background: disabled ? '#F0F0F0' : '#fff',
    },
  })
);

export const FormikRolesInput: React.FC<InputProps> = ({
  name,
  placeholder = '',
  width = 300,
  disabled = false,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <InputWrapper width={width} disabled={disabled}>
          <input
            type='text'
            placeholder={placeholder}
            {...field}
            disabled={disabled}
          />
          {meta.touched && meta.error && (
            <Typography fontSize='0.8rem' color='#d32f2f !important'>
              {meta.error}
            </Typography>
          )}
        </InputWrapper>
      )}
    </Field>
  );
};
