import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import useStyles from './index.styles';

import {
  CustomFormikWorkflowInput,
  CustomFormikWorkflowSelect,
} from 'components/formik/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowAttributes } from 'store/reducer/workflowReducer';

import {
  convertSegments,
  getGraphData,
  getSegment,
  getSelectedSegments,
} from 'utils/workflow/graphHandler';
import {
  getKeys,
  getPath,
  getStepValue,
  updateConfigObj,
  updateWorkflow,
} from 'utils/workflow/jsonModifier';
import { getworkflowProperties } from 'store/reducer/workflowReducer/action';
import SegmentsList from './segmentsList';
import WorkflowActionButtons from '../actionButtons';

interface GetSegmentDrawerProps {
  setLoading: (x: boolean) => void;
  updateJsonConfig: (x: any) => void;
  validationSchema: any;
}

const GetSegmentDrawer: React.FC<GetSegmentDrawerProps> = ({
  setLoading,
  updateJsonConfig,
  validationSchema,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const addBranch = useAppSelector((state: any) => state.workflow.addBranch);
  const workflowActionData: any = useAppSelector(
    (state: any) => state.workflow.workflowActionData
  );
  const workflowEditor = useAppSelector(
    (state: any) => state.workflow.workflowEditor
  );
  const graphData: any = getGraphData();

  const getSegments: any = useAppSelector(workflowAttributes)?.get_segment;

  const [segmentOptions, setSegmentOptions] = useState<any>([]);
  const [selectedSegments, setSelectedSegments] = useState<string[]>([]);
  console.log(
    '🚀 ~ file: getSegment.tsx:58 ~ selectedSegments:',
    selectedSegments
  );
  const [allowedSegmentData, setAllowedSegmentData] = useState<any>([]);
  const [showError, setShowError] = useState(false);

  const segmentTypes = useMemo(
    () =>
      allowedSegmentData?.map((segment: any) => ({
        value: Object.keys(segment)[0],
        label: Object.keys(segment)[0],
      })),
    [allowedSegmentData]
  );

  const fetchSegments = useCallback(async () => {
    try {
      await dispatch(getworkflowProperties({ action_type: 'get_segment' }));
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    fetchSegments();
  }, [fetchSegments]);

  useEffect(() => {
    if (getSegments) {
      const allowed = convertSegments(getSegments);
      setAllowedSegmentData(allowed);
    }
  }, [getSegments]);

  useEffect(() => {
    if (allowedSegmentData.length) {
      const workflowSegment =
        !workflowActionData?.segment ||
        workflowActionData?.segment === 'dpd_bucket'
          ? 'DPD_bucket'
          : workflowActionData?.segment;
      const option: any = allowedSegmentData.find(
        (segment: any) => Object.keys(segment)[0] === workflowSegment
      );
      setSegmentOptions(option[workflowSegment]);

      if (workflowActionData?.segment) {
        const selected = Object.keys(workflowActionData?.haschildren);
        const selectSeg = getSelectedSegments(workflowSegment, selected);
        setSelectedSegments(selectSeg);
      } else {
        // const selected: any = allowedSegmentData[0]['DPD_bucket'];
        const selected: any =
          allowedSegmentData?.find((el: any) => 'DPD_bucket' in el)
            ?.DPD_bucket || [];
        setSelectedSegments(selected);
      }
    }
  }, [allowedSegmentData, workflowActionData]);

  const handleSubmit = async (values: any) => {
    if (selectedSegments.length === 0) {
      setShowError(true);
      return;
    }

    setLoading(true);
    const { stepName, segment } = values;
    const configObj = { ...graphData.config };

    // if (workflowEditor === 'create' && selectedSegments.length) {
    if (selectedSegments.length) {
      const path = getPath(
        configObj,
        workflowActionData?.parent,
        workflowActionData?.parentValue
      );
      let children = {};
      selectedSegments.forEach((segment: string) => {
        const seg = getSegment(segment);
        const child = {
          [seg]: [
            { parent: stepName, parentValue: seg, position: 1, name: '' },
          ],
        };
        children = { ...children, ...child };
      });

      const oldData = addBranch ? getStepValue(configObj, path) : [];
      const newData = [
        {
          ...workflowActionData,
          name: stepName,
          position: oldData.length + 1,
          type: 'GET_SEGMENTS',
          // segment: segment === 'DPD_bucket' ? 'bucket' : segment,
          segment: segment === 'DPD_bucket' ? 'dpd_bucket' : segment,
          haschildren: children,
        },
      ];
      let data = [...oldData, ...newData];
      updateConfigObj(configObj, path, data);
    } else {
      const keys = getKeys(configObj, workflowActionData?.name);
      keys?.splice(-1, 1, 'segment');
      updateWorkflow(configObj, keys, values.segment);
    }

    updateJsonConfig(configObj);
  };

  const validation = validationSchema.concat(
    yup.object().shape({
      segment: yup.string().required('Required'),
    })
  );

  return (
    <Formik
      initialValues={{
        stepName: workflowActionData?.name,
        precursorName: workflowActionData?.parent,
        segment: workflowActionData?.segment
          ? workflowActionData?.segment
          : 'DPD_bucket',
      }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={classes.formWrapper}>
          <Box>
            <CustomFormikWorkflowInput
              label='Step Name'
              name='stepName'
              placeholder='Step Name'
              disabled={workflowEditor === 'create' ? false : true}
            />

            <CustomFormikWorkflowInput
              label='Precursor Name'
              name='precursorName'
              placeholder='Precursor Name'
              disabled={true}
            />

            <CustomFormikWorkflowSelect
              name='segment'
              label='Segment Type'
              onChange={(value) => {
                const option: any = allowedSegmentData.find(
                  (segment: any) => Object.keys(segment)[0] === value
                );
                const segments = Object.values(option)[0] as string[];
                setSegmentOptions(segments);
                setSelectedSegments(segments);
                setShowError(false);
              }}
              options={segmentTypes}
            />

            <SegmentsList
              values={values}
              segmentOptions={segmentOptions}
              allowedSegmentData={allowedSegmentData}
              selectedSegments={selectedSegments}
              setSelectedSegments={setSelectedSegments}
              setShowError={setShowError}
            />
            {showError && (
              <Typography fontSize='0.8rem' color='#d32f2f'>
                Segment is required.
              </Typography>
            )}
          </Box>

          {!graphData.published && (
            <WorkflowActionButtons
              setLoading={setLoading}
              updateJsonConfig={updateJsonConfig}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GetSegmentDrawer;
