import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    funnelWrapper: {
      background: '#fff',
      boxShadow:
        '0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)',
      borderRadius: '8px',
      padding: '1rem 2rem',
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
    },
    conversionRateCont: {
      display: 'flex',
      alignItems: 'center',
      color: '#71DD37',
      '& svg': {
        fontSize: '1.2rem',
        color: '#71DD37',
        marginBottom: '2px',
      },
    },
  })
);

export default useStyles;
