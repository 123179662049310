import { Box, styled, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikCustomerDisplayProps {
  label: string;
  name: string;
}

const FormikCustomerDisplayWrapper = styled('div')({
  marginBottom: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& input': {
    border: 'transparent',
    textAlign: 'center',
    borderRadius: '9px',
    padding: '0.5rem 0.5rem 0.3rem',
    fontFamily: 'Overpass',
    width: '100%',
    fontSize: '0.9rem',
    color: '#24254A',
  },
});

const FormikCustomerDisplay: React.FC<FormikCustomerDisplayProps> = ({
  label,
  name,
}) => {
  return (
    <FormikCustomerDisplayWrapper>
      <label>
        <Typography fontFamily='Overpass'>{label}</Typography>
      </label>
      <Field name={name}>
        {({ field, meta }: FieldProps) => {
          const { value } = field;

          return (
            <Box display='flex' justifyContent='end'>
              <input
                type='text'
                {...field}
                disabled
                style={{
                  backgroundColor:
                    value === 'NA'
                      ? 'rgba(111, 111, 111, 0.3)'
                      : 'rgba(255, 182, 0, 0.31)',
                }}
              />
              {meta.touched && meta.error && (
                <Typography fontSize='0.8rem' color='#d32f2f'>
                  {meta.error}
                </Typography>
              )}
            </Box>
          );
        }}
      </Field>
    </FormikCustomerDisplayWrapper>
  );
};

export default FormikCustomerDisplay;
