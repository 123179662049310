import { Box, Typography } from '@mui/material';
import useStyles, { ChatButton } from './index.styles';

import CustomerChatMessenger from './ChatMessenger';
import CustomerResponse from './CustomerResponse';
import ChatHeader from './ChatHeader';

const CustomerChatScreen = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <ChatHeader />

      <Box className={classes.contentWrapper}>
        <CustomerChatMessenger />

        <CustomerResponse />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '1rem',
          }}
        >
          <ChatButton>
            <Typography>CREATE & COPY</Typography>
          </ChatButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerChatScreen;
