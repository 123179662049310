import { styled, Box, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { workflowHandler } from 'store/reducer/workflowReducer/action';

interface DeleteModalProps {
  workflowId: string;
  workflowName: string;
  onClose: () => void;
  isPublished: boolean;
  handleDelete: () => void;
}

interface ButtonProps {
  backgroundColor: string;
}

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const Button = styled('button')((props: ButtonProps) => ({
  background: props.backgroundColor,
  color: '#fff',
  fontFamily: 'inherit',
  padding: '6px 30px',
  fontSize: '1rem',
  borderRadius: '3px',
}));

const DeleteModal: React.FC<DeleteModalProps> = ({
  workflowId,
  workflowName,
  onClose,
  isPublished,
  handleDelete,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const handleDelete = async () => {
  //   try {
  //     await dispatch(
  //       workflowHandler({
  //         workflow_id: [workflowId],
  //         action: 'delete',
  //       })
  //     ).unwrap();
  //     navigate('/workflow');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Typography align='center' fontSize='1.2rem' sx={{ fontWeight: 600 }}>
        Are you sure?
      </Typography>

      <Box sx={{ maxWidth: '450px', margin: '2rem auto' }}>
        <Typography sx={{ fontWeight: 600, color: '#8E8E8E' }}>
          This is a {isPublished ? 'published' : 'unpublished'} workflow. Please
          click confirm if you want to continue to delete the workflow:{' '}
          <span style={{ color: '#000' }}>{workflowName}</span>
        </Typography>
      </Box>

      <ButtonWrapper>
        <Button backgroundColor='#6AC5AB' onClick={handleDelete}>
          Confirm
        </Button>
        <Button backgroundColor='#DAA09A' onClick={onClose}>
          Cancel
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default DeleteModal;
