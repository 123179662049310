import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    apiCallEndpointWrapper: {
      padding: '1.2em 0',
      borderRight: '1px solid #CCCCCC',

      '& p': {
        textTransform: 'capitalize',
      },
    },
    apiCallEndpointCont: {
      paddingBottom: '3rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100vh - 395px)',
    },
    endpointBoxStyle: {
      height: 'calc(100vh - 400px)',
      overflow: 'auto',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    getApiTextStyle: {
      padding: '0.8em 1.5em',
      fontSize: '0.9em',
      cursor: 'pointer',
      fontWeight: 700,
      color: '#0C101A',
    },
    endpointTextStyle: {
      padding: '0.8em 1.5em',
      fontSize: '0.9em',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
