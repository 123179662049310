export const CUSTOMER_ACTIONS = {
  CHANGE_STATUS: 'Update Status',
  MOVE_TO_QUEUE: 'Move to Queue',
  EDIT_DATA: 'Edit Data',
  ADD_REQUEST: 'Customer Request',
  CREATE_PLAN: 'Custom Plan',
  ADD_TASK: 'Add Task',
  SEND_COMMUNICATION: 'Send communication',
  ADD_NOTES: 'Add Notes',
  DELETE: 'Delete Customer',
};
