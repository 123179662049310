import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import './multiple_drag_drop.css';

import uploadImg from 'assets/upload.svg';

interface MultiTemplateDragAndDropProps {
  size: string;
  selectedChannel: string;
  fileList: any;
  setFileList: (x: any) => void;
}

const MultiTemplateDragAndDrop: React.FC<MultiTemplateDragAndDropProps> = ({
  size,
  selectedChannel,
  fileList,
  setFileList,
}) => {
  const wrapperRef: any = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e: any) => {
    const files = e.target.files;
    let channelList = fileList[selectedChannel];
    Object.values(files).forEach((file: any) => {
      if (
        (selectedChannel === 'email' && file.type === 'text/html') ||
        (selectedChannel !== 'email' &&
          (file.type === 'application/json' || file.type === 'text/plain'))
      ) {
        channelList.push(file);
      }
    });
    const updatedFileList = {
      ...fileList,
      [selectedChannel]: channelList,
    };
    setFileList(updatedFileList);
  };

  return (
    <>
      <Box
        ref={wrapperRef}
        className='drop-file-input'
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Box
          className={
            size === 'small'
              ? 'drop-file-input__label_small'
              : 'drop-file-input__label'
          }
        >
          <img src={uploadImg} alt='' />
          <Box mb={size !== 'small' ? '2rem' : 0}>
            <Typography
              fontSize={size === 'small' ? '0.9rem' : '1rem'}
              mt='1rem'
            >
              Select a file or drag and drop here
            </Typography>
            <Typography
              fontSize={size === 'small' ? '0.9rem' : '1rem'}
              className='drop-file-input_subtitle'
            >
              {selectedChannel === 'email'
                ? 'HTML file format only, file size no more than 10MB'
                : 'txt and json file format only, file size no more than 10MB'}
            </Typography>
          </Box>
          <Box className='drop-file-input_select'>SELECT FILE</Box>
        </Box>
        <input
          type='file'
          value=''
          multiple
          accept={
            selectedChannel === 'email'
              ? '.html'
              : 'application/JSON, text/plain'
          }
          onChange={onFileDrop}
        />
      </Box>
    </>
  );
};

export default MultiTemplateDragAndDrop;
