import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    selectLoanCont: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      justifyContent: 'end',
    },
  })
);

export default useStyles;
