import { styled, Box, Dialog, Typography } from '@mui/material';
import { kollectAITheme } from 'config/theme';

interface TemplatePreviewModalProps {
  open: boolean;
  onClose: () => void;
  selectedOption: any;
  channel: string;
}

const DialogContent = styled('div')({
  margin: '1rem 2rem',
  border: `1px solid ${kollectAITheme.primaryColor}`,
  padding: '1rem',
  minWidth: '240px',
  minHeight: '210px',
  borderRadius: '5px',
});

const TemplatePreviewModal: React.FC<TemplatePreviewModalProps> = ({
  open,
  onClose,
  selectedOption,
  channel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={channel === 'email' ? 'md' : 'sm'}
    >
      <Box padding='2rem'>
        <Typography align='center' fontSize='1.2rem' fontWeight={700}>
          {selectedOption?.template_name}
        </Typography>
        {channel === 'email' ? (
          <DialogContent
            dangerouslySetInnerHTML={{ __html: selectedOption?.template }}
          ></DialogContent>
        ) : (
          <DialogContent>{selectedOption?.template}</DialogContent>
        )}
      </Box>
    </Dialog>
  );
};

export default TemplatePreviewModal;
