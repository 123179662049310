import { createAsyncThunk } from '@reduxjs/toolkit';
import permissionApi from 'apis/permissions';
import permissionEndpoint from 'apis/permissions/config';
import { ModuleType } from 'constants/types';

export const getPermissionSummary = createAsyncThunk(
  `${permissionEndpoint.permissionSummary}Get`,
  async (payload: { search_query?: string; limit: number; offset: number }) => {
    const { data } = await permissionApi.getPermissionSummary(payload);
    return data;
  }
);

export const getPermission = createAsyncThunk(
  `${permissionEndpoint.permissionView}Get`,
  async (payload: { permission_id: string }) => {
    const { data } = await permissionApi.getPermission(payload);
    return data;
  }
);

export const createPermission = createAsyncThunk(
  `${permissionEndpoint.permissionView}Post`,
  async (payload: { permission_name: string; modules: ModuleType[] }) => {
    const { data } = await permissionApi.createPermission(payload);
    return data;
  }
);

export const updatePermission = createAsyncThunk(
  `${permissionEndpoint.permissionView}Update`,
  async (payload: { permission_id: string; modules: ModuleType[] }) => {
    const { data } = await permissionApi.updatePermission(payload);
    return data;
  }
);

export const deletePermission = createAsyncThunk(
  `${permissionEndpoint.permissionView}Delete`,
  async (payload: { permission_id: string }) => {
    const { data } = await permissionApi.deletePermission(payload);
    return data;
  }
);

export const getClientConfig = createAsyncThunk(
  `${permissionEndpoint.clientConfig}Delete`,
  async () => {
    const { data } = await permissionApi.getClientConfig();
    return data;
  }
);
