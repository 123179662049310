const kollectAiCustomerManagement = {
  customerStats: '/kollectai/api/v1/customer-management/stats/',
  customerDetails: '/kollectai/api/v1/customer-management/customer-details',
  customerProfile:
    '/kollectai/api/v1/customer-management/customer-details/profile',
  customerActivity:
    '/kollectai/api/v1/customer-management/customer-details/activity',
  customerRequests:
    '/kollectai/api/v1/customer-management/customer-details/requests',
  customerActions:
    '/kollectai/api/v1/customer-management/customer-details/actions',
  customerPreferences:
    '/kollectai/api/v1/customer-management/customer-details/preferences',
  customerStatusUpdate:
    '/kollectai/api/v1/customer-management/customer-details/customer_status/update',
  customerCommunicationUpdate:
    '/kollectai/api/v1/customer-management/customer-details/communication/trigger',
  // customerQueueUpdate:
  //   '/kollectai/api/v1/customer-management/customer-details/customer_queue/update/',
  customerQueue: '/kollectai/api/v1/customer-management/customer-details/queue',
  // getCustomerQueue:
  //   '/kollectai/api/v1/customer-management/customer-details/queue',
  customerAgentUpdate:
    '/kollectai/api/v1/customer-management/customer-details/agent/update/',
  customerDelete:
    '/kollectai/api/v1/customer-management/customer-details/delete',
  customerWorkableUpdate:
    '/kollectai/api/v1/customer-management/customer-workable-update/',
  getCustomerById:
    '/kollectai/api/v1/customer-management/customer-details/filter',
  customerAttribute:
    '/kollectai/api/v1/customer-management/customer-details/attribute',
  customerNotes: '/kollectai/api/v1/customer-management/customer-details/notes',
  profileFilter:
    '/kollectai/api/v1/customer-management/customer-details/profile/filter',
  customerActivityDetails:
    '/kollectai/api/v1/customer-management/customer-details/activity/view_details',
  customerActivityFilter:
    '/kollectai/api/v1/customer-management/customer-details/activity/filter',
  customerPaymentDetails:
    '/kollectai/api/v1/customer-management/customer-details/payment_details',
  customerAgentsList:
    '/kollectai/api/v1/customer-management/customer-details/agents_list',
  customerRequestFilter:
    '/kollectai/api/v1/customer-management/customer-details/requests/filter',
  hardshipDetails:
    '/kollectai/api/v1/customer-management/customer-details/hardship_viewdetails',
  agentActionLists:
    '/kollectai/api/v1/customer-management/customer-details/agent_actions_list',
  callRequestAttemts:
    '/kollectai/api/v1/customer-management/customer-details/call_req_attempts',
  customerAllowedAttributes:
    '/kollectai/api/v1/customer-management/customer-details/allowed_attributes',
  allCustomerNotes:
    '/kollectai/api/v1/customer-management/customer-details/notes',
};

export default kollectAiCustomerManagement;
