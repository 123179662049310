import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import useStyles from './styles';

import PaymentResponse from './PaymentResponse';
import ReadyImg from 'assets/icons/customer/ready_to_pay.svg';
import PromisedImg from 'assets/icons/customer/promised_to_pay.svg';
import RefusedImg from 'assets/icons/customer/refused_to_pay.svg';
import UnableImg from 'assets/icons/customer/unable_to_pay.svg';
import ReadyToPay from './ready';
import PromiseToPay from './promised';
import RefusedToPay from './refused';
import UnableToPay from './unable';

export enum PayType {
  READY = 'ready',
  PROMISED = 'promised',
  REFUSED = 'refused',
  UNABLE = 'unable',
}

const paymentResponses = [
  { pay: PayType.READY, img: ReadyImg },
  { pay: PayType.PROMISED, img: PromisedImg },
  { pay: PayType.REFUSED, img: RefusedImg },
  { pay: PayType.UNABLE, img: UnableImg },
];

const CustomerResponse = () => {
  const classes = useStyles();

  const [selectedPay, setSelectedPay] = useState<PayType>(PayType.READY);

  return (
    <Box>
      <Grid container>
        <Grid item xs={5}>
          <Box
            sx={{
              padding: '2rem 1rem 4rem',
              borderRight:
                selectedPay === PayType.PROMISED
                  ? '2px solid rgba(44, 98, 236, 0.15)'
                  : 'none',
            }}
          >
            <Typography sx={{ opacity: 0.6, fontSize: '0.9rem' }}>
              Select customer response
            </Typography>

            <Box className={classes.paymentResponseWrapper}>
              {paymentResponses.map((payment) => (
                <PaymentResponse
                  {...payment}
                  isSelected={payment.pay === selectedPay}
                  onClick={() => setSelectedPay(payment.pay)}
                />
              ))}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box
            sx={{
              borderLeft:
                selectedPay === PayType.PROMISED
                  ? 'none'
                  : '2px solid rgba(44, 98, 236, 0.15)',
              padding: '2rem 1.5rem 4rem',
            }}
          >
            {selectedPay === PayType.READY && <ReadyToPay />}
            {selectedPay === PayType.PROMISED && <PromiseToPay />}
            {selectedPay === PayType.REFUSED && <RefusedToPay />}
            {selectedPay === PayType.UNABLE && <UnableToPay />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerResponse;
