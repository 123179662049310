import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';
import { SlArrowDown } from 'react-icons/sl';

interface ChannelBoxProps {
  channel: string;
  options: any;
  selectedComm: any;
  handleChannel: (x: string) => void;
  handleTemplate: (x: string, y: string) => void;
  handlePreview: (x: string) => void;
}

const ChannelBox: React.FC<ChannelBoxProps> = ({
  channel,
  options,
  selectedComm,
  handleChannel,
  handleTemplate,
  handlePreview,
}) => {
  const isSelected = channel in selectedComm && true;

  return (
    <Box
      sx={{
        width: 230,
      }}
    >
      <Box
        sx={{
          border: '1px solid #E6E6E6',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '55px',
          padding: '0 0.5rem 0 1rem',
          width: '200px',
        }}
      >
        <Typography color='#6F6F6F' textTransform='uppercase'>
          {channel}
        </Typography>
        <button type='button' onClick={() => handleChannel(channel)}>
          {isSelected ? (
            <AiFillMinusCircle color='#DAA09A' fontSize='1.5rem' />
          ) : (
            <BsFillPlusCircleFill color='#6AC5AB' fontSize='1.3rem' />
          )}
        </button>
      </Box>

      {isSelected && (
        <Box
          sx={{
            background: '#E6E6E6',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '0px 0px 5px 5px',
            padding: '10px',
            width: '180px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <FormControl sx={{ width: 150, zIndex: 10 }}>
            <Select
              displayEmpty
              value={selectedComm[channel] || ''}
              onChange={(event: SelectChangeEvent) => {
                const { value } = event.target;
                handleTemplate(channel, value);
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                position: 'relative',
                borderRadius: '8px',
                height: 40,
                textAlign: 'center',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6F6F6F',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6F6F6F',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6F6F6F',
                },
              }}
              IconComponent={() => (
                <SlArrowDown
                  style={{
                    fontSize: '0.9rem',
                    marginRight: '12px',
                    position: 'absolute',
                    right: 0,
                  }}
                />
              )}
            >
              {/* <MenuItem disabled value=''>
                <Typography color='#757575'>Template</Typography>
              </MenuItem> */}
              {options?.map((type: any, index: number) => (
                <MenuItem value={type.value} key={index}>
                  {type?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <button type='button' onClick={() => handlePreview(channel)}>
            <FaEye color='#6AC5AB' fontSize='1.5rem' />
          </button>
        </Box>
      )}
    </Box>
  );
};

export default ChannelBox;
