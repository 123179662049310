import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'hooks/useRedux';
import _ from 'lodash';

import PortfolioDashboardScreen from '../containers/Dashboard/Portfolio/index';
import CampaignDashboardScreen from 'containers/Dashboard/Campaign';
import StrategyScreen from 'containers/Strategy';
import StrategyDetails from 'containers/Strategy/StrategyDetails';
import StrategySummary from 'containers/Strategy/StrategySummary';
import StrategyScreenV2 from 'containers/Strategy';
import SubscriptionScreen from 'containers/Subscription';
import WorkflowManagementScreen from 'containers/Workflow';
import WorkflowEditorScreen from 'containers/Workflow/editor';
import DashboardScreen from 'containers/Dashboard';
import ComingSoonPage from 'components/comingSoon';
import ABTestingPage from 'containers/ABTesting';

import Template from 'containers/Templates/template';
import CustomerManagementScreen from 'containers/CustomerMgmt';
import IntegrationScreen from 'containers/Integrations';
import CustomerDetailsPage from 'containers/CustomerMgmt/CustomerDetails';
import CreateUpdateTemplateScreen from 'containers/Templates/CreateUpdate';
import CampaignManagementScreen from 'containers/campaigns/campaign';
import CampaignCreateScreen from 'containers/campaigns/create';
import CampaignCustomerDataScreen from 'containers/campaigns/CustomerData';
import CampaignDetailsScreen from 'containers/campaigns/CampaignDetails';
// import { userAccess } from 'utils/helper';
import RolesScreen from 'containers/Roles';
import { PermissionScreen } from 'containers/Permissions';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import NotFoundPage from 'components/ErrorHandling/NotFoundPage';
import FBConnectScreen from 'containers/FBConnect';

const BaseRouter = () => {
  // const userRole = useAppSelector((state: any) => state.auth.role);

  const permissions = useAppSelector(userPermissions);

  return (
    <Routes>
      <Route path='/' element={<DashboardScreen />} />
      <Route path='/fb-connect' element={<FBConnectScreen />} />
      <Route
        path='/portfolio-dashboard'
        element={<PortfolioDashboardScreen />}
      />

      {/* {userAccess(userRole)?.campaign.access && ( */}
      {_.has(permissions, MODULELIST.CAMPAIGN) && (
        <>
          <Route
            path='/campaign-dashboard'
            element={<CampaignDashboardScreen />}
          />
          {/* <Route path='/create-campaigns' element={<CreateCampaignsScreen />} /> */}
          <Route path='/create-campaigns' element={<CampaignCreateScreen />} />

          <Route path='/campaigns'>
            <Route index element={<CampaignManagementScreen />} />
            <Route path=':id' element={<CampaignCreateScreen />} />
            <Route
              path='customer-data'
              element={<CampaignCustomerDataScreen />}
            />
            <Route path='details/:id' element={<CampaignDetailsScreen />} />
          </Route>

          {/* <Route path='/campaign-details' element={<CampaignDetailsScreen />} /> */}
        </>
      )}

      {/* {userAccess(userRole)?.strategy.access && ( */}
      {_.has(permissions, MODULELIST.STRATEGY) && (
        <>
          <Route path='/strategy' element={<StrategyScreen />} />
          <Route path='/strategy-details/:id' element={<StrategyDetails />} />
          <Route path='/strategy-summary/:id' element={<StrategySummary />} />
          <Route path='/strategy-v2' element={<StrategyScreenV2 />} />
        </>
      )}

      {/* {userAccess(userRole)?.template.access && ( */}
      {_.has(permissions, MODULELIST.TEMPLATE) && (
        <>
          <Route path='/template-management' element={<Template />} />
          <Route
            path='/template-management/create'
            element={<CreateUpdateTemplateScreen />}
          />
          <Route
            path='/template-management/duplicate'
            element={<CreateUpdateTemplateScreen />}
          />
          <Route
            path='/template-management/:id'
            element={<CreateUpdateTemplateScreen />}
          />
        </>
      )}

      {/* {userAccess(userRole)?.workflow.access && ( */}
      {_.has(permissions, MODULELIST.WORKFLOW) && (
        <>
          <Route path='/workflow' element={<WorkflowManagementScreen />} />
          <Route path='/workflow-editor' element={<WorkflowEditorScreen />} />
        </>
      )}

      <Route path='/ab-testing' element={<ABTestingPage />} />

      <Route path='/contact-center' element={<ComingSoonPage />} />

      {/* {userAccess(userRole)?.customer.access && ( */}
      {_.has(permissions, MODULELIST.CUSTOMER) && (
        <>
          <Route
            path='/customer-management'
            element={<CustomerManagementScreen />}
          />
          <Route
            path='/customer-details/:id'
            element={<CustomerDetailsPage />}
          />
        </>
      )}

      <Route path='/smart-collect' element={<ComingSoonPage />} />

      <Route path='/integrations' element={<IntegrationScreen />} />

      {_.has(permissions, MODULELIST.USER) && (
        <>
          <Route path='/roles' element={<RolesScreen />} />
          <Route path='/permissions' element={<PermissionScreen />} />
        </>
      )}

      <Route
        path='/performance-reports'
        element={<PortfolioDashboardScreen />}
      />

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default BaseRouter;
