import { Typography } from '@material-ui/core';
import useStyles from './index.styles';

export type ContactibilityAndPenetrationBoxPros = {
  name: string;
  value: string | number;
};

const ContactibilityAndPenetrationBox: React.FC<
  ContactibilityAndPenetrationBoxPros
> = ({ name, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.contactiblityWrapper}>
      <Typography variant='subtitle1' align='center'>
        {name}
      </Typography>
      <div className={classes.contactiblityNumberStyle}>{value}</div>
    </div>
  );
};

export default ContactibilityAndPenetrationBox;
