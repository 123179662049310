import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    attributeFieldInput: {
      width: '95%',
      background: 'inherit',
      border: '1px solid #fff',
      minHeight: '34px',
      borderRadius: '5px',
      outline: 'none',
      color: '#fff',
      paddingLeft: '10px',
      fontSize: '1rem',
      fontFamily: 'inherit',
    },

    attributeFieldDatePickerWrapper: {
      '& .MuiSvgIcon-root': {
        color: '#fff',
        fontSize: '16px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '13px',
      },
    },

    attributeFieldDatePicker: {
      border: '1px solid #d5d5d5',
      padding: '0.4rem 0 0.4rem 1rem',
      position: 'relative',
      borderRadius: '4px',

      '& .react-datepicker__input-container input': {
        width: '100% !important',
        background: 'transparent !important',
        color: '#fff !important',
        cursor: 'default',
      },
    },
  })
);

export default useStyles;
