import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getClientConfig, getPermissionSummary } from './action';

type IntialType = {
  permissionSummary: any;
  clientConfig: null | any;
};

const initialState: IntialType = {
  permissionSummary: [],
  clientConfig: null,
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPermissionSummary.fulfilled, (state, { payload }) => {
      state.permissionSummary = payload;
    });
    builder.addCase(getClientConfig.fulfilled, (state, { payload }) => {
      state.clientConfig = payload;
    });
  },
});

export default permissionSlice.reducer;

export const permissionSummary = (state: RootState) =>
  state.permission.permissionSummary;
export const clientConfig = (state: RootState) => state.permission.clientConfig;
