import { Card, Grid } from '@material-ui/core';
import useStyles from './index.styles';

import ChallengerSummary from 'components/strategyDetails/ABTestSummary/challengerSummary';
import ChampionSummary from 'components/strategyDetails/ABTestSummary/championSummary';
import ChannelSchedule from './ChannelSchedule';

interface ABTestSummaryProps {
  strategyGroup: any;
}

const ABTestSummary: React.FC<ABTestSummaryProps> = ({ strategyGroup }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item lg={6}>
        <Card variant='outlined' className={classes.card}>
          <ChampionSummary />
          <ChannelSchedule strategyIndex={0} strategyGroup={strategyGroup} />
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card variant='outlined' className={classes.card}>
          <ChallengerSummary />
          <ChannelSchedule strategyIndex={1} strategyGroup={strategyGroup} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ABTestSummary;
