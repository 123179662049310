import * as React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import moment from 'moment';
import useStyles, { Circle, Line, ViewButton } from './index.styles';

import { useAppSelector } from 'hooks/useRedux';
import { agentActionLists } from 'store/reducer/customerReducer';

import CustomerNotesFilter from './filter';
import ViewDetailsModal from './detailsModal';

export default function DenseTable() {
  const classes = useStyles();

  const [openView, setOpenView] = React.useState<boolean>(false);
  const [viewData, setViewData] = React.useState<any>(null);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>('');
  const [date, setDate] = React.useState<Date | null>(null);

  const actionLists = useAppSelector(agentActionLists);

  const handleView = async (action: any) => {
    const { id, ...rest } = action;
    setViewData(rest);
    setOpenView(true);
  };

  return (
    <>
      <Box my='1rem'>
        <Box className={classes.customerActivityHeader}>
          <Typography>Most recent at the top</Typography>
          <Box sx={{ position: 'relative' }}>
            <button onClick={() => setShowFilter((prev) => !prev)}>
              <TuneIcon />
            </button>
            {showFilter ? (
              <CustomerNotesFilter
                onClose={() => setShowFilter(false)}
                filter={filter}
                setFilter={setFilter}
                date={date}
                setDate={setDate}
              />
            ) : null}
          </Box>
        </Box>

        <TableContainer sx={{ maxHeight: 800 }}>
          <Table className={classes.table} size='small'>
            <TableBody>
              {actionLists?.map((action: any, index: number) => {
                const { action_date, type_of_action } = action;
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell width={185}>
                      <Typography color='#6F6F6F'>
                        {moment(action_date).format('DD/MM/YYYY hh:mm A')}
                      </Typography>
                    </TableCell>

                    <TableCell width={40} sx={{ position: 'relative' }}>
                      <Circle />
                      <Line index={index} actionLists={actionLists} />
                    </TableCell>

                    <TableCell>
                      <Typography>{type_of_action}</Typography>
                    </TableCell>

                    <TableCell width={100}>
                      <ViewButton onClick={() => handleView(action)}>
                        <Typography>view details</Typography>
                      </ViewButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <ViewDetailsModal
          open={openView}
          onClose={() => setOpenView(false)}
          data={viewData}
        />
      </Box>
    </>
  );
}
