import { FormikValues } from 'formik';
import { mapKeys } from 'lodash';
import { findPath } from 'utils/workflow/jsonModifier';

export const MESSAGE_JSON_KEY = 'msgJson';

const getPath = (id: string, obj: any) => {
  const path = findPath(id, obj);
  let arr = path.split('.');
  arr.pop();
  return arr.join('.');
};

const getStepValue = (obj: any, pathValue: string) => {
  if (pathValue.length === 1) return obj[pathValue];

  const path = pathValue.split('.');
  const len = path.length;
  for (let i = 0; i < len; i++) {
    obj = obj[path[i]];
  }
  return obj;
};

const updateMsgObj = (object: any, path: string, value: any) => {
  if (path.length === 1) {
    object[path] = value;
  } else {
    const parts = path.split('.');
    const limit = parts.length - 1;
    for (let i = 0; i < limit; ++i) {
      const key = parts[i];
      object = object[key] ?? (object[key] = {});
    }
    const key = parts[limit];
    object[key] = value;
  }
};

export const getMessageJson = () => localStorage.getItem(MESSAGE_JSON_KEY);

export const createMessageJson = (send_message: string) => {
  let msg = {
    Message: {
      msg_id: '1',
      send_message,
      response_expected: 1,
      response_predefined: 1,
      Responses: {},
    },
  };
  localStorage.setItem(MESSAGE_JSON_KEY, JSON.stringify(msg));
};

export const updateMessageJson = ({
  msgId,
  key,
  value,
  preValue,
  optInd,
  optName,
  emptyPrev,
}: {
  msgId: string;
  key: string;
  value: string | number;
  preValue?: string;
  optInd?: number;
  optName?: string;
  emptyPrev?: string;
}) => {
  const msgObj = JSON.parse(localStorage.getItem(MESSAGE_JSON_KEY) || '{}');
  let newMsg = msgObj;
  const path = getPath(msgId, msgObj);
  const obj = getStepValue(msgObj, path);

  if (key === 'response_type') {
    let newObj: any = {
      msg_id: msgId,
      send_message: obj.send_message || '',
      response_expected: 1,
      response_predefined: 0,
      Responses: {
        default: {},
      },
    };
    if (value === 'predefined') {
      newObj = {
        msg_id: msgId,
        send_message: '',
        response_expected: 1,
        response_predefined: 1,
        Responses: {},
      };
    }
    updateMsgObj(newMsg, path, newObj);
  }

  if (key === 'message') {
    const newObj = {
      ...obj,
      send_message: value,
    };
    updateMsgObj(newMsg, path, newObj);
  }

  if (key === 'option') {
    let newResObj = { ...obj.Responses };
    let prev = preValue || emptyPrev;
    if (
      prev &&
      prev in newResObj &&
      Object.keys(newResObj).indexOf(prev) === optInd
    ) {
      newResObj = mapKeys(newResObj, (_, key) => {
        if (key === prev) return value;
        return key;
      });
    } else {
      newResObj = { ...obj.Responses, [value]: {} };
    }
    const newObj = { ...obj, Responses: newResObj };
    updateMsgObj(newMsg, path, newObj);
  }

  if (key === 'link_check') {
    if (optName && !value) {
      let newResObj = { ...obj.Responses };
      newResObj[optName] = {
        ...newResObj[optName],
        msg_id: '',
        send_message: '',
        response_expected: 0,
        response_predefined: 0,
      };
      const newObj = { ...obj, Responses: newResObj };
      updateMsgObj(newMsg, path, newObj);
    }
    if (optName && value) {
      let newResObj = { ...obj.Responses };
      newResObj[optName] = {
        ...newResObj[optName],
        msg_id: `${+value + 1}`,
        send_message: '',
        response_expected: 0,
        response_predefined: 0,
      };
      const newObj = { ...obj, Responses: newResObj };
      updateMsgObj(newMsg, path, newObj);
    }
  }

  if (key === 'link_msg') {
    if (optName) {
      let newResObj = { ...obj.Responses };
      newResObj[optName] = {
        ...newResObj[optName],
        msg_id: `${+value + 1}`,
        send_message: '',
        response_expected: 0,
        response_predefined: 0,
      };
      const newObj = { ...obj, Responses: newResObj };
      updateMsgObj(newMsg, path, newObj);
    }
  }

  if (key === 'add_action') {
    if (optName) {
      let newResObj = { ...obj.Responses };
      newResObj[optName] = {
        ...newResObj[optName],
        action: value,
      };
      const newObj = { ...obj, Responses: newResObj };
      updateMsgObj(newMsg, path, newObj);
    }
  }

  if (key === 'response_expected') {
    if (value) {
      const newObj = {
        ...obj,
        response_expected: 1,
        response_predefined: 1,
        Responses: {},
      };
      updateMsgObj(newMsg, path, newObj);
    }
  }

  if (key === 'delete_msg') {
    const path = getPath(msgId, msgObj);
    updateMsgObj(newMsg, path, {});
  }

  if (key === 'delete_option') {
    if (optName) {
      let newResObj = { ...obj.Responses };
      delete newResObj[optName];

      const newObj = { ...obj, Responses: newResObj };
      updateMsgObj(newMsg, path, newObj);
    }
  }

  localStorage.setItem(MESSAGE_JSON_KEY, JSON.stringify(newMsg));
};

export const deleteMessageJson = () => {
  localStorage.removeItem(MESSAGE_JSON_KEY);
};

export const getMsgObj = (values: FormikValues) => {
  if (values.length < 1) return {};

  let obj: any = {};
  values.forEach((item: any, index: number) => {
    const { defaultResponse } = item;
    let responses: any = {};
    item.responses.forEach((resp: any) => {
      if (resp.option) {
        responses[resp.option] = {
          msg_id: resp.message ? `${+resp.message + 1}` : '',
          action: resp.action,
          action_attribute: resp.action_attribute,
        };
      }
    });
    const default_response = defaultResponse?.linkMessage
      ? {
          msg_id: defaultResponse.message
            ? `${+defaultResponse.message + 1}`
            : '',
          action: defaultResponse.action || '',
          action_attribute: defaultResponse.action_attribute || '',
        }
      : {};
    obj[`${index + 1}`] = {
      msg_id: `${index + 1}`,
      send_message: item.msg,
      Responses: responses,
      default_response: default_response,
    };
  });
  return obj;
};

const buildConfig = (messages: any, config_json: any, addedMsgs: string[]) => {
  config_json['response_expected'] = 0;
  config_json['response_predefined'] = 0;

  // console.log({ config_res: config_json.Responses });

  if (config_json.Responses && Object.keys(config_json.Responses).length > 0) {
    config_json['response_expected'] = 1;
    config_json['response_predefined'] = 1;
    for (let key in config_json.Responses) {
      const message_index = config_json.Responses[key].msg_id;
      if (message_index && !addedMsgs.includes(message_index)) {
        config_json['Responses'][key] = {
          ...config_json['Responses'][key],
          ...messages[message_index],
        };
        addedMsgs.push(message_index);
        buildConfig(messages, config_json['Responses'][key], addedMsgs);
      }
    }
  }

  if (config_json.default_response && config_json.default_response.msg_id) {
    config_json['response_expected'] = 1;
    const message_index = config_json['default_response']['msg_id'];
    if (message_index && !addedMsgs.includes(message_index)) {
      const default_response = config_json['default_response'];
      config_json['default_response'] = {
        ...default_response,
        ...messages[message_index],
      };
      addedMsgs.push(message_index);
      buildConfig(messages, config_json['default_response'], addedMsgs);
    }
  }

  return config_json;
};

export const getJsonConfig = (msgs_objects: any) => {
  let addedMsgs: string[] = [];

  let config_json = { Messages: {} };
  config_json['Messages'] = msgs_objects['1'];
  addedMsgs.push('1');
  // console.log('config_msg', config_json['Messages']);
  // console.log({ msgs_objects });
  let final_config = buildConfig(
    msgs_objects,
    config_json['Messages'],
    addedMsgs
  );
  return final_config;
};
