import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { createPermissions } from 'utils/helper';
import { logout, setUserCookie } from '../../../utils/auth';
import { getUserConfig, login, verifyEmailToken } from './actions';
import { AwsLinkType } from 'constants/types';

export interface AuthTypes {
  accessToken: string;
  email: string;
  userId: string;
  verifyEmailData: null | any;
  authUrl: string;
  phone_number: string;
  role: string;
  role_name: string;
  userPermissions: any;
  aws_url: null | {
    file_upload: AwsLinkType;
    integration_api: AwsLinkType;
    template_api: AwsLinkType;
    cust_mgmt_customer_pii_data_update_api: AwsLinkType;
    cust_mgmt_get_pii_data_api: AwsLinkType;
  };
}

const initialState: AuthTypes = {
  accessToken: '',
  email: '',
  userId: '',
  authUrl: '',
  verifyEmailData: null,
  phone_number: '',
  role: '',
  role_name: '',
  userPermissions: null,
  aws_url: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setVerifyEmailData: (state, { payload }) => {
      state.verifyEmailData = payload;
    },
    handleLogOut: (state) => {
      state.verifyEmailData = null;
      logout();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.status === 'SUCCESS' && meta?.success === true) {
        state.accessToken = data.access_token;
        state.email = data.email;
        state.phone_number = data.phone_number;
        state.authUrl = data.url;
        state.userId = data.user_id;
        state.verifyEmailData = null;
        state.aws_url = data?.aws_url;
        state.role_name = data?.role_name || 'admin';
        setUserCookie(data);
      }
    });
    builder.addCase(verifyEmailToken.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (meta?.status === 'SUCCESS' && meta?.message === 'email verified') {
        state.verifyEmailData = data;
      }
    });
    builder.addCase(getUserConfig.fulfilled, (state, { payload }) => {
      const permissions = createPermissions(payload);
      state.userPermissions = permissions;
    });
  },
});

export const { setVerifyEmailData, handleLogOut } = AuthSlice.actions;

export { login };

export default AuthSlice.reducer;

export const userPermissions = (state: RootState) => state.auth.userPermissions;
export const awsUrl = (state: RootState) => state.auth.aws_url;
