import React from 'react';
import useStyles from './styles';

const DataInput = ({
  value,
  placeholder = '',
}: // handleChange,
{
  value: string;
  placeholder?: string;
  // handleChange: (x: string) => void;
}) => {
  const classes = useStyles();

  return (
    <input
      type='text'
      placeholder={placeholder}
      value={value}
      onChange={() => {}}
      disabled={true}
      className={classes.dataInput}
    />
  );
};

export default DataInput;
