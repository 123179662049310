import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  CustomerCallDatePicker,
  CustomerCallInput,
  CustomerCallRadio,
  CustomerCallSelect,
} from 'components/formik/customer/call';

const RefusedToPay = () => {
  return (
    <Box>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <Form>
            <CustomerCallSelect
              label='Specify more*'
              name='specify_more'
              options={[]}
            />

            <CustomerCallRadio label='Amount paid' name='amount' />

            <CustomerCallInput name='paid_amount' label='Paid Amount' />

            <CustomerCallDatePicker label='Paid on' name='paid_on' />

            <CustomerCallDatePicker
              label='Please select PTP date for balance amount (if any)'
              name='ptp_date'
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default RefusedToPay;
