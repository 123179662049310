import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import BasicModal from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  allWorkflowExecution,
  getFunnelMapping,
  runWorkflow,
  workflowHandler,
} from 'store/reducer/workflowReducer/action';
import Loader from 'components/loader';
import { getGraphData, setGraphData } from 'utils/workflow/graphHandler';
import { useNavigate } from 'react-router-dom';
import {
  CreateDuplicateWorkflowModal,
  DeleteModal,
} from 'components/workflow/landing';
import { kollectAITheme } from 'config/theme';
import WorkflowSelect from './workflowSelect';
import {
  setFunnelData,
  setFunnelMapping,
  setRefetchWorkflow,
  setWorkflowFunnel,
} from 'store/reducer/workflowReducer';
import { CiCalendar } from 'react-icons/ci';
import moment from 'moment';
import { FiChevronLeft } from 'react-icons/fi';
import funnelImg from 'assets/funnel.svg';
import WorkflowExecutionSelect from './executionSelect';
// import { userAccess } from 'utils/helper';
import {
  Button,
  DatePickerCont,
  FullScreenButton,
  FunnelViewCont,
  RunWorkflowId,
  ViewFunnelButton,
  WorkflowToolWrapper,
} from './style';
import { userPermissions } from 'store/reducer/auth';
import { MODULELIST } from 'constants/types';
import { isStrategyWorkflowDeletable } from 'utils/helper';
import WorkflowForceDelete from 'components/workflow/landing/forceDeleteModal';

interface WorkflowEditorToolProps {
  handleSidebar: () => void;
  handleSave: () => void;
  handlePublish: () => void;
  enlargeIcon: string;
}

const WorkflowEditorTools: React.FC<WorkflowEditorToolProps> = ({
  handleSidebar,
  enlargeIcon,
}) => {
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [workflowId, setWorkflowId] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [runDate, setRunDate] = useState<Date | null>(new Date());
  const [isInsideView, setIsInsideView] = useState<boolean>(false);
  const [runWorkflowMeta, setRunWorkflowMeta] = useState<{
    show: boolean;
    msg: string;
  }>({ show: false, msg: '' });
  const [selectedExecution, setSelectedExecution] = useState<string>('');
  const [openForceDelete, setOpenForceDelete] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<
    { strategy: string; usedList: string[] }[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const workflowData = useAppSelector(
    (state: any) => state.workflow.allWorkflow
  );
  const refetchWorkflow = useAppSelector(
    (state: any) => state.workflow.refetchWorkflow
  );
  const workflowFunnel: any = useAppSelector(
    (state: any) => state.workflow.workflowFunnel
  );
  // const userRole = useAppSelector((state: any) => state.auth.role);
  const funnelData: any = useAppSelector((state) => state.workflow.funnelData);

  const workflowGraphData = getGraphData();

  useEffect(() => {
    const { published, workflow_id } = getGraphData();
    setWorkflowId(workflow_id);
    const publish = published ? true : false;
    setIsPublished(publish);

    dispatch(setRefetchWorkflow(false));
    setRunWorkflowMeta({ show: false, msg: '' });
    setSelectedExecution(funnelData?.executionId || '');

    const { last_execution_date } = getGraphData();
    setRunDate(new Date(last_execution_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWorkflow]);

  const handlePublish = async () => {
    try {
      setLoading(true);
      const { meta } = await dispatch(
        workflowHandler({ workflow_id: [workflowId], action: 'publish' })
      ).unwrap();
      if (meta.status === 'SUCCESS') {
        let payload = { ...workflowGraphData };
        payload.published = 1;
        setGraphData('graphData', payload);
        dispatch(setRefetchWorkflow(true));
        navigate('/workflow-editor');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDuplicateModalClose = () => setOpenDuplicateModal(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const handleBack = () => {
    dispatch(setWorkflowFunnel({ viewFunnel: false, workflowId: '' }));
    if (!isInsideView) {
      navigate(-1);
    }
    setIsInsideView(false);
  };

  const handleViewFunnel = async () => {
    setLoading(true);
    const { workflow_id, last_execution_id, last_execution_date } =
      workflowGraphData;
    const date = moment(last_execution_date).format('YYYY-MM-DD');

    await dispatch(
      getFunnelMapping({ workflow_id, date, execution_id: last_execution_id })
    ).unwrap();
    await dispatch(allWorkflowExecution({ workflowId: workflow_id, date }));
    dispatch(setWorkflowFunnel({ viewFunnel: true, workflowId: workflow_id }));
    dispatch(
      setFunnelData({
        executionId: last_execution_id,
        workflowId: workflow_id,
        date,
      })
    );
    dispatch(setRefetchWorkflow(true));
    setIsInsideView(true);
    setLoading(false);
  };

  const handleRun = async (testing_flag?: boolean) => {
    try {
      setLoading(true);
      const { workflow_id } = workflowGraphData;
      let payload: any = { workflow_id };
      if (testing_flag) payload.testing_flag = true;
      const { msg } = await dispatch(runWorkflow(payload)).unwrap();
      setRunWorkflowMeta({ show: true, msg });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = async (date: Date) => {
    setLoading(true);
    try {
      setRunDate(date);
      const runDate = moment(date).format('YYYY-MM-DD');

      const { result } = await dispatch(
        allWorkflowExecution({ workflowId, date: runDate })
      ).unwrap();

      const exId = result[0]?.we_id || '';
      if (exId) {
        await dispatch(
          getFunnelMapping({
            workflow_id: workflowId,
            date: runDate,
            execution_id: exId,
          })
        );
      } else {
        dispatch(setFunnelMapping([]));
      }
      dispatch(
        setFunnelData({ ...funnelData, date: runDate, executionId: exId })
      );
      setSelectedExecution(exId);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleExecutionChange = async (execution: any) => {
    setLoading(true);
    try {
      setSelectedExecution(execution.value);
      const date = moment(runDate).format('YYYY-MM-DD');
      await dispatch(
        getFunnelMapping({
          workflow_id: workflowId,
          date,
          execution_id: execution.value,
        })
      );
      dispatch(setFunnelData({ ...funnelData, executionId: execution.value }));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      let payload: any = {
        workflow_id: [workflowGraphData?.workflow_id],
        action: 'delete',
      };
      const { meta, data } = await dispatch(workflowHandler(payload)).unwrap();
      if (meta?.success) {
        const { deletable, usedList } = isStrategyWorkflowDeletable(data);
        if (!deletable) {
          setDeleteData(usedList);
          setOpenDeleteModal(false);
          setOpenForceDelete(true);
        } else {
          await dispatch(
            workflowHandler({ ...payload, force_delete: true })
          ).unwrap();
          navigate('/workflow');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleForceDelete = async () => {
    setLoading(true);
    try {
      await dispatch(
        workflowHandler({
          workflow_id: [workflowGraphData?.workflow_id],
          action: 'delete',
          force_delete: true,
        })
      ).unwrap();
      navigate('/workflow');
    } finally {
      setLoading(false);
    }
  };

  // const userPermissions = userAccess(userRole)?.workflow.permissions;
  const permissions = useAppSelector(userPermissions);

  return (
    <>
      <Loader loading={loading} />
      <WorkflowToolWrapper>
        {workflowFunnel.viewFunnel && workflowFunnel.workflowId ? (
          <FunnelViewCont>
            <button onClick={handleBack}>
              <FiChevronLeft />
              <Typography
                sx={{
                  fontWeight: 600,
                  textDecoration: 'underline',
                  color: kollectAITheme.primaryColor,
                }}
              >
                Back
              </Typography>
            </button>

            <Box display='flex' alignItems='center' gap='1rem'>
              <Box display='flex' alignItems='center' gap='10px'>
                <Typography>Workflow run data for:</Typography>
                <RunWorkflowId>{workflowGraphData?.wf_name}</RunWorkflowId>
              </Box>

              <Box display='flex' alignItems='center' gap='10px'>
                <Typography>Execution ID:</Typography>
                <WorkflowExecutionSelect
                  setLoading={setLoading}
                  selected={selectedExecution}
                  handleChange={handleExecutionChange}
                />
              </Box>
            </Box>

            <Box display='flex' alignItems='center' gap='10px'>
              <Typography>Run on:</Typography>
              <DatePickerCont>
                <DatePicker
                  selected={runDate}
                  onChange={handleDateChange}
                  minDate={moment(new Date()).subtract(30, 'days').toDate()}
                  maxDate={new Date()}
                />
                <CiCalendar />
              </DatePickerCont>
            </Box>
          </FunnelViewCont>
        ) : (
          <>
            <Box display='flex' alignItems='center' gap='0.5rem'>
              <Box display='flex' gap='0.5rem' alignItems='center'>
                <Typography>Workflow:</Typography>
                <WorkflowSelect setLoading={setLoading} />
              </Box>

              {workflowGraphData.last_execution_id &&
              workflowGraphData.last_execution_date &&
              workflowGraphData.published ? (
                <ViewFunnelButton onClick={handleViewFunnel}>
                  <img src={funnelImg} alt='' />
                  <Typography>View funnel</Typography>
                </ViewFunnelButton>
              ) : (
                ''
              )}

              <FullScreenButton onClick={handleSidebar}>
                {enlargeIcon}
              </FullScreenButton>
            </Box>

            <Box display='flex' alignItems='center' gap='0.5rem'>
              {!isPublished ? (
                <>
                  {/* {userPermissions.includes('update') && ( */}
                  {permissions[MODULELIST.WORKFLOW].includes('update') && (
                    <Button color='#00C087' onClick={handlePublish}>
                      Publish
                    </Button>
                  )}

                  {/* {userPermissions.includes('delete') && ( */}
                  {permissions[MODULELIST.WORKFLOW].includes('delete') && (
                    <Button
                      color='#DAA09A'
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {permissions[MODULELIST.WORKFLOW].includes('execute') && (
                    <Button color='#6AC5AB' onClick={() => handleRun(true)}>
                      Simulate
                    </Button>
                  )}

                  {permissions[MODULELIST.WORKFLOW].includes('execute') && (
                    <Button color='#6AC5AB' onClick={() => handleRun()}>
                      Run Now
                    </Button>
                  )}

                  {permissions[MODULELIST.WORKFLOW].includes('create') && (
                    <Button
                      color='#047D95'
                      onClick={() => setOpenDuplicateModal(true)}
                    >
                      Copy & Edit
                    </Button>
                  )}

                  {permissions[MODULELIST.WORKFLOW].includes('delete') && (
                    <Button
                      color='#DAA09A'
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </WorkflowToolWrapper>

      <BasicModal
        open={openDuplicateModal}
        onClose={handleDuplicateModalClose}
        children={
          <CreateDuplicateWorkflowModal
            workflowData={workflowData}
            setLoading={setLoading}
            selectedWorkflow={[workflowId]}
            handleClose={handleDuplicateModalClose}
          />
        }
      />

      <BasicModal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        children={
          <DeleteModal
            workflowId={workflowGraphData.workflow_id}
            workflowName={workflowGraphData.wf_name}
            isPublished={isPublished}
            onClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        }
      />

      <BasicModal
        open={runWorkflowMeta.show}
        onClose={() => setRunWorkflowMeta({ show: false, msg: '' })}
        children={
          <Box width={320}>
            <Typography sx={{ fontWeight: 600, opacity: 0.5 }}>
              {runWorkflowMeta?.msg}
            </Typography>
          </Box>
        }
      />

      <WorkflowForceDelete
        open={openForceDelete}
        deleteData={deleteData}
        onClose={() => setOpenForceDelete(false)}
        handleForceDelete={handleForceDelete}
      />
    </>
  );
};

export default WorkflowEditorTools;
