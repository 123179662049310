import * as React from 'react';
import { Box, Modal } from '@mui/material';

export type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableEnforceFocus?: any;
};

const PlainModal: React.FC<ModalProps> = ({
  children,
  open = false,
  onClose,
  disableEnforceFocus = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEnforceFocus={disableEnforceFocus}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '4px',
          outline: 'none',
          '& p': { fontFamily: 'Open sans', color: '#32475CDE' },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default PlainModal;
