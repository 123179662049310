import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import BasicModal from 'components/Modal';
import { FaFileAlt, FaFileCode } from 'react-icons/fa';
import { HiXMark } from 'react-icons/hi2';
import useStyles from './index.styles';

import MultiTemplateChannelSelect from './channelSelect';
import MultiTemplateDragAndDrop from './dragAndDrop';
import { useAppDispatch } from 'hooks/useRedux';
import { bulkTemplateUpload } from 'store/reducer/templateReducer/action';
import Loader from 'components/loader';
import { errorToast, successToast } from 'utils/toast';

interface MultiTemplateModalProps {
  open: boolean;
  onClose: () => void;
  getAllTemplates: () => void;
}

interface SelectOption {
  label: string;
  value: string;
}

const ChannelPreview = ({
  selectedChannel,
  content,
}: {
  selectedChannel: string;
  content: string;
}) => {
  if (selectedChannel === 'email') {
    return (
      <Box
        sx={{ width: '600px', height: '700px', overflow: 'auto' }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></Box>
    );
  } else {
    return (
      <Box sx={{ width: '600px', maxHeight: '700px', overflow: 'auto' }}>
        {content}
      </Box>
    );
  }
};

const channelOptions: SelectOption[] = ['email', 'sms', 'whatsapp', 'ivr'].map(
  (channel: string) => ({ label: channel.toUpperCase(), value: channel })
);

const MultiTemplateModal: React.FC<MultiTemplateModalProps> = ({
  open,
  onClose,
  getAllTemplates,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [previewModal, setPreviewModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<SelectOption>({
    label: 'EMAIL',
    value: 'email',
  });
  const [fileList, setFileList] = useState<any>({
    email: [],
    sms: [],
    ivr: [],
    whatsapp: [],
  });
  const [previewContent, setPreviewContent] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleChannelChange = (channel: SelectOption) => {
    setSelectedChannel(channel);
  };

  const handlePreview = async (file: any) => {
    const content: string = await new Promise((resolve) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = function (e: any) {
          resolve(e.target.result);
        };
        reader.readAsText(file);
      }
    });
    setPreviewContent(content);
    setPreviewModal(true);
  };

  const fileRemove = (file: any) => {
    const selected = selectedChannel.value;
    let channelList = fileList[selected];
    channelList.splice(fileList[selected].indexOf(file), 1);
    const updatedFileList = { ...fileList, [selected]: channelList };
    setFileList(updatedFileList);
    setFileList(updatedFileList);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const channel = selectedChannel.value;
      let formdata: any = new FormData();
      formdata.append('channel_type', channel);

      fileList[channel].forEach((file: any, index: number) => {
        const ind = index + 1;
        formdata.append(`file_${ind}`, file);
      });
      const { meta, data } = await dispatch(
        bulkTemplateUpload(formdata)
      ).unwrap();
      if (meta?.success) {
        const totalTemplates = data['Total Templates'];
        const validTemplates =
          data['Valid JSON File'] + data['Valid Text File'];
        const errorInfo = data['Error Information'];

        const errorToaster = () =>
          errorToast(
            <Box>
              <Typography sx={{ lineHeight: '1.1', mb: '5px' }}>
                Following templates could not be uploaded:
              </Typography>
              <ul>
                {Object.keys(errorInfo).map((error: string) => (
                  <li key={error} style={{ marginBottom: '4px' }}>
                    {error.split(':')[1]}
                  </li>
                ))}
              </ul>
            </Box>
          );

        if (validTemplates === 0) {
          errorToaster();
        } else {
          successToast(
            `${validTemplates} ${channel} ${
              validTemplates > 1 ? 'templates' : 'template'
            } uploaded successfully`
          );
          if (totalTemplates !== validTemplates) {
            errorToaster();
          }
          resetState();
          onClose();
          getAllTemplates();
        }
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const resetState = () => {
    setFileList({
      email: [],
      sms: [],
      ivr: [],
      whatsapp: [],
    });
    setSelectedChannel({
      label: 'EMAIL',
      value: 'email',
    });
  };

  const submitDisabled = useMemo(
    () =>
      Object.values(fileList).every(
        (value) => Array.isArray(value) && value.length === 0
      ),
    [fileList]
  );

  return (
    <>
      <Loader loading={loading} />
      <BasicModal
        open={open}
        onClose={() => {
          onClose();
          setFileList({
            email: [],
            sms: [],
            ivr: [],
            whatsapp: [],
          });
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            width: '850px',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box textAlign='center'>
            <Typography sx={{ fontWeight: 500, fontSize: '1.3rem' }}>
              Feel free to upload multiple templates
            </Typography>
            <Typography color='rgba(0, 0, 0, 0.6)'>
              As this is for bulk update, the name of the file will be used as
              template name and label will be empty
            </Typography>
          </Box>

          <MultiTemplateChannelSelect
            selectedChannel={selectedChannel}
            channelOptions={channelOptions}
            onChange={handleChannelChange}
          />

          <Box
            sx={{
              width: '85%',
              marginTop: '2rem',
              height: `${
                fileList[selectedChannel.value].length > 0 ? '120px' : '400px'
              }`,
            }}
          >
            <MultiTemplateDragAndDrop
              size={`${
                fileList[selectedChannel.value].length > 0 ? 'small' : 'large'
              }`}
              selectedChannel={selectedChannel.value}
              fileList={fileList}
              setFileList={setFileList}
            />
          </Box>

          <Box width='85%'>
            {fileList[selectedChannel.value].length > 0 && (
              <Box>
                <Typography>File added</Typography>
                <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
                  {fileList[selectedChannel.value].map(
                    (file: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: '100px 1fr 100px',
                            padding: '0.5rem 1rem',
                          }}
                        >
                          <Box>
                            {file.type === 'text/html' ? (
                              <FaFileCode color='#00C087' fontSize='25px' />
                            ) : (
                              <FaFileAlt color='#00C087' fontSize='25px' />
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'start',
                              gap: '10px',
                            }}
                          >
                            <Box>
                              <Typography>{file.name}</Typography>
                              <Typography
                                fontSize='12px'
                                color='rgba(0, 0, 0, 0.7)'
                              >
                                {file.size} B
                              </Typography>
                            </Box>
                            <button onClick={() => handlePreview(file)}>
                              <Typography color='#00C087'>Preview</Typography>
                            </button>
                          </Box>
                          <button onClick={() => fileRemove(file)}>
                            <HiXMark fontSize='16px' />
                          </button>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: '2px solid #FBFDFE',
            padding: '1rem 0.5rem 0',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <button
              className={classes.multiTemplateButton}
              onClick={() => {
                resetState();
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className={classes.multiTemplateButton}
              style={{
                color: submitDisabled ? '#fff' : '#00C087',
                backgroundColor: submitDisabled ? 'rgb(162, 167, 174)' : '#fff',
                cursor: submitDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={handleUpload}
              disabled={submitDisabled}
            >
              Upload
            </button>
          </Box>
        </Box>
      </BasicModal>

      <BasicModal
        open={previewModal}
        onClose={() => {
          setPreviewModal(false);
          setPreviewContent('');
        }}
        children={
          <ChannelPreview
            selectedChannel={selectedChannel.value}
            content={previewContent}
          />
        }
      />
    </>
  );
};

export default MultiTemplateModal;
