import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import useStyles from './index.styles';
import { kollectAITheme } from 'config/theme';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  // getCustomerActivity,
  getCustomerActivityDetails,
} from 'store/reducer/customerReducer/action';
import Loader from 'components/loader';
import CustomerActivityFilter from './filter';
import { constumerActivity } from 'store/reducer/customerReducer';
import ActivityViewDetails from './viewDetails';

export default function DenseTable() {
  const classes = useStyles();

  const { id: customerId } = useParams();
  const dispatch = useAppDispatch();
  // const [activities, setActivities] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openView, setOpenView] = React.useState<boolean>(false);
  const [viewData, setViewData] = React.useState<any>(null);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>('');
  const [date, setDate] = React.useState<Date | null>(null);

  const activities = useAppSelector(constumerActivity);

  const handleView = async (activity: any) => {
    setLoading(true);
    try {
      let id =
        activity?.payment_id ||
        activity?.ac_id ||
        activity?.message_sid ||
        activity?.call_sid ||
        activity?.workflow_id;
      let payload = {
        customer_id: customerId,
        id,
        message: activity?.msg,
        ...(activity?.step_name && { step_name: activity?.step_name }),
      };
      if (['SMS', 'Whatsapp'].includes(activity?.communication_medium)) {
        // if (['Whatsapp'].includes(activity?.communication_medium)) {
        payload.conversation_id = activity?.conversation_id || null;
      }
      const { data } = await dispatch(
        getCustomerActivityDetails(payload)
      ).unwrap();
      if (data) {
        const { contact_id, template_id, dpd, bucket, ...rest } = data;
        let view = rest;
        if (activity?.communication_medium)
          view.communication_medium = activity.communication_medium;
        setViewData(view);
        setOpenView(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <Box my='1rem'>
        <Box className={classes.customerActivityHeader}>
          <Typography>Most recent at the top</Typography>
          <Box sx={{ position: 'relative' }}>
            <button onClick={() => setShowFilter((prev) => !prev)}>
              <TuneIcon />
            </button>
            {showFilter ? (
              <CustomerActivityFilter
                onClose={() => setShowFilter(false)}
                filter={filter}
                setFilter={setFilter}
                date={date}
                setDate={setDate}
              />
            ) : null}
          </Box>
        </Box>

        <TableContainer sx={{ maxHeight: 800 }}>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                padding: '10px 0',
              },
            }}
            size='small'
            aria-label='a dense table'
          >
            <TableBody>
              {activities?.map((workflow: any, index: number) => {
                const { date_time, msg } = workflow;
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <Typography color='#6F6F6F'>
                        {moment(date_time).format('DD/MM/YYYY hh:mm A')}
                      </Typography>
                    </TableCell>

                    <TableCell
                      width={40}
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          background: '#D9D9D9',
                          border: '1px solid #004357',
                          width: '14px',
                          height: '14px',
                          borderRadius: '50%',
                          zIndex: 10,
                          position: 'relative',
                        }}
                      />
                      <Box
                        sx={{
                          '&:before': {
                            position: 'absolute',
                            borderLeft: '1px solid #004357',
                            width: 0,
                            height: '100%',
                            display: 'block',
                            content: '""',
                            left: 8,
                            top:
                              index === 0
                                ? '50%'
                                : index === activities.length - 1
                                ? '-50%'
                                : 0,
                          },
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography>{msg}</Typography>
                    </TableCell>

                    <TableCell>
                      <button onClick={() => handleView(workflow)}>
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            color: kollectAITheme.primaryColor,
                          }}
                        >
                          view details
                        </Typography>
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <ActivityViewDetails
          open={openView}
          onClose={() => setOpenView(false)}
          data={viewData}
        />
      </Box>
    </>
  );
}
