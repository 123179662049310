import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import { availableActions } from './action_trigger';
import { kollectAITheme } from 'config/theme';
import { FiSearch } from 'react-icons/fi';
import { useDebouncedCallback } from 'use-debounce';

const TemplateActionTrigger = () => {
  // const actionTrigger: any = actions;
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<any>(availableActions);

  const debounced = useDebouncedCallback((value) => {
    let filteredData = availableActions.filter((attribute: any) =>
      attribute.name.toLowerCase().includes(value.toLowerCase())
    );
    setData(filteredData);
    setSearchValue(value);
  }, 0);

  return (
    <Box
      sx={{
        border: '1px solid #6F6F6F',
        borderRadius: '10px',
        height: 'calc(100vh - 225px)',
        overflow: 'auto',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box
        padding='10px 20px'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography color='#6F6F6F' fontWeight={600}>
          Available Action Triggers
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #6F6F6F',
            borderRadius: '5px',
            height: '35px',
            padding: '0 10px',
            '& input': {
              border: 'transparent',
              background: 'transparent',
              outline: 'none',
              height: '32px',
              fontSize: '1rem',
              paddingLeft: '10px',
              fontFamily: 'Montserrat',
            },
          }}
        >
          <FiSearch />
          <input
            type='text'
            placeholder='Search Action'
            value={searchValue}
            onChange={(e) => debounced(e.target.value)}
          />
        </Box>
      </Box>

      <Box>
        {data.map((attribute: any, index: number) => {
          return (
            <Box borderTop='1px solid #6F6F6F' key={index}>
              <Box
                sx={{
                  padding: '10px 20px',
                  display: 'grid',
                  gridTemplateColumns: '200px 500px',
                  alignItems: 'center',
                  columnGap: '2rem',
                }}
              >
                <Typography
                  color={kollectAITheme.primaryColor}
                  fontWeight={600}
                >
                  {attribute.name}
                </Typography>
                <Typography color='#6F6F6F' fontWeight={500}>
                  {attribute.description}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TemplateActionTrigger;
