import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Select from 'react-select';
import { kollectAITheme } from 'config/theme';
import { getGraphData, setGraphData } from 'utils/workflow/graphHandler';
import { getWorkflowById } from 'store/reducer/workflowReducer/action';
import { setRefetchWorkflow } from 'store/reducer/workflowReducer';

interface WorkflowSelectProps {
  setLoading: (x: boolean) => void;
}

export const workflowSelectStyles = {
  control: (styles: any) => ({
    ...styles,
    background: '#E3E5E8',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: 0,
    width: '290px',
    borderRadius: 0,
    minHeigth: '35px',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#000',
    fontSize: '0.8rem',
  }),
  placeholder: (styles: any) => ({ ...styles, color: '#000' }),
  menuList: (styles: any) => ({ ...styles, padding: 0 }),
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: isSelected ? '#fff' : '#000',
      background: isSelected
        ? kollectAITheme.primaryColor
        : kollectAITheme.lightPrimaryColor,
    };
  },
};

const WorkflowSelect: React.FC<WorkflowSelectProps> = ({ setLoading }) => {
  const dispatch = useAppDispatch();

  const [workflowIdOptions, setWorkflowIdOptions] = useState([]);

  const allWorkflowData = useAppSelector(
    (state: any) => state.workflow.allWorkflow
  );
  const workflowGraphData = getGraphData();

  useEffect(() => {
    const options = allWorkflowData?.map((workflow: any) => ({
      label: workflow.workflow_name,
      value: workflow.workflow_id,
    }));
    setWorkflowIdOptions(options);
  }, [allWorkflowData]);

  const defaultValue = (options: any, value: string) => {
    return options ? options.find((option: any) => option.value === value) : '';
  };

  return (
    <Select
      value={defaultValue(workflowIdOptions, workflowGraphData?.workflow_id)}
      onChange={async (value: any) => {
        setLoading(true);
        const { data } = await dispatch(
          getWorkflowById({ workflow_id: value.value })
        ).unwrap();
        setGraphData('graphData', data);
        dispatch(setRefetchWorkflow(true));
        setLoading(false);
      }}
      options={workflowIdOptions}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={workflowSelectStyles}
    />
  );
};

export default WorkflowSelect;
