import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateChannelSelectBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #E8E8E8',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '0.6rem',
      cursor: 'pointer',
      color: theme.kollectAITheme.primaryColor,
    },
    templateChannelSelectBoxSelected: {
      background: theme.kollectAITheme.primaryColor,
      color: '#fff',
    },
    templateSelectBoxIconStyle: {
      width: '40px',
      background: theme.kollectAITheme.primaryColor,
      borderRadius: '10px',
      display: ' flex',
      justifyContent: 'center',
      alignItems: ' center',
      height: '40px',
      marginRight: '1.5em',
    },
    templateSelectBoxIconSelected: {
      background: '#E6EFF9',
    },
  })
);

export default useStyles;
