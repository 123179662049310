import { useState } from 'react';
import { Box } from '@mui/material';

import dbConnectJson from '../db_connect_attributes.json';
import DBConnectedScreen from './connectedScreen';
import DBConnectLandingScreen from './landingScreen';
import Loader from 'components/loader';

export interface DBFormValue {
  host: string;
  port: string;
  database: string;
  username: string;
  password: string;
}

const IntegrationDBConnection = () => {
  const attributeJson: any = dbConnectJson;

  const [loading, setLoading] = useState(false);
  const [isDBConnected, setIsDBConnected] = useState<boolean>(false);
  const [connectionData, setConnectionData] = useState<DBFormValue | {}>({});

  return (
    <>
      <Loader loading={loading} />
      <Box
        sx={{
          padding: '1rem 2rem',

          '& p': {
            fontFamily: 'Overpass',
          },
        }}
      >
        {!isDBConnected ? (
          <DBConnectLandingScreen
            attributeJson={attributeJson}
            setIsDBConnected={setIsDBConnected}
            setConnectionData={setConnectionData}
            setLoading={setLoading}
          />
        ) : (
          <DBConnectedScreen
            attributeJson={attributeJson}
            setIsDBConnected={setIsDBConnected}
            connectionData={connectionData}
            setLoading={setLoading}
          />
        )}
      </Box>
    </>
  );
};

export default IntegrationDBConnection;
