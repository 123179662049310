import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles, { HTMLPreviewButton } from './index.styles';

interface TemplatePreviewProps {
  channel: string;
  template: any;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  channel,
  template,
}) => {
  const classes = useStyles();

  const [displayState, setDisplayState] = useState<string>('preview');

  const handleDisplay = (state: 'html' | 'preview') => setDisplayState(state);

  return (
    <Box mb='1rem'>
      {channel === 'email' && (
        <Box display='flex' justifyContent='end'>
          <Box className={classes.htmlSwitchButtonCont}>
            <HTMLPreviewButton
              type='button'
              active={displayState === 'preview' && true}
              onClick={() => handleDisplay('preview')}
            >
              <Typography>Preview</Typography>
            </HTMLPreviewButton>
            <HTMLPreviewButton
              type='button'
              active={displayState === 'html' && true}
              onClick={() => handleDisplay('html')}
            >
              <Typography>HTML</Typography>
            </HTMLPreviewButton>
          </Box>
        </Box>
      )}

      <Box
        className={classes.templatePreviewBox}
        sx={{
          width: channel === 'email' ? 'calc(100vw - 700px)' : 500,
          height: channel === 'email' ? 'calc(100vh - 400px)' : 200,
          marginTop: channel === 'email' ? '1rem' : '2rem',
        }}
      >
        {Object.keys(template).length > 0 && (
          <>
            {channel === 'email' && displayState === 'preview' ? (
              <div
                dangerouslySetInnerHTML={{ __html: template.template }}
              ></div>
            ) : (
              <Typography sx={{ whiteSpace: 'pre-wrap', padding: '1rem 2rem' }}>
                {template.template}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TemplatePreview;
