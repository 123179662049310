import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
// @ts-ignore
import { useSpeechSynthesis } from 'react-speech-kit';
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs';
import { IoBackspaceOutline } from 'react-icons/io5';

import useStyles from './index.styles';

import { useLocation } from 'react-router';

interface IvrTwoWayPreviewProps {
  json?: any;
  messages?: any;
}

const IvrTwoWayPreview: React.FC<IvrTwoWayPreviewProps> = ({
  json,
  messages,
}) => {
  const { speaking, speak, voices, cancel } = useSpeechSynthesis();

  const { pathname } = useLocation();
  const notModal = pathname === '/template-management/create' && true;
  const classes = useStyles({ notModal });

  const [chats, setChats] = useState<any>([]);
  const [audioText, setAudioText] = useState('');
  const [responses, setResponses] = useState<any[]>([]);

  useEffect(() => {
    const message = messages[0];
    if (message && message?.responseExpected) {
      setTimeout(() => {
        setChats([{ message: message.msg, response: '' }]);
        setAudioText(message.msg);
        setResponses(message.responses);
      }, 1000);
    }
  }, [messages]);

  const handleSend = (number: string) => {
    const response = responses.find((resp: any) => resp.option === number);
    if (response) {
      const index = chats.length - 1;
      const msgObj = messages[+response.message];
      let chatCopy = chats.map((chat: any, ind: number) => {
        if (index === ind) return { ...chat, response: number };
        return { ...chat };
      });
      chatCopy[index + 1] = {
        message: msgObj.msg,
        response: '',
      };
      setResponses(msgObj.responses);
      setAudioText(msgObj.msg);
      setChats(chatCopy);
    }
  };

  return (
    <Box className={classes.ivrPreviewCont}>
      <Box className={classes.ivrPreviewMessageCont}>
        <Typography fontSize='0.9rem'>{audioText}</Typography>
      </Box>

      <Box className={classes.ivrPreviewPlayerCont}>
        <button
          type='button'
          onClick={() => {
            if (!speaking) speak({ text: audioText, voice: voices[2] });
            if (speaking) cancel();
          }}
        >
          {!speaking ? (
            <BsPlayCircle fontSize={40} color='#6AC5AB' />
          ) : (
            <BsPauseCircle fontSize={40} color='#6AC5AB' />
          )}
        </button>

        <Box className={classes.ivrPreviewWaveBox}>
          {Array.from({ length: 21 }, (_, i) => i + 1).map((item) => {
            return (
              <span
                style={{
                  height: speaking ? '100%' : item % 2 === 0 ? '100%' : '50%',
                  width: '2px',
                  background: 'rgba(0, 67, 87, 0.37)',
                }}
                key={item}
                className={speaking ? 'stroke' : ''}
              ></span>
            );
          })}
        </Box>
      </Box>

      <Box className={classes.ivrPreviewPhoneWrapper}>
        <Box className={classes.ivrPreviewPhoneKeyCont}>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('1')}
          >
            <Typography>1</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('2')}
          >
            <Typography>2</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('3')}
          >
            <Typography>3</Typography>
          </button>
        </Box>

        <Box className={classes.ivrPreviewPhoneKeyCont}>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('4')}
          >
            <Typography>4</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('5')}
          >
            <Typography>5</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('6')}
          >
            <Typography>6</Typography>
          </button>
        </Box>

        <Box className={classes.ivrPreviewPhoneKeyCont}>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('7')}
          >
            <Typography>7</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('8')}
          >
            <Typography>8</Typography>
          </button>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('9')}
          >
            <Typography>9</Typography>
          </button>
        </Box>

        <Box className={classes.ivrPreviewPhoneKeyCont}>
          <div></div>
          <button
            type='button'
            className={classes.ivrPreviewPhoneKeyBox}
            onClick={() => handleSend('0')}
          >
            <Typography>0</Typography>
          </button>
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <IoBackspaceOutline fontSize={30} />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default IvrTwoWayPreview;
